import styled, { css, keyframes } from 'styled-components';

export const SidebarContainer = styled.div`
  display : flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  background: ${props => props.theme.white};
`

export const SidebarRow = styled.div`
  border-bottom: 1px solid ${props => props.theme.white_border};

  &:last-child {
    border-bottom: 0px solid ${props => props.theme.white_border};
  }
`

export const SidebarRow2 = styled.div`
  overflow-y:auto;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
`

export const SidebarMenuItem = styled.div`
  display: block;
  cursor: pointer;
  padding: 0.75rem 1rem;

  color: ${props => props.theme.primary};
  background-color: #FFFFFF; 

  font-size: ${props => props.theme.font_m};
  font-weight: ${props => props.theme.font_bold};

  background-color:  ${props => props.active ? props.theme.primary : ''}; 
  border-bottom: 1px solid ${props => props.theme.white_border};

  ${props => props.disable && css` color: #C0C0C0; `}
`

export const SidebarMenuItem2 = styled(SidebarMenuItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props => props.selected 
    && css`
      color: ${props.theme.primary};
      background-color: ${props.theme.white_hilight}; 
    `
  }

  ${props => props.actived
    && css`
      color: ${props.theme.white_hilight}; 
      background-color: ${props.theme.primary};
    `
  }
`

export const LogoutBtnSection = styled.div`
  padding:5px 15px;
`

export const SidebarTitleItems = styled.div`
  display: inline-block;
  font-size: ${props => props.sm && props.theme.font_s};
`

const rotateDown = keyframes`
  from { transform:  rotate(0deg); }
    to { transform:  rotate(180deg); }
`

const rotateUp = keyframes`
  from { transform:  rotate(180deg); }
    to { transform:  rotate(0deg); }
`

export const SidebarIcon = styled.div`
  margin-right: 0.5rem; 
  min-width: 35px; 
  font-size: 12px;
  display: inline-block;
  text-align: center;
`

export const SidebarIconCaret = styled.div`
  float:right;
  animation : ${props => props.toggle ? rotateDown : rotateUp} 0.25s linear ;
  animation-fill-mode: forwards;
  width: 18px;
`

export const SidebarSubmenuContainer = styled.div`
  overflow: hidden;
  display: ${props => props.toggle ? 'flex' : 'none'};
  flex-direction: column;
  transition: height .5s;
  border-bottom:  ${props => props.toggle ? '1px' : '0px'} solid ${props => props.theme.white_border};
`

export const SidebarSubMenuItem = styled.div`
  font-size: ${props => props.theme.font_m};
  background: ${props => props.active
    ? props.theme.white_hilight
    : props.danger ? '#dc3545' : null};
  color:  ${props => props.active
    ? props.theme.primary
    : props.danger
      ? 'white'
      : props.theme.secondary};
      
  font-weight:  ${props => props.active ? props.theme.font_bold : null}; 
  display: block;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  cursor:pointer;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-left: 5px solid ${props => props.theme.primary}; 

  ${props => props.disable
    && css`
      color: #C0C0C0;
      cursor: not-allowed;
    `
  }

  &:hover{
    background:  ${props => props.active ? props.theme.white_hilight : `#f0f0f0aa`};
  }
`

export const SidebarSubMenuGroupLabel = styled.div`
  font-size: ${props => props.theme.font_m};
  color:  ${props => props.theme.secondary};
  display: block;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 5px solid ${props => props.theme.primary}; 

  > div {
    opacity: 0.65;
  }

  &::after {
    content: "";
    display: block;
    height: 1px;
    background-color: ${props => props.theme.secondary};
    flex-grow: 1;
    margin-left: 8px;
    opacity: 0.4;
  }
`

export const SidebarBusinessName = styled.div`
  font-size: ${props => props.theme.font_l};
  color: ${props => props.theme.primary};
  font-weight: ${props => props.theme.font_bold};
  padding: 10px 0px;
`

export const SidebarBusinessDropdown = styled.div`
  margin: 5px 0px;
  position: relative;
  width: 100%;
`

export const SidebarBusinessSelected = styled.div`
  color: ${props => props.theme.primary};
  font-weight: ${props => props.theme.font_bold};
  font-size: ${props => props.theme.font_l};
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items:center;
  cursor:pointer;
`

export const SidebarBusinessList = styled.div`
  position: absolute;
  top: 0;
  color: ${props => props.theme.secondary};
  background: ${props => props.theme.white};
  max-height: 150px;
  opacity: ${props => props.toggle ? '1' : '0'};
  transition: opacity .5s;
  box-shadow: 1px 1px 1px 1px #ccc;
  width:100%;
  z-index:  ${props => props.toggle ? '10' : '-10'};
  overflow: hidden;
`

export const SidebarBusinessListDropdown = styled.div`
  max-height: 150px;
  overflow-y: auto;
`

export const SidebarBusinessListItem = styled.div`
  padding: 5px;
  text-align: center;
  cursor: pointer;
  &:hover{
    color: ${props => props.theme.primary};
  }
`

export const SidebarBusinessTotal = styled.div`
  padding: 5px;
  text-align: center;
  cursor: pointer;
  font-size: ${props => props.theme.font_s};
  color: ${props => props.theme.secondary};
`

export const BadgePro = styled.div`
  font-weight: 500;
  color: #212529;
  background-color: #ffc107;
  padding: 4px 8px;
`

export const BagdeSuccess = styled.div`
  min-width: 20px;

  color: ${props => props.theme.green};
  padding: 0em 0.4em;

  border: 1px solid ${props => props.theme.green};
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;

  font-size: ${props => props.theme.font_s};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xs};
  }
`

export const BadgeNoti = styled.div`
  min-width: 20px;

  color: ${props => props.theme.white};
  padding: 0em 0.4em;

  background-color:${props => props.theme.red};
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;
  font-weight: 600;
`

export const BadgeVerify = styled.div`
  font-weight: 500;
  color: #ffc107;
  background-color: #212529;
  padding: 4px 8px;
`
