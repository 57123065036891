import { AUTOMATION } from "../actions_const"

const initState = {
  camapaign: { total: 0, list: [], offset: 0, limit: 10 },
  member_card_transactions: { total: 0, list: [], offset: 0, limit: 10 },
  ecard_transactions: { total: 0, list: [], offset: 0, limit: 10 },
  trigger: 'daily',
}

const ReducerAutomation =  (state = initState, action) => {
  switch (action.type) {
    case AUTOMATION.AUTOMATION_ECARD_CAMPAIGN_LIST: {
      return Object.assign({}, state, {
        camapaign: action.data
      })
    }
    case AUTOMATION.AUTOMATION_MEMBER_CARD_TRNASACTIONS: {
      return Object.assign({}, state, {
        member_card_transactions: action.data
      })
    }
    case AUTOMATION.AUTOMATION_ECARD_TRNASACTIONS: {
      return Object.assign({}, state, {
        ecard_transactions: action.data
      })
    }
    case AUTOMATION.AUTOMATION_SET_TRIGGER: {
      return Object.assign({}, state, {
        trigger: action.data
      })
    }
    default:
      return state;
  }
}

export default ReducerAutomation;