const ChartColor = {
  point: "#022F47",
  coupon: "#126782",
  broadcast: "#209EBC",
  member_card: "#8ECAE6",
  trans_noti_expire_point: "#FB8500",
  trans_noti_expire_member_card: "#FFB701",
  reject_collect_points: "#FD9E03",
  remain: "#E8EBED",
};


export { ChartColor };
