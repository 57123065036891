import styled, { css, keyframes } from 'styled-components'
import { Title2 } from '../../components/Font'
import { CardHeader } from 'reactstrap'

export const ScrollModal = styled.div`
  padding: 0px 5px;
  ${props => !props.readOnly
    && css`
      width: 100%;  
      max-height: calc(100vh - 280px);

      padding-right: 15px;
      overflow-x: hidden;
      overflow-y: scroll;
    `
  }  
`

export const CenterBox = styled.div`
  display: flex;
  align-items: center;
  justify-contents: center;
  flex-direction: column;
`

export const Verify = styled.div`
  width: 100%;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const VerifyInfo = styled.div`
  padding: 10px 15px;
`

export const VerifyInfoDetail = styled.div`
  display: flex;
  padding: 5px 0px 0px 0px;
  align-items: center;
  ${ props => props.center
    ? css`justify-content: center;`
    : css`justify-content: start;`
  }
  ${ props => props.tab
    && css`padding-left: 15px;`
  }
`

export const VerifyInfoImg = styled.div`
  display: flex;
  font-size: 30px;
  justify-content: center;
`

export const VerifyStatusBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  color: #FFFFFF;
  padding: 12px 10px;
  border-radius: 5px;

  ${props => props.background === 'verified'
    ? css`background: ${props.theme.green};`
    : props.background === 'pending'
      ? css`background: ${props.theme.blue};`
      : props.background === 'reject'
        ? css`background: ${props.theme.red};`
        : css`background: ${props.theme.primary};`
  }
`

export const BusinessTypebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  
  padding: 10px 10px;
  border-radius: 10px;
  
  cursor: ${props => props.pointer ? 'pointer' : 'not-allowed'};
  ${props => props.select
    ? css`  border: 2px solid #b6b5b5;
            box-shadow: 0px 0px 15px -5px #BBB;
            background-color: floralwhite;`
    : css`  border: 1px solid #e9ecef;
            background-color: #eef0f2;`
  }
`

export const BusinessTypeIcon = styled.i`
  font-size: 12px;
  ${props => props.select
    ? css`color: ${props.theme.primary};`
    : css`color: ${props.theme.third};`
  }
`

export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ModelDetailFooter = styled.div`
  width: 100%;
  display: flex;
  padding-top: 15px;
  margin-top: 15px;
  background: #FFFFFF;
  border-top: 1px solid #EAEAEA;
  ${props => props.spacebetween ? css`justify-content: space-between;` : css`justify-content: flex-end;`}
`

export const ButtonPhone = styled.div`
  padding-top: 6px;
  display: flex;
  justify-content: flex-end;
`

export const TitleBar = styled.div`
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  background-color: rgb(0,0,0,0.05);
`

export const IconPlus = styled.i`
  font-size: 13px;
  color: ${props => props.theme.secondary};
`

export const IconCheckBox = styled.i`
  font-size: 18px;
  color: ${props => props.theme.primary};
`

export const CreditCardBox = styled.div`
  width: 100%;
  height: 85px;
  ${props => !props.readOnly && css`cursor: pointer;`}
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.selectCard
    && css`background-color: rgb(0,0,0,0.05);`
  }
`

export const CreditCardDetail = styled.div`
  width: 100%;
  > * {
    border-bottom: 1px solid #dee2e6;
  }
`

export const LoadingSizeBox = styled.div`
  height: 100%;
  min-height: ${props => props.height || 200}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardPrimarySelectCard = styled.div`
  width: 30vw;
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CreditCardBoxDetail = styled.div`
  height: 80%;
  display: flex;
  ${props => props.direction ? css`flex-direction: column;` : null}
  justify-content: space-evenly;
`

export const CreditLogo = styled.div`
  width: 25vw;
  max-width: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const CreditLogoImage = styled.img`
  height: auto;
  max-width: 40px;
`

export const BlackCircle = styled.div`
  width: 7px;
  height: 7px;
  @media (max-width: 990px) {
    width: 5px;
    height: 5px;
  }

  margin: 1px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #546174;
`

export const BlockAlignCenter = styled.div`
  display: flex;
  align-items: center;
`

export const Stripe = styled.img`
  width: 15vw;
  max-width: 120px;
  min-width: 20px;
`

export const Selectbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7f7;
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid #CCC;
  color: ${props => props.theme.primary};
  position: relative;
  width: 100%;
`

export const Draftsearchlist = styled.div`
  display: flex;
  align-items: center;
  ${props => props.center && css`justify-content: center;`}
  height: 100%;
  color: #a1a1a1;
  flex-direction: column;
  padding: 5px 10px;
`

export const ShowCategoryBox = styled.div`
  display: flex;
  align-items: center;
  ${props => props.center && css`justify-content: center;`}
  flex-direction: column;
  padding: 5px 10px;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #CCC;
`

export const VerifyBusinessCard = styled.div`
  font-size: 26px;
  overflow: hidden;
  margin: 8px 12px;

  border-radius: 8px;
  border: 1px solid ${props => props.theme.secondary}80;

  color: ${props => props.theme.primary}90;
  ${props => props.danger && css`color: ${props.theme.red};`}
  ${props => props.success && css`color: ${props.theme.green};`}
  
  cursor: ${props => props.pointer && 'pointer'};
  transition: all 0.2s ease;

  &:hover{
    box-shadow: 0px 3px 10px 0px #9a9696;
    font-size: 28px;

    color: ${props => props.theme.primary};
    ${props => props.danger && css`color: ${props.theme.red};`}
    ${props => props.success && css`color: ${props.theme.green};`}
  }
`

export const VerifyBusinessContainer = styled.div`
  width: 100%;
  height: 120px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const VerifyBusinessBtnAddDetail = styled.div`
  padding: 0.75rem 0.25rem;
  border-top: 1px solid ${props => props.theme.secondary}80;
  
  background-color: #FFFFFF;
  transition: all 0.2s ease-out;

  background-color: ${props => props.theme.secondary}18;
`

export const VerifyBusinessCheck = styled.i`
  font-size: 16px;
  color: ${props => props.theme.primary}90;

  ${props => props.success && css`color: ${props.theme.green};`}
  ${props => props.danger && css`color: ${props.theme.red};`}
`
export const InputBox = styled.div`
  border-radius: 5px;
  background: #e9ecef;
  padding: 7px 0px 7px 25px;
  border-bottom: 1px solid #F0F0F0;
`

export const VerifyText = styled(Title2)`
  color: ${
    props => props.status === 'verified' ? props.theme.green :
    (props.status === 'pending' ? props.theme.blue :
      (props.status === 'reject' ? props.theme.red : props.theme.primary))
  }
`
export const CardHeaderContainer = styled(CardHeader)`
  border: 1px solid ${props => props.theme.secondary}80;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #fff;
`
export const SearchItem = styled.div`
  width: 100%;
  min-width: 100px;
  cursor: pointer;
  padding: 8px 15px;
  border-bottom: 1px solid ${props => props.theme.border_line};
  ${props => props.selected ? css`background: ${props => props.theme.blue_light};` : ''}

  &:hover{
    padding-left: 10px;
    border-left: 5px solid #546174;
  }

  :last-child{
    border-bottom: none;
  }
`
export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: solid;
  border-color: #CCC;
  height: 100%;
  ${props => props.pointer && css`cursor: pointer;`}
  ${props => props.absolute && css`position: absolute; right: 0px;`}
  border-width: ${props => props.fullborder ? `1px 1px 1px 1px;` : `0px 0px 0px 1px;`}
  background-color: #f8f8f8;
`
export const Icon = styled.i`
  ${props => props.pointer && css`cursor:pointer;`}
  padding: 0px 10px;
  color: ${props => props.theme.primary};
`
const rotateDown = keyframes`
  from { transform:  rotate(0deg); }
    to { transform:  rotate(180deg); }
`

const rotateUp = keyframes`
  from { transform:  rotate(180deg); }
    to { transform:  rotate(0deg); }
`
export const IconChevron = styled.div`
  animation: ${props => props.toggle ? rotateUp : rotateDown} 0.2s linear;
  animation-fill-mode: forwards;
`
export const DropdownType = styled.div`
  position: absolute;
  left: 0;
  width: inherit;
  z-index: 200;
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
`
export const TypeListBox = styled.div`
    width: 100%;
    max-height: 140px;
    overflow-y: scroll;
    overflow-x: hidden;
    @media (min-width: 990px){
      max-height: 160px;
    }
`