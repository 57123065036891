import axios from 'axios'

import { getCookie } from '../libs/cookies'

function AxiosRequest (options) {
  let lang = getCookie('__lang') || 'th'
  options.headers = options.headers || {}
  Object.assign(options.headers, {
    'x-language': lang
  })
  return axios(options)
} 

export default AxiosRequest;