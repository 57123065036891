import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import App from './App';
import store from './store/index';

import GoogleLogin from '../GoogleLogin/GoogleLogin';

function BroadcastMessage() {
  const [logout, setLogout] = useState(undefined);

  if (logout || logout === null) {
    return (
      <Router>
        <Route path={'/googlelogin'} component={GoogleLogin} />
        <Redirect
          to={{
            pathname: '/googlelogin',
            from: '/broadcastmessage',
          }}
        />
      </Router>
    );
  }

  return (
    <Provider store={store}>
      <div id="broadcast-message-modal"></div>
      <App setLogout={setLogout}></App>
    </Provider>
  );
}

export default BroadcastMessage;
