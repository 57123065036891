import React from "react";
import { useSelector } from "react-redux";
import AutomationMembercardSetting from "./AutomationMembercardSetting";

export default function AutomationMembercard() {
  const current_business = useSelector((state) => state.business.current);
  
  /*
  const permission =  current_business.permission;
  const dictionary = useSelector((state) => state.language.dictionary);
  const permission_view = _.get(permission, [PERMISSION_KEY, 'permission_view']);
  const permission_edit = _.get(permission, [PERMISSION_KEY, 'permission_edit']);

  const tabMenus = [
    {
      show: permission_view,
      key: MENU_HISTORY,
      icon: 'fas fa-history',
      name: dictionary.history_transactions,
    }, {
      show: permission_edit,
      key: MENU_SETTING,
      icon: 'fas fa-cog',
      name: dictionary.settings,
    }
  ];

  return <TabContainer tabMenuList={tabMenus} TabContent={MenuContent} />

  */

  return <AutomationMembercardSetting business={current_business} />
}

// const MenuContent = (props) => {
//   switch (props.currentMenu) {
//     case MENU_HISTORY:
//       return <AutomationMembercardTransactions {...props} />
//     case MENU_SETTING:
//       return <AutomationMembercardSetting {...props} />
//     default:
//       return null
//   }
// }