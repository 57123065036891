import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessPackageSSE, getBusinessPackage } from "../../redux/actions/action_package"; // Import both actions
import { usePrevious } from "../../libs/hooks/usePrevious";
import { Title2, Title3, Title4 } from "../Font";
import { DangerDot } from "./BusinessPackage.style";
import { TransparentBtn, UpgradeBtn } from "../../components/Button";
import ModalPackage from "./BusinessPackageModal";
import ModalSubscription from "./BusinessSubscriptionModal";
import ModalSubscriptionNewPackage from "./BusinessSubscriptionNewPackageModal";
import Loading from "../Loading";
import { hasValueInArray } from "../../libs/validator";

const permission_key = "setting-packages";

export default function BusinessPackage() {
  const [isOpenPackage, setIsOpenPackage] = useState(false);
  const [isOpenSubscription, setIsOpenSubscription] = useState(false);
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);

  const dispatch = useDispatch();
  const { dictionary } = useSelector((state) => state.language);
  const business_code = useSelector((state) => state.business.current.business_code);
  const { subscription } = useSelector((state) => state.packages);
  const previousBusinessCode = usePrevious(business_code);

  const isMounted = useRef(false);
  const { isNewPackage } = subscription;

  const getPackageBusiness = (business_code, load = false) => {
    if (load) setIsLoadingLocal(true);

    const cleanup = dispatch(
      getBusinessPackage({ business_code, load }, (err, data) => {
        // Call getBusinessPackage action
        if (err) {
          console.error("Package fetch error:", err);
        } else {
          console.log("Package data received:", data);
          setIsLoadingLocal(false);
        }
      })
    );

    return cleanup;
  };

  const getPackageBusinessSSE = (business_code, load = false) => {
    if (load) setIsLoadingLocal(true);

    const cleanup = dispatch(
      getBusinessPackageSSE({ business_code, load }, (err, data) => {
        if (err) {
          console.error('SSE error:', err);
        } else {
          console.log('SSE data received:', data);
          setIsLoadingLocal(false);
        }
      })
    );

    return cleanup;
  };

  useEffect(() => {
    let cleanup;
    const useSSE = typeof EventSource !== 'undefined';

    const setupSSE = () => {
      if (cleanup) cleanup();

      cleanup = useSSE
        ? getPackageBusinessSSE(business_code, true)
        : getPackageBusiness(business_code, true);
    };

    setupSSE();

    const handleReconnectWhenOnline = () => {
      console.log('Network is back online. Reconnecting SSE...');
      setupSSE();
    };

    const handleOffline = () => {
      console.log('Network is offline. SSE connection closed.');
      if (cleanup) cleanup();
    };

    window.addEventListener('online', handleReconnectWhenOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      if (cleanup) cleanup();

      window.removeEventListener('online', handleReconnectWhenOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [business_code]);


  const toggleModalPackagePlan = () => {
    setIsOpenPackage(!isOpenPackage);
  };

  const toggleModalSubscription = () => {
    setIsOpenSubscription(!isOpenSubscription);
  };

  const { packages, packages_list, isLoading, packages_free } = useSelector(
    (state) => state.packages
  );
  const {
    current: { permission }
  } = useSelector((state) => state.business);
  const { usage_record } = useSelector((state) => state.usage);

  const packageItems = (packages && packages.package_data) || {};
  const this_permission = (permission && permission[permission_key]) || {};
  const permiss_packages = (permission && permission["setting-packages"]) || {};

  return (
    <>
      <div className="border-top border-bottom" style={{ padding: "0.5rem 1rem" }}>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ minHeight: 50 }}
        >
          {isLoading || isLoadingLocal ? (
            <LoadingBox />
          ) : (
            <>
              <div>
                <Title2 bold>{`${dictionary[packageItems.title_dictionary]}`}</Title2>
                <Title4>{dictionary.current_usage_package}</Title4>
              </div>
              {Boolean(this_permission && this_permission.permission_view) &&
              Boolean(packages.subscription_package) ? (
                <TransparentBtn
                  onClick={
                    Boolean(packages.subscription_package)
                      ? toggleModalSubscription
                      : toggleModalPackagePlan
                  }
                >
                  <Title3 bold link>
                    {dictionary.show_detail}
                    {((usage_record.credit_remaining_percent < 20 && !isNewPackage) ||
                      (isNewPackage &&
                        (usage_record.isTxCloseToLimit || usage_record.isCreditCloseToLimit))) && (
                      <DangerDot />
                    )}
                  </Title3>
                </TransparentBtn>
              ) : Boolean(permiss_packages && permiss_packages.permission_view) ? (
                <UpgradeBtn
                  style={{ marginRight: "14px" }}
                  onClick={
                    Boolean(packages.subscription_package)
                      ? toggleModalSubscription
                      : toggleModalPackagePlan
                  }
                >
                  {dictionary.upgrade}
                </UpgradeBtn>
              ) : null}
            </>
          )}
        </div>
      </div>
      {isOpenPackage && packages_list.length && (
        <ModalPackage
          isOpen={isOpenPackage}
          toggle={toggleModalPackagePlan}
          toggleSubscription={toggleModalSubscription}
          packageItems={packages_list.filter((v) => v.show)}
        />
      )}
      {isOpenSubscription &&
        !isNewPackage &&
        packages &&
        !hasValueInArray(packages_free, packages.subscroption_package) && (
          <ModalSubscription
            packageItems={packageItems}
            isOpen={isOpenSubscription}
            toggle={toggleModalSubscription}
            togglePackage={toggleModalPackagePlan}
          />
        )}
      {isOpenSubscription &&
        isNewPackage &&
        packages &&
        !hasValueInArray(packages_free, packages.subscroption_package) && (
          <ModalSubscriptionNewPackage
            packageItems={packageItems}
            isOpen={isOpenSubscription}
            toggle={toggleModalSubscription}
            togglePackage={toggleModalPackagePlan}
          />
        )}
    </>
  );
}

const LoadingBox = () => {
  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <Loading size="2rem" margin="0" />
    </div>
  );
};
