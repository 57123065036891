import {
  TOGGLE_MODAL,
  TOGGLE_MODAL_MEMBER
} from '../actions_const'

export const toggleModal = (isOpen) => {
  return { type: TOGGLE_MODAL, data: isOpen }
}

export const toggleModalMember = (memberBadgeCode, option) => {
  return { type: TOGGLE_MODAL_MEMBER, data: { memberBadgeCode: memberBadgeCode || '', option: option || {} } }
}