import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { PrimaryBtn } from '../../Button'
import { Title2 } from '../../Font'
import { Label, LabelSelectModal } from '../../Label'
import { getTargetMemberLabelTotal, initBroadcastTarget, setTargetMemberTotal } from '../../../redux/actions/action_broadcast'
import { toastError } from '../../ToastComponent/ToastError'
import { getBusinessLabel } from '../../../redux/actions/action_business'

export default function TargetMemberLabel({
	target,
	readonly,
	hideAddButton,
	disabledLabel,
	noRequest
}) {
	const [memberLabel, setMemberLabel] = useState([])
	const [openModal, setOpenModal] = useState(false)
	const dictionary = useSelector((state) => state.language.dictionary)
	const { business_code } = useSelector((state) => state.business.current)
	const dispatch = useDispatch()
	const isMounted = useRef()
	const limitSelectLabel = 5

	const handleGetTargetTotal = useCallback(() => {
		if (memberLabel) {
			const labels = memberLabel.map(m => m.tag_id)
			if (!noRequest) {
				dispatch(getTargetMemberLabelTotal({ business_code, labels: labels }, (err) => {
					if (err) toastError(dictionary.error)
				}))
			} else {
				dispatch(setTargetMemberTotal({
					target: 'member_label',
					target_data: labels
				}))
			}
		}
	}, [dispatch, memberLabel, business_code, dictionary, noRequest])

	const handleGetMemberLabel = useCallback(() => {
		dispatch(getBusinessLabel({ business_code, excludeArchive: '' }, (err, data) => {
			if (!isMounted.current) return null
			setMemberLabel(() => {
				const value = Array.isArray(target) ? data.filter(f => target.indexOf(f.tag_id) > -1) : []
				return value
			})
		}))	
	}, [dispatch, business_code, target])

	useEffect(() => {
		if (isMounted.current) {
			handleGetTargetTotal()
		}

		if (!isMounted.current) {
			isMounted.current = true
			if (target && target.length) {
				handleGetMemberLabel()
			}
			dispatch(initBroadcastTarget('member_label'))
		}
	}, [dispatch, handleGetTargetTotal, handleGetMemberLabel, target])

	const toggleModal = () => {
		setOpenModal((prev) => !prev)
	}

	const selectLabel = (value) => {
		if (value) {
			setMemberLabel(value)
			toggleModal()
		}
	}

	return (
		<FormGroup className='pb-1'>
			<Title2 className='pb-2' bold><span className='text-danger'>* </span>{dictionary.select_member_label}</Title2>
			<div className='w-100 d-flex align-items-center justify-content-start flex-wrap pl-2'
				style={{ maxHeight: '105px', overflowY: 'scroll', overflowX: 'hidden' }}>
				{
					(memberLabel && memberLabel.length > 0)
					&& memberLabel
						.sort((a, b) => a.tag_name.localeCompare(b.tag_name))
						.map((tag) => {
							return <Label
								formLabel
								pointer={!readonly}
								disabled={disabledLabel}
								hoverContent={!readonly && <><i className="fas fa-pen pr-2" />{dictionary.edit}</>}
								key={tag.tag_id}
								type={tag.tag_type}
								name={tag.tag_name}
								color={tag.tag_style.tag_color}
								onClick={!readonly && toggleModal}
								style={{ margin: '5px 5px 5px 0px' }} />
						})
				}
				{
					(memberLabel && (memberLabel.length < limitSelectLabel) && !hideAddButton && !readonly)
					&& <PrimaryBtn
						sm bold
						id={`btn-add_target_member_label`}
						disabled={((memberLabel && memberLabel.length) || 0) >= limitSelectLabel}
						style={{ fontSize: 10, marginRight: '5px', minWidth: (((memberLabel && memberLabel.length) || 0) > 0) ? 0 : '' }}
						onClick={toggleModal}>
						{
							(((memberLabel && memberLabel.length) || 0) > 0)
								? <i className="fas fa-plus" />
								: <><i className="fas fa-plus pr-1" />{dictionary.member_label}</>
						}
					</PrimaryBtn>
				}
				{
					openModal &&
					<LabelSelectModal
						unselect
						type='member'
						limit_label={limitSelectLabel}
						isOpen={openModal}
						toggle={toggleModal}
						modalTitle={dictionary.member_label}
						selected={memberLabel || []}
						onChange={selectLabel}
					/>
				}
			</div>
		</FormGroup>
	)
}