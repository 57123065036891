import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TagLabel, Circle, Content } from './label.style';
import { Tooltip } from 'reactstrap';

class Label extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentWidth: '',
      contentHeight: '',
      tooltip: false,
    };
  }

  componentDidMount() {
    if (this.props.name && this.props.hoverContent) {
      let elem = document.getElementById(`label-content_${this.props.name}`);
      if (!elem) return null;
      this.setState({
        contentWidth: elem.offsetWidth,
        contentHeight: elem.offsetHeight,
      });
    }
  }

  Luminance = (hex, lum = 0) => {
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6)
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];

    // convert to decimal and change luminosity
    var rgb = '#';
    for (var i = 0; i < 3; i++) {
      let code = parseInt(hex && hex.substr(i * 2, 2), 16);
      code = Math.round(Math.min(Math.max(0, code + code * lum), 255)).toString(
        16
      );
      rgb += ('00' + code).substr(code.length);
    }
    return rgb;
  };

  toggleToolTip = () => {
    this.setState((prevState) => ({
      tooltip: !prevState.tooltip,
    }));
  };

  render() {
    let {
      store_language: { dictionary },
    } = this.props;
    let {
      formLabel,
      onClick,
      name,
      color,
      pointer,
      disabled,
      check,
      style,
      type,
      noWidth,
      hoverContent,
      ai_label,
    } = this.props;
    return (
      <TagLabel
        style={style}
        hoverContent={hoverContent}
        white={type === 'member'}
        noWidth={noWidth}
        formLabel={formLabel}
        check={check}
        disabled={disabled}
        onClick={!disabled && typeof onClick === 'function' ? onClick : null}
        pointer={pointer}
        color={ai_label ? '#FFF' : color}
        borderColor={this.Luminance(color, -0.1)}
        borderWidth={ai_label ? '2px' : '1px'}
        id={ai_label ? 'tooltip-ai-label' : ''}
      >
        <div
          hidden={formLabel}
          className="mr-2"
          style={{ minWidth: 20, display: 'flex', justifyContent: 'center' }}
        >
          <i className="fas fa-check text-success" hidden={!check} />
          {type === 'member' ? (
            <i className="fas fa-user-tag fa-lg" hidden={check} />
          ) : (
            <Circle hidden={check} />
          )}
        </div>
        <div id={`label-content_${name}`}>
          <Content
            id="label-content"
            center={formLabel}
            minWidth={this.state.contentWidth}
            minHeight={this.state.contentHeight}
            color={ai_label && this.Luminance(color, -0.5)}
          >
            <div id="label-content-name">{name}</div>
            {hoverContent && <div id="label-content-hover">{hoverContent}</div>}
          </Content>
        </div>
        {ai_label && (
          <Tooltip
            placement="bottom"
            isOpen={this.state.tooltip}
            target={'tooltip-ai-label'}
            toggle={this.toggleToolTip}
            delay={200}
          >
            {dictionary.tooltip_ai_label}
          </Tooltip>
        )}
      </TagLabel>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
  };
};

export default connect(mapStateToProps)(Label);
