import { NEWSFEED } from '../actions_const'

let initState = {
  newsfeed: [],
  limit: 20,
  offset: 0,
  total: 0,
  nextfeed: []
}

const ReducerNewsFeed = (state = initState, action) => {
  switch (action.type) {
    case NEWSFEED.GET_BUSINESS_NEWSFEED:

      return Object.assign({}, state, {
        newsfeed: action.isLoadmore ? [...state.newsfeed, ...action.data] : [...action.data],
        limit: action.limit,
        offset: action.offset,
        total: action.total,
        nextfeed: state.nextfeed.filter(f => f.create_date > action.data[0] && action.data[0].create_date)
      })

    case NEWSFEED.GET_BUSINESS_NEXT_NEWSFEED:
      return Object.assign({}, state, {
        nextfeed: action.data.filter(f => f.create_date > state.newsfeed[0].create_date)
      })

    case NEWSFEED.CLEAR_BUSINESS_NEWSFEED:
      return Object.assign({}, state, initState)

    default:
      return state
  }
}

export default ReducerNewsFeed;