import React from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Col, Container } from "reactstrap";
import _ from "lodash";
import { formatDateFull } from "./../../libs/date";
import { toCurrency } from "../../libs/currency";
import { hasValueInArray } from '../../libs/validator';

import BusinessPayment from "./../../components/BusinessPayment";
import ContactUsModal from "./ContactUsModal";

import {
  BusinessPlanAddon,
  BusinessPlanAll,
  BusinessPlanBox,
  BusinessPlanDetail,
  BusinessPlanFeature,
  BusinessPlanSection,
  BusinessPlanTitle,
  PGTitle1,
  PGTitle2,
  PGTitle3,
  PlanDetail,
  PlanFeature,
  PlanFeatureDisc,
  PlanFeatureDetail,
  PlanFeatureGroup,
  UpgradePlan,
  UpgradePlanTop,
} from "./BusinessPackage.style";
import { BusinessDetailChoose } from "./BusinessPackage.style";
import { Title3, Title4 } from "../Font";
import "./packagesDetail.css"

const permission_key = "setting-packages";

class PackagesDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenPayment: false,
      isOpenContact: false,
      selected_package: "",
      isChangePackage: false,
    };
  }

  toggleContact = () => {
    this.setState({
      isOpenContact: !this.state.isOpenContact
    });
  }

  toggleModalPackage = (package_id, isChangePackage) => {
    this.setState({
      isOpenPayment: !this.state.isOpenPayment,
      selected_package: package_id,
      isChangePackage,
    });
  };

  render() {
    let {
      store_language: { dictionary },
    } = this.props;
    let {
      store_packages: { packages, packages_list, packages_free },
    } = this.props;
    let {
      store_usage: { usage_record },
    } = this.props;
    let {
      store_business: { permission },
    } = this.props;

    let current_package_id = packages && packages.package_data && packages.package_data.package_id;
    const this_permission = (permission && permission[permission_key]) || {};
    const business_permission_edit = this_permission.permission_edit;
    const packages_not_onward = ['ps-f32']
    let packageItems = packages_list.filter((it) => it.show || (it.package_id === current_package_id));
    let showCreditDetail = Boolean(packageItems.find((it) => it.credit));
    let size = {
      xs: packageItems.length > 1 ? 11 : 12,
      md: packageItems.length > 1 ? 11 : 12,
      lg: packageItems.length > 2 ? 5 : 6,
      xl: packageItems.length > 2 ? 4 : 6,
    };
    let addCustomColCss = packageItems.length > 3 ? 'custom-col-xl' : '';

    packageItems = packageItems.map((p) => {
      if (!hasValueInArray(packages_free, p.package_id)) return p;
      return Object.assign(p, {
        member: usage_record.free_members || 20,
        transaction: usage_record.free_transactions || 20,
      });
    });
 
    return (
      <Container>
        <BusinessPlanAll>
          <div className="d-flex mt-2 mb-2" style={{ width: "100%", overflowY: "scroll" }}>
            {packageItems.map((v, k) => {
              let end_sale_date = v.end_sale_date && new Date(v.end_sale_date);
              let addons_list =
                v.addons && v.addons.length > 0
                  ? v.addons.sort((a, b) => a.id > b.id)
                  : [];
              v.package_style = v.package_style || {};
              const notification_items = v.items.filter(
                (item) => item.name === "additional_sms" || item.name === "additional_line_noti"
              ).sort((a, b) => a.id - b.id);
              const broadcast_items = v.items.filter(
                (item) => item.name === "additional_sms_broadcast" || item.name === "additional_line_broadcast" || item.name === "additional_line_broadcast_free"
              );
              return (
                (v.package_id === 'ps-free' && current_package_id !== 'ps-free') ||
                (v.package_id === 'ps-free-trial' && current_package_id !== 'ps-free-trial') ||
                (v.package_id === 'ps-free-trial-2023' && hasValueInArray(['ps-free', 'ps-free-trial'], current_package_id)) ? null :
                <Col key={k} xs={size.xs} md={size.md} lg={size.lg} xl={size.xl} className={`pt-3 pb-3 ${addCustomColCss}`}>
                  <BusinessPlanBox package_style={v.package_style}>
                    <div>
                    <UpgradePlanTop package_style={v.package_style}>
                      {v.package_id === current_package_id ? (
                        <BusinessDetailChoose current>
                          <i className="pr-2 fas fa-check-circle" />
                          <div>{dictionary.current_usage_package}</div>
                        </BusinessDetailChoose>
                      ) : business_permission_edit ? (
                        hasValueInArray(packages_free, current_package_id) ? (
                          <BusinessDetailChoose
                            id={`btn-choose_${v.id}`}
                            onClick={() => {
                              v.yearly_fee ? this.toggleContact()
                              : this.toggleModalPackage(v.package_id);
                            }}
                          >
                            <div>
                              {dictionary.package_upgrade} <sup>{ current_package_id === 'ps-free' && 5 }</sup>
                            </div>
                          </BusinessDetailChoose>
                        ) : (
                          hasValueInArray(packages_free, v.package_id) ? null :
                          <BusinessDetailChoose
                            id={`btn-request_to_change_package_${v.id}`}
                            // onClick={() => window.open(process.env.REACT_APP_GOTO_SUPPORT, "_blank")}
                            onClick={() => {
                              v.yearly_fee ? this.toggleContact()
                              : this.toggleModalPackage(v.package_id, true);
                            }}
                          >
                            <div> 
                              { dictionary.request_change_package} <sup>{ current_package_id === 'ps-free' && 5 }</sup> 
                            </div>
                          </BusinessDetailChoose>
                        )
                      ) : null}
                    </UpgradePlanTop>
                      {/* TITLE */}
                      <BusinessPlanTitle package_style={v.package_style}>
                        {/* <PGTitle3 color={v.package_style.secondary}>{dictionary.package.toUpperCase()}</PGTitle3> */}
                        <PGTitle1 center bold color={v.package_style.primary}>
                          {dictionary[v.title_dictionary]}
                        </PGTitle1>
                      </BusinessPlanTitle>
                      <BusinessPlanSection>
                        {/* MONTHLY FEE */}
                        <BusinessPlanDetail style={{ minHeight: 120}} package_style={v.package_style} hidden={!v.monthly_fee ? !v.yearly_fee : false}>
                          <PGTitle2 bold color={v.package_style.secondary}>
                            {v.monthly_fee ? dictionary.monthly_fee_package : `${dictionary.yearly_fee_package}`} {v.yearly_fee && <sup>7</sup>}
                          </PGTitle2>
                          <PlanDetail>
                            {Boolean(v.monthly_fee_sale) && (
                              <PGTitle3 className="p-0" color={v.package_style.primary}>
                                <span style={{ textDecoration: "line-through" }}>{`${toCurrency(v.monthly_fee)} ${
                                  dictionary[v.currency || "thb"]
                                }`}</span>
                              </PGTitle3>
                            )}
                            <PGTitle1 className="pb-2" center bold color={v.package_style.primary}>
                              {`${toCurrency(v.monthly_fee_sale || v.monthly_fee || v.yearly_fee)} ${dictionary[v.currency || "thb"]}`}
                            </PGTitle1>
                            <PGTitle3 color={v.package_style.secondary}>{v.monthly_fee ? dictionary.per_month : dictionary.per_year}</PGTitle3>
                            {Boolean(v.end_sale_date) && (
                              <PGTitle3 className="p-0" color={v.package_style.primary}>
                                {`${dictionary.pricing_sale_until} ${formatDateFull(end_sale_date)}`}
                              </PGTitle3>
                            )}
                          </PlanDetail>
                        </BusinessPlanDetail>
                        {/* MEMBERS */}
                        {hasValueInArray(packages_free, v.package_id) ? (
                          <BusinessPlanDetail style={{ minHeight: 120 }} package_style={v.package_style}>
                            <PGTitle3 color={v.package_style.secondary}>
                              {dictionary.member_package.toUpperCase()} <sup>1</sup>
                            </PGTitle3>
                            <PlanDetail>
                              <PGTitle1 center bold color={v.package_style.primary}>
                                {`${dictionary.free.toUpperCase()} ${toCurrency(
                                  v.member
                                )} ${dictionary.members.toUpperCase()}`}
                              </PGTitle1>
                            </PlanDetail>
                          </BusinessPlanDetail> 
                          )
                        :
                          <BusinessPlanDetail style={{ minHeight: 120 }} package_style={v.package_style}>
                            <PGTitle3 color={v.package_style.secondary}>
                              {dictionary.member_package.toUpperCase()} <sup>1</sup>
                            </PGTitle3>
                            <PlanDetail style={{ minHeight: 80 }}>
                              {!v.member ? 
                              (
                                <PGTitle1 bold color={v.package_style.primary}>
                                  {dictionary.unlimited}
                                </PGTitle1>
                              ) : (
                                <>
                                  <PGTitle1 className="pb-2" center bold color={v.package_style.primary}>
                                    {`${dictionary.free.toUpperCase()} ${toCurrency(
                                      v.member
                                    )} ${dictionary.members.toUpperCase()}`}
                                  </PGTitle1>
                                  <PGTitle3 color={v.package_style.secondary}>
                                    {String.format(dictionary.members_onward, toCurrency(v.member + 1)).toUpperCase()}
                                  </PGTitle3>
                                  <PGTitle3 color={v.package_style.secondary}>{`${
                                    toCurrency(v.member_fee, 2) || dictionary.unlimit
                                  } ${dictionary[v.currency || "thb"]}`}</PGTitle3>
                                  <PGTitle3 color={v.package_style.secondary}>
                                    {dictionary.per_member_month.toUpperCase()}
                                  </PGTitle3>
                                </>
                              )}
                            </PlanDetail>
                          </BusinessPlanDetail>
                        }
                        {/* TRANSACTION */}
                        <BusinessPlanDetail style={{ minHeight: 150 }} package_style={v.package_style}>
                          <PGTitle3 color={v.package_style.secondary}>
                            {v.credit ? dictionary.basic_transactions.toUpperCase() : dictionary.transaction_package.toUpperCase()} {v.yearly_fee ? <sup>8</sup> : <sup>2</sup>}
                          </PGTitle3>
                          <PlanDetail style={{ minHeight: 108 }}>
                            {hasValueInArray(packages_free, v.package_id) ? (
                              <>
                                <PGTitle1 className="pb-2" center bold color={v.package_style.primary}>
                                  {`${dictionary.free.toUpperCase()} ${toCurrency(
                                    v.transaction || 0
                                  )} ${dictionary.package_items.transaction_package.toUpperCase()}`} { v.package_id !== 'ps-free' && <sup>5</sup> }
                                </PGTitle1>
                                <PGTitle3 color={v.package_style.secondary}>{dictionary.per_month}</PGTitle3>
                              </>
                            ) : (
                              <>
                                <PGTitle1 className="pb-2" center bold color={v.package_style.primary}>
                                  {`${dictionary.free.toUpperCase()} ${toCurrency(
                                    v.transaction
                                  )} ${dictionary.package_items.transaction_package.toUpperCase()}`}
                                </PGTitle1>
                                {
                                  (v.transaction_fee && !hasValueInArray(packages_not_onward, v.package_id)) &&
                                  (
                                   <>
                                    <PGTitle3 color={v.package_style.secondary}>
                                      {String.format(
                                        dictionary.transaction_onward,
                                        toCurrency(v.transaction + 1)
                                      ).toUpperCase()}
                                    </PGTitle3>
                                    <PGTitle3 color={v.package_style.secondary}>{`${
                                      toCurrency(v.transaction_fee, 2)
                                    } ${v.yearly_fee ? dictionary.credit : dictionary[v.currency || "thb"]}`}</PGTitle3>
                                    <PGTitle3 color={v.package_style.secondary}>
                                      {dictionary.per_transaction_month.toUpperCase()}
                                    </PGTitle3>
                                   </> 
                                  )
                                }
                              </>
                            )}
                          </PlanDetail>
                        </BusinessPlanDetail>
                        {/* MONTHLY FREE CREDIT */}
                        {
                          v.credit ? (
                            <BusinessPlanDetail package_style={v.package_style}>
                              <PGTitle3 color={v.package_style.secondary}>
                                {dictionary.monthly_free_credit} <sup>9</sup>
                              </PGTitle3>
                              <PlanDetail>
                                <PGTitle1 center bold color={v.package_style.primary}>
                                  {toCurrency(v.credit)}
                                </PGTitle1>
                              </PlanDetail>
                            </BusinessPlanDetail>
                          ): null
                        }
                        {/* TRANSACTION */}
                        {notification_items.length ? (
                          <BusinessPlanDetail package_style={v.package_style}>
                            <PGTitle3 color={v.package_style.secondary}>
                              {dictionary.notification_package.toUpperCase()} <sup>3</sup>
                            </PGTitle3>
                            <PlanDetail>
                              {notification_items.map((item) => {
                                return (
                                  <PGTitle3 key={item.item_id} color={v.package_style.secondary}>{`
                                      ${dictionary[item.title_dictionary]} 
                                      ${item.pricing} ${v.yearly_fee ? dictionary.credit : dictionary[v.currency || "thb"]} / 
                                      ${dictionary[item.unit_dictionary]}`}</PGTitle3>
                                );
                              })}
                            </PlanDetail>
                          </BusinessPlanDetail>
                        ) : null}
                      {
                        /* FEATURE */
                        v.feature && v.feature.length > 0 && (
                          <BusinessPlanFeature package_style={v.package_style}>
                            <PGTitle2 className="pb-2" bold color={v.package_style.secondary}>
                              {dictionary.feature_package.toUpperCase()}
                            </PGTitle2>
                              {v.feature.map((value, key) => {
                                return (
                                  <PlanFeatureDisc
                                    color={v.package_style.primary}
                                    key={`${key}_${value}`}
                                  >
                                    <PGTitle3 color={v.package_style.primary}>
                                      {`${
                                        _.find(v.items, { name: value }) &&
                                        _.find(v.items, { name: value })
                                          .max_quantity !== undefined
                                          ? _.find(v.items, { name: value })
                                              .max_quantity
                                          : ``
                                      } ${
                                        dictionary.package_features[value] ||
                                        dictionary[value] ||
                                        value
                                      }`}
                                    </PGTitle3>
                                  </PlanFeatureDisc>
                                );
                              })}
                          </BusinessPlanFeature>
                        )
                      }
                      {addons_list && addons_list.length > 0 && (
                        <BusinessPlanAddon>
                          <PGTitle2 className="pb-2" bold color={v.package_style.secondary}>
                            {dictionary.add_on_package.toUpperCase()}
                          </PGTitle2>
                          {addons_list.map((value, key) => {
                            return (
                              <PlanFeatureGroup key={`${key}_${value.id}`}>
                                <PlanFeature>
                                  <PGTitle3 className="pl-3" color={v.package_style.primary}>
                                    {`${dictionary[value.title_dictionary] || value.title_dictionary}`}
                                  </PGTitle3>
                                </PlanFeature>
                                {
                                (v.package_id === "ps-free-trial-2023" || v.package_id === "ps-free-trial") && value.item_id === "add-broadcast-free-20" ?
                                  <PlanFeatureDetail>
                                    <PGTitle3 color={v.package_style.primary}>
                                      {`- ${dictionary.free} 20 ${dictionary.package_items.transaction_package.toLowerCase()}`}{" "}
                                      <sup>6</sup>
                                    </PGTitle3>
                                    <PGTitle3 color={v.package_style.primary}>
                                      {`- SMS ${dictionary.and} LINE`}
                                    </PGTitle3>
                                  </PlanFeatureDetail> 
                                :
                                value.item_id === "add-broadcast-free" && (
                                  <PlanFeatureDetail>
                                    {broadcast_items.map((item, item_id) => {
                                      if (item.name === "additional_sms_broadcast") {
                                        return (
                                          <PGTitle3 key={item_id} color={v.package_style.primary}>
                                            {`- SMS ${item.pricing} ${v.yearly_fee ? dictionary.credit : dictionary.thb} / ${dictionary.chunk}`}{" "}
                                            <sup>4</sup>
                                          </PGTitle3>
                                        );
                                      } 
                                      return null
                                    })}
                                    {broadcast_items.map((item, item_id) => {
                                      if (item.name === "additional_line_broadcast") {
                                        return (
                                          <PGTitle3
                                            key={item_id}
                                            color={v.package_style.primary}
                                          >{`- LINE `} {item.pricing > 0 ? `${item.pricing} ${v.yearly_fee ? dictionary.credit : dictionary.thb} / ${dictionary.message}` : ''}</PGTitle3>
                                        );
                                      } else if (item.name === "additional_line_broadcast_free") {
                                        return (
                                          <PGTitle3
                                            key={item_id}
                                            color={v.package_style.primary}
                                          >
                                            {`- LINE`}
                                          </PGTitle3>
                                        )
                                      }
                                      return null
                                    })}
                                  </PlanFeatureDetail>
                                )}
                              </PlanFeatureGroup>
                            );
                          })}
                        </BusinessPlanAddon>
                      )}
                      </BusinessPlanSection>
                    </div>
                    <UpgradePlan package_style={v.package_style}>
                      {v.package_id === current_package_id ? (
                        <BusinessDetailChoose current>
                          <i className="pr-2 fas fa-check-circle" />
                          <div>{dictionary.current_usage_package}</div>
                        </BusinessDetailChoose>
                      ) :  business_permission_edit ? (
                        hasValueInArray(packages_free, current_package_id) ? (
                          <BusinessDetailChoose
                            id={`btn-choose_${v.id}`}
                            onClick={() => {
                              v.yearly_fee ? this.toggleContact()
                              : this.toggleModalPackage(v.package_id);
                            }}
                          >
                            <div>
                              {dictionary.package_upgrade} <sup>{ current_package_id === 'ps-free' && 5 }</sup>
                            </div>
                          </BusinessDetailChoose>
                        ) : (
                          hasValueInArray(packages_free, v.package_id) ? null :
                          <BusinessDetailChoose
                            id={`btn-request_to_change_package_${v.id}`}
                            // onClick={() => window.open(process.env.REACT_APP_GOTO_SUPPORT, "_blank")}
                            onClick={() => {
                              v.yearly_fee ? this.toggleContact()
                              : this.toggleModalPackage(v.package_id, true);
                            }}
                          >
                            <div> 
                              { dictionary.request_change_package} <sup>{ current_package_id === 'ps-free' && 5 }</sup> 
                            </div>
                          </BusinessDetailChoose>
                        )
                      ) : null}
                    </UpgradePlan>
                  </BusinessPlanBox>
                </Col>
              );
            })}
          </div>
        </BusinessPlanAll>
        <Remark current_package={current_package_id} dictionary={dictionary} showCreditDetail={showCreditDetail} />
        <BusinessPayment
          isOpen={this.state.isOpenPayment}
          toggle={this.props.toggle}
          toggleModalPackage={this.toggleModalPackage}
          toggleSubscription={this.props.toggleSubscription}
          package_id={this.state.selected_package}
          isChangePackage={this.state.isChangePackage}
        />
        <ContactUsModal
          isOpen={this.state.isOpenContact}
          toggleContact={this.toggleContact}
        />
      </Container>
    );
  }
}

const Remark = ({ current_package, dictionary, showCreditDetail }) => {

  let remark_lists = [
    `1. ${dictionary.member_usage_description}`,
    `2. ${dictionary.transaction_usage_description}`,
    `3. ${dictionary.notification_usage_description}`,
    `4. ${dictionary.chunk_description}`,
    `5. ${dictionary.broadcast_transaction_limit_100}`,
    `6. ${dictionary.broadcast_transaction_usage}`,
    `7. ${dictionary.prices_vat}`,
  ]

  if (showCreditDetail) {
    remark_lists.push(
      `8. ${dictionary.transaction_yearly_usage_description}`,
      `9. ${dictionary.transaction_yearly_credit_usage_description}`
    );
  } else {
    remark_lists.push(`8. ${dictionary.transaction_annual_usage_description}`);
  }

  if (current_package === 'ps-free') {
    remark_lists = [
      `1. ${dictionary.member_usage_description}`,
      `2. ${dictionary.transaction_usage_description}`,
      `3. ${dictionary.notification_usage_description}`,
      `4. ${dictionary.chunk_description}`,
      `5. ${dictionary.prices_vat}`
    ];
  }

  return (
    <div style={{ margin: "0px 24px 24px 24px" }}>
      <Title3 bold className="pb-1">
        {dictionary.remark}
      </Title3>
      {remark_lists.map((v) => (
        <Title4 bold key={v} className="pl-3 pt-1">
          {v}
        </Title4>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language,
    store_usage: state.usage,
    store_packages: state.packages
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PackagesDetail)