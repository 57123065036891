import styled, { css } from 'styled-components'

const Button = styled.button`
  ${props => props.fluid ? 'width: 100%;' : null}  
  border-radius:  ${props => props.pill ? '30px' : '4px'};
  padding: .375rem .75rem;
  min-width: 70px;
  outline:none;
  box-shadow:none;
  cursor: pointer;
  user-select: none;
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.font_m};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_s};
  }

  ${props => props.bold && css` font-weight: ${props.theme.font_bold}; `}
  ${props => props.md
    && css` font-size: ${props => props.theme.font_s}; 
            padding: .375rem .75rem;
            @media (max-width: 990px) {
              font-size: ${props => props.theme.font_xs};
            } `}
  ${props => props.sm
    && css` font-size: ${props => props.theme.font_xs}; 
            padding: .355rem .75rem;
            @media (max-width: 990px) {
              font-size: ${props => props.theme.font_xxs};
            } `}            


  &:disabled{
    background: #eee;
    cursor: not-allowed;
    border: 1px solid #eee; 
    color: ${props => props.theme.secondary};
  }

  &:hover{
    opacity: 0.9; 
  }

  transition: all 0.1s linear;
`

export const PrimaryBtn = styled(Button)`
  background-color: ${props => props.theme.primary};
  border: 1px solid ${props => props.theme.primary};

  ${props => props.invert && css`
    background: transparent;
    color: ${props => props.theme.primary};
    border: 1px solid ${props => props.theme.primary};
    &:hover{
      color: #FFFFFF;
      border: 1px solid #FFFFFF;
      background: ${props => props.theme.primary};
    }
  `}
`

export const TransparentBtn = styled(Button)`
  background: transparent;
  background-color: rgb(0, 0, 0, 0.03);
  border: 1px solid transparent;

  &:hover{
   background-color: rgb(0, 0, 0, 0.08);
  }
`
export const UpgradeBtn = styled(Button)`
  font-weight: 500;
  color: #212529;
  background-color: #ffc107;
  padding: 4px 8px;
  border: 1px solid transparent;

  &:hover{
    opacity: 0.8; 
  }
`

export const SuccessBtn = styled(Button)`
  background-color: ${props => props.theme.green};
  border: 1px solid ${props => props.theme.green};

  ${props => props.invert && css`
    background: transparent;
    color: ${props => props.theme.green};
    border: 1px solid ${props => props.theme.green};
    &:hover{
      color: #FFFFFF;
      border: 1px solid #FFFFFF;
      background: ${props => props.theme.green};
    }
  `}
`

export const DangerBtn = styled(Button)`
  background-color: ${props => props.theme.red};
  border: 1px solid ${props => props.theme.red};

  ${props => props.invert && css`
    background: transparent;
    color: ${props => props.theme.red};
    border: 1px solid ${props => props.theme.red};
    &:hover{
      color: #FFFFFF;
      border: 1px solid #FFFFFF;
      background: ${props => props.theme.red};
    }
  `}
`

export const InvertBtn = styled(Button)`
  background-color: transparent;
  border: 1px solid transparent;
  color: ${props => props.theme.secondary};

  ${props => props.invert && css`
    background: transparent;
    color: ${props => props.theme.secondary};
    border: 1px solid ${props => props.theme.secondary};
    &:hover{
      color: #FFFFFF;
      border: 1px solid #FFFFFF;
      background: ${props => props.theme.secondary};
    }
  `}
`

export const WarningBtn = styled(Button)`
  background-color: ${props => props.theme.orange};
  border: 1px solid ${props => props.theme.orange};

  ${props => props.invert && css`
    background: transparent;
    color: ${props => props.theme.orange};
    border: 1px solid ${props => props.theme.orange};
    &:hover{
      color: #FFFFFF;
      border: 1px solid #FFFFFF;
      background: ${props => props.theme.orange};
    }
  `}
`

export const ButtonGroup = styled.div`
  padding: 0px;
  width: ${props => props.fluid ? 'calc(100% - 1px);' : 'max-content;'}  
  overflow: hidden;

  display: flex;
  align-items: center;
  transition: all 0.1s linear;

  border-radius:  ${props => props.pill ? '30px' : '4px'};
  cursor: pointer;
  color: ${props => props.theme.white};
  font-size: ${props => props.theme.font_m};
  background-color: transparent;
  border: 1px solid ${props => props.theme.primary}; 
  
  ${props => props.bold && css` font-weight: ${props.theme.font_bold}; `}
  ${props => props.md
    && css` font-size: ${props => props.theme.font_s}; 
            @media (max-width: 990px) {
              font-size: ${props => props.theme.font_xs};
            } `}
  ${props => props.sm
    && css` font-size: ${props => props.theme.font_xs}; 
            @media (max-width: 990px) {
              font-size: ${props => props.theme.font_xxs};
            } `}   

  &:disabled{
    background: #eee;
    cursor: not-allowed;
    border: 1px solid #eee; 
    color: ${props => props.theme.secondary};
  }

  &:hover{ 
    opacity: 1; 
  }

  ${props => props.warning
    && css` color: ${props.theme.orange};
            border: 1px solid ${props.theme.orange}; `}

  ${props => props.danger
    && css` color: ${props.theme.red};
            border: 1px solid ${props.theme.red}; `}

  ${props => props.primary
    && css` color: ${props.theme.primary};
            border: 1px solid ${props.theme.primary}; `}

  ${props => props.success
    && css` color: ${props.theme.green};
            border: 1px solid ${props.theme.green}; `}

  > * {
    min-width: 35px;
    padding: 0.375rem 0.7rem;;

    border-width: 0px;
    border-radius: 0px;
    border-left: 1px solid ${props => props.theme.primary};
    &:first-child{ 
      border-left-width: 0px;
    }

    ${props => props.warning && css` border-left: 1px solid ${props.theme.orange}; `}
    ${props => props.danger && css` border-left: 1px solid ${props.theme.red}; `}
    ${props => props.primary && css` border-left: 1px solid ${props.theme.primary}; `}
    ${props => props.success && css` border-left: 1px solid ${props.theme.green}; `}

    &:hover{
      opacity: 1; 
      border-width: 0px;

      &:first-child{
        border-left-width: 0px;
      }
      
      border-left: 1px solid ${props => props.theme.primary};
      ${props => props.warning && css` border-left: 1px solid ${props.theme.orange}; `}
      ${props => props.danger && css` border-left: 1px solid ${props.theme.red}; `}
      ${props => props.primary && css` border-left: 1px solid ${props.theme.primary}; `}
      ${props => props.success && css` border-left: 1px solid ${props.theme.green}; `}
    }
  }
`