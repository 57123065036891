import { CREDENTIAL } from "./../actions_const";

const initState = {
  isLoading: false,
  token_list: []
}

const ReducerCredential = (state = initState, action) => {
  switch (action.type) {
    case CREDENTIAL.IS_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      })

    case CREDENTIAL.GET_CREDENTIALS:
      return Object.assign({}, state, {
        isLoading: false,
        token_list: action.data.tokens || []
      })
      
    default:
      return state
  }
}

export default ReducerCredential;