import _ from "lodash";
import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { PrimaryBtn } from "../../components/Button";
import { Title2 } from "../../components/Font";
class ModalTypeImport extends Component {
	render() {
		let { isOpen, toggle, title, dictionary, onClick, fileExam, subscription, subscriptionItems } = this.props;
		let importType = [
			{
				id: "points",
				title: dictionary.points,
				icon: fileExam ? "fas fa-file-download" : "fab fa-product-hunt",
			},
			{
				id: "amount",
				title: dictionary.amount,
				icon: fileExam ? "fas fa-file-download" : "fas fa-cubes",
			},
		];
		let result =
      _.find(subscription, function (feature) {
        if (feature === "product_management") return true;
      }) || subscriptionItems?.indexOf('add-product_mgmt-free') > -1;
		if (result) {
			importType.push({
				id: "product",
				title: dictionary.product,
				icon: fileExam ? "fas fa-file-download" : "fas fa-boxes",
			});
		}
		return (
			<Modal isOpen={isOpen} toggle={toggle} centered size="sm">
				<ModalHeader toggle={toggle} className="d-flex align-items-center justify-content-center">
					<Title2 bold>{title}</Title2>
				</ModalHeader>
				<ModalBody>
					{importType.map((val, key) => {
						return (
							<PrimaryBtn key={key} bold fluid invert className="my-2 py-2" onClick={(event) => onClick(event, val.id)}>
								<div className="d-flex align-items-center justify-content-between">
									{val.title}
									<i className={`${val.icon}`} style={{ fontSize: "20px" }} />
								</div>
							</PrimaryBtn>
						);
					})}
				</ModalBody>
			</Modal>
		);
	}
}

export default ModalTypeImport;
