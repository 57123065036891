import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loading from '../../components/Loading'
import Cookies from 'js-cookie'
import { DEBUG } from '../../libs/utils'
import { userLogout, cancelAuthStateChange } from '../../redux/actions/action_permission'
import { TitleMain1, Title2 } from '../../components/Font'
import { ContainerWizard, WizardImageLogo, WizardBox, WizardBoxDetail, BusinessAvatar, BusinessListItem, LogoutSection, SendAgainText } from './selectBusiness.style'
import PointSpotLogo from '../../assets/img/logo.svg'
import { selectBusinessDropdownlist } from '../../redux/actions/action_business'
import OtpAuthen from './OtpAuthen'
import { getCookie } from '../../libs/cookies'
import { resetErrorEmailOTP, sendEmailOTP } from '../../redux/actions/action_verify_otp'

export default function SelectBusiness() {
  const dispatch = useDispatch();
  const { list, current, isFromDropdown } = useSelector((state) => state.business);
  const { dictionary, locale } = useSelector((state) => state.language);
  const { emailOTP } = useSelector((state) => state.verifyotp);
  const { isLogin, profile, tabUUIDStore } = useSelector((state) => state.permission);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowOTP, setIsShowOTP] = useState(false);
  const [mainTitle, setMainTitle] = useState(dictionary.choose_your_business)
  const [currentBusiness, setCurrentBusiness] = useState('');
  const loginTabUUID = getCookie('_tabUUID');
  const tabUUID = sessionStorage.getItem('tabUUID') || tabUUIDStore;

  useEffect(() => {
    if (isLogin) {
      if (tabUUID !== loginTabUUID) {
        dispatch(cancelAuthStateChange());
      } else if ((list.length === 1 || isFromDropdown) && current?.two_factor_authen && !current?.otp_verify) {
        selectBusiness({ business_info: current });
      }
    } else {
      if (tabUUID !== loginTabUUID) {
        dispatch(cancelAuthStateChange());
      } else if (!isFromDropdown) {
        logout();
      } else if (isFromDropdown && current?.two_factor_authen && !current?.otp_verify) {
        selectBusiness({ business_info: current });
      }
    }
    // eslint-disable-next-line
  }, [isFromDropdown, current, loginTabUUID])

  const logout = () => { 
    setIsLoading(true);
    DEBUG('logout');
    for (let key in localStorage) {
      delete localStorage[key]
    }
    Cookies.remove('_token');
    Cookies.remove('_email');
    dispatch(userLogout());
  }

  const selectBusiness = ({ business_info }) => {
    let { business_code, two_factor_authen, otp_verify } = business_info
    if (two_factor_authen && !otp_verify) {
      setIsShowOTP(true);
      setCurrentBusiness(business_info);
      setMainTitle(dictionary.confirm_sending_otp);
      dispatch(sendEmailOTP({ email: profile.email, lang: locale }));
    } else {
      dispatch(selectBusinessDropdownlist(business_code));
    }
  }

  const backToSelectBusiness = () => {
    dispatch(resetErrorEmailOTP());
    setIsShowOTP(false);
  }

  return (
    <div>
      {
        isLoading 
        ? <Loading /> 
        :
          <ContainerWizard className="justify-content-center">
            <div className='px-3 d-flex flex-column align-items-center justify-content-center'>
              <WizardImageLogo alt='' src={PointSpotLogo} />
              <TitleMain1 bold className='mx-2'>
                {mainTitle}
              </TitleMain1>
              <WizardBox>
                {
                  isShowOTP 
                    ? <OtpAuthen 
                        business_info={currentBusiness}
                      />
                    : <WizardBoxDetail>
                        {
                          list.length > 0 && list.map((info, index) => {
                            let { business_name, business_logo_url } = info
          
                            return (
                              <BusinessListItem key={index} onClick={() => selectBusiness({ business_info: info })}>
                                <div style={{ width: '50px', height: '50px'}}>
                                  <BusinessAvatar alt="" src={business_logo_url} />
                                </div>
                                <div className='pl-4'>
                                  <Title2>{business_name}</Title2>
                                </div>
                              </BusinessListItem>
                            )
                          })
                        }
                      </WizardBoxDetail>
                }
                <LogoutSection className='border-top'>
                  <SendAgainText
                    disabled={emailOTP.isProcess}
                    onClick={() => list.length === 1 || !isShowOTP ? logout() : backToSelectBusiness()}
                  >
                    {
                      list.length === 1 || !isShowOTP
                      ? dictionary.sign_in_with_another_account
                      : dictionary.back
                    }
                  </SendAgainText>
                </LogoutSection>
              </WizardBox>
            </div>
          </ContainerWizard>
      }
    </div>
  )
}