import { SETTINGPOINTS } from '../actions_const'

let initState = {
  isLoading: false,
  config: {},
}

const ReducerPointsSetting = (state = initState, action) => {
  switch (action.type) {
    case SETTINGPOINTS.PROCESS_POINTS_SETTING_START:
      return Object.assign({}, state, { isLoading: true })
    case SETTINGPOINTS.GET_POINTS_SETTING:
      return Object.assign({}, state, { isLoading: false, config: action.data })
    case SETTINGPOINTS.UPDATE_POINTS_SETTING:
      return Object.assign({}, state, { isLoading: false, config: Object.assign({}, state.config, action.data) })
    case SETTINGPOINTS.ERROR_POINTS_SETTING:
      return Object.assign({}, state, { isLoading: false, config: {} })
    default:
      return state
  }
}

export default ReducerPointsSetting;