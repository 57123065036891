import styled from 'styled-components'

export const DropdownIcon = styled.div`
  top: 1px;
  cursor: pointer;
  color: #F0F0F0;
  margin: 0px 15px 0px 0px;
  @media (max-width: 376px) {
     font-size: 15px;
     margin: 0px 11px 0px 0px;
  }
  transition: all 0.25s ease;
  &:hover{ 
    color: #FFF; 
  }
`
export const DropdownIconSpace = styled.div`
  top: 1px;
  cursor: pointer;
  color: #F0F0F0;
  font-size: 18px;
  margin: 0px 15px 0px 0px;
  @media (max-width: 376px) {
     font-size: 15px;
     margin: 0px 11px 0px 0px;
  }
  transition: all 0.25s ease;
  &:hover{ 
    color: #FFF; 
  }
`
export const DropdownIconLanguage = styled.div`
  font-size: 12px;
  cursor: pointer;
  background-color: #F0F0F0;
  color: #546174;
  text-align: center;
  line-height: 21px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  @media (max-width: 376px) {
     font-size: 14px;
     margin: 0px 11px 0px 0px;
  }
  transition: all 0.25s ease;
  &:hover{
    background-color: #FFF;
  }
`
export const DropdownCard = styled.div`
  position: absolute; 
  top: 43px; 
  right: 0px;
  min-width: 290px;
  min-heigth: 100px;
  background: #FFF;
  z-index: 1001;
  border: 1px solid #ccc;
  border-color: rgba(0,0,0,.2);
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
`

export const DropdownCardMessage = styled.div`
  position: fixed;
  top: 53px; 
  right: 30px;
  min-width: 30%;
  min-heigth: 100px;
  max-height: 80vh;
  overflow-x: scroll;
  background: #FFF;
  z-index: 1001;
  border: 1px solid #ccc;
  border-color: rgba(0,0,0,.2);
  box-shadow: 0 2px 10px rgba(0,0,0,.2);    
`

export const DropdownCardBox = styled.div`
  position: absolute;
  top: 43px; 
  right: 0px;
  min-width: 175px;
  min-heigth: 100px;
  background: #FFF;
  z-index: 1001;
  border: 1px solid #ccc;
  border-color: rgba(0,0,0,.2);
  box-shadow: 0 2px 10px rgba(0,0,0,.2);
`

export const DropdownAvatar = styled.div`
  width: 100%;
`
export const DropdownItem = styled.div`
  width: 100%;
  min-width: 200px;

  cursor: pointer;
  padding: 12px 15px;
  font-size: ${props => props.theme.font_m};
  color:  ${props => props.theme.secondary};
  &:hover{
    color: ${props => props.theme.primary};
    padding-left: 10px;
    background: #F0F0F0;
    border-left: 5px solid #546174;
  }
`

export const DropdownItemName = styled.div`
  display: block;
  cursor: pointer;
  margin-left: 10px;
`

export const DropdownItemMessage = styled.div`
  display: block;
  cursor: pointer;
  margin: 0px 0px 0px 15px;

  font-size: ${props => props.theme.font_m};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_s};
  }
`

export const DropdownItemIcon = styled.div`
  padding: 0px 15px;
  display: inline-block;
`

export const Avatar = styled.div`
  border-radius: 50%;  
  width: 95px;
  height: 95px;  
  background: url('${props => props.src}');
  background-size: cover;
`
export const AvatarBadge = styled.div`
  width: 32px;
  height: 32px;  
  @media (max-width: 376px) {
    width: 28px;
    height: 28px; 
  }
  transition: all 0.25s ease;

  border-radius: 50%;  
  background: url('${props => props.src}');
  background-size: cover;
`

export const BadgeIconSpace = styled.div`
  width: 21px;
  height: 21px;
  font-size: 14px;
  margin: 0px 15px 0px 0px;
  @media (max-width: 376px) {
    width: 18px;
    height: 18px;
    font-size: 12px;
    margin: 0px 11px 0px 0px;
  }

  transition: all 0.25s ease;
  cursor: pointer;
  font-weight: 600;
  border-radius: 50%;

  color: #FFF;
  background: ${props => props.theme.info};

  display: flex;
  align-items: center;
  justify-content: center;
`