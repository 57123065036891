import React, { useState } from "react";
import { Title2 } from "../../components/Font";
import {
  BoxHead,
  BoxProcess,
  BoxProcessHead,
  BoxDetail,
} from "./verifyBusinessHash.style";
import { Row, Col } from "reactstrap";

export default function TelInfo({ status, onCheck, info }) {
  const [expand, setExpand] = useState(false);

  return (
    <BoxProcess select={!status}>
      <BoxProcessHead select={!status} pointer>
        <div onClick={onCheck}>
          {status ? (
            <i className="far fa-circle pr-2"></i>
          ) : (
            <i className="fas fa-times-circle pr-2"></i>
          )}
        </div>
        <Title2 className="flex-grow-1" onClick={() => setExpand(!expand)} bold white>
          รายละเอียดเบอร์โทรศัพท์
        </Title2>
        <div onClick={() => setExpand(!expand)}>
          {expand ? (
            <i className="fas fa-chevron-up"></i>
          ) : (
            <i className="fas fa-chevron-down"></i>
          )}
        </div>
      </BoxProcessHead>
      {expand ? (
        <div>
          {info.status_phone !== null ? (
            <div style={{ padding: "20px" }}>
              <Row>
                <Col>
                  <BoxHead>
                    <Title2 bold>
                      {" "}
                      <span className="text-danger">* </span>
                      เบอร์โทรศัพท์ที่ใช้ในการยืนยันธุรกิจ
                    </Title2>
                  </BoxHead>
                  <BoxDetail>
                    <Title2 bold>{info.phone_verify}</Title2>
                  </BoxDetail>
                </Col>
              </Row>
            </div>
          ) : (
            <BoxHead center>
              <Title2 bold danger>
                ไม่มีข้อมูลการยืนยันเบอร์โทรศัพท์
              </Title2>
            </BoxHead>
          )}
        </div>
      ) : null}
    </BoxProcess>
  );
}
