import axios from "axios";
import { getCookie } from "../../libs/cookies";
import { ERROR } from "../../libs/utils";
import { CUSTOM_FORM, UPDATE_BUSINESS_CONFIG } from "../actions_const";
import { businessAPI } from "../end_point";

export const getCustomFormSetting = ({ business_code, with_archive = false }, callback) => {
  let isFunc = typeof callback === 'function'
  return (dispatch) => {
    dispatch({ type: CUSTOM_FORM.IS_LOADING })
    axios({
      headers: { Authorization: getCookie("_token") },
      method: "get",
      url: `${businessAPI}/${business_code}/customForm?with_archive=${with_archive}`
    })
      .then(({ data }) => {
        isFunc && callback(null, data.data)
        return dispatch({ type: with_archive ? CUSTOM_FORM.GET_CUSTOM_FORM_TITLE_ALL : CUSTOM_FORM.GET_CUSTOM_FORM_TITLE, data: data.data });
      })
      .catch(err => {
        ERROR(err)
        return isFunc && callback(err)
      });
  };
};

export const updateCustomFormConfig = (business_code, custom_form, callback) => {
  let isFunc = typeof callback === 'function'
  return (dispatch) => {
    axios({
      headers: { Authorization: getCookie("_token") },
      method: "put",
      url: `${businessAPI}/${business_code}/config/customForm`,
      data: { custom_form }
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Update Custom Form Config'))
        
        isFunc && callback(null, data.data)
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(err => {
        return isFunc && callback(new Error('ErrorUpdate Custom Form  Config'))
      });
  };
}

export const updateCustomFormSetting = ({ business_code, form }, callback) => {
  let isFunc = typeof callback === 'function'
  return (dispatch) => {
    axios({
      headers: { Authorization: getCookie("_token") },
      method: "post",
      url: `${businessAPI}/${business_code}/customForm`,
      data: form
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Update Custom Form Setting'))
        
        return isFunc && callback(null, data)
      })
      .catch(err => {
        return isFunc && callback(new Error('Error Update Custom Form'))
      });
  };
}

export const getCustomFormValue = (business_code, memberBadgeCode, callback) => {
  return (dispatch) => {
    dispatch({ type: CUSTOM_FORM.IS_LOADING_CUSTOM_FORM_VALUE })
    axios({
      headers: { Authorization: getCookie("_token") },
      method: "get",
      url: `${businessAPI}/${business_code}/customForm/${memberBadgeCode}`
    })
      .then(({ data }) => {
        dispatch({ type: CUSTOM_FORM.GET_CUSTOM_FORM_VALUE, data: data.data });
      })
      .catch(err => {
        if (typeof callback === 'function') callback(err)
        ERROR(err)
      });
  };
};

export const editCustomFormValue = ({ business_code, member_badge_code, value }, callback) => {
  return (dispatch) => {
    dispatch({ type: CUSTOM_FORM.IS_LOADING_UPDATE })
    axios({
      headers: { Authorization: getCookie("_token") },
      method: "put",
      url: `${businessAPI}/${business_code}/customForm`,
      data: {
        member_badge_code,
        value
      }
    })
      .then(({ data }) => {
        if (typeof callback === 'function') callback(null, data.data)
        dispatch({ type: CUSTOM_FORM.GET_CUSTOM_FORM_VALUE, data: data.data })
      })
      .catch(err => {
        if (typeof callback === 'function') callback(err)
        ERROR(err)
      });
  };
};

export const deleteCustomFormSetting = ({ business_code, form_id }, callback) => {
  return (dispatch) => {
    axios({
      headers: { Authorization: getCookie("_token") },
      method: "delete",
      url: `${businessAPI}/${business_code}/customForm`,
      data: {
        form_id
      }
    })
      .then(({ data }) => {
        if (typeof callback === 'function') callback(null, data.data)
        dispatch({ type: CUSTOM_FORM.GET_CUSTOM_FORM_TITLE, data: data.data })
      })
      .catch(err => {
        if (typeof callback === 'function') callback(err)
        ERROR(err)
      });
  };
};

export const updateCustomFormSettingShowOnMemberCard = ({ business_code, form_id }, callback) => {
  let isFunc = typeof callback === 'function'
  return () => {
    axios({
      headers: { Authorization: getCookie("_token") },
      method: "post",
      url: `${businessAPI}/${business_code}/customForm/showOnMemberCard`,
      data: {
        form_id
      }
    })
    .then(({ data }) => {
      if (!data.result)
        return isFunc && callback(new Error('Error Update Custom Form Setting'))
      
      return isFunc && callback(null, data)
    })
    .catch(err => {
      return isFunc && callback(new Error('Error Update Custom Form'))
    });
  };
};