import React from "react";
import "./MemberCard.css";
import { darken } from "polished";

function TimeSpin(props) {
  let { primary } = props;

  return (
    <div id="countdown">
      <div id="countdown-number" style={{ color: darken(0.1, primary) }}>
        43
      </div>
      <svg>
        <circle r="18" cx="20" cy="20" stroke={darken(0.1, primary)}></circle>
      </svg>
    </div>
  );
}
export default TimeSpin;
