import { useEffect, useRef } from 'react'

export const usePrevious = (prev) => {
  const previous = useRef()

  useEffect(() => {
    previous.current = prev
  }, [prev])

  return previous.current
}
