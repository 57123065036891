import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Label from './Label'
import { Title3 } from '../Font'
import { PrimaryBtn } from '../Button'
import { Input } from 'reactstrap'
import { AbsoluteBox, LabelContainer, Labelbox, IconLabelSearch } from './label.style'
import { getBusinessLabel } from '../../redux/actions/action_business'

class LabelSelect extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            labelList: [],
            labelSelect: [],
            labelSearch: '',
            isToggle: false,
            isLoading: false,
        }
    }

    componentDidMount() {
        let { store_business: { current: { business_code } } } = this.props
        let { type } = this.props

        this.setState({ isLoading: true })
        this.props.getBusinessLabel({ business_code, excludeArchive: true, tag_type: type || '' }, (err, data) => {
            if (this._isMounted)
                return null
            this.setState({ isLoading: false, labelList: data })
        })

        window.document.addEventListener('click', this.clickOutBtn)
    }

    componentWillUnmount() {
        this._isMounted = true
        window.document.removeEventListener('click', this.clickOutBtn)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.label !== this.props.label)
            this.setState({ labelSelect: this.props.label })
    }

    onChangeSearch = (event) => {
        let { name, value } = event.target
        this.setState({ [name]: value })
    }

    clearSearch = () => {
        this.setState({ labelSearch: '' })
    }

    clickOutBtn = (event) => {
        let { id } = this.props
        let element1 = window.document.getElementById(`LabelButton_${id}`)
        let element2 = window.document.getElementById(`SelectLabelBox_${id}`)

        if (element1 && element2) {
            var isClickBtn = element1.contains(event.target);
            var isClickBox = element2.contains(event.target);
            if (!isClickBtn && !isClickBox && this.state.isToggle) this.toggleSelectLabel()
        }
    }

    toggleSelectLabel = () => {
        this.setState({ isToggle: !this.state.isToggle }, () => {
            if (!this.state.isToggle)
                this.clearSearch()
        })
    }

    selectLabel = (label) => {
        if (this.state.labelSelect.map(v => v.tag_id).indexOf(label.tag_id) >= 0)
            return this.setState({ labelSelect: this.state.labelSelect.filter(v => v.tag_id !== label.tag_id) }, () => {
                this.props.onChange(this.state.labelSelect)
            })

        this.setState({ labelSelect: [...this.state.labelSelect, label] }, () => this.props.onChange(this.state.labelSelect))
    }

    render() {
        let { store_language: { dictionary } } = this.props
        let { isToggle, labelList, isLoading, labelSelect, labelSearch } = this.state
        let { position, btnName, id } = this.props

        if (!labelList || (labelList && labelList.length === 0)) return null

        if (labelSearch)
            labelList = labelList.filter(val => val.tag_name.toLowerCase().indexOf(labelSearch.toLowerCase()) > -1)

        return <div>
            <div className='position-relative'>
                <PrimaryBtn id={`LabelButton_${id}`} sm bold style={{ fontSize: '10px' }} onClick={!isLoading ? this.toggleSelectLabel : null}>
                    {
                        isLoading
                            ? <i className="fas fa-spinner fa-pulse" />
                            : btnName || <div><i className="fas fa-plus pr-1" />{dictionary.label}</div>
                    }
                </PrimaryBtn>
                {
                    isToggle
                    && <AbsoluteBox hidden={!isToggle} id={`SelectLabelBox_${id}`} position={position ? position : window.innerWidth > 990 ? 'bottom' : 'top'}>
                        <div className='border-bottom d-flex align-items-center justify-content-between' style={{ padding: '0.75rem' }}>
                            <Title3 bold>{dictionary.select_label}</Title3>
                            <Title3 bold className='pl-2 pointer' onClick={this.toggleSelectLabel}><i className="fas fa-times fa-lg" /></Title3>
                        </div>
                        <div className='p-1 position-relative border-bottom'>
                            <IconLabelSearch pointer={Boolean(labelSearch)} className={`${Boolean(labelSearch) ? 'fas fa-times' : 'fas fa-search'}`} onClick={Boolean(labelSearch) ? this.clearSearch : null} />
                            <Input
                                type='text'
                                name='labelSearch'
                                autoFocus={isToggle}
                                value={labelSearch}
                                autoComplete='off'
                                style={{ paddingRight: '2rem' }}
                                className='form-control-xs'
                                onChange={this.onChangeSearch} />
                        </div>
                        <LabelContainer>
                            {
                                labelList && labelList.length > 0
                                    ? labelList
                                        .sort((a, b) => a.tag_name.localeCompare(b.tag_name))
                                        .map((val) => {
                                            return <Labelbox key={val.tag_id}>
                                                <Label
                                                    pointer
                                                    type={val.tag_type}
                                                    check={labelSelect.map(v => v.tag_id).indexOf(val.tag_id) >= 0}
                                                    name={val.tag_name}
                                                    color={val.tag_style.tag_color}
                                                    onClick={() => this.selectLabel(val)} />
                                            </Labelbox>
                                        })
                                    : <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                        <Title3 secondary center>{dictionary.label_not_found}</Title3>
                                    </div>
                            }
                        </LabelContainer>
                    </AbsoluteBox>
                }
            </div>
            {
                (labelSelect && labelSelect.length > 0)
                && <div className='w-100 d-flex align-items-center flex-wrap mt-2' style={{ maxHeight: '90px', overflowY: 'scroll', overflowX: 'hidden' }}>
                    {
                        labelSelect.map((v) => {
                            return <Label key={v.tag_id} type={v.tag_type} formLabel name={v.tag_name} color={v.tag_style.tag_color} style={{ margin: '5px 2px' }} />
                        })
                    }
                </div>
            }
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        store_language: state.language,
        store_business: state.business,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({
        getBusinessLabel,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelSelect)
