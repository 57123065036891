import React from 'react'
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap'

import { HighNumber } from "./MemberBoard.styled";
import LoadingBox from '../Loading'

class MemberBoardPresenter extends React.Component {
  render() {
    let { store_members, memberType } = this.props
    memberType = memberType || 'member'

    if (!store_members || !store_members.stats[memberType]) return <LoadingBox type={'grow'} />

    return (
      <Card>
        <CardHeader>
          <CardTitle>Total {memberType}</CardTitle>
        </CardHeader>
        <CardBody>
          <HighNumber className={memberType === 'engaged' ? 'text-success' : 'text-warning'}>
            {store_members.stats[memberType].toLocaleString()}
          </HighNumber>
        </CardBody>
      </Card>
    )
  }
}

export default MemberBoardPresenter