import React from "react";
import { Title3 } from "../Font";

const BoxItemApproval = (props) => {
  if (!props.state) return null;
  if (props.state === "pending") {
    return (
      <div
        style={{
          width: "100%",
          padding: "10px",
          border: "2px solid #B6B5B5",
          boxShadow: "0px 0px 15px -5px #BBB",
          backgroundColor: "#FFFAF0",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        <Title3 bold third={true} style={{ color: "#546174" }}>
          {props.value} ({props.state[0].toUpperCase() + props.state.slice(1)})
        </Title3>
      </div>
    );
  } else if (props.state === "approved") {
    return (
      <div
        style={{
          width: "100%",
          padding: "10px",
          border: "2px solid #B6B5B5",
          boxShadow: "0px 0px 15px -5px #BBB",
          backgroundColor: "#86b874",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        <Title3 bold third={true} style={{ color: "#fff" }}>
          {props.value} ({props.state[0].toUpperCase() + props.state.slice(1)})
        </Title3>
      </div>
    );
  }
};

export default BoxItemApproval;
