import React, { useState,useEffect,useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Modal, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";

import { getUsageRecord } from "./../../redux/actions/action_usage";
import { getBusinessSubscription } from "../../redux/actions/action_package";

import { InvertBtn } from "../Button";
import { Title2, Title3 } from "../Font";
import Loading from "../Loading";
import ModalBodyBusinessPackage from "./SubscriptionDetail/Components/ModalBodyBusinessPackage";
import ModalBodyBusinessUsageOverview from "./SubscriptionDetail/Components/ModalBodyBusinessUsageOverview";
import ModalBodyBusinessUsageOverageDoughnut from "./SubscriptionDetail/Components/ModalBodyBusinessUsageOverageDoughnut";

const BusinessSubscriptionNewPackageModal = (props) => {
  const dispatch = useDispatch();
  const { dictionary } = useSelector((state) => state.language);
  const { current } = useSelector((state) => state.business);
  const { subscription } = useSelector((state) => state.packages);
  const period_end_string = moment(new Date()).format("LLL");
  const [isLoading, setIsLoading] = useState(false);
  const { itemsUsage } = subscription;
  const [key, setKey] = useState(0);
  const overageTransaction = itemsUsage.find((usage) => usage.title_dictionary === "overage_transaction");

  const refreshUsage = useCallback(() => {
    setIsLoading(true);
    dispatch(getUsageRecord(current.business_code, (err1, data1) => {
      if (!err1) {
        dispatch(getBusinessSubscription(current.business_code, (err2, data2) => {
          setIsLoading(false);
        }));
      } else {
        setIsLoading(false);
      }
    }));
  }, [dispatch, current.business_code]);

  useEffect(() => {
    refreshUsage();
  }, [refreshUsage]);


  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [overageTransaction]);

  return (
    <Modal centered size="xl" fade={true} isOpen={props.isOpen} style={{ maxWidth: '70%',margin: '40px auto' }}>
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center">
          <Title2 bold>{dictionary.usage_detail}</Title2>
          <div className="d-flex">
            <Title3 style={{ color: "#B2B9C3" }}>{`${dictionary.data_on} ${period_end_string}`}</Title3>
            <Title3 link onClick={() => refreshUsage()}>
              &nbsp;<i className="fas fa-redo-alt"></i>
            </Title3>
          </div>
        </div>
      </ModalBody>
      <ModalBody style={{ backgroundColor: "#E8EBED" }} >
        <Container className="pl-4 pr-4">
          <ModalBodyBusinessPackage togglePackage={props.togglePackage} packageItems={props.packageItems} />
          {isLoading ? (
            <Loading />
          ) : (
            <React.Fragment key={key}>
              <ModalBodyBusinessUsageOverview  />
              {overageTransaction?.totalCreditUsage > 0 &&
                <ModalBodyBusinessUsageOverageDoughnut/>
              }
            </React.Fragment>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <InvertBtn onClick={() => props.toggle()}>
          <Title3 bold>{dictionary.close}</Title3>
        </InvertBtn>
      </ModalFooter>
    </Modal>
  );
};

export default BusinessSubscriptionNewPackageModal;