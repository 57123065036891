import { TRANSACTIONS } from '../actions_const'

let initSate = {
  profile: [],
  balance: {},
  queryLimit: 20,
  // currentPage: 0,
  // currentPageFilter: null,
  isFetching: true,
  hiddenSearch: false,
  transactions: [],
  transactionsTotal: 0,
  transactionsFilter: [],
  transactionsFilterTotal: 0,
  transactionsMemberDetail: [],
  transReedemRewards: { isLoading: false, list: [] },
  isLoading: false,
  isLoading2: false,
}

const ReducerTransaction = (state = initSate, action) => {
  switch (action.type) {
    case TRANSACTIONS.GET_LIST:
      return Object.assign({}, state, {
        isFetching: action.isFetching || false,
        transactions: action.data,
        transactionsTotal: action.total,
        // currentPage: Math.ceil(parseInt(action.offset, 10) / parseInt(state.queryLimit, 10)),
      })

    case TRANSACTIONS.GET_FILTER_LIST:
      return Object.assign({}, state, {
        isFetching: action.isFetching || false,
        transactionsFilter: action.data,
        transactionsFilterTotal: action.total,
        // currentPageFilter: Math.ceil(parseInt(action.offset, 10) / parseInt(state.queryLimit, 10)),
      })

    case TRANSACTIONS.CLEAR_STATE:
      return Object.assign({}, state, {
        profile: [],
        // currentPage: 0,
        isFetching: true,
        // hiddenSearch: false,
        transactions: [],
        transactionsTotal: 0,
        transactionsFilter: [],
        transactionsFilterTotal: 0,
        // currentPageFilter: null,
      })

    case TRANSACTIONS.GET_MEMBER_PROFILE:
      return Object.assign({}, state, {
        profile: action.data,
        isFetching: action.data.length > 0,
        // hiddenSearch: action.data.length > 0 ? true : false,
      })

    case TRANSACTIONS.CLEAR_SEARCH:
      return Object.assign({}, state, {
        profile: [],
        balance: {},
        // currentPageFilter: null,
        // hiddenSearch: false,
        transactionsFilter: [],
        transactionsFilterTotal: 0
      })

    case TRANSACTIONS.TRANSACTIONS_LOADING:
      return Object.assign({}, state, {
        isLoading: true
      })

    case TRANSACTIONS.TRANSACTIONS_LOADING2:
      return Object.assign({}, state, {
        isLoading2: action.data
      })

    case TRANSACTIONS.ACTIVATE_CODE:
      let _index = state.transactions.findIndex(v => { return v.trans_id === action.trans_id })
      let _index_filter = state.transactionsFilter.findIndex(v => { return v.trans_id === action.trans_id })
      if (action.data.result) {
        if (state.transactions.length > 0 && _index > -1) {
          state.transactions[_index]['trans_activate_date'] = action.data && action.data.data && action.data.data.trans_activate_date
          state.transactions[_index]['trans_activate_by'] = action.data && action.data.data && action.data.data.trans_activate_by
        }
        if (state.transactionsFilter.length > 0 && _index_filter > -1) {
          state.transactionsFilter[_index_filter]['trans_activate_date'] = action.data && action.data.data && action.data.data.trans_activate_date
          state.transactionsFilter[_index_filter]['trans_activate_by'] = action.data && action.data.data && action.data.data.trans_activate_by
        }
      }
      return Object.assign({}, state, {
        isLoading2: false,
        transactions: state.transactions,
        transactionsFilter: state.transactionsFilter
      })

    case TRANSACTIONS.GET_MEMBER_BALANCE:
      return Object.assign({}, state, {
        balance: action.data.data
      })

    case TRANSACTIONS.GET_TRANSACTION_WITH_TRANDID:
      return Object.assign({}, state, {
        isLoading: false,
        transactionsMemberDetail: action.data.data
      })

    case TRANSACTIONS.CLEAR_TRANSACTION_WITH_TRANDID:
      return Object.assign({}, state, {
        transactionsMemberDetail: []
      })

    case TRANSACTIONS.TOGGEL_TRANSACTION_PERSONAL:
      return Object.assign({}, state, {
        hiddenSearch: action.data
      })

    case TRANSACTIONS.GET_REDEEM_REWARDS:
      return Object.assign({}, state, {
        transReedemRewards: Object.assign({}, {
          list: (action && action.data) || state.transReedemRewards.list || []
        })
      })

    case TRANSACTIONS.UPDATE_REDEEM_REWARDS:
      let update_tran = state.transReedemRewards.list.map(trans => {
        let updated = action && action.data.length > 0 && action.data.filter(f => f.id === trans.trans_ecard)
        return updated && updated.length > 0 ? Object.assign({}, trans, { delivery_status: updated[0].delivery_status }) : trans
      })
      return Object.assign({}, state, {
        transReedemRewards: Object.assign({}, {
          list: update_tran || state.transReedemRewards.list
        })
      })

    case TRANSACTIONS.SET_EXPORT_POINT_TRANSACTION_CANCEL_SOURCE: {
      return Object.assign({}, state, {
        exportPointTransactionCancelSource: action.data.source,
      });
    }

    default:
      return state
  }
}


export default ReducerTransaction