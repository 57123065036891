import axios from './../axios_request'
import { JOBS } from '../actions_const'
import { businessAPI } from '../end_point'
import { getCookie } from '../../libs/cookies'

export const createCronjob = (business_code, data, callback) => {
  return dispatch => {
    let route = `${businessAPI}/${business_code}/jobs`;
    if (data.type === 'membercard') {
      route += '/memberCard'
    }
    dispatch({ type: JOBS.IS_PROCESS, data: true })
    axios({
      method: 'post',
      url: `${route}`,
      headers: { Authorization: getCookie("_token") },
      data: data
    })
      .then(res => {
        dispatch({ type: JOBS.IS_PROCESS, data: false })
        if (res.data.result) {
          if (typeof callback === 'function') {
            callback(res.data.data[0].id)
          }
        }
      })
      .catch(error => dispatch({ type: JOBS.IS_ERROR, error: error }))
  }
}

export const getCronjobs = (business_code, limit, offset, type = "point") => {
	return (dispatch) => {
		let route = `${businessAPI}/${business_code}/jobs`;
		let initial = false
    if (type === "membercard") {
			route += "/memberCard";
		} else {
			if (window.location.pathname === "/jobsMemberCard") {
				initial = true;
			}
		}

		dispatch({ type: JOBS.IS_LOADING });
		axios({
			method: "get",
			url: `${route}?limit=${limit}&offset=${offset}&initial=${initial}`,
			headers: { Authorization: getCookie("_token") },
		})
			.then((res) => {
				if (res.data.result) {
					dispatch({ type: JOBS.GET_CRONJOBS, data: res.data });
				}
			})
			.catch((error) => dispatch({ type: JOBS.IS_ERROR, error: error }));
	};
};

export const getCronjobDetail = (business_code, cronjob_id, limit, offset, type = "point", callback) => {
	return (dispatch) => {
		let route = `${businessAPI}/${business_code}/jobs`;
		let initial = false;
		if (type === "membercard") {
			route += "/memberCard";
		} else {
			if (window.location.pathname.includes("/jobsMemberCard")) {
				initial = true;
			}
		}
		dispatch({ type: JOBS.IS_LOADING });
		axios({
			method: "get",
			url: `${route}/${cronjob_id}?limit=${limit}&offset=${offset}&initial=${initial}`,
			headers: { Authorization: getCookie("_token") },
		})
			.then((res) => {
				if (!res.data.result) {
					if (typeof callback === "function") callback("result error");
					return dispatch({ type: JOBS.GET_CRONJOBS_DETAIL, data: [] });
				}
				if (typeof callback === "function") callback(null, res.data);
				dispatch({ type: JOBS.GET_CRONJOBS_DETAIL, data: res.data });
			})
			.catch((error) => {
				if (typeof callback === "function") callback(error);
				dispatch({ type: JOBS.IS_ERROR, error: error });
			});
	};
};

export const clearCronjobDetail = () => {
  return dispatch => dispatch({ type: JOBS.INIT_STATE_JOB_DETAIL })
}

export const updateCronjobStaus = (business_code, cronjob_id, method, type, credit, overTx, callback) => {
	return (dispatch) => {
		let initial = false;
    let route = `${businessAPI}/${business_code}/jobs`;
		if (type === "membercard") {
			route += "/memberCard";
		} else {
			if (window.location.pathname.includes("/jobsMemberCard")) {
				initial = true;
			}
		}

		dispatch({ type: JOBS.IS_PROCESS, data: true });
		axios({
			method: "put",
			url: `${route}/${cronjob_id}`,
			headers: { Authorization: getCookie("_token") },
			data: { method, initial, credit, overTx },
		})
			.then((res) => {
				if (!res.data.result) {
					if (typeof callback === "function") callback(res.data);
					dispatch({ type: JOBS.IS_ERROR });
				}
				if (typeof callback === "function") callback(null, res.data);
				dispatch({ type: JOBS.UPDATE_CRONJOBS_STATUS, data: res.data.data });
			})
			.catch((error) => {
				if (typeof callback === "function") callback(error);
				dispatch({ type: JOBS.IS_ERROR, error: error });
			});
	};
};

export const cancelCronjob = (business_code, cronjob_id, type, callback) => {
  return dispatch => {
    let route = `${businessAPI}/${business_code}/jobs`;
		let initial = false;
    if (type === 'membercard') {
      route += '/memberCard'
    } else {
			if (window.location.pathname.includes("/jobsMemberCard")) {
				initial = true;
			}
		}
    
    dispatch({ type: JOBS.IS_PROCESS, data: true })
    axios({
      method: 'delete',
      url: `${route}/${cronjob_id}`,
      headers: { Authorization: getCookie("_token") },
			data: { initial }
    })
      .then(res => {
        dispatch({ type: JOBS.IS_PROCESS, data: false })
        if (res.data.result) {
          if (typeof callback === 'function') {
            callback()
          }
        }
      })
      .catch(error => dispatch({ type: JOBS.IS_ERROR, error: error }))
  }
}
export const resetJobsList = () => {
  return { type: JOBS.RESET_CRONJOBS_DETAIL }
}
export const resetCronjobs = () => {
  return { type: JOBS.RESET_CRONJOBS }
}
export const initialJobssState = () => {
  return { type: JOBS.INIT_STATE }
}
export const importCronjobsFile = (data) => {
  return { type: JOBS.IMPORT_CRONJOBS_FILE, data: data }
}
export const togleTabMember = (data) =>{
  return { type: JOBS.TOGGLE_TAB_MEMBER, data: data }
}