import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import App from './App';
import store from '../BroadcastMessage/store/index';

import BroadcastMessage from '../BroadcastMessage/BroadcastMessage';
import VerifyBusinessHash from '../VerifyBusinessHash';

export let loggedIn = false;

function GoogleLogin(props) {
  const [redirect, setRedirect] = useState(null);

  if (redirect) {
    loggedIn = true;
    const hash = redirect.split('verifybusiness/')[1];
    switch (redirect) {
      case '/broadcastmessage':
        return (
          <Router>
            <Route path={redirect} component={BroadcastMessage} />
            <Redirect to={redirect} />
          </Router>
        );
      case `/verifybusiness/${hash}`:
        return (
          <Router>
            <Route exact path="/verifybusiness/:hash" component={VerifyBusinessHash} />
            <Redirect to={redirect} />
          </Router>
        );
      default:
        return;
    }
  }

  return (
    <Provider store={store}>
      <App setRedirect={setRedirect} redirectTo={props.location.from}></App>
    </Provider>
  );
}

export default GoogleLogin;
