import { INIT_WALLET_OWNER, IS_OWNER_EXIST, PERMISSION, CLEAR_BUSINESS_CODE } from '../actions_const'

let initState = {
  ownerId: '',
  isExist: false,
  business_name: '',
  business_code: '',
  archive: false
}

const ReducerWizard = (state = initState, action) => {
  switch (action.type) {
    case PERMISSION.USER_LOGOUT_SUCCESS: 
      return initState;
    case INIT_WALLET_OWNER:
      return Object.assign({}, state, { ownerId: action.owner_id || '', business_name: action.business_name || '', archive: action.archive || false, business_code: action.business_code || '' })
    case IS_OWNER_EXIST:
      return Object.assign({}, state, { isExist: action.data })
    case CLEAR_BUSINESS_CODE:
      return Object.assign({}, state, { business_code: '' })
    default:
      return state
  }
}


export default ReducerWizard;