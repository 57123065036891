import _ from "lodash";

import React, { Component, useEffect } from 'react';
import { bindActionCreators } from 'redux'
import { connect, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { Container } from 'reactstrap'

import Loading from '../../components/Loading'
import { DangerBtn } from '../../components/Button'
import { Title1, Title2, Title3 } from '../../components/Font'
import { ToastBar } from '../../components/ToastComponent/Toast.style'

import {
  ModelDetailFooter,
  Header,
} from './verifyBusinessHash.style'
import ConfirmModalHash from './confirmModalHash'

import { updateVerificationBusinessByHash } from '../../redux/actions/action_business'
import BusinessInfo from './BusinessInfo';
import PackageInfo from './PackageInfo';
import CreditInfo from './CreditInfo';
import AddressInfo from './AddressInfo';
import ProductInfo from './ProductInfo';
import TelInfo from './TelInfo';
import CardInfo from './CardInfo';
import { DEBUG } from '../../libs/utils';
import Settings from './Settings';
import { MANAGER } from "../../redux/actions_const";
import InvoiceInfo from "./InvoiceInfo";
import { getBusinessTypeByID } from "../../redux/actions/action_business"

class VerifybusinessHashShow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info_status: true,
      category_status: true,
      phone_status: true,
      card_status: true,
      isShowModal: false,
      isLoadingUpdate: false,
      business_category: [],
      product_category: [],
    }
  }

  componentDidMount() {
    let { store_business: { verifyBusiness } } = this.props
    
    if (verifyBusiness && verifyBusiness.hash_key)
      this.setState({
        info_status: verifyBusiness.status_info,
        category_status: verifyBusiness.status_category,
        phone_status: verifyBusiness.status_phone,
        // card_status: verifyBusiness.status_card,
      })
    this.getBusinessTypeByID()
  }

  toastShow(type, message) {
    toast[type](<ToastBar><Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{message}</Title2></ToastBar>)
  }

  selectDetail = (name) => {
    let { info_status, category_status, phone_status, card_status } = this.state
    if (name === 'info_status')
      this.setState({ info_status: !info_status })

    if (name === 'category_status')
      this.setState({ category_status: !category_status })

    if (name === 'phone_status')
      this.setState({ phone_status: !phone_status })

    if (name === 'card_status')
      this.setState({ card_status: !card_status })
  }

  toggleModel = () => {
    this.setState({ isShowModal: !this.state.isShowModal })
  }

  getBusinessTypeByID = () => {
    let { store_business: { verifyBusiness } } = this.props

    if (!(verifyBusiness && verifyBusiness.business_category))
      return null

    this.props.getBusinessTypeByID(verifyBusiness.business_category, verifyBusiness.product_category, (err, data) => {
      if (data) {
        this.setState({ business_category: data.data.main_type })
        this.setState({ product_category: data.data.type_name })
      }
    })
  }

  verifyButtonReject = () => {
    let { info_status, category_status, phone_status } = this.state
    return info_status && category_status && phone_status
  }

  upDateHash = () => {
    let { info_status, category_status, phone_status } = this.state
    let { store_business: { verifyBusiness } } = this.props
    this.setState({ isLoadingUpdate: true })
    this.props.updateVerificationBusinessByHash(
      {
        hash: verifyBusiness.hash_key,
        status: { info_status, category_status, phone_status }
      },
      (err, data) => {
        if (data) {
          this.setState({ isLoadingUpdate: false });
          this.toastShow("success", "การปฏิเสธธุรกิจสำเร็จ");
        }
      }
    );
  }

  render() {
    let { store_business } = this.props
    let { store_business: { verifyBusiness } } = this.props

    if (store_business.isProcess)
      return <Loading size={`3rem`} />

    if (!verifyBusiness)
      return <Container className='pt-3 pb-3 w-100 text-center'>
        <Title3 secondary>ไม่พบคำขอยืนยันธุรกิจ</Title3>
      </Container>

    let _businessVerified = verifyBusiness.status === 'verified'
      && Boolean(verifyBusiness.status_info)
      && Boolean(verifyBusiness.status_category)
      && Boolean(verifyBusiness.status_phone)

    return (
      <div>
        <Header className='pt-3 pb-3'>
          <div className="container">
            <Title1 bold white>จัดการบัญชี Pointspot</Title1>
          </div>
        </Header>
        <Container className='pt-3 pb-3'>
            {
              this.state.isLoadingUpdate
                ? <Loading size={`3rem`} />
                : <>
                    <BusinessInfo info={verifyBusiness} />
                    <PackageInfo business_code={verifyBusiness.business_code} email={verifyBusiness.email} />
                    <CreditInfo info={verifyBusiness} />
                    <InvoiceInfo business_code={verifyBusiness.business_code} />
                    <Settings business_code={verifyBusiness.business_code}/>
                    <AddressInfo status={this.state.info_status} onCheck={() => _businessVerified && this.selectDetail("info_status")}  
                      info={verifyBusiness}/>
                    <ProductInfo status={this.state.category_status} onCheck={() => _businessVerified && this.selectDetail('category_status')} 
                      info={verifyBusiness} business_category={this.state.business_category} product_category={this.state.product_category}/>
                    <TelInfo status={this.state.phone_status} onCheck={() => _businessVerified && this.selectDetail('phone_status')}
                       info={verifyBusiness} />
                    <CardInfo status={this.state.card_status} onCheck={() => _businessVerified && this.selectDetail('card_status')}
                       info={verifyBusiness}/>
                    {
                      this.state.isShowModal
                      && <ConfirmModalHash
                        isOpen={this.state.isShowModal}
                        toggle={() => { this.toggleModel() }}
                        info_status={this.state.info_status}
                        category_status={this.state.category_status}
                        phone_status={this.state.phone_status}
                        card_status={this.state.card_status}
                        updateHash={() => { this.upDateHash() }}
                      />
                    }
                    {
                      _businessVerified
                      && <ModelDetailFooter>
                        <DangerBtn bold onClick={this.toggleModel} disabled={this.verifyButtonReject()}>ปฏิเสธธุรกิจ</DangerBtn>
                      </ModelDetailFooter>
                    }
                </>
            }
        </Container>
        <Toast />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language,
    store_manager: state.manager,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    updateVerificationBusinessByHash,
    getBusinessTypeByID
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifybusinessHashShow);

const Toast = () => {
  const processingStatus = useSelector((state) => state.manager.processingStatus);
  useEffect(() => {
    DEBUG("[useEffect] SuccessToast", processingStatus);
    if ( _.get(processingStatus, ['message'])) {
      const message = (
        <ToastBar>
        <Title2 bold white style={{ width: "100%", textAlign: "center" }}>
          {processingStatus.message}
          </Title2>
        </ToastBar>
      );

      if (_.get(processingStatus, ['status']) === MANAGER.PROCESS_STATUS_SUCCEED) {
        toast.success(message);
      } else if (_.get(processingStatus, ['status']) === MANAGER.PROCESS_STATUS_ERROR) {
        toast.error(message);
      }
    }
  }, [processingStatus]);

  return <></>
}