import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { get } from 'lodash'
import sha1 from 'sha1'
import { useAddProductPoint, useChangeDate } from './hooks'

import InputDateRange from '../../InputDateRange'
import ProductPoints from '../../../pages/SendPoint/ProductPoints'
import { Title2, Title3 } from '../../Font'
import { getTargetMemberPurchaseProductTotal, setTargetMemberTotal } from '../../../redux/actions/action_broadcast'
import { formatDateTime } from '../../../libs/date'
import { usePrevious } from '../../../libs/hooks/usePrevious'
import { toastError } from '../../ToastComponent/ToastError'

export default function TargetPurchaseProduct ({ target, disabled, noDateSelect, noRequest, excludeArchive }) {
	const [productList, setProductList, isProductLoading] = useAddProductPoint(target?.products)
	const [groupDate, setGroupDate] = useChangeDate(target)
	const [purchasedCondition, setPurchasedCondition] = useState(target?.condition || 'all')
	const { business_code } = useSelector((state) => state.business.current)
	const dictionary = useSelector((state) => state.language.dictionary)
	const targetLoading = useSelector((state) => state.broadcast.broadcast_target_loading)
	const limitTarget = 5
	const isMounted = useRef()
	const dispatch = useDispatch()

	const handleProductList = useMemo(() => {
		return {
			condition: purchasedCondition,
			start_date: groupDate.start_date,
			end_date: groupDate.end_date,
			type: groupDate.type,
			products:
				productList.product_list.length > 0 ? productList.product_list.map((d) => get(d,'product_id', d)) : []
		}
	}, [purchasedCondition, groupDate, productList])
	
	const productPrevious = usePrevious(handleProductList)

	const dispatchTargetMemberPurchaseProduct = useCallback(() => {
		if (!noRequest) {
			dispatch(
				getTargetMemberPurchaseProductTotal({
					business_code,
					...handleProductList
				}, (err) => {
					if (err) toastError(dictionary.error)
				})
			);
		} else {
			dispatch(setTargetMemberTotal({
				target: 'member_purchase_product',
				target_data: { ...handleProductList }
			}))
		}
	}, [dispatch, noRequest, handleProductList, dictionary, business_code])

	useEffect(() => {
	
		if (isMounted.current) {
			if (
				((JSON.stringify(productPrevious) !==
					JSON.stringify(handleProductList) &&
					handleProductList.products.length) ||
					(!handleProductList.products.length &&
						productPrevious &&
						productPrevious.products.length))
			) {
				dispatchTargetMemberPurchaseProduct()
			}
		}

		if (!isMounted.current) {
			isMounted.current = true
			if (handleProductList.products.length) {
				dispatchTargetMemberPurchaseProduct()
			} else {
				dispatch(setTargetMemberTotal({
					target: 'member_purchase_product'
				}))
			}
		}

	}, [dispatch, business_code, handleProductList, productPrevious, dictionary, dispatchTargetMemberPurchaseProduct])

	const addProductPoint = (list) => {
		console.log('addProductPoint ', list)
		setProductList(list)
	}

	const handleChangeDate = (date) => {
		setGroupDate(date)
  }

	const handleChange = (e) => {
		const { value } = e.target
		setPurchasedCondition(value)
	}

	const timeUnique = useMemo(() => {
		return sha1(new Date().getTime()).substring(0, 5)
	},[])
	
	return (
		<>
			<div className='mb-2'>
				<Title2 bold><span className='text-danger'>* </span>{dictionary.product_list}</Title2>
				<div className='ml-3 mb-2'>
					<ProductPoints
						product_list={handleProductList.products}
						limit={limitTarget}
						targetGroups={true}
						fullSize={true}
						disabled={(productList && productList.product_list.length === limitTarget) || (targetLoading) || disabled}
						hiddenButton={disabled}
						removeDisabled={targetLoading}
						callBack={addProductPoint}
						isExternalLoading={isProductLoading}
						excludeArchive={excludeArchive}
					/>
				</div>
				{ productList && productList.product_list.length > 1 &&
					<div className='mb-3'>
						<Title2 bold><span className='text-danger'>* </span>{dictionary.purchase_condition}</Title2>
						<Row>
						<Col sm="12" className='ml-3'>
							<FormGroup check>
								<Input
									name={`memberPurchaseProduct_${timeUnique}`}
									type="radio"
									onChange={handleChange}
									value="all"
									disabled={disabled}	
									checked={Boolean(purchasedCondition === 'all')}
								/> {" "}
								<Label check>
									<Title2>{dictionary.members_purchased_all}</Title2>
								</Label>
							</FormGroup>
						</Col>
						<Col sm="12" className='ml-3'>
							<FormGroup check>
								<Input
									name={`memberPurchaseProduct_${timeUnique}`}
									type="radio"
									onChange={handleChange}
									value="any"
									disabled={disabled}	
									checked={Boolean(purchasedCondition === 'any')}
								/>
								{" "}
								<Label check>
									<Title2>{dictionary.members_purchased_any}</Title2>
								</Label>
							</FormGroup>
							</Col>
						</Row>
					</div>
				}
				<Title2 className='pb-2' bold><span className='text-danger'>* </span>{dictionary.date_range}</Title2>
				{
					!noDateSelect &&
					<div className='pb-2'>
						<InputDateRange
							customRange={false}
							title={false}
							isShowGroupDate={false}
							toggle={(date) => handleChangeDate(date)}
							noTitle={true}
							type={groupDate.type}
							disabled={disabled}
						/>
					</div>
				}
				<div className="d-flex flex-wrap flex-grow-1 pl-3">
					<Title3 secondary>{`${dictionary.date_on}`}&nbsp;</Title3>
					<Title3 secondary>{
						`${formatDateTime(groupDate.start_date)} ${dictionary.to}`
					}&nbsp;</Title3>
					<Title3 secondary>{
						formatDateTime(groupDate.end_date)
					}</Title3>
				</div>
			</div>
		</>
	)
}