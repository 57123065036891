import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, ModalFooter } from 'reactstrap'
import { PrimaryBtn } from '../Button'
import TransactionPersonal from '../../pages/Transactions/TransactionPersonal'
import { toggleModalMember } from '../../redux/actions/action_modal'
import { ContainerTransaction } from './modalMember.style'

class ModalMember extends Component {

    componentDidMount() {
        Object.assign(window, {
            toggleModalMember: (memberBadgeCode, option) => this.props.toggleModalMember(memberBadgeCode, option)
        })
    }

    render() {
        let { store_language: { dictionary } } = this.props
        let { store_modal: { memberBadgeCode, search, activeTab, hiddenTab } } = this.props

        return Boolean(memberBadgeCode)
            ? <Modal fade centered isOpen={Boolean(memberBadgeCode)} size='xl' toggle={() => this.props.toggleModalMember()}>
                <ContainerTransaction>
                    <TransactionPersonal
                        isModal
                        memberBadgeCode={memberBadgeCode}
                        search={search}
                        activeTab={activeTab}
                        hiddenTab={hiddenTab} />
                </ContainerTransaction>
                <ModalFooter className='p-2'>
                    <PrimaryBtn invert md bold className='m-0' onClick={() => this.props.toggleModalMember()}>
                        {dictionary.close}
                    </PrimaryBtn>
                </ModalFooter>
            </Modal>
            : null
    }
}

const mapStateToProps = (state) => {
    return {
        store_modal: state.modal,
        store_language: state.language,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({
        toggleModalMember
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalMember);