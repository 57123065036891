import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { setCookie } from '../../libs/cookies'
import RewardCard from '../../pages/Ecard/Rewards/RewardCard'
import { InvertBtn, PrimaryBtn } from '../Button'
import { Title2, Title3 } from '../Font'
import { filter } from 'lodash'
import { IconsSelectRewardCoupon, ModalListCouponBox, RewardCouponBox } from './ModalStyle.style'
import { getAllRewardsList } from '../../redux/actions/action_rewards'
import Loading from '../Loading'

export default function ModalRewardListCoupon(props) {
  const dispatch = useDispatch()
  const isMounted = useRef()
  const [selectList, setSelectList] = useState(props.selectedReward, [])
  const [isOpen, setOpen] = useState(props.isOpen || false)
  const store_business = useSelector((state) => state.business)
  const store_rewards = useSelector((state) => state.rewards)
  const dictionary = useSelector((state) => state.language.dictionary)
  const toggleModal = props.toggleModal || null
  const permission = store_business.permission
	const businessCode = store_business.current.business_code
	const isListLoading = store_rewards.isLoading

  useEffect(() => {
		if (!isMounted.current) {
      dispatch(getAllRewardsList({ business_code: businessCode, expire: 0 }))
			isMounted.current = true
		}	
	}, [dispatch, businessCode])

  const rewardListMemo = useMemo(() => {
    const rewardList = store_rewards.AllRewardsList || [];
    return rewardList.filter(m => (m.ecard_visibility && !m.ecard_visibility.hidden) || m.ecard_visibility === null);
  }, [store_rewards.AllRewardsList]);

  const closeModal = () => {
    setOpen(false)
  }

  const getTime = (date) => {
    if (date) return new Date(date).getTime()
    else return new Date().getTime()
  }

  const selectRewardCoupon = (ecard) => {
    if (ecard) {
      setSelectList([ecard])
    }
  }

  const onSubmitRewardCoupon = (list) => {
    let arr = list.length > 0
      ? list
      : []
    props.onSubmitRewardCoupon(arr)
    toggleHandler()
  }

  const toggleHandler = () => {
    if (toggleModal && typeof toggleModal === 'function') {
      toggleModal()
    } else {
      closeModal()
    }
  }

  return (
    <Modal centered isOpen={isOpen}>
      <ModalBody className='border-bottom'>
        <div className='w-100 d-flex justify-content-between align-items-center'>
          {
            <Title2 bold>{dictionary.select_redeem_rewards}</Title2>
          }
        </div>
      </ModalBody>
      <ModalBody>
        <ModalListCouponBox className='Modal_receipt_coupon'>
          {
            isListLoading
              ? <Loading size='2rem' />
              :
              rewardListMemo.length === 0
                ? permission['setting-rewards'].permission_create
                ? <AddRewardsCoupon /> 
                : <div className='d-flex align-items-center justify-content-center' style={{ width: '100%', height: '100%' }}>
                    <div className='d-flex align-items-center justify-content-center' style={{ width: '80%', height: '20%', background: '#F0F0F0', borderRadius: 10 }}>
                      <div className='text-center'>
                        <Title3 bold className='pt-2'>{ dictionary.reward_not_found }</Title3>
                      </div>
                    </div>
                  </div>
                  : rewardListMemo.map((value, key) => {
                    let selected = filter(selectList, ['ecard_id', value.ecard_id]).length > 0
                    let active = (value.stop_due && getTime(value.stop_due) > getTime()) || value.ecard_life_time

                    return (
                      active &&
                      <RewardCouponBox key={key} selected={selected} onClick={() => { selectRewardCoupon(value) }}>
                        <IconsSelectRewardCoupon className='pr-2'>
                          {selected ? <i className='fas fa-check-circle' /> : <i className='far fa-circle' />}
                        </IconsSelectRewardCoupon>
                        <div className='w-100 d-flex align-items-center justify-content-center'>
                            <RewardCard data={value} dictionary={dictionary} />
                        </div>
                      </RewardCouponBox>
                    )
                  })
          }
        </ModalListCouponBox>
      </ModalBody>
      <ModalFooter>
        <InvertBtn md bold onClick={toggleHandler}>{dictionary.close}</InvertBtn>
        <PrimaryBtn md bold id={`btn-submit`} 
          onClick={() => { onSubmitRewardCoupon(selectList) }}
          disabled={selectList.length === 0}
        >{dictionary.submit}</PrimaryBtn>
      </ModalFooter>
    </Modal>
  )
}

const AddRewardsCoupon = () => {
  const dictionary = useSelector((state) => state.language.dictionary)

  const setModalOpen = () => {
    let minutes = 1
    let date = new Date()
    date = date.setTime(date.getTime() + (minutes * 60 * 1000))
    setCookie('__REWARDS', true, date)
  }

  return (
    <Link to={'/rewards'} onClick={setModalOpen} style={{ textDecoration: 'none' }}>
      <div className='d-flex align-items-center justify-content-center' style={{ width: '100%', height: '100%' }}>
        <div className='d-flex align-items-center justify-content-center p-4' style={{ width: '80%', background: '#F0F0F0', borderRadius: 10, cursor: 'pointer' }}>
          <div className='text-center'>
            <Title2>
              <i className='fas fa-ticket-alt' style={{ fontSize: 30, position: 'relative' }}>
                <i className='fas fa-percentage' style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: 10, color: '#FFF' }} />
              </i>
            </Title2>
            <Title3 bold className='pt-2'>{dictionary.add_reward}</Title3>
          </div>
        </div>
      </div>
    </Link>
  )
}
