import styled from 'styled-components';

export const HighNumber = styled.div`
  color: ${props => props.theme.primary};
  font-weight: ${props => props.theme.font_bold};
  text-align: right;

  font-size: 36px;
  @media (max-width: 990px) {
    font-size: 34px;
  }

  ::after {
    font-size: 14px;
    content: " pts";
  }
`

export const TransactionNumber = styled.div`
  color: ${props => props.theme.primary};
  font-weight: ${props => props.theme.font_bold};
  text-align: left;

  font-size: 16px;
  @media (max-width: 990px) {
    font-size: 14px;
  }
  
  ::after {
    content: " txs";
  }
`