import axios from './../axios_request';
import { publicAPI } from '../end_point'

export const getRToken = (token, callback) => {
  let promise = axios({
    method: 'get',
    url: publicAPI + '/rtoken/' + token,
  })
  promise.then(response => {
    let response_body = response.data
    if (response_body.result) {
      if (typeof callback === 'function') callback(null, response_body.data)
    }
  }).catch(err => {
    if (typeof callback === 'function') callback(err, null)
  })
}