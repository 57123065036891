import styled from 'styled-components'

export const CookieConsentContainer = styled.div`

font-family: 'Sarabun', sans-serif !important;
  width: 90%;
  max-width: 600px;
  background-color: ${props => props.theme.primary};
  border-color: ${props => props.theme.primary};
  border: solid ${props => props.theme.primary} 2px;
  font-size: 14px;
  line-height: 26px;
  padding: 8px;
  position: fixed;
  bottom: 0.5rem;
  left: 50%;
  right: 0;
  z-index: 9999;
  transform: translateX(-50%);
  justify-content: space-between;
  display: flex;
  align-items: center;
  border-radius: 5px;
`

export const CookieConsentLinkInline = styled.span`
  cursor: pointer;
  color: ${props => props.theme.yellow};
`
export const CookieConsentLink = styled.div`
  background: ${props => props.theme.white};
  color: ${props => props.theme.primary};
  float: right;
  display: inline-block;
  cursor: pointer;
  padding: 0.1rem 0.5rem;
  font-weight: 600;
  border: solid 0.5px;
  border-radius: 5px;
`