import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import QRCode_raw from "qrcode";
import {
  MemberCardContainer,
  BusinessQR,
  CardMainContainer,
  MemberCardContainerBackground,
  CardFront,
  CardBack,
  ButtonAndExpire,
  QRButton,
  ExpireBox,
  BackMemberCardContainer,
  CardNameBar,
  IDBox,
  BusinessLogo,
  IDContainer,
  QRCodeImg,
} from "./MemberCard.styled";
import "./MemberCard.css";
import TimeSpin from "./TimeSpin";
import { useState } from "react";
import MemberCardInfoSection from "./MemberCardInfoSection";
import { nl2br } from "../../libs/nl2br";
import { formatCustom } from "../../libs/date";
import moment from "moment";

function MemberCardIndex(props) {
  let {
    cardPrimaryColor = "#516373",
    cardFontColor = "#ffffff",
    cardName = "Member",
    cardPrivilege,
    expiryDate,
    croppedImage,
    background_image,
    isShowExpiry,
    isShowInfoSection,
    isShowQRButton,
    memberBadgeCode,
    isLoading,
    isManual,
  } = props;

  const dictionary = useSelector((state) => state.language.dictionary);
  const current = useSelector((state) => state.business.current);

  const [isFlipped, setIsFlipped] = useState(false);

  let themeColor = current?.primary_color || "#516373";
  let expiry_date = "";
  let now = new Date();

  if (expiryDate && moment(expiryDate) > now) {
    expiry_date = formatCustom(expiryDate, "MMM YYYY");
  }

  function handleCardDisplay() {
    setIsFlipped(!isFlipped);
  }

  return (
    <>
      <CardMainContainer isManual={isManual}>
        <MemberCardContainer rotateCard={isFlipped}>
          <CardFront isFlipped={isFlipped}>
            <FrontMemberCard
              cardName={cardName}
              primary={cardPrimaryColor}
              fontColor={cardFontColor}
              isUploadedImage={Boolean(croppedImage)}
              background_image={background_image}
              croppedImage={croppedImage}
              memberBadgeCode={memberBadgeCode}
            />
          </CardFront>

          <CardBack isFlipped={isFlipped}>
            <BackMemberCard
              primary={cardPrimaryColor}
              isLoading={isLoading}
              hasBackgroundImage={
                Boolean(background_image) || Boolean(croppedImage)
              }
              memberBadgeCode={memberBadgeCode}
            />
          </CardBack>
        </MemberCardContainer>

        <ButtonAndExpire>
          {isShowQRButton && (
            <QRButton onClick={handleCardDisplay} primary={cardPrimaryColor}>
              {isFlipped ? (
                <i className="fas fa-reply" />
              ) : (
                <i
                  className="icon-qrcode-card"
                  style={{ marginTop: "2px", marginLeft: "1px" }}
                />
              )}
            </QRButton>
          )}

          {isShowExpiry && expiry_date && (
            <ExpireBox themeColor={themeColor}>
              <i className="far fa-calendar-alt pr-1" />
              {dictionary.expire_in} {expiry_date}
            </ExpireBox>
          )}
        </ButtonAndExpire>

        {isShowInfoSection && (
          <MemberCardInfoSection
            themeColor={themeColor}
            cardName={cardName}
            cardPrivilege={cardPrivilege}
          />
        )}
      </CardMainContainer>
    </>
  );
}

export default MemberCardIndex;

const FrontMemberCard = (props) => {
  let {
    primary,
    fontColor,
    cardName,
    isUploadedImage,
    background_image,
    croppedImage,
    memberBadgeCode,
  } = props;

  const current = useSelector((state) => state.business.current);

  const [fontSize, setFontsize] = useState("14px");
  const [height, setHeight] = useState("30px");

  useEffect(() => {
    const lines = cardName.split("\n");
    const longestLine = lines.reduce(
      (a, b) => (a.length > b.length ? a : b),
      ""
    );

    if (longestLine.length > 19) {
      setFontsize("12px");
    } else {
      setFontsize("14px");
    }

    if (lines.length > 1) {
      setHeight("50px");
    } else {
      setHeight("30px");
    }
  }, [cardName]);

  return (
    <MemberCardContainerBackground
      primary={primary}
      hasBackgroundImage={Boolean(background_image) || Boolean(croppedImage)}
      background_image={croppedImage || background_image}
    >
      <div className="d-flex justify-content-between">
        {current.business_logo_url && (
          <BusinessLogo src={current.business_logo_url} primary={primary} />
        )}

        <CardNameBar
          style={{
            fontSize: fontSize,
            height: height,
          }}
          primary={primary}
          fontColor={fontColor}
        >
          {nl2br(cardName)}
        </CardNameBar>
      </div>

      {!isUploadedImage && !background_image && (
        <IDContainer>
          <IDBox primary={primary} fontColor={fontColor}>
            {memberBadgeCode || "123456"}
          </IDBox>
        </IDContainer>
      )}
    </MemberCardContainerBackground>
  );
};

const BackMemberCard = (props) => {
  let { primary, memberBadgeCode } = props;

  const [QRCode, setQRCode] = useState(null);
  const [isLoadingQR, setIsLoadingQR] = useState(false);

  let hide_qr_prefix = false;

  useEffect(() => {
    if (memberBadgeCode) {
      generateQR(memberBadgeCode);
    } else {
      generateQR("123456");
    }
    // eslint-disable-next-line
  }, [memberBadgeCode]);

  function generateQR(data = "") {
    let QRPath = hide_qr_prefix ? `${data}` : `PSBadge:${data}`;

    let options = {
      margin: 0,
      type: "image/png",
      errorCorrectionLevel: "L",
      color: { dark: "#000000", light: "#FFFFFF" },
    };

    setIsLoadingQR(true);

    QRCode_raw.toDataURL(QRPath, options, (error, QRData) => {
      if (error) {
        setQRCode(null);
      } else {
        setQRCode(QRData);
      }
      setIsLoadingQR(false);
    });
  }

  return (
    <MemberCardContainerBackground primary={primary}>
      <BackMemberCardContainer>
        <div id="div_member_card">
          {QRCode && !isLoadingQR ? (
            <BusinessQR>
              <QRCodeImg src={QRCode} alt="QRCode" />
            </BusinessQR>
          ) : (
            <i className="fa fa-spinner fa-pulse" />
          )}
        </div>

        <TimeSpin primary={primary} />
      </BackMemberCardContainer>
    </MemberCardContainerBackground>
  );
};
