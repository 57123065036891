import styled, { css, keyframes } from 'styled-components'

export const AttachIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 5px;
  display:inline-block;
  padding: 10px;
  color:${props => props.theme.secondary};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.secondary};
  &:hover{
    opacity:0.9;
    cursor:pointer;
  }
`

export const VerifyWarningContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.red};
  cursor: ${props => props.pointer ? 'pointer' : ''};
`

export const ExchangeRateDiv = styled.div`
  display:flex;
  justify-content: space-between;
  align-items:center;
  padding-top: 20px;
  position:relative;
`

export const InputGroupSend = styled.div`
    display: inline-block;
`

export const InputLabel = styled.div`
  position: absolute;
  top:0;
`

export const AttachmentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  max-width: 100px;
  min-width: 100px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.03);
  position: relative;
`

export const IconRemoveAtt = styled.i`
  position: absolute;
  top: -7px;
  right: -15px;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  color: ${props => props.theme.primary};
`

export const ImageAttachment = styled.img`
  max-width: 100px;
  max-height: 70px;
  overflow: hidden;
  border-radius: 10px;
  padding: 10px;
  border-radius: 15px;
`

export const ReceiptCouponBox = styled.div`
  ${props => props.centered && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  border-radius: 10px;
  background: rgba(0, 0, 0, 0.03);

  min-height: 135px;
  max-height: 135px;
  width: 100%;
  
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
`

const rotateDown = keyframes`
  from { transform:  rotate(0deg); }
    to { transform:  rotate(180deg); }
`

const rotateUp = keyframes`
  from { transform:  rotate(180deg); }
    to { transform:  rotate(0deg); }
`

export const IconChevron = styled.div`
  animation: ${props => props.toggle ? rotateUp : rotateDown} 0.2s linear;
  animation-fill-mode: forwards;
  cursor: pointer;
`

export const FromDataInfo = styled.div`
  border-radius: 5px;
  background: #F5F5F5;
  padding: 7px 15px;
  border: 1px solid ${props => props.theme.border_line};
  margin-top: 5px;
  width: 100%;
  max-height: ${props => props.maxH ? props.maxH : '150px'};
  overflow: hidden;
  overflow-y: scroll;
`

export const SendPointsBtnContainer = styled.div`
@media (max-width: 767px){
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
  > * {
    border-radius: 0px;
    padding: .75rem;
  }
}

> * {
  font-size: 12px !important;
}
`

export const DropdownProduct = styled.div`
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 100;
  ${props => props.fullSize ? css`width : 100%;`: css`width: 260px;` }
  background-color: #FFF;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px;
`

export const IconSearch = styled.i`
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    padding: .75rem;
    z-index: 1;
    color: ${props => props.theme.primary};
    ${props => props.pointer && css`cursor: pointer;`}
`

export const ProductListBox = styled.div`
    width: 100%;
    max-height: 140px;
    overflow-y: scroll;
    overflow-x: hidden;
    @media (min-width: 990px){
      max-height: 160px;
    }
`

export const SearchItem = styled.div`
  width: 100%;
  min-width: 100px;
  cursor: pointer;
  padding: 8px 15px;
  border-bottom: 1px solid ${props => props.theme.border_line};
  ${ props => props.selected ? css`background: ${props => props.theme.blue_light};` : ''}
  ${ props => (!props.selected && props.disabled) ? css`background: '#546174';` : '' }

  &:hover{
    padding-left: 10px;
    border-left: 5px solid #546174;
  }

  :last-child{
    border-bottom: none;
  }
`

export const TempProductList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: scroll hidden;
  border-bottom: 1px solid ${props => props.theme.border_line};

  &::-webkit-scrollbar {
    height: 2px;
    background: transparent;
   }
 
   &::-webkit-scrollbar-thumb {
     background: ${props => props.theme.primary};
   }

   > * {
     :first-child{
        padding-left: .25rem;
     }
   }
`

export const TempItem = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  color: ${props => props.theme.secondary};
  font-size: 10px;
  white-space: nowrap;
  padding: 5px 8px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.border_line};
  border-radius: 5px;
  background: ${props => props.theme.blue_light};
`

export const ProductCol = styled.div`
  width: 100%;
  padding: 0px 5px 0px 0px;
  text-align: ${props => props.align ? props.align : 'left'};
  color: ${props => props.disabled ? props.theme.gray : props.theme.primary};
  font-weight: ${props => props.bold ? props.theme.font_bold : ''};
  font-size: ${props => props.theme.font_s};
  word-break: break-word;
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xs};
  }
  :nth-child(1) { width: 35%;}
  :nth-child(2) { width: 30%;}
  :nth-child(3) { width: 30%;}
  :nth-child(4) { width: 5%;}
  :first-child:nth-last-child(2) { width: 100%; }
`

export const BadgeUpgrade = styled.div`
  font-size: 10px;
  font-weight: 500
  margin: 2px 10px;
  padding: 14px 4px;
  color: #212529;
  background-color: #ffc107;
`