import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormGroup } from 'reactstrap';
import ReactTagsInput from '../../react-tags-input';
import { Title2, Title3 } from '../../Font';
import { getMemberProfileTarget } from '../../../redux/actions/action_ecoupon';
import { getTargetSpecificMemberTotal, setTargetMemberTotal, updateBroadcastTarget } from '../../../redux/actions/action_broadcast';
import { toastError } from '../../ToastComponent/ToastError';
import { toCurrency } from '../../../libs/currency';

export default function TargetMember({ method, disabled, target, noRequest, hideTagInput, max }) {
	const dispatch = useDispatch();
	const isMounted = useRef();
	const timeoutId = useRef();
	const prevTags = useRef();

	const [tags, setTags] = useState(Array.isArray(target) ? target : []);

	const { business_code } = useSelector((state) => state.business.current);
	const dictionary = useSelector((state) => state.language.dictionary);
	
	const setTargetMember = useCallback(
		(value) => {
			if (!noRequest) {
				const members = tags.map((m) => m.isValid && parseInt(m.member_badge_code)).filter((f) => f);
				if (members.length) {
					const preMembers = prevTags?.current?.map((m) => m.isValid && parseInt(m.member_badge_code)).filter((f) => f);
					if (JSON.stringify(members) !== JSON.stringify(preMembers)) {
						prevTags.current = members;
						if (timeoutId.current) {
							clearTimeout(timeoutId.current);
						}
						timeoutId.current = setTimeout(() => {
							dispatch(
								getTargetSpecificMemberTotal({ business_code, members, value }, (err) => {
									if (err) toastError(dictionary.error);
								})
							);
						}, 300);
					} else {
					 	dispatch(updateBroadcastTarget(value.map((m) => m.member_badge_code && parseInt(m.member_badge_code)).filter((f) => f).join(',')));
					}
				} else {
					dispatch(
						setTargetMemberTotal({
							target: 'member',
						})
					);
				}
			} else {
				dispatch(
					setTargetMemberTotal({
						target: 'member',
						target_data: value.map((m) => m.isValid && m.value).filter((f) => f).join(','),
					})
				);
			}
		},
		[tags, prevTags, noRequest, business_code, dictionary, dispatch]
	);

	const tagsValidator = useCallback(
		(newTags, allTags) => {
			if (newTags.length) {
				const tagsMap = allTags.reduce((acc, tag, index) => {
					acc[tag.member_badge_code] = index;
					return acc;
				}, {});

				dispatch(
					getMemberProfileTarget(business_code, newTags, (results) => {
						const items = results.map((element) => {
							if (element.member_badge_code) {
								const isInTheTags = tagsMap[element.member_badge_code] !== undefined;
								if (isInTheTags) {
									return {
										value: element.target,
										member_badge_code: element.member_badge_code,
										isValid: false,
										invalidReason:
											!element.subscribe_date || element.hide_business || element.archive ? 'user_invalid' : 'user_duplicated',
										pending: false,
									};
								} else {
									return {
										value: element.target,
										member_badge_code: element.member_badge_code,
										isValid: element.subscribe_date && !element.hide_business && !element.archive,
										invalidReason: !element.subscribe_date || element.hide_business || element.archive ? 'user_invalid' : '',
										pending: false,
									};
								}
							} else {
								return {
									value: element.target,
									isValid: false,
									pending: false,
									invalidReason: 'user_invalid',
								};
							}
						});
						setTags([...allTags, ...items]);
					})
				);
			}
		},
		[dispatch, business_code]
	);

	const tagsHandleChange = useCallback((newTags) => {
		setTags([...newTags]);
	}, []);

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true;
			setTargetMember([]);
		} else {
			setTargetMember(tags);
		}
	}, [tags, setTargetMember]);

	return (
		<FormGroup>
			<div className="d-flex justify-content-between align-items-center">
				<Title2
					className={tags.length === 0 ? 'pb-2' : ''}
					bold>
					<span className="text-danger">* </span>
					{`${dictionary.mobile} ${dictionary.or} ${dictionary.pointspot_id}`}
				</Title2>
				<Title3
					className={tags.length === 0 ? 'pb-2' : ''}
					bold
					hidden={method && method.indexOf('Update') > -1}
					danger={tags.length > max}>
					{`(${tags.length} / ${toCurrency(max)})`}
				</Title3>
			</div>
			<div className="d-flex align-items-center pb-1">
				<TagsInput
					tags={tags}
					tagsValidator={tagsValidator}
					disabled={disabled}
					tagsHandleChange={tagsHandleChange}
					hideTagInput={hideTagInput}
					max={max}
				/>
			</div>
		</FormGroup>
	);
}

const TagsInput = ({ tags, tagsValidator, disabled, tagsHandleChange, hideTagInput, max }) => {
	const tagRef = useRef();
	const dictionary = useSelector((state) => state.language.dictionary);
	const [tagOverflow, setTagOverflow] = useState(false);

	useEffect(() => {
		if (tagRef.current) {
			const height = tagRef.current?.offsetHeight;
			setTagOverflow(height > 99);
		}
	}, [tags]);

	return (
		<CustomReactTagsInput
			tagOverflow={tagOverflow}
			forwardRef={tagRef}
			tags={tags}
			validator={tagsValidator}
			disabled={disabled}
			disableInput={disabled || tags.length >= max}
			handleChange={tagsHandleChange}
			hideInput={hideTagInput}
			placeholder={`${dictionary.mobile} ${dictionary.or} ${dictionary.pointspot_id}`}
		/>
	);
};

const CustomReactTagsInput = styled(ReactTagsInput)`
	.reacttags_tags_area {
		overflow-y: ${(props) => (props.tagOverflow ? 'auto' : 'unset')} !important;
	}
`;
