import styled, { css } from 'styled-components';

export const CouponSelectBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  width: 100%;;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.03);
  position: relative;
`

export const RewardCouponBox = styled.div`
  width: 100%;
  height: 40vw;
  min-width: 240px;
  min-height: 115px;

  ${props => props.sm
    ? css`
        max-width: 260px;
        max-height: 115px;
    `
    : css`
        max-width: 340px;
        max-height: 145px;
    `
  }

  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  ${props => props.active && css`cursor: pointer;`}
`

export const ErrorTargetGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
` 