import styled from 'styled-components'

export const AdvanceOptionHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
  cursor: pointer;

  .title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    white-space: nowrap;

    &::after {
      content: "";
      width: 100%;
      background-color: #dee2e6;
      height: 1px;
      display: block;
      margin: 0 8px;
    }
  }
`