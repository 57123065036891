import axios from './../axios_request';

import { businessAPI, privateAPI } from '../end_point'
import {
  GET_REWARD_LIST_START,
  GET_REWARD_LIST,
  GET_REWARD_LIST_MORE,
  GET_REWARD_LIST_END,
  CLEAR_REWARDS,
  GET_ALL_REWARD_LIST,
} from '../actions_const'
import { getCookie } from '../../libs/cookies';

export const getRewardsList = ({ business_code, limit, offset, type }, callback) => {
  return dispatch => {
    dispatch({ type: GET_REWARD_LIST_START })
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/ecard/rewards?limit=${limit}&offset=${offset}`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data)
        if (type === 'load_more')
          return dispatch({ type: GET_REWARD_LIST_MORE, data: data, total: data.total })
        else
          return dispatch({ type: GET_REWARD_LIST, data: data, total: data.total })
      } else {
        if (typeof callback === 'function') callback('error')
      }
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: GET_REWARD_LIST_END })
    })
  }
}

export const getAllRewardsList = ({ business_code, expire }, callback) => {
  return dispatch => {
    dispatch({ type: GET_REWARD_LIST_START })
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/ecard/rewards?expire=${expire}`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.data)
        return dispatch({ type: GET_ALL_REWARD_LIST, data: data.data })
      } else {
        if (typeof callback === 'function') callback('error')
        return Promise.reject('result error')
      }
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: GET_ALL_REWARD_LIST })
    })
  }
}

export const getReward = (params, callback) => {
  return () => {
    axios({
      method: 'get',
      url: `${businessAPI}/${params.business_code}/ecard/rewards/${params.ecard_id}`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data)
      } else {
        if (typeof callback === 'function') callback('result error')
      }
    }).catch(() => {
      if (typeof callback === 'function') callback('error')
    })
  }
}

export const addReward = (params, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${params.business_code}/ecard/rewards`,
      headers: { Authorization: getCookie("_token") },
      data: params
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data)
      } else {
        if (typeof callback === 'function') callback('error result')
      }
    }).catch(() => {
      if (typeof callback === 'function') callback('error')
    })
  }
}

export const editReward = (params, callback) => {
  return dispatch => {
    axios({
      method: 'put',
      url: `${businessAPI}/${params.business_code}/ecard/rewards/${params.ecard_id}`,
      headers: { Authorization: getCookie("_token") },
      data: params
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data)
      } else {
        if (typeof callback === 'function') callback('result error')
      }
    }).catch(() => {
      if (typeof callback === 'function') callback('error')
    })
  }
}

export const deleteReward = (params, callback) => {
  return dispatch => {
    axios({
      method: 'delete',
      url: `${businessAPI}/${params.business_code}/ecard/rewards/${params.ecard_id}`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data)
      } else {
        if (typeof callback === 'function') callback('result error')
      }
    }).catch(() => {
      if (typeof callback === 'function') callback('error')
    })
  }
}

export const clearRewards = () => {
  return dispatch => {
    return dispatch({ type: CLEAR_REWARDS })
  }
}

export const getAddressByID = (address_id, callback) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${privateAPI}/geography?address_id=${address_id}`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.data)
      } else {
        if (typeof callback === 'function') callback('result error')
      }
    }).catch((error) => {
      if (typeof callback === 'function') callback('error')
    })
  }
}


export const sortReward = (business_code, data, callback) => {
  return dispatch => {
    axios({
      method: 'put',
      headers: { Authorization: getCookie("_token") },
      url: `${businessAPI}/${business_code}/ecard/rewards/sort`,
      data: data
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data);
      } else if (typeof callback === 'function') callback('result error');
    }).catch((err) => {
      if (typeof callback === 'function') callback(err);
    })
  }
}