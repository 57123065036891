import styled from 'styled-components'

export const TitleBar = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  top: 0px;
  padding: 0px 20px;
  z-index: 999;
  position: sticky;

  background-color: ${props => props.theme.primary};
`

export const ContainerWizard = styled.div`
  width: 100%;
  height: calc(100vh - 50px);

  margin: 0px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${props => props.theme.secondary}11;
`

export const WizardBox = styled.div`
  margin: 20px 0px;
  width: 100%;
  max-width: 850px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #FFFFFF;
  box-shadow: rgb(220, 220, 220) 3px 3px 8px 0px;
`

export const WizardBoxDetail = styled.div`
  width: 90%;
  max-width: 500px;
  
  height: 30vw;
  min-height: 180px;
  max-height: 250px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
`

export const WizardImageLogo = styled.img`
  width: 50vw;
  height: auto;

  min-width: 300px;
  max-width: 450px;
`