import styled from 'styled-components';

export const SidebarBusinessRow = styled.div`
  display:flex;
  // padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const SidebarBusinessWrapper = styled.div`
  border-radius: 50%;  
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  overflow: hidden;
`

export const SidebarBusinessAvatar = styled.img`
  width: 100%;
`

export const SidebarBusinessName = styled.div`
  font-size: ${props => props.theme.font_l};
  color: ${props => props.theme.primary};
  font-weight: ${props => props.theme.font_bold};
  padding: 10px 0px;
`

export const SidebarBusinessEditIcon = styled.div`
  color: ${props => props.theme.secondary};
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.primary};
  }
  align-self: flex-end;
`


