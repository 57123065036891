import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, Input } from "reactstrap";
import { Title2, Title3 } from "../../../components/Font";
import { DEBUG } from "../../../libs/utils";
import { updateAutomationMemberCardDemotionConfig } from "../../../redux/actions/action_business";
import { InvertBtn, SuccessBtn } from '../../../components/Button';
import ConfirmModal from "./ConfirmModal";


const TYPE_CARD_EXPIRATION_DATE = 'card_expiry_date';
const TYPE_EARNED_POINT = 'earned_point';

export default function DemotionSetting({ dictionary, permission, business, toastShow }) {
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);
  const typeOptions = [
    {
      title: 'month_1',
      value: '1_month',
      description: 'member_card_demotion_1_month_check'
    },
    {
      title: 'month_3',
      value: '3_month',
      description: 'member_card_demotion_3_month_check'
    },
    {
      title: 'month_6',
      value: '6_month',
      description: 'member_card_demotion_6_month_check'
    },
    {
      title: 'month_12',
      value: '12_month',
      description: 'member_card_demotion_12_month_check'
    },
  ];

  const [isLoading, setLoading] = useState(false);
  const [selectedType, setType] = useState(business.member_card_automation_demotion_type === TYPE_CARD_EXPIRATION_DATE
    ? TYPE_CARD_EXPIRATION_DATE
    : TYPE_EARNED_POINT);
  const [selectedFrequency, setFrequency] = useState(
    (!business.member_card_automation_demotion_type || business.member_card_automation_demotion_type === TYPE_CARD_EXPIRATION_DATE)
      ? typeOptions[0].value
      : business.member_card_automation_demotion_type);

  const onTypeChange = (event) => {
    const { value } = event.target;
    DEBUG("[DemotionSetting] onTypeChange", value);
    setType(value);
  };

  const onSave = () => {
    setShowConfirm(false);
    setLoading(true);
    const type = (selectedType === TYPE_CARD_EXPIRATION_DATE ? TYPE_CARD_EXPIRATION_DATE : selectedFrequency);
    dispatch(updateAutomationMemberCardDemotionConfig(business.business_code, type, (err, data) => {
      setLoading(false);
      if (err) {
        toastShow("error", dictionary.error_occurred);
      } else {
        toastShow('success', dictionary.member_card_automation_status_success);
      }
    }));
  };

  const onCancel = () => {
    setType(business.member_card_automation_demotion_type === TYPE_CARD_EXPIRATION_DATE
      ? TYPE_CARD_EXPIRATION_DATE
      : TYPE_EARNED_POINT);
    setFrequency((!business.member_card_automation_demotion_type || business.member_card_automation_demotion_type === TYPE_CARD_EXPIRATION_DATE)
      ? typeOptions[0].value
      : business.member_card_automation_demotion_type);
  };

  return (
    <Card className="my-3">
      <CardBody className="border-bottom">
        <Title2 bold>{dictionary.member_card_demotion_setting}</Title2>
        <Title3 secondary>{dictionary.member_card_demotion_setting_description}</Title3>
      </CardBody>
      <CardBody className="border-bottom">
        <Title2 className="mb-2" bold>{dictionary.member_card_demotion_condition}</Title2>
        <div className="d-flex align-items-center mb-2">
          <input
            className='pointer'
            type='radio'
            name={TYPE_CARD_EXPIRATION_DATE}
            value={TYPE_CARD_EXPIRATION_DATE}
            checked={selectedType === TYPE_CARD_EXPIRATION_DATE}
            disabled={!permission}
            onChange={!permission ? null : onTypeChange}
          />
          <Title2 className="pl-2">{dictionary.member_card_demotion_card_expiration}</Title2>
        </div>
        <div className="d-flex align-items-center mb-2">
          <input
            className='pointer'
            type='radio'
            name={TYPE_EARNED_POINT}
            value={TYPE_EARNED_POINT}
            checked={selectedType === TYPE_EARNED_POINT}
            disabled={!permission}
            onChange={!permission ? null : onTypeChange}
          />
          <Title2 className="pl-2">{dictionary.member_card_demotion_earned_point}</Title2>
        </div>
        <div className="align-items-baseline d-flex flex-column flex-md-row pl-5 w-100">
          <Title2 className="flex-shrink-0 mr-3">{dictionary.member_card_demotion_earned_point_check_frequency}</Title2>
          <div className="flex-grow-1 w-100">
            <Input type="select"
              style={{ width: 150 }}
              name="calculation-frequency"
              className="form-control"
              value={selectedFrequency}
              disabled={(selectedType !== TYPE_EARNED_POINT) || !permission}
              onChange={(event) => !permission ? null : (setFrequency(_.get(event, ['target', 'value']) || typeOptions[0].value))}>
              {
                typeOptions.map((item, index) => (
                  <option key={index} value={item.value}>{dictionary[item.title]}</option>
                ))
              }
            </Input>
            {
              selectedFrequency && <Title3 className="mt-2" secondary>{dictionary[_.find(typeOptions, { value: selectedFrequency }).description]}</Title3>
            }
          </div>
        </div>
      </CardBody>
      <CardBody>
        <div className="d-flex justify-content-end align-items-center">
          <InvertBtn onClick={onCancel}>
            {dictionary.cancel}
          </InvertBtn>
          <SuccessBtn id={`btn-save`} disabled={isLoading || !permission} onClick={() => setShowConfirm(true)}>
            {
              isLoading ? <i className='fas fa-spinner fa-spin' /> : <Title2 white>{dictionary.save}</Title2>
            }
          </SuccessBtn>
        </div>
      </CardBody>
      {
        showConfirm && <ConfirmModal
          isOpen={showConfirm}
          toggle={() => setShowConfirm(!showConfirm)}
          dictionary={dictionary}
          onConfirm={onSave}
        />
      }
    </Card>
  )
}