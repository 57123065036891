import {
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import { InvertBtn, PrimaryBtn } from '../../Button'
import { Title2, Title3, Title4 } from '../../Font'

import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createCustomerPaymentCreditCard } from '../../../redux/actions/action_payment'
import { toCreditCard } from '../../../libs/creditCard'

import logo_2c2p from '../../../assets/img/logo_2c2p.png'
import { getError } from '../../../libs/my2c2p'
import _ from 'lodash'


class BusinessPaymentModalCard extends React.Component {
  timeoutId = null;
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      number: '',
      expire_month: new Date().getMonth() + 1,
      expire_year: new Date().getFullYear() + 1,
      cvv: '',
      name: '',
      isError: false,
      error_msg: '',
      err_number: false,
      err_expire_month: false,
      err_expire_year: false,
      err_cvv: false,
      err_name: false,
      err_text: ''
    }
    this.onChangeInput = this.onChangeInput.bind(this)
    this.onChangeDate = this.onChangeDate.bind(this)
    this.validateField = this.validateField.bind(this)
    this.createCard = this.createCard.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
  }
  componentDidMount() {
    const { REACT_APP_MY_2C2P_SDK } = process.env
    const my2c2pScript = document.createElement("script");
    my2c2pScript.src = REACT_APP_MY_2C2P_SDK;
    my2c2pScript.async = true;
    window.document.body.appendChild(my2c2pScript);

    this.timeoutId = setTimeout(()=>{
      let ccnumber = window.document.getElementById('ccnumber')
      if(ccnumber) ccnumber.focus();
    }, 300)
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  onChangeInput(event) {
    let { name, value } = event.target
    const errKey = `err_${name}`
    switch (name) {
      case 'number':
        let max
        let americanExpress = ['34', '37']
        let dinersClub = ['30', '36', '38']

        let prefixCard = value.toString().replace(/[\W\D\s_]+/g, '').substr(0, 2)
        if (americanExpress.indexOf(prefixCard) > -1) max = 15
        else if (dinersClub.indexOf(prefixCard) > -1) max = 14
        else max = 16
        if (value.toString().replace(/[\W\s_]+/g, '').length <= max)
          this.setState({ [name]: toCreditCard(value), [errKey]: false, err_text: '' })
        break;

      case 'cvv':
        if (value.length < 4)
          this.setState({ [name]: value, [errKey]: false, err_text: '' })
        break;

      default:
        this.setState({ [name]: value.toUpperCase(), [errKey]: false, err_text: '' })
        break;

    }
  }

  validateField() {
    let { number, name, cvv, expire_month, expire_year } = this.state
    let numberLength = 16
    let dataCreditNumber = [
      { name: 'American Express', prefix: ['34', '37'], lengthCard: 15 },
      { name: 'Diners Club', prefix: ['30', '36', '38'], lengthCard: 14 }
    ]

    if (number.length > 2) {
      let prefixCard = number.toString().replace(/[\W\D\s_]+/g, '').substr(0, 2)
      let temp = dataCreditNumber.filter(x => x.prefix.indexOf(prefixCard) > -1)
      numberLength = temp.length > 0 ? temp[0].lengthCard : 16
    }
    return !(number
      && name
      && cvv
      && expire_month
      && expire_year
      && number.toString().replace(/[\W\D\s_]+/g, '').length === numberLength
      && cvv.length >= 3
    )
  }

  onChangeDate(event) {
    let { name, value } = event.target
    const errKey = `err_${name}`
    this.setState({ [name]: value, [errKey]: false, err_text: '' })
  }

  onKeyDown(event) {
    let { key } = event
    if (key === 'Enter' && !this.validateField())
      this.createCard()
  }

  createCard() {
    let { store_business: { current: { business_code } } } = this.props
    let { number, name, cvv, expire_month, expire_year } = this.state
    //let card = _.pick(this.state, ['number', 'expire_month', 'expire_year', 'cvv', 'name'])

    const form = document.getElementById("2c2p-payment-form");
    form.my2c2p_cc.value = number.replace(/\s/gi, "");
    form.my2c2p_month.value = expire_month < 10 ? `0${expire_month}` : expire_month;
    form.my2c2p_year.value = expire_year;
    form.my2c2p_cvv.value = cvv;

    window.My2c2p.getEncrypted("2c2p-payment-form", (encryptedData, errCode, errDesc) => {
      if (!errCode) {
        this.setState({ isLoading: true, isError: false })
        this.props.createCustomerPaymentCreditCard(business_code, {
          encryptedData,
          name,
          business_code
        },
          (error, data) => {
            if (Boolean(error))
              this.setState({
                isLoading: false,
                isError: true,
                error_msg: error.text
              })
            else {
              this.props.toggleModalCard()
              const card_id = _.get(data, ['data', 'payment_customer_card_id']);
              this.props.onClickSelectCard(card_id)
            }
          }
        )
      } else {
        const error = getError(errCode);
        if (error) {
          const { text, field } = error;
          const errKey = `err_${field}`
          this.setState({
            err_text: text,
            [errKey]: true
          });
        }
      }
    });
  }

  render() {
    let { isOpen, toggleModalCard } = this.props
    let { store_language: { dictionary } } = this.props

    return <Modal centered fade={true} isOpen={isOpen}>
      <ModalHeader>
        <Title2 bold>{dictionary.add_payment_method}</Title2>
      </ModalHeader>
      <ModalBody>
        {
          this.state.err_text ? <Title3 className="mb-3 text-center" danger>{dictionary[this.state.err_text]}</Title3> : null
        }
        <Row className='pb-3'>
          <Col xs={12}>
            <div className='pb-1 d-flex align-items-center'>
              <Title3 bold danger className='pr-2'>*</Title3>
              <Title3 bold>{dictionary.credit_card_number}</Title3>
            </div>
            <Input
              id='ccnumber'
              type='text'
              name="number"
              required autoComplete="cc-number"
              disabled={this.state.isLoading}
              className='form-control-xs'
              value={this.state.number}
              onChange={this.onChangeInput}
              invalid={this.state.err_number}
              placeholder={dictionary.credit_card_number} />
          </Col>
        </Row>
        <Row className='pb-3'>
          <Col xs={4}>
            <div className='pb-1 d-flex align-items-center'>
              <Title3 bold danger className='pr-2'>*</Title3>
              <Title3 bold>{dictionary.expiration_month}</Title3>
            </div>
            <Input
              id='ccmonth'
              type='select'
              name="expire_month"
              required autoComplete='cc-exp-month'
              disabled={this.state.isLoading}
              className='form-control-xs'
              onChange={this.onChangeDate}
              invalid={this.state.err_expire_month}
              value={this.state.expire_month}>
              <OptionMonth />
            </Input>
          </Col>
          <Col xs={5}>
            <div className='pb-1 d-flex align-items-center'>
              <Title3 bold danger className='pr-2'>*</Title3>
              <Title3 bold>{dictionary.expiration_year}</Title3>
            </div>
            <Input
              id='ccyear'
              type='select'
              name="expire_year"
              required autoComplete='cc-exp-year'
              disabled={this.state.isLoading}
              className='form-control-xs'
              onChange={this.onChangeDate}
              invalid={this.state.err_expire_year}
              value={this.state.expire_year}>
              <OptionYear />
            </Input>
          </Col>
          <Col xs={3}>
            <div className='pb-1 d-flex align-items-center'>
              <Title3 bold danger className='pr-2'>*</Title3>
              <Title3 bold>cvv</Title3>
            </div>
            <Input
              id='cvc'
              type='password'
              placeholder='cvv'
              name="cvv"
              required autoComplete="cc-csc"
              className='form-control-xs'
              disabled={this.state.isLoading}
              value={this.state.cvv}
              invalid={this.state.err_cvv}
              onChange={this.onChangeInput} />
          </Col>
        </Row>
        <Row className='pb-3'>
          <Col xs={12}>
            <div className='pb-1 d-flex align-items-center'>
              <Title3 bold danger className='pr-2'>*</Title3>
              <Title3 bold>{dictionary.cardholder_name}</Title3>
            </div>
            <Input
              id='ccname'
              type='text'
              name="name"
              autoComplete="cc-name"
              value={this.state.name}
              className='form-control-xs'
              onKeyDown={this.onKeyDown}
              onChange={this.onChangeInput}
              disabled={this.state.isLoading}
              placeholder={dictionary.cardholder_name} />
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Title3 bold danger><span className='text-danger'>* </span>{dictionary.note}</Title3>
            <Title4 secondary className='pl-3'>{`- ${dictionary.card_verification_remark}`}</Title4>
          </Col>
        </Row>


        {
          this.state.isError
          && <Row className='pt-3'>
            <Col xs={12} className='text-center'>
              <Title3 bold danger>{dictionary[this.state.error_msg]}</Title3>
            </Col>
          </Row>
        }
      </ModalBody>
      <form id="2c2p-payment-form" hidden>
        <input name="my2c2p_cc" type="text" data-encrypt="cardnumber" />
        <input name="my2c2p_month" type="text" data-encrypt="month" />
        <input name="my2c2p_year" type="text" data-encrypt="year" />
        <input name="my2c2p_cvv" type="password" data-encrypt="cvv" />
      </form>
      <ModalFooter>
        <div className='w-100 d-flex align-items-center justify-content-between'>
          <div className="d-flex align-items-center">
            <Title4 secondary>Powered by</Title4>
            <img alt='2c2p' src={logo_2c2p} style={{ height: 40 }} />
          </div>
          {
            this.state.isLoading
              ? <InvertBtn disabled>
                <Title3><i className='fas fa-spinner fa-spin' /></Title3>
              </InvertBtn>
              : <div className='d-flex'>
                <InvertBtn onClick={toggleModalCard}>
                  <Title3>{dictionary.close}</Title3>
                </InvertBtn>
                <PrimaryBtn disabled={this.validateField()} onClick={this.createCard}>
                  <Title3 white={!this.validateField()}>
                    {dictionary.add_credit_card}
                  </Title3>
                </PrimaryBtn>
              </div>
          }
        </div>

      </ModalFooter>
    </Modal >
  }
}

const OptionMonth = () => {
  let data = []
  let month = [null, '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
  month.map((value, key) => Boolean(value) && data.push(<option key={key} value={key}>{value}</option>))
  return data
}

const OptionYear = () => {
  let data = []
  let thisYear = new Date().getFullYear()
  let maxYear = thisYear + 8
  for (let index = thisYear; index < maxYear; index++) {
    data.push(<option key={index} value={index}>{index}</option>)
  }
  return data
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language,
    store_payment: state.payment
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    createCustomerPaymentCreditCard
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPaymentModalCard)