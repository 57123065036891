import { useMemo } from "react";
import { useSelector } from "react-redux";

const useNormalTransactionData = () => {
  const { subscription } = useSelector((state) => state.packages);
  const { itemsUsage, freeTransactions } = subscription;

  const normalTransaction = useMemo(() => 
    itemsUsage?.find((item) => item.title_dictionary === "normal_transaction"),
    [itemsUsage]
  );

  const usages = normalTransaction?.usages || [];

  const transaction = useMemo(() => ({
    point: calculateUsage(usages, "point"),
    coupon: calculateUsage(usages, "ecard"),
    broadcast: calculateUsage(usages, "broadcast"),
    member_card: calculateUsage(usages, "member_card"),
    trans_noti_expire_point: calculateUsage(usages, "trans_noti_expire_point"),
    trans_noti_expire_member_card: calculateUsage(usages, "trans_noti_expire_member_card"),
    reject_collect_points: calculateUsage(usages, "reject_collect_points"),
  }), [usages]);

  transaction.total = useMemo(() => 
    Object.values(transaction).reduce((acc, cur) => acc + cur, 0) || 0,
    [transaction]
  );

  transaction.remain = freeTransactions - transaction.total;

  return { transaction };
};

const calculateUsage = (usages, type) =>
  usages
    ?.find((usage) => usage.title_dictionary === type)
    ?.items?.find((item) => item.title_dictionary === "tx")
    ?.amount || 0;

export default useNormalTransactionData;