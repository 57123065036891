import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col } from 'reactstrap'

import { toCurrency } from '../../../libs/currency'
import LoadingGradient from '../../LoadingGradient'
import { Title2, Title3, Title4 } from '../../Font'
import { SubscriptionDetail, BoxPrice, DotNumber, CardDetailBox } from './BusinessSubscriptionModal.style'

import americanExpress from '../../../assets/img/creditCard/americanExpress.svg'
import dinersClub from '../../../assets/img/creditCard/dinersClub.svg'
import discover from '../../../assets/img/creditCard/discover.svg'
import jcb from '../../../assets/img/creditCard/JCB.svg'
import mastercard from '../../../assets/img/creditCard/mastercard.svg'
import unionPay from '../../../assets/img/creditCard/unionPay.svg'
import visa from '../../../assets/img/creditCard/visa.svg'
import pointspot from '../../../assets/img/logo.svg'

import { getCustomerPayment, getCustomerPaymentCreditCard, updateCustomerPaymentCreditCard } from '../../../redux/actions/action_payment'
import SubscriptionDetailAddonItems from './Components/SubscriptionDetailAddonItems'
import BusinessPayment from '../../BusinessPayment'
import { toast } from 'react-toastify'
import { ToastBar } from '../../ToastComponent/Toast.style'

class BusinessSubscriptionDetail extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isOpenCard: false,
    }
    this.toggleChangeCard = this.toggleChangeCard.bind(this)
    this.setDefaultCard = this.setDefaultCard.bind(this)
  }

  componentDidMount() {
    let { store_business: { current: { business_code } } } = this.props

    this._isMounted = true
    this.setState({ isLoading: true }, () => {
      this.props.getCustomerPaymentCreditCard(business_code, () => {
        this.props.getCustomerPayment(business_code, () => {
          if (this._isMounted)
            this.setState({ isLoading: false })
        })
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  numberWithCommas(x) {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  toggleChangeCard() {
    this.setState({ isOpenCard: !this.state.isOpenCard })
  }

  setDefaultCard(card_id) {
    let { store_business: { current: { business_code } } } = this.props
    let { store_language: { dictionary } } = this.props
    this.props.updateCustomerPaymentCreditCard(business_code, card_id, (error, data) => {
      if (Boolean(data)) {
        this.toastShow('success', dictionary.change_credit_card_success)
        this.toggleChangeCard()
        this.props.refreshUsage()
      } else {
        this.toastShow('error', dictionary.change_credit_card_fail)
      }
    })
  }

  toastShow(type, message) {
    toast[type](<ToastBar><Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{message}</Title2></ToastBar>)
  }

  render() {
    let { isLoading, isLoadingUnsub } = this.props
    if (this.state.isLoading || isLoading || isLoadingUnsub) return <LoadingBusinessSubscriptionDetail />

    let { store_language: { dictionary } } = this.props
    let { store_package: { subscription = {} } } = this.props
    let { store_business: { current: { permission } } } = this.props
    const this_permission = (permission && permission["setting-packages"]) || {}

    let imageCardBrand = [
      { name: 'American Express', image: americanExpress },
      { name: 'Diners Club', image: dinersClub },
      { name: 'Discover', image: discover },
      { name: 'JCB', image: jcb },
      { name: 'MasterCard', image: mastercard },
      { name: 'UnionPay', image: unionPay },
      { name: 'Visa', image: visa },
      { name: 'Unknown', image: pointspot },
    ]

    const { collection_method, sub_total, tax_amount, total, total_discount, 
        discount_off, percent_off, discount_name, payment_customer_card, items } = subscription;

    // const fee = ['ps-transaction-add-0.2', 'ps-notification-sms-0.4', 'ps-notification-line-0.1', 'ps-broadcast-line-0.1', 'ps-broadcast-sms-0.4', 'ps-member-add-0.6']
    // _.forEach(items, (item) => {
    //   if (fee.indexOf(item.item_id) > -1) {
    //     item.items_type = 'product_item_fee'
    //   }
    // })
    
    const mainItem = _.find(items, { filter_term: 'ps-package' }) || {};
    const broadcastAddon = _.find(items, { filter_term: 'broadcast_message'});
    const broadcastAddonFeePatt = /(additional).+(broadcast)/
    const transItems = _.filter(items, (item) => {
      return item.items_type !== 'product_item_fee' && (item.filter_term === 'ps-member' || item.filter_term === 'ps-transaction');
    });
    const productItems = _.filter(items, (item) => {
      return (item.items_type !== 'product_item_fee' && item.filter_term !== 'ps-package' && item.filter_term !== 'broadcast_message' 
        && item.filter_term !== 'ps-member' && item.filter_term !== 'ps-transaction' && item.filter_term !== 'advance_apis');
    });
    const overageItems = _.filter(items, (item) => {
      //DEBUG("overageItems", item.name, broadcastAddon, broadcastAddonFeePatt.test(item.name))
      if (!parseInt(item.total_usage)) {
        return false;
      }

      if (broadcastAddon) {
        return item.items_type === 'product_item_fee';
      } else {
        return (item.items_type === 'product_item_fee' && !broadcastAddonFeePatt.test(item.name));
      }
    });

    //DEBUG("separate items", mainItem, productItems, overageItems, items);

    let img_card = payment_customer_card && imageCardBrand.filter(f => f.name.toLocaleLowerCase() === payment_customer_card.brand.toLocaleLowerCase())

    return <Row>
      <Col xs={12} md={12} lg={12} className='p-0'>
        <SubscriptionDetail>
          <div className='p-2 d-flex justify-content-between'>
            <Col xs={7} sm={8} className='px-0'>
              <Title2 bold>{dictionary.current_balance_pending}</Title2>
              <Title4 secondary>{`${dictionary.might_be_changed}`}</Title4>
            </Col>
            <Col xs={5} sm={4} className='px-0'>
              <Title4 secondary bold className='text-right pb-2'>{`${dictionary.payment_method}: ${dictionary[collection_method]}`}</Title4>
              {
                (collection_method === 'charge_automatically' /*&& subscription.metadata && subscription.metadata.uid === profile.uid*/) && payment_customer_card
                  ? <div className='d-flex align-items-center justify-content-end'>
                      <div 
                        className='d-flex border rounded px-1' 
                        onClick={Boolean(this_permission && this_permission.privilege_group_id === 1) && this.toggleChangeCard} 
                        style={{ cursor: Boolean(this_permission && this_permission.privilege_group_id === 1) && 'pointer' }}
                      >
                        <img alt='' src={(img_card && img_card.length > 0) ? img_card[0].image : ''} style={{ width: 35, height: 'auto' }} />
                        <CardDetailBox className='pl-3'>
                          <Title3 bold className='pb-1'>{payment_customer_card.name }</Title3>
                          <div className='d-flex align-items-center'>
                            <DotNumber /><DotNumber /><DotNumber /><DotNumber /><Title4 secondary className='pl-2'>{payment_customer_card.last4}</Title4>
                          </div>
                        </CardDetailBox>
                      </div>
                  </div>
                  : null
              }
              {
                collection_method === 'charge_automatically' && payment_customer_card && Boolean(this_permission && this_permission.privilege_group_id === 1) &&
                  <Title4 link blue bold className='text-right pt-1'
                    onClick={Boolean(this_permission && this_permission.privilege_group_id === 1) && this.toggleChangeCard}
                  >
                    {dictionary.change_credit_card}
                  </Title4>
              }
            </Col>
          </div>
          <div id="columns" className='align-items-baseline border-bottom border-top d-flex justify-content-between pt-2 pb-2'>
            <div className='align-items-baseline d-flex w-100'>
              <Title3 className="col-4" bold>{dictionary.billing_item}</Title3>
              <Title3 className="col-2 text-right" bold>{dictionary.billing_quantity}</Title3>
              <Title3 className="col-3 text-right" bold>{dictionary.billing_unit_price}</Title3>
              <Title3 className="col-3 text-right" bold>{dictionary.billing_amount}</Title3>
            </div>
          </div>
          <div id="ps-package" className='pt-2 pb-2 d-flex align-items-baseline justify-content-between w-100'>
            <Title2 className="col-4" bold>{dictionary.package_items[mainItem.title_dictionary]}</Title2>
            <Title3 className="col-2 text-right" bold>{toCurrency(mainItem.amount || 0, 2)} {dictionary.currency.thb}</Title3>
          </div>
          {
            transItems.map((item, key) => {
              let title = dictionary.package_items[item.title_dictionary] || 'Add-on'
              return <div key={key} className='mb-1 align-items-baseline d-flex w-100'>
                <Title3 className="col-4">{title}</Title3>
                <Title3 className="col-2 text-right" secondary>{this.numberWithCommas(item.total_usage) || 0}</Title3>
              </div>
            })
          }
          {
            productItems.length ? (
              <div id="ps-product" className='pt-2 pb-2 d-flex align-items-baseline justify-content-between w-100'>
                <Title3 className="col-4">{dictionary.feature_package}</Title3>
              </div>
            ) : null
          }
          {
            productItems.map((item, key) => {
              let title = dictionary.package_items[item.title_dictionary] || 'Add-on'
              return <div key={key} className='mb-1 align-items-baseline d-flex w-100'>
                <Title3 className="col-4 pl-5">{title}</Title3>
              </div>
            })
          }
          <SubscriptionDetailAddonItems items={items} />
          {
            overageItems.length ? (
              <div id="ps-package" className='pt-2 pb-2 mt-2 d-flex align-items-baseline justify-content-between'>
                <Title2 className="col-4" bold>{dictionary.billing_overage}</Title2>
              </div>
            ) : null
          }
          {
            overageItems.map((item, key) => {
              let title = dictionary.package_items[item.title_dictionary] || 'Add-on'
              return <div key={key} className='mb-1 align-items-baseline d-flex w-100'>
                <Title3 className={`col-4 pl-5`}>{title}</Title3>
                <Title3 className="col-2 text-right align-self-end" secondary>{this.numberWithCommas(item.total_usage)}</Title3>
                <Title3 className="col-3 text-right align-self-end">{item.amount ? `${toCurrency(item.amount || 0, 2)} ${dictionary.currency.thb}` : ''}</Title3>
                <Title3 className="col-3 text-right align-self-end" bold>{item.sub_total ? `${toCurrency(item.sub_total || 0, 2)} ${dictionary.currency.thb}` : ''}</Title3>
              </div>
            })
          }
          <div className="mt-3"></div>
          {
            total_discount ? <div className='p-2 border-top d-flex align-items-baseline justify-content-end'>
              <Title2 bold className='pr-3'>{dictionary.subscription_coupons[discount_name]}</Title2>
              <BoxPrice>
                {discount_off ? <Title3 bold>{toCurrency(discount_off, 2)} {dictionary.currency.thb}</Title3> : null}
                {percent_off ? <>
                  <Title3 bold>{Number.parseFloat(percent_off).toFixed(2)} %</Title3>
                  <Title4 bold>({toCurrency(total_discount, 2)} {dictionary.currency.thb})</Title4>
                </> : null}
              </BoxPrice>
            </div> : null
          }

          {!!tax_amount
            ? <>
              < div className='p-2 d-flex border-top align-items-baseline justify-content-end'>
                <Title2 bold className='pr-3'>{dictionary.sub_total}</Title2>
                <BoxPrice>
                  <Title3 bold>{toCurrency(sub_total, 2)} {dictionary.currency.thb}</Title3>
                </BoxPrice>
              </div>
              <div className='p-2 d-flex border-top align-items-baseline justify-content-end'>
                <Title2 bold className='pr-3'>{`${dictionary.vat}`}</Title2>
                <BoxPrice>
                  <Title3 bold>{toCurrency(tax_amount, 2)} {dictionary.currency.thb}</Title3>
                </BoxPrice>
              </div>
            </>
            : null
          }
          <div className='p-2 d-flex border-top align-items-baseline justify-content-end'>
            <Title2 bold className='pr-3'>{dictionary.total_balance}</Title2>
            <BoxPrice>
              <Title3 bold>{toCurrency(total, 2)} {dictionary.currency.thb}</Title3>
            </BoxPrice>
          </div>
        </SubscriptionDetail>
      </Col>
      {
        collection_method === 'charge_automatically' && payment_customer_card &&
        <BusinessPayment
          isOpen={this.state.isOpenCard}
          toggleModalPackage={this.toggleChangeCard}
          card_id={payment_customer_card.id}
          isChangeCard={true}
          setDefaultCard={this.setDefaultCard}
          showSteps={[3]}
        />
      }
    </Row >
  }
}

const LoadingBusinessSubscriptionDetail = () => {
  return <Row>
    <Col xs={12} md={12} lg={12} className='p-0'>
      <SubscriptionDetail>
        <div className='p-2 d-flex justify-content-between'>
          <div>
            <div className='pb-1'><LoadingGradient width={'15vw'} minWidth={'120px'} /></div>
            <div className='pb-1'><LoadingGradient width={'12vw'} minWidth={'80px'} /></div>
          </div>
          <div className='d-flex align-items-center'>
            <div className='d-none d-lg-block'>
              <LoadingGradient type='square' width={'35px'} />
            </div>
            <div className='pl-3' style={{ width: '15vw', minWidth: 130 }}>
              <div className='pb-1'><LoadingGradient width={'10vw'} minWidth={'80px'} /></div>
              <div className='d-flex justify-content-between'>
                <div>
                  <LoadingGradient width={'2vw'} minWidth={'15px'} />
                  <LoadingGradient width={'3vw'} minWidth={'25px'} />
                </div>
                <LoadingGradient width={'3vw'} minWidth={'36px'} />
              </div>
            </div>
          </div>
        </div>

        {
          ['1', '1', '1'].map((value, key) => {
            return <div key={key} className='p-2 border-top d-flex align-items-baseline justify-content-between'>
              <LoadingGradient width={'8vw'} minWidth={'60px'} />
              <LoadingGradient width={'5vw'} minWidth={'50px'} />
            </div>
          })
        }

        <div className='p-2 d-flex border-top align-items-baseline justify-content-end'>
          <LoadingGradient width={'6vw'} minWidth={'40px'} />
        </div>

        <div className='p-2 d-flex border-top align-items-baseline justify-content-end'>
          <LoadingGradient width={'12vw'} minWidth={'80px'} />
        </div>

      </SubscriptionDetail>
    </Col>
  </Row>
}

// const getExpCredit = (month, year) => {
//   return `${('0' + String(month)).slice(-2)} / ${String(year).slice(-2)}`
// }

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_business: state.business,
    store_config: state.config,
    store_language: state.language,
    store_package: state.packages,
    store_payment: state.payment,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getCustomerPayment,
    getCustomerPaymentCreditCard,
    updateCustomerPaymentCreditCard
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSubscriptionDetail)