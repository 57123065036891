import React, { useMemo } from "react";
import { HorizontalBar } from "react-chartjs-2";
import useTooltip from "./hooks/useTooltip";
import { ChartColor } from "./ChartConstant";
import createCustomTooltip from "./CustomTooltip";
import { useSelector } from "react-redux";

const BusinessUsageOverviewChartTransaction = ({ transaction }) => {
  const { dictionary } = useSelector((state) => state.language);
  useTooltip();

  const DoughnutSetting = {
    stack: "Stack 1",
    barThickness: 42,
    maxBarThickness: 50,
  };

  const data = useMemo(() => ({
    labels: [dictionary.chart_normal_tx],
    datasets: [
      {
        label: dictionary.chart_point_title,
        data: [transaction.point],
        backgroundColor: ChartColor.point,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_coupon_title,
        data: [transaction.coupon],
        backgroundColor: ChartColor.coupon,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_broadcast_title,
        data: [transaction.broadcast],
        backgroundColor: ChartColor.broadcast,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_member_card_title,
        data: [transaction.member_card],
        backgroundColor: ChartColor.member_card,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_trans_noti_expire_member_card_title,
        data: [transaction.trans_noti_expire_member_card],
        backgroundColor: ChartColor.trans_noti_expire_member_card,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_reject_collect_points_title,
        data: [transaction.reject_collect_points],
        backgroundColor: ChartColor.reject_collect_points,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_trans_noti_expire_point_title,
        data: [transaction.trans_noti_expire_point],
        backgroundColor: ChartColor.trans_noti_expire_point,
        ...DoughnutSetting,
      },
      {
        label: "remain",
        data: [transaction.remain],
        backgroundColor: ChartColor.remain,
        ...DoughnutSetting,
      },
    ],
  }), [dictionary, transaction, DoughnutSetting]);

  const options = useMemo(() => ({
    plugins: {
      datalabels: {
        display: false,
      },
    },
    responsive: true,
    indexAxis: "y",
    legend: false,
    hover: {
      intersect: true,
      mode: "nearest",
    },
    title: {
      display: false,
    },
    tooltips: {
      enabled: false,
      custom: createCustomTooltip('transaction', transaction.total),
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          stepSize: 1,
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
      },
      xAxes: [
        {
          gridLines: { display: false, drawOnChartArea: false, drawTicks: true },
          display: false,
          ticks: {
            min: 0,
            max: transaction.total + transaction.remain,
            callback: function (value) {
              return Number(value.toString());
            },
          },
          afterBuildTicks: function (chartObj) {
            chartObj.ticks = [transaction.total + transaction.remain];
          },
        },
      ],
      yAxes: [
        {
          gridLines: { display: false, drawOnChartArea: false, drawTicks: false },
          display: false,
        },
      ],
    },
  }), [transaction]);

  return <HorizontalBar height={18} data={data} options={options} />;
};

export default BusinessUsageOverviewChartTransaction;