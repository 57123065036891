import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import prepareText from '../../../../libs/prepareText'
import { nl2br } from '../../../../libs/nl2br'
import Loading from '../../../../components/Loading'
import { Title2 } from '../../../../components/Font'
import { InvertBtn, SuccessBtn } from '../../../../components/Button'
import EcardBillingInfo from './billingInfo'
import { getIsOverTrans } from '../../../../redux/actions/action_usage'

export default function ModalConfirmCreate({
	sendSms,
	isOpen,
	toggleModal,
	updateEcoupon,
	method,
	isLoading,
	setCredit
}) {
	const broadcastTarget = useSelector((state) => state.broadcast.broadcast_target)
	const broadcastTargetLoading = useSelector((state) => state.broadcast.broadcast_target_loading)
	const { dictionary } = useSelector((state) => state.language)
	const { current } = useSelector((state) => state.business)
	const { isLoadingOverTrans } = useSelector((state) => state.usage)
	const counterMembers = broadcastTarget.total
	const [disable, setDisable] = useState(false)
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getIsOverTrans(current.business_code))
		//eslint-disable-next-line
	}, [])

	const handleUpdateEcoupon = useCallback(() => {
		if (updateEcoupon && typeof updateEcoupon === 'function') {
			updateEcoupon()
		}
	}, [updateEcoupon])

	const callBackBilling = (data) => {
    setDisable(data.disable)
    setCredit(data.credit)
  } 

	return (
		<Modal fade centered isOpen={isOpen} id="billingInfoStyle">
			<ModalHeader>
				<Title2 bold>{dictionary.ecoupon_confrim_send}</Title2>
			</ModalHeader>
			<ModalBody className='text-center'>
				<div className='d-flex align-items-center justify-content-center' style={{ minHeight: 150 }}>
					{
						broadcastTargetLoading || isLoadingOverTrans
							? <Loading />
							:counterMembers <= 0
								? <Title2 bold>{nl2br(dictionary.not_member_in_target_group)}</Title2>
								: current.is_use_credit ?
									<EcardBillingInfo sendSms={sendSms} callBack={callBackBilling} />
									:
									<div className='w-100 text-center'>
										<Title2 bold>{nl2br(dictionary.ecoupon_costs_approximately)}</Title2>
										<Title2 bold danger>{nl2br(prepareText(dictionary.ecoupon_costs_approximately_transaction, [counterMembers || '0']))}</Title2>
									</div>
					}
				</div>
			</ModalBody>
			<ModalFooter>
				<div className='d-flex justify-content-end'>
					<InvertBtn md bold onClick={toggleModal}>{dictionary.close}</InvertBtn>
					{
						isLoading
							? <SuccessBtn md bold disabled><i className='fas fa-spinner fa-pulse' /></SuccessBtn>
							: <SuccessBtn md bold id={`btn-save_${method.toLowerCase()}`} onClick={handleUpdateEcoupon} hidden={counterMembers <= 0} disabled={disable}>
								{dictionary.submit}
							</SuccessBtn>
					}
				</div>
			</ModalFooter>
		</Modal >
	)
	
}