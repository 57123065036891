import styled, { keyframes } from 'styled-components'

export const TitleHerder = styled.div`
  width: 100%;
  padding: 8px 12px;
  border-radius: 5px;  
  background: ${props => props.theme.primary};
`

const sideShow = keyframes`
    0% { opacity: .3; display: none; max-height: 0px; }
   80% { opacity: .3; display: none; max-height: 0px; }
  100% { opacity: 1; display: block;  max-height: 50px; }
`

export const BoxSkipStep = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  animation-name: ${sideShow};
  animation-duration: 8s;
  animation-delay: -0.5s;
`