import React from 'react'
import ReactPaginate from 'react-paginate'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCronjobDetail } from '../../redux/actions/action_jobs'
import { Col } from 'reactstrap'
class JobsPagination extends React.Component {
  onPageChange(event) {
    let { selected } = event
    let { store_business, store_jobs, getCronjobDetail, type } = this.props
    let { id } = store_jobs.jobs[0]
    let offset = selected * store_jobs.limit
    getCronjobDetail(store_business.current.business_code, id, store_jobs.limit, offset, type)
  }

  render() {
    let { store_jobs } = this.props
    let curPage = store_jobs.currentPageJobs
    let total = store_jobs.totalJobs
    let pageCount = Math.ceil(total / store_jobs.limit)
    return pageCount > 0
      && <Col className="text-center pt-2">
        <div className="d-inline-block">
          <ReactPaginate
            forcePage={curPage}
            pageCount={pageCount}
            previousLabel={<i className="fas fa-chevron-left" />}
            nextLabel={<i className="fas fa-chevron-right" />}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            pageClassName={"page-items"}
            onPageChange={this.onPageChange.bind(this)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"} />
        </div>
      </Col>
  }
}

const mapStateToProps = (state) => {
  return {
    store_members: state.members,
    store_business: state.business,
    store_jobs: state.jobs
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getCronjobDetail
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(JobsPagination)
