import React, { useState } from "react";
import { Title2 } from "../../components/Font";
import {
  BoxHead,
  BoxProcess,
  BoxProcessHead,
} from "./verifyBusinessHash.style";
import { Row, Col } from "reactstrap";
import CardModel from "../../components/CardModel";

export default function CardInfo({ status, onCheck, info }) {
  const [expand, setExpand] = useState(false);

  return (
    <BoxProcess select={!status} hidden>
      <BoxProcessHead select={!status} pointer>
        <div onClick={onCheck}>
          {status ? (
            <i className="far fa-circle pr-2"></i>
          ) : (
            <i className="fas fa-times-circle pr-2"></i>
          )}
        </div>
        <Title2 className="flex-grow-1" onClick={() => setExpand(!expand)} bold white>
          รายละเอียดบัตรเครดิต
        </Title2>
        <div onClick={() => setExpand(!expand)}>
          {expand ? (
            <i className="fas fa-chevron-up"></i>
          ) : (
            <i className="fas fa-chevron-down"></i>
          )}
        </div>
      </BoxProcessHead>
      {expand ? (
        <div>
          {info.status_card !== null ? (
            <div style={{ padding: "20px" }}>
              <Row>
                <Col>
                  <BoxHead>
                    <Title2 bold>
                      {" "}
                      <span className="text-danger">* </span>
                      บัตรเครดิตที่ใช้ในการยืนยันธุรกิจ
                    </Title2>
                  </BoxHead>
                  <div className="d-flex justify-content-center">
                    <CardModel
                      card_last4={info.card_last4}
                      card_type={info.card_type}
                      title_card={"Verified CreditCard"}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <BoxHead center>
              <Title2 bold danger>
                ไม่มีข้อมูลการยืนยันบัตรเครดิต
              </Title2>
            </BoxHead>
          )}
        </div>
      ) : null}
    </BoxProcess>
  );
}
