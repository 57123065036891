import axios from './../axios_request';
import { ERROR, DEBUG } from '../../libs/utils'
import { businessAPI } from '../end_point'
import { TRANSACTIONS } from '../actions_const'
import { getCookie } from '../../libs/cookies';
import { CancelToken, Cancel } from 'axios'

export const getTransactions = ({ business_code, limit, offset, group_type = null, type = null, admin = null, trans_label = null, member_label = null, product_id = null, start_date = null, end_date = null }, callback) => {
  return dispatch => {
    dispatch({ type: TRANSACTIONS.GET_LIST, isFetching: true, offset: 0, total: 0, data: [] })
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/transactions`,
      headers: { Authorization: getCookie("_token") },
      data: { limit, offset, group_type, type, admin, trans_label, member_label, product_id, start_date, end_date }
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data);
      if (!data.result)
        return dispatch({ type: TRANSACTIONS.GET_LIST, offset: 0, total: 0, data: [] })
      else
        return dispatch({ type: TRANSACTIONS.GET_LIST, offset: data.offset, total: data.total, data: data.data })

    }).catch(err => {
      if (typeof callback === 'function') callback(err);
      return dispatch({ type: TRANSACTIONS.GET_LIST, offset: 0, total: 0, data: [] })

    })
  }
}

export const getTransactionsSendmail = ({ business_code, group_type = null, type = null, admin = null, trans_label = null, member_label = null, product_id = null, start_date = null, end_date = null, sendmail = null, language = 'th' }, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/transactions/sendmail`,
      headers: { Authorization: getCookie("_token") },
      data: { limit: 0, offset: 0, group_type, type, admin, trans_label, member_label, product_id, start_date, end_date, sendmail, language }
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data);
    }).catch(err => {
      if (typeof callback === 'function') callback(err);
    })
  }
}

export const getTransactionsDownload = ({ business_code, group_type = null, type = null, admin = null, trans_label = null, member_label = null, product_id = null, start_date = null, end_date = null }, callback) => {
  return dispatch => {
    const source = CancelToken.source()
    dispatch({ type: TRANSACTIONS.SET_EXPORT_POINT_TRANSACTION_CANCEL_SOURCE, data: { source } });
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/transactions`,
      headers: { Authorization: getCookie("_token") },
      data: { limit: 0, offset: 0, group_type, type, admin, trans_label, member_label, product_id, start_date, end_date },
      cancelToken: source.token
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data);
      dispatch({ type: TRANSACTIONS.SET_EXPORT_POINT_TRANSACTION_CANCEL_SOURCE, data: { source: false } })
    }).catch(err => {
      dispatch({ type: TRANSACTIONS.SET_EXPORT_POINT_TRANSACTION_CANCEL_SOURCE, data: { source: false } })
      if (err instanceof Cancel) {
        DEBUG('getReportPointTransactions canceled')
      } else {
        if (typeof callback === 'function') callback(err);
      }
    })
  }
}

export const getTransactionsWithFilter = (business_code, limit, offset, search_by, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    dispatch({ type: TRANSACTIONS.GET_FILTER_LIST, isFetching: true, offset: 0, total: 0, data: [] })
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/transactions`,
      headers: { Authorization: getCookie("_token") },
      data: { limit, offset, search_by }
    }).then(({ data }) => {
      if (!data.result) {
        isFunc && callback(!data.result)
        return dispatch({ type: TRANSACTIONS.GET_FILTER_LIST, offset: 0, total: 0, data: [] })
      }

      isFunc && callback(null, data.data)
      return dispatch({ type: TRANSACTIONS.GET_FILTER_LIST, offset: data.offset, total: data.total, data: data.data })

    }).catch((err) => {
      isFunc && callback(err)
      return dispatch({ type: TRANSACTIONS.GET_FILTER_LIST, offset: 0, total: 0, data: [] })

    })
  }
}

export const getProfileTransactionWithTransId = (business_code, trans_id) => {
  return dispatch => {
    dispatch({ type: TRANSACTIONS.TRANSACTIONS_LOADING })
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/transactions/${trans_id}`,
      headers: { Authorization: getCookie("_token") }
    }).then(data => {
      if (data.data.result)
        dispatch({ type: TRANSACTIONS.GET_TRANSACTION_WITH_TRANDID, data: data.data })
      else
        dispatch({ type: TRANSACTIONS.GET_TRANSACTION_WITH_TRANDID, data: [] })
    }).catch(err => {
      dispatch({ type: TRANSACTIONS.GET_TRANSACTION_WITH_TRANDID, data: [] })
    })
  }
}

export const getProductWithTransId = ({ business_code, trans_id }, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/transactions/product`,
      headers: { Authorization: getCookie("_token") },
      data: { trans_id }
    }).then(({ data }) => {
      if (data.result)
        return isFunc && callback(null, data.data)
      else
        return Promise.reject(data.error || 'result error')
    }).catch(err => {
      return isFunc && callback(err, null)
    })
  }
}

export const clearProfileTransactionWithTransId = () => {
  return dispatch => {
    dispatch({ type: TRANSACTIONS.CLEAR_TRANSACTION_WITH_TRANDID })
  }
}

export const activateTransaction = (business_code, trans_id, redeem_code, remark = null, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    dispatch({ type: TRANSACTIONS.TRANSACTIONS_LOADING2, data: true })
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/activation/${redeem_code}`,
      headers: { Authorization: getCookie("_token") },
      data: {
        trans_id: trans_id,
        redeem_code: redeem_code,
        remark: remark
      }
    }).then(({ data }) => {
      dispatch({ type: TRANSACTIONS.TRANSACTIONS_LOADING2, data: false })
      if (!data.result)
        return isFunc && callback(!data.result)

      isFunc && callback(null, trans_id)
      return dispatch({ type: TRANSACTIONS.ACTIVATE_CODE, data: data, trans_id: trans_id })

    }).catch((error) => {
      isFunc && callback(error)
      return dispatch({ type: TRANSACTIONS.TRANSACTIONS_LOADING2, data: false })
    })
  }
}

export const getMemberProfileByMobile = (business_code, searchKey, callback) => {
  let isFunc = (typeof callback === 'function')
  return dispatch => {
    dispatch({ type: TRANSACTIONS.GET_MEMBER_PROFILE, data: [] })
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/member/${searchKey}`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      if (!data.result || !data.data.data.length)
        return isFunc && callback(true)

      isFunc && callback(null, data.data.data)
      return dispatch({ type: TRANSACTIONS.GET_MEMBER_PROFILE, data: data.data.data })

    }).catch(err => {
      return isFunc && callback(err.message)

    })
  }
}

export const clearProfileSearch = () => {
  return dispatch => dispatch({ type: TRANSACTIONS.CLEAR_SEARCH })
}

export const clearTransactionsState = () => {
  return dispatch => dispatch({ type: TRANSACTIONS.CLEAR_STATE })
}

export const toggleTransactionPersonal = (toggle) => {
  return dispatch => dispatch({ type: TRANSACTIONS.TOGGEL_TRANSACTION_PERSONAL, data: toggle })
}

export const getTransactionsRedeemRewards = ({ business_code, start_date, end_date, coupon_status = null, delivery_status = null, ecard_id = null, limit = null, offset = null, join_address = false }, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/transactions/ecard/reward`,
      headers: { Authorization: getCookie("_token") },
      data: { business_code, start_date, end_date, coupon_status, delivery_status, ecard_id, limit, offset, join_address }
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data)
        return dispatch({ type: TRANSACTIONS.GET_REDEEM_REWARDS, data: data.data })
      } else {
        if (typeof callback === 'function') callback('result error')
        return dispatch({ type: TRANSACTIONS.GET_REDEEM_REWARDS })
      }
    }).catch((err) => {
      ERROR('getTransactionsRedeemRewards', err)
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: TRANSACTIONS.GET_REDEEM_REWARDS })
    })
  }
}

export const updateDeliveryStatus = ({ trans_id, to_status, business_code }, callback) => {
  let isFunc = (typeof callback === 'function')
  return dispatch => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/transactions/ecard/delivery_status`,
      headers: { Authorization: getCookie("_token") },
      data: { trans_id: trans_id, to_status: to_status }
    }).then(({ data }) => {
      if (!data.result)
        return Promise.reject((data && data.error) || 'result error')

      isFunc && callback(null, data.data)
      return dispatch({ type: TRANSACTIONS.UPDATE_REDEEM_REWARDS, data: data.data })

    }).catch(err => {
      ERROR('updateDeliveryStatus', err)
      isFunc && callback((err && err.message) || err)
      return dispatch({ type: TRANSACTIONS.UPDATE_REDEEM_REWARDS })

    })
  }
}