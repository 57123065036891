export function toCreditCard(number) {
  let americamExpress = ['34', '37']
  let dinersClub = ['30', '36', '38']

  number = number.toString().replace(/[\W\D\s_]+/g, '')

  if (number.length >= 2) {
    let preflixType = number.substr(0, 2)
    if (americamExpress.indexOf(preflixType) > -1) return formatCard('Americam Express', number)
    else if (dinersClub.indexOf(preflixType) > -1) return formatCard('Diners Club', number)
    else return formatCard('all', number)
  } else {
    return number
  }
}

const formatCard = (type, number) => {
  let split
  let chunk = []

  if (type === 'Americam Express') split = [4, 6, 5]
  else if (type === 'Diners Club') split = [4, 6, 4]
  else split = [4, 4, 4, 4]

  for (let index = 0, i = 0, len = number.length; i < len; i += split[index], index++) {
    chunk.push(number.substr(i, split[index]))
  }
  return chunk.join(' ')
}
