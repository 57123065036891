import package_config from './../../../package.json'

const ReducerConfig = (state = {}, action) => {
  return Object.assign({}, state, {
    application: {
      name: package_config.appDisplay,
      version: package_config.version
    }
  })
}

export default ReducerConfig;