import moment from 'moment'
import { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sumDeciNatural } from '../../../libs/sumDeciNatural'
import { getProductsByID } from '../../../redux/actions/action_product'

export const useAddProductPoint = (initiateList) => {
	const setInitProduct = useMemo(() => ({
        product_list: initiateList || [],
        amount: 0,
        point: 0
    }), [initiateList]);
	const dispatch = useDispatch()
	const isMounted = useRef()
	const [productList, setProductList] = useState(setInitProduct)
	const [isLoading, setLoading] = useState(false)
	const store_business = useSelector((state) => state.business)
	const business_code = store_business.current.business_code
	const pointsConversion = store_business.current.points_conversion

	const handlerProductList = useCallback((list) => {
		let setProduct = setInitProduct
		if (list && list.length) {
			let product = []
			let points = 0
			let amount = 0
			for (const val of list) {
				product.push({
					...val,
					product_id: val.product_id,
					product_name: val.product_name,
					amount: val.amount || '',
					points: val.points || '',
					points_ratio: val.points_ratio || pointsConversion,
				})	

				amount += Number(val.amount) || 0
				points += Number(val.points) || 0
			}
			setProduct = {
				product_list: product,
				amount: sumDeciNatural(amount),
				point: Math.floor(sumDeciNatural(points))
			}
		} else {
			setProduct = {
				...setInitProduct,
				product_list: []
			}
		}
		setProductList(setProduct)
	}, [pointsConversion, setInitProduct])

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true
			if (productList.product_list.length) {
				setLoading(true)
				dispatch(getProductsByID({ business_code, products_id: productList.product_list }, (err, data) => {
					setLoading(false)
					handlerProductList(data)
				}))
			}
		}
	}, [dispatch, business_code, productList, handlerProductList])

	return [productList, handlerProductList, isLoading]
}

export const useChangeDate = (init) => {
	let [groupDate, setGroupDate] = useState(init || {
		type: 'this_month',
		start_date: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
		end_date: moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
		group_time: 'month',
	})

	function groupDateControl(date) {
		if (date && date !== '') {
			setGroupDate({
				...groupDate,
				type: date.type || 'this_month',
				start_date: date.start_date || moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
				end_date: date.end_date || moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
				group_time: date.group_time || 'month'
			})
		}
	}

	return [groupDate, groupDateControl]
}
