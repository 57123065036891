import './style.css'
import React from 'react'
import { IconLoading } from './switch.style'

class SwitchToggle extends React.Component {
  render() {
    let { disabled, isLoading, active, toggle, opacity = 1 } = this.props

    let cursor = disabled ? 'not-allowed' : 'pointer'
    return isLoading
      ? <div className='d-flex align-items-center justify-content-center' style={{ minWidth: '46px' }}>
        <IconLoading className='fas fa-spinner fa-pulse' />
      </div>
      : <div
        style={{ cursor: cursor, opacity: opacity}}
        onClick={disabled ? null : toggle}
        className={`member-switch ${isLoading && 'loading'} ${active ? 'on' : 'off'} `}>
      </div>
  }
}

export default SwitchToggle 