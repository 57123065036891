
import React, { useState, useEffect, useRef, useCallback} from 'react'

const LazyImg = ({classLazyLoad, offset = 400, dataSrc, placeholder, style}) => {

  const [isPreload, setIsPreload] = useState(false);

  const lazyImage = useRef()

  const preload = (src) => {
    setIsPreload({ isPreload: true })
    let image = new Image()
    image.src = src
    return image
  }

  const loadImage = useCallback(() => {
    let { src } = lazyImage.current.dataset
    let scope = window.innerHeight;
    let y = lazyImage.current.getBoundingClientRect().y - offset
    if (lazyImage.current.src === src || y > scope) return

    if (!isPreload) {
      let image = preload(src)
      lazyImage.current.src = image.src
    }
  }, [isPreload, offset])

  useEffect(() => {
    let elem = window.document.querySelector(`.${classLazyLoad || 'Main-Content'}`)
  
    if (elem)
      elem.addEventListener('scroll', loadImage())
    window.addEventListener('resize', loadImage())
    return () => {
      if (elem)
        elem.removeEventListener('scroll', loadImage())
      window.removeEventListener('resize', loadImage())
    }
  }, [classLazyLoad, loadImage])
  

  return (
    <img
    onLoad={loadImage}
    alt=""
    ref={lazyImage}
    className="w-100"
    style={style}
    data-src={dataSrc}
    src={dataSrc} />
  )
}

export default LazyImg;
