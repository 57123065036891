import { toCurrency2 } from '../../../../../libs/currency';
import { theme } from '../../../../../styles/theme';

const CustomTooltip = (title, total) => {
  return function (tooltipModel) {
    let tooltipEl = document.getElementById("chartjs-tooltip");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.innerHTML = "<table></table>";
      document.body.appendChild(tooltipEl);
    }

    if (tooltipModel.opacity === 0 || total === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    tooltipEl.className = tooltipModel.yAlign ? tooltipModel.yAlign : "no-transform";

    const getBody = (bodyItem) => bodyItem.lines;

    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map(getBody);
      const arrowStyle = `
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 10px solid rgba(0, 0, 0, 0.70);
        position: absolute;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
      `;
      let innerHtml = `
        <div style="position:relative">
          <div style="${arrowStyle}"></div>
          <div style="display: flex; flex-direction: column; padding: 10px;">
      `;

      titleLines.forEach((titleHeader) => {
        innerHtml += `
          <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px;">
            <span>${titleHeader}</span>
            <span style="font-size: 14px;">${title === "transaction" ? toCurrency2(total) : toCurrency2(total, 2)}</span>
          </div>
        `;
      });

      bodyLines.forEach((body, i) => {
        const colors = tooltipModel.labelColors[i];
        const [typeName, typeValue] = body[0].split(":");
        if (typeValue.trim() !== "0" && typeName !== "remain") {
          const style = `
            background: ${colors.backgroundColor}; 
            width: 15px; 
            height: 15px; 
            display: inline-block; 
            margin-right: 10px;
          `;
          innerHtml += `
            <div style="display: flex; align-items: center; justify-content: space-between; margin-bottom: 5px; min-width: 250px;">
              <div style="display: flex; align-items: center;">
                <span style="${style}"></span>
                <span>${typeName}</span>
              </div>
              <div style="margin-left: 10px;">
                <span>${title === "transaction" ? toCurrency2(typeValue) : toCurrency2(typeValue.trim(), 2)}</span>
              </div>
            </div>
          `;
        }
      });

      innerHtml += "</div></div>";
      tooltipEl.innerHTML = innerHtml;
    }

    const position = this._chart.canvas.getBoundingClientRect();
    const chartCenterX = position.left + window.scrollX + position.width / 2;
    const chartCenterY = position.top + window.scrollY + position.height / 2;

    Object.assign(tooltipEl.style, {
      opacity: 1,
      backgroundColor: "rgba(0, 0, 0, 0.70)",
      color: "#fff",
      borderRadius: "4px",
      position: "absolute",
      zIndex: 9999,
      left: `${chartCenterX}px`,
      top: `${chartCenterY}px`,
      transform: "translate(-50%, -50%)",
      fontFamily: theme.font_style.primary,
      fontSize: `${tooltipModel.bodyFontSize}px`,
      fontStyle: tooltipModel._bodyFontStyle,
      padding: `${tooltipModel.yPadding}px ${tooltipModel.xPadding}px`,
      pointerEvents: "none",
    });
  };
};

export default CustomTooltip;