import React, { Component } from 'react';
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col } from 'reactstrap'

import { setCookie } from '../../libs/cookies';
import { getBusinessLabel } from '../../redux/actions/action_business'

import { LabelContainerModal, IconLabelSearch, LabelBar, LabelAddButton } from './label.style'
import Label from './Label'
import { Title2, Title3 } from '../Font'
import { SuccessBtn, InvertBtn } from '../Button'
import Loading from '../Loading';



class LabelSelectModal extends Component {
    _isMounted = false
    constructor(props) {
        super(props);
        this.state = {
            labelList: [],
            labelSelect: [],
            labelSearch: '',
            isLoadingGet: false,
        }
    }

    componentDidMount() {
        let { selected } = this.props
        let { type, excludeArchive } = this.props
        let { store_business: { current: { business_code } } } = this.props

        if (selected && selected.length)
            this.setState({ labelSelect: selected })

        this.setState({ isLoadingGet: true })
        this.props.getBusinessLabel({ business_code, excludeArchive: excludeArchive || true, tag_type: type || '' }, (err, data) => {
            if (this._isMounted)
                return null
            this.setState({ isLoadingGet: false, labelList: data })
        })
    }

    componentWillUnmount() {
        this._isMounted = true
        this.clearSearch()
        this.setState({ labelSelect: [] })
    }

    clickToScroll = () => {
        const element = document.getElementById('label_bar');
        let isDown = false, startX, scrollLeft;

        if (!element) return null

        element.addEventListener('mouseenter', () => {
            if (element.scrollWidth > element.clientWidth)
                element.style.cursor = "grab";
        });
        element.addEventListener('mousedown', (e) => {
            isDown = true;
            startX = e.pageX - element.offsetLeft;
            scrollLeft = element.scrollLeft;
        });
        element.addEventListener('mouseup', () => { isDown = false });
        element.addEventListener('mousemove', (e) => {
            if (!isDown) return;
            e.preventDefault();
            const x = e.pageX - element.offsetLeft;
            const walk = (x - startX) * 3; //scroll-fast
            element.scrollLeft = scrollLeft - walk;
        });
    }

    onChangeSearch = (event) => {
        let { name, value } = event.target
        this.setState({ [name]: value })
    }

    clearSearch = () => {
        this.setState({ labelSearch: '' })
    }

    selectLabel = (label) => {
        if (this.state.labelSelect.map(v => v.tag_id).indexOf(label.tag_id) >= 0)
            return this.setState({ labelSelect: this.state.labelSelect.filter(v => v.tag_id !== label.tag_id) }, () => {
                if (this.state.labelSelect.length > 0)
                    this.clickToScroll()
            })

        this.setState({ labelSelect: [...this.state.labelSelect, label] }, () => {
            if (this.state.labelSelect.length > 0)
                this.clickToScroll()
        })
    }

    submitLabel = () => this.props.onChange(this.state.labelSelect)

    render() {
        let { store_language: { dictionary } } = this.props

        let { labelList, labelSelect, labelSearch } = this.state
        let { isOpen, toggle, modalTitle, isLoading, limit_label, unselect, ignoreList } = this.props

        let isOverLimit = (this.state.labelSelect.length && limit_label) ? this.state.labelSelect.length >= limit_label : false

        // if (!labelList || (labelList && labelList.length === 0)) return null

        if (labelSearch)
            labelList = labelList.filter(val => val.tag_name.toLowerCase().indexOf(labelSearch.toLowerCase()) > -1)

        if (ignoreList && ignoreList.length > 0)
            labelList = labelList.filter(f => ignoreList.indexOf(f.tag_id) < 0)

        return <Modal isOpen={isOpen} centered>
            <ModalHeader toggle={toggle} className='d-flex align-items-center'>
                <Title2 bold>{`${modalTitle || dictionary.label} ${limit_label ? `(${labelSelect.length || 0}/${limit_label})` : ``}`}</Title2>
            </ModalHeader>
            <ModalBody style={{ padding: 0 }}>
                <div className='w-100 p-1 position-relative border-bottom'>
                    <IconLabelSearch pointer={Boolean(labelSearch)} className={`${Boolean(labelSearch) ? 'fas fa-times' : 'fas fa-search'}`} onClick={Boolean(labelSearch) ? this.clearSearch : null} />
                    <Input
                        type='text'
                        name='labelSearch'
                        autoFocus={isOpen}
                        value={labelSearch}
                        autoComplete='off'
                        style={{ paddingRight: '2rem' }}
                        className='form-control-xs'
                        onChange={this.onChangeSearch} />
                </div>
                {
                    (labelSelect && labelSelect.length > 0)
                    && <LabelBar id='label_bar'>
                        {
                            labelSelect.map((v) => {
                                return <div className='p-1' key={v.tag_id}>
                                    <Label
                                        formLabel
                                        noWidth
                                        type={v.tag_type}
                                        name={<>{v.tag_name} <i className='fas fa-close fa-lg pl-1 pointer' hidden={!unselect} onClick={() => this.selectLabel(v)} /></>}
                                        color={v.tag_style.tag_color}
                                        style={{ whiteSpace: 'nowrap', minWidth: 60 }} />
                                </div>
                            })
                        }
                    </LabelBar>
                }
                <LabelContainerModal>
                    <Row className='h-100'>
                        {
                            (labelList && labelList.length > 0)
                                ? labelList
                                    .sort((a, b) => a.tag_name.localeCompare(b.tag_name))
                                    .map((val) => {
                                        let select = labelSelect && labelSelect.map(v => v.tag_id).indexOf(val.tag_id) >= 0
                                        return <Col xs={12} sm={6} key={val.tag_id} className='pb-1 d-flex align-items-center justify-content-center'>
                                            <Label
                                                type={val.tag_type}
                                                check={select}
                                                name={val.tag_name}
                                                color={val.tag_style.tag_color}
                                                pointer={isOverLimit ? select : true}
                                                onClick={(isOverLimit && !select) ? null : () => this.selectLabel(val)} />
                                        </Col>
                                    })
                                : <Col xs={12} className='w-100 d-flex align-items-center justify-content-center mt-4'>
                                    {
                                        this.state.isLoadingGet
                                        ? <Loading />
                                            : <AddMemberLabel />
                                    }
                                </Col>
                        }
                    </Row>
                </LabelContainerModal>
            </ModalBody>
            <ModalFooter>
                {
                    isLoading
                        ? <SuccessBtn disabled bold md><i className="fas fa-spinner fa-pulse" /></SuccessBtn>
                        : <>
                            <InvertBtn bold md onClick={toggle}>{dictionary.cancel}</InvertBtn>
                            <SuccessBtn bold md disabled={limit_label ? (labelSelect.length || 0) > limit_label : false} onClick={this.submitLabel}>{dictionary.confirm}</SuccessBtn>
                        </>
                }
            </ModalFooter>
        </Modal>
    }
}

const AddMemberLabel = () => {
    const dictionary = useSelector((state) => state.language.dictionary)

    const setModalOpen = () => {
        let minutes = 0.5
        let date = new Date()
        date = date.setTime(date.getTime() + (minutes * 60 * 1000))
        setCookie('__MEMBER_LABEL', true, date)
    }

    return (
        <Link to={'/label'} onClick={setModalOpen} style={{ textDecoration: 'none' }}>
        <div className='d-flex align-items-center justify-content-center flex-column' style={{ width: '100%', height: '100%' }}>
            <Title3 className='pb-4' secondary center>{dictionary.label_not_found}</Title3>
            <LabelAddButton className='p-3'>
                <Title3 bold>{dictionary.add_member_label}</Title3>
            </LabelAddButton>
        </div>
        </Link>
    )
}
  

const mapStateToProps = (state) => {
    return {
        store_language: state.language,
        store_business: state.business,
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators({
        getBusinessLabel
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelSelectModal)