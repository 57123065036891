import { ModalFooter, ModalHeader } from 'reactstrap'
import styled, { css } from 'styled-components'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  border: 1px solid #000;
  padding: 10px;
  background: ${props => props.theme.primary};
`

export const BoxProcess = styled.div`
  border: ${props => props.select ? `1px solid #BF0413;` : `1px solid ${props.theme.primary};`}
  margin: 20px 0px;
  ${props => props.center && css`display: flex;align-items: center;justify-content: center;`}
`

export const VerifyBusinessHashDetail = styled.div`
  padding: 15px 0px;
  display: flex;
  align-items: center;
  ${props => props.first
    ? css`justify-content: flex-start;`
    : css`justify-content: center;`
  }

  ${props => props.borderTop
    && css`border-top: 1px solid #F0F0F0;`
  }
`

export const VerifyBusinessHashDetail2 = styled.div`
  width: 100%;
  padding-top: 15px;
  display: flex;
  align-items: center;
  ${props => props.first
    ? css`justify-content: flex-start;`
    : css`justify-content: center;`
  }
  ${props => props.flexEnd && css`justify-content: flex-end;`}
  ${props => props.borderTop
    && css`border-top: 1px solid #F0F0F0;`
  }
  ${props => props.tab
    && css`padding-left: 15px;`
  }
`

export const BoxProcessHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  border: 1px solid #000;
  padding: 10px;
  ${props => props.select ? css`background: #BF0413;` : css`background: ${props.theme.primary};`}
  cursor: ${props => props.pointer && 'pointer'};
`

export const BoxHead = styled.div`
  padding: 5px 0px;
  margin: 5px 15px 0px 15px;
  ${props => props.center ? css`display: flex;align-items: center;justify-content: center;` : null}
`

export const BoxDetail = styled.div`
  border-radius: 5px;
  border: 1px solid #cdd4db;
  padding: 5px 10px;
  margin: 0px 15px 10px 15px;
  ${props => props.select ? css`background: #BF0413;` : css`background: #e9ecef;`}
`

export const ModelDetailFooter = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  padding-top: 10px;
  border-top: 1px solid ${props => props.theme.third};
  ${props => props.center ? css`justify-content: center;` : css`justify-content: flex-end;`}
`

export const VerifyModalHeader = styled(ModalHeader)`
  background: ${props => props.theme.primary};
`

export const VerifyModalFooter = styled(ModalFooter)`
  justify-content: flex-end;
`