import { css } from 'styled-components';
import { theme } from './theme';

export const TooltipStyles = css`
	.tooltip .tooltip-inner {
		background-color: ${theme.tooltip_bg} !important;
		color: ${theme.tooltip_text_color};
		font-family: ${theme.font_style.primary} !important;
	}

	.tooltip.show {
		opacity: 1 !important;
	}

	.bs-tooltip-top .arrow::before, 
	.bs-tooltip-auto[x-placement^="top"] .arrow::before {
		border-top-color: ${theme.tooltip_bg} !important;
	}

	.bs-tooltip-bottom .arrow::before, 
	.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
		border-bottom-color: ${theme.tooltip_bg} !important;
	}

	.bs-tooltip-left .arrow::before, 
	.bs-tooltip-auto[x-placement^="left"] .arrow::before {
		border-left-color: ${theme.tooltip_bg} !important;
	}

	.bs-tooltip-right .arrow::before, 
	.bs-tooltip-auto[x-placement^="right"] .arrow::before {
		border-right-color: ${theme.tooltip_bg} !important;
	}
`