import { AddonsImages, AddonsLabelList } from './BusinessPayment.style'
import { Col, ModalBody, ModalFooter, Row } from 'reactstrap'
import { DescriptionBar, TitleBar } from './Card/BusinessPaymentCard.style'
import { InvertBtn, PrimaryBtn } from '../Button'

import CreditCard from '../BusinessPayment/Card/CreditCard'
import { ModalBodyBox } from './BusinessPayment.style'
import React from 'react'
import { Title3, Title4 } from '../Font'

class BusinessPaymentDescription extends React.Component {

  componentDidMount() {
    let { store_language: { dictionary } } = this.props
    this.props.setHeaderTitle(dictionary.show_detail)
  }

  render() {
    let { prevStep, subscriptionBusiness, isLoadingState } = this.props
    let { store_language: { dictionary } } = this.props
    let { store_payment: { card } } = this.props

    return (
      <>
        <ModalBodyBox>
          <ModalBody>
            <Row>
              <Col xs={12} md={6}>
                <TitleBar>
                  <Title3 bold>{dictionary.company_name}</Title3>
                </TitleBar>
                <DescriptionBar>
                  <Title3 className='pl-2'>{this.props.company}</Title3>
                  <Title3 className='pl-2'>{this.props.tax_id}</Title3>
                </DescriptionBar>
              </Col>
              <Col xs={12} md={6}>
                <div>
                  <TitleBar>
                    <Title3 bold>{`${dictionary.contact_invoice} / ${dictionary.contact_receipt}`}</Title3>
                  </TitleBar>
                  <DescriptionBar>
                    <div className='w-100 pl-2'>
                      <Title3>{this.props.line1}</Title3>
                      <Title3>{this.props.line2}</Title3>
                    </div>
                    <div className='w-100 pl-2 d-flex align-items-center'>
                      <Title3>{this.props.city},</Title3>
                      <Title3 className='pl-2'>{this.props.postal_code},</Title3>
                      <Title3 className='pl-2'>{this.props.country}</Title3>
                    </div>
                    <div className='w-100 pl-2'>
                      <Title3>{this.props.phone}</Title3>
                    </div>
                  </DescriptionBar>
                </div>
              </Col>
              <Col xs={12} md={this.props.addon.length > 0 ? 6 : 12}>
                <TitleBar>
                  <Title3 bold>{`${dictionary.credit_card} / ${dictionary.debit_card}`}</Title3>
                </TitleBar>
                <DescriptionBar>
                  {
                    card && card.map((value, key) => {
                      return value.id === this.props.card_id
                        && <CreditCard
                          readOnly
                          key={key}
                          Id={value.id}
                          Name={value.name}
                          Brand={value.brand}
                          Last4={value.last4}
                          ExpYear={value.exp_year}
                          ExpMonth={value.exp_month} />
                    })
                  }
                </DescriptionBar>
              </Col>
              {
                this.props.addon.length > 0
                && <Col xs={12} md={6}>
                  <TitleBar> <Title3 bold>{dictionary.addons}</Title3></TitleBar>
                  <DescriptionBar>
                    {
                      this.props.addon.map(val => {
                        return <AddonsLabelList className='mb-2' key={val.id}>
                          <AddonsImages IconSmall className='mr-2' src={val.img} alt={val.name} />
                          <Title3 bold>
                            {val.name}
                            {/* {' '}{(val.id === 'premium_id' && this.props.premium_id) && `( ${this.props.premium_id} )`} */}
                          </Title3>
                        </AddonsLabelList>
                      })
                    }
                  </DescriptionBar>
                </Col>
              }
            </Row>
            <div className='w-100 border-top pt-3 px-3'>
              <Title3 bold danger><span className='text-danger'>* </span>{dictionary.note}</Title3>
              <Title4 bold secondary className='pl-3'>{`- ${dictionary.card_verification_remark}`}</Title4>
            </div>
          </ModalBody>
        </ModalBodyBox>
        <ModalFooter>
          <div className='w-100 d-flex align-items-center justify-content-between'>
            <InvertBtn md bold onClick={prevStep} disabled={isLoadingState}>{dictionary.back}</InvertBtn>
            <PrimaryBtn md bold onClick={!isLoadingState ? subscriptionBusiness : null}>
              {
                isLoadingState
                  ? <i className="fas fa-spinner fa-pulse" />
                  : dictionary.subscription_package
              }
            </PrimaryBtn>
          </div>
        </ModalFooter>
      </>
    )
  }
}

export default BusinessPaymentDescription