import { SUBSCRIBE } from '../actions_const'

let initState = {
  isError: false,
  subscribe: [],
}

const ReducerSubscribe = (state = initState, action) => {
  switch (action.type) {
    case SUBSCRIBE.IS_PROCESS:
      return Object.assign({}, state, {
        isError: false,
      })

    case SUBSCRIBE.IS_ERROR:
      return Object.assign({}, state, {
        isError: true
      })

    case SUBSCRIBE.SET_SUBSCRIBE:
      return Object.assign({}, state, {
        isError: false,
        subscribe: [Object.assign({}, state.subscribe[0], action.data[0])],
      })

    default:
      return state
  }
}


export default ReducerSubscribe;