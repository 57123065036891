import _ from 'lodash'
import React from 'react'
import { Row, Col } from 'reactstrap'

import { Title3 } from '../Font'
import { toCurrency } from '../../libs/currency'

import {
  ReviewRatelStar, ReviewBoxStar, ReviewTitleStar,
  ReviewTitlePercent, ReviewBar, ReviewBarPercent,
  ReviewRatelTotal, ReviewTitleStarBox, ReviewIcon,
  ReviewTotalStar, ReviewTitleStarBoxtotal, ReviewTitleReview,
} from './BusinessReview.styled'

class BusinessReviewChart extends React.Component {
  render() {
    let { dictionary, reviews } = this.props
    let { reviews: { total, reviews_rate } } = this.props

    if (_.isEmpty(reviews)) return null

    return <Row>
      <Col xs={12} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
        <Row>
          <Col xs={3} className='pr-0'>
            <ReviewRatelTotal>
              <div className='text-center'>
                <ReviewTitleStarBox>
                  <ReviewIcon className='fas fa-star' />
                  <ReviewTotalStar>{total.star}</ReviewTotalStar>
                </ReviewTitleStarBox>
                <ReviewTitleStarBoxtotal>
                  {
                    Number.parseInt(total.reviews, 10) > 0
                      ? <Title3 secondary>{total.reviews} {dictionary.reviews}</Title3>
                      : <Title3 secondary>{dictionary.non_review}</Title3>
                  }
                </ReviewTitleStarBoxtotal>
              </div>
            </ReviewRatelTotal>
          </Col>
          <Col xs={9}>
            <ReviewRatelStar>
              {
                reviews_rate.length > 0 && reviews_rate.map((v, k) => {
                  let _sort = this.props.review_star === 'all' ? true : v.star === parseInt(this.props.review_star, 10)
                  return <ReviewBoxStar key={`${k}_${v.star}`} show={_sort}>
                    <ReviewTitleStar>
                      <Title3 bold className='pr-2' style={{ width: 20 }}>{v.star}</Title3>
                      <Title3 ><i className='fas fa-star' /></Title3>
                    </ReviewTitleStar>
                    <ReviewBar>
                      <ReviewBarPercent percent={v.percent} />
                    </ReviewBar>
                    {
                      Number.parseInt(total.reviews, 10) > 0
                      && <ReviewTitlePercent>
                        <ReviewTitleReview>
                          <Title3 bold className='pr-2'>{toCurrency(v.count)}</Title3>
                        </ReviewTitleReview>
                        <Title3 bold>{dictionary.reviews}</Title3>
                      </ReviewTitlePercent>
                    }
                  </ReviewBoxStar>
                })
              }
            </ReviewRatelStar>
          </Col>
        </Row>
      </Col>
    </Row>
  }
}

export default BusinessReviewChart