import styled from 'styled-components'

export const SubscriptionDetail =  styled.div`
  padding: 15px;
  margin: 10px 15px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
`

export const BoxPrice = styled.div`
  width: 40vw;
  max-width: 100px;
  text-align: right;
  letter-spacing: 1px;
`

export const DotNumber = styled.div`
  margin-right: 2px;

  width: 4px;
  height: 4px;
  background: ${props => props.theme.secondary};

  overflow: hidden;
  border-radius: 50%;
`

export const CardDetailBox = styled.div`
  width: fit-content;
`