import styled, { css } from "styled-components";

export const BtnAddReward = styled.div`
	position: fixed;
	right: 15px;
	bottom: 15px;
	z-index: 10;
	background: ${(props) => props.theme.green};
	border-radius: 50%;
	color: ${(props) => props.theme.white};
	height: 50px;
	width: 50px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: 3px 6px 9px #888888;
`;

export const RewardTool = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 10px 15px;
	position: sticky;
	top: 0;
	z-index: 998;
	background: ${(props) => props.theme.white};
	min-height: 50px;
	box-shadow: ${(props) => (props.nonShadow ? "" : "1px 1px 1px #ccc")};
`;

export const CenterBox = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CardReward = styled.div`
	width: 100%;
	height: 40vw;
	max-height: 153px;
	max-width: 350px;
	min-width: 300px;
	min-height: 130px;
	background-color: #f0f0f0;
	box-shadow: 0 6px 20px -6px #cccccc;
	position: relative;
	overflow: hidden;
	border: 1px solid #ccc;
	border-radius: 5px;
	cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
	${(props) =>
		props.disabled &&
		css`
			filter: grayscale(1);
		`}
`;

export const TitleBar = styled.div`
	width: 100%;
	background-image: linear-gradient(to right, #e0dada6b, #f9f9f9, #e0dada6b);
	border-bottom: 1px solid #ccc;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 7px 10px 7px 20%;
`;

export const ContentBox = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Content = styled.div`
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const QRBox = styled.div`
	width: 55%;
	position: relative;
`;

export const QRImgBox = styled.img`
	width: 100%;
	${(props) =>
		props.example &&
		css`
			opacity: 0.35;
		`}
`;

export const HeadcouponAbsolute = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	> * {
		transform: rotate(-45deg);
	}
`;

export const ShowPoint = styled.div`
	width: 70px;
	text-align: center;
	border: 1px solid #ccc;
	border-radius: 10px;
	background: #eaeaeae6;
	padding: 3px 5px;
`;

export const BoxImage = styled.div`
	width: 70%;
	border-left: 1px solid #ccc;
`;

export const BoxDateTime = styled.div`
	border-radius: 10px;
	background: rgba(250, 250, 250, 0.9);
	padding: 3px 7px;

	font-size: 10px;
	@media (max-width: 990px) {
		font-size: 8px;
	}
`;

export const BoxDateTimeAbsolute = styled.div`
	right: 1px;
	bottom: 6px;
	position: absolute;
	z-index: 1;
`;

export const BoxActionValueCoupon = styled.div`
	right: 1px;
	bottom: 30px;
	position: absolute;

	${(props) =>
		props.danger
			? css`
					color: ${props.theme.red};
			  `
			: css`
					color: ${props.theme.primary};
			  `}

	text-align: right;
	border-radius: 10px;
	background: rgba(250, 250, 250, 0.9);

	padding: 3px 7px;

	font-size: 10px;
	@media (max-width: 990px) {
		font-size: 8px;
	}
`;

export const Ribbon = styled.div`
	width: 120px;
	height: 20px;
	transform: rotate(-45deg);
	position: absolute;
	top: 16px;
	left: -37px;
	z-index: 1;
	box-shadow: 0px 2px 5px -1px rgba(92, 92, 92, 1);
	background-image: linear-gradient(to right, #9e7f23, #ecd798, #9e7f23);
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TitleRibbon = styled.div`
	color: #946e04;
	font-weight: ${(props) => props.theme.font_bold};
	font-size: ${(props) => props.theme.font_xs};
	@media (max-width: 990px) {
		font-size: ${(props) => props.theme.font_xxs};
	}
`;

export const RewardImg = styled.div`
	border-top-left-radius: calc(0.25rem - 1px);
	border-top-right-radius: calc(0.25rem - 1px);
	min-height: 150px;
	background: ${(props) => props.theme.secondary};
`;

export const Message = styled.div`
	color: ${(props) => props.theme.primary};
	padding: 40px 15px;
`;

export const InputBox = styled.div`
	width: 100%;
	@media (max-width: 575px) {
		padding-left: 1.2rem;
	}
`;

export const ItemsTab = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border-width: 1px;
	border-style: solid;
	border-color: #f0f0f1;
	border-radius: 10px 10px 0px 0px;

	${(props) =>
		props.select &&
		css`
			border-color: #f0f0f1;
			background-color: #f0f0f1;
		`}

	&:hover {
		border-color: #f0f0f1;
		background-color: #f0f0f1;
	}
`;

export const ExportBox = styled.div`
	cursor: pointer;
	padding: 0.5rem;
`;

export const DateBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media (max-width: 767px) {
		align-items: center;
		margin: 5px 0px;
	}
`;

export const FontCell = styled.div`
	font-size: ${(props) => props.theme.font_s};
	@media (max-width: 990px) {
		font-size: ${(props) => props.theme.font_xs};
	}

	color: ${(props) => props.theme.primary};
	${(props) =>
		props.secondary &&
		css`
			color: ${props.theme.secondary};
		`}

	${(props) =>
		props.blue &&
		css`
			color: ${props.theme.blue};
		`}

  ${(props) =>
		props.link &&
		css`
			cursor: pointer;
			&:hover {
				opacity: 0.9;
			}
		`}

  ${(props) =>
		props.bold &&
		css`
			font-weight: ${props.theme.font_bold};
		`}

  text-align: ${(props) => (props.center ? "center" : props.right ? "right" : "left")};

	@media (max-width: 767px) {
		text-align: left !important;
	}
`;

export const BagdeSuccess = styled.div`
	min-width: 20px;

	color: ${(props) => props.theme.green};
	padding: 0em 0.4em;

	border: 1px solid ${(props) => props.theme.green};
	border-radius: 0.25rem;

	display: flex;
	align-items: center;
	justify-content: center;

	font-weight: 600;

	font-size: ${(props) => props.theme.font_s};
	@media (max-width: 990px) {
		font-size: ${(props) => props.theme.font_xs};
	}
`;

export const StatusTag = styled.div`
	min-width: 125px;
	display: flex;
	margin: 0px 5px 5px 0px;
	cursor: pointer;
	border: 1px solid ${(props) => props.theme.border_line};
	border-radius: 5px;

	:last-child {
		margin-right: 0px;
	}

	${(props) =>
		props.current &&
		css`
			background-color: ${(props) => props.theme.blue_light} !important;
			opacity: 1 !important;
		`}

	${(props) =>
		props.disabled
			? css`
					background-color: #ffffff;
					cursor: not-allowed;
					opacity: 0.6;
			  `
			: css`
					:hover {
						background-color: #f0f0f0;
					}
			  `}

  > * {
		padding: 0.5rem;
	}

	transition: all 0.15s ease;
`;
export const StatusTagDetail = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	color: ${(props) => props.theme.primary};
	border-left: 1px solid ${(props) => props.theme.border_line};
`;

export const StatusBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	border-radius: 10px;
	font-size: 40px;
	color: ${(props) => props.theme.primary};
	border: 1px solid ${(props) => props.theme.border_line};
	padding: 0.75rem 1.5rem;
`;

export const IconArrow = styled.div`
	font-size: 40px;
	color: ${(props) => props.theme.primary};
`;

export const ConfirmStatusDetail = styled.div`
	width: 100%;
	max-width: fit-content;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin: 1rem 0rem;
`;

export const AddressList = styled.div`
  width: 100%;
  border: 1px solid #0000001a;
  > * {
  &:nth-child(odd){
    background: rgba(0,0,0,0.03);
  }
`;

export const AddressItem = styled.div`
	width: ${(props) => (props.size ? `${props.size || 100}%` : "100%")};
	padding: 6px 8px;
	display: flex;
	align-items: center;
	word-break: break-word;
	${(props) =>
		props.left
			? css`
					justify-content: flex-start;
			  `
			: props.right
			? css`
					justify-content: flex-end;
			  `
			: css`
					justify-content: center;
					text-align: center;
			  `}
`;

export const IconList = styled.i`
	font-size: 7px;
	height: 100%;
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.primary};
`;

export const CopyButton = styled.button`
	background-color: transparent;
	border: none;
	color: #546174;
	&:hover {
		background-color: rgb(0, 0, 0, 0.08);
		border-radius: 50%;
	}
`;

export const LinkContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
