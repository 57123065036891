import axios from './../axios_request';

import { publicAPI, businessAPI } from '../end_point'
import { SUBSCRIBE } from '../actions_const'
import { getCookie } from '../../libs/cookies';

export const getBusinessSubscribe = (business_code, callback) => {
  return dispatch => {
    axios({
      method: 'get',
      url: businessAPI + '/' + business_code + '/subscribe',
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      if (data.result)
        dispatch({ type: SUBSCRIBE.SET_SUBSCRIBE, data: data.data })
      else
        dispatch({ type: SUBSCRIBE.IS_ERROR })

      if (typeof callback === 'function') callback(null, data.data)

    }).catch(err => {
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: SUBSCRIBE.IS_ERROR })

    })
  }
}

export const changeBusinessSubscribe = (business_code, data, callback) => {
  return dispatch => {
    axios({
      method: 'put',
      url: businessAPI + '/' + business_code + '/subscribe',
      headers: { Authorization: getCookie("_token") },
      data: data
    }).then(({ data }) => {
      if (data.result)
        dispatch({ type: SUBSCRIBE.SET_SUBSCRIBE, data: data.data })
      else
        dispatch({ type: SUBSCRIBE.IS_ERROR })

      if (typeof callback === 'function') callback(null, data.data)

    }).catch(err => {
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: SUBSCRIBE.IS_ERROR })

    })
  }
}

export const unsubscribeWithToken = (token, callback) => {
  axios({
    method: 'delete',
    url: publicAPI + '/unsubscribe',
    data: { token }
  }).then(({ data }) => {
    if (typeof callback === 'function') callback(null, data)

  }).catch(err => {
    if (typeof callback === 'function') callback(err)

  })
}