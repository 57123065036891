import { CONDITION } from '../actions_const'

let initState = {
  isLoadingPointsCondition: false,
  points_condition: [],
}

const ReducerCondition = (state = initState, action) => {
  switch (action.type) {
    case CONDITION.PROCESS_POINTS_CONDITION:
      return Object.assign({}, state, { isLoadingPointsCondition: true })
    case CONDITION.GET_POINTS_CONDITION:
      return Object.assign({}, state, { isLoadingPointsCondition: false, points_condition: action.data || [] })
    case CONDITION.OPEN_POINTS_CONDITION:
      return Object.assign({}, state, { isLoadingPointsCondition: false, points_condition: [...state.points_condition, action.data || {}] })
    case CONDITION.UPDATE_POINTS_CONDITION:
      return Object.assign({}, state, {
        isLoadingPointsCondition: false,
        points_condition: state.points_condition && state.points_condition.map(v => parseInt(v.id) === parseInt(action.data.id) ? action.data : v)
      })
    case CONDITION.ERROR_POINTS_CONDITION:
      return Object.assign({}, state, { isLoadingPointsCondition: false, points_condition: state.points_condition || [] })
    default:
      return state
  }
}

export default ReducerCondition;