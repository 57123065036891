import styled, { css, keyframes } from 'styled-components';

export const AddTransactionSection = styled.div`
  font-size: ${(props) => props.theme.font_l};
  color: ${(props) => props.theme.primary};
  font-weight: ${(props) => props.theme.font_bold};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  background: white;
  box-shadow: 0px -2px 10px 0px;
  &:hover {
    opacity: 0.9;
  }
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 50px;
  z-index: 1;
`;

export const TransactionTitle = styled.div`
  margin-left: 10px;
  display: inline-block;
`;

export const TransactionIcon = styled.div`
  color: ${(props) => props.theme.green};
  display: inline-block;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export const AddTransactionBtn = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileAddTransactionBtn = styled.div`
  background: ${(props) => props.theme.green};
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  padding-top: 2px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  color: ${(props) => props.theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.9;
  }
`;

export const TransactionBack = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
`;

export const LabelCircle = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 2px;
`;

export const FilterBar = styled.div`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  padding: 0.75rem;
`;

const rotateDown = keyframes`
  from { transform:  rotate(0deg); }
    to { transform:  rotate(180deg); }
`;

const rotateUp = keyframes`
  from { transform:  rotate(180deg); }
    to { transform:  rotate(0deg); }
`;

export const IconChevron = styled.div`
  animation: ${(props) => (props.toggle ? rotateUp : rotateDown)} 0.2s linear;
  animation-fill-mode: forwards;
`;

export const FilterBox = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction column;

  width: 100%;
  max-width: 280px;

  margin-bottom: 0.5rem;
`;

export const FilterContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${(props) => props.theme.white_1};
  @media (max-width: 767px) {
    align-items: center;
    margin: 0px 0px 5px 0px;
    :first-child {
      margin: 5px 0 0 0;
    }
  }
`;

export const TransactionContainer = styled.div`
  width: 100%;
  ${(props) =>
    props.card
      ? css`
          padding: 0.75rem 0px;
          background-color: ${(props) => props.theme.white_1};
        `
      : css`
          padding-bottom: 0.75rem;
        `}
`;

export const TransactionHeader = styled.div`
  width: 100%;
  position: ${(props) => (props.stickyHead ? 'sticky' : '')};
  top: ${(props) => (props.stickyTop ? props.stickyTop : '96px')};
  background-color: ${(props) => props.theme.white_2};
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.border_line};
  ${(props) =>
    props.couponPersonal
      ? css`
          border-top: 1px solid ${(props) => props.theme.border_line};
        `
      : ''}
`;

export const TransactionBody = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.white};
  border-left: 1px solid ${(props) => props.theme.border_line};
  border-right: 1px solid ${(props) => props.theme.border_line};
`;

export const TransactionRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0.25rem 0rem;
  border-bottom: 1px solid ${(props) => props.theme.border_line};
  background-color: ${(props) => props.theme.white_1};
  &:nth-child(even) {
    background-color: #00000008;
  }
`;

export const TransactionCell = styled.div`
  width: 100%;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  cursor: ${(props) => (props.pointer ? 'pointer' : '')};
  padding: 0.5rem;
  word-break: break-word;
  :nth-child(1) {
    width: 10%;
  }
  :nth-child(2) {
    width: 19%;
  }
  :nth-child(3) {
    width: 13%;
  }
  :nth-child(4) {
    width: 17%;
  }
  :nth-child(5) {
    width: 13%;
  }
  :nth-child(6) {
    width: 13%;
  }
  :nth-child(7) {
    width: 15%;
  }

  @media (min-width: 990px) {
    :first-child {
      padding-left: 1rem;
    }
    :last-child {
      padding-right: 1rem;
    }
  }
`;

export const TransactionListTableCell = styled.div`
  width: 100%;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  cursor: ${(props) => (props.pointer ? 'pointer' : '')};
  padding: 0.5rem;
  word-break: break-word;
  :nth-child(1) {
    width: 10%;
  }
  :nth-child(2) {
    width: 19%;
  }
  :nth-child(3) {
    width: 13%;
  }
  :nth-child(4) {
    width: 20%;
  }
  :nth-child(5) {
    width: 12%;
  }
  :nth-child(6) {
    width: 12%;
  }
  :nth-child(7) {
    width: 14%;
  }

  @media (min-width: 990px) {
    :first-child {
      padding-left: 1rem;
    }
    :last-child {
      padding-right: 1rem;
    }
  }
`;

export const ItemProductList = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.border_line};
  padding: 0.5rem 0rem;
`;

export const TransContent = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.border_line};
  padding: 0.75rem;
  :last-child {
    border-bottom: 0px;
  }
`;

export const LabelInfo = styled.div`
  width: fit-content;
  min-height: 23px;
  white-space: nowrap;
  color: ${(props) =>
    props.isImg ? props.theme.white : props.theme.secondary};
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.border_line};
  border-radius: 5px;
  padding: 3px 10px;
  background-color: ${(props) =>
    props.isImg ? props.theme.third : props.theme.blue_light};
  cursor: pointer;
  margin: 0.15rem 0.15rem 0.15rem 0px;
`;

export const TransactionCard = styled.div`
  width: 100%;
  margin-bottom: 0.75rem;
  background-color: #fff;
  border-bottom: 1px solid ${(props) => props.theme.border_line};
  border-top: 1px solid ${(props) => props.theme.border_line};
`;

export const TransCardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  background-color: ${(props) => props.theme.white_1};
  border-bottom: 1px solid ${(props) => props.theme.border_line};
`;

export const TransCardBody = styled.div`
  width: 100%;
  padding: 0.75rem;
  cursor: ${(props) => (props.pointer ? 'pointer' : '')};
  border-bottom: 1px solid ${(props) => props.theme.border_line};
  :last-child {
    border-bottom: 0px;
  }
`;

export const ProductCol = styled.div`
  width: 100%;
  padding-right: 3px;
  text-align: ${(props) => (props.align ? props.align : 'left')};
  color: ${(props) =>
    props.secondary ? props.theme.secondary : props.theme.primary};
  font-weight: ${(props) => (props.bold ? props.theme.font_bold : '')};
  font-size: ${(props) => props.theme.font_s};
  word-break: break-word;
  @media (max-width: 990px) {
    font-size: ${(props) => props.theme.font_xs};
  }
  :nth-child(1) {
    width: 40%;
  }
  :nth-child(2) {
    width: 30%;
  }
  :nth-child(3) {
    width: 30%;
  }
`;

export const FontCell = styled.div`
  font-size: ${(props) => props.theme.font_s};
  @media (max-width: 990px) {
    font-size: ${(props) => props.theme.font_xs};
  }

  color: ${(props) => props.theme.primary};
  ${(props) =>
    props.secondary &&
    css`
      color: ${props.theme.secondary};
    `}

  ${(props) =>
    props.blue &&
    css`
      color: ${props.theme.blue};
    `}

  ${(props) =>
    props.link &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    `}

  ${(props) =>
    props.bold &&
    css`
      font-weight: ${props.theme.font_bold};
    `}

  text-align: ${(props) =>
    props.center ? 'center' : props.right ? 'right' : 'left'};

  @media (max-width: 767px) {
    text-align: left !important;
  }
`;
export const ExportBox = styled.div`
  cursor: pointer;
  margin: 0.5rem;
`;

export const IdAndCard = styled.div`
  display: flex;
  column-gap: 10px;
  flex-wrap: wrap-reverse;
`;

export const RemarkSection = styled.div`
  width: 50%;
  margin-left: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
