import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';

import {
  DropdownIconLanguage,
  DropdownItem,
  DropdownCardBox,
  DropdownItemName,

} from './Dropdown.style'
import { ComponentIcon } from '../Titlebar/Titlebar.style'

import { selectLang } from '../../redux/actions/action_language'

class DropdownLanguage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DropdownShow: false,
      tooltipOpen: false
    }
    this.closeMenu = this.closeMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ DropdownShow: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ DropdownShow: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  toggle() {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  }

  render() {
    const { store_language: { dictionary, locale } } = this.props
    const menuList = [{
      title: dictionary.language_thailand,
      show: true,
      function: (e) => {
        e.preventDefault(); this.props.selectLang('th')
      }
    }, {
      title: dictionary.language_english,
      show: true,
      function: (e) => {
        e.preventDefault(); this.props.selectLang('en')
      }
    }]
    
    return (
      <div className='position-relative'>
        <ComponentIcon isOpen={this.props.isOpen} id={'menu_language'} onClick={this.showMenu}>
          <DropdownIconLanguage>
            <span>{locale.toUpperCase()}</span>
          </DropdownIconLanguage>
        </ComponentIcon>
        <Tooltip placement="bottom-end" isOpen={this.state.tooltipOpen} target={'menu_language'} toggle={this.toggle} delay={750}>
          {dictionary.change_language}
        </Tooltip>
        <DropdownCardBox hidden={!this.state.DropdownShow}>
          {
            menuList.map((value, key) => {
              return (
                value.show && <DropdownItem key={key} onClick={value.function}>
                  <div className='d-flex align-items-center'>
                    <DropdownItemName> {value.title}</DropdownItemName>
                  </div>
                </DropdownItem>
              )
            })
          }
        </DropdownCardBox>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language,
    store_transactions: state.transactions,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    selectLang
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownLanguage)