import React from 'react'

import LoadingGradient from '../LoadingGradient'
import { ReviewsRateBox } from './BusinessReview.styled'

class BusinessReviewMessageDetailLoading extends React.Component {
  render() {
    return <ReviewsRateBox>
      <div className='border-bottom mb-2 pb-2 d-flex justify-content-between align-items-center'>
        <div>
          <LoadingGradient width={'8vw'} minWidth={'60px'} />
          <LoadingGradient width={'9vw'} minWidth={'80px'} />
        </div>
        <div className='text-center'>
          <LoadingGradient width={'4vw'} minWidth={'30px'} />
          <LoadingGradient width={'5vw'} minWidth={'40px'} />
        </div>
      </div>
      <div style={{ minHeight: '65px', padding: '10px 0px' }}>
        <div className='pb-1'>
          <LoadingGradient width={'4vw'} minWidth={'30px'} />
        </div>
        <LoadingGradient width={'10vw'} minWidth={'90px'} />
        <LoadingGradient width={'12vw'} minWidth={'120px'} />
        <LoadingGradient width={'9vw'} minWidth={'80px'} />
      </div>
    </ReviewsRateBox>
  }
}

export default BusinessReviewMessageDetailLoading