import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toCurrency } from '../../../../libs/currency'
import { Title3 } from '../../../Font'

export default function SubscriptionDetailAddonItems(props) {
	const items = props.items
	const dictionary = useSelector((state) => state.language.dictionary)
	let [addonItems, setAddonItems] = useState([])
	
	useEffect(() => {
		setAddonItems(_.filter(items, (item) => {
			return ['broadcast_message', 'advance_apis'].indexOf(item.filter_term) > -1
		}))
			
		return () => {
			setAddonItems([])
		}
	}, [items])

	return (
		addonItems && addonItems.length ?
			<>
				<div id="ps-add-on" className='pt-2 pb-2 d-flex align-items-baseline justify-content-between w-100'>
					<Title3 className="col-4">{dictionary.add_on_package}</Title3>
				</div>
				{
					addonItems.map((item, index) => {
						return (
							<div key={`${item}_${index}`}>
								{
									item.pricing && item.pricing > 0 && item.unit_dictionary === 'month' ?
									<div id="ps-package" className='mb-1 d-flex align-items-baseline justify-content-between w-100'>
										<Title3 className="col-4 pl-5">{dictionary.package_items[item.title_dictionary] || 'Add-on'}</Title3>
										<Title3 className="col-2 text-right" bold>{toCurrency(item.pricing || 0, 2)} {dictionary.currency.thb}</Title3>
									</div>
									:
									<div className='mb-1 align-items-baseline d-flex w-100'>
										<Title3 className="col-4 pl-5">{dictionary.package_items[item.title_dictionary] || 'Add-on'}</Title3>
									</div>
								}
							</div>
						)
					})
				}
			</>
	: null
	)
}