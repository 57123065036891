import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import Loading from "../../components/Loading";
import { getMemberCardInfo } from "../../redux/actions/action_business";
import { initialJobssState } from "../../redux/actions/action_jobs";
import CronjobsCreateContainer from "./CronjobsCreateContainer";
import StickyMenu from "./StickyMenu";

const menukey = "membercard-batchjob";

export default function MemberCardJobsCreate() {
	const dispatch = useDispatch();
	const store_business = useSelector((state) => state.business);
	const history = useHistory();

	const business = _.get(store_business, ["current"]);
	const permission = _.get(business, ["permission", menukey]);
	const memberCardInfoList = store_business.member_card_info;

	useEffect(() => {
		if (!permission || !permission.permission_create) {
			history.push("/jobsMemberCard");
		}

		return () => {
			dispatch(initialJobssState());
		};
	}, [permission, history, dispatch]);

	useEffect(() => {
		if (_.isEmpty(memberCardInfoList) && business.business_code) {
			dispatch(getMemberCardInfo(business.business_code));
		}
	}, [business, memberCardInfoList, dispatch]);

	return (
		<div>
			{store_business.isFetching ? (
				<Container fluid style={{ margin: "30px 0px" }}>
					<Loading />
				</Container>
			) : (
				<>
					<StickyMenu customRoute="/jobsMemberCard" />
					<CronjobsCreateContainer type="membercard" />
				</>
			)}
		</div>
	);
}
