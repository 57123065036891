import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { Title1, Title2 } from '../Font'
import { icon } from '../../libs/icon.js'

import DropdownAccount from '../DropdownMenu/DropdownAccount'
import DropdownLanguage from '../DropdownMenu/DropdownLanguage'
import DropdownMessage from '../DropdownMenu/DropdownMessage'
import DropdownManual from '../DropdownMenu/DropdownManual'
import { DropdownIconSpace } from '../DropdownMenu/Dropdown.style'

import { PrimaryBtn } from '../Button'

import {
  TitlebarContainer, IconMenubar, PageTitle,
  TopBar, InputSearch, ComponentName
} from './Titlebar.style'

import { toggleSidebar } from '../../redux/actions/action_sidebar'
import { clearProfileSearch, getMemberProfileByMobile } from '../../redux/actions/action_transactions'

class Titlebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isSearch: false,
      search: '',
    }
  }

  componentDidMount() {
    window.document.addEventListener('click', this.clickInside)
  }

  componentWillUnmount() {
    window.document.removeEventListener('click', this.clickInside)
  }

  clickInside = (event) => {
    let _id = (event && event.target && event.target.id) || ''
    let element = window.document.getElementById('searchBox')

    if (['btnClear', 'btnClose'].indexOf(_id) > -1)
      return null

    if (!this.state.isSearch)
      return null

    if (element) {
      var isClickInsideElement = element.contains(event.target);
      if (isClickInsideElement)
        return null
      else
        return this.toggleSearch(true)
    }
  }

  gotoManual() {
    window.open(process.env.REACT_APP_GOTO_MANUAL, '_blank');
  }

  handdleChange = ({ target: { name, value = '' } }) => {
    value = value && value.trim()
    if (value === '' || Boolean(value.match(/^((\d{0,8})|(0\d{0,9})|(\+\d{0,15}))$/g)))
      return this.setState({ [name]: value })
  }

  toggleSearch = (forceToggle = false) => {
    if (forceToggle)
      this.setState({ isSearch: !this.state.isSearch, search: '' })

    if (this.state.search)
      this.onSearchMember()
  }

  toggleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onKeyDown = ({ key }) => {
    if (key === 'Enter')
      this.onSearchMember()
  }

  clearSearch = () => {
    this.setState({ search: '' })
  }

  onSearchMember = () => {
    let { store_business: { current } } = this.props

    if (_.isEmpty(current) || !Boolean(current && current.business_code))
      return null

    if (this.state.search === '')
      return null

    this.setState({ isLoading: true })
    this.props.getMemberProfileByMobile(current.business_code, this.state.search, (err, data) => {
      let value = data && data[0] && data[0].member_badge_code
      this.setState({ isLoading: false, search: '' })

      if (Boolean(err) || !Boolean(value) || value === '')
        return this.setState({ isOpen: true })
      else
        return this.props.history.push(`/member/${value}`)
    })
  }

  render() {
    let { menu } = this.props
    let { store_language: { dictionary } } = this.props
    let { store_transactions: { hiddenSearch } } = this.props
    let { history: { location: { pathname } } } = this.props

    if (!menu)
      return null

    let defaultTitle = [
      { href: '/', title: dictionary.home_page },
      { href: '/newsfeed', title: dictionary.activities },
      { href: '/packages', title: dictionary.packages },
      { href: '/dashboard', title: dictionary.dashboard }
    ]

    let _isShowSearch = false
    _isShowSearch = Boolean(menu.filter(f => f && f.show && f.serach && f.href === pathname).length)
    _isShowSearch = _isShowSearch || Boolean(menu.filter(f => f && f.show && f.serach && f.submenu && f.submenu.findIndex(f1 => f1.href.split('/').filter(v => v)[0] === pathname.split('/').filter(v => v)[0]) > -1).length)

    let arr = [...defaultTitle]
    menu.filter(f => f.submenu).forEach(element => arr.push(...element.submenu))
    let this_menu = arr.filter(f => f && f.href && f.href.split('/').filter(v => v)[0] === pathname.split('/').filter(v => v)[0])[0]

    return <>
      <TitlebarContainer>
        <div className="d-flex justify-content-start align-items-center">
          {
            !this.props.hide && <IconMenubar className="d-md-inline-block d-lg-none" onClick={this.props.toggleSidebar}>
              <Title1 white><i className={icon.bars} /></Title1>
            </IconMenubar>
          }
          <ComponentName isOpen={!this.state.isSearch}>
            <PageTitle>
              {
                hiddenSearch
                  ? <div className='d-flex pointer' onClick={() => this.props.history.goBack()}>
                    <Title2 bold white className='d-block d-lg-block pr-2'><i className="fas fa-chevron-left" /></Title2>
                    <Title2 bold white>{dictionary.back}</Title2>
                  </div>
                  : <Title2 bold white>{(this_menu && this_menu.title) || ''}</Title2>
              }
            </PageTitle>
          </ComponentName>
        </div>

        <TopBar hidden={hiddenSearch}>
          {
            _isShowSearch && <div id='searchBox' className='d-flex align-items-center justify-content-baseline'>
              <div className='position-relative'>
                <InputSearch
                  type='tel'
                  name='search'
                  value={this.state.search}
                  isSearch={this.state.isSearch}
                  onChange={this.handdleChange}
                  onKeyDown={this.onKeyDown}
                  placeholder={`${dictionary.mobile_no} ${dictionary.or} ${dictionary.pointspot_id}`} />
                {
                  this.state.isLoading || this.state.search
                    ? <div className='position-absolute' style={{ top: 0, right: 20 }}>
                      {
                        this.state.isLoading
                          ? <Title1 secondary>
                            <i className="fas fa-spinner fa-pulse" />
                          </Title1>
                          : <Title1 secondary onClick={this.clearSearch}>
                            <i id='btnClear' className="fas fa-times" />
                          </Title1>
                      }
                    </div>
                    : null
                }
              </div>

              <div className='d-flex align-items-center justify-content-center' onClick={() => this.toggleSearch(!Boolean(this.state.search))}>
                <DropdownIconSpace id={'menu_search'}>
                  <i className="fas fa-search" />
                </DropdownIconSpace>
              </div>
            </div>
          }
          <DropdownManual {...this.props} isOpen={!this.state.isSearch} />
          <DropdownMessage {...this.props} isOpen={!this.state.isSearch} />
          <DropdownLanguage {...this.props} isOpen={!this.state.isSearch} />
          <DropdownAccount {...this.props} />
        </TopBar>
      </TitlebarContainer>

      <Modal centered toggle={this.toggleOpen} isOpen={this.state.isOpen} >
        <ModalHeader>
          <Title2 bold>{dictionary.notification}</Title2>
        </ModalHeader>
        <ModalBody>
          <div className='d-flex flex-column align-items-center justify-content-center' style={{ minHeight: 120 }}>
            <Title2 bold>
              <i className="fas fa-user-times fa-3x" />
            </Title2>
            <Title2 bold className='mt-2'>{dictionary.no_user_found}</Title2>
          </div>
        </ModalBody>
        <ModalFooter>
          <PrimaryBtn id='btnClose' bold md onClick={this.toggleOpen}>{dictionary.close}</PrimaryBtn>
        </ModalFooter>
      </Modal>
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    store_sidebar: state.sidebar,
    store_business: state.business,
    store_transactions: state.transactions,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    toggleSidebar,
    clearProfileSearch,
    getMemberProfileByMobile,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Titlebar))
