import React from 'react'
import ReactDOM from 'react-dom'
import AppRoute from './AppRoute'
import * as serviceWorker from './serviceWorker';

import ReduxPromise from 'redux-promise'
import ReduxThunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import Reducers from './redux/reducers'
import { ThemeProvider } from 'styled-components'
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import axios from 'axios';

import { theme } from './styles/theme'
import { GlobalStyle } from './styles/globalStyle'
import { initialize } from './libs/default'
import Caching from './libs/caching'

import PointspotAlertBox from './components/PointspotAlertBox'
import CookieConsent from './components/CookieConsent'

import './index.css'
import './libs/StringAddition'
import './styles/globalStyle'
import 'bootstrap/dist/css/bootstrap.css'
import '@fortawesome/fontawesome-free/css/all.css'
import './styles/pagination.css'
import './styles/datePicker.css'
import './fontello/css/fontello.css'

initialize()
Caching.enable();

let store = createStore(Reducers, applyMiddleware(ReduxPromise, ReduxThunk))

// Set up axios interceptor for handling 403 error
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response.status === 403 && error.response.data?.error?.code === 'pointspot/permission-denied') {
    store.dispatch({ type: "PERMISSION_DENIED" });
  }
  return Promise.reject(error)
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <div className="w-100 h-100">
        <GlobalStyle />
        <PointspotAlertBox />
        <ToastContainer pauseOnHover hideProgressBar autoClose={2000} transition={Slide} closeButton={false} position="bottom-center" />
        <AppRoute />
        <CookieConsent />
      </div>
    </ThemeProvider>
  </Provider>
  , document.getElementById('root'))

serviceWorker.register({
  onUpdate: () => {
    document.getElementById('pointspot-alert-box').style.display = 'flex'
  }
})
