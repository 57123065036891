import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Loading from '../../components/Loading'
import { checkLogin } from '../../redux/actions/action_permission'

export default function Home() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLogin())
  }, [dispatch, history]);

  return <Loading />
}
