import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Title3 } from '../Font';
import { ExportBox } from './ButtonTextExportPermission.style';

/**
 * Export Text's button with permission
 * @param {*} props
 * @param {boolean} props.isLoading {optional} appear loading when true, default is false.
 * @param {string} props.menuKey {require} the name of menu to match permission to display button.
 * @param {boolean} props.hidden {optional} be hidden when true, default is false.
 * @param {string} props.text {optional} custom text, default is "Export data".
 * @returns React Component
 */
function ButtonTextExportPermission(props) {
	let [isLoading, setLoading] = useState(false);
	let [permissionKey] = useState(props.menuKey || null);
	let { dictionary } = useSelector((state) => state.language);
	let { permission } = useSelector((state) => state.business);

	useEffect(() => {
		setLoading(props.isLoading);
	}, [props.isLoading]);

	const handleOnClick = () => {
		if (props.onClick) {
			props.onClick();
		} else {
			return null;
		}
	};

	return (
		permissionKey && permission && permission[permissionKey]?.permission_export ?
		<ExportBox
			hidden={props.hidden} 
			className={props.className} 
			onClick={handleOnClick}>
			<Title3 link blue bold>
				<>
					{isLoading && <i className="fas fa-spinner fa-pulse mr-2"></i>}
					{props.text ? props.text : dictionary.export_data}
				</>
			</Title3>
		</ExportBox>
		: null
	);
}

export default ButtonTextExportPermission;
