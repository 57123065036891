import React, { useState } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";

import { Title2, Title3 } from "../../components/Font";
import { AdvanceOptionHeader } from "../Ecard/Ecard.style";
import { IconChevron } from "../../components/CardHeaderFilter/CardHeaderFilter.style";
import { Collapse, Row, Col, FormGroup, Input } from "reactstrap";
import "../BroadcastPromotion/styleBroadcast.css";
import { toCurrency } from "../../libs/currency";

const MAX_LENGTH = 100;

export default function AdvanceOption({ message, onMessageChange }) {
	const [isShow, setShow] = useState(false);
	const dictionary = useSelector((state) => state.language.dictionary);

	const handleDynamicOptionClick = (code) => {
		const text = `${message.text.trim()} ${code}`;
		const element = document.getElementById("input-message");
		onMessageChange({
			text: text,
			isError: getMessageLength(text) > MAX_LENGTH,
		});
		if (element) {
			element.focus();
		}
	};

	const handleMessageInput = (event) => {
		const { value } = event.target;
		onMessageChange({
			text: value,
			isError: getMessageLength(value) > MAX_LENGTH,
		});
	};

	const getMessageLength = (text) => {
		const sanitizedMsg = text.replace(/\$name|\$link/g, "").trim();
		return sanitizedMsg.length;
	};

	return (
		<>
			<AdvanceOptionHeader onClick={() => setShow(!isShow)}>
				<Title2 className="title" bold>
					{dictionary.addtional_setting}
				</Title2>
				<IconChevron toggle={isShow}>
					<Title3>
						<i className="fas fa-chevron-up" />
					</Title3>
				</IconChevron>
			</AdvanceOptionHeader>
			<Collapse isOpen={isShow}>
				<Row>
					<Col sm={12} md={6}>
						<FormGroup>
							<div className="d-flex pb-1 align-items-center justify-content-between w-100">
								<div className="d-flex">
									<Title2 bold>{dictionary.notification_message}</Title2>
								</div>
								<Title3 secondary danger={message.isError} bold={message.isError}>
									{`${toCurrency(getMessageLength(message.text))} / ${MAX_LENGTH}`}
								</Title3>
							</div>
							<Input
								rows={2}
								name="message"
								type="textarea"
								id="input-message"
								style={{ resize: "none" }}
								onChange={handleMessageInput}
								value={message.text}
							/>
						</FormGroup>
					</Col>
					<Col sm={12} md={6}>
						<div className="pb-1">
							<Title2 bold>{dictionary.dynamic_message}</Title2>
							<Title3 secondary>{dictionary.dynamic_message_description}</Title3>
						</div>
						<DynamicOptions message={message.text} dictionary={dictionary} onItemClick={handleDynamicOptionClick} />
					</Col>
				</Row>
			</Collapse>
		</>
	);
}

const DynamicOptions = ({ message = "", dictionary, onItemClick }) => {
	const dynamicOptions = [
		{
			code: "$name",
			title: "<code class='code-broadcast'>" + dictionary.first_name + "</code>",
			title_disable: "<code class='code-broadcast disable'>" + dictionary.first_name + "</code>",
		},
		{
			code: "$link",
			title: "<code class='code-broadcast'>" + dictionary.business_link + "</code>",
			title_disable: "<code class='code-broadcast disable'>" + dictionary.business_link + "</code>",
		},
	];

	return (
		<div className="w-100 d-flex">
			{dynamicOptions.map((value, key) => {
				let check = message.match(/\$name|\$link/gm) || [];
				let _disable = Boolean(check.indexOf(value.code) > -1);

				if (_disable)
					return (
						<div className="mr-2" key={`dynamic_${key}`}>
							<Title2>{parse(value.title_disable)}</Title2>
						</div>
					);

				return (
					<div className="mr-2 pointer" key={`dynamic_${key}`} onClick={() => onItemClick(value.code)}>
						<Title2>{parse(value.title)}</Title2>
					</div>
				);
			})}
		</div>
	);
};
