import _ from 'lodash'
import React from 'react'
import PaymentAgreement from './PaymentAgreement'
import BusinessPaymentAddress from './BusinessPaymentAddress'
import BusinessSubscriptionAddons from './BusinessSubscriptionAddons'
import BusinessPaymentCard from './BusinessPaymentCard'
import BusinessPaymentDescription from './BusinessPaymentDescription'

class BusinessPayment extends React.Component {
  constructor(props) {
    super(props)
    const steps = _.get(this.props, ['showSteps']) || [1, 2, 3, 4]
    this.state = {
      step: 0,
      currentStep: steps[0]
    }

    this.nextStep = this.nextStep.bind(this)
    this.prevStep = this.prevStep.bind(this)
  }

  componentDidMount() {
    this.props.getCustomerPayment()
  }

  componentWillUnmount() {
    this.props.clearState()
  }

  nextStep() { 
    const steps = _.get(this.props, ['showSteps']) || [1, 2, 3, 4]
    const step = this.state.step + 1;
    let currentStep = 1;
    //console.log("nextStep", steps, step, step >= steps.length, typeof this.props.onLastStepEnd)
    if (step >= steps.length && typeof this.props.onLastStepEnd === 'function') {
      this.props.onLastStepEnd();
    } else if (step < steps.length) {
      currentStep = steps[step];
      this.setState({ step, currentStep })
    }
  }

  prevStep() { 
    const steps = _.get(this.props, ['showSteps']) || [1, 2, 3, 4]
    const step = this.state.step - 1;
    const currentStep = steps[step];
    this.setState({ step, currentStep }) 
  }

  render() {
    let { store_payment: { payment, isLoading } } = this.props
    let { LoadingBox, isLoadingPayment } = this.props
    
    if (isLoadingPayment || isLoading)
      return LoadingBox

    if (!Boolean(payment && payment.user && payment.user.customer_token_id))
      return <PaymentAgreement {...this.props} createCustomerToken={this.props.createCustomerToken} toggle={this.props.toggleModalPackage} />

    switch (this.state.currentStep) {
      case 1:
        return <BusinessPaymentAddress {...this.props} nextStep={this.nextStep} />

      case 2:
        return <BusinessSubscriptionAddons {...this.props} prevStep={this.prevStep} nextStep={this.nextStep} />

      case 3:
        return <BusinessPaymentCard {...this.props} prevStep={this.prevStep} nextStep={this.nextStep} />

      case 4:
        return <BusinessPaymentDescription {...this.props} prevStep={this.prevStep} />

      default:
        return null
    }
  }
}

export default BusinessPayment