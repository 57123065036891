import _ from "lodash";

const errors = [
  {
    code: 1,
    text: "card_no_require",
    field: "number",
  },
  {
    code: 2,
    text: "card_no_invalid",
    field: "number",
  },
  {
    code: 3,
    text: "exp_month_require",
    field: "expire_month",
  },
  {
    code: 4,
    text: "exp_month_2_digit",
    field: "expire_month",
  },
  {
    code: 5,
    text: "exp_year_require",
    field: "expire_year",
  },
  {
    code: 6,
    text: "exp_year_4_digit",
    field: "expire_year",
  },
  {
    code: 7,
    text: "card_expired",
    field: "expire_year",
  },
  {
    code: 8,
    text: "card_expired",
    field: "expire_month",
  },
  {
    code: 9,
    text: "exp_month_invalid",
    field: "expire_month",
  },
  {
    code: 10,
    text: "cvv_invalid",
    field: "cvv",
  },
  {
    code: "003",
    text: "add_card_error_cancelled",
  },
  {
    code: "4014",
    text: "add_card_error_invalid_number",
  },
  {
    code: "4056",
    text: "add_card_error_invalid_number",
  },
  {
    code: "4076",
    text: "add_card_error_invalid_number",
  },
  {
    code: "4017",
    text: "add_card_error_owner_cancel",
  },
  {
    code: "4018",
    text: "add_card_error_owner_cancel",
  },
  {
    code: "4031",
    text: "add_card_error_bank_unsupport",
  },
  {
    code: "4033",
    text: "add_card_error_expired_card",
  },
  {
    code: "4035",
    text: "add_card_error_restricted_card",
  },
  {
    code: "4041",
    text: "add_card_error_lost_card",
  },
  {
    code: "4043",
    text: "add_card_error_stolen_card",
  },
  {
    code: "4051",
    text: "add_card_error_insufficient_funds",
  },
  {
    code: "4061",
    text: "add_card_error_insufficient_funds",
  },
  {
    code: "4065",
    text: "add_card_error_exceed_withdrawal_limit",
  },
];

const defaultError = {
  text: "add_card_error",
};

export const getError = (code) => {
  if (!code) {
    return defaultError;
  } else {
    return _.find(errors, { code: code }) || defaultError;
  }
};
