import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";

import Tour from "reactour";
import { Modal, ModalBody } from "reactstrap";

import { getCookie, removeCookie } from "../../libs/cookies";
import { nl2br } from "../../libs/nl2br";
import { Title1, Title2, Title3 } from "../Font";
import { PrimaryBtn, InvertBtn, SuccessBtn } from "../Button";

import { TitleHerder, BoxSkipStep } from "./tutorial.style";

import { toggleSidebar } from "../../redux/actions/action_sidebar";
import { toggleOpenTutorial } from "../../redux/actions/action_tutorial";

class tutorialList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isTourOpen: false,
			isOpenModal: false,
			image:
				"https://firebasestorage.googleapis.com/v0/b/rp-loyalty.appspot.com/o/_images%2FPointSpot%20Tutorial.jpg?alt=media&token=a967c6be-8b2d-4735-9fed-fe76c9c76843",
		};

		this.toggleTour = this.toggleTour.bind(this);
		this.skipTutorial = this.skipTutorial.bind(this);
		this.clearCookies = this.clearCookies.bind(this);
		this.toggleModalTutorial = this.toggleModalTutorial.bind(this);
	}

	componentDidMount() {
		let { store_business } = this.props;
		if (store_business && store_business.current && store_business.current.business_code)
			this.toggleModalTutorial(store_business.current.business_code);
	}

	componentDidUpdate(prevProps, prevState) {
		let { store_business } = this.props;

		if (
			!prevProps.store_business ||
			!prevProps.store_business.current ||
			!prevProps.store_business.current.business_code
		)
			return;

		if (!store_business || !store_business.current || !store_business.current.business_code) return;

		if (store_business.current.business_code !== prevProps.store_business.current.business_code)
			this.toggleModalTutorial(store_business.current.business_code);
	}

	toggleModalTutorial(business_code) {
		let _cookies = getCookie(`__CBS_${business_code}`);

		if (!business_code || !_cookies) return;

		let setTime = setInterval(() => {
			this.props.toggleModal();
			clearInterval(setTime);
		}, 1000);
	}

	toggleTour() {
		let { isTourOpen } = this.state;

		// Delay Open Tutorial after toggle sidebarContents
		this.props.toggleOpenTutorial(!isTourOpen);
		let setTimeToggle = setInterval(() => {
			this.setState({ isTourOpen: !isTourOpen }, () => {
				clearInterval(setTimeToggle);
			});
		}, 500);
	}

	skipTutorial() {
		this.clearCookies();
		this.props.toggleModal();
	}

	clearCookies() {
		let {
			store_business: { current },
		} = this.props;
		let _cookies = current && current.business_code ? getCookie(`__CBS_${current.business_code}`) : null;

		if (_cookies) removeCookie(`__CBS_${current.business_code}`);
	}

	render() {
		let {
			store_language: { dictionary },
		} = this.props;
		return (
			<>
				<Modal centered fade isOpen={this.props.isOpen}>
					<ContainerModal
						{...this.state}
						dictionary={dictionary}
						toggleTour={this.toggleTour}
						skipTutorial={this.skipTutorial}
						toggleModal={this.props.toggleModal}
					/>
				</Modal>
				{this.state.isTourOpen && (
					<ContainerTour
						{...this.props}
						{...this.state}
						dictionary={dictionary}
						toggleTour={this.toggleTour}
						isOpen={this.state.isTourOpen}
						clearCookies={this.clearCookies}
					/>
				)}
			</>
		);
	}
}

const ContainerModal = (props) => {
	let { dictionary } = props;
	return (
		<ModalBody>
			<Title1 bold className='text-center p-2'>
				{dictionary.tutorial_welcome}
			</Title1>
			<div className='text-center p-2'>
				<img alt='' src={props.image} style={{ width: "100%", minHeight: 160 }} />
			</div>
			<div className='text-center p-2'>
				<Title2>{nl2br(dictionary.tutorial_detail)}</Title2>
			</div>
			<div className='p-2'>
				<PrimaryBtn
					bold
					md
					fluid
					className='mb-2'
					onClick={() => {
						props.toggleTour();
						props.toggleModal();
					}}
				>
					{dictionary.start_tutorial}
				</PrimaryBtn>
				<InvertBtn md fluid onClick={props.skipTutorial}>
					{dictionary.skip}
				</InvertBtn>
			</div>
		</ModalBody>
	);
};

const ContainerTour = withRouter((props) => {
	let {
		isOpen,
		toggleTour,
		toggleSidebar,
		location: { pathname },
		history,
	} = props;
	let {
		store_sidebar: { toggle },
	} = props;

	const stepTutorial = [
		{
			selector: '[data-tut="sidebar_send"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && !toggle) toggleSidebar(toggle);
				if (pathname !== "/send") history.push("/send");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_sendpoint__moblie"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/send") history.push("/send");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_sendpoint__amount"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/send") history.push("/send");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_sendpoint__button_send"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/send") history.push("/send");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="sidebar_rewards"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && !toggle) toggleSidebar(toggle);
				if (pathname !== "/rewards") history.push("/rewards");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_rewards__first"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/rewards") history.push("/rewards");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="sidebar_ecouponactivate"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && !toggle) toggleSidebar(toggle);
				if (pathname !== "/ecouponactivate") history.push("/ecouponactivate");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_activate__coupon"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/ecouponactivate") history.push("/ecouponactivate");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="sidebar_poster"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && !toggle) toggleSidebar(toggle);
				if (pathname !== "/poster") history.push("/poster");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_poster__standard"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/poster") history.push("/poster");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_poster__coverBusiness"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/poster") history.push("/poster");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="sidebar_settings"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && !toggle) toggleSidebar(toggle);
				if (pathname !== "/settings") history.push("/settings");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_setting__logo"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/settings") history.push("/settings");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_setting__description"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/settings") history.push("/settings");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_setting__business_cover"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/settings") history.push("/settings");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_setting__business_cover_display"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/settings") history.push("/settings");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_setting__business_color"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/settings") history.push("/settings");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_setting__btn_save_business"]',
			style: { padding: "16px" },
			position: "top",
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/settings") history.push("/settings");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="sidebar_verify"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && !toggle) toggleSidebar(toggle);
				if (pathname !== "/verify") history.push("/verify");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
		{
			selector: '[data-tut="p_verify__detail"]',
			style: { padding: "16px" },
			action: () => {
				if (window.innerWidth < 992 && toggle) toggleSidebar(!toggle);
				if (pathname !== "/verify") history.push("/verify");
			},
			content: (content) => <ContentStep {...props} {...content} />,
		},
	];

	return (
		<Tour
			rounded={2}
			isOpen={isOpen}
			steps={stepTutorial}
			accentColor='#546174'
			showNumber={false}
			showButtons={false}
			showNavigation={false}
			showCloseButton={false}
			closeWithMask={false}
			disableInteraction
			disableDotsNavigation
			onRequestClose={toggleTour}
			update={String(`${pathname}_${toggle}`)}
			updateDelay={window.innerWidth < 992 ? 500 : 150}
			disableKeyboardNavigation={["esc", "left", "right"]}
		/>
	);
});

const ContentStep = (props) => {
	let { clearCookies } = props;
	let { dictionary, step, goTo, close } = props;

	let maxStep = 19;
	let pathStep = [0, 4, 6, 8, 11, 19];

	let backStep = step - 2;
	let thisStep = step - 1;
	let nextStep = step;
	let thisMainStepIndex = pathStep.indexOf(thisStep);
	let stepDetail = dictionary.tutorial_step_detail[thisStep];

	let btn_id = stepDetail.btnid_next || `btn-tutorial_${thisStep}`;

	return (
		<div className='position-relative' style={{ minWidth: 280 }}>
			<div className='position-absolute w-100' style={{ top: -50 }}>
				{stepDetail.title && (
					<TitleHerder>
						<Title2 bold white className='text-center'>
							{stepDetail.title}
						</Title2>
					</TitleHerder>
				)}
			</div>
			<div className='w-100 my-3 border-top border-bottom'>
				{stepDetail.description && (
					<Title2 bold className='my-3 text-center'>
						{nl2br(stepDetail.description)}
					</Title2>
				)}
				{stepDetail.note && <Title3 secondary className='my-1 text-center'>{`* ${stepDetail.note}`}</Title3>}
				{thisMainStepIndex > -1 && thisMainStepIndex !== pathStep.length - 1 && (
					<BoxSkipStep>
						<InvertBtn md fluid className='mb-2' onClick={() => goTo(pathStep[thisMainStepIndex + 1])}>
							{dictionary.skip}
							<i className='pl-2 fas fa-step-forward' style={{ fontSize: 11 }} />
						</InvertBtn>
					</BoxSkipStep>
				)}
			</div>
			<div className='w-100 d-flex align-items-center justify-content-between'>
				<div>
					{thisMainStepIndex !== 0 ? (
						<InvertBtn md onClick={() => goTo(thisMainStepIndex > -1 ? pathStep[thisMainStepIndex - 1] : backStep)}>
							<i className='pr-2 fas fa-chevron-left' style={{ fontSize: 11 }} />
							{dictionary.back}
						</InvertBtn>
					) : null}
				</div>
				{maxStep === thisStep ? (
					<SuccessBtn
						id={btn_id}
						md
						bold
						onClick={() => {
							close();
							clearCookies();
						}}
					>
						{dictionary.verify_business}
					</SuccessBtn>
				) : (
					<PrimaryBtn id={btn_id} md bold invert onClick={() => goTo(nextStep)}>
						{dictionary.next}
						<i className='pl-2 fas fa-chevron-right' style={{ fontSize: 11 }} />
					</PrimaryBtn>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		store_language: state.language,
		store_permission: state.permission,
		store_business: state.business,
		store_sidebar: state.sidebar,
		store_transfers: state.transfers,
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return bindActionCreators(
		{
			toggleSidebar,
			toggleOpenTutorial,
		},
		dispatch
	);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(tutorialList));
