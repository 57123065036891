
import Cookies from 'js-cookie'

const cookie_options = { expires: 5 * 365, secure: true, sameSite: 'strict' }
if (window.location.protocol.indexOf('https') < 0)
  Object.assign(cookie_options, { secure: false, sameSite: 'lax' })

export function getCookie(key) {
  return _getCookie(key);
}
export function getCookieJSON(key) {
  return _getCookie(key) || {};
}

export function setCookie(key, value, expires) {
  return _setCookie(key, value, expires)
}

export function removeCookie(key) {
  Cookies.remove(key)
}

export function setCookieWithDomain(key, value, expires) {
  return _setCookie(`_s${key}`, value, expires, { domain: process.env.REACT_APP_COOKIEDOMAIN })
}

export function getCookieWithDomain(key) {
  return _getCookie(`_s${key}`);
}

export function removeCookieWithDomain(key) {
  Cookies.remove(`_s${key}`, { domain: process.env.REACT_APP_COOKIEDOMAIN }) 
}

function _getCookie(key) {
  let cc = Cookies.get(key)
  if (!cc) return cc;

  let dc = cc
  try {
    dc = atob(cc)
  } catch (error) {
  }

  try {
    if (dc.indexOf('{') >= 0) { dc = JSON.parse(dc) }
    return dc;

  } catch (error) {
    return cc;
  }
}

function _setCookie(key, value, expires, options) {
  let op = Object.assign({}, cookie_options)
  if (expires)
    Object.assign(op, { expires })

  if (options) {
    Object.assign(op, options)
  }

  let cc = value
  if (typeof value === 'object') {
    cc = _getCookie(key) || {}
    cc = Object.assign({}, cc, value)
    cc = JSON.stringify(cc)
  }

  let b64 = btoa(cc)
  Cookies.set(key, b64, op)
  return true;
}
