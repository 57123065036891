import styled, { css } from "styled-components";

export const AutomationEcardContainer = styled.div`
  width: 100%;
  ${(props) =>
    props.card
      ? css`
          padding: 0.75rem 0px;
          background-color: ${(props) => props.theme.white_1};
        `
      : css`
          padding-bottom: 0.75rem;
        `}
`;

export const AutomationEcardBody = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.white};
  border-left: 1px solid ${(props) => props.theme.border_line};
  border-right: 1px solid ${(props) => props.theme.border_line};
`;

export const AutomationEcardRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0.25rem 0rem;
  border-bottom: 1px solid ${(props) => props.theme.border_line};
  background-color: ${(props) => props.theme.white_1};
  &:nth-child(even) {
    background-color: #00000008;
  }
`;

export const DescriptionText = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
`;
