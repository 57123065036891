import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'

import Loading from '../Loading'
import BusinessUsage from './BusinessUsage'

import { getUsageRecord } from '../../redux/actions/action_usage'
import { hasValueInArray } from '../../libs/validator'

class BusinessUsageIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    }
    this.getUsageRecord = this.getUsageRecord.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    let { store_business: { current } } = this.props
    this.getUsageRecord(current.business_code)
  }

  componentDidUpdate(prevProps, prevState) {
    let currentBusinessCode = prevProps.store_business.current.business_code
    let nextBusinessCode = this.props.store_business.current.business_code

    if (currentBusinessCode !== nextBusinessCode) {
      this.getUsageRecord(nextBusinessCode)
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUsageRecord(business_code) {
    this.setState({ isLoading: true })

    this.props.getUsageRecord(business_code, () => {
      if (this._isMounted) {
        this.setState({ isLoading: false })
      }
    })
  }

  render() {
    let { store_package: { packages, isLoadingBusinessPackage, packages_free } } = this.props
    let { store_language: { dictionary } } = this.props
    let { store_usage: { usage_record, usage_limit, business_limit } } = this.props
    let overLimit = packages && packages.package_data && !hasValueInArray(packages_free, packages.package_data.package_id) ? false : usage_limit && usage_limit.isOverUsage

    return (this.state.isLoading || isLoadingBusinessPackage)
      ? <LoadingBox />
      : <BusinessUsage
        overLimit={overLimit}
        dictionary={dictionary}
        createDate={usage_record.create_date}
        usageTxAllTotal={usage_record.tx_all_total}
        usageMember={parseInt(usage_record.tx_member, 10) || 0}
        usageCredit={parseFloat(usage_record.usage_credit) || 0}
        usageTransaction={parseInt(usage_record.tx_total, 10) || 0}
        business_limit={business_limit} />
  }
}

const LoadingBox = () => {
  return <div className='p-3'>
    <div className='d-flex align-items-center justify-content-between' style={{ minHeight: 50 }}>
      <div className='w-100 d-flex align-items-center justify-content-center'>
        <Loading size='2rem' margin='0px' />
      </div>
    </div>
  </div>
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_business: state.business,
    store_config: state.config,
    store_language: state.language,
    store_package: state.packages,
    store_usage: state.usage,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getUsageRecord
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessUsageIndex)
