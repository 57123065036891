import axios from "./../axios_request";

import { businessAPI } from "../end_point";
import { uploadPhoto } from "../../libs/Firebase";

import {
	ECOUPON_CLEAR,
	ECOUPON_LOADING,
	ECOUPON_FAIL,
	ECOUPON_GET,
	ECOUPON_GET_MORE,
	ECOUPON_GET_MEMBER_PROFILE,
	ECOUPON_GET_MEMBER_PROFILE_LOADING,
	ECOUPON_GET_MEMBER_PROFILE_SUCCESS,
	ECOUPON_CLEAR_MEMBER_PROFILE,
	ECOUPON_GET_ALL,
} from "../actions_const";
import { getCookie } from "../../libs/cookies";

export const clearEcoupon = () => {
	return (dispatch) => {
		dispatch({ type: ECOUPON_CLEAR });
	};
};

export const getEcoupon = ({ business_code, offset, limit, type }, callback) => {
	return (dispatch) => {
		dispatch({ type: ECOUPON_LOADING });
		axios({
			method: "get",
			headers: { Authorization: getCookie("_token") },
			url: `${businessAPI}/${business_code}/ecard/coupon`,
      params: { offset, limit }
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data.data);
					if (type === "load_more") return dispatch({ type: ECOUPON_GET_MORE, data: data, total: data.total });
					else return dispatch({ type: ECOUPON_GET, data: data, total: data.total });
				} else if (typeof callback === "function") callback("result error");
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
				return dispatch({ type: ECOUPON_FAIL });
			});
	};
};

export const createEcoupon = (business_code, data, callback) => {
	const dataCoupon = {
		title: data.title,
		description: data.description,
		start_due: data.start_due,
		stop_due: data.stop_due,
		action: {
			type: data.action_type,
			value: data.action_value,
		},
		target: {
			type: data.target_type,
			value: data.target_value,
		},
		send_sms: data.send_sms,
		copy: data.copy,
		path_location: data.path_location || `${business_code}/coupons/${new Date().getTime()}.jpeg`,
		ecard_life_time: data.ecard_life_time,
		language: data.language,
		credit: parseFloat(data.credit).toFixed(2),
	};

	return (dispatch) => {
		let uploadPath = data.copy ? "" : dataCoupon.path_location;
		uploadPhoto(uploadPath, data.image)
			.then((res) => {
				if (res.url) dataCoupon.link_url = res.url;
				else dataCoupon.link_url = data.image;
				if (dataCoupon.copy) {
					let reg = new RegExp(`${business_code}/[a-z]*/[0-9]*.jpeg`, "g");
					let newPath = decodeURIComponent(dataCoupon.link_url).match(reg);
					dataCoupon.path_location = newPath[0];
				}
				axios({
					method: "post",
					url: `${businessAPI}/${business_code}/ecard/coupon`,
					headers: { Authorization: getCookie("_token") },
					data: dataCoupon,
				})
					.then(({ data }) => {
						if (data.result) {
							if (typeof callback === "function") callback(null, data);
						} else if (typeof callback === "function") callback(data);
					})
					.catch((err) => {
						if (typeof callback === "function") callback(err);
					});
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

export const updateEcoupon = (business_code, data, callback) => {
	const dataCoupon = {
		title: data.title,
		description: data.description,
		start_due: data.start_due,
		stop_due: data.stop_due,
		ecard_life_time: data.ecard_life_time,
		action: {
			type: data.action_type,
			value: data.action_value,
		},
	};
	return (dispatch) => {
		uploadPhoto(data.path_location, data.image)
			.then((res) => {
				dataCoupon.link_url = res.url || data.image;
				axios({
					method: "PUT",
					url: `${businessAPI}/${business_code}/ecard/coupon/${data.ecard_id}`,
					headers: { Authorization: getCookie("_token") },
					data: dataCoupon,
				})
					.then(({ data }) => {
						if (data.result) {
							if (typeof callback === "function") callback(null, data);
						} else if (typeof callback === "function") callback("result error");
					})
					.catch((err) => {
						if (typeof callback === "function") callback(err);
					});
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

export const deleteEcoupon = (business_code, ecard_id, callback) => {
	return (dispatch) => {
		axios({
			method: "delete",
			headers: { Authorization: getCookie("_token") },
			url: `${businessAPI}/${business_code}/ecard/coupon/${ecard_id}`,
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data);
				} else if (typeof callback === "function") callback("result error");
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

export const getWelcomeEcoupon = ({ business_code, offset, limit, type }, callback) => {
	return (dispatch) => {
		dispatch({ type: ECOUPON_LOADING });
		axios({
			method: "get",
			headers: { Authorization: getCookie("_token") },
			url: `${businessAPI}/${business_code}/ecard/welcoupon`,
      params: { offset, limit }
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data.data);
					if (type === "load_more") return dispatch({ type: ECOUPON_GET_MORE, data: data, total: data.total });
					else return dispatch({ type: ECOUPON_GET, data: data, total: data.total });
				} else if (typeof callback === "function") callback("result error");
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
				return dispatch({ type: ECOUPON_FAIL });
			});
	};
};

export const getAllWelcomeEcoupon = ({ business_code }, callback) => {
	return (dispatch) => {
		dispatch({ type: ECOUPON_LOADING });
		axios({
			method: "get",
			headers: { Authorization: getCookie("_token") },
			url: `${businessAPI}/${business_code}/ecard/welcoupon`,
      params: { expire : 0 }
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data.data);
					return dispatch({ type: ECOUPON_GET_ALL, data: data });
				} else if (typeof callback === "function") callback("result error");
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
				return dispatch({ type: ECOUPON_FAIL });
			});
	};
};

export const createWelcomeEcoupon = (business_code, data, callback) => {
	const imgPath = `${business_code}/welcoupon/${new Date().getTime()}.jpeg`;
	const dataCoupon = {
		title: data.title,
		description: data.description,
		start_due: data.start_due,
		stop_due: data.stop_due,
		path_location: imgPath,
		action: {
			type: data.action_type,
			value: data.action_value,
		},
		target: {
			type: data.target_type,
			value: data.target_value,
		},
	};
	return (dispatch) => {
		uploadPhoto(imgPath, data.image)
			.then((res) => {
				dataCoupon.link_url = res.url;
				axios({
					method: "post",
					url: `${businessAPI}/${business_code}/ecard/welcoupon`,
					headers: { Authorization: getCookie("_token") },
					data: dataCoupon,
				})
					.then(({ data }) => {
						if (data.result) {
							if (typeof callback === "function") callback(null, data);
						} else if (typeof callback === "function") callback("result error");
					})
					.catch((err) => {
						if (typeof callback === "function") callback(err);
					});
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

export const deleteWelcomeEcoupon = (business_code, ecard_id, callback) => {
	return (dispatch) => {
		axios({
			method: "delete",
			headers: { Authorization: getCookie("_token") },
			url: `${businessAPI}/${business_code}/ecard/welcoupon/${ecard_id}`,
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data);
				} else if (typeof callback === "function") callback("result error");
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

export const updateWelcomeEcoupon = (business_code, data, callback) => {
	const dataCoupon = {
		title: data.title,
		description: data.description,
		start_due: data.start_due,
		stop_due: data.stop_due,
		action: {
			type: data.action_type,
			value: data.action_value,
		},
	};
	return (dispatch) => {
		uploadPhoto(data.path_location, data.image)
			.then((res) => {
				dataCoupon.link_url = res.url || data.image;
				axios({
					method: "PUT",
					url: `${businessAPI}/${business_code}/ecard/welcoupon/${data.ecard_id}`,
					headers: { Authorization: getCookie("_token") },
					data: dataCoupon,
				})
					.then(({ data }) => {
						if (data.result) {
							if (typeof callback === "function") callback(null, data);
						} else if (typeof callback === "function") callback("result error");
					})
					.catch((err) => {
						if (typeof callback === "function") callback(err);
					});
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

export const getMemberProfile = (businessCode, memberBadgeCode) => {
	return (dispatch) => {
		dispatch({ type: ECOUPON_GET_MEMBER_PROFILE_LOADING });
		axios({
			method: "get",
			url: `${businessAPI}/${businessCode}/member/${memberBadgeCode}`,
			headers: { Authorization: getCookie("_token") },
		})
			.then((res) => {
				if (!res.data.result) {
				} else {
					let { data, is_subscribe } = res.data.data;
					dispatch({ type: ECOUPON_GET_MEMBER_PROFILE, data: is_subscribe ? data : [] });
				}
			})
			.catch((err) => {
				dispatch({ type: ECOUPON_GET_MEMBER_PROFILE, data: [] });
			});
	};
};

export const getMemberProfileTarget = (businessCode, targets, callback) => {
	let isCallBack = typeof callback === "function";
	return (dispatch) => {
		dispatch({ type: ECOUPON_GET_MEMBER_PROFILE_LOADING });
		axios({
			method: "POST",
			url: `${businessAPI}/${businessCode}/member/target`,
			headers: { Authorization: getCookie("_token") },
			data: { targets },
		})
			.then((res) => {
				isCallBack && callback(res.data.data);
				dispatch({ type: ECOUPON_GET_MEMBER_PROFILE_SUCCESS });
			})
			.catch((err) => {
				isCallBack && callback(false);
				dispatch({ type: ECOUPON_GET_MEMBER_PROFILE_SUCCESS });
			});
	};
};

export const clearMemberProfile = () => {
	return (dispatch) => {
		dispatch({ type: ECOUPON_CLEAR_MEMBER_PROFILE });
	};
};

export const sortWelcomeEcoupon = (business_code, data, callback) => {
	return (dispatch) => {
		axios({
			method: "put",
			headers: { Authorization: getCookie("_token") },
			url: `${businessAPI}/${business_code}/ecard/welcoupon/sort`,
			data: data,
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data);
				} else if (typeof callback === "function") callback("result error");
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

export const getEcardImportedCodeHistory = (business_code, ecard_id, callback) => {
	return (dispatch) => {
		axios({
			method: "get",
			headers: { Authorization: getCookie("_token") },
			url: `${businessAPI}/${business_code}/ecard/importedCode/history/${ecard_id}`,
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data.data);
				} else if (typeof callback === "function") callback("result error");
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

/**
 * Get list of import code.
 * @param {String} business_code
 * @param {Callback} callback
 * @returns Array - Array with code in import code table.
 */
export const getEcardImportedCodeList = (business_code, callback) => {
	return (dispatch) => {
		axios({
			method: "get",
			headers: { Authorization: getCookie("_token") },
			url: `${businessAPI}/${business_code}/ecard/importedCode/list`,
		})
			.then(({ data }) => {
				if (data.result) {
					if (typeof callback === "function") callback(null, data.data);
				} else if (typeof callback === "function") callback("result error");
			})
			.catch((err) => {
				if (typeof callback === "function") callback(err);
			});
	};
};

/**
 *
 * @param {String} business_code
 * @param {Array} codes
 * @param {Callback} callback
 * @returns Array - Array with code that be already exist.
 */
export const getRedeemCodeUsedInBusiness = async (business_code, codes = [], callback) => {
	return await axios({
		method: "post",
		headers: { Authorization: getCookie("_token") },
		url: `${businessAPI}/${business_code}/ecard/importedCode/validate/redeemCode`,
		data: {
			codes: codes,
		},
	})
		.then(({ data }) => {
			if (data.result) {
				if (typeof callback === "function") callback(null, data.data);
				else return data.data;
			} else if (typeof callback === "function") callback("result error");
		})
		.catch((err) => {
			if (typeof callback === "function") callback(err);
		});
};
