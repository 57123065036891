import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getTotalMember } from "./../../redux/actions/action_member";
import MemberBoardPresenter from './presenter'

class MemberBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      memberType: 'member'
    }
  }
  componentDidMount() {
    let { store_business: { current: currentBusiness } } = this.props
    let { memberType, getTotalMember } = this.props
    memberType = memberType || 'member'

    this.setState({ memberType: memberType })
    getTotalMember(currentBusiness.business_code, memberType)
  }

  render() {
    return <MemberBoardPresenter {...this.props}></MemberBoardPresenter>
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_members: state.members,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getTotalMember
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MemberBoard)