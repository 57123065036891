import _ from "lodash";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, CardHeader, CardBody, ListGroup, ListGroupItem, Popover, PopoverBody } from "reactstrap";
import styled from "styled-components";
import { Title2, Title3 } from "../../../components/Font";
import SwitchToggle from "../../../components/SwitchToggle";
import { ToastBar } from "../../../components/ToastComponent/Toast.style";
import { formatDateFull2, formatDateTime } from "../../../libs/date";
import { templateRFM } from "../../../libs/rfm";
import { updateCampaignStatus } from "../../../redux/actions/action_automation";
import { FREQUENCY_TYPES, TARGET_TYPES } from "./const";

const Campaign = React.memo(function ({ disabled, data, onEditClick, onDuplicateClick, memberCardInfo = [], refreshCampaign }) {
  const dispatch = useDispatch();
  const dictionary = useSelector((state) => state.language.dictionary);
  const [isLoading, setLoading] = useState(false);
  const [isEnable, setEnable] = useState(data.enable);
  const { ecard_info = {}, campaign_id, stop_due } = data;
  const { link_url: image, total_send, total_activate, target_type, target_value } = ecard_info;
  const frequency = _.find(FREQUENCY_TYPES, { key: data.frequency });
  const target = _.find(TARGET_TYPES, { key: target_type }) || {};
  const isExpired = checkExpire(stop_due);
  let targetSubValue = '';

  if (target_type) {
    if (target_type.indexOf("rfm") > -1) {
      const rfm_group = _.find(templateRFM, { segment_group_id: parseInt(target_value) }) || {};
      targetSubValue = dictionary.RFM_list[rfm_group.RFM_key].name;
    }
    
    if (target_type === 'member_card') {
      const memberCard = target_value === 'all' ? { card_name: dictionary.all } : (_.find(memberCardInfo, { card_id: Number(target_value) }) || { card_name: '-' });
      targetSubValue = memberCard.card_name;
    }
  }

  const onEdit = () => {
    onEditClick(data);
  };

  const onDuplicate = () => {
    onDuplicateClick(data);
  };

  const toggle = () => {
    setLoading(true);
    dispatch(updateCampaignStatus(data.business_code, {
      campaign_id,
      enable: !isEnable
    }, (err) => {
      if (err) {
        toast.error(
          <ToastBar>
            <Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{dictionary.error_occurred}</Title2>
          </ToastBar>
        )
      } else {
        toast.success(
          <ToastBar>
            <Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{dictionary.saved_successfully}</Title2>
          </ToastBar>
        )
        setEnable(!isEnable);
        refreshCampaign()
      }
      setLoading(false);
    }))
  }

  //console.log("Campaign expire", data.title, isExpired, stop_due);

  return (
    <Card className="mb-3">
      <CardHeader>
        <div className="align-items-center d-flex justify-content-between">
          <div>
            <Title2 bold>{data.title}</Title2>
            {
              isExpired 
              ? <Title3 bold danger>{dictionary.campaign_expired}</Title3>
              : (
                <Title3 secondary>{
                  data.update_date 
                  ? `${dictionary.latest_update}: ${formatDateTime(data.update_date)}` 
                  : `${dictionary.created_on}: ${formatDateTime(data.create_date)}`
                }</Title3>
              )
            }
          </div>
          <div className="align-items-center d-flex">
            <SwitchToggle active={isEnable} isLoading={isLoading} toggle={toggle} disabled={(isExpired || disabled)} opacity={(isExpired || disabled) ? 0.25 : 1} />
            <MenuButton id={`campaign-menu-${data.campaign_id}`} onEditClick={onEdit} onDuplicateClick={onDuplicate} disabled={disabled}/>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-column flex-md-row no-gutters">
          <div className="col-md-3 mr-md-4" style={isExpired ? {filter: 'grayscale(100)'} : null}>
            <CouponImage image={image} />
          </div>
          <div className="col-md-9">
            <div className="d-flex flex-column flex-md-row">
              <div className="col-md-4 mb-2">
                <Title3 secondary>{dictionary.name_ecoupon}</Title3>
                <Title2>{ecard_info.title}</Title2>
              </div>
              <div className="col-md-3 mb-2">
                <Title3 secondary>{dictionary.target}</Title3>
                <Title2>{target.withSubValue ? `${dictionary[target.title]}: ${targetSubValue}` : dictionary[target.title]}</Title2>
              </div>
              <div className="col-md-3 mb-2">
                <Title3 secondary>{dictionary.campaign_frequency}</Title3>
                <Title2>{frequency ? dictionary[frequency.title] : '-'}
                </Title2>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row mt-md-4">
              <div className="col-md-4 mb-2">
                <Title3 secondary>{dictionary.campaign_period}</Title3>
                <Title2>{`${formatDateFull2(data.start_due)} - ${formatDateFull2(data.stop_due)}`}</Title2>
              </div>
              <div className="col-md-3 mb-2">
                <Title3 secondary>{dictionary.campaign_coupon_sent}</Title3>
                <Title2>{parseInt(total_send).toLocaleString()}</Title2>
              </div>
              <div className="col-md-3 mb-2">
                <Title3 secondary>{dictionary.campaign_coupon_activated}</Title3>
                <Title2>{parseInt(total_activate).toLocaleString()}</Title2>
              </div>
              <div className="col-md-2 mb-2">
                <Title3 secondary>{dictionary.campaign_coupon_usage}</Title3>
                <Title2>{parseInt(total_send) ? `${+((parseInt(total_activate) / parseInt(total_send)) * 100).toFixed(2)}%` : '0%'}</Title2>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  )
});

export default Campaign;

const MenuButton = ({ id, onEditClick, onDuplicateClick, disabled }) => {
  const dictionary = useSelector((state) => state.language.dictionary);
  const [showMenu, setShowMenu] = useState(false);
  const menuList = [
    {
      key: 'edit',
      title: dictionary.edit,
      icon: 'fas fa-pen pr-2',
      onClick: onEditClick
    },
    {
      key: 'duplicate',
      title: dictionary.duplicate,
      icon: 'fas fa-copy pr-2',
      onClick: onDuplicateClick
    }
  ];

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="align-items-center border-left d-flex px-4" style={{ margin: '-0.75rem -1.25rem -0.75rem 1.5rem', height: 64 }}>
      <Title2 id={id} onClick={disabled ? null : toggleMenu}>
        <i className="fas fa-ellipsis-v fa-lg" style={{ cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? 0.25 : 1 }}></i>
      </Title2>
      <Popover
        target={id}
        toggle={toggleMenu}
        isOpen={showMenu}
        trigger="focus"
        placement="bottom-end"
        flip >
        <PopoverBody>
          <ListGroup flush>
            {
              menuList.map((item, index) => (
                <ListGroupItem key={index} onClick={item.onClick} style={{ cursor: 'pointer' }}>
                  <Title2><i className={item.icon} /> {item.title}</Title2>
                </ListGroupItem>
              ))
            }
          </ListGroup>
        </PopoverBody>
      </Popover>
    </div>
  )
}

const checkExpire = (stop_due) => {
  const today = moment().endOf("day");
  const dueDate = moment(stop_due).endOf("day");
  return today.isAfter(dueDate);
}

const CouponImage = styled.div`
  width: 100%;
  padding-top: calc(100% * 9/16);
  background-image: url(${props => props.image});
  background-size: cover;
  border-radius: 16px;
  border: 1px solid #ddd;
  background-position: center;

  @media (max-width: 767px) { 
    margin: 0 15px 15px;
    width: calc(100% - 30px);
    padding-top: calc((100% - 30px) * 9/16);
  }
`