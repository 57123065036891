import React from "react"
import { useSelector } from "react-redux"
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import { InvertBtn } from "../Button"
import { Title2, Title3 } from "../Font"

import icon from '../../../src/assets/img/contact_us.svg'

export default function ContactUsModal(props) {
  const dictionary = useSelector((state) => state.language.dictionary)
  const { isOpen, toggleContact, isPurchase } = props
  return (
    <Modal isOpen={isOpen} centered style={{ width: 370 }}>
      <ModalBody>
        <div className="d-flex justify-content-center">
          <img src={icon} alt='' style={{ width: '15%' }} />
          <div className='d-flex flex-column justify-content-center ml-4' style={{ minHeight: 140 }}>
            <Title2 style={{ fontSize: '18px' }}>{isPurchase ? dictionary.contact_purchase : dictionary.contact_upgrade}</Title2>
            <Title2 style={{ fontSize: '18px' }}>{dictionary.contact_call}</Title2>
            <Title2 bold style={{ fontSize: '24px' }}>{dictionary.contact_number}</Title2>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <InvertBtn onClick={toggleContact}>
          <Title3 bold secondary>{dictionary.close}</Title3>
        </InvertBtn>
      </ModalFooter>
    </Modal>
  )
}