import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Title2 } from "../../components/Font";
import { VerifyModalFooter } from "./verifyBusinessHash.style";
import { InvertBtn, PrimaryBtn } from "../../components/Button";
import { DEBUG } from "../../libs/utils";
import { MANAGER } from "../../redux/actions_const";

export default function ConfirmModal({ isOpen, toggle, text, onConfirm, closeOnError }) {
  const isInited = useRef(false);
  const processingStatus = useSelector(
    (state) => state.manager.processingStatus
  );
  const isProcessing = processingStatus.status === MANAGER.PROCESS_STATUS_WORKING;

  useEffect(() => {
    DEBUG("[useEffect] ConfirmModal", processingStatus, isOpen);
    if (!isInited.current) {
      isInited.current = true;
    } else {
      if (isOpen && (processingStatus.status === MANAGER.PROCESS_STATUS_SUCCEED 
          || ( processingStatus.status === MANAGER.PROCESS_STATUS_ERROR && closeOnError))) {
        toggle();
      }
    }
  }, [processingStatus, isOpen, isInited, toggle]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={isOpen} centered={true}>
      <ModalBody className="p-4 text-lg-center">
        <Title2>{text}</Title2>
      </ModalBody>
      <VerifyModalFooter className="d-flex flex-grow-1 justify-content-end">
      <InvertBtn onClick={toggle} disabled={isProcessing}>
          ยกเลิก
        </InvertBtn>
        <PrimaryBtn
          onClick={onConfirm}
          disabled={isProcessing}
        >
          {isProcessing ? <i className="fas fa-spinner fa-pulse" /> : "ยืนยัน"}
        </PrimaryBtn>
      </VerifyModalFooter>
    </Modal>
  )
}