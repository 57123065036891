import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { toCurrency } from "../../../../libs/currency";
import { Title1 } from "../../../Font";

export default function UsageChart(props) {
  const { data, label, total } = props;
  const { dictionary } = useSelector((state) => state.language)

  const textCenter = {
    id: "textCenter",
    beforeDatasetDraw(chart) {
      const { ctx, data } = chart;
      const width = chart.width / 2
      const height = chart.height / 2
      let creditCurrency = label === dictionary.monthly_free_credits || label === dictionary.add_ons_credit
      let remain = toCurrency(data.datasets[0].data[0], creditCurrency ? 2 : 0);
      let totals = toCurrency(total, creditCurrency ? 2 : 0);
  
      ctx.save();
      ctx.font = "normal 10px Prompt";
      ctx.fillStyle = "#B2C4D9";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(
        dictionary.balance,
        width,
        height - 10
      );

      ctx.font = "normal 16px Prompt";
      ctx.fillStyle = "#556271";
      ctx.fillText(
        remain,
        width,
        height + 10
      );

      ctx.font = "normal 10px Prompt";
      ctx.fillStyle = "#B2C4D9";
      ctx.fillText(
        `/${totals}`,
        width,
        height + 30
      );

      ctx.restore();
    },
  };

  return (
    <div>
      <Doughnut
        height={250}
        data={data}
        options={{
          maintainAspectRatio: true,
          responsive: true,
          cutoutPercentage: 80,
          tooltips: {
            enabled: false,
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        }}
        plugins={[textCenter]}
      />
      <Title1 className="d-flex justify-content-center align-items-center text-center" style={{ marginTop: "10px", minHeight: '48px' }}>
        {label}
      </Title1>
    </div>
  );
}
