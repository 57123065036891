import axios from './../axios_request';
import { uploadPhoto } from '../../libs/Firebase'
import { businessAPI } from '../end_point'
import { TRANSFERS, USAGE, BUSINESS_SEND_POINT_SUCCESS } from '../actions_const'
import { getCookie } from '../../libs/cookies';
import _ from 'lodash';

export const getMemberProfile = (business_code, keyword, callback) => {
  return dispatch => {
    dispatch({ type: TRANSFERS.PROFILE_SEARCHING })
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/member/${keyword}`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data)
        return dispatch({ type: TRANSFERS.GET_MEMBER_PROFILE, data: data.data })
      } else
        if (typeof callback === 'function') callback(data.error)
        // return Promise.reject(data.error || 'result error')
    }).catch((err) => {
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: TRANSFERS.GET_MEMBER_PROFILE })
    })
  }
}

export const transferPoint = (preData, business_code, callback) => {
  let { image } = preData
  let isFunc = typeof callback === 'function'
  let path_location = business_code + `/transactions/${new Date().getTime()}.jpeg`
  let img, link_url;

  return async dispatch => {
    dispatch({ type: TRANSFERS.TRANSFER_POINT_IS_PROCESS })
    try {
      if (image) {
        img = await uploadPhoto(path_location, image)
        if (!img.url) throw new Error('Image url error')
        link_url = img.url
      }

      delete preData.image
      let { data } = await axios({
        method: 'put',
        url: `${businessAPI}/${business_code}/point/${preData.type}`,
        headers: { Authorization: getCookie("_token") },
        data: Object.assign({}, preData, { img: link_url || '' })
      })

      if (data.result) {

        let transferInterval = setInterval(() => {
          clearInterval(transferInterval)
          dispatch({ type: USAGE.INCREASE_USAGE_RECORD, data: { type: preData.type } })
        }, 800)

        if (preData.type === 'send') {
          dispatch({ type: BUSINESS_SEND_POINT_SUCCESS })
          const member_badge_code = _.get(data.data, [0, 'member_badge_code']);
          if (global && global.memberRequest && global.memberRequest[member_badge_code]) {
            delete global.memberRequest[member_badge_code];
          }
        }

        if (isFunc) callback(null, preData)
        return dispatch({ type: TRANSFERS.TRANSFER_POINT_SUCCESS, data: preData })
      } else
        throw ((data && data.error) || { code: 'transfers/operation-error', message: 'Operation have problem.' })

    } catch (error) {
      if (isFunc) callback(error)
      return dispatch({
        type: TRANSFERS.TRANSFER_POINT_UNSUCCESS,
        error: error ? error : { code: 'transfers/operation-error', message: 'Operation have problem.' }
      })
    }
  }
}

export const clearMemberProfile = () => {
  return { type: TRANSFERS.CLEAR_MEMBER_PROFILE }
}

export const clearError = () => {
  return { type: TRANSFERS.CLEAR_ERROR }
}
