import {
  MEMBER_IS_LOGIN,
  MEMBER_IS_LOGOUT,
  GET_SITE_CONFIG,
  PERMISSION
} from '../actions_const'

let initState = {
  authAdmin: false,
  authAccount: false,
  isLoading: true,
  profile: {},
  siteConfig: {},
  isVerify: false,
  isLogin: false,
  isUpdateToken: false,
  isError: false,
  isShow: false,
  providerData: null,
  message: '',
  profileAccount: {},
  tabUUIDStore: '',
}
const ReducerPermission = (state = initState, action) => {
  switch (action.type) {
    case MEMBER_IS_LOGIN:
      return Object.assign({}, state,
        {
          authAdmin: true,
          authAccount: action.data.authAccount,
          isLoading: false,
          profile: action.data,
          isVerify: action.data.isVerify,
          isUpdateToken: false,
        }
      )
    case MEMBER_IS_LOGOUT:
      return Object.assign({}, state, {
        authAdmin: false,
        authAccount: false,
        isLoading: false,
        profile: {}
      })
    case GET_SITE_CONFIG:
      return Object.assign({}, state, {
        siteConfig: action.data
      })
    case PERMISSION.USER_LOGIN_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
      })
    case PERMISSION.USER_LOGIN_FAIL:
      return Object.assign({}, state, {
        authAccount: false,
        isLoading: false,
        isLogin: false,
        isError: true,
        isShow: false,
        message: action.error.message,
        error: action.error
      })
    case PERMISSION.USER_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        authAccount: true,
        isLoading: false,
        isLogin: true,
        isUpdateToken: true,
        isError: false,
        isShow: false,
        isVerify: action.data.emailVerified || false,
        profile: action.data
      })
    case PERMISSION.USER_LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        authAdmin: false,
        authAccount: false,
        isLoading: false,
        isLogin: false,
        isShow: false,
        isError: false,
        profile: {},
        providerData: null,
        isVerify: false
      })
    case PERMISSION.REMOVE_SOCIAL_CREDENTAIL:
      return Object.assign({}, state, {
        providerData: null
      })
    case PERMISSION.CREATE_USER_FAIL:
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        isLogin: false,
        isShow: false,
        message: action.error.message,
        error: action.error
      })
    case PERMISSION.EMAIL_ALREADY_VERIFIED:
      return Object.assign({}, state, {
        isVerify: true
      })
    case PERMISSION.CLEAR_ERROR:
      return Object.assign({}, state, {
          isError: false,
          message: ''
      })
    case PERMISSION.GET_PROFILE_ACCOUNT:
      return Object.assign({}, state, {
        profileAccount: action.data
      });
    case PERMISSION.SET_TAB_UUID:
      return Object.assign({}, state, {
        tabUUIDStore: action.data
      });
    default:
      return state
  }
}

export default ReducerPermission;