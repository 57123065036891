import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Container, Row, Col, Progress } from 'reactstrap'

import { Title1 } from '../../components/Font'
import PointsDashboard from '../../components/PointsDashboard'
import BusinessReview from '../../components/BusinessReview'
import MemberBoard from '../../components/MemberBoard'
import RecentTransactionBoard from '../../components/RecentTransactionBoard'

const boxShadowStyle = {
  border: '1px solid',
  margin: '10px',
  padding: '10px',
  boxShadow: '5px 10px #888888'
}

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      boardlist: [
        {
          name: 'row-top',
          columns: [
            {// Column Total Subscribe Member
              name: 'subscribe-board',
              size: [12, 4, null, null, null],
              component: <MemberBoard memberType={'subscriber'}></MemberBoard>
            },
            {// Column Total Member
              name: 'member-board',
              size: [12, 4, null, null, null],
              component: <MemberBoard style={boxShadowStyle} memberType={'premember'}></MemberBoard>
            },
            {// Column Total Engaged
              name: 'engaged-board',
              size: [12, 4, null, null, null],
              component: <MemberBoard style={boxShadowStyle} memberType={'engaged'}></MemberBoard>
            },
          ]
        },
        {
          name: 'row-2',
          columns: [
            {// Column PointBoard
              name: 'points-board',
              size: [12, null, null, null, null],
              component: <PointsDashboard></PointsDashboard>
            },
            {// Column BusinessReview
              name: 'review-board',
              size: [12, null, null, null, null],
              component: <BusinessReview></BusinessReview>
            }
          ]
        },
        {
          name: 'row-3',
          columns: [
            {// Column PointBoard
              name: 'recent-transaction-board',
              size: [12, null, 6, null, null],
              component: <RecentTransactionBoard></RecentTransactionBoard>
            }
          ]
        }
      ]
    }
  }

  render() {
    let { store_members: { stats } } = this.props
    let { boardlist } = this.state;

    let maxMember = 500;
    let memberUsage = stats.engaged || 0;
    let maxBar = memberUsage > maxMember ? memberUsage : maxMember

    return (
      <div>
        <Container className='mt-3 mb-3'>
          <Row>
            <Col className='p-3'>
              <Title1>Member capacity
                <span className={`float-right ${memberUsage > maxMember ? 'text-danger' : 'text-info'}`}>
                  ({memberUsage.toLocaleString()} / {maxMember.toLocaleString()})
                </span>
              </Title1>
              <Progress multi >
                <Progress bar color="success" value={stats.subscriber} max={maxBar} />
                <Progress bar color="warning" value={memberUsage - stats.subscriber} max={maxBar} />
                <Progress bar color="danger" value={memberUsage - maxMember} max={maxBar} />
              </Progress>
            </Col>
          </Row>
          {
            /**
             * Show Board on dashboard
             */
            boardlist.map(row => {
              return <Row key={row.name}>
                {row.columns.map(column => {
                  return <Col key={column.name} className='p-3'
                    xs={column.size[0]} sm={column.size[1]} md={column.size[2]} lg={column.size[3]} xl={column.size[4]}>
                    {column.component}
                  </Col>
                })}
              </Row>
            })
          }
        </Container>
      </div >
    )
  }
}


//const ActivityListWithLoading = HOC(ActivityList)

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_business: state.business,
    store_members: state.members,
    store_language: state.language,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)