import styled, { createGlobalStyle, css } from 'styled-components'

createGlobalStyle`
 .business-logo-rounded .cropper-face, 
 .business-logo-rounded .cropper-view-box {
   border-radius: 50%;
  }
 .cropper-modal{
   opacity: .75;
  }
  .modal-content{
    overflow: hidden;
  }
`;

export const ImgUploadContainer = styled.div`
  cursor: ${props => props.disabled ? '' : 'pointer'};
  min-height: 100px;
  max-width:100%;
  height: auto;
  display: flex;
  justify-content: center; 
  align-items: center;
  position: relative;
  &:hover{
    opacity: 0.95;
  }
`
export const CameraWrapper = styled.div`
  position:absolute;
  bottom: 0;
  right: 0;
  background: #fafafa;
  padding: 5px 10px;
  border-top-left-radius: 4px;
`
export const CameraHover = styled.div`
  position:absolute;
  top:40%;
  background: #fafafa;
  padding: 5px 10px;
  border: 1px solid #dee2e6;
`
export const CameraPlaceholder = styled.div`
  ${(props) =>
    !props.backgroundHidden &&
    !props.broadcastLine &&
    css`
      min-height: 200px;
    `}
  display: flex;
  align-items: center;

  ${(props) =>
    props.broadcastLine &&
    css`
      height: 150px;
      width: 150px;
      justify-content: center;
    `}
`;