import styled, { css } from 'styled-components';

export const TitlebarContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.primary};
  position: sticky;
  top:0;
  z-index: 999;
`

export const IconMenubar = styled.div`
  color: ${props => props.theme.white};
  cursor: pointer;
  padding: 15px 0px 15px 15px; 
`

export const PageTitle = styled.div`
  margin: 0px 15px;
  @media (max-width: 376px) {
    margin: 0px 0px 0px 12px;
  }

 transition: all 0.25s ease;
`

export const Dropdown = styled.div`
  z-index: 1010;
  display: flex;
  margin: 0px 15px;
`

export const TopBar = styled.div`
  z-index: 1020;
  display: flex;
  align-items: center;
`

export const Manual = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 15px 0px 0px;
  &:hover{
    font-size: ${props => props.theme.font_m};
  }
`
export const Support = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 15px 0px 0px;
  &:hover{
    font-size: ${props => props.theme.font_m};
  }
`

export const InputSearch = styled.input`
  margin-right: 18px;
  text-align: center;
  visibility: hidden;

  transition: all 0.2s ease-in;
  background: transparent !important;
  color: ${props => props.theme.white} !important;

  border-style: solid;
  border-width: 0 0 1px 0;
  border-color: ${props => props.theme.white_border};

  &::placeholder {
    color: #e6e6e6AA; 
    text-align: center;
    font-size: ${props => props.theme.font_s};
  }

  font-size: ${props => props.theme.font_m};
  ${props => props.isSearch
    ? css`width: 190px; visibility: visible;`
    : css`width: 0px; visibility: hidden;`
  }

  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_s};
    ${props => props.isSearch
    ? css`width: 170px; visibility: visible;`
    : css`width: 0px; visibility: hidden;`
  }
  }
`

export const ComponentName = styled.div`
  overflow: hidden;
  width: 100%;
  ${props => props.isOpen
    ? css`transition: all 1s ease-in;`
    : css`transition: all .2s ease-out;`
  }

  @media (max-width: 500px) {
    ${props => props.isOpen
      ? css`opacity: 1; visibility: visible;`
      : css`opacity: 0; visibility: hidden;`
    }
  }
`

export const ComponentIcon = styled.div`
  transition: all .25s ease;
  overflow: hidden;

  ${props => props.isOpen
    ? css`width: 30px; visibility: visible;`
    : css`width: 0px; visibility: hidden;`
  }
`