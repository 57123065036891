import React, { useState, useEffect, useRef, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import "./GoogleLogin.css";
import { setUserData } from "../../redux/actions/action_manager";
import { VerifyBusinessContext } from "./context";
import Logo from '../../assets/img/logo-white.svg';
function App(props) {
  const {
    REACT_APP_BACKEND_INTERNAL_API_SECRET_TOKEN,
    REACT_APP_GOOGLE_CLIENT_ID,
  } = process.env;
  const dispatch = useDispatch();
  const isInit = useRef(false);
  const [isRP, setIsRP] = useState(null);
  const { hash } = useContext(VerifyBusinessContext);
  const history = useHistory();

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      callback: onLoginSuccess,
    });

    google.accounts.id.renderButton(document.getElementById("signInDiv"), {
      theme: "outline",
      size: "large",
      shape: "circle",
    });

    google.accounts.id.prompt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInit.current) isInit.current = true;
  }, [isInit]);

  const onLoginSuccess = (res) => {
    if (isInit.current) {
      fetch(
        `${process.env.REACT_APP_BACKEND_API}/broadcastmessage/tokensignin`,
        {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body:
            "idToken=" +
            res.credential +
            "&clientId=" +
            REACT_APP_GOOGLE_CLIENT_ID,
        }
      ).then((resFromBackend) => {
        if (resFromBackend.status === 200) {
          let profileObj = jwt.decode(res.credential);
          dispatch(
            setUserData({
              idToken: res.credential,
              clientId: REACT_APP_GOOGLE_CLIENT_ID,
            })
          );

          const payload = { email: profileObj.email };
          const secret = REACT_APP_BACKEND_INTERNAL_API_SECRET_TOKEN;
          const emailJwt = jwt.sign(payload, secret);
          document.cookie = `login_user=${emailJwt}; path=/`;

          if (props.redirectTo === "/verifybusiness") {
            history.push(`/verifybusiness/${hash}`);
          } else {
            history.push(props.redirectTo);
          }
        } else {
          setIsRP(false);
        }
      });
    }
  };

  return (
    <div className="google-signin">
      <div className="google-signin-content">
        <img
          src={Logo}
          alt="Pointspot"
          className="google-signin__logo"
        ></img>

        <div className="google-signin__btn">
          {isRP === false && (
            <div className="google-login-unauthorized">
              <div>You are unauthorized!</div>
              <div>Please sign in with @readyplanet email</div>
            </div>
          )}
          <div className="btns">
            <div id="signInDiv"></div>
            {isRP === false && (
              <a
                className="add-new-account-btn"
                href="https://accounts.google.com/AddSession?hl=en&continue=https://google.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
                  <g fill="#000" fillRule="evenodd">
                    <path
                      d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                      fill="#EA4335"
                    ></path>
                    <path
                      d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                      fill="#4285F4"
                    ></path>
                    <path
                      d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                      fill="#FBBC05"
                    ></path>
                    <path
                      d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                      fill="#34A853"
                    ></path>
                    <path fill="none" d="M0 0h18v18H0z"></path>
                  </g>
                </svg>
                Add Google Account
              </a>
            )}
          </div>
        </div>

        <p>การล็อคอินเข้าสู่ระบบนี้ ท่านยอมรับ</p>

        <div className="google-login__links">
          <a
            href="https://th.pointspot.co/17238814/terms-of-service"
            rel="noopener noreferrer"
            target="_blank"
          >
            เงื่อนไขการใช้บริการ
          </a>{" "}
          |{" "}
          <a
            href="https://terms.readyplanet.com/privacy-policy"
            rel="noopener noreferrer"
            target="_blank"
          >
            นโยบายข้อมูลส่วนบุคคล
          </a>
        </div>

        <a
          href="https://th.pointspot.co"
          rel="noopener noreferrer"
          target="_blank"
          className="google-login__link"
        >
          สร้างระบบสะสมแต้มสำหรับธุรกิจ
        </a>
      </div>
    </div>
  );
}

export default App;
