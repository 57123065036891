import styled, { css } from 'styled-components'
import { Title2 } from '../../components/Font'

export const ContainerWizard = styled.div`
  width: 100%;
  height: ${window.innerHeight ? window.innerHeight + 'px' : '100vh'};

  margin: 0px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${props => props.theme.secondary}11;
`

export const WizardBox = styled.div`
  margin: 20px 0px;
  padding: 10px;
  width: 100%;
  max-width: 500px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background-color: #FFFFFF;
  box-shadow: rgb(220, 220, 220) 3px 3px 8px 0px;
`

export const WizardBoxDetail = styled.div`
  width: 100%;
  min-height: 120px;
  max-height: 280px;

  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
`

export const WizardImageLogo = styled.img`
  width: 50vw;
  height: auto;

  min-width: 300px;
  max-width: 450px;
`

export const BusinessAvatar = styled.img`
  max-width: 50px;
  border-radius: 50%;
`

export const BusinessListItem = styled.div`
  padding: 5px 15px;
  font-size: ${props => props.theme.font_s};
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;

  &:hover{
    > * {
      > * {
        color: ${props => props.theme.white};
      }
    }
    background:  ${props => props.theme.primary};
  }
`

export const LogoutSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  padding-top: 10px;
`

export const SendAgainText = styled(Title2)`
  ${props => props.disabled 
    ? css`
        color: ${props.theme.gray};
         cursor: not-allowed;
      `
    : css`
        color: ${ props.theme.blue};
        cursor: pointer;
        &:hover{
          opacity:0.9;
        }
      `
  }
`