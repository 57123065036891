import { BoxProcess, BoxProcessHead, VerifyModalFooter, VerifyModalHeader } from "./verifyBusinessHash.style";
import "react-datepicker/dist/react-datepicker.css";
import { InvertBtn, PrimaryBtn } from "../../components/Button";
import { Modal, ModalBody, Input } from "reactstrap";
import DatePicker from "react-datepicker";
import { enUS, th } from "date-fns/esm/locale";
import React, { useEffect, useRef, useState } from "react";
import { Title2, Title3 } from "../../components/Font";
import {
  cancelPackage,
  getBusinessPackage,
  resetProcessingStatus,
  setTrialPackage,
  updateTrialPackage,
  getCouponInfo,
  updateBusinessCoupon,
} from "../../redux/actions/action_manager";
import { useDispatch, useSelector } from "react-redux";

import ConfirmModal from "./ConfirmModal";
import { DEBUG } from "../../libs/utils";
import Loading from "./Loading";
import { MANAGER } from "../../redux/actions_const";
import _ from "lodash";
import { formatDateTime, formatDateFull2 } from "../../libs/date";
import moment from "moment";

export default function PackageInfo({ business_code, email }) {
  const dispatch = useDispatch();
  const dictionary = useSelector((state) => state.language.dictionary);
  const isLoading = useSelector((state) => state.manager.isLoadingPackageInfo);
  const [expand, setExpand] = useState(true);
  const [isShowModal, setShowModal] = useState(false);
  const [isShowConfirmCancelPackage, setShowConfirmCancelPackage] = useState(false);
  const newSubscriptionId = useSelector((state) => state.manager.newSubscriptionId);
  const currentPackage = useSelector((state) => state.manager.packageInfo);
  const currentSubscription = useSelector((state) => state.manager.subscriptionInfo);
  const isTrial = useSelector((state) => state.manager.isTrial);
  const isFreePackage = _.isEmpty(currentPackage);
  const couponInfo = useSelector((state) => state.manager.couponInfo);
  const [isShowCouponModal, setShowCouponModal] = useState(false);

  useEffect(() => {
    DEBUG("[useEffect] PackageInfo", business_code, newSubscriptionId, currentSubscription)
    if (business_code && newSubscriptionId !== currentSubscription.id) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      dispatch(getBusinessPackage(business_code));
      dispatch(getCouponInfo(business_code));
    }
  }, [business_code, newSubscriptionId, couponInfo, currentSubscription, dispatch])

  const onSubmit = (data) => {
    const { selectedPackage, days } = data;
    DEBUG("onSubmit", selectedPackage, days, business_code, email)
    if (selectedPackage && days && business_code && email) {
      if (isTrial) {
        dispatch(
          updateTrialPackage({
            business_code,
            email,
            package_id: selectedPackage,
            days,
          })
        );
      } else {
        dispatch(
          setTrialPackage({
            business_code,
            email,
            package_id: selectedPackage,
            days,
          })
        );
      }
    }
  };

  const onSubmitCouponModal = (data) => {
    const { selectedCoupon, expirationDate } = data;
    DEBUG("onSubmitCouponModal", selectedCoupon, expirationDate, business_code);
    dispatch(
      updateBusinessCoupon({
        business_code,
        couponId: selectedCoupon,
        expireDate: expirationDate
      })
    );
  };

  const onCancelSubscription = () => {
    DEBUG("onCancelSubscription", business_code);
    if (business_code) {
      dispatch(resetProcessingStatus());
      setShowConfirmCancelPackage(true);
    }
  };

  const onCancelPackageConfirm = () => {
    DEBUG("onCancelPackageConfirm", business_code);
    dispatch(
      cancelPackage({
        business_code,
      })
    );
    setShowConfirmCancelPackage(false);
  };

  const onStartOrEditClicked = () => {
    dispatch(resetProcessingStatus());
    setShowModal(true);
  };

  DEBUG("PackageInfo", business_code, isFreePackage, isTrial, currentPackage, currentSubscription);

  return (
    <BoxProcess>
      <BoxProcessHead className="justify-content-between align-items-center" pointer onClick={() => setExpand(!expand)}>
        <Title2 bold white>
          แพคเกจ
        </Title2>
        {expand ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
      </BoxProcessHead>
      {expand ? (
        isLoading ? (
          <Loading />
        ) : (
          <div style={{ padding: "20px" }}>
            <Title2 bold>แพคเกจปัจจุบัน</Title2>
            <div className="d-flex p-3 justify-content-between align-items-center">
              <Title2>
                {`${dictionary[currentPackage.title_dictionary] || currentPackage.title_dictionary || "Free"} ${
                  currentPackage.pricing
                    ? `(${currentPackage.pricing} ${dictionary[currentPackage.currency || "thb"]}/${
                        dictionary[currentPackage.unit_dictionary] || dictionary.transaction_unit
                      })`
                    : ""
                } ${isTrial ? "(ทดลองใช้)" : ""}`}
              </Title2>
              {isFreePackage || isTrial ? (
                <PrimaryBtn onClick={onStartOrEditClicked}>{isFreePackage ? "เริ่มทดลองใช้งาน" : "แก้ไข"}</PrimaryBtn>
              ) : null}
            </div>
            {isTrial ? (
              <>
                <Title2 bold>วันหมดอายุการทดลองใช้งาน</Title2>
                <Title2 className="p-3">{formatDateTime(currentSubscription.cancel_at * 1000)}</Title2>
              </>
            ) : null}
            {!isFreePackage && !isTrial ? (
              <>
                <Title2 bold>วันที่สมัครแพคเกจ</Title2>
                <Title2 className="p-3">{formatDateTime(currentSubscription.created * 1000)}</Title2>
                <Title2 bold>รอบบิลถัดไป</Title2>
                <Title2 className="p-3">
                  {formatDateTime(moment.unix(currentSubscription.current_period_end).add(1, "day").startOf("month"))}
                </Title2>
              </>
            ) : null}
            <Title2 bold>คูปอง</Title2>
            {!couponInfo.subscribed_coupon ? (
              <PrimaryBtn onClick={() => setShowCouponModal(true)} className="m-3">
                เพิ่มคูปอง
              </PrimaryBtn>
            ) : (
              <div className="d-flex mb-3 align-items-baseline justify-content-between">
                <Title2>ชื่อคูปอง: {dictionary.subscription_coupons[couponInfo.subscribed_coupon.coupon_name]}</Title2>
                <Title2>วันที่เริ่ม: {formatDateFull2(couponInfo.subscribed_coupon.received_date)}</Title2>
                <Title2>วันที่หมดอายุ: {formatDateFull2(couponInfo.subscribed_coupon.expiration_date)}</Title2>
                <PrimaryBtn onClick={() => setShowCouponModal(true)}>แก้ไขคูปอง</PrimaryBtn>
              </div>
            )}
          </div>
        )
      ) : null}
      {isShowModal && (
        <StartTrialModal
          packageInfo={currentPackage}
          subscriptionInfo={currentSubscription}
          isOpen={isShowModal}
          toggle={() => setShowModal(!isShowModal)}
          onSubmit={onSubmit}
          onCancelSubscription={onCancelSubscription}
        />
      )}
      {isShowCouponModal && (
        <CouponModal
          couponInfo={couponInfo}
          isOpen={isShowCouponModal}
          toggle={() => setShowCouponModal(!isShowCouponModal)}
          onSubmit={onSubmitCouponModal}
          dictionary={dictionary}
        />
      )}
      {isShowConfirmCancelPackage && (
        <ConfirmModal
          isOpen={isShowConfirmCancelPackage}
          toggle={() => setShowConfirmCancelPackage(!isShowConfirmCancelPackage)}
          text="ยืนยันการยกเลิกการทดลองใช้"
          onConfirm={onCancelPackageConfirm}
        />
      )}
    </BoxProcess>
  );
}

const CouponModal = ({ couponInfo, isOpen, onSubmit, toggle, dictionary }) => {
  const [selectedCoupon, setSelectedCoupon] = useState(
    couponInfo.subscribed_coupon ? couponInfo.subscribed_coupon.coupon_id : 1
  );
  const [expireDateCoupon, setExpireDateCoupon] = useState(
    couponInfo.subscribed_coupon ? couponInfo.subscribed_coupon.expiration_date : moment().endOf('day')
  );

  const onSubmitClick = () => {
    onSubmit({ selectedCoupon, expirationDate: expireDateCoupon });
    toggle();
  };

  const handleChangeSelect = (value) => {
    setSelectedCoupon(value);
  };

  const handleChangeDate = (value) => {
    setExpireDateCoupon(moment(value).endOf('day'));
  };

  return (
    <Modal isOpen={isOpen} centered={true}>
      <VerifyModalHeader>
        <Title2 white bold>
          {!couponInfo.subscribed_coupon ? "เพิ่มคูปอง" : "แก้ไขคูปอง"}
        </Title2>
      </VerifyModalHeader>
      <ModalBody>
        <Title2 bold className="mb-2">
          เลือกคูปอง
        </Title2>
        <Input
          type="select"
          name="selectCoupon"
          value={selectedCoupon}
          onChange={(event) => handleChangeSelect(event.target.value)}
        >
          {couponInfo.coupon_info.map((coupon, key) => {
            return <option label={`${dictionary.subscription_coupons[coupon.coupon_name]}, discount ${coupon.discount_amount ? (coupon.discount_amount) : (coupon.discount_percent + "%")}, duration ${coupon.duration_type === "forever" ? "forever" : (coupon.duration + " month(s)")}` } 
            value={coupon.id} key={key} />;
          })}
        </Input>
        <Title2 bold className="mb-2 mt-2">
          เลือกวันหมดอายุ
        </Title2>
        <DatePicker
          name="coupon_expire_date"
          fixedHeight
          selectsStart
          showYearDropdown
          showMonthDropdown
          className="w-100 form-control"
          locale={dictionary.locale === "en" ? enUS : th}
          dateFormat="dd MMM yyyy"
          popperPlacement="top-start"
          wrapperClassName="w-100"
          minDate={new Date()}
          selected={new Date(expireDateCoupon)}
          onChange={(event) => handleChangeDate(event)}
        />
      </ModalBody>
      <VerifyModalFooter>
        <InvertBtn onClick={toggle}>ยกเลิก</InvertBtn>
        <PrimaryBtn className="ml-2" onClick={() => onSubmitClick()}>
          ยืนยัน
        </PrimaryBtn>
      </VerifyModalFooter>
    </Modal>
  );
};

const StartTrialModal = ({ packageInfo, subscriptionInfo, isOpen, onSubmit, toggle, onCancelSubscription }) => {
  const isInited = useRef(false);
  const processingStatus = useSelector((state) => state.manager.processingStatus);
  const subscribedData = {};
  const availabelPackages = [
    {
      id: "ps-premium",
      title: "Premium",
    },
    {
      id: "ps-gold",
      title: "Gold",
    },
  ];

  useEffect(() => {
    DEBUG("[useEffect] StartTrialModal", processingStatus, isOpen);
    if (!isInited.current) {
      isInited.current = true;
    } else {
      if (isOpen && processingStatus.status === MANAGER.PROCESS_STATUS_SUCCEED) {
        toggle();
      }
    }
  }, [processingStatus, isOpen, isInited, toggle]);

  if (!_.isEmpty(subscriptionInfo)) {
    const startDate = moment(subscriptionInfo.created * 1000).seconds(0);
    const endDate = moment(subscriptionInfo.cancel_at * 1000).seconds(0);
    subscribedData.days = endDate.diff(startDate, "days");
    DEBUG("[StartTrialModal] diff", startDate, endDate, subscribedData.days);
    subscribedData.endDate = endDate;
  }

  if (!_.isEmpty(packageInfo)) {
    subscribedData.package = packageInfo.package_id;
  }

  const [selectedPackage, setSelectedPackage] = useState(subscribedData.package || availabelPackages[0].id);
  const [days, setDays] = useState(subscribedData.days || "");

  const isProcessing = processingStatus.status === MANAGER.PROCESS_STATUS_WORKING;
  const isEditing =
    _.isEmpty(subscriptionInfo) || subscribedData.days !== days || subscribedData.package !== selectedPackage;
  const date =
    !isEditing && subscribedData.endDate
      ? subscribedData.endDate
      : moment().add(days, "days").hours(23).minutes(59).seconds(59);

  DEBUG("[StartTrialModal]", isEditing, subscribedData, date, days);

  const onTextChange = (event) => {
    const { value } = event.target;
    if (!value || (!isNaN(value) && parseInt(value) >= 0 && parseInt(value) <= 90)) {
      setDays(parseInt(value));
    }
  };

  const onSubmitClick = () => {
    onSubmit({
      selectedPackage,
      days,
    });
  };

  return (
    <Modal isOpen={isOpen} centered={true}>
      <VerifyModalHeader>
        <Title2 white bold>
          {_.isEmpty(subscriptionInfo) ? "ตั้งค่าการทดลองใช้งาน" : "แก้ไขการทดลองใช้งาน"}
        </Title2>
      </VerifyModalHeader>
      <ModalBody>
        <Title2 bold className="mb-2">
          แพคเกจทดลองใช้งาน
        </Title2>
        <div className="d-flex align-items-center mb-3 pl-3">
          {availabelPackages.map((item, index) => (
            <div key={index} className="d-flex align-items-center pr-4">
              <input
                className="pointer"
                type="radio"
                name={item.id}
                onChange={() => setSelectedPackage(item.id)}
                checked={selectedPackage === item.id}
              />
              <Title2 className="pl-2 pointer" onClick={() => setSelectedPackage(item.id)}>
                {item.title}
              </Title2>
            </div>
          ))}
        </div>
        <Title2 bold className="mb-2">
          จำนวนวัน
        </Title2>
        <div className="d-flex align-items-center ml-3">
          <input
            className="form-control mr-2"
            type="number"
            name="days"
            value={days}
            placeholder="1"
            min={0}
            max={90}
            onChange={onTextChange}
            style={{ width: "80px" }}
          />
          <Title2>วัน</Title2>
        </div>
        <Title3 className="mb-3 ml-3 mt-1" secondary>
          * ระบุจำนวนวันได้สูงสุด 90 วัน
        </Title3>
        <Title2>{`วันหมดอายุ การทดลองใช้งาน : ${formatDateTime(date.valueOf())}`}</Title2>
      </ModalBody>
      <VerifyModalFooter>
        {!_.isEmpty(subscriptionInfo) && (
          <InvertBtn onClick={onCancelSubscription} disabled={isProcessing}>
            <Title2 danger>ยกเลิกการทดลองใช้</Title2>
          </InvertBtn>
        )}
        <div className="d-flex flex-grow-1 justify-content-end">
          <InvertBtn onClick={toggle} disabled={isProcessing}>
            ยกเลิก
          </InvertBtn>
          <PrimaryBtn className="ml-2" onClick={onSubmitClick} disabled={isProcessing || !isEditing || !days}>
            {isProcessing ? <i className="fas fa-spinner fa-pulse" /> : "ยืนยัน"}
          </PrimaryBtn>
        </div>
      </VerifyModalFooter>
    </Modal>
  );
};
