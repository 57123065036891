import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Loading from '../Loading'
import { PrimaryBtn } from '../Button'

class index extends Component {
  render() {
    let { store_language: { dictionary } } = this.props
    return (
      <div className='w-100'>
        {this.props.children}
        {
          this.props.loadMoreCheck
            ? <div className='d-flex justify-content-center align-items-center w-100' style={{ minHeight: 60 }}>
              {
                this.props.isLoading
                  ? <Loading margin={'0px'} padding={'0px'} size={'1.8rem'} />
                  : <PrimaryBtn md invert bold id={`btn-loadmore`} onClick={this.props.actionLoadMore}>
                    {dictionary.show_more}
                  </PrimaryBtn>
              }
            </div>
            : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
    store_business: state.business,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(index)