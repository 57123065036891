import React, { useEffect } from "react";

export default function ScrollToTop() {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const element = document.getElementById("scroll-to-me");
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }, 30);

    return () => clearTimeout(timeoutId);
  }, []);

  return <div id="scroll-to-me" style={{ position: 'absolute', top: 0, left: 0, right: 0 }}></div>;
}