import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import moment from 'moment'
import { Container, Card, CardHeader, CardBody } from 'reactstrap'

import LoadingBox from './../Loading'
import { Title2, Title3 } from './../Font'

import BusinessReviewChart from './BusinessReviewChart'
import BusinessReviewMessage from './BusinessReviewMessage'
import { getBusinessReview, clearBusinessReview } from './../../redux/actions/action_business'

class BusinessReview extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLoadingComment: false,
      isLoadingLoadMore: false,
      review_star: 'all',
      review_message: true,
      start_date: null,
      end_date: moment(new Date()).endOf('day').format('YYYY-MM-DD 23:59:59'),
      date_range: 'all_time'
    }

    this.onClickLoadMore = this.onClickLoadMore.bind(this)
    this.onClickSortReviews = this.onClickSortReviews.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    let { store_business } = this.props
    let { store_business: { current: { business_code }, reviews } } = this.props

    let dataParams = {
      review_star: this.state.review_star,
      review_message: this.state.review_message,
      date_range: this.state.date_range,
      start_date: moment(store_business.current.create_date).startOf('days').format('YYYY-MM-DD 00:00:00'),
      end_date: this.state.end_date
    }

    let dataReview = Object.assign({}, { business_code: business_code, limit: reviews.limit, offset: reviews.offset }, dataParams)
    this.setState(Object.assign({}, { isLoading: true, isLoadingComment: true, }, dataParams), () => {
      this.props.getBusinessReview(dataReview, () => {
        if (this._isMounted)
          this.setState({ isLoading: false, isLoadingComment: false })
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
    this.props.clearBusinessReview()
    if (window.toggleModalMember)
      window.toggleModalMember()
  }

  onClickLoadMore() {
    this._isMounted = true
    let { store_business: { current: { business_code }, reviews, isProcess } } = this.props

    let dataReview = {
      business_code: business_code,
      limit: reviews.limit,
      offset: reviews.offset + reviews.limit,
      review_star: this.state.review_star,
      review_message: this.state.review_message,
      date_range: this.state.date_range,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      type: 'load_more'
    }

    if (reviews.loadmore && !this.state.isLoadingComment && !isProcess && !this.state.isLoadingLoadMore) {
      this.setState({ isLoadingLoadMore: true })
      this.props.getBusinessReview(dataReview, () => {
        if (this._isMounted)
          this.setState({ isLoadingLoadMore: false })
      })
    }
  }

  onClickSortReviews(review_star, review_message, dateRange) {
    this._isMounted = true
    let { store_business: { current: { business_code }, reviews } } = this.props

    let dataReview = {
      business_code: business_code,
      limit: reviews.limit,
      offset: 0,
      review_star: review_star,
      review_message: review_message,
      date_range: this.state.date_range,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
    }

    if (dateRange) {
      dataReview = Object.assign({}, dataReview, {
        date_range: dateRange.type,
        start_date: dateRange.start_date,
        end_date: dateRange.end_date
      })
    }

    if (this.state.review_star !== review_star || this.state.review_message !== review_message || this.state.date_range !== dateRange) {
      this.setState(Object.assign({}, { isLoadingComment: true }, dataReview))
      this.props.getBusinessReview(dataReview, (err, data) => {
        if (this._isMounted)
          this.setState({ isLoadingComment: false })
      })
    }
  }

  render() {
    let { store_language: { dictionary } } = this.props
    let { store_business: { current, reviews } } = this.props
    let { store_permission } = this.props

    return <>
      <Container className='pt-3'>
        {
          this.state.isLoading
            ? <LoadingBox />
            : <>
              <Card>
                <CardHeader>
                  <Title2 bold>{dictionary.satisfaction_summary}</Title2>
                  <Title3 secondary>{dictionary.satisfaction_summary_in_business} : {current.business_name}</Title3>
                </CardHeader>
                <CardBody>
                  <BusinessReviewChart
                    {...this.state}
                    reviews={reviews}
                    dictionary={dictionary} />
                </CardBody>
              </Card>
              <BusinessReviewMessage
                {...this.state}
                reviews={reviews}
                dictionary={dictionary}
                business={current}
                profile={store_permission.profile}
                onClickSortReviews={this.onClickSortReviews}
                onClickLoadMore={() => this.onClickLoadMore()}
                isLoadingLoadMore={this.state.isLoadingLoadMore} />
            </>
        }
      </Container>
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language,
    store_permission: state.permission
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getBusinessReview,
    clearBusinessReview,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessReview))