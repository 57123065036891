import styled, { css } from 'styled-components';

export const TagLabel = styled.div`
  ${(props) =>
    props.noWidth
      ? css``
      : css`
          width: ${(props) => (props.formLabel ? 'fit-content' : '100%')};
          max-width: 240px;
          min-width: ${(props) => (props.formLabel ? '60' : '100')}px;
        `}
  min-height: ${(props) => !props.formLabel && '36px'};
  padding: ${(props) => (props.formLabel ? '4px 10px' : '0.5rem 1rem')};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.formLabel ? 'center' : 'flex-start')};
  color: ${(props) =>
    props.disabled
      ? props.theme.secondary
      : props.white
      ? props.theme.white
      : props.theme.primary};
  font-size: ${(props) => (props.formLabel ? '10' : '12')}px;
  font-weight: 600;
  background-color: ${(props) => (props.disabled ? '#eee' : props.color)};
  border: solid ${(props) => (props.disabled ? '#eee' : props.borderColor)};
  border-width: ${(props) => (props.disabled ? '1px' : props.borderWidth)};
  border-radius: 20px;
  // box-shadow: 0px 3px 15px 0px #dcdcdc;
  cursor: ${(props) =>
    props.disabled ? 'not-allowed' : props.pointer ? 'pointer' : ''};
  word-break: break-word;
  #label-content #label-content-name {
    display: flex;
    align-items: center;
  }
  #label-content #label-content-hover {
    display: none;
  }
  :hover {
    opacity: 0.8;
    ${(props) =>
      props.hoverContent && !props.disabled
        ? css`
            #label-content #label-content-name {
              display: none;
            }
            #label-content #label-content-hover {
              display: block;
            }
          `
        : css``}
  }
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
  color: ${(props) => props.color};
  ${(props) =>
    props.minWidth
      ? css`
          min-width: ${props.minWidth}px;
        `
      : css``}
  ${(props) =>
    props.minHeight
      ? css`
          min-height: ${props.minHeight}px;
        `
      : css``}
`;

export const AbsoluteBox = styled.div`
  position: absolute;
  ${(props) =>
    props.position === 'bottom'
      ? css`
          top: 30px;
        `
      : css`
          bottom: 30px;
        `};
  left: 0;
  z-index: 100;
  width: 260px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;
`;

export const LabelContainer = styled.div`
  width: 100%;
  height: 125px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
`;

export const LabelContainerModal = styled.div`
  width: 100%;
  min-height: 220px;
  max-height: 220px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
`;

export const Labelbox = styled.div`
  width: 100%;
  margin-bottom: 10px;
  :nth-last-child(1) {
    margin-bottom: 0px;
  }
`;

export const IconLabelSearch = styled.i`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  padding: 0.75rem;
  z-index: 1;
  color: ${(props) => props.theme.primary};
`;

export const LabelBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  overflow: scroll hidden;
  border-bottom: 1px solid ${(props) => props.theme.border_line};

  &::-webkit-scrollbar {
    height: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.primary};
  }
`;

export const LabelCircle = styled.div`
  width: ${(props) => (props.size ? props.size : '15px')};
  height: ${(props) => (props.size ? props.size : '15px')};
  min-width: ${(props) => (props.size ? props.size : '15px')};
  min-height: ${(props) => (props.size ? props.size : '15px')};
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 0px 2px;
  cursor: pointer;
`;

export const LabelAddButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  cursor: pointer;
`;

export const NumberCircle = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(84, 97, 116, 0.1);
  border: 1px solid #546174;
  font-size: 10px;
  font-weight: 600;
  color: #546174;
  :hover {
    opacity: 0.8;
  }
`;
