import axios from '../axios_request';
import { CancelToken, isCancel } from 'axios';
import { businessAPI } from '../end_point';

import { ERROR } from '../../libs/utils';

import { BROADCAST } from '../actions_const';
import { getCookie } from '../../libs/cookies';
import { uploadPhoto } from '../../libs/Firebase';

let sourceToken;

export const createShortener = ({ business_code, title, destination, tag }, callback) => {
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		axios({
			method: 'post',
			url: `${businessAPI}/${business_code}/shortener`,
			headers: { Authorization: getCookie('_token') },
			data: { title, destination, tag },
		})
			.then(({ data }) => {
				return isFunc && callback(null, data);
			})
			.catch((err) => {
				ERROR(`createShortener ${err}`);
				return isFunc && callback(err);
			});
	};
};

export const getBroadcastInfo = ({ business_code, limit = null, offset = null, type }, callback) => {
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/broadcast?limit=${limit}&offset=${offset}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				isFunc && callback(null, data);
				if (type === 'load_more') return dispatch({ type: BROADCAST.GET_BROADCAST_INFO_MORE, data: data });
				else return dispatch({ type: BROADCAST.GET_BROADCAST_INFO, data: data });
			})
			.catch((err) => {
				ERROR(`getBroadcastInfo ${err}`);
				isFunc && callback(err);
				return dispatch({ type: BROADCAST.UPDATE_BROADCAST_INFO });
			});
	};
};

export const createBroadcastInfo = ({ business_code, message, type, image, title, link, button_label }, callback) => {
	let isFunc = typeof callback === 'function';
	let imgPath = `${business_code}/broadcastLine/${new Date().getTime()}.jpeg`;

	return (dispatch) => {
		uploadPhoto(imgPath, image)
			.then((img) => {
				return axios({
					method: 'post',
					url: `${businessAPI}/${business_code}/broadcast`,
					headers: { Authorization: getCookie('_token') },
					data: { message, type, image: img.url, title, link, button_label },
				});
			})
			.then(({ data }) => {
				isFunc && callback(null, data);
				return dispatch({ type: BROADCAST.CREATE_BROADCAST_INFO });
			})
			.catch((err) => {
				ERROR(`createBroadcastInfo ${err}`);
				isFunc && callback(err);
				return dispatch({ type: BROADCAST.CREATE_BROADCAST_INFO });
			});
	};
};

export const updateBroadcastInfo = (
	{ business_code, broadcast_info_id, message, image, title, link, button_label },
	callback
) => {
	let isFunc = typeof callback === 'function';
	let imgPath = `${business_code}/broadcastLine/${new Date().getTime()}.jpeg`;

	return (dispatch) => {
		uploadPhoto(imgPath, image)
			.then((img) => {
				return axios({
					method: 'put',
					url: `${businessAPI}/${business_code}/broadcast`,
					headers: { Authorization: getCookie('_token') },
					data: { broadcast_info_id, message, image: img.url || image, title, link, button_label },
				});
			})
			.then(({ data }) => {
				isFunc && callback(null, data);
				return dispatch({ type: BROADCAST.CREATE_BROADCAST_INFO });
			})
			.catch((err) => {
				ERROR(`updateBroadcastInfo ${err}`);
				isFunc && callback(err);
				return dispatch({ type: BROADCAST.CREATE_BROADCAST_INFO });
			});
	};
};

export const archiveBroadcastInfo = ({ business_code, broadcast_info_id }, callback) => {
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		axios({
			method: 'delete',
			url: `${businessAPI}/${business_code}/broadcast`,
			headers: { Authorization: getCookie('_token') },
			data: { broadcast_info_id },
		})
			.then(({ data }) => {
				isFunc && callback(null, data);
				return dispatch({ type: BROADCAST.CREATE_BROADCAST_INFO });
			})
			.catch((err) => {
				ERROR(`createBroadcastInfo ${err}`);
				isFunc && callback(err);
				return dispatch({ type: BROADCAST.CREATE_BROADCAST_INFO });
			});
	};
};

export const clearBroadcast = () => {
	return (dispatch) => {
		return dispatch({ type: BROADCAST.CLEAR_BROADCAST });
	};
};

export const getBroadcastPromotion = ({ business_code, limit = null, offset = null, type }, callback) => {
	let isFunc = typeof callback === 'function';
	return (dispatch) => {
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/broadcast/promotion?limit=${limit}&offset=${offset}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				isFunc && callback(null, data);
				if (type === 'load_more') return dispatch({ type: BROADCAST.GET_BROADCAST_PROMOTION_MORE, data: data });
				else return dispatch({ type: BROADCAST.GET_BROADCAST_PROMOTION, data: data });
			})
			.catch((err) => {
				ERROR(`getBroadcastPromotion ${err}`);
				isFunc && callback(err);
				return dispatch({ type: BROADCAST.UPDATE_BROADCAST_PROMOTION });
			});
	};
};

export const createBroadcastPromotion = (
	{ business_code, target_type, target_value, broadcast_info_id, broadcast_option, broadcast_total, estimated_credit },
	callback
) => {
	let isFunc = typeof callback === 'function';

	return (dispatch) => {
		axios({
			method: 'post',
			url: `${businessAPI}/${business_code}/broadcast/promotion`,
			headers: { Authorization: getCookie('_token') },
			data: { target_type, target_value, broadcast_info_id, broadcast_option, broadcast_total, estimated_credit },
		})
			.then(({ data }) => {
				isFunc && callback(null, data);
				return dispatch({ type: BROADCAST.UPDATE_BROADCAST_PROMOTION });
			})
			.catch((err) => {
				ERROR(`getBroadcastPromotion ${err}`);
				isFunc && callback(err);
				return dispatch({ type: BROADCAST.UPDATE_BROADCAST_PROMOTION });
			});
	};
};

export const getTargetAllMemberTotal = (business_code, callback) => {
	return (dispatch) => {
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		axios({
			method: 'get',
			url: businessAPI + '/' + business_code + `/broadcast/targetTotal/member`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data);
				dispatch(
					setTargetMemberTotal({
						target: 'all',
						...data.data,
					})
				);
			})
			.catch((err) => {
				ERROR('get', 'member', 'Member Count axios catch', err);
				if (typeof callback === 'function') callback(err);
				dispatch({ type: BROADCAST.GET_TARGET_MEMBER_TOTAL, data: { target: 'all', total: 0 } });
			});
	};
};

export const getTargetPreMemberTotal = (business_code, callback) => {
	return (dispatch) => {
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/preMember`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data);
				dispatch(
					setTargetMemberTotal({
						target: 'member_pending_group',
						target_data: 0,
						...data.data,
					})
				);
			})
			.catch((err) => {
				ERROR('get', 'member', 'Member Count axios catch', err);
				if (typeof callback === 'function') callback(err);
				dispatch({ type: BROADCAST.GET_TARGET_MEMBER_TOTAL, data: { target: 'member_pending_group', total: 0 } });
			});
	};
};

export const getTargetMemberCardTotal = (business_code, cardId, callback) => {
	return (dispatch) => {
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/memberCard?card_id=${cardId}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data);
				dispatch(
					setTargetMemberTotal({
						target: 'member_card',
						target_data: cardId,
						...data.data,
					})
				);
			})
			.catch((err) => {
				ERROR('get', 'member', 'Member Count axios catch', err);
				if (typeof callback === 'function') callback(err);
				dispatch({ type: BROADCAST.GET_TARGET_MEMBER_TOTAL, data: { target: 'member_card', total: 0 } });
			});
	};
};

export const getTargetRFM = (business_code, { range, start_date, end_date }, callback) => {
	return (dispatch) => {
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/rfm?start_date=${start_date}&end_date=${end_date}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data);
				dispatch({
					type: BROADCAST.GET_TARGET_RFM,
					data: {
						range: range,
						segment_group: [...data.data],
					},
				});
			})
			.catch((err) => {
				ERROR('getTargetRFM axios catch', err);
				if (typeof callback === 'function') callback(err);
				dispatch({ type: BROADCAST.GET_TARGET_RFM, data: null });
			});
	};
};

export const getTargetMemberBirthMonthTotal = (business_code, month = 1, callback) => {
	return (dispatch) => {
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/birthMonth?month=${month}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data);
				dispatch(
					setTargetMemberTotal({
						target: 'birthmonth',
						target_data: month,
						...data.data,
					})
				);
			})
			.catch((err) => {
				ERROR('getMemberCounterBirthday catch', err.message);
				if (typeof callback === 'function') callback(err);
				dispatch({ type: BROADCAST.GET_TARGET_MEMBER_TOTAL, data: { target: 'birthmonth', total: 0 } });
			});
	};
};

export const getTargetMemberPurchaseProductTotal = (
	{ business_code, condition, type, products = [], start_date = null, end_date = null },
	callback
) => {
	return (dispatch) => {
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		axios({
			method: 'post',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/purchasedProduct`,
			headers: { Authorization: getCookie('_token') },
			data: {
				condition: condition,
				products: products,
				start_date: start_date,
				end_date: end_date,
			},
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data);
				dispatch(
					setTargetMemberTotal({
						target: 'member_purchase_product',
						target_data: {
							condition: condition,
							type: type,
							products: products,
							start_date: start_date,
							end_date: end_date,
						},
						...data.data,
					})
				);
			})
			.catch((err) => {
				if (typeof callback === 'function') callback(err);
				dispatch({ type: BROADCAST.GET_TARGET_MEMBER_TOTAL, data: { target: 'member_purchase_product', total: 0 } });
				ERROR(`getMemberRangePoint ${err}`);
			});
	};
};

export const getTargetMemberRewardTotal = (business_code, ecard_id, callback) => {
	return (dispatch) => {
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/reward?ecard_id=${ecard_id}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data.data);
				dispatch(
					setTargetMemberTotal({
						target: 'member_receive_reward',
						target_data: {
							ecard_id: ecard_id,
						},
						...data.data,
					})
				);
			})
			.catch((err) => {
				if (typeof callback === 'function') callback(err);
				dispatch({ type: BROADCAST.GET_TARGET_MEMBER_TOTAL, data: { target: 'member_receive_reward', total: 0 } });
				ERROR(`getTargetMemberRewardTotal ${err}`);
			});
	};
};

export const getTargetMemberLabelTotal = ({ business_code, labels = [] }, callback) => {
	return (dispatch) => {
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		axios({
			method: 'get',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/memberLabel?labels=${labels}`,
			headers: { Authorization: getCookie('_token') },
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data.data);
				dispatch(
					setTargetMemberTotal({
						target: 'member_label',
						target_data: labels,
						...data.data,
					})
				);
			})
			.catch((err) => {
				if (typeof callback === 'function') callback(err);
				dispatch({ type: BROADCAST.GET_TARGET_MEMBER_TOTAL, data: { target: 'member_label', total: 0 } });
				ERROR(`getTargetMemberRewardTotal ${err}`);
			});
	};
};

export const getTargetSpecificMemberTotal = ({ business_code, members = [], value = [] }, callback) => {
	return (dispatch) => {
		if (sourceToken) sourceToken.cancel('Cancelled request getTargetSpecificMemberTotal');
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		sourceToken = CancelToken.source();

		axios({
			method: 'post',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/specificMember`,
			headers: { Authorization: getCookie('_token') },
			data: {
				members: members,
			},
			cancelToken: sourceToken.token,
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data);
				dispatch(
					setTargetMemberTotal({
						target: 'member',
						target_data: value.map((m) => m.isValid && parseInt(m.member_badge_code)).filter((f) => f).join(',') || null,
						...data.data,
					})
				);
			})
			.catch((err) => {
				if (!isCancel(err)) {
					ERROR(`specificMemberTotal ${err}`);
					if (typeof callback === 'function') callback(err);
					dispatch({
						type: BROADCAST.GET_TARGET_MEMBER_TOTAL,
						data: { target: 'member', total: 0 },
					});
				}
			});
	};
};

export const getTargetMemberPointTotal = ({ business_code, point_flow, point_start, point_end }, callback) => {
	return (dispatch) => {
		if (sourceToken) sourceToken.cancel('Cancelled request getTargetMemberPointTotal');
		dispatch({ type: BROADCAST.TARGET_LOADING, data: { loading: true } });
		sourceToken = CancelToken.source();

		axios({
			method: 'post',
			url: `${businessAPI}/${business_code}/broadcast/targetTotal/memberPoint`,
			headers: { Authorization: getCookie('_token') },
			data: {
				point_flow: point_flow,
				point_start,
				point_end,
			},
			cancelToken: sourceToken.token,
		})
			.then(({ data }) => {
				if (typeof callback === 'function') callback(null, data);
				dispatch(
					setTargetMemberTotal({
						target: 'member_points',
						target_data: {
							point_flow: point_flow,
							point_start: point_start,
							point_end: point_end,
						},
						...data.data,
					})
				);
			})
			.catch((err) => {
				if (!isCancel(err)) {
					ERROR('get', 'member', 'Member Count axios catch', err);
					if (typeof callback === 'function') callback(err);
				}
				dispatch({ type: BROADCAST.GET_TARGET_MEMBER_TOTAL, data: { target: 'member_points', total: 0 } });
			});
	};
};

export const initBroadcastOption = () => {
	return (dispatch) => {
		return dispatch({
			type: BROADCAST.INIT_BROADCAST_OPTION,
		});
	};
};

export const initBroadcastTarget = (target) => {
	return (dispatch) => {
		return dispatch({
			type: BROADCAST.INIT_BROADCAST_TARGET,
			data: {
				target: target,
			},
		});
	};
};

export const setBroadcastOption = (data) => {
	return (dispatch) => {
		return dispatch({
			type: BROADCAST.SET_BROADCAST_OPTION,
			data: {
				...data,
			},
		});
	};
};

export const setTargetMemberTotal = (data) => {
	if (sourceToken) sourceToken.cancel('Cancelled request');
	return (dispatch) => {
		return dispatch({
			type: BROADCAST.GET_TARGET_MEMBER_TOTAL,
			data: {
				target: data.target || '',
				target_data: data.target_data || null,
				total: data.total || 0,
				line_total: data.line_total || 0,
				shappy_total: data.shappy_total || 0,
				inter_total: data.inter_total || 0,
				billing: {
					sms: data.billing_sms || 0,
					line: data.billing_line || 0,
					shappy: data.billing_shappy || 0,
					email: data.billing_email || 0,
				},
			},
		});
	};
};

export const updateBroadcastTarget = (data) => {
	return (dispatch) => {
		return dispatch({
			type: BROADCAST.UPDATE_TARGET_VALUE,
			data,
		});
	}
}