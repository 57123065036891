import { combineReducers } from 'redux'

import config from "./reducers/reducer_config";
import permission from './reducers/reducer_permission'
import business from './reducers/reducer_business'
import sidebar from './reducers/reducer_sidebar'
import transactions from './reducers/reducer_transactions'
import transfers from './reducers/reducer_transfers'
import rewards from './reducers/reducer_rewards'
import language from './reducers/reducer_language'
import wizard from './reducers/reducer_wizard'
import members from "./reducers/reducer_member";
import modal from "./reducers/reducer_modal";
import admin from "./reducers/reducer_admin";
import coupon from "./reducers/reducer_coupon";
import report from "./reducers/reducer_report";
import jobs from "./reducers/reducer_jobs";
import ecoupon from './reducers/reducer_ecoupon'
import message from './reducers/reducer_message'
import subscribe from './reducers/reducer_subscribe'
import packages from './reducers/reducer_package'
import payment from './reducers/reducer_payment'
import credential from './reducers/reducer_credential'
import usage from './reducers/reducer_usage'
import verifyotp from './reducers/reducer_verify_otp'
import tutorial from './reducers/reducer_tutorial'
import master_data from './reducers/reducer_master_data'
import newsfeed from './reducers/reducer_newsfeed'
import points_setting from './reducers/reducer_points_setting'
import broadcast from './reducers/reducer_broadcast'
import products from './reducers/reducer_product'
import condition from './reducers/reducer_condition'
import manager from './reducers/reducer_manager'
import automation from './reducers/reducer_automation'
import points from './reducers/reducer_points'
import totp from './reducers/reducer_totp'
import rfm from './reducers/reducer_rfm'
import custom_form from './reducers/reducer_custom_form';


const Reducers = combineReducers({
  config,
  permission,
  business,
  sidebar,
  transactions,
  rewards,
  language,
  wizard,
  members,
  modal,
  admin,
  coupon,
  report,
  transfers,
  jobs,
  ecoupon,
  message,
  subscribe,
  credential,
  packages,
  payment,
  usage,
  verifyotp,
  tutorial,
  master_data,
  newsfeed,
  points_setting,
  broadcast,
  products,
  condition,
  manager,
  automation,
  points,
  totp,
  rfm,
  custom_form
})

export default Reducers;