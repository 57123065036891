import { CardHeader } from 'reactstrap'
import styled, { css, keyframes } from 'styled-components'

export const CardHeaderContainer = styled(CardHeader)`
  ${props => props.onClick ? css`cursor: pointer;` : ``}

  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardHeaderFilterContainer = styled(CardHeader)`
  background-color: #FFFFFF !important;
`

export const FilterBox = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction column;

  width: 100%;
  max-width: 280px;

  margin-bottom: 0.5rem;
`

const rotateDown = keyframes`
  from { transform:  rotate(0deg); }
    to { transform:  rotate(180deg); }
`

const rotateUp = keyframes`
  from { transform:  rotate(180deg); }
    to { transform:  rotate(0deg); }
`

export const IconChevron = styled.div`
  animation: ${props => props.toggle ? rotateUp : rotateDown} 0.2s linear;
  animation-fill-mode: forwards;
`

export const IconInfo = styled.i`
  color: #a7a7b5;
  font-size: ${props=>props.theme.font_m};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_s};
  }
`