import React, { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'


class LoadingGradient extends Component {
  render() {
    let { nm, type, width, minWidth, className } = this.props

    if (type === 'square')
      return <BoxLoading className={className} nm={nm} width={width} height={width} />
    if (type === 'circle')
      return <BoxLoading circle className={className} nm={nm} width={width} height={width} /> 

    return <BoxLoading className={className} nm={nm} width={width} minWidth={minWidth} height={'5px'} />
  }
}

const placeHolderShimmer = keyframes`
    0% { background-position: -468px 0; }
  100% { background-position: 468px 0; }
`

const BoxLoading = styled.div`
  background: #CCC;
  border-radius: ${ props => props.circle ? '50% 50%' : '5px'};
  
  width: 100%;
  height: 100%;
  width: ${props => props.width};
  height: ${props => props.height};
  
  ${props => props.minWidth && css`min-width: ${props.minWidth};`}

  margin: 6px 0px;
  ${props => props.nm && css`margin: 0px;`}
  
  @media (max-width: 990px) {
    margin: 4px 0px;
    ${props => props.nm && css`margin: 0px;`}
  }

  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: ${placeHolderShimmer};
  animation-timing-function: linear;

  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, ${props => props.theme.primary}10 38%, ${props => props.theme.primary}24 40%);
  background-size: 1000px 640px;
`

export default LoadingGradient