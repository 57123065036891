import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Input } from 'reactstrap'
import { PrimaryBtn } from '../../components/Button'
import { Title3 } from '../../components/Font'
import { IconSearch, ProductListBox, SearchItem, TempItem, TempProductList } from './SendPoint.style'
import Loading from '../../components/Loading'

/**
 * This component for ProductPoints's component
 * @property - There are require so many properties following by below
 * productList, productListWithSearch, categoryList, temp, search, disabled, onChangeSearch,
 * toggleDropDownProduct, keyPressSearch, clearSearch, removeTempItem, selectTemp, selectProduct,
 * isDropDown
 */
export default function ProductList({ 
	productList, 
	productListWithSearch, 
	categoryList, 
	temp, 
	search, 
	disabled,
	onChangeSearch,
	toggleDropDownProduct,
	keyPressSearch,
	clearSearch,
	removeTempItem,
	selectTemp,
	selectProduct,
	isDropDown,
	limit,
	isLoading
}) {
	const { dictionary } = useSelector((state) => state.language)
	const [isDisabled, setDisabled] = useState(disabled || false)

	useEffect(() => {
		if (limit && limit !== '') {
			setDisabled(limit === temp.length)
		}
	}, [temp, limit])

	if (isLoading) {
		return <Loading />
	}

	return (
		<>
		<div className='border-bottom d-flex align-items-center justify-content-between' style={{ padding: '0.75rem' }}>
				<Title3 bold>
					{dictionary.select_product}
					{limit && ` ${temp.length}/${limit}`}
				</Title3>
			<Title3 bold className='pl-2 pointer' onClick={toggleDropDownProduct}><i className="fas fa-times fa-lg" /></Title3>
		</div>
		<div className='p-1 position-relative border-bottom'>
			<IconSearch className={`${Boolean(search) ? 'fas fa-times pointer' : 'fas fa-search'}`} onClick={Boolean(search) ? clearSearch : null} />
			<Input
				type='text'
				name='search'
				autoFocus={isDropDown}
				value={search}
				autoComplete='off'
				placeholder={dictionary.search_product_placeholder}
				disabled={!productList.length || isDisabled}
				style={{ paddingRight: '2rem' }}
				onChange={onChangeSearch}
				onKeyPress={keyPressSearch} />
		</div>
		{(temp.length > 0)
			&& <TempProductList className='py-1' id='temp_bar'>
				{
					temp.map((tmp) => {
						return <div className='pr-1' key={tmp.product_id}>
							<TempItem>
								{`${tmp.product_name} [${tmp.product_code}]`}
								<i className="fas fa-times pl-2 pointer fa-lg" onClick={!disabled ? () => removeTempItem(tmp) : null} />
							</TempItem>
						</div>
					})
				}
			</TempProductList>
		}
		<ProductListBox>
			{
				(productListWithSearch.length > 0)
					? productListWithSearch.map((val) => {
						let selected = temp.map(v => v.product_id).indexOf(val.product_id) > -1
						let cat = categoryList.length > 0 && categoryList.filter(f => f.id === val.category[0])
						return (
							<SearchItem key={`${val.product_id}_${val.product_name}`} disabled={isDisabled} onClick={!isDisabled ? () => selectTemp(val) : null} selected={selected}>
								<Title3 bold> {`${val.product_name}`}</Title3>
								<Title3 secondary>{`[${val.product_code}] ${cat.length > 0 ? cat[0].category_name : ''}`}</Title3>
							</SearchItem>
						)
					})
					: <div className='w-100 d-flex align-items-center justify-content-center' style={{ minHeight: '125px' }}>
						<Title3 secondary center>{dictionary.product_not_found}</Title3>
					</div>
			}
		</ProductListBox>
		<div className='w-100 d-flex align-items-center justify-content-center p-2 border-top'>
			<PrimaryBtn sm bold disabled={!temp.length || disabled} onClick={selectProduct}>
				{dictionary.select}
			</PrimaryBtn>
		</div>
		</>
	)
}