import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loadable from 'react-loadable';
import { hasValueInArray } from './libs/validator';

import Layout from './components/Layout';
import Loading from './components/Loading';
import LoadBusiness from './components/LoadBusiness';
import ModalMember from './components/ModalMember';
import AlertPermission from './components/PointspotAlertPermission';

import { selectLang } from './redux/actions/action_language';
import { restoreMemberInfo } from './redux/actions/action_member';
import { getArchiveReasons } from './redux/actions/action_master_data';
import { checkLogin, getConfig, onAuthStateChanged, setTabUUID } from './redux/actions/action_permission';
import { getPackages, getBusinessPackage } from './redux/actions/action_package';
import { getUsageRecord } from './redux/actions/action_usage'

import { initWalletOwner, checkOwnerAccount } from './redux/actions/action_wizard';

///////// PAGE /////////
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Wizard from './pages/Wizard';
import SelectBusiness from './pages/SelectBusiness';
import RedirectRoute from './pages/RedirectRoute';
import BroadcastMessage from './pages/BroadcastMessage/BroadcastMessage';
import MemberCardJobs from './pages/BatchJobs/MemberCardJobs';
import MemberCardJobsCreate from './pages/BatchJobsCreate/MemberCardJobsCreate';
import MemberCardJobsDetail from './pages/BatchJobsDetail/MemberCardJobsDetail';
import Login from './pages/Login';
import AutomationMembercard from './pages/Automation/MemberCard';
import AutomationEcard from './pages/Automation/Ecard';
import _ from 'lodash';
import { getCookie } from './libs/cookies';
import { generateTabUUID } from './libs/token';


const Transactions = Loadable({
  loader: () => import('./pages/Transactions'),
  loading: Loading,
});
const SendPoint = Loadable({
  loader: () => import('./pages/SendPoint'),
  loading: Loading,
});
const QuickSend = Loadable({
  loader: () => import('./pages/QuickSend'),
  loading: Loading,
});
const Rewards = Loadable({
  loader: () => import('./pages/Ecard/Rewards'),
  loading: Loading,
});
const Member = Loadable({
  loader: () => import('./pages/Member'),
  loading: Loading,
});
const Admin = Loadable({
  loader: () => import('./pages/Admin'),
  loading: Loading,
});
const ActivateECoupon = Loadable({
  loader: () => import('./pages/ActivateECoupon'),
  loading: Loading,
});
const ReportBirthday = Loadable({
  loader: () => import('./pages/Report/birthday'),
  loading: Loading,
});
const ReportDemographic = Loadable({
  loader: () => import('./pages/Report/demographic'),
  loading: Loading,
});
const ReportTransaction = Loadable({
  loader: () => import('./pages/Report/transaction'),
  loading: Loading,
});
const ReportTransactionAdmin = Loadable({
  loader: () => import('./pages/Report/transactionAdmin'),
  loading: Loading,
});
const ReportDashBoard = Loadable({
  loader: () => import('./pages/Report/dashboard'),
  loading: Loading,
})
const ReportPointsFlow = Loadable({
  loader: () => import('./pages/Report/pointsFlow'),
  loading: Loading,
});
const ReportFrequency = Loadable({
  loader: () => import('./pages/Report/frequency'),
  loading: Loading,
});
const ReportRecency = Loadable({
  loader: () => import('./pages/Report/recency'),
  loading: Loading,
});
const ReportRewards = Loadable({
  loader: () => import('./pages/Report/rewards'),
  loading: Loading,
});
const ReportCoupon = Loadable({
  loader: () => import('./pages/Report/coupon'),
  loading: Loading,
});
const ReportAdmin = Loadable({
  loader: () => import('./pages/Report/admin'),
  loading: Loading,
});
const ReportReview = Loadable({
  loader: () => import('./pages/Report/review'),
  loading: Loading,
});
const TermsConditions = Loadable({
  loader: () => import('./pages/TermsConditions'),
  loading: Loading,
});
const PullPoint = Loadable({
  loader: () => import('./pages/PullPoint'),
  loading: Loading,
});
const RequestPoint = Loadable({
  loader: () => import('./pages/RequestPoint'),
  loading: Loading,
});
const BatchJobs = Loadable({
  loader: () => import('./pages/BatchJobs'),
  loading: Loading,
});
const Ecoupon = Loadable({
  loader: () => import('./pages/Ecard/Ecoupon'),
  loading: Loading,
});
const WelcomeEcoupon = Loadable({
  loader: () => import('./pages/Ecard/WelcomeEcoupon'),
  loading: Loading,
});
const BatchJobsCreate = Loadable({
  loader: () => import('./pages/BatchJobsCreate'),
  loading: Loading,
});
const BatchJobsDetail = Loadable({
  loader: () => import('./pages/BatchJobsDetail'),
  loading: Loading,
});
const VerifyBusiness = Loadable({
  loader: () => import('./pages/VerifyBusiness'),
  loading: Loading,
});
const VerifyBusinessHash = Loadable({
  loader: () => import('./pages/VerifyBusinessHash'),
  loading: Loading,
});
const Settings = Loadable({
  loader: () => import('./pages/Settings/Business'),
  loading: Loading,
});
const BusinessContact = Loadable({
  loader: () => import('./pages/Settings/BusinessContact'),
  loading: Loading,
});
const BusinessSubscribe = Loadable({
  loader: () => import('./pages/Settings/BusinessSubscribe'),
  loading: Loading,
});
const APICredential = Loadable({
  loader: () => import('./pages/Settings/APICredential'),
  loading: Loading,
});
const Game = Loadable({
  loader: () => import('./pages/Settings/Game'),
  loading: Loading,
});
const Unsubscribe = Loadable({
  loader: () => import('./pages/Unsubscribe'),
  loading: Loading,
});
const Packages = Loadable({
  loader: () => import('./pages/Packages'),
  loading: Loading,
});
const BusinessPoster = Loadable({
  loader: () => import('./pages/Settings/BusinessPoster'),
  loading: Loading,
});
const BusinessPosterCustom = Loadable({
  loader: () => import('./pages/Settings/BusinessPoster/BusinessPosterCustom'),
  loading: Loading,
});
const Maintenance = Loadable({
  loader: () => import('./components/MaintenancePage'),
  loading: Loading,
});
const ReceiptCoupon = Loadable({
  loader: () => import('./pages/ReceiptCoupon'),
  loading: Loading,
});
const WelcomePage = Loadable({
  loader: () => import('./pages/Welcome'),
  loading: Loading,
});
const TransactionPersonal = Loadable({
  loader: () => import('./pages/Transactions/TransactionPersonal'),
  loading: Loading,
});
const Newsfeed = Loadable({
  loader: () => import('./pages/Newsfeed'),
  loading: Loading,
});
const SettingsPoints = Loadable({
  loader: () => import('./pages/Settings/Points'),
  loading: Loading,
});
const SettingsCoupons = Loadable({
  loader: () => import('./pages/Settings/Coupons/index'),
  loading: Loading,
});
const ReportSentEcard = Loadable({
  loader: () => import('./pages/Report/transSentEcard'),
  loading: Loading,
});
const ReportActivatedEcard = Loadable({
  loader: () => import('./pages/Report/transActivatedEcard'),
  loading: Loading,
});
const ReportRFM = Loadable({
  loader: () => import('./pages/Report/RFM'),
  loading: Loading,
});
const MembersCardInfo = Loadable({
  loader: () => import('./pages/Settings/MemberCardInfo'),
  loading: Loading,
});
const TransactionMembercard = Loadable({
  loader: () => import('./pages/Report/transactionMembercard'),
  loading: Loading,
});
const GiveawayCoupon = Loadable({
  loader: () => import('./pages/Ecard/Giveaway'),
  loading: Loading,
});
const Giftvoucher = Loadable({
  loader: () => import('./pages/Giftvoucher'),
  loading: Loading,
});
const BroadcastPromotion = Loadable({
  loader: () => import('./pages/BroadcastPromotion'),
  loading: Loading,
});
const Label = Loadable({
  loader: () => import('./pages/Settings/Label'),
  loading: Loading,
});
const Product = Loadable({
  loader: () => import('./pages/Settings/Product'),
  loading: Loading,
});
const ProductReport = Loadable({
  loader: () => import('./pages/Report/product'),
  loading: Loading,
});
const FriendGetFriends = Loadable({
  loader: () => import('./pages/Settings/FriendGetFriends'),
  loading: Loading,
});
const FriendGetFriendsReport = Loadable({
  loader: () => import('./pages/Report/friendGetFriends'),
  loading: Loading,
});
const SettingAdditionalRegister = Loadable({
  loader: () => import('./pages/Settings/AdditionalRegister'),
  loading: Loading,
});
const SettingLineLiff = Loadable({
  loader: () => import('./pages/Settings/LineLiff'),
  loading: Loading,
});
const Register = Loadable({
  loader: () => import('./pages/Register'),
  loading: Loading,
});
const ResetPassword = Loadable({
  loader: () => import('./pages/ResetPassword'),
  loading: Loading,
})
const SendEmailVerify = Loadable({
  loader: () => import('./pages/SendEmailVerify'),
  loading: Loading,
});
const EmailVerified = Loadable({
  loader: () => import('./pages/EmailVerified'),
  loading: Loading,
});
const AuthService = Loadable({
  loader: () => import('./pages/AuthService'),
  loading: Loading,
});
const Logout = Loadable({
  loader: () => import('./pages/Logout'),
  loading: Loading,
});
const UserManagementPage = Loadable({
  loader: () => import('./pages/UserManagement'),
  loading: Loading,
});
const SettingSecurity = Loadable({
  loader: () => import('./pages/Settings/Security'),
  loading: Loading,
});
////// END PAGE ///////

class AppRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {},
    };
  }

  componentDidMount() {
    this.props.getConfig((err, config) => {
      this.setState({ config: config });

      this.props.checkOwnerAccount();
      this.props.onAuthStateChanged(() => {
        this.props.checkLogin();
      });

      //this.initialData();
    });
    this.setBrowserTabID();

    // document.body.onfocus = () => {
    //   this.props.checkLogin();
    // };
  }

  componentDidUpdate(prevProps) {
    const {
      store_business: { current },
      store_packages: { packages },
      store_permission: { authAdmin }
    } = this.props;
    const {
      store_business: { current: prev_business },
      store_packages: { packages: prev_packages },
      store_permission: { authAdmin: prevAuthAdmin }
    } = prevProps;

    if (!current || !prev_business) return;

    if (prev_business.business_code !== current.business_code && current.business_code) {

      // let last_date = getCookie('__last_feed') || new Date('2020-02-20').getTime();
      // this.props.getBusinessNewsfeed({
      //   business_code: current.business_code,
      //   start_date: last_date,
      //   limit: 20,
      //   offset: 0,
      // });
      this.props.restoreMemberInfo();
    }

    if (prevAuthAdmin !== authAdmin) {
      this.initialData();
    }

    if (prev_packages.packages_list !== packages.packages_list) {
      this.props.getUsageRecord(current.business_code)
      this.props.getBusinessPackage({ business_code: current.business_code, load: false });
    }
  }

  setBrowserTabID() {
    let tabUUID = sessionStorage.getItem('tabUUID');
    if (!tabUUID) {
      tabUUID = generateTabUUID();
      sessionStorage.setItem('tabUUID', tabUUID);
      this.props.setTabUUID(tabUUID);
    }
  }

  initialData() {
    this.props.getPackages();
    this.props.getArchiveReasons();
  }

  checkPermission(verified, pro, feature = true) {
    let {
      store_business: { current },
    } = this.props;
    let {
      store_packages: { packages, packages_free },
    } = this.props;
    let {
      store_usage: { usage_limit }
    } = this.props;

    let permission = current && current.verified;
    if (verified !== true) permission = true;

    if (!feature) permission = feature

    if (pro === true && permission && packages.package_data && packages.package_data.package_id) {
      if (!hasValueInArray(packages_free, packages.package_data.package_id) || (hasValueInArray(packages_free, packages.package_data.package_id) && !usage_limit.isOverUsage)) {
        permission = true
      } else {
        permission = false
      }
    }

    return permission;
  }

  checkBanned (menuKey) {
    let {
      store_packages: { packages }
    } = this.props;
    return packages && packages.package_data && packages.package_data.menu_banned && hasValueInArray(packages.package_data.menu_banned, menuKey)
  }

  packagePermission (key) {
    let {
      store_packages: { packages, packages_free, subscription_items }
    } = this.props;
    if (packages.package_data && packages.package_data.package_id === "ps-shappy"){
      if (['points-request', 'setting-line-liff', 'setting-game', 'automation-member-card', 'automation-ecoupon'].includes(key))
        return false
      else
        return true
    } else if (packages.package_data && packages.package_data.package_id === "ps-free") { // free เก่า
      if (['points-request', 'setting-line-liff'].includes(key))
        return false
      else
        return true
    } 
    
    if (['ps-custom-form'].includes(key)) {
      return Boolean(
        _.find(
          hasValueInArray(packages_free, packages.package_data.package_id)
            ? packages && packages.package_data && packages.package_data.items
            : subscription_items,
          { filter_term: key }
        )
      );
    }
  }

  render() {
    let { store_business } = this.props;
    let { store_permission } = this.props;

    let {
      store_packages: { packages },
    } = this.props;
    let {
      store_business: { permission, isError, isLoading },
    } = this.props;
    let {
      store_permission: { siteConfig: propsConfig },
    } = this.props;
    let siteConfig = Object.assign({}, this.state.config, propsConfig);

    if (!permission || !packages) return null;

    let current_biz = getCookie('__bnc')

    const RoutingPathPermissionList = [
      {
        key: 'points-send',
        path: '/send',
        component: SendPoint,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('points-send')
      },
      {
        key: 'points-quick-send',
        path: '/quickSend',
        component: QuickSend,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('points-quick-send')
      },
      {
        key: 'points-pull',
        path: '/pull',
        component: PullPoint,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('points-pull')
      },
      {
        key: 'points-request',
        path: '/request',
        component: RequestPoint,
        permission: this.checkPermission(false, false, this.packagePermission('points-request')),
      },
      {
        key: 'points-batchjob',
        path: '/jobs',
        component: BatchJobs,
        permission: this.checkPermission(true, true),
        banned: this.checkBanned('points-batchjob')
      },
      {
        key: 'points-batchjob',
        path: '/jobs/create',
        component: BatchJobsCreate,
        permission: this.checkPermission(true),
        banned: this.checkBanned('points-batchjob')
      },
      {
        key: 'points-batchjob',
        path: '/jobs/:id',
        component: BatchJobsDetail,
        permission: this.checkPermission(true),
        banned: this.checkBanned('points-batchjob')
      },
      {
        key: 'membercard-batchjob',
        path: '/jobsMemberCard',
        component: MemberCardJobs,
        permission: this.checkPermission(true, true),
        banned: this.checkBanned('membercard-batchjob')
      },
      {
        key: 'membercard-batchjob',
        path: '/jobsMemberCard/create',
        component: MemberCardJobsCreate,
        permission: this.checkPermission(true),
        banned: this.checkBanned('membercard-batchjob')
      },
      {
        key: 'membercard-batchjob',
        path: '/jobsMemberCard/createPoint',
        component: BatchJobsCreate,
        permission: this.checkPermission(true),
      },
      {
        key: 'membercard-batchjob',
        path: '/jobsMemberCard/:id',
        component: MemberCardJobsDetail,
        permission: this.checkPermission(true),
        banned: this.checkBanned('membercard-batchjob')
      },
        {
        key: 'membercard-batchjob',
        path: '/jobsMemberCard/point/:id',
        component: BatchJobsDetail,
        permission: this.checkPermission(true),
      },
      {
        key: 'automation-member-card',
        path: '/automationMemberCard',
        component: AutomationMembercard,
        permission: this.checkPermission(true, true, this.packagePermission('automation-member-card')),
        banned: this.checkBanned('automation-member-card')
      },
      {
        key: 'automation-ecoupon',
        path: '/automationEcard',
        component: AutomationEcard,
        permission: this.checkPermission(true, true, this.packagePermission('automation-ecoupon')),
        banned: this.checkBanned('automation-ecoupon')
      },
      {
        key: 'setting-rewards',
        path: '/rewards',
        component: Rewards,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-rewards')
      },
      {
        key: 'setting-ecoupon',
        path: '/ecoupon',
        component: Ecoupon,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('setting-ecoupon')
      },
      {
        key: 'welcome-ecoupon',
        path: '/welcomeecoupon',
        component: WelcomeEcoupon,
        permission: this.checkPermission(false),
        banned: this.checkBanned('welcome-ecoupon')
      },
      {
        key: 'receipt-ecoupon',
        path: '/attachedCoupon',
        component: ReceiptCoupon,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('receipt-ecoupon')
      },
      {
        key: 'ecoupon-activate',
        path: '/ecouponactivate',
        component: ActivateECoupon,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('ecoupon-activate')
      },
      {
        key: 'member',
        path: '/member',
        component: Member,
        permission: this.checkPermission(false),
        banned: this.checkBanned('member')
      },
      {
        key: 'report-birthday',
        path: '/birthdayReport',
        component: ReportBirthday,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-birthday')
      },
      {
        key: 'report-demographic',
        path: '/demographicReport',
        component: ReportDemographic,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-demographic')
      },
      {
        key: 'report-transaction',
        path: '/transactionReport',
        component: ReportTransaction,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-transaction')
      },
      {
        key: 'report-transaction-admin',
        path: '/transactionReportAdmin',
        component: ReportTransactionAdmin,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('report-transaction-admin')
      },
      {
        key: 'report-dashboard',
        path: '/dashBoardReport',
        component: ReportDashBoard,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-dashboard')
      },
      {
        key: 'report-points-flow',
        path: '/pointsFlowReport',
        component: ReportPointsFlow,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-points-flow')
      },
      {
        key: 'report-frequency',
        path: '/frequencyReport',
        component: ReportFrequency,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('report-frequency')
      },
      {
        key: 'report-recency',
        path: '/recencyReport',
        component: ReportRecency,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-recency')
      },
      {
        key: 'report-rewards',
        path: '/rewardsReport',
        component: ReportRewards,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-rewards')
      },
      {
        key: 'report-ecoupon',
        path: '/ecouponReport',
        component: ReportCoupon,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-ecoupon')
      },
      {
        key: 'report-admin',
        path: '/adminReport',
        component: ReportAdmin,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-admin')
      },
      {
        key: 'report-review',
        path: '/reviewsReport',
        component: ReportReview,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-review')
      },
      {
        key: 'report-transaction-sent',
        path: '/sentReport',
        component: ReportSentEcard,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-transaction-sent')
      },
      {
        key: 'report-transaction-activated',
        path: '/activatedReport',
        component: ReportActivatedEcard,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-transaction-activated')
      },
      {
        key: 'setting-transaction-membercard',
        path: '/transactionMembercard',
        component: TransactionMembercard,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-transaction-membercard')
      },
      {
        key: 'setting-business',
        path: '/settings',
        component: Settings,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-business')
      },
      {
        key: 'setting-terms',
        path: '/terms',
        component: TermsConditions,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-terms')
      },
      {
        key: 'setting-admin',
        path: '/admin',
        component: Admin,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-admin')
      },
      {
        key: 'setting-verify',
        path: '/verify',
        component: VerifyBusiness,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-verify')
      },
      {
        key: 'setting-business',
        path: '/contact',
        component: BusinessContact,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-business')
      },
      {
        key: 'setting-subscribe',
        path: '/subscribe',
        component: BusinessSubscribe,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-subscribe')
      },
      {
        key: 'setting-credential',
        path: '/credential',
        component: APICredential,
        permission: this.checkPermission(true, true),
        banned: this.checkBanned('setting-credential')
      },
      {
        key: 'setting-packages',
        path: '/packages',
        component: Packages,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-packages')
      },
      {
        key: 'setting-poster',
        path: '/poster',
        component: BusinessPoster,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-poster')
      },
      {
        key: 'setting-poster',
        path: '/poster/:id',
        component: BusinessPosterCustom,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-poster')
      },
      {
        key: 'dashboard',
        path: '/dashboard',
        component: Dashboard,
        permission: this.checkPermission(false),
        banned: this.checkBanned('dashboard')
      },
      {
        key: 'welcome',
        path: '/',
        component: WelcomePage,
        permission: this.checkPermission(false),
        banned: this.checkBanned('welcome')
      },
      {
        key: 'newsfeed',
        path: '/newsfeed',
        component: Newsfeed,
        permission: this.checkPermission(false),
        banned: this.checkBanned('newsfeed')
      },
      {
        key: 'setting-game',
        path: '/game',
        component: Game,
        permission: this.checkPermission(false, true, this.packagePermission('setting-game')),
        banned: this.checkBanned('setting-game')
      },
      {
        key: 'setting-points',
        path: '/settingsPoints',
        component: SettingsPoints,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-points')
      },
      {
        key: 'setting-additional-register',
        path: '/additionalRegister',
        component: SettingAdditionalRegister,
        permission: this.checkPermission(false, false, this.packagePermission('ps-custom-form')),
        banned: this.checkBanned('setting-additional-register')
      },
      {
        key: 'setting-coupons',
        path: '/settingsCoupons',
        component: SettingsCoupons,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-coupons')
      },
      {
        key: 'report-RFM-analysis',
        path: '/RFMReport',
        component: ReportRFM,
        permission: this.checkPermission(false),
        banned: this.checkBanned('report-RFM-analysis')
      },
      {
        key: 'setting-member-card',
        path: '/memberCard',
        component: MembersCardInfo,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-member-card')
      },
      {
        key: 'giveaway-ecoupon',
        path: '/giveawayCoupon',
        component: GiveawayCoupon,
        permission: this.checkPermission(false),
        banned: this.checkBanned('giveaway-ecoupon')
      },
      {
        key: 'giftvoucher-ecoupon',
        path: '/giftvoucher',
        component: Giftvoucher,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('giftvoucher-ecoupon')
      },
      {
        key: 'broadcast-promotion',
        path: '/broadcastPromotion',
        component: BroadcastPromotion,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('broadcast-promotion')
      },
      {
        key: 'setting-tags',
        path: '/label',
        component: Label,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-tags')
      },
      {
        key: 'setting-product',
        path: '/product',
        component: Product,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('setting-product')
      },
      {
        key: 'report-product',
        path: '/productReport',
        component: ProductReport,
        permission: this.checkPermission(false, true),
        banned: this.checkBanned('report-product')
      },
      {
        key: 'setting-friend-get-friends',
        path: '/settingFriendGetFriends',
        component: FriendGetFriends,
        permission: this.checkPermission(true),
        banned: this.checkBanned('setting-friend-get-friends')
      },
      {
        key: 'report-friend-get-friends',
        path: '/getFriendsReport',
        component: FriendGetFriendsReport,
        permission: this.checkPermission(true),
        banned: this.checkBanned('report-friend-get-friends')
      },
      {
        key: 'setting-line-liff',
        path: '/lineLiff',
        component: SettingLineLiff,
        permission: this.checkPermission(true, false, this.packagePermission('setting-line-liff')),
        banned: this.checkBanned('setting-line-liff')
      },
      {
        key: 'setting-security',
        path: '/security',
        component: SettingSecurity,
        permission: this.checkPermission(false),
        banned: this.checkBanned('setting-security')
      },
    ];

    return (
      <>
      {
      isError && !isLoading && 
      (<AlertPermission/>) 
      }
        <Router>
          <ModalMember />
          {siteConfig.is_billing || siteConfig.is_maintenance ? (
            <Switch>
              <Route path="/" component={Maintenance} />
              <Redirect to="/" />
            </Switch>
          ) : store_permission.isLoading ? (
            <Loading />
          ) : (
            <Switch>
              <Route path="/_auth/:service/:code/:lang?" component={AuthService} />
              <Route exact path="/verified" component={EmailVerified} />
              <Route exact path="/t/:token/:action" component={RedirectRoute} />
              <Route exact path="/l/:userToken/:action?" component={RedirectRoute} />
              <Route exact path="/b/:business/:route?" component={RedirectRoute} />
              <Route exact path="/unsubscribe/:token" component={Unsubscribe} />
              <Route exact path="/verifybusiness/:hash" component={VerifyBusinessHash} />
              <Route exact path="/broadcastmessage" component={BroadcastMessage} />
              <Route exact path="/usermanagement" component={UserManagementPage} />
              <Route exact path="/signup/:email?" component={Register} />
              <Route exact path="/signup/:email?/:token?" component={Register} />
              {
                /**
                 * routeItem.component
                 * Check Authorization
                 * if not => redirect to home page
                 */
                    !store_permission.authAdmin ? (
                      store_permission.isLogin
                        ? (
                          <Switch>
                            <Route path="/" component={Home} />
                          </Switch>
                        )
                        : (
                          <Switch>
                            <Route exact path="/signin/:email?/:businessCode?" component={Login} />
                            <Route exact path="/reset" component={ResetPassword} />
                            <Redirect to="/signin" />
                          </Switch>
                        )
                    ) : !store_permission.isVerify ? (
                      <Switch>
                        <Route exact path="/verify" component={SendEmailVerify} />
                        <Redirect to="/verify" />
                      </Switch>
                    ) : (
                  <LoadBusiness>
                    {
                      /**
                       * Check business list
                       */
                      store_business.list.length === 0 || store_business.isCreated ? (
                        <Switch>
                          <Route exact path="/" component={Wizard} />
                          <Redirect to="/" />
                        </Switch>
                      ) : (store_business.list.length > 1 && !current_biz) ||
                          (store_business.current?.two_factor_authen && !store_business.current?.otp_verify) ? (
                        <Switch>
                          <Route exact path="/" component={SelectBusiness} />
                          <Redirect to="/" />
                        </Switch>
                      ) : (
                        <Switch>
                          <Route exact path="/signout" component={Logout} />
                          <Layout>
                            {!store_business.isFetching && (
                              <Switch>
                                <Route exact path="/transactions" component={Transactions} />
                                <Route
                                  exact
                                  path="/member/:search"
                                  component={TransactionPersonal}
                                />
                                <Route
                                  exact
                                  path="/send/:search?"
                                  component={SendPoint}
                                />
                                <Route
                                  exact
                                  path="/pull/:search?"
                                  component={PullPoint}
                                />
                                <Route exact path="/packages" component={Packages} />
                                {
                                  /**
                                   * Add page routing follow by routing list
                                   */
                                  RoutingPathPermissionList.map((routeItem) => {
                                    let { key: permissionKey } = routeItem;
                                    let thisPermission = permission && permission[permissionKey];

                                    return thisPermission &&
                                      thisPermission.permission_view &&
                                      routeItem.permission && !routeItem.banned ? (
                                      <Route
                                        exact
                                        key={permissionKey}
                                        path={routeItem.path}
                                        component={routeItem.component}
                                      />
                                    ) : null;
                                  })
                                }
                                <Redirect to="/" />
                              </Switch>
                            )}
                          </Layout>
                        </Switch>
                      )
                    }
                  </LoadBusiness>
                )
                /**
                 * End of check authorization
                 */
              }
              <Redirect to="/" />
            </Switch>
          )}
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_business: state.business,
    store_packages: state.packages,
    store_newsfeed: state.newsfeed,
    store_usage: state.usage
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      checkLogin,
      getConfig,
      selectLang,
      initWalletOwner,
      checkOwnerAccount,
      getPackages,
      getUsageRecord,
      getArchiveReasons,
      getBusinessPackage,
      restoreMemberInfo,
      onAuthStateChanged,
      setTabUUID,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRoute);
