export const FREQUENCY_TYPES = [
  {
    key: 'daily',
    title: 'campaign_frequency_daily',
  },
  {
    key: 'monthly',
    title: 'campaign_frequency_monthly',
  },
  {
    key: 'new_members',
    title: 'campaign_frequency_new_members',
  }
];

export const TARGET_TYPES = [
  {
    key: 'all',
    title: 'all_members',
  },
  {
    key: 'rfm_groups_6m',
    title: 'rfm_members',
    withSubValue: true
  },
  {
    key: 'rfm_groups_12m',
    title: 'rfm_members',
    withSubValue: true
  },
  {
    key: 'member_card',
    title: 'member_card_groups',
    withSubValue: true
  },
  {
    key: 'each_birthmonth',
    title: 'each_birth_month_members',
  },
  {
    key: 'birthmonth',
    title: 'birth_month_members',
  },
  {
    key: 'member_purchase_product',
    title: 'member_purchase_product'
  },
  {
   key: 'member_receive_reward',
    title: 'member_receive_reward'
  },
  {
    key: 'member',
    title: 'member_business',
  },
  {
    key: 'member_label',
    title: 'member_label',
  },
  {
    key: 'member_pending_group',
    title: 'member_pending_group'
  },
  {
    key: 'member_points',
    title: 'member_points'
  }
]
