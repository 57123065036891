import React, { Component } from 'react'
import styled from 'styled-components'

class ContainerModalBody extends Component {
  render() {
    return <ModalContainer size={this.props.size || 200}>
      {this.props.children}
    </ModalContainer>
  }
}

const ModalContainer = styled.div`
  -transition: all 0.3s ease;

  -width: 100%;
  -overflow-x: hidden;
  -overflow-y: scroll;
  -max-height: calc(100vh - ${props => props.size}px);
`

export default ContainerModalBody