export const theme = {
  primary: '#546174',
  secondary: '#7d889a',
  third: '#b1bccf',
  input_placeholder: '#B1BCCF',
  gray: '#ced4da',
  disabled: '#e4ebf1',
  // orange: '#FFBB33',
  orange: '#d86814',

  // green: '#2E7F33',
  green: '#28a745',
  // red: '#FF4444',
  red: '#EF2020',
  // blue: '#33B5E5',
  blue: '#0099CC',
  blue_light: '#f0f8ff',

  yellow: '#ffc107',
  brown: '#67532f',
  brown_light: '#c5ac2d',
  white: '#fafafa',
  white_2: '#F0F0F0',
  white_1: '#FFFFFF',
  white_border: '#e6e6e6',
  white_hilight: '#f0f0f0',
  info: '#17a2b8',
  border_line: '#dee2e6',
  highlight: '#fffabf',

  font_xxl: '20px',
  font_xl: '18px',
  font_l: '16px',
  font_m: '14px',
  font_s: '12px',
  font_xs: '10px',
  font_xxs: '8px',
  font_xxxs: '6px',
  font_bold: '600',

  // type eCard
  reward: '#00DA71',
  giveaway_coupon: '#FFBB33',
  discount_amount: '#FD7E14',
  coupon: {
    reward: '#00c0d9',
    discount: '#FE0041',
    points_in: '#FE9200',
    discount_amount: '#FD7E14',
  },
  tooltip_bg: '#f8f8f8',
  tooltip_text_color: '#546174',
  font_style: {
    primary: `'Prompt', sans-serif`,
  }
}