import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Title2 } from "../Font";
import {
  CardInfoContainer,
  InfoContainer,
  InfoTitle,
  MemberCardTabBar,
  PrivilegeContainer,
  SelectedBox,
  TabMenu,
} from "./MemberCard.styled";
import { nl2br } from "../../libs/nl2br";

function MemberCardInfoSection(props) {
  let { themeColor, cardPrivilege, cardName } = props;
  const dictionary = useSelector((state) => state.language.dictionary);

  const [tabSelected, setTabSelected] = useState("Info");
  const [selectedBoxPosition, setSelectedBoxPosition] = useState(0);

  useEffect(() => {
    if (cardName) {
      setTabSelected("Info");
      setSelectedBoxPosition(0);
    }
  }, [cardName]);

  useEffect(() => {
    if (cardPrivilege) {
      setTabSelected("Privilege");
      setSelectedBoxPosition(100);
    }
  }, [cardPrivilege]);

  function selectTab(tab, position) {
    setTabSelected(tab);
    setSelectedBoxPosition(position);
  }

  return (
    <CardInfoContainer>
      <MemberCardTabBar themeColor={themeColor}>
        <SelectedBox position={selectedBoxPosition} themeColor={themeColor} />
        <TabMenu
          selected={tabSelected === "Info"}
          onClick={() => selectTab("Info", 0)}
        >
          <Title2 style={{ color: themeColor }} className="ml-3">
            {dictionary.member_card_info}
          </Title2>
        </TabMenu>

        <TabMenu
          selected={tabSelected === "Privilege"}
          onClick={() => selectTab("Privilege", 100)}
        >
          <Title2 style={{ color: themeColor }} className="mr-3">
            {dictionary.privilege_card}
          </Title2>
        </TabMenu>
      </MemberCardTabBar>

      {tabSelected === "Info" ? (
        <MemberCardInfo themeColor={themeColor} />
      ) : (
        <MemberCardPrivilege
          cardPrivilege={cardPrivilege}
          themeColor={themeColor}
        />
      )}
    </CardInfoContainer>
  );
}
export default MemberCardInfoSection;

const MemberCardInfo = (props) => {
  let { themeColor } = props;

  const dictionary = useSelector((state) => state.language.dictionary);

  return (
    <InfoContainer themeColor={themeColor}>
      <div className="d-flex">
        <InfoTitle themeColor={themeColor}>
          {dictionary.f_name}-{dictionary.last_name}
        </InfoTitle>
        : {dictionary.first_name} {dictionary.last_name}
      </div>

      <div className="d-flex">
        <InfoTitle themeColor={themeColor}>Pointspot ID</InfoTitle>: 123456
      </div>
    </InfoContainer>
  );
};

const MemberCardPrivilege = (props) => {
  let { cardPrivilege, themeColor } = props;

  return (
    <PrivilegeContainer themeColor={themeColor}>
      {nl2br(cardPrivilege)}
    </PrivilegeContainer>
  );
};
