import styled from "styled-components";

export const RowHeadData = styled.div`
	min-height: 40px;
	display: flex;
	align-items: center;
	border-top: 1px solid #dee2e6;
	border-bottom: 1px solid #dee2e6;
`;

export const ColHeadData = styled.div`
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5px;
	text-align: center;
	width: ${(props) => props.width}%;
`;

export const RowData = styled.div`
	min-height: 40px;
	display: flex;
	align-items: stretch;
	border-bottom: 1px solid #dee2e6;
	${(props) => props.valueKey % 2 !== 1 && `background-color:  #F2F2F2;`}
`;

export const ColData = styled.div`
	min-height: 40px;
	padding: 5px;
	display: flex;
  flex-direction: column;
	align-items: center;
	width: ${(props) => props.width}%;
	justify-content: center;
  text-align: center;
`;