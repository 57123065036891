import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import { userActions } from '../../store/userSlice';
import './NavBar.css';

function NavBar() {
  const dispatch = useDispatch();

  const imageUrlFromLogin = useSelector((state) => state.user.imageUrl);
  const [imageUrl, setImageUrl] = useState(imageUrlFromLogin);

  const name = useSelector((state) => state.user.name);
  const email = useSelector((state) => state.user.email);
  const [hidden, setHidden] = useState(true);
  const [logoutHidden, setLogoutHidden] = useState(true);

  function clickHandler() {
    if (name) {
      setHidden(!hidden);
    }
  }

  function logoutHandler() {
    setHidden(true);
    setLogoutHidden(false);
  }

  function errorHandler() {
    setImageUrl(null);
  }

  function logoutConfirm() {
    dispatch(userActions.logout());
    setLogoutHidden(true);
  }

  function cancelLogout() {
    setLogoutHidden(true);
  }

  return (
    <>
      {!logoutHidden &&
        createPortal(
          <>
            <div className="logout-overlay" onClick={cancelLogout}></div>
            <div className="logout-confirmaion">
              <p className="title">Log out</p>
              <p>Are you sure you want to log out?</p>
              <div className="logout-btns">
                <button className="logoutbtn" onClick={cancelLogout}>
                  Cancel
                </button>
                <button className="logoutbtn" onClick={logoutConfirm}>
                  Log Out
                </button>
              </div>
            </div>
          </>,
          document.getElementById('broadcast-message-modal')
        )}

      <div className="broadcast-navbar">
        <div className="broadcast-navbar-container">
          <p>Broadcast Message</p>
          <div className="broadcast-navbar-dropdown">
            <p onClick={clickHandler}>{name}</p>
            <img
              src={imageUrl ? imageUrl : require('../default_avatar.png')}
              alt=""
              onClick={clickHandler}
              onError={errorHandler}
            ></img>
            <ul className={hidden ? 'dropdown-hidden' : ''}>
              <li>{email}</li>
              <li>
                <button className="logout-btn" onClick={logoutHandler}>
                  Log Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavBar;
