const EN = {
	locale: "en",
	language_thailand: "ภาษาไทย",
	language_english: "English",
	language_thai: "Thai",
	language_eng: "English",
	hello: "HELLO!",
	continue_with_google: "Continue with Google",
	continue_with_facebook: "Continue with Facebook",
	signup: "Signup",
	signup_business: "Create new Pointspot business account",
	signin: "Signin",
	goto_admin: "Goto Admin",
	email: "Email",
	password: "Password",
	forget_password: "Forget password",
	singin_with_readyplanet_account: "Sign in with your Readyplanet account",
	email_or_password_is_incorrect: "Email or Password is incorrect.",
	too_many_failed_login_attempts: "Too many failed login attempts. Please try again later.",
	an_error_has_occurred: "An error has occurred. Please try again later.",
	name: "Name",
	f_name: "First name",
	first_name: "First name",
	last_name: "Last name",
	surname: "Surname",
	phone: "Phone",
	confirm_password: "Confirm password",
	confirm: "Confirm",
	apply: "Apply",
	submit: "Submit",
	cancel: "Cancel",
	cancelled: "Cancelled",
	back: "Back",
	reset_password: "Reset password",
	set_password: "New password",
	or: "or",
	to: "to",
	as: "as",
	email_incorrect: "Email is incorrect",
	email_already_in_use: "Email is already in use",
	weak_password: "Password must be at least 6 characters",
	password_not_match: "Password not match",
	logout: "Sign out",
	profile: "Profile",
	wallet_id: "Wallet ID",
	check_email_for_reset_password: "Link for reset your password is send to",
	from: "From",
	no_results_found: "No results found",
	no_user_found: "Member not found",
	wait: "Wait",
	second: "Second",
	seconds: "Seconds",
	minute: "Minute",
	minutes: "Minutes",
	hour: "Hour",
	hours: "Hours",
	save: "Save",
	dont_save: "Don't Save",
	delete: "Delete",
	publish: "Publish",
	unpublish: "Unpublish",
	edit: "Edit",
	confirm_logout: "Are you sure you want to sign out?",
	earn: "Earn",
	search: "Search",
	close: "Close",
	goto_manual: "Go to docs",
	support: "Support",
	feature_request: "Feature request",
	announce_messages: "Announce Messages",
	change_language: "เปลี่ยนภาษา",
	account_profile: "Account profile",
	edit_profile: "Edit profile",
	edit_password: "Edit password",
	billing_payment: "Payment",
	select_photo: "Select photo",
	remove_image: "Remove image",
	crop: "Crop",
	hide: "Hide",
	show: "Show",
	copy: "Copy",
	copied: "Copied",
	show_more: "SHOW MORE",
	more: "MORE",
	show_less: "SHOW LESS",
	rewards: "Rewards",
	expired: "Expired",
	type: "Type",
	active: "Active",
	inactive: "Inactive",
	received: "Received",
	completed: "Completed",
	and: "and",
	items: "items",
	day: "day",
	notification: "Notification",
	by: "by",
	create: "Create",
	times: "Times",
	anouncement_date: "Anouncement date",
	data_not_found: "Data not found ",
	number: "Number",
	data_range: "Date range",
	percent_of_data_all: "Percent of data all",
	saved: "Save completed",
	created_successfully: "Created successfully",
	saved_successfully: "Saved successfully",
	deleted_successfully: "Deleted successfully",
	sent_points_successfully: "Sent points successfully",
	pulled_points_successfully: "Pulled points successfully",
	archived_successfully: "Archived successfully",
	month: "month",
	year: "year",
	caution: "Caution",
	terms_conditions: "Terms & Conditions",
	accept_terms: "By signing in to system you agree to all the",
	accept_terms_conditions: "Accept Terms & Conditions",
	accept_terms_create: "By creating account you agree to all the",
	privacy_policy: "Privacy Policy",
	thb: "THB",
	accept: "Accept",
	skip: "Skip",
	next: "Next",
	recommend: "Recommend",
	terms_payment: "Payment terms",
	terms_payment_list: [
		{
			title: "1. Payment method",
			description:
				"Payment for Pointspot service will charge via credit card online, The system will summarize the expense for each month on the last day of the month and debiting from the card that has been set up in the system. In the case that the card payment system cannot be deduct money because the card has expired or the credit limit is not enough or others, service may be unavailable until the outstanding payments.",
		},
		{
			title: "2. Pointspot service provider",
			description:
				"The company that provides Pointspot service is Readyplanet Public Company Limited., which is registered and located in 51 MAJOR TOWER RAMA 9 - RAMKUMHANG Building 17th floor, room 1701 - 1706, Ramkhamhaeng Road, Hua Mak Subdistrict, Bang Kapi District, Bangkok 10240. The service charge is include VAT",
		},
		{
			title: "3. Payment security system",
			description:
				"Pointspot uses online payment systems from 2c2p, a global online payment service provider. Credit card information is not stored in the Pointspot system but it is maintained at the payment service company. For security reasons",
		},
	],
	not_do_transaction: "Can not do the transaction",
	not_do_transaction_payment: "Payment unsuccessful Can not do the transaction",
	all: "All",
	valid: "Valid",
	select_all: "Select all",
	cancel_all: "Cancel all",
	you_can_learn: "You can learn how to use",
	get_from_here: "get from here",
	dashboard: "Dashboard",
	in_business: "in business",
	setting_menu: "Setting menu",
	filter: "Filter",
	duration: "Duration",
	refresh: "Refresh",
	available: "Available",
	option: "Option",
	wait_check_info: "Checking information, please wait.",
	updated_data: "Updated data",
	updating_data: "Data update",
	latest_update: "Latest update",
	update_period: "Update period",
	every_time: "Every time",
	every_hour: "Every hour",
	every_day: "Daily",
	get_started: "Get started",
	privilege: "Privilege",
	enabled: "Enabled",
	i_agree: "I Agree",
	create_by: "Create by",
	message: "Message",
	notification_message: "Notification Message",
	details: "Details",
	details_more: "Details more",
	not_specified: "Not specified",
	created_on: "Created on",
	order_by: "Order by",
	choose: "Choose",
	upgrade: "Upgrade",
	new: "New",
	need_verify: "Activate business",
	process_success: "Processing successfully",
	boardcast: "Boardcast",
	send_coupon: "Send coupon",
	can_not_scan_qr: "Can not read this QR code.",
	detail_more_click: "More details click",
	menu: "Menu",
	member: "Member",
	view: "View",
	by_product: "By Product",
	by_member: "By Member",
	language: "Language",
	exporting: "Exporting...",
	exported: "Exported successfully",
	send_to_my_email: "Send to my email",
	advance_setting: "Advance Settings",
	addtional_setting: "Additional Settings",
	date_of_birth: "Date of birth",
	automation: "Automation",
	swipe_by_client: "Swipe by client",
	start_date: "Start date",
	end_date: "End date",

	singin_with_pointspot_account: "Sign in with your Pointspot account",
	firstname: "First name",
	lastname: "Last name",
	contact_phone: "Contact Phone",
	resend_email: "Resend email",
	dont_receive_email: "Didn't receive email ?",
	please_verify_email: "Please verify your email address",
	please_check_email: `Please check your email`,
	please_check_email2: `And confirm by email that Pointspot sent to`,
	require_email_reset: "Reset password for this email",
	check_email: "Please check email",
	new_password: "New password",
	password_changed: "Password changed",
	signin_with_new: "You can now sign in with your new password",

	// Business Type List
	home_decoration: "Home & Decorations",
	business_service: "Business Service",
	sport: "Sport",
	automotive: "Automotive",
	catering_ceremonies: "Catering & Ceremonies",
	education: "Education",
	mom_child: "Mother & Child",
	entertainment: "Entertainment",
	travel: "Travel",
	industry: "Industry",
	health_beauty: "Health & Beauty",
	fashion: "Fashion",
	foods: "Food, Beverages and Ingredients",
	technology: "Technology & IT",
	construction: "Construction & Material",
	government_sector: "Government agencies & Organizations",
	financial: "Bank & Financial",
	shopping_mall: "Stores",
	energy: "Energy",
	real_estate: "Real Estate",
	publications: "Media & Publications",
	office: "Equipment",
	agriculture: "Agriculture",
	other: "Others",

	emergency_service: "Emergency service",
	kitchen: "Kitchen",
	sanitary_ware: "Sanitary ware",
	floor_wall_ceiling: "Floor, wall and ceiling",
	home_equipment: "Equipment and home appliances",
	electrical: "Electrical appliances",
	garden: "Garden",
	repair_service: "Repair and other service",
	furniture: "Furniture and decoration",
	office_decoration: "Office decoration",
	office_supplies: "Office material",
	foreign_trade: "Foreign trade",
	consultant: "Consultant",
	paper_packaging: "Paper and packaging business",
	packaging_transportation: "Packaging and transportation",
	management_service: "Administration, management and services",
	accounting_tax: "Accounting and Taxation",
	advertising_social_media: "Advertising, public relations, publication and online media",
	sports_venue: "Sports and coaching venues",
	sports_equipment: "Sports equipment",
	buy_sell: "Buy, sell, trade and rent",
	tool_equipment: "Tools, equipment and spare parts",
	auto_accessories: "Auto accessories",
	car_wash: "Wash, polish, coat",
	garage: "Repair",
	insurance: "Insurance and other services",
	vehicles: "Vehicles and other services",
	catering_socializing: "Catering and socializing",
	event_location: "Event and location",
	charity_items: "Merit event",
	funeral_items: "Funeral",
	wedding_items: "Engagement and wedding",
	graduation_items: "Graduation day supplies",
	charity_engagement: "Merit at the engagement ceremony and wedding",
	education_institutions: "Education and institutions",
	educational_equipment: "Educational equipment",
	research_experiment: "Research, Experiment, and Other Services",
	mothers: "Start a mother",
	nursery: "Nursery",
	children_item: "Baby Equipment and Supplies",
	entertaining: "Entertaining",
	home_entertainment: "Home entertainment",
	hobby: "Hobby",
	pets: "Pets",
	travel_accommodation: "Travel and accommodation",
	traveling: "Traveling",
	travel_supplies: "Travel supplies and other services",
	industrial_appliances: "Machinery, tools, equipment and industrial appliances",
	metal_business: "Metal business, steel, stainless steel, aluminum",
	land_factory: "Land, building, factory and service business",
	consumer_goods: "Consumer goods business",
	plastic_industry: "Plastics industry business",
	sercives: "Service business",
	doctor_treatment: "Doctor and treatment",
	medicine: "Medicines, equipment and appliances for treatment",
	beauty_salon: "Beauty salon and health center",
	cosmetic: "Cosmetic business",
	clothing_jewelry: "Clothing, leather goods, textiles, jewelry business",
	entertainment_venue: "Entertainment venue",
	food_equipment_service: "Food equipment and services",
	food_beverage: "Food, beverage, seasoning business",
	food_beverage_service: "Food and beverage services",
	communication_tech: "Communication and technology",
	it_equipment: "IT equipment",
	internet: "Internet",
	contractors_designer: "Contractors, designers and technicians",
	construction_equipment: "Construction equipment",
	foundation_organization: "Foundation, organization, association",
	banking: "Banking",
	grocery_stores: "Grocery store",
	department_stores: "Department store",
	energy_environment: "Energy and environment business",
	accommodation: "Accommodation",
	home_land: "Home and land business",
	service: "Service",
	factory_warehouse: "Buildings, factories, warehouses",
	equipment_appliance: "Equipment and appliances",
	agribusiness: "Agribusiness, fishery, livestock, mining",

	// Sidebar Menu
	report_demographic_transactions: "Transaction",
	reports: "Reports",
	settings: "Settings",
	points_transactions: "Transactions",
	history_transactions: "Transactions",
	points_send_points: "Send Points",
	points_pull_points: "Pull Points",
	collect_point: "Collect Points",
	points_transfer_points: "Transfer Points to friend",
	points_activate_ecoupon: "Activate coupon/reward",
	report_members: "Members",
	report_birthday: "Birthday",
	report_demographic: "Demographic",
	report_transactions: "Transactions point",
	report_frequency: "Member loyalty",
	setting_business: "Business",
	setting_redeem_rewards: "Redeemable rewards",
	setting_terms_conditions: "Terms & Conditions",
	setting_privacy_policy: "Privacy Policy",
	setting_subscribe: "Subscribe",
	setting_admin: "Admin",
	setting_credential: "API Credential",
	setting_line_liff: "LINE Login",
	setting_ecard_group_reward: "Rewards",
	setting_ecard_group_coupon: "Coupons",
	setting_additional_register: 'Registration Form',
	pt: "PT",

	//Alert Message
	cannot_completed_request: "Cannot completed your request,\r\nplease try again later.",

	// Point Board
	create_business: "Create business",
	limited_business: "Limited business",
	create_x_business: "Create business (Quota $1)",
	limited_x_business: "Quota limited (Total owned $1)",
	points_summary: "Today points summary",
	points_send: "Send",
	points_pull: "Pull",
	points_pay: "Pay",
	transfer_to_business: "Transfers to business",
	points_redeem: "Redeem",
	points_transfers: "Transfers",
	pay_points: "Pay points",
	receive_points: "Receive points",
	game_points: "Game points",
	redeem_points: "Redeem points",

	// Collect Point
	approved: "Approved",
	approve: "Approve",
	approver: "Approver",
	reject: "Reject",
	rejected_request: "Rejected",
	pending_approval: "Pending approval",
	back_comment: "back",
	check: "Review",
	check_collet: "Review Points",
	total_amount_baht: "Total Amount (Baht)",
	amount_baht: "Amount (Baht)",
	amount_no_baht: "Amount",
	approve_point: "Proceed to send points to",
	reject_point: "Reject to send points to",
	photo_attachment: "Attach Image",
	comment_reject_label: "Please specify the reason for reject.",
	request_approve_successfully: "Approved Collect Points Successfully",
	request_reject_successfully: "Rejected Collect Points Successfully",
	setting_request_points_type_on_off: "Setting ON / OFF Collect Points",
	setting_by_product_type_on_off: "ON / OFF Require Product",
	setting_by_product_description: "Requires 1 product to activate",
	setting_by_product_link: "Go to Products Management",
	enable_setting_request_points: "Enable Collect Points",
	disable_setting_request_points: "Disable Collect Points",
	enable_setting_collect_points_by_product: "Enable Require Product",
	disable_setting_collect_points_by_product: "Disable Require Product",
	all_product: "All Product",
	no_product: "No Product",
	collect_point_report: "Collect Points Report",
	comment_reject: "Comment",
	admin_comment: "Admin comment",
	member_comment: "Member comment",
	from_collect_points: "Points from Collect Points",
	last_update: "Last Updated",
	an_error_has_occurred2:
		"An error has occurred. It appears that another administrator has already updated this transaction.",
	setting_notify_to_members: "Notify to members for points collect requests that have been rejected.",
	caution_setting_notify_to_members:
		"Caution : Setting the notification will be charged for the number of notification from actual usage.",
	enable_setting_rejected_noti: "Enable Notification",
	disable_setting_rejected_noti: "Disable Notification",
	collect_point_noti: "Collect Points Notification",

	// Transactions
	transactions_history: "Transactions history",
	transactions: "Transactions",
	basic_transactions: "Basic Transactions",
	promotional_transactions: "Promotional Transactions",
	no_transactions: "No transactions",
	mobile_number: "Mobile number",
	user_duplicated: "Duplicate user",
	user_invalid: "User invalid",
	mobile_no: "Mobile No.",
	activity: "Activity",
	activity_detail: "Activity detail",
	noactivity: "No activity",
	points: "Points",
	points_total: "Total Points",
	action: "Action",
	actionBy: "Action by",
	send: "Send",
	pull: "Pull",
	redeem: "Redeem",
	transfers: "Transfers",
	transfers_points: "Transfers points",
	receive_transfer: "Receive transfer",
	receive: "Receive",
	activate: "Activate",
	activated: "Activated",
	activate_code: "Activate code",
	activated_successfully: "Activated successfully",
	created: "Created",
	bySystem: "System",
	byPersonal: "Personal",
	can_not_activate_coupon: "Coupon can not activate. Recommend user to subscribe business",

	// Coupon
	search_ecoupon: "Search coupon",
	ecoupon_description: "Please scan QR Code or insert redeem code. For example, Code : 12345678",
	personal_detail: "Personal detail",
	coupon_detail: "Coupon detail",
	reward_detail: "Reward detail",
	coupon_code: "Coupon code",
	coupon_status: "Status",
	coupon_created: "Created",
	ready: "Ready",
	activated_date: "Activated",
	confirm_activate_coupon: "Please confirm to activate coupon",
	confirm_change_status_coupon: "Are you sure you want to change coupon status ?",
	show_redeem_code: "Show redeem code",
	redeem_code: "Redeem code",
	truly_verified: "Please verify information",
	ecoupon_member: "Coupon member",
	coupon_display_options: "Coupon display options",
	reward_display_options: "Reward display options",
	reward_display_options_info: [
		{ title: "Not show reward", description: "When selected, members will not see this reward." },
		{
			title: "Display by member card",
			description: "When selected, only members who hold the selected member card can see this reward.",
		},
		{
			title: "Display only these members",
			description:
				"When selected, only members who have at least one of the selected member labels can see this reward.",
		},
		{
			title: "Not display to these members",
			description:
				"When selected, members who have at least one of the selected member labels can not see this reward.",
		},
	],
	coupon_display_options_info: [
		{ title: "Not show coupon", description: "When selected, members will not see this coupon." },
		{
			title: "Display by member card",
			description: "When selected, only members who hold the selected member card can see this coupon.",
		},
		{
			title: "Display only these members",
			description:
				"When selected, only members who have at least one of the selected member labels can see this coupon.",
		},
		{
			title: "Not display to these members",
			description:
				"When selected, members who have at least one of the selected member labels can not see this coupon.",
		},
	],
	hide_visibility_coupon: "Not show coupon",
	hide_visibility_reward: "Not show reward",
	member_card_visibility: "Display by member card",
	member_label_visibility: "Display only these members",
	member_label_visibility_hidden: "Not display to these members",
	show_for_action_value: "Members with points",
	sorting: "sorting",
	sortToTop: "Move to top",
	sortUp: "Move up",
	sortToBottom: "Move to bottom",
	sortDown: "Move down",
	sortCancel: "Canceled successfully",
	sortingNotFound: "Coupon not found",

	// Send Point and Pull Point
	initialization: "Initialization...",
	pointspot_id: "Pointspot ID",
	mobile: "Mobile",
	send_points_description: "Please insert mobile number or Pointspot ID",
	please_insert_number: "Please insert mobile number or Pointspot ID",
	new_member: "New member",
	non_member: "Non-Member",
	send_points: "Send points",
	send_points_remain: "Send points remain",
	send_points_exchange_rate: "Points conversion ratio is",
	For_example: "For example,",
	ex: "Ex.",
	example: "Example",
	amount: "Amount",
	price: "Price",
	limit: "Limit",
	attachment: "Attachment",
	image_attachment: "Attach Image",
	attach_img_or_coupon: "Attach image / coupon",
	receipt: "Receipt",
	photo: "Photo",
	image: "Image",
	remove: "Remove",
	remark: "Remark",
	reference: "Remark",
	campaign_name: "Campaign name",
	receipt_number: "Receipt number",
	send_points_confirmation: "Send points confirmation",
	recent_transactions: "Recent transactions",
	recent_transactions_of: "Recent transactions of",
	no_recent_transactions: "No recent transactions",
	pull_points: "Pull points",
	pull_points_from: "Pull points from",
	point_not_enough: "Point not enough",
	pull_points_confirmation: "Pull points confirmation",
	tip_register: ` Tip : For new customers \n Should recommend customer to subscribe business before send points`,
	tip_register_gift_voucher: ` Tip : For new customers \n Should recommend customer to subscribe business before send the gift voucher`,
	customer_subscribe_business: "Your customer would like to subscribe the business member",
	customer_unsubscribe_business: "Customer unsubscribe the business",
	sender_name_warning:
		"Sending messages during the service trial may not be deliver in some case (block broadcast message)",
	member_will_not_receive_sms: "Member will not receive SMS notifications for receiving points.",
	tell_customer_register_receive_sms: "Tell this customer to register in order to receive SMS",
	credit_to_be_used: "The credit to be used",

	// Quick send points
	quick_send_points: "Quick send points",
	send_points_detail: "Send points details",
	exchange_rate: "Exchange rate is",
	go_to_send_points: "Go to the send points on desktop.",

	//Report Dashboard
	dashboard_report: "Summary",
	dashboard_report_desc: "Summary of all report",
	dashboard_transaction: "Transactions",
	dashboard_reviews: "Reviews",
	dashboard_point: "Points",
	dashboard_coupon: "Coupons",
	dashboard_broadcast: "Broadcast",
	dashboard_member_card: "Member Cards",
	dashboard_point_expiry_notification: "Points Expiry Notification",
	dashboard_collect_point_notification: "Rejected Collect Points Notification",
	dashboard_member_card_expiry_notification: "Member Card Expiry Notification",
	dashboard_members: "Members",
	dashboard_coupon_reward: "Coupons and Rewards",
	dashboard_total_transactions: "Total Transactions",
	dashboard_recent_review: "Recently Reviews",
	dashboard_promotional_coupon: "Promotional",
	dashboard_welcome_coupon: "Welcome",
	dashboard_attached_coupon: "Attached",
	dashboard_giveaway_coupon: "Giveaway",
	dashboard_gift_voucher_coupon: "Gift Voucher",
	dashboard_reward: "Rewards",
	dashboard_most_activated_coupon: "Top 5 rankings",
	dashboard_coupon_name: "Coupon name",
	dashboard_total_activated: "Total Activated",
	dashboard_total_members: "Total Members",
	dashboard_new_members: "New Members",
	dashboard_return_members: "Return Members",
	dashboard_active_members: "Active Members",
	dashboard_not_found_coupons: "Not found activated coupons",
	dashboard_not_found_review: "Not found reviews",
	dashboard_view_details: "View details",
	dashboard_activated: "Activated",
	view_details_less: "view less",
	view_member_details: "View member details",
	top_five_products: "Top 5 Most Used Products",
	view_product_details: "View product details",
	product_transaction: "Transactions",
	view_transaction_details: "View transaction details",
	dashboard_create_product: "Create product",
	dashboard_upgrade_product: "Upgrade for use product level points",
	total_usage_transactions: "Total usage transactions",
	dashboard_no_data: "No data",
	dashboard_no_product: 'No product',
	dashboard_no_product_transaction: 'No product transaction',

	// Report Member
	members_report: "Members report",
	members: "Members",
	members_infomation: "Members information",
	pending_members: "Pending members",
	pending_member: "Pending member",
	members_detail: "Members detail",
	members_contact: "Members contact",
	subscribed: "Subscribed",
	pending_members_description: "Customers who received points or member card but not subscribe business",

	// Report birthday
	birthday_report: "Birthday report",
	birthday_report_data: "Birthday data report",
	January: "January",
	February: "February",
	March: "March",
	April: "April",
	May: "May",
	June: "June",
	July: "July",
	August: "August",
	September: "September",
	October: "October",
	November: "November",
	December: "December",
	date_of_birth_in: "Date of birth in",
	select_date: "Select month",
	export_to_xls: "Export to .XLS",
	export_to_mail: "Export to Email",
	export_member_name: "Export member name",
	data: "Data",
	name_lastname: "Name lastname",
	member_name: "Members name",
	age: "Age",
	subscribe_date: "Subscribe date",
	create_date: "Create date",
	business: "Business",
	data_not_found_in: "Data not found in",

	// Report Demographic
	demographic_report: "Demographic report",
	report_demographic_description: "Demographic : Gender (male and female), Age range",
	male: "Male",
	female: "Female",
	unspecified: "Unspecified",
	independent_variables: "Independent variables",
	percent: "Percent",
	age_range: "Age range",
	total: "Total",
	gender: "Gender",
	date: "Date",
	person: "Person",

	// Report Transactions
	transactions_report: "Transactions point report",
	activity_points_in_business: "Activity points in business",
	select_date_range: "Select date range",
	date_range: "Date range",
	date_on: "Date on",
	data_not_found_on: "Data not found on",
	today: "Today",
	yesterday: "Yesterday",
	this_week: "This week",
	last_week: "Last week",
	this_month: "This month",
	last_month: "Last month",
	last_30_days: "Last 30 days",
	this_quarter: "This quarter",
	last_quarter: "Last quarter",
	last_3_month: "Last 3 months",
	last_6_month: "Last 6 months",
	last_12_month: "Last 12 months",
	this_year: "This year",
	last_year: "Last year",
	all_time: "All time",
	custom: "Custom",
	past_1_month: "Last 1 month",
	past_3_month: "Last 3 months",
	past_6_month: "Last 6 months",
	past_12_month: "Last 12 months",
	month_1: "Monthly",
	month_3: "Quarterly",
	month_6: "Six-monthly",
	month_12: "Yearly",
	report_will_send_to_email_after_processed: "Report will be sent to your email after it finishes processing",
	report_sent_coupon_history: "Sent coupons report",
	report_large_date_export_to_email: "Due to a large volume of data, the system will send it via email.",

	// Report Points Flow
	points_flow: "Points flow",
	report_point_flow: "Points flow report",
	report_point_flow_sub: "Comparison report between spent and earned points",
	points_earned: "Points earned",
	points_spent: "Points spent",
	view_chart: "Choose chart view",
	recommendation: "Recommendation",
	conditions_recommend: "Recommendation (processed from the latest 30 days of statistical data)",
	recommend_within_thirty: "Recommendation from reports in the last 30 days",
	point_flow_description: {
		title: "Points flow report details",
		sub_title: "Report comparing between points in and points out",
		list: [
			{ description: "X axis : show time period" },
			{ description: "Y-axis : show the number of points" },
			{
				description:
					"Points earned : The green bars on the graph show the number of points that get back from members in each time period.",
			},
			{
				description:
					"Points spent : The red bars on the graph show the number of points that send to members in each time period.",
			},
		],
	},
	point_flow_recommend: {
		total_conditon: {
			earn_less_than_spent: "Adjust rewards to be more interesting or use fewer points to redeem a reward.",
			earn_more_than_spent: "Your business has loyal customers returning to use points to redeem rewards continuously.",
			earn_between_spent: "Persuade customers to use their points to redeem a reward.",
		},
		frequent_condition: {
			spent_less_than_half:
				"Send points more often and should send as soon as customers come to buy products or use the service.",
			spent_more_than_half:
				"Keep S to customers to increase the chances of changing to new customers into regular customers",
		},
		special_condition: {
			average_spent_less_twenty:
				"Adjust points conversion rate to motivate customers to want to come back to accumulate points more often.",
		},
	},

	// Frequency Report
	frequency: "Member loyalty",
	frequency_report: "Member loyalty report",
	frequency_report_data: "Member loyalty data report",
	frequency_detail: "Member loyalty report in business",
	loyalty_member_report: "Member loyalty detail report",
	frequency_range: "Service",
	frequency_of_services: "Frequency of services",
	frequency_member: "Member",
	export_member_name_keep_secret: "Export member name as personal information,\nPlease keep it a secret.",
	sent_to_your_email: "Successful, Data sent to your email",
	document_has_sent_to_email: "The document will be sent to your email after it finishes processing",
	percentile: "Percentile",

	// Recency Report
	recency: "Recency",
	recency_report: "Recency",
	recency_detail: "Recency report of activity points in business",
	daylastseen_range: "Days since last seen",

	// Rewards Report
	reward_report: "Rewards report",
	reward_report_sub: "Rewards redemption report",
	reward_title: "Rewards name",
	expire_date: "Expire date",
	total_redeem: "Total\nredeem",
	total_activated: "Total\nactivated",
	total_point_earned: "Total\npoints earned",
	points_used: "Points used",
	points_used_redeem: "Points used",
	reward_status: "Rewards status",
	choose_view: "Choose view",
	info_report_rewards_description:
		"Report Reward is checking usage reports of reward points when customer redeem reward points and applied to business that can choose time what you want for redeem reward types, how many times of redeem and how many times for applied to your business.",
	info_report_rewards_detail: [
		{ title: "Date range", detail: "Period for redeeming reward coupons or periord for admin usage coupons." },
		{ title: "Rewards name", detail: "Rewards name created by business." },
		{ title: "Expire date", detail: "Expried date of rewards." },
		{
			title: "Rewards category",
			detail:
				"Show reward category specified by the administrator, will show are promotions redeemable reward or giveaway reward.",
		},
		{ title: "Total redeem", detail: "Number of redeem rewards of member according to chosen time." },
		{ title: "Total activated", detail: "Number of activated reward coupon according to chosen time by admin." },
		{
			title: "Points used",
			detail:
				"The total number of points used by members to redeem coupons, Example the reward A redeem 10 points and member redeem for 5 times. Points used are 50 points.",
		},
	],
	info_report_rewards_note:
		"When members redeem reward and business activated coupon will effect to number of redeem coupon match to number of activated coupon.",
	redeemable_rewards: "Redeemable rewards",

	// Ecoupon Report
	ecoupon_report: "Coupons report",
	ecoupon_report_sub: "Activated coupons report",
	total_sent_coupons: "Total\nsent",
	percent_usage_coupons: "Percent usage",
	usage: "Usage",
	reward: "Reward",
	discount: "Discount",
	wel: "Welcome",
	promotion: "Promotion",
	ecoupon_status: "Coupons status",
	attached_receipt: "attached",
	birthmonth: "Birth month",
	specific_member: "Specific member",
	info_report_coupon_description:
		"Coupon report is checking usage of coupon that business create and press send to customer. This report will show coupon that your business has activated. Which able to view in according to the desired time.",
	info_report_coupon_detail: [
		{ title: "Date range", detail: "Period of coupon usage." },
		{ title: "Coupons name", detail: "Coupon name created by business." },
		{
			title: "Coupons category",
			detail:
				"Show coupon category specified by the administrator, will show are promotions coupon, receipt coupon or welcome coupon.",
		},
		{ title: "Coupons type", detail: "Show coupon type that are rewards or discount." },
		{
			title: "Target",
			detail:
				"Show target group of sent coupon that are all members, birth month members, business members or members in RFM group.",
		},
		{ title: "Total activated", detail: "Total of activated coupon by admin according to selected time." },
	],

	// Business
	business_infomation: "Business information",
	business_code: "Business Code",
	business_logo: "Business logo",
	business_name: "Business name",
	business_cover: "Business cover",
	business_color: "Business color",
	business_example: "Example customer screen",
	business_card_display: "Business card display",
	business_description: "Business description",
	business_link: "Business link",
	convertion_ratio: "Convertion ratio",
	convertion_discount_ratio: "Discount conversion ratio",
	set_ratio_convert: "Set the conversion ratio",
	set_ratio_convert_amount_points: "Convert from amount to points.",
	set_ratio_convert_points_amount: "Convert from points to discount.",
	set_only_per_one: "Ratio of 1 : X or X : 1 only",
	you_can_use_points_discount_rshop: "You can use point as a discount at",
	get_poster: "Get poster",
	bath: "Baht",
	busines_creating: "Business creating...",
	busines_wallet_creating: "Waiting for wallet initialize....",

	// Rewards
	reward_image: "Reward image",
	add_reward: "Add reward",
	title: "Title",
	description: "Description",
	duration_redeem: "Redeem duration",
	delete_reward_confirm: "Do you want to delete this rewards ?",
	points_to_redeem: "Points redeem",
	create_reward_successfully: "Created reward successfully",
	edited_reward_successfully: "Edited reward successfully",
	deleted_reward_successfully: "Deleted reward successfully",
	start_on: "Start on",
	error: "Error",
	set_life_rewards: "Set the life time after redeemable rewards.",
	set_limit_rewards: "Limiting the amount of redemption rewards.",
	redemption_per_member: "Redemption per member",
	redemption_per_member_unit: "redemption",
	redemption_limit_all: "Redemption limit",
	redemption: "redemption",
	show_remain_rewards: "Show the remaining rewards.",
	less_than: "Less than",
	case_not_show_remian: 'In case of not specifying the amount, the word "limited quantity" will be displayed.',
	balance: "Balance",
	amount_rewards: "Amount\nRewards",
	infinity_amount: "Unlimited",
	member_view: "member view",
	reward_expired: "Expired",
	deleted: "Deleted",
	expired_after_receiving_reward: "Expire after redeem",
	rewards_list: "Redeemable reward list",
	rewards_redeem_list: "Redeem reward list",
	rewards_receiving: "Redeemable reward receiving",
	spot_redemption: "Spot Redemption",
	by_self: "Receive by self",
	by_delivery: "Receive by delivery",
	both_self_delivery: "Receive by self or delivery",
	use_by_delivey: "by delivery",
	prepare_reward: "Preparing reward",
	prepare: "Prepare",
	delivery: "Delivery",
	delivered: "Delivered",
	address_delivery: "Delivery address",
	address_delivery_not_found: "Delivery address not found.",
	delivery_status: "Delivery status",
	guide: "Guide",
	change_delivery_status_guide: "Select the delivery status filter to change delivery status.",
	change_status: "Change coupon status",
	change_delivery_status: "Change delivery status",
	amount_coupon_change: "Total number of coupons being changed status",
	update_status_success: "Status updated successfully.",
	reward_link_access: "Link to show the redeemable reward",
	reward_link_line_liff: "Link to show the redeemable reward for LINE LIFF app",
	giveaway_link_access: "Link to show the giveaway reward",
	giveaway_link_line_liff: "Link to show the giveaway reward for LINE LIFF app",
	reward_create_header: "Create redeemable reward",
	reward_create_confirmation: "Create redeemable reward confirmation",
	reward_edit_header: "Edit redeemable reward",
	reward_edit_confirmation: "Edit redeemable reward confirmation",
	giveaway_edit_confirmation: "Edit giveaway reward confirmation",
	giveaway_create_header: "Create giveaway reward",
	giveaway_create_confirmation: "Create giveaway reward confirmation",
	giveaway_edit_header: "Edit giveaway reward",
	reward_not_found: "Reward coupon not found.",
	select_reward: "Select reward",
	reward_redeem_value: "Redeemable reward value",
	value_not_set: "Value is not set",

	// Terms & Conditions
	terms_and_conditions: "Terms & Conditons",
	terms_and_conditions_description: "Terms and Conditions are a set of rules",
	no_terms_and_conditions: "Not found Terms and Conditions",
	privacy_policy_description: "Privacy Policy for customer's data",
	no_privacy_policy: "Privacy Policy not found",
	invalid_policy_url: "Invalid URL",

	// Admin
	Permission_denied: "Permission denied",
	role_name_error_character: "Role names only support letters and numbers.",
	admin: "Admin",
	invite: "Invite",
	invitation: "Invitation",
	roles: "Roles",
	function: `Function`,
	permission: `Permission`,
	"user-permission/role-in-used": `Cannot delete this role because there is an admin currently using it.`,
	"user-permission/role-invited": `Cannot delete role invited list`,
	roles_have_exceeded_the_limit: `Your custom roles have exceeded the limit. It is not possible to create or change custom roles at this time.`,
	package_cannot_create: `Your package cannot create or change custom roles.`,
	create_custom_role_successfully: `Create custom role successful`,
	edit_custom_role_successfully: `Edit custom role successful`,
	create_custom_role: `Create custom role`,
	edit_custom_role: `Edit custom role`,
	confirm_create_custom_role: `Confirm create custom role`,
	confirm_edit_custom_role: `Confirm edit custom role`,
	custom_role: `Manage roles`,
	custom_role_name: `Custom role name`,
	add_role: `Click Add to create custom role`,
	delete_custom_role_confirm: `Confirm to delete this admin role`,
	duplicate_role_name: `Duplicate role name`,
	only_self_transactions: `Only self transactions`,
	title_role_list: {
		member: `Member`,
		member_card: `Member cards`,
		points: `Points`,
		coupon: `Coupon / Rewards`,
		automation: `Automation`,
		etc: `Other`,
	},
	item_role_list: {
		permission_view: {
			member: `View members`,
			"membercard-batchjob": `Create/Import members`,
			"report-birthday": `Birthday Report`,
			"report-demographic": `Demographic Report`,
			"report-friend-get-friends": `Friend Get Friends Report`,
			"report-RFM-analysis": `RFM Analysis Report`,
			"report-frequency": `Member loyalty Report`,
			"setting-member-card": `View member cards`,
			"setting-transaction-membercard": `Transaction member cards Report`,
			"points-transactions": `Transactions Points`,
			"points-send": `Send Points`,
			"points-batchjob": `Import Points`,
			"points-pull": `Pull Points`,
			"report-transaction": `Transactions Report`,
			"report-transaction-admin": `Transactions by admin Report`,
			"report-points-flow": `Points flow Report`,
			"report-product": `Product Report`,
			"setting-rewards": `View rewards`,
			"setting-ecoupon": `View coupons`,
			"transaction-redeem": `Redeem reward list`,
			"ecoupon-activate": `Activate coupon / rewards`,
			"report-rewards": `Rewards Report`,
			"report-ecoupon": `Coupons Report`,
			"report-transaction-sent": `Broadcast coupon Report`,
			"report-transaction-activated": `activated coupon Report`,
			"automation-member-card": `View setting automation`,
			"report-dashboard": `Dashboard Report`,
			"report-review": `Satisfaction & Review Report`,
			"report-admin": `Admin Report`,
			newsfeed: `Activities`,
			"broadcast-giftvoucher": `Broadcast list`,
		},
		permission_edit: {
			member_name: `Edit members`,
			"setting-member-card": `Create/Edit member cards`,
			"setting-rewards": `Create/Edit rewards`,
			"setting-ecoupon": `Create/Edit coupons`,
			"setting-business": `Settings`,
			"broadcast-promotion": `Broadcast message`,
			"restrict-admin-report-owned": `Only self transactions`,
		},
		permission_create: {
			member_label: `Add labels to members`,
			"setting-transaction-membercard": `Issue/Change member cards`,
			"points-request": `Collect Points`,
			"broadcast-giftvoucher": `Send Gift Voucher`,
			"automation-member-card": `Setting automation`,
			"setting-admin": `Setting admin`,
		},
		permission_delete: {
			newsfeed: `Package management`,
		},
		permission_export: {
			member: `Export data`,
		},
	},
	admin_report_owned: {
		description_1: `Transactions`,
		description_2: `Sent Coupons Report`,
		description_3: `Activated coupons Report`,
		description_4: `Transactions by admin Report`,
		description_5: `Admin Report`,
	},
	role_transactions_history: "Transactions history",
	role_transactions_history_access: "Transactions history access",
	role_restrict_specific_menus: "Assign this role to view the transaction history as follows.",
	role_description: "Role description",
	admin_list: "Admin list",
	invitation_list: "Invitation list",
	email_already_exist: " Email is already exist",
	no_invitation: "No invitation list",
	delect_admin_confirm: "Confirm to delete this admin list",
	delect_invitation_confirm: "Confirm to delete this invitation list",
	send_invite: "Send invite",
	trans_limit: "Transfer limit",
	trans_limit_x_point: "Transfer limit: $1",
	manual_admin: "Administrator Manual",

	// Batch Jobs
	import: "Import",
	order_no: "No.",
	job_id: "Job ID",
	batchJobs: "Import points",
	batchJobsMemberCard: "Import member",
	step_batchJobs: "Step import points",
	addJob: "Create / import job",
	import_type: "Import type",
	choose_import_type: "Choose import type",
	choose_file_ex: "Choose a example import file.",
	last_modified: "Last modified",
	status: "Status",
	receiver: "Receiver",
	list: "List",
	record: "Record(s)",
	modified_on: "Modified on",
	modified_by: "Modified by",
	on: "on",
	add: "Add",
	example_CSV: "Example import points in CSV format",
	download: "Download",
	select_column: "Select column",
	send_sms_text_messages: "Notification are only sent between {0}",
	only: "only",
	total_job: "Total records",
	success_job: "Success records",
	failure_job: "Failure records",
	successful: "Successful",
	failed: "Failed",
	partial_failure: "Partial failure",
	in_progress: "In Progress",
	stoped: "Wait",
	start_job: "Start job",
	cancel_job: "Cancel job",
	upload: "Upload file",
	select_file_upload: "Select file",
	download_from: "Download from example",
	download_example: "Download example file",
	show_allData: "Show all data",
	incorrect_data: "Incorrect data ",
	correct_data: "Correct data ",
	job_points: "Points",
	job_receiver: "Receiver",
	job_remark: "Remark",
	browse_file: "Browse file",
	file_error_larger_size: "File had larger than 5 MB",
	file_error_wrong_type: "File had wrong type",
	file_error_empty: "File had empty",
	file_error_data: "Data had more than {0} records",
	file_error_data_200: "Data had more than 200 ",
	file_error: "File error",
	file_error_format: "Data had invalid format",
	file_error_import_point_type: "File doesn't match the import type",
	limit_file_size: "Limit file size to 5 mb",
	limit_import_records: "Limit record to {0} records per job",
	cron_job_error: {
		"parmas/request-points-params": "Invalid number of points.",
		"parmas/points-params-not-match": "Invalid points format",
		"points/request-points": "Invalid number of points.",
		"points/limit_poitns-transfer": "Send points over limit transfer.",
		"member/request-member-profile": "Member not found",
		"member/already-has-card": "Receiver already has member card",
		"cronjob/transfer-points-internal-error": "An error has occurred. Send points failed",
		"cronjob/import-member-card-internal-error": "An error has occurred. Create member card failed",
		"member/user-already-exist": "User already exist",
	},
	import_receiver_invalid: "Invalid receiver.",
	import_point_invalid: "Invalid points format.",
	import_amount_invalid: "Invalid amount format.",
	import_firstname_invalid: "First name cannot be more than 50 characters.",
	import_lastname_invalid: "Last name cannot be more than 50 characters.",
	import_gender_invalid: 'Gender can be one of "male" "female" "unspecified".',
	import_date_of_birth_invalid: "Valid format of Date Of Birth is DD-MM-YYYY.",
	age_range_invalid: "Supports members aged 13 - 100 years.",
	import_email_invalid: "Invalid email.",
	import_member_card_invalid: "Member card does not exist",
	incorrect_sku: "Incorrect SKU",
	incorrect_receipt_no: "Incorrect Receipt No.",
	import_member_card: "Import Member Card",
	import_initial_point: "Import Initial Points",
	import_initial_member_card_upload_type: "Member Card",
	import_initial_point_upload_type: "Initial Points",
	modal_title_incomplete_import_data: "Incomplete import data",
	modal_detail_please_verify: "Please recheck and correct, the system can import only the correct data.",
	modal_btn_download: "Download Incorrect Data",
	modal_btn_cancel: "Cancel",
	modal_btn_continue: "Continue",
	open_member_card: "Please create a member card before continuing. ",
	click_open_member_card: "Click here to open member card settings.",
	product_point: "** The total points are calculated by points from each product in the same receipt.",
	import_confirm: "Import confirmation",
	// Coupon
	ecoupon: "Coupons",
	ecoupon_image: "Coupon image",
	add_ecoupon: "Add Promotional coupon",
	create_and_send_coupon: "Create and Send Coupon",
	name_ecoupon: "Coupon name",
	name_ecoupons: "Coupons name",
	name_ecoupon_placeholder: "Ex. discount 25% for product or services",
	type_ecoupon: "Coupon type",
	type_ecoupons: "Coupons type",
	coupons_category: "Coupons category",
	rewards_category: "Rewards category",
	description_ecoupon: "Coupon description",
	ecoupon_free: "Reward",
	ecoupon_discount: "Discount",
	ecoupon_givePoint: "Give points",
	note: "Note",
	target: "Target",
	target_groups: "Target groups",
	all_members: "All members",
	rfm_members: "RFM group",
	rfm_groups: "RFM groups",
	rfm_groups_6m: "Last 6 months",
	rfm_groups_12m: "Last 12 months",
	member_card_groups: "Member card",
	birth_month_members: "Birth month",
	each_birth_month_members: "Member born in each month",
	member_business: "Specific members",
	select_birth_month_members: "Select birth month",
	select_rfm_group: "Select RFM groups",
	time: "Time",
	duration_usage: "Usage duration",
	schedule_of_usage: "Specific period",
	ecoupon_all: "Sended",
	coupon_promotion: "Promotional coupons",
	coupon_promotion_create: "Create promotional coupon",
	coupon: "Promotional coupons",
	coupon_send_sms_text: "Send notifications ",
	repeat: "Repeat",
	duplicate: "Duplicate",
	create_coupon_successfully: "Create coupon successfully",
	edited_coupon_successfully: "Edited coupon successfully",
	deleted_coupon_successfully: "Deleted coupon successfully",
	ecoupon_will_incur_costs: "Sending these promotional coupon will be calculated according to the package used.",
	ecoupon_confrim_send: "Are you want to send promotional coupon ?",
	ecoupon_costs_approximately: "Sending promotional coupons is charged according to the package used.",
	ecoupon_costs_approximately_transaction: "Send the coupon is a total of $1 transactions.",
	not_member_in_target_group: "Not found members in the selected target group.\nPlease select target group again.",
	confirm_delete_coupon: "Do you want to delete this coupon ?",
	ecoupon_automation_setting: "Coupon Automation",
	ecoupon_automation_setting_description: "Create a campaign to automatically send coupons to your target audience.",
	ecoupon_automation_add_campaign: "Add campaign",
	campaign: "Campaign",
	campaign_frequency: "Trigger",
	campaign_frequency_daily: "Everyday",
	campaign_frequency_monthly: "On the first day of every month",
	campaign_frequency_new_members: "On subscription of new members",
	campaign_create_success: "Create campaign successfully",
	campaign_edit_success: "Edit campaign successfully",
	campaign_delete_success: "Delete campaign successfully",
	campaign_confirm_delete: "Confirm to delete this campaign.",
	campaign_editor_create: "Create campaign",
	campaign_editor_edit: "Edit campaign",
	campaign_period: "Campaign duration",
	campaign_coupon_sent: "Sent",
	campaign_coupon_activated: "Activated",
	campaign_coupon_not_activated: "Not activated",
	campaign_coupon_usage: "Usage",
	campaign_title: "Campaign name",
	campaign_duration: "Campaign duration",
	campaign_coupon_duration_warning: "The duration of coupon usage is not related to the campaign duration.",
	campaign_coupon_duration: "Coupon usage duration",
	campaign_coupon_target: "Target",
	campaign_coupon_target_each_month: "Send coupon to members born in each month during the campaign period.",
	campaign_use_coupon_setting: "Based on coupon's settings",
	campaign_edit_coupon_warning:
		'This coupon has been used in the "{0}" campaign. \nModifications to the time period may result in members not receiving the coupon.',
	campaign_expired: "Expired campaign",
	campaign_please_select_target_group_again: "Please select target group again.",
	campaign_trigger_description: "Everyday and on the first day of every month trigger, coupons will be sent at 9:00 AM.",

	automation_coupon_title: "Coupon Automation",
	automation_coupon_description: "Create a campaign to automatically send coupons to your target audience.",

	// Welcome Coupon
	coupon_welcome: "Welcome coupons",
	wel_coupon: "Welcome coupons",
	coupon_welcome_image: "Welcome coupon image",
	add_welcome_ecoupon: "Add Welcome coupon",
	name_welcome_ecoupon: "Welcome coupon name",
	type_welcome_ecoupon: "Welcome coupon type",
	description_welcome_ecoupon: "Welcome coupon description",
	create_welcoupon_successfully: "Created welcome coupon successfully",
	edited_welcoupon_successfully: "Edited welcome coupon successfully",
	deleted_welcoupon_successfully: "Deleted welcome coupon successfully",

	//Verify Business
	verify_process: "Activate Business Process",
	verify_establishment: "Establishment",
	verify_business_product_type: "Business and Product type",
	verify_business_type: "Business type",
	verify_mobile_phone: "Mobile phone",
	verify_credit_card: "Credit card",
	add_detail: "Add detail",
	edit_detail: "Edit detail",
	add_detail_success: "success",
	rejected: "Rejected",
	confirm_details: "Confirm establishment details",
	confirm_type: "Confirm business and product type",
	confirm_phone: "Confirm mobile phone",
	confirm_card: "Confirm credit card",
	confirm_sub: " of",
	juristic_person: "Juristic",
	ordinary_person: "Ordinary",
	verify_business: "Activate business",
	submit_detail: "Submit detail",
	submit_type: "Submit type",
	submit_phone: "Submit phone",
	submit_card: "Submit card",
	verify_detail_success: "Verify establishment detail successful",
	verify_type_success: "Verify business type successful",
	verify_phone_success: "Verify mobile phone successful",
	verify_card_success: "Verify credit card successful",
	verify_detail_fail: "Verify establishment detail fail",
	verify_type_fail: "Verify business type fail",
	verify_phone_fail: "Verify mobile phone fail",
	verify_card_fail: "Verify credit card fail",
	request_verify_business: "Submit verify",
	company_name: "Company name / Legal entity",
	recept_name: "Company name / Legal entity / recipient name",
	commercial_businesses_name: "Commercial businesses name",
	name_of_director: "Name of director",
	owner_name: "Owner name",
	id: "Identification No.",
	tax_id: "Taxpayer Identification No.",
	receipt_tax_id: "Taxpayer Identification No. / Identification No.",
	business_address: "Business Address",
	address_line1: "No, Village No, Village, Lane",
	address_line2: "Road, Sub-district",
	map_position: "Location",
	map_description: "Move the map to the desired location and click to set the position on the map.",
	copy_of_IDcard: "Copy of ID card",
	copyof_company_certification: "Copy of company certification",
	business_registration: "Business registration",
	commercial_registration: "Commercial registration",
	photo_shop: "Photo in front of the shop",
	attached_file: "Attached file",
	choose_registration_type: "Choose your business registration type",
	choose_business_type: "Choose your business type",
	choose_business_type_sub: "Search and select your business type at least 1 item, but no more than 3 items.",
	business_choosed: "Your business type",
	choose_product_type: "Choose your product type",
	choose_product_type_sub: "Search and select your product type at least 1 item, but no more than 3 items.",
	product_choosed: "Your product type",
	search_business: "Search your business type",
	search_product: "Search your product type",
	no_result: "No result",
	phone_mobile: "Mobile phone",
	input_phone: "Enter the phone number to receive the OTP code.",
	request_otp: "Request OTP",
	resend_otp: "Resend in",
	verify_otp: "Verify OTP",
	input_otp: "Enter the OTP code",
	error_otp: "Error in the verify OTP code. Please try again.",
	"oauth2/invalid-phone-number": "Mobile number incorrect.",
	"oauth2/argument-phone-error": "Please enter mobile number.",
	"oauth2/argument-otp-error": "Please enter OTP",
	"oauth2/invalid-otp": "OTP incorrect, please try again.",
	"oauth2/otp-limit-exceeded": "Request OTP is limit exceeded,\nplease try again in $1 minutes.",
	"oauth2/operation-error": ", Please contact admin, System error occur.",
	"oauth2/invalid-reference": "OTP expired, please resend OTP again.",
	"oauth2/reference-expired": "OTP expired, please try again.",
	"oauth2/otp-too-many-retry": "OTP entered is incorrect too many retry,\nplease resend OTP again.",
	your_business_type: "Your business type",
	wait_verify: "Please complete all the details to Activate the business",
	pending_verify: "Business activation pending",
	fail_verify: "Business activation failed",
	success_verify: "Business activation successful",
	verification_business: "Business Activation",
	get_verified_business: "Get your activate business",
	send_point_unlimited: "Send points according to the package used",
	import_point_verified: "Can use import points.",
	warning: "Warning",
	submit_verify_business: "Do you want to submit the activate business",
	submit_verify_business_detail_1: 'When you "Submit" the system will verify your information',
	submit_verify_business_detail_2: "then you cannot change your business data.",
	warning_verify_send_points: "Your business wait for verify confirmation. You can send only $1 times ",
	total_send_points: "sent total",
	total_more_send_points: "Send more than",
	refCode: "Ref Code",
	your_verifyphone: "Mobile phone number, You used to verify business",
	your_verifycard: "Credit card, You used to verify business",
	foreign_pay_detail:
		"In case pay for the service with a debit card, please contact the bank to approve paying in foreign country.",
	card_verification_remark:
		"Pointspot will make a one-time test charge of 1 Baht when adding your payment card. The transaction will be voided after charged successfully.",
	mobile_invalid: "Mobile number incorrect.",

	verifyprocess: [
		{
			id: "BusinessDetail",
			detail: "1. Confirm establishment details",
		},
		{
			id: "BusinessTypeModal",
			detail: "2. Confirm business type ",
		},
		{
			id: "PhoneModal",
			detail: "3. Confirm mobile phone",
		},
		{
			id: "CreditCardModal",
			detail: "4. Confirm credit card",
		},
	],
	conditionVerify: {
		accept: {
			title: "Business Activation and Approval Criterias",
			description: "Confirmation must be completed in accordance with 3 steps of activation as follows",
			items: [
				{ title: `Confirm of establishment details Which details of the establishment must correct information.` },
				{ title: `Confirm of the business type that is currently sold or serviced.` },
				{ title: `Confirm of the phone number belonging to the real owner and through the OTP code confirmation.` },
			],
		},
		reject: {
			title: "Businesses in the following groups are not accepted",
			description: "As they may be against the law regarding advertising and sales promotion activities",
			items: [
				{ title: `Liquor, beer, alcoholic beverages, entertainment businesses.` },
				{
					title: `Financial business such as digital money (BitCoin), quick cash, informal and illegal loans, online games refill cards, etc.`,
				},
				{ title: `Medicine and medical supplies business.` },
				{ title: `Cosmetic business, there is cosmetic with that boasts exaggerated properties and not registered.` },
			],
		},
	},
	cookie_consent: `We uses cookies to improve user experience.`,
	terms_and_con: {
		title: "Note",
		items: [
			{
				terms_and_con_detail:
					"1. If we verify the information of the customer and we find that it does not according to terms and conditions, The company will be reject your business.",
			},
			// { terms_and_con_detail: '2. Credit card verification process, customers will not be charged until the package is applied.' }
		],
	},
	credit_non_charge: "Customers will not be charged until the package is applied.",
	scan_qrcode_send_point: "Scan QR Code\nGet member Pointspot ID",

	//Business Contact
	business_contact: "Contact",
	website: "Website",

	//Business Subscribe
	dailymail_summary: "Dailymail summary",
	dailymail_summary_detail: "Send or Unsend email display dailymail summary",
	something_wrong: "Problem exist",

	//Business Review
	report_satisfaction: "Satisfaction",
	report_review: "Review",
	satisfaction: "Satisfaction",
	satisfaction_score: "Satisfaction score",
	satisfaction_summary: "Satisfaction",
	satisfaction_summary_in_business: "Satisfaction summary in business",
	reviews: "reviews",
	non_review: "No review",
	comment: "Reviews",
	comment_on: "Reviewed on",
	comment_summary: "Reviews summary",
	comment_summary_in_business: "Reviews summary in business",
	comments_only: "Reviews only",
	profile_member: "Member profile",
	satisfaction_comment_summary: "Satisfaction and reviews summary",
	satisfaction_comment_summary_report: "Satisfaction and reviews summary report",
	subscribe_at: "You can subscribe at",
	review: "Review",
	review_only: "Review only",
	score: "Score",

	// Expiry points
	expiry_points: "Points expiry",
	setting_expiry_points: "Points expiry",
	setting_expiry_points_noti: "Points Expiration Notification",
	setting_expiry_points_setting: "Setting the expiry date of points in the Business",
	select_expiration_of_point: "Select expiration of points",
	noti_expiration_of_point: "Points Expiration Notification",
	non_noti_expiry_points: "Do not send notification",
	use_noti_expiry_points: "Advance notification",
	use_noti_expiry_points_detail:
		"Make points expiration notifications in advance, from the date the points will expire (not more than 30 days)." +
		"\nFor example, point expire on last day of $1 . Set advance notification for 10 days, members will get notification on $2.",
	noti_expiry_points_note:
		"Setting the points expiration notification will be charged for the number of notification from actual usage.",
	select_number_day: "Select number days (1-30)",
	non_setting_expiry_points: "No expiry",
	non_setting_expiry_points_detail: "The points no expiration date.",
	month_setting_expiry_points: "Monthly expiry",
	month_setting_expiry_points_detail:
		"The points will be pull back in the last of each month. From the month of receiving points." +
		"\nFor example, Send points in month $1. The points have lifetime $2 month and the point will be expired in the last day of $3",
	select_number_month: "Select number month",
	year_setting_expiry_points: "Yearly setting points expiration",
	year_setting_expiry_points_detail:
		"The points will be pull back in the last of each year. From the year of receiving points." +
		"\nFor example, Send points in year $1. The points have lifetime $2 year and the point will be expired in the last day of $3",
	select_number_year: "Select number year",
	custom_setting_expiry_points: "Custom setting points expiration",
	custom_setting_expiry_points_detail:
		"The points will be pull back in the last of each month and year indicated." +
		"\nFor example, the point will be expired in the last day of $1",
	select_month_year: "Select month / year",
	expiry_points_note: "Setting the expiry date of points can choose only once.",
	expiry_confirm: "Are you want to change points expiry?",
	expiry_noti_confirm: "Are you want to change points expiration notification?",
	points_expiration_noti: "Points Expiration Notification",

	//Archive Business
	archive_business_menu: "Archive",
	archive_business_title: "Archive business",
	archive_warning: {
		title: "By deleting your business, you agree to: ",
		lists: ["Member cannot see your business.", "Disable all member subscribtion.", "Delete business from Pointspot."],
		confirm_message1: "Please enter the business code to confirm archive of the business.",
		confirm_message2: "Please enter the business code to confirm OTP code.",
	},
	archive_with_trial_process: "Just try it out and will apply later",
	archive_with_not_looking_for: "Not what is looking for",
	archive_with_no_feature_need: "No features needed",
	archive_with_no_support: "No support from staff",
	tell_us: "Tell us why you want to delete a business. To help improve the platform",
	otp_confirm_archive: "Confirm the OTP to delete the business.",
	otp_sendTo_mobile: "OTP code is sent to the phone number that used to verify the business.",

	//Package
	package: "Package",
	title_free_package: "FREE",
	title_shappy_package: "SHAPPY+",
	title_free_trial_package: "FREE",
	title_pro_package: "PRO",
	title_premium_package: "PREMIUM",
	title_gold_package: "GOLD",
	title_platinum_package: "T15",
	title_diamond_package: "DIAMOND",
	title_enterprise_package: "ENTERPRISE",
	title_max_package: "T30",
	title_test_package: "TEST",
	title_f32_package: "F32",
	title_starter_package: "Starter",
	title_advance_package: "Professional",
	title_annual_l_package: "Enterprise",
	title_pro_a_package: "Pro-A",
	title_premium_a_package: "Premium-A",
	title_f200_package: "F200",

	pricing_sale_until: "Pricing until",
	pricing_package: "Pricing package",
	unlimited: "Unlimited",
	current_usage_package: "Current package",
	monthly_fee_package: "MONTHLY FEE",
	yearly_fee_package: "YEARLY FEE",
	member_package: "MEMBER",
	member_unit: "Member",
	monthly_free_credit: "MONTHLY FREE CREDIT",
	monthly_free_credits: "Monthly Free Credit",
	add_ons_credit: "Add-ons Credit",
	member_usage_description: '"Members" refers to users who registered and have received a membership card or points.',
	transaction_package: "TRANSACTION",
	transaction_unit: "Transaction",
	transaction_usage_description:
		"Transaction means the amount of activity from admin to client about point (sending, pulling), sending coupon or create member card.",
	transaction_annual_usage_description:
		"Transaction include activities such as sending or pulling points, issuing member card, issuing promotional coupons, issuing receipt coupons, sending gift vouchers, sending broadcasts, rejected collect points notification, points expiration notification and member card expiration notification.",
	transaction_yearly_usage_description:
		'"Basic Transactions" include activities such as sending or pulling points, and creating member cards.',
	transaction_yearly_credit_usage_description:
		'"Credit usage" include activities such as sending broadcasts, issuing promotional coupons, issuing receipt coupons, sending gift vouchers, and transactions that exceed the package limit.',
	chunk_description: "Chunk means the subunit of SMS.",
	feature_package: "Additional features",
	add_on_package: "Add-ons",
	choose_package: "Choose a package",
	prices_vat: "7% VAT  is not included.",
	package_upgrade: "Upgrade",
	change_package: "Choose",
	request_change_package: "Change package",
	currency: {
		thb: "THB",
	},
	upgrade_subscription_successfully: "Upgrade successfully",
	cancle_subscription_successfully: "Unsubscription successfully",
	name_unit: "Name",
	notification_package: "Notification",
	additional_sms: "SMS",
	additional_line_noti: "LINE",
	message_unit: "MESSAGE",
	update_payment_info_successfully: "Update info successfully",
	notification_usage_description: "Only the overage notification message is charged.",
	chunk_unit: "Chunk",
	chunk: "Chunk",

	//Package FREE
	per_month: "PER MONTH",
	per_year: "PER YEAR",
	free: "Free",
	package_free: "Free",
	free_up_to: "FREE UP TO",
	transaction_month: "TRANSACTIONS / MONTH",
	transaction_over_quota: "Not enough remaining transaction.",

	//Package BASIC
	package_basic: "Basic",

	//Package PRO
	package_pro: "Pro",
	first_members: "First Member {0}",
	members_onward: "{0} Members Onward",
	per_member_month: "PER MEMBER / MONTH",
	first_transaction: "First Transaction {0}",
	transaction_onward: "{0} Transactions Onward ",
	per_transaction_month: "PER TRANSACTION / MONTH",
	package_pro_start: "Use on July 1, 2019",
	payment_method: "Payment Method",
	show_detail: "Show detail",
	package_features: {
		premium_id: "Premium ID Ex. @yourbrand",
		transfer_points_to_friend: "Transfer points to friend",
		promotional_coupons: "Promotional coupons",
		attached_coupons: "Attached coupons",
		member_loyalty: "Member loyalty",
		transaction_history_seperated_by_admin: "Transaction history seperated by admin",
		lucky_wheel: "Wheel Game add points",
		custom_sender: "Custom sender name",
		gift_voucher: "Gift Voucher",
		broadcast_message: "Broadcast message",
		product_level_points: "Product-level points",
		product_management: "Product-level points and products report",
		automation_coupon: "Automation coupon",
		automation_member_card: "Automation member card",
		broadcast_line_flex: "Broadcast image",
		custom_role: "Custom admin roles",
		custom_form: "Registration Form",
		advance_points_expire_notification: "Points expiration notification",
		advance_member_card_expire_notification: "Member card expiration notification",
		member_card_level: "Member card type",
	},
	package_items: {
		overage_basic_transaction: "Overage Basic Transactions",
		monthly_fee_package: "Monthly fee",
		member_package: "Members",
		transaction_package: "Transactions",
		broadcast_message: "Broadcast message",
		premium_id: "Premium ID",
		sender_name: "Sender name",
		product_level_points: "Product-level points",
		additional_transaction: "Transactions",
		additional_sms: "Notification - SMS",
		additional_email: "Notification - EMAIL",
		additional_shappy: "Notification - SHAPPY",
		additional_line_noti: "Notification - LINE",
		additional_sms_broadcast: "Broadcast - SMS",
		additional_email_broadcast: "Broadcast - EMAIL",
		additional_line_broadcast: "Broadcast - LINE",
		additional_shappy_broadcast: "Broadcast - SHAPPY",
		additional_member: "Members",
		additional_sms_inter: "International - SMS",
		advance_apis: "Advance APIs",
		additional_coupon_transaction: "Coupon/Gift voucher",
		additional_coupon_sms: "Coupon/Gift voucher - SMS",
		additional_coupon_line: "Coupon/Gift voucher - LINE",
		additional_coupon_email: "Coupon/Gift voucher - EMAIL",
		additional_coupon_shappy: "Coupon/Gift voucher - SHAPPY",
		not_send_notification: "Send without notification",
		broadcast: "Broadcast",
		notification: "Notification",
		additional_sms_collect_points: "Points collect requests rejected notifications - SMS",
		additional_line_collect_points: "Points collect requests rejected notifications - LINE",
		additional_email_collect_points: "Points collect requests rejected notifications - EMAIL",
		additional_shappy_collect_points: "Points collect requests rejected notifications - SHAPPY",
		points_collect_requests_rejected: "Points collect requests rejected notifications.",
		advance_points_expiration_notifications: "Advance points expiration notifications.",
		additional_sms_points_expiration: "Advance points expiration notifications - SMS",
		additional_line_points_expiration: "Advance points expiration notifications - LINE",
		additional_email_points_expiration: "Advance points expiration notifications - EMAIL",
		additional_shappy_points_expiration: "Advance points expiration notifications - SHAPPY",
	},
	package_items_unit: {
		transaction_unit: "Transaction",
		name_unit: "Transaction",
		month: "Month",
		member_unit: "Member",
	},
	charge_automatically: "Charge automatically",
	send_invoice: "Send invoice",
	usage_history: "Usage history",
	credit_purchase_history: "Credit purchase history",

	// Credit Card
	you_want_delete_credit_card: "Do you want to delete card ?",
	credit_card_number: "Card Number",
	add_credit_card: "Add Card",
	when_doing: "When doing",
	you_understand_and_accept: "You understand and accept",
	cardholder_name: "Cardholder Name",
	expiration_month: "Expiration month",
	expiration_year: "Expiration year",
	delete_credit_card: "Delete card",
	create_card_error_message: "An error occurred while processing your card. Try again in a little bit.",
	card_primary: "Primary",
	card_mark_primary: "Mark Primary",

	//API Token
	credential_title: "API Credentials",
	credential_detail: "Create credentials to access your API.",
	btn_create_credential: "Create Credential",
	credential_params: {
		clientid: "Client ID",
		create_date: "Create date",
		seen_date: "Last seen date",
		device_os: "Create from",
	},
	use_token: "Token for API access.",
	confirm_message_revoke: "Are you want revoke this token ?",
	revoke: "Revoke",
	revoke_successfully: "Revoke successfully",
	web_restriction: "Website restriction",
	restriction_description: "Define key usage request to the website",
	restriction: "Restriction",
	all_access: `If you don't define restriction\nyour API will accept requests from any websites.`,

	//Unsubscribe Confirm
	unsubscribe_confirm: {
		card_title: "Unsubscribe confirm",
		email_confirm: 'You have successfully "Dailymail summary" unsubscribed.',
		review_message_confirm: 'You have successfully "Dailymail summary" unsubscribed.',
	},

	// payment contact
	add_contact: "Add Contact",
	contact_invoice: "Contact Invoice",
	contact_receipt: "Contact Receipt",
	address: "Address",
	address_number: "Number",
	village_number: "Village No.",
	village: "Village",
	lane: "Lane",
	road: "Road",
	district: "District",
	city: "City",
	state: "State",
	postal_code: "Postal Code",
	country: "Country",
	_sub_district: "Sub-District",
	_district: "District",
	province: "Province",
	please_fill_detail_english: "Please fill up the details only in English",
	please_fill_information: "Please fill in the information",
	please_fill_information_english: "Please fill in English",
	please_fill_information_number: "Please fill in Number",
	please_fill_information_format_phone: "Please fill in Phone",

	//payment method
	add_payment_method: "Add Payment method",
	credit_card: "Credit card",
	change_credit_card: "Change credit card",
	change_credit_card_success: "Change credit card successfully",
	change_credit_card_fail: "Change credit card failed",
	debit_card: "Debit Card",
	select: "Select",
	payment_failed: "Payment failed. Please try again.",

	// subscription
	packages: "Packages",
	package_and_charge: "Package and Charge",
	package_title: "Packages",
	subscription_package: "Subscription package",
	unsubscription_package: "Unsubscription package",
	change_subscribed_package: "Change package",
	sub_total: "Sub total",
	vat: "VAT",
	monthly_fee: "Monthly fee",
	annual_fee: "Annual fee",
	total_balance: "Net Total",
	current_balance_pending: "The current balance during billing cycle",
	usage_summary: "Usage summary of",
	example_invoice: "Example: Invoice ",
	trial: "Trial",
	might_be_changed: "It may change if the subscription is updated.",
	period_end: "Period end",
	usage_detail: "Usage Detail",
	data_on: "Data as at",
	unsubscription_package_confirm:
		"Are you want to unsubscription package ?\nUsage fee will be charged after unsubscription the package.",
	unsubscription_package_payment_failed: "You can not unsubscription package. Because, payment failed.",
	unable_show_packages: "Unable to show package details.",
	addons: "Add-ons",
	add_subscription_addons: "Adding add-ons",
	premium_id: "Premium ID",
	premium_id_description: "Can change the business link to show as the specified name",
	broadcast_addons: "Broadcast information",
	broadcast_message_description: "Can broadcast the information to your customers.",
	broadcast_transaction_usage:
		"The amount of broadcast transaction means SMS 3 chunks/transaction or LINE 1 message/transaction.",
	broadcast_transaction_limit_100: "Maximum 100 transactions.",
	billing_overage: "Overage",
	billing_item: "Items",
	billing_quantity: "Quantity",
	billing_unit_price: "Unit Price",
	billing_unit_credit: "Credit per unit",
	billing_amount: "Amount",
	subscription_coupons: {
		internal_account: "Internal Account",
		system_change_package: "System Change Package",
		special_discount: "Special Discount",
		first_month_25_discount: "First month upgrade 25% discount",
		first_month_50_discount: "First month upgrade 50% discount",
		first_month_75_discount: "First month upgrade 75% discount",
		covid_discount: "Covid discount",
		trial_package: "Trial package",
	},
	credit_use: "Used credit",
	credit_not_enough: "Your credits is not enough",
	credit_low: "Your credit is running low. Please add credits for continuous usage.",
	transactions_low: "Your transaction usage is nearing the limit of your package. Please add credits for continuous usage.",
	purchase_credit: "Purchase Credits",
	contact_upgrade: "To upgrade your package,",
	contact_purchase: "Buy credit,",
	contact_call: "please contact our staff.",
	contact_staff: "Contact staff",
	contact_number: "Call 02-016-6900",
	usage_report: "Pointspot Usage report",

	// usage record
	temporary_out_of_service: "Temporarily out of service",
	temporary_exceeding_quotas: "The usage are going to exceed the quota.",
	usage_in: "Usage in",
	member_usage: "Member usage",
	transaction_usage: "Transactions usage",
	over_usage: "Exceeding quota",
	over_usage_plz_upgrade: "Exceeding quota. Please upgrade package.",

	// Business Poster
	business_poster: "Business poster",
	poster_description: "Apply for membership to collect point and get privilege.",
	scan_qrcode: `Scan QR Code`,
	scan_qrcode_description: `For register and receive the rewards. \n Free registration and easy to use \n with phone number.`,
	goto_website: `Go to website`,
	supports_working_on_computer: "Supports working on a Computer only",

	// Attached Coupon
	receipt_coupon: "Attached coupons",
	receipt_coupons: "Attached coupons",
	attached_coupon: "Attached coupons",
	create_attached_coupon: "Create attached coupon",
	select_coupon: "Select coupon",
	edit_coupon: "Edit coupon",
	select_attached_coupon: "Select attached coupon",
	redeem_rewards: "Redeem rewards",
	expired_coupon: "Expired coupon",
	"pointspot/ecard-expire": "Expired coupon",
	"pointspot/ecard-cancel": "Canceled coupon",
	"pointspot/points-not-enough": "Point not enough",
	expired_after_receiving_coupon: "Expired after receiving",
	after_receiving_coupon: "After receiving coupon",
	set_expired_date_after_receiving_coupon: "Number of days",
	attached_coupon_name: "Attached coupon name",
	attached_coupon_description: "Attached coupon description",
	attached_coupon_type: "Attached coupon type",
	attached_coupon_image: "Attached coupon image",
	add_attached_coupon: "Add attached coupon",
	create_attached_coupon_successfully: "Create attached coupon successfully",
	edit_attached_coupon_successfully: "Edit attached coupon successfully",
	delete_attached_coupon_successfully: "Delete attached coupon successfully",
	want_to_delete_attached_coupon: "Are you want to delete attached coupon ?",
	attached_coupon_not_found: "Attached coupon not found",

	//ReportUsageAdmin
	report_admin: "Transactions by admin",
	report_usage_admin: "Administrator transactions report",
	report_usage_admin_sub: "Administrator transactions report",
	transaction_date: "Transactions date",
	admin_name: "Admin name",
	total_transactions: "Total transactions",
	error_occurred: "An error has occurred.",
	please_enter_redeemcode: "Please enter redeem code.",
	not_found: "Not found",
	export_data: "Export data",
	export_data_all: "Export all data",
	account_admin: "Admin",
	account_pincode: "PIN CODE",
	account_origin: "Domain name",
	account_credential: "API Credential ID",
	account_automation: "Automation",

	//ReportAdmin
	admin_report: "Admin report",
	admin_report_description: "Administrator summary report",
	registered_members: "Registered members",
	total_member: "Total member",
	total_points_send: "Points send",
	total_points_pull: "Points pull",
	member_unit_report: "Members",

	// Welcome Page
	welcome: "Starter Guide",
	home_page: "Home page",
	welcome_page: "Starter Guide",
	welcome_to: "Welcome to Pointspot",
	how_to_pointspot: "Learn how to use Pointspot",
	features_basic_pointspot: "Pointspot basic Features",
	hide_home_page: "Hide home page",
	hide_guide: "Hide this guide",
	intro_welcome:
		"New to Pointspot? This is a basic guide to help you learn how to use \nand start using with your business faster.",
	topic_send_point: "Points",
	intro_send_point: `Send points conveniently and quickly`,
	button_send: "Send Points",
	topic_reward: "Rewards",
	intro_reward: "Create a redeemable reward.",
	button_reward: "Create a redeemable reward",
	topic_contact: "Contacts",
	intro_contact: "Manage your contact info.",
	button_contact: "Contact",
	topic_poster: "Poster",
	intro_poster: "Create Pointspot poster.",
	button_poster: "Create Poster",
	verify_for_free: "Please activate business to use Pointspot.",
	intro_transaction: "Show Business Transaction.",
	intro_welcome_verified:
		"Home page this is a Features basic guide to help you learn how to use \nand start using with your business faster.",

	// Wizard
	business_brand: "Business / Brand",
	your_business_brand: "Your Business / Brand",
	enter_information_create_business: "Enter information to create business",
	please_enter_your_business: "Please enter your Business / Brand more than 5 characters",

	// Select Business
	choose_your_business: "Choose your business",
	sign_in_with_another_account: "Sign in with another account",
	verification_sent : "Enter the verification code we sent to",
	confirm_sending_otp: "OTP Authentication",
	confirm_choose_business: "To enhance security for accessing the <b>$1</b> system.",
	send_to_email: "OTP will be sent to the email <b>$1</b>.",
  verify_ref: "ref",
  verify_button: "Verify",
	send_otp: "Send OTP",
  send_email_again: "Send a new code",

	// Tutorial
	tutorial: "Tutorial",
	start_tutorial: "Start Tutorial",
	tutorial_welcome: "Welcome to Pointspot",
	tutorial_detail:
		"Congratulations! You have successfully created your business on Pointspot. Next step we would like to introduce our features and guide you how to use it to get started the business faster.",
	tutorial_step_detail: [
		{
			title: `Send points`,
			description: `You can give the points to the members or to new customers easily by going to the "Send Points" menu.`,
		},
		{
			title: `Send points`,
			description: `Enter the phone number or scan the QR Code of the member who wants to send points to.`,
		},
		{
			title: `Send points`,
			note: `The amount of points can be converted later`,
			description: `Enter the amount in order to change to points for the members.`,
		},
		{
			title: `Send points`,
			description: `Click on the "Send Points" button. The System will send the notification to the phone they can check the receipt.`,
		},
		{
			title: `Redeem Rewards`,
			description: `Next the "Redeem Rewards" menu, the business owner can add and manage the rewards for the members to redeem.`,
		},
		{
			title: `Redeem Rewards`,
			description: `The example of reward which you can try to redeem or create more later.`,
		},
		{
			title: `Activate Coupon`,
			description: `When the members redeem the rewards, click on the "Activate Coupon" menu to get the coupon.`,
		},
		{
			title: `Activate Coupon`,
			description: `Enter the redeem code or scan the OR Code to activate the coupon.`,
		},
		{
			title: `Business poster`,
			description: `Promote Pointspot for new customers. Get to know by creating a poster. Click on the menu "Business poster".`,
		},
		{
			title: `Business poster`,
			description: `Ready-made posters that you can download to display at your business.`,
		},
		{
			title: `Business poster`,
			description: `And another designed poster that you can upload your business photo to make it more beautiful.`,
		},
		{
			title: `Business information`,
			description: `Let the new members or the customers get to know your business. Click on the "Business information" menu to update your business information.`,
		},
		{
			title: `Business information`,
			description: `Upload your business logo with 1: 1 photo.`,
		},
		{
			title: `Business information`,
			description: `Enter business details in order for members to understand more about your business.`,
		},
		{
			title: `Business information`,
			description: `Upload photos of your business, such as the storefront image, the atmosphere or the shop banner with 2: 1 picture size.`,
		},
		{
			title: `Business information`,
			description: `Upload a picture of your business for the business card.`,
		},
		{
			title: `Business information`,
			description: `Choose your business branding color to build brand recognition.`,
		},
		{
			title: `Business information`,
			description: `When you have completed your business information, click on the "Save" button to save.`,
		},
		{
			title: `Activate business`,
			description: `When signed up please activate your business to receive the benefits to use the system, click on the menu "Activate business".`,
		},
		{
			title: `Activate business`,
			description: `Complete 3 Steps to activate your business then you can use the package immediately.`,
		},
	],

	// News feed
	newsfeed: "News Feed",
	activities: "Activities",
	redeemed_reward: "redeemed reward",
	activated_reward: "activated reward",
	get_giveaway: "get giveaway reward",
	register_and_subscribe: "registered and subscribed in your Business",
	pay_to_business: "pay {0} points to your business.",
	found_newsfeed: "Found {0} Activities",
	notfound_newsfeed: "Not found Activities",
	notfound_newsfeed_in_business: "Not found Activities in your business",
	to_know_movement_business: "To know the movement in your business \n Recommend Send points and Create a reward.",
	just_now: "Just now",
	minute_ago: "minute ago",
	minutes_ago: "minutes ago",
	hour_ago: "hour ago",
	hours_ago: "hours ago",

	// game
	game: "Game",
	game_setting: "Game setting",
	game_setting_sub: "Set game on/off after your customers receive points",
	game_sub_condition:
		'When the wheel game add points is enabled Members can play the wheel game add points by clicking on the link in the "receive points notification" only, and the points received from playing the wheel game will be sent to members immediately.',
	earn_game: "Receive points from games",
	lose_game: "Lose points from games",
	increase: "Increase",
	increase_points: "Increase points",
	decrease: "Decrease",
	decrease_points: "Decrease points",
	lucky_wheel: "Wheel Game add points",
	lucky_draw_wheel_guide:
		"Guide : When you press to spin, The wheel will spin and stop randomly at one area of the wheel, The result is the location where the pointer is pointing.",
	earn_points_more: "You got more points.",
	lose_points: "You lose points",
	game_deal: "You did not get more points",
	spin_draw: "Spin wheel",
	try_again_later: "Try again later",
	congratulations: "Congratulations",
	email_verified: "Your email has been verified.",
	try_again: "Try again next time.",
	save_success: "Save successfully",
	game_preview: "Game Preview",
	points_game_test: "Test earning points.",
	points_game_test_sub: "Assume the points that members receives to see a sample of the wheel.",
	setting_minimum_points: "Setting the minimum points",
	setting_minimum_points_sub: "Set the minimum points that members received to be able to play games.",
	points_to_percent_ratio: "The system will take the received points as a percentage ratio (%) and shown on the wheel.",
	setting_section_wheel: "Setting the ratio of the wheel game add points",
	cal_ratio_description:
		"The ratio will be calculated as a percentage (%) of received points. Can add a maximum of 8 pieces",
	cal_waste_points: "When enabled the game, the average additional points will be used",
	add_piece_wheel: "Add piece wheel",
	orderly: "orderly",

	// setting points
	setting_points: "Points setting",
	pay_setting: "Setting transfer points to business.",
	pay_setting_sub: "Enable or disable transfer points to business.",
	transfer_setting: "Setting transfer points to friend.",
	transfer_setting_sub: "Enable or disable transfer points to friend.",
	transfer_expire_month: "The points received from the transfer will expire within that month.",
	transfer_expire_year: "The points received from the transfer will expire within that year.",

	// transaction_activated_coupon
	transaction_activated: "Activated Coupon",
	transaction_activated_in_business: "Activated Coupon in business",
	transaction_activated_report: "Activated Coupon report",
	activated_name: "Activated by",
	coupon_activated_range: "Activated date range",
	coupon_activated_date: "Activated date",

	// transaction_sent_coupon
	transaction_sent: "Sent Coupons",
	transaction_sent_in_business: "Sent Coupon in business",
	transaction_sent_coupon_report: "Sent Coupons report",
	sent_name: "Sent by",
	coupon_sent_range: "Sent date range",
	coupon_sent_date: "Sent date",

	// Setting Business Activate Coupon
	setting_activate_coupon: "Activate coupon",
	swipe_client: "Slide on screen",
	pincode: "PIN CODE",
	add_pincode: "Add PIN CODE",
	setting_swipe_to_activate: "Setting slide on screen to activate coupon",
	setting_swipe_to_activate_description: "Can activate coupon On the member coupon By Slide on screen",
	setting_pincode_to_activate: "Setting PIN CODE to activate coupon",
	setting_pincode_to_activate_description: "Can activate coupon On the member coupon By PIN CODE",
	pincode_info: "PIN CODE information",
	not_found_pincode_info: "PIN CODE information not found",
	please_enter_more_than_5char: "Please enter more than 5 characters",
	you_want_delete_pincode: "Do you want to delete PIN CODE ?",
	swipe_note: "Remark",
	show_activated_with_swipe: "Activated by slide on screen only",
	show_activated_with_delivery: "Activated by delivery only",

	// RFM Analysis
	RFM_analysis: "RFM Analysis",
	RFM_analysis_report: "RFM Analysis report",
	RFM_analysis_description: "RFM Analysis description",
	RFM_recency: "Recency",
	RFM_frequency: "Frequency",
	RFM_monetary: "Monetary",
	RFM_group: "Group",
	RFM_list: {
		champions: {
			name: "Champions",
			description: `The customers who engaged recently, frequently, and received lots of points.`,
		},
		loyal_customers: {
			name: "Loyal Customers",
			description: `The customers who engaged frequently and received lots of points.`,
		},
		potential_loyal_list: {
			name: "Potential Loyalist",
			description: `The customers who engaged recently with moderate frequency and received some points.`,
		},
		new_customers: {
			name: "New Customers",
			description: `The customers who just engaged recently.`,
		},
		promise: {
			name: "Promising",
			description: `The customers who engaged recently but not frequently and received a few points.`,
		},
		need_attention: {
			name: "Need Attention",
			description: `The customers who engaged with moderate frequency and received some points.`,
		},
		about_to_sleep: {
			name: "About to Sleep",
			description: `The customers with few points, low frequency, and haven't engaged for a while.`,
		},
		cant_lose_them: {
			name: "Can’t Lose Them",
			description: `The customers with lots of points, used to engage frequently in the past, but you haven’t seen them for a long time.`,
		},
		at_risk: {
			name: "At Risk",
			description: `The customers with moderate frequency and some points but haven’t engaged for a long time.`,
		},
		hibernating: {
			name: "Hibernating",
			description: `The customers with few points, low frequency, and haven’t engaged for a long time.`,
		},
	},
	RFM_desRFM: {
		recency: {
			name: "Recency",
			description: `A metric to indicate when was the last time the customers engaged with your business.`,
		},
		"frequency&monetary": {
			name: "Frequency & Monetary",
			description: `These two metrics let you know how often the customers engaged with your business and how many points they have received.`,
		},
	},
	RFM_start_tutorial:
		" RFM Analysis report that groups members in business\ninto 10 groups. To know Show the members of the group.\nThe average returning to business and\nthe average business usage",
	RFM_tutorial_step: [
		{
			description: `All group types\nwhich is divided into 10 groups.`,
		},
		{
			description: `These two metrics let you know how often the customers engaged with your business and how many points they have received.`,
		},
		{
			description: `A metric to indicate when was the last time the customers engaged with your business.`,
		},
		{
			description: `You can click to select the desired group.\nTo know the explanation and show details\nof members in the group.`,
		},
		{
			description: `Will name and symbol \n and the explanations\nand definitions of the group.`,
		},
		{
			description: `Show the members of the group\nTo know Show the members of the group.\nThe average returning to business and the average business usage`,
		},
	],

	// Member card
	member_card: "Member card",
	no_member_card: "No Member card",
	member_card_exist_same_card: `Member already has $1 card.`,
	issue_member_card: "Issue Member card",
	issue_member_card_successfully: "Issue Member card successfully",
	issue_member_card_description: "Issue Member card by mobile number or Pointspot ID",
	select_member_card: "Select Member card",
	setting_member_card_type_on_off: "Setting ON / OFF Member card",
	create_setting_member_card_type_detail: "Create Member card in business",
	enable_setting_member_card: "Enable Member card setting",
	disable_setting_member_card: "Disable Member card setting",
	setting_member_card_type: "Setting Member card type",
	type_member_card: "Member card type",
	type_member_card_in_business: "Member card type in business",
	type_member_card_in_business_not_found: "Not found member card type in business",
	please_add_type_member_card: "Please create the member card type at menu",
	add_member_card_type: "Add Member card type",
	sort_member_card_type: "Sort Member card type",
	sort_member_card_type_successfully: "Sort Member card type successfully",
	edit_member_card_type: "Edit Member card type",
	example_member_card: "Member card example",
	member_card_type_name: "Member card type name",
	member_card_privilege: "Member card type privilege",
	member_card_style: "Member card type style",
	member_card_bg_color: "Background color",
	member_card_font_color: "Font color",
	member_card_upload_bg_image: "Upload member card background image",
	member_card_size: "Member Card Size: 170px x 300px",
	member_card_file_type: "File type : .jpg, .jpeg, .png",
	member_card_selected_fail: "Please upload a file with .jpg, .jpeg, or .png extension.",
	zoom: "Zoom",
	rotate: "Rotate",
	member_card_expiry_date: "Member card expiry date",
	set_member_card_expiry_only_new_card: "Can set the Member card expiry date only for the new card",
	cant_set_member_card_expiry_automation: "Does not expire according to the Automation settings.",
	member_receive_member_card_type: "Member receive the Member card type",
	canceled_cannot_use_member_card: `will be canceled and cann't use Member card`,
	member_card_type_non_expiry_date: "No expiration date",
	member_card_type_expiry_date_after: "Set an expiration date",
	after_receiving_member_card_3_month: "3 month after receiving Member card",
	after_receiving_member_card_6_month: "6 month after receiving Member card",
	after_receiving_member_card_12_month: "12 month after receiving Member card",
	after_receiving_member_card_24_month: "24 month after receiving Member card",
	create_member_card_type_successfully: "Create Member card type successfully",
	edit_member_card_type_successfully: "Edit Member card type successfully",
	delete_member_card_type_successfully: "Delete Member card type successfully",
	change_member_card_type: "Change Member card type",
	edit_member_card_infomation: "Edit Member card information",
	update_member_card: "Update Member card",
	change_member_card_type_successfully: "Change member card type successfully",
	change_member_card_name_successfully: "Change member's name successfully",
	cancel_member_card: "Cancel Member card",
	cancel_member_card_successfully: "Cancel Member card successfully",
	you_want_cancel_member_card: "Do you want to cancel Member card ",
	card_holder: "Card holder",
	membercard_setting_description:
		"When setting on, Businesses can create member card types and issue cards for members in your business.",
	manual_member_card: "Member card usage detail",
	when_enabled_member_card:
		"Once the member card type has been enabled.\nWill affect the calculation of service charge to the package.",
	when_enabled_member_card_remark: "Once the member card type has been enabled. It cannot be disabled.",
	enabled_member_card_usage:
		"For service charge, count from number of Members who hold the member card and number of transaction that member card was issued",
	you_confirm_enable_member_card: "Do you want to enable of the member card type ?",
	manual_member_card_detail: [
		{
			title: "Member card settings",
			detail: [
				"When setting on, the member card can be created and edited.",
				"Member card can be edit by clicking on the member card.",
				"Business can create up to 3 member card types.",
			],
		},
		{
			title: "Issuing member cards",
			detail: [
				"Can issue cards to member by going to the members information menu.",
				"Select the member you want to issue card, then click to issue member card. You can enter first name, last name, and choose member card type",
				"When you confirm, the system will send a message to member.",
				"After issuing the member card, the card can be change or cancel.",
			],
		},
	],
	total_member_card: "Total member card",
	gender_male: "Male",
	gender_female: "Female",
	gender_unspecified: "Unspecified",
	member_card_automation_setting: "Member card automation",
	member_card_automation_setting_description:
		"The system automatically adjusts the member card level according to the points received.",
	member_card_automation_setting_confirmation: "Please confirm member card automation setting.",
	member_card_promotion_setting: "Send Member Card",
	member_card_promotion_setting_description:
		"The member card will be automatically sent when a member has earned the specified amount of points.",
	member_card_promotion_setting_time: "Time period to calculate earned points",
	member_card_automation_status_success: "Update settings successfully",
	member_card_earned_point: "The amount of points to get member card for each level.",
	member_card_earned_point_range: "Earned {0} - {1} points",
	member_card_earned_point_range_last: "Earned more than {0} points",
	member_card_earned_point_specific: "Earned {0} points",
	member_card_earned_point_error_required: "{0}'s required points must be greater than {1}",
	member_card_promotion_remark:
		"* The service fee will be calculated based on the change of member card according to actual usage.",
	member_card_promotion_edit_remark:
		"* The settings will be applied to the member who receives the new point transaction only.",
	member_card_not_available: "Member card not found.",
	member_card_not_available_click: "Click here to setup.",
	member_card_demotion_setting: "Demote Member Card",
	member_card_demotion_setting_description:
		"The member card will be demoted according to member card's expiration date or member's earned points.",
	member_card_demotion_card_expiration: "Member card's expiration date.",
	member_card_demotion_earned_point: "Member's earned points.",
	member_card_demotion_earned_point_check_frequency: "Frequency of point calculation.",
	member_card_demotion_1_month_check: "Points are calculated at the end of every month.",
	member_card_demotion_3_month_check: "Points are calculated at the end of Mar, Jun, Sep and Dec.",
	member_card_demotion_6_month_check: "Points are calculated at the end of Jun and Dec.",
	member_card_demotion_12_month_check: "Points are calculated at the end of December.",
	member_card_demotion_condition: "Member card demote condition",
	issue_member_card_confirm: "Issue Member card confirmation",
	change_member_card_type_confirm: "Change Member card type confirmation",
	member_card_info: "Information",
	privilege_card: "Privilege",
	member_card_one_line: "One line member card name",
	member_card_two_lines: "Two line member card name",
	size_member_card: "Member Card Size: 170px x 300px",
	size_logo: "Logo Size: 50px x 50px",
	view_template: "View Template",
	max_2_lines: "Maximum 2 lines",

	// Report Member card
	transaction_member_card: "Transaction Member card",
	transaction_member_card_in_business: "Transaction Member card in business",
	transaction_member_card_report: "Transaction Member card report",
	status_member_card: "Member card status",
	status_changed: "Change",
	status_activate: "Activate",
	status_canceled: "Cancel",
	status_expired: "Expired",
	authority_member_card: "Authority",

	// Giveaway reward
	giveaway_coupon: "Giveaway rewards",
	giveaway: "Giveaway",
	giveaway_coupon_ribbon: "Giveaway",
	add_giveaway_coupon: "Add giveaway reward",
	add_giveaway_coupon_successfully: "Create giveaway reward successfully",
	edited_giveaway_coupon_successfully: "Edited giveaway reward successfully",
	delete_giveaway_coupon_successfully: "Deleted giveaway reward successfully",
	image_giveaway_coupon: "Giveaway reward image",
	title_giveaway_coupon: "Giveaway reward name",
	description_giveaway_coupon: "Giveaway reward description",
	duration_of_giveaway_coupon: "Duration of giveaway reward",
	set_lifetime_giveaway_coupon: "Set the life time after receiving giveaway reward.",
	expired_after_receiving_giveaway_coupon: "Expire after receiving coupon.",
	set_limit_giveaway_coupon: "Limiting the amount of giveaway reward.",
	receiving_per_member: "Receiving per member",
	receiving_limit_all: "All Receiving limit",
	show_remain_giveaway_coupon: "Show the remaining giveaway reward.",
	confirm_delete_giveaway: "Do you want to delete the giveaway reward ?",
	coupon_unit: "Coupon",
	coupon_per_member_unit: "Coupon",

	// redeem points
	use_points: "Use points",
	pending: "Pending",

	// gift voucher
	giftvoucher: "Gift Voucher",
	gift_voucher: "Gift voucher coupons",
	add_gift_voucher: "Add Gift Voucher",
	send_gift_voucher: "Send Gift Voucher",
	gift_voucher_list: "Gift Voucher",
	gift_voucher_broadcast_list: "Broadcast list",
	gift_voucher_broadcast_list_title: "Gift Voucher Broadcast List",
	select_gift_voucher: "Select Gift Voucher",
	gift_voucher_name: "Gift Voucher Name",
	gift_voucher_description: "Gift Voucher description",
	gift_voucher_type: "Gift Voucher type",
	gift_voucher_image: "Gift Voucher image",
	gift_voucher_value: "Gift Voucher value",
	gift_voucher_status: "Gift Voucher Status",
	gift_voucher_send_amount: "Number of gift voucher",
	gift_voucher_amount: "Gift Voucher",
	gift_voucher_notfound: "Gift Voucher not found",
	gift_voucher_confirm: "Gift Voucher confirmation",
	voucher_value: "Value",
	expired_after_gift_voucher: "Expired after receiving",
	after_receiving_gift_voucher: "After receiving gift voucher",
	set_expired_date_after_gift_voucher: "Number of days",
	gift_voucher_text_messages: "Send Gift Voucher notification are only sent between {0}",
	send_by_gift_voucher: "Send by",
	amount_gift_voucher: "Amount Gift Voucher",
	total_sent_gift_voucher: "Total sent",
	total_activated_gift_voucher: "Total activated",
	date_send: "Date send",
	send_gift_voucher_successfully: "Send Gift Voucher successfully",
	created_gift_voucher_successfully: "Created Gift Voucher successfully",
	edited_gift_voucher_successfully: "Edited Gift Voucher successfully",
	deleted_gift_voucher_successfully: "Deleted Gift Voucher successfully",
	want_to_delete_gift_voucher: "Are you want to delete Gift Voucher ?",
	discount_amount: "Discount amount",
	sent: "Sent",
	sent_by: "Sent By",

	// tag label
	label_name: "Labels name",
	example_label_name: "Labels name example",
	select_label: "Select labels",
	select_member_label: "Select member labels",
	label: "Labels",
	tag_label_description: "Use labels to track usage.",
	add_label: "Add labels",
	add_member_label: "Add member labels",
	add_transaction_label: "Add transaction labels",
	edit_member_label: "Edit member label",
	edit_transaction_label: "Edit transaction label",
	label_not_found: "Labels not found.",
	delete_label_confirm: "Do you want to delete this labels ?",
	add_label_success: "Add labels successfully.",
	add_label_error: "Add labels failed.",
	save_label_success: "Save labels successfully.",
	save_label_error: "Save labels failed.",
	delete_label_success: "Delete labels successfully.",
	delete_label_error: "Delete labels failed.",
	label_color: "Labels color",
	transaction_label: "Transaction labels",
	transaction_label_description: "Use labels to track usage.",
	member_label: "Member labels",
	no_member_label: "No Member labels",
	member_label_description: "Use labels to track members.",
  automatic_label: 'Automatic label selection by AI',
  automatic_label_description:
    'Use AI to label transactions by analyzing attached image in the send points transaction.',
  tooltip_ai_label: 'Label selected by AI',

	// broadcast promotion
	broadcast: "Broadcast",
	"Broadcast Message": "Broadcast message",
	broadcast_message: "Broadcast message",
	broadcast_tx_remain: "Remaining transactions",
	broadcast_promotiom_list: "Broadcast promotion list",
	enable_broadcast_message_success: "Enable Broadcast message success",
	setting_enable_broadcast_message: "Setting enable Broadcast message",
	setting_enable_broadcast_message_detail: "Setting enable Broadcast message. Send information to members in business.",
	addon_package_broadcast_message:
		"There is an additional service charge from the main package, according to text size and the amount of messages.",
	message_broadcast: "Message broadcast",
	add_message_broadcast: "Add message",
	edit_message_broadcast: "Edit message broadcast",
	message_broadcast_not_found: "Message not found",
	broadcast_message_example: "Sample Broadcast message",
	created_broadcast_message_successfully: "Created broadcast message successfully",
	edited_broadcast_message_successfully: "Edited broadcast message successfully",
	deleted_broadcast_message_successfully: "Deleted broadcast message successfully",
	dynamic_message: "Dynamic message",
	dynamic_message_description: "Message will be modified based on member information or business information.",
	dynamic_message_description2: "Message will be modified based on member information.",
	add_link: "Add link",
	add_shortener_link: "Add Shortener link",
	total_send_message: "total send message",
	delivered_message: "Delivered",
	opened_message: "Opened",
	clicked_message: "Clicked",
	select_broadcast_messages_target: "Select broadcast messages and business target groups.",
	broadcast_message_confrim_send: "Are you want to send Broadcast promotion ?",
	message_broadcast_confrim_delete: "Are you want to delete Message broadcast ?",
	broadcast_status: {
		created: "In progress",
		queueing: "In progress",
		success: "Success",
		failed: "Error",
	},
	broadcast_tab_message: "Message",
	broadcast_tab_list: "Broadcast promotion list",
	broadcast_tab_send: "Send broadcast",
	broadcast_billing_credit: "Credits Usage",
	broadcast_billing_charge: "Broadcast Charge",
	broadcast_billing_provider: "Providers",
	broadcast_billing_messages: "Messages",
	broadcast_billing_amount: "Amount",
	broadcast_billing_sub_total: "Amount (THB)",
	broadcast_billing_total: "Total",
	broadcast_billing_remark: "* The actual charge may be different.",
	broadcast_billing_remark_credit: "* The actual credits may be different.",
	broadcast_choose_by_system: "Chosen by system",
	tooltip_broadcast_option_system: "System will choose to use LINE first and then use SMS.",
	tooltip_broadcast_option_system_inter: "For foreign members, system can be sent by EMAIL only.",
	select_broadcast_option: "Broadcast options",
	select_redeem_rewards: "Redeemable rewards",
	credit: "Credit",
	free_credit: "Free Credit",
	remaining_credit: "Remaining Credit",
	not_noti_sms_provider_remark: "* For SMS providers, notifications will not be sent.",

	info_spot_redmption: "Working patterns of the Spot Redemption",
	info_spot_redmption_detail: [
		"When user redeem reward, The system will display the redemption code only 5 minutes.",
		"The system will not reduce points and the number of redemption. Until the admin activates the redemption code.",
	],
	member_pending_group: "Pending group",
	member_points: "Point range",
	member_purchase_product: "Purchase history",
	member_receive_reward: "Reward conditions",
	condition: "Condition",
	purchase_condition: "Condition",
	members_purchased_all: "All of the selected products",
	members_purchased_any: "Some of the selected products",
	between: "between",
	select_points: "Select Points",
	amount_points: "Points",
	points_range_invalid: "Invalid point range.",
	points_invalid: "Invalid point",
	point_from_to: "From",
	point_greater_equal: "Greater/equal",
	point_less_equal: "Less/equal",
	points_greater: "points",
	points_or_greater: "points or greater",
	button_label: "Button Label",
	title_name: "Title",
	broadcast_title: "Broadcast Title",
	default_message: "Default Message",
	line_message: "LINE Message",
	line_error: "Please set up LINE Login\nand LINE Messaging in order to proceed.",
	aspect_ratio: "Aspect Ratio",
	view_details: "View details",
	invalid_link: "Invalid link",

	// Product Management
	product: "Product",
	products_management: "Products Management",
	product_level_points: "Products Level Points",
	detail_product_level_points_title: "Products Level Points description",
	product_level_points_description: "Manage business products to use for sending points by products list",
	confirm_enable_product_level_points: "Enable Products Level Points ?",
	when_enabled_product_level_points:
		"When enable Products Level Points\nPackage price will be charged an additional 1,200 THB/Month.",
	enable_success: "Enable successfully",
	disable_success: "Disable successfully",
	add_category: "Add category",
	category_name: "Category name",
	category_name_place_holder: "Category 1",
	add_category_success: "Add category successfully",
	edit_category_success: "Edit category successfully",
	delete_category_success: "Delete category successfully",
	move_product_success: "Move all product successfully",
	edit_category: "Edit category",
	delete_category: "Delete category",
	move_all_product: "Move all products",
	move: "Move",
	confirm_delete_category: "Do you want to delete this category ?",
	category: "Category",
	product_category: "Product category",
	product_quantity: "Product quantity",
	item: "Item",
	add_product: "Add product",
	product_name: "Product name",
	product_number: "Product number",
	sku_number: "SKU",
	points_ratio: "Points convertion ratio",
	points_ratio_product: "Product convertion ratio",
	set_points_ratio_product: "Set product convertion ratio",
	product_points_ratio_condition: `Products that don't set a point conversion ratio will use the standard conversion ratio.`,
	add_product_success: "Add product successfully",
	delete_product_success: "Delete product successfully",
	edit_product_success: "Edit product successfully",
	default: "Default",
	product_not_found: "Product not found",
	edit_product: "Edit product",
	delete_product: "Delete product",
	product_deleted: "Product is deleted",
	confirm_delete_product: "Do you want to delete this product ?",
	add_image: "Add image",
	select_image: "Select image",
	change_image: "Change image",
	image_size_recommend: "Recommended image size 1200 * 600",
	not_defined: "Not defined",
	standard_value: "Standard value",
	search_products: "Search products",
	search_product_placeholder: "SKU or Product name",
	product_list: "Products list",
	select_product: "Select products",
	total_amount: "Total amount",
	trans_detail: "Transaction details",
	manual_product_level_points: [
		{
			title: "Product Management",
			detail: [
				"When enabled, Able to create a products",
				"Able to customize the products details. Ex. Name, SKU, Conversion ratio and image.",
				"Able to create categories and categorize products",
			],
		},
		{
			title: "Products Level Points",
			detail: [
				"Able to send points according to the added products list",
				"Points will be calculated automatically according to the product list.",
				"Multiple products item can be added per one submission.",
				"Able to view the Product Level Points report",
			],
		},
	],
	product_report: "Products report",
	product_report_description: "Products summary report",
	number_of_member: "Members",
	transaction_product: "Total products",
	total_points: "Points",
	points_error_remark:
		"Total points are inaccurate from the actual. Because the sending points is calculated from many items",
	all_archive_product: "Products in this category will be deleted.",
	already_sku: "SKU already exists",
	same_sku: "Duplicate SKU",
	no_sku: "Require SKU",
	"pointspot/sku-exists": "SKU already exists",
	import_product: "Import Products",
	importing_product: "Importing products",
	limit_import_product_records: "Limit record to 200 records per job",
	import_product_points_ratio_condition: "Leave product's ratio field empty to use the standard conversion ratio",
	import_product_qty: "Quantity",
	produc_sku_error_character: "SKU must not contain spaces or special characters.",
	produc_sku_error_length: "SKU cannot be more than 20 characters.",
	produc_name_error_length: "Product name cannot be more than 300 characters.",
	all_categories: "All categories",
	product_require_config: "Require Product in Send Points",
	product_require_config_description: "Enable to require at least one product in Send Points",
	set_require_product_status_success: "Update product config successfully",

	// Welcome points
	welcome_points: "Welcome points",
	welcome_points_setting: "Setting welcome points",
	welcome_points_setting_sub: "Enable or disable send welcome points for new members.",
	welcome_points_period: "Welcome points period",
	open_setting: "Enable",
	send_sms_confirm: "Please confirm to send notifications",

	// display_name
	display_name: "Display name",
	"user-permission/email-already-invite": "This email already exists",
	"user-permission/transfer-limit": "Transfer limit incorrect",

	// friend_get_friends
	friend_get_friends: "Friend Get Friends",
	friend_get_friends_reward: "Friend Get Friends reward",
	friend_get_friends_description: "Members can invite friends to subscribe for receive rewards.",
	friend_get_friends_period: "Friend Get Friends period",
	inviter_condition: "Condition for the inviter to receive the reward",
	invitee_subscribed: "Invitee subscribed",
	invitee_active: "Invitee active (activate coupon or receive points)",
	invitee_subscribed_active: "Invitee subscribed and use coupon",
	inviter_limit: "Limit invitations",
	inviter_reward: "Reward of inviter",
	invitee_reward: "Reward of invitee",
	tooltip_invite_limit: "If limit is not specified, it will be unlimited",
	invite_limit_unit: "Invitee",
	friend_get_friends_report: "Friend Get Friends report",
	friend_get_friends_report_description: "The report that shows new members who are invited by members.",
	friends_subscribe_date: "Subscribe date",
	invitee_id: "Pointspot ID",
	invitee_name: "Member name",
	invitee_reward_status: "New member reward status",
	inviter_id: "Pointspot ID inviter",
	inviter_name: "Inviter name",
	inviter_reward_status: "Inviter reward status",
	invite_reward_receive: "Received",
	invite_reward_not_receive: "Not yet received",

	//alias name
	update_alias_name: "Edit Name",
	alias_name: "Name",

	// line_liff
	liff_id: "LINE LIFF app ID",
	liff_id_title: "Connect Pointspot with LINE LIFF app",
	liff_id_nav: "Connect with LINE LIFF app",
	liff_home_page: "Home Page",
	liff_member_card_page: "Member Card",
	liff_my_coupon_page: "My Coupon",
	liff_reward_page: "Rewards",
	liff_switch_title: "Access to Pointspot with LINE LIFF app.",
	liff_switch_description: "Get started on Pointspot through LINE account with LINE LIFF app.",
	liff_save_toast_success: "Save Successfully",
	liff_save_toast_fail: "Save Failed",
	liff_close_toast_success: "Turn off successfully",
	liff_close_toast_fail: "Turn off failed",
	liff_copy_toast_success: "Copied",
	login_with_line: "Login with LINE",
	liff_endpoint_url: "Endpoint URL",
	liff_remove_warning_title: "Disconnect with LINE Login",
	liff_remove_waening_des:
		"Please confirm to disconnect with LINE Login. Users from LINE will be unable to auto-login to Pointspot",
	rich_menu_title: "Rich Menu",
	rich_menu_card: "Rich Menu Settings",
	rich_menu_image_size: "Image Size",
	rich_menu_color: "Font and Icon Colors",
	rich_menu_image: "Menu Image",
	rich_menu_dark: "Dark",
	rich_menu_light: "Light",

	//line messaging
	line_messaging_nav: "Connect with LINE Messaging",
	line_messaging_save_toast_fail: "Save Failed",
	line_messaging_save_toast_success: "Save Successfully",
	line_messaging_close_toast_success: "Turn off successfully",
	line_messaging_close_toast_fail: "Turn off failed",
	line_messaging_switch_title: "Send Pointspot notification messages with LINE.",
	line_messaging_switch_description:
		"Send Pointspot notification messages through LINE Official Account with LINE Messaging APIs.",
	line_messaging_id_title: "Connect Pointspot with LINE Messaging",
	line_messaging_token: "LINE Messaging token",
	line_messaging_remove_warning_title: "Disconnect with LINE Messaging",
	line_messaging_remove_warning_des:
		"Please confirm to disconnect with LINE Messaging. Notification will send with SMS only",

	//change payment noti
	change_payment_noti_title: "Notice of change of payment provider from Stripe to 2c2p",
	change_payment_noti_des:
		"Because of this change, need to bither you to re-check the address information and credit card information.",
	change_payment_noti_verify: "verify",
	change_payment_noti_later: "later",

	// 2c2p
	card_no_require: "Card number is required.",
	card_no_invalid: "Card number is invalid.",
	exp_month_require: "Expiry month is required.",
	exp_month_2_digit: "Expiry month must be two numbers.",
	exp_month_invalid: "Expiry month is invalid.",
	exp_year_require: "Expiry year is required.",
	exp_year_4_digit: "Expiry year must be four numbers.",
	card_expired: "Card already expired.",
	cvv_invalid: "CVV is invalid.",
	add_card_error: "Transaction failed, please try again later.",
	add_card_error_cancelled: "Transaction failed, transaction is cancelled.",
	add_card_error_invalid_number: "Transaction failed, invalid card number.",
	add_card_error_owner_cancel: "Transaction failed, customer cancellation.",
	add_card_error_bank_unsupport: "Transaction failed, bank not supported.",
	add_card_error_expired_card: "Transaction failed, expired card.",
	add_card_error_restricted_card: "Transaction failed, restricted card.",
	add_card_error_lost_card: "Transaction failed, lost card marked.",
	add_card_error_stolen_card: "Transaction failed, stolen card marked.",
	add_card_error_insufficient_funds: "Transaction failed, insufficient funds.",
	add_card_error_exceed_withdrawal_limit: "Transaction failed, Exceeds Withdrawal Frequency Limit.",

	// Invoice status
	invoice_status: {
		processing: "Processing",
		paid: "Paid",
		failed: "Failed",
	},

	// Ecard
	ecard_code: "Reward Code",
	ecard_code_add: "Add code",
	ecard_code_added: "Imported codes",
	ecard_code_added_total: "Total",
	ecard_code_type: "Choose code type",
	ecard_code_type_auto: "Auto generation",
	ecard_code_type_import: "Import coupon code",
	ecard_code_import_limit: "Limit {0} codes at a time",
	ecard_code_import_limit_input: "Limit {0} codes at a time",
	ecard_code_import_limit_exceed: "Data had more than {0} items",
	ecard_code_import_pattern: 'Allow A-Z, a-z, 0-9 and "-" only',
	ecard_code_import_limit_character: "Each code can be up to 32 characters",
	ecard_code_import_pattern_separator: 'Separate each code by "," or space only',
	ecard_code_import_input: "Enter codes",
	ecard_code_import_type_here: "Enter codes here",
	ecard_code_import_method_change_confirm: "Confirm import method change",
	ecard_code_import_method_change_warning:
		"Please confirm to change code import method.\nAfter change code import method all prepared code will be reset.",
	ecard_code_duplicated: "Duplicate code",
	ecard_code_exist: "Code already exists",
	ecard_code_invalid: "Invalid code",
	ecard_code_invalid_32: "Code exceeds 32 characters",
	ecard_code_list_total: "Total codes: {0}",
	ecard_code_list_view: "View",
	ecard_code_list_valid: "{0} valid codes",
	ecard_code_list_duplicated: "{0} duplicate codes",
	ecard_code_list_exist: "{0} codes already exist",
	ecard_code_list_invalid: "{0} invalid codes",
	ecard_code_list_valid_label: "Valid code list",
	ecard_code_list_error_label: "Invalid code list",
	ecard_code_list_invalid_label: "Invalid code pattern",
	ecard_code_list_duplicated_exist_label: "Duplicate codes / already exist codes",
	ecard_code_automatic: "Automatic",
	ecard_code_custom: "Custom",

	// Consent
	consent: {
		accept_terms: "Accept",
		accept: "Accept",
		decline: "Decline",
		accept_personal_shared_a: "Accept to use personal data for marketing purposes of ",
		accept_personal_shared_b: "Readyplanet Public Company Limited and ",
		accept_personal_shared_c: "affiliated company.",
	},

	total_points_setting: "TOTAL POINTS",
	invalid_member_card: "Invalid Member Card",

	expire_in: "Expire in",

  view_transactions: "View transactions",
	points_earned_last_30_days: "Points earned in the last 30 days",

	//icon
	home: "Home",
	point: "Point",
	my_coupons: "My coupons",
	icon_other: "Others",
	get_friends: "Invite",

	free_privileges: "Free Privileges",
	history: "History",
	point_expire: "25 points will expire on 30 Jun 2023",
	view_all: "View all",

	setting_coupon: "Coupons setting",
	view_all_photos: "View all photos",

	title_member_card_setting_noti_expiry: "Member Card Expiry Notification",
	no_notification_member_card_setting_noti: "No Notification",
	advance_member_card_setting_noti: "Advance Notification",
	setting_member_card_setting_noti_announcement: "Set up advance notification for card expiration, counting from the expiration date (up to 30 days)",
	setting_member_card_setting_noti_explain_example: "For example, if a card expires at the end of March 2024 and you set a 10-day advance notification, members will receive a notification on March 21st.",
	choose_amount_setting_member_noti_expiry_date: "Choose the number of days (1-30)",
	warning_member_card_setting_noti_use_credit: "Warning: Setting up advance notifications for expired member cards will incur notification service charges based on actual usage.",
	edit_member_card_setting_noti_successfully: "Successfully edited member card expiry notification.",
	member_card_expire_notification: "Member Card Expiry Notification",

  // Registration Form
  enable_or_disable_additional_register: 'Enable / Disable Registration Form',
  title_additional_register: 'Title',
  answer_type: 'Answer type',
  require_form: 'Require',
  require: 'Require',
  display_form_on: 'Display information on',
  enable_setting_additional_register: 'Enable Registration Form',
  disable_setting_additional_register: 'Disable Registration Form',
  show_example: 'Preview',
  register_page: 'Register Page',
  follow_biz_page: 'Business Follow page',
  popup_page: 'Popup Page',
	popup_page_description: 'Display a popup window if a member has not answered the required question.',
  example_additional_register: 'Registration form preview',
	question: 'Question',
  add_question: 'Add a question',
  add_thai: 'Add Thai',
  add_english: 'Add English',
  title_example_th: 'เช่น รหัสสาขา จำนวนสัตว์เลี้ยงของคุณ',
  title_example_en: 'E.g. branch code, number of your pet.',
  short_answer: 'Short answer (100 character)',
  long_answer: 'Long answer (200 character)',
  choice_answer: 'Choice (100 choices)',
  placeholder: 'Placeholer',
  member_information_page: 'Client side "Information you shared with this business" page',
  membercard_page: 'Member card',
  manage_option: 'Manage options',
  options: 'options',
	no_option: 'No option',
	option_not_found: 'Option not found.',
  add_options: 'Add options',
  edit_options: 'Edit option',
	duplicate_option: 'Duplicate option',
  delete_options: 'Delete option',
  add_items_description: 'Specify 1 option (maximum length 50 characters) per line.',
  option_list: 'Option list',
  and_more: 'and',
	options_reached_maximum: 'Options has reached the maximum.',
	edit_info_additional_register: 'Edit information from Registration Form',
	custom_form_confirm_delete: "Confirm to delete this question.",
	delete_question_successfully: "Question deleted successfully",
	display_member_card: 'Select the question to display on the member card.',
	changes_not_save: "Changes have not been saved",
	do_you_want_to_save: "Do you want to save the data ?",
	editing: "Editing",
	allow_member_to_edit: "Members can edit their information after submission.",
	dont_show: "Don't Show",

	line_display_name: 'LINE Display Name',

	//Modal Chart New Package
	chart_point_title:'Points',
	chart_coupon_title:'Coupons',
	chart_broadcast_title:'Broadcast',
	chart_member_card_title:'Member Card',
	chart_trans_noti_expire_point_title:'Points Expiration Notification',
	chart_trans_noti_expire_member_card_title:'Member Card Expiration Notification',
	chart_reject_collect_points_title:'Collect Points Notification',
	chart_doughnut_tx:'Transaction',
	chart_doughnut_email:'Email',
	chart_doughnut_sms:'SMS',
	chart_doughnut_line:'LINE',
	chart_doughnut_credit_title:'Credits',
	chart_overage_tx:'Overage Transactions',
	chart_normal_tx:'Transactions',
	chart_usage:'Usage',
	chart_summary_credit:'Credit Summary',
	chart_topup_credit:'Topup Credit',
	
	//security
	security: 'Security',
	two_factor_authentication: '2 Factor Authentication',
	confirm_enable_two_factor_authentication: 'Enable 2 Factor Authentication.',
	confirm_disable_two_factor_authentication: 'Disable 2 Factor Authentication.',
	two_factor_description: 'Add an email OTP verification step after successful login on a new browser.',
}
export default EN;