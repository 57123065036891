import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import YouTube from 'react-youtube';
import parser from 'bbcode-to-react';

import { messagesActions } from '../../store/messagesSlice';
import './MessagesSetting.css';

library.add(faCircle);
library.add(faEllipsisV);

function MessagesSetting() {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.messages.messages);
  const getMessagesData = useSelector((state) => state.messages.getMessagesData);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteMessageID, setDeleteMessageID] = useState();

  function showHandler() {
    dispatch(messagesActions.showMessage(this));
    const btns = document.getElementById(this);
    btns.style.display = 'none';
  }

  function hideHandler() {
    dispatch(messagesActions.hideMessage(this));
    const btns = document.getElementById(this);
    btns.style.display = 'none';
  }

  function editHandler() {
    dispatch(messagesActions.editMessageInit(this));
    const btns = document.getElementById(this);
    btns.style.display = 'none';
  }

  function getMoreMessagesHandler() {
    if (getMessagesData.noMore) return;
    dispatch(messagesActions.getMoreMessages());
  }

  function displayYoutube(id) {
    return <YouTube videoId={id} className="player-wrapper" />;
  }

  if (messages.length === 0) {
    return <div className="message-seting__loading">Loading Messages From Firestore...</div>;
  }

  function compare(a, b) {
    const dateA = a.timestamp;
    const dateB = b.timestamp;

    let comparison = 0;
    if (dateA < dateB) {
      comparison = 1;
    } else if (dateA > dateB) {
      comparison = -1;
    }
    return comparison;
  }

  const sortedMessages = messages.slice().sort(compare);

  function convertToHtml(str) {
    const message = str;
    const replaced = message.replace(/\\n/g, '\n');
    return replaced
      ? replaced.split('\n').map((line, idx) => (
          <span key={idx}>
            {parser.toReact(line)}
            <br />
          </span>
        ))
      : '';
  }

  function dropDownClickHandler() {
    const btns = document.getElementById(this);
    const currentDisplay = btns.style.display;
    if (currentDisplay === 'none') {
      btns.style.display = 'block';
    } else {
      btns.style.display = 'none';
    }
  }

  function closeDeleteMessageModalHandler() {
    setOpenDeleteModal(false);
    setDeleteMessageID(undefined);
  }

  const outputMessagesSetting = sortedMessages.map((message) => {
    let messageContentWithoutYoutube = message.message;
    const hasYoutubeTag = message.message.includes('[youtube]');
    let videoID = null;
    if (hasYoutubeTag) {
      videoID = message.message.split('[youtube]')[1].split('[/youtube]')[0];
      messageContentWithoutYoutube = message.message.replace(`[youtube]${videoID}[/youtube]`, '');
    }

    return (
      <div key={message.id} className="message-setting">
        <div className="message-setting-content">
          <div className="status-container">
            <div className={`status ${message.show ? 'shown' : 'hidden'}`}>{`${
              message.show ? 'shown' : 'hidden'
            }`}</div>
            <p className="message-setting__date">{message.date}</p>
          </div>
          <div className={`message-setting__message ${message.message.includes('[img]') ? 'image-wrapper' : ''}`}>
            {convertToHtml(messageContentWithoutYoutube)}
          </div>
          {videoID ? displayYoutube(videoID) : ''}
          <a href={message.url} target="_blank" rel="noopener noreferrer">
            {message.url}
          </a>
        </div>
        <div className="dropdown-icon" onClick={dropDownClickHandler.bind(message.id)}>
          <p>
            <FontAwesomeIcon icon={['fas', 'ellipsis-v']} />
          </p>
        </div>
        <div className="message-setting-btns" id={message.id} style={{ display: 'none' }}>
          <div
            className={`setting-btn ${message.show ? 'current-setting' : ''}`}
            onClick={showHandler.bind(message.id)}
          >
            Show
          </div>
          <div
            className={`setting-btn ${!message.show ? 'current-setting' : ''}`}
            onClick={hideHandler.bind(message.id)}
          >
            Hide
          </div>
          <div className={'setting-btn setting-btn__edit'} onClick={editHandler.bind(message.id)}>
            Edit
          </div>
          <div
            className={'setting-btn setting-btn__edit'}
            onClick={() => {
              setDeleteMessageID(message.id);
              setOpenDeleteModal(true);
              document.getElementById(message.id).style.display = 'none';
            }}
          >
            Delete
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      {openDeleteModal &&
        createPortal(
          <>
            <div className="delete-message-overlay" onClick={closeDeleteMessageModalHandler}></div>
            <div className="delete-message-popup">
              <p className="title">Delete Message</p>
              <p>Are you sure you want to delete this message?</p>
              <div className="yesno-btns">
                <button
                  className="yesnobtn"
                  onClick={() => {
                    if (deleteMessageID) {
                      dispatch(messagesActions.deleteMessage(deleteMessageID));
                    }
                    closeDeleteMessageModalHandler();
                  }}
                >
                  Yes
                </button>
                <button className="yesnobtn" onClick={closeDeleteMessageModalHandler}>
                  No
                </button>
              </div>
            </div>
          </>,
          document.getElementById('broadcast-message-modal')
        )}
      <div className="message-setting-nav">
        <p>
          <FontAwesomeIcon icon={['far', 'circle']} />
        </p>
        <p>Current Messages</p>
      </div>
      <div className="message-setting-border">
        <div>{outputMessagesSetting}</div>
        {!getMessagesData.noMore && (
          <div
            className={`setting-btn-next ${getMessagesData.noMore ? 'no-more-messages' : ''}`}
            onClick={getMoreMessagesHandler}
          >
            Get more messages
          </div>
        )}
      </div>
    </div>
  );
}

export default MessagesSetting;
