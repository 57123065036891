import { verifyMobilePhone } from './validator'

export const mobileFormat = (country_code, mobile_number) => {
  let isInternationalFormat = process.env.REACT_APP_MOBILE_INTERNATIONAL_FORMAT === 'true' && country_code !== '+66'

  return `${isInternationalFormat ? `${country_code}` : '0'}${mobile_number}`
}

export const mobileForm = (mobile_number) => {
  let mobile = mobile_number && mobile_number.toString().trim()
  if (!verifyMobilePhone(mobile)) return mobile_number

  if (mobile && mobile.length === 10) {
    let format = mobile.match(/^(\d{3})(\d{3})(\d{4})$/)
    return format[1] + '-' + format[2] + '-' + format[3]
  } else if (mobile && mobile.length > 10) {
    let format = mobile.match(/^(\+\d{2})(\d{2})(\d{3})(\d{4})$/)
    return format[1] + ' ' + format[2] + ' ' + format[3] + ' ' + format[4]
  }
}