import styled, { css } from 'styled-components'

export const ReceiptCouponBox = styled.div`
  width: 100%;
  height: 40vw;
  min-width: 240px;
  min-height: 115px;

  ${props => props.sm
    ? css`
        max-width: 260px;
        max-height: 115px;
    `
    : css`
        max-width: 340px;
        max-height: 145px;
    `
  }

  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  ${props => props.active && css`cursor: pointer;`}
`

export const Headcoupon = styled.div`
  width: 35%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 6px 20px -6px #CCCCCC;

  padding: 7px;
  background: #F0F0F1;
  border-radius: 10px;
  position: relative;
  
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  border-width: 1px;
  border-style: solid;
  border-color: #b5bbc3;
`

export const HeadcouponAbsolute = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  > * {
    transform: rotate(-45deg);
  }
`

export const LineCut = styled.div`
  width: 1px; 
  z-index: 1;
  position: relative;

  height: 90%;
  border-left: 2px dashed ${props => props.theme.primary};
`

export const IconCut = styled.div`
  position: absolute;
  left: -9.5px;
  bottom: -7px;

  font-size: 20px;
  transform: rotate(270deg);
  color: ${props => props.theme.primary};
`

export const Tailcoupon = styled.div`
  width: 65%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 6px 20px -6px #CCCCCC;

  overflow: hidden;
  border-radius: 10px;
  background: #F0F0F1;

  border-width: 1px;
  border-style: solid solid solid none;
  border-color: #b5bbc3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const BusinessBar = styled.div`
  height: 100%;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const QRBox = styled.div`
  width: 65%;
  position: relative;
`

export const QRImgBox = styled.img`
  width: 100%;
  ${props => props.example && css`opacity: 0.35;`}
`

export const UnderLineType = styled.div`
  width: 100%;
  height: 5px;

  ${props => props.type === 'discount'
    ? css`background: ${props => props.theme.red};`
    : css`background: ${props => props.theme.blue};`
  }
  
`

export const BoxInputType = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  margin-bottom: 5px;
`

export const BoxImageCoupon = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const BoxDateTimeCoupon = styled.div`
  right: 1px;
  bottom: 6px;
  position: absolute;

  ${props => props.danger
    ? css`color: ${props.theme.red};`
    : css`color: ${props.theme.primary};`
  }
  
  text-align: right;
  border-radius: 10px;
  background: rgba(250,250,250,.9);

  margin: 0px;
  padding: 3px 7px;

  font-size: 10px;
  @media (max-width: 990px) {
    font-size: 8px;
  }
`

export const BoxReceiptCoupon = styled.div`
  padding: 0px 0.5rem;
  max-height: 85vh;
  overflow-x: hidden;
  overflow-y: scroll;
`

export const ShowStat = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`