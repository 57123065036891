import {
  TOGGLE_MODAL,
  TOGGLE_MODAL_MEMBER,
  TRANSFERS
} from '../actions_const'

const initState = {
  isOpen: false,
  memberBadgeCode: '',
  search: '',
  activeTab: '',
  hiddenTab: [],
}

const ReducerModal = (state = initState, action) => {
  switch (action.type) {
    case TOGGLE_MODAL:
      return Object.assign({}, state, { isOpen: action.data })
    case TRANSFERS.TRANSFER_POINT_SUCCESS:
      return Object.assign({}, state, { isOpen: false })
    case TOGGLE_MODAL_MEMBER:
      return Object.assign({}, state, {
        memberBadgeCode: action.data.memberBadgeCode || '',
        search: action.data.option ? (action.data.option.search || '') : '',
        activeTab: action.data.option ? (action.data.option.activeTab || '') : '',
        hiddenTab: action.data.option ? (action.data.option.hiddenTab || []) : '',
      })
    default:
      return state
  }
}

export default ReducerModal;