import { RFM } from "../actions_const";
import axios from "./../axios_request";
import { businessAPI } from "../end_point";
import moment from "moment";
import { getCookie } from "../../libs/cookies";
import { ERROR } from "./../../libs/utils";

const cookie_name_token = "_token";

export const getMemberCircle = (
  {
    business_code,
    start_date = moment().subtract(6, "month").startOf("month"),
    end_date = moment().endOf("month"),
    id,
    limit,
    offset,
  },
  callback
) => {
  let isFunc = typeof callback === "function";

  return (dispatch) => {
    axios({
      method: "post",
      url: `${businessAPI}/${business_code}/rfm/getMemberRFMReport`,
      headers: { Authorization: getCookie("_token") },
      data: { start_date, end_date, id, limit, offset, decrypt: false },
    })
      .then(({ data }) => {
        isFunc && callback(null, "Done");
        return dispatch({
          type: RFM.GET_RFM_GROUP_DATA,
          data: (data && data.data) || [],
          id: id,
        });
      })
      .catch((err) => {
        isFunc && callback(err);
        return dispatch({
          type: RFM.GET_RFM_GROUP_DATA,
          data: [],
          id: id,
        });
      });
  };
};

export const getTotalRFMGroup = (
  {
    business_code,
    start_date = moment().subtract(6, "month").startOf("month"),
    end_date = moment().endOf("month"),
  },
  callback
) => {
  let isFunc = typeof callback === "function";
  return (dispatch) => {
    axios({
      method: "post",
      url: `${businessAPI}/${business_code}/rfm/getTotalRFMGroup`,
      headers: { Authorization: getCookie("_token") },
      data: { start_date, end_date },
    })
      .then(({ data }) => {
        isFunc && callback(null, data.data);
        let Data = data.data;

        const result = [];
        const segmentGroupIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

        for (const id of segmentGroupIds) {
          const item = Data.find((item) => item.segment_group_id === id);
          result.push(item ? parseInt(item.count) : 0);
        }

        return dispatch({
          type: RFM.GET_TOTAL_RFM_GROUP,
          data: result,
        });
      })
      .catch((err) => {
        isFunc && callback(err);
        return dispatch({
          type: RFM.GET_TOTAL_RFM_GROUP,
          data: [],
        });
      });
  };
};

export const getRFMGender = (
  {
    business_code,
    start_date = moment().subtract(6, "month").startOf("month"),
    end_date = moment().endOf("month"),
  },
  callback
) => {
  let isFunc = typeof callback === "function";

  return (dispatch) => {
    axios({
      method: "post",
      url: `${businessAPI}/${business_code}/rfm/getRFMGender`,
      headers: { Authorization: getCookie("_token") },
      data: { start_date, end_date },
    })
      .then(({ data }) => {
        isFunc && callback(null, data.data);

        let Data = data.data;

        const result = {};

        for (let id = 1; id <= 10; id++) {
          result[id] = { id, male: 0, female: 0, unspecified: 0 };
        }

        Data.forEach((item) => {
          const id = item.segment_group_id;
          const gender = item.gender || "unspecified";
          const count = parseInt(item.count);

          result[id][gender] += count;
        });

        const genderObject = Object.values(result);

        return dispatch({
          type: RFM.GET_GENDER,
          data: genderObject,
          totalCount: Data[0].total_count,
        });
      })
      .catch((err) => {
        isFunc && callback(err);
        return dispatch({
          type: RFM.GET_GENDER,
          data: {},
        });
      });
  };
};

export const resetDataRFMForTable = () => {
  return (dispatch) => {
    dispatch({ type: RFM.RESET_DATA_RFM_FOR_TABLE });
  };
};

export const getMemberRFMReport = (
  {
    business_code,
    start_date = moment().subtract(6, "month").startOf("month"),
    end_date = moment().endOf("month"),
    id,
    limit,
    offset,
  },
  callback
) => {
  let isFunc = typeof callback === "function";

  return (dispatch) => {
    axios({
      method: "post",
      url: `${businessAPI}/${business_code}/rfm/getMemberRFMReport`,
      headers: { Authorization: getCookie("_token") },
      data: { start_date, end_date, id, limit, offset, decrypt: true },
    })
      .then(({ data }) => {
        isFunc && callback(null, "Done");
        dispatch({ type: RFM.LOADING_DATA_MEMBER });

        return dispatch({
          type: RFM.GET_DATA_RFM_FOR_TABLE,
          data: (data && data.data) || [],
        });
      })
      .catch((err) => {
        isFunc && callback(err);

        return dispatch({
          type: RFM.GET_DATA_RFM_FOR_TABLE,
          data: [],
        });
      });
  };
};

export const getDataRFMForExport = (
  {
    business_code,
    start_date = moment().subtract(6, "month").startOf("month"),
    end_date = moment().endOf("month"),
    id,
  },
  callback
) => {
  let isFunc = typeof callback === "function";

  return (dispatch) => {
    axios({
      method: "post",
      url: `${businessAPI}/${business_code}/rfm/getDataRFMForExport`,
      headers: { Authorization: getCookie("_token") },
      data: { start_date, end_date, id },
    })
      .then(({ data }) => {
        dispatch({ type: RFM.SET_IS_LOADING_DATA });

        isFunc && callback(null, data.data);

        return dispatch({
          type: RFM.GET_DATA_RFM_FOR_EXPORT,
          data: (data && data.data) || [],
        });
      })
      .catch((err) => {
        isFunc && callback(err);

        return dispatch({
          type: RFM.GET_DATA_RFM_FOR_EXPORT,
          data: [],
        });
      });
  };
};

export const sendEmailReportRFM = (
  { business_code, start_date, end_date, sendmail, language, id },
  callback
) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${businessAPI}/${business_code}/rfm/exportRFMReportToEmail`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        start_date: start_date,
        end_date: end_date,
        sendmail,
        language,
        id,
      },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null);
        } else if (typeof callback === "function")
          callback(new Error("internal-error"));
      })
      .catch((err) => {
        ERROR("sendEmailReportRFM catch", err);
        if (typeof callback === "function") {
          callback(err);
        }
      });
  };
};

export const setCurrentPage = (page) => {
  return (dispatch) => {
    dispatch({ type: RFM.SET_CURRENT_PAGE, data: page });
  };
};

export const resetDataRFM = () => {
  return (dispatch) => {
    dispatch({ type: RFM.RESET_DATA_RFM });
  };
};
