import sha1 from 'sha1'
import { setCookie } from './cookies';
import { getIdToken } from './FirebaseAccount';
import { ERROR } from './utils';

export function setToken() {
  return new Promise((resolve, reject) => {
    getIdToken()
      .then(token => {
        setCookie('_token', token);

        resolve(token);
      })
      .catch(err => {
        ERROR('setToken', err);
        reject(err);
      });
  });
}

export function setUUID() {
  const uuid = sha1(new Date().getTime().toString() + getRandom8Digits());
  setCookie('_uuid', uuid);
  return uuid;
}

function getRandom8Digits() {
  return Math.round(Math.random()*100000000);
}

export function generateTabUUID() {
  const uuid = sha1(new Date().getTime().toString() + getRandom8Digits());
  return uuid;
}