import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Title3 } from '../../components/Font'

class JobStatus extends React.Component {
  render() {
    let { status, store_language: { dictionary } } = this.props
    let status_style = {
      success: {
        icon: "fas fa-check-circle fa-md",
        color: "text-success",
        word: dictionary.successful
      },
      failure: {
        icon: "fas fa-exclamation-circle  fa-md",
        color: "text-danger",
        word: dictionary.partial_failure
      },
      pending: {
        icon: "fas fa-spinner fa-pulse  fa-md",
        color: "text-primary",
        word: dictionary.in_progress
      },
      cancel: {
        icon: "fas fa-ban",
        color: "text-danger  fa-md",
        word: dictionary.cancel
      },
      stop: {
        icon: "fas fa-minus-circle  fa-md",
        color: "text-warning",
        word: dictionary.stoped
      },
    }
    return status && <Title3 bold className={`${status_style[status].color}`} >
      <i className={status_style[status].icon} >  </i>
      {` ${status_style[status].word}`}
    </Title3>
  }
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({

  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobStatus))