import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { getCookieJSON } from '../../libs/cookies'
import { Tooltip } from 'reactstrap'

import { Title2 } from '../Font'
import Tutorial from '../Tutorial'
import {
  DropdownIconSpace, DropdownItem,
  DropdownCardBox, DropdownItemName,
} from './Dropdown.style'

import { ComponentIcon } from '../Titlebar/Titlebar.style'
const this_menu = 'pointspot-tutorial'

class DropdownManual extends Component {
  constructor(props) {
    super(props)
    this.state = {
      DropdownShow: false,
      tooltipOpen: false,
      isOpenTutorial: false
    }
    this.closeMenu = this.closeMenu.bind(this)
    this.showMenu = this.showMenu.bind(this)
    this.toggle = this.toggle.bind(this)
    this.togglTutorial = this.togglTutorial.bind(this)
  }

  showMenu(event) {
    event.preventDefault()
    this.setState({ DropdownShow: true }, () => {
      document.addEventListener('click', this.closeMenu)
    })
  }

  closeMenu() {
    this.setState({ DropdownShow: false }, () => {
      document.removeEventListener('click', this.closeMenu)
    })
  }

  toggle() {
    this.setState({ tooltipOpen: !this.state.tooltipOpen })
  }

  togglTutorial() {
    this.setState({ isOpenTutorial: !this.state.isOpenTutorial })
  }

  render() {
    let { store_business } = this.props
    let { store_language: { dictionary } } = this.props
    let showTutorial = store_business && store_business.permission && store_business.permission[this_menu] && store_business.permission[this_menu].permission_view

    let menuList = [{
      key: 'pointspot_manual',
      title: dictionary.goto_manual,
      show: true,
      icon: 'fas fa-book',
      function: () => {
        window.open(process.env.REACT_APP_GOTO_MANUAL, '_blank');
      }
    }, {
      key: 'pointspot_welcome_page',
      title: dictionary.welcome_page,
      show: store_business && store_business.current && store_business.current.business_code && Boolean(getCookieJSON(`__skipWCP`)[store_business.current.business_code]),
      icon: 'fas fa-home',
      function: () => {
        if (window.location.pathname !== '/welcome')
          this.props.history.push('/welcome')
      }
    }, {
      key: 'pointspot_tutorial',
      title: dictionary.tutorial,
      show: showTutorial,
      icon: 'fas fa-play-circle',
      function: this.togglTutorial
    }, {
      key: 'support',
      title: dictionary.support,
      show: true,
      icon: 'fas fa-question',
      function: () => {
        window.open(process.env.REACT_APP_GOTO_SUPPORT, '_blank');
      }
    }, {
      key: 'support_new_feature',
      title: dictionary.feature_request,
      show: true,
      icon: 'fas fa-lightbulb',
      function: () => {
        window.open(process.env.REACT_APP_GOTO_SUPPORT, '_blank');
      }
    }]

    return <div className='position-relative'>
      <ComponentIcon isOpen={this.props.isOpen} id={'menu_manual'} onClick={this.showMenu}>
        <DropdownIconSpace>
          <i className="fas fa-book" />
        </DropdownIconSpace>
      </ComponentIcon>
      <Tooltip placement="bottom-end" isOpen={this.state.tooltipOpen} target={'menu_manual'} toggle={this.toggle} delay={750}>
        {dictionary.goto_manual}
      </Tooltip>

      <DropdownCardBox hidden={!this.state.DropdownShow}>
        {
          menuList.map((value, key) => {
            return value.show && <DropdownItem key={key} onClick={value.function}>
              <div className='d-flex justify-content-start align-items-center'>
                <i className={`${value.icon} text-center`} style={{ fontSize: 13, width: 25 }} />
                <DropdownItemName>
                  <Title2 link>{value.title}</Title2>
                </DropdownItemName>
              </div>
            </DropdownItem>
          })
        }
      </DropdownCardBox>
      {
        showTutorial && <Tutorial toggleModal={this.togglTutorial} isOpen={this.state.isOpenTutorial} />
      }
    </div >
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language,
    store_transactions: state.transactions,
    store_business: state.business,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropdownManual))