const node_env = process.env.REACT_APP_ENV
const isDebug = node_env !== 'production'

const TRACE = (...args) => {
  let _console = console;
  _console.log.apply(_console, ['T>', ...args]);
}
const DEBUG = (...args) => {
  if (!isDebug) return;
  let _console = console;
  _console.log.apply(_console, ['D>', ...args]);
}
const ERROR = (...args) => {
  if (!isDebug) return;
  let _console = console;
  _console.error.apply(_console, ['E>', ...args]);
}

module.exports = {
  isDebug, node_env, TRACE, DEBUG, ERROR
}