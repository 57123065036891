import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMemberCardInfo } from '../../redux/actions/action_business'

/**
 * Get member_card_info from redux store at first.
 * If the member_card_info is undefined then dispatch to get member_card_info.
 * @returns {Array}
 */
export const useGetMemberCard = () => {
	const businessCode = useSelector((state) => state.business.current.business_code)
	const memberCard = useSelector((state) => state.business.member_card_info)
	const dispatch = useDispatch()
	const isMounted = useRef()

	useEffect(() => {
		isMounted.current = true

		if (!memberCard) {
			dispatch(getMemberCardInfo(businessCode, () => {
				if (!isMounted.current) return null
			}))
		}

		return () => isMounted.current = false
	}, [dispatch, memberCard, businessCode])

	return memberCard
}
