import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { Title3, Title4 } from '../../components/Font'

class JobStatus extends React.Component {
  render() {
    let { status, store_language: { dictionary } } = this.props
    let status_style = {
      success: { color: "text-success", icon: "fas fa-check-circle fa-md", word: dictionary.successful },
      failure: { color: "text-danger", icon: "fas fa-exclamation-circle fa-md", word: dictionary.failed },
      pending: { color: "text-primary", icon: "fas fa-spinner fa-pulse fa-md", word: dictionary.in_progress },
      cancel: { color: "text-danger fa-md", icon: "fas fa-ban", word: dictionary.cancel },
      stop: { color: "text-warning", icon: "fas fa-minus-circle fa-md", word: dictionary.stoped },
    }
    return <>
      {
        status
        && <Title3 bold className={`${status_style[status].color}`} >
          <i className={status_style[status].icon} />
          {` ${status_style[status].word}`}
        </Title3>
      }
      <ErrorStatusDetail {...this.props} />
    </>
  }
}

const ErrorStatusDetail = (props) => {
  let { status, status_detail, store_language: { dictionary } } = props
  if (status !== 'failure')
    return null

  if (!Boolean(status_detail && status_detail.error))
    return null

  return <Title4 className='text-danger'>{dictionary.cron_job_error[status_detail.error] || ''}</Title4>

}

const mapStateToProps = (state) => {
  return {
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({

  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JobStatus))