import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { InvertBtn, PrimaryBtn } from "../../components/Button";
import { Title2, Title4 } from "../../components/Font";
import { toCurrency } from "../../libs/currency";
import { DEBUG } from "../../libs/utils";
import { formatDateTime } from "../../libs/date";
import {
  chargeInvoice,
  getPaymentData,
} from "../../redux/actions/action_manager";
import { BoxProcess, BoxProcessHead, VerifyModalFooter, VerifyModalHeader } from "./verifyBusinessHash.style";
import Loading from "./Loading";
import ReactPaginate from "react-paginate";
import CreditCard from "../../components/BusinessPayment/Card/CreditCard";
import ConfirmModal from "./ConfirmModal";

export default function InvoiceInfo({ business_code }) {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(true);
  const [isShowCardListModal, setShowCardListModal] = useState(false);
  const [isShowConfirmModal, setShowConfirmModal] = useState(false);
  const isInited = useRef(false);
  const selectedInvoice = useRef(null);
  const selectedCard = useRef(null);
  const isLoading = useSelector((state) => state.manager.isLoadingInvoiceInfo);
  const customerToken = useSelector((state) => state.manager.customerToken);
  const paymentInfo = useSelector((state) => state.manager.paymentInfo) || {};
  const isPrepaid = useSelector((state) => state.manager.isPrepaid);
  const { invoices = [], cards = [] } = paymentInfo;

  useEffect(() => {
    DEBUG("[InvoiceInfo][useEffect]", customerToken);
    if (customerToken && !isInited.current) {
      if (!isInited.current) {
        isInited.current = true;
      }

      dispatch(
        getPaymentData(customerToken, business_code)
      );
    }
  }, [customerToken, business_code, dispatch]);

  const onPayClicked = (invoice_id) => {
    DEBUG("onPayClicked", invoice_id);
    selectedInvoice.current = invoice_id;
    if (isPrepaid) {
      setShowConfirmModal(true);
    } else if (cards.length) {
      setShowCardListModal(true);
    }
  }

  const onCardSubmitted = (card) => {
    DEBUG("onCardSubmitted", card);
    selectedCard.current = card.id;
    setShowCardListModal(false);
    setShowConfirmModal(true);
  }

  const onPaymentConfirm = () => {
    DEBUG("onPaymentConfirmed", selectedInvoice.current, selectedCard.current);
    dispatch(
      chargeInvoice({
        customer_token_id: customerToken,
        invoice_id: selectedInvoice.current,
        card_id: selectedCard.current,
        business_code,
      })
    );
  }

  return (
    <BoxProcess>
      <BoxProcessHead
        className="justify-content-between align-items-center"
        pointer
        onClick={() => setExpand(!expand)}
      >
        <Title2 bold white>
          ใบแจ้งหนี้
        </Title2>
        {expand ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
      </BoxProcessHead>
      {expand ? (
        isLoading ? (
          <Loading />
        ) : (
          <div style={{ padding: "20px" }}>
            {
              paymentInfo.invoices ? <InvoiceList invoices={invoices} validPayment={isPrepaid || cards.length} onPayClicked={onPayClicked} /> : <Title2 className="text-center" secondary>ไม่มีใบแจ้งหนี้</Title2>
            }
          </div>
        )
      ) : null}
      {
        isShowCardListModal && (
          <CardListModal
            cards={cards}
            isOpen={isShowCardListModal}
            toggle={() => setShowCardListModal(!isShowCardListModal)}
            onSubmitted={onCardSubmitted}
          />
        )
      }
      {
        isShowConfirmModal && (
          <ConfirmModal
            isOpen={isShowConfirmModal}
            toggle={() => setShowConfirmModal(!isShowConfirmModal)}
            text="กรุณายืนยันการชำระเงิน"
            onConfirm={onPaymentConfirm}
            closeOnError={true}
          />
        )
      }
    </BoxProcess>
  );
}

const InvoiceList = ({ invoices = [], validPayment = false, onPayClicked }) => {
  const limit = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const dictionary = useSelector((state) => state.language.dictionary);
  const firstInvoice = _.first(invoices) || {};
  const showPayButton = validPayment && (firstInvoice.invoice_status === "failed" || firstInvoice.charge_status === "failed");

  const onPageChanged = ({ selected: page }) => {
    //console.log("onPageChanged", page);
    setCurrentPage(page);
  }

  const start = currentPage * limit;
  const end = start + limit;
  const displayedInvoices = _.slice(invoices, start, end);

  DEBUG("InvoiceList", invoices, currentPage, start, end, displayedInvoices);

  return (
    <>
      <div className="d-flex mb-3 align-items-center">
        <div className="w-100">
          <Title2 bold>วันที่ออกใบแจ้งหนี้</Title2>
        </div>
        <div className="w-100 text-center">
          <Title2 bold>สถานะ</Title2>
        </div>
        <div className="w-100 text-right">
          <Title2 bold>จำนวนเงิน (บาท)</Title2>
        </div>
        {
          showPayButton ? <div className="w-100"></div> : null
        }
      </div>
      {displayedInvoices.map((invoice) => {
        const createDate = moment(invoice.invoice_create_date);
        const updateDate = moment(invoice.invoice_update_date);
        const status = (invoice.invoice_status === 'create' 
          ? (invoice.charge_status === 'failed' ? invoice.charge_status : 'processing') 
          : invoice.invoice_status);
        return (
          <div className="d-flex mb-3 align-items-baseline justify-content-between" key={invoice.invoice_id}>
            <div className="w-100">
              <Title2>{formatDateTime(createDate.valueOf())}</Title2>
            </div>
            <div className="w-100 text-center">
              <Title2 danger={status === 'failed'}>{dictionary.invoice_status[status]}</Title2>
              <Title4 secondary danger={status === 'failed'}>{`อัปเดต : ${formatDateTime(updateDate.valueOf())}`}</Title4>
            </div>
            <div className="w-100 text-right">
              <Title2>{toCurrency(invoice.charge_amount / 100, 2)}</Title2>
            </div>
            {
              showPayButton ? (
                <div className="w-100 text-right">
                  {
                    invoice.invoice_id === firstInvoice.invoice_id ? <PrimaryBtn onClick={() => { onPayClicked(invoice.invoice_id) }}>ชำระ</PrimaryBtn> : null
                  }
                </div>
              ) : null
            }
          </div>
        );
      })}
      {
        invoices.length > 5 ? (
          <div className="mt-5 d-flex justify-content-center">
            <ReactPaginate
              pageCount={invoices.length / limit}
              pageRangeDisplayed={3}
              activeClassName={"active"}
              pageClassName={"page-items"}
              containerClassName={"pagination"}
              forcePage={currentPage}
              subContainerClassName={"pages pagination"}
              onPageChange={onPageChanged}
              nextLabel={<i className="fas fa-chevron-right" />}
              previousLabel={<i className="fas fa-chevron-left" />}
            />
          </div>
        ) : null
      }
    </>
  );
};

const CardListModal = ({ cards = [], isOpen, onSubmitted, toggle }) => {
  const [selectedCard, setSelectedCard] = useState(cards[0]);
  const onCardSelected = (id) => {
    DEBUG("CardListModal onCardSelected", id);
    setSelectedCard(_.find(cards, { id: id }));
  }

  return (
    <Modal isOpen={isOpen} centered={true}>
      <VerifyModalHeader>
        <Title2 white bold>ช่องทางการชำระเงิน</Title2>
      </VerifyModalHeader>
      <ModalBody>
        {
          cards.map((value, key) => {
            return <CreditCard
              key={key}
              Id={value.id}
              Name={value.name}
              Brand={value.brand}
              Last4={value.last4}
              ExpYear={value.exp_year}
              ExpMonth={value.exp_month}
              SelectCard={selectedCard.id === value.id}
              onClickSelectCard={onCardSelected}
            />
          })
        }
      </ModalBody>
      <VerifyModalFooter>
        <InvertBtn onClick={toggle}>ยกเลิก</InvertBtn>
        <PrimaryBtn
          className="ml-2"
          onClick={() => { onSubmitted(selectedCard) }}
        >ยืนยัน</PrimaryBtn>
      </VerifyModalFooter>
    </Modal>
  )
}
