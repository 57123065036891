import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTargetAllMemberTotal, setTargetMemberTotal } from '../../../redux/actions/action_broadcast';
import { toastError } from '../../ToastComponent/ToastError';

export default function TargetAllMember({ noRequest }) {
	const businessCode = useSelector((state) => state.business.current.business_code)
	const dictionary = useSelector((state) => state.language.dictionary)
	const dispatch = useDispatch()
	const isMounted = useRef()

	useEffect(() => {
		isMounted.current = true

		if (isMounted.current) {
			if (!noRequest) {
				dispatch(getTargetAllMemberTotal(businessCode, (err, data) => {
					if (!isMounted.current) return null
					if (err) toastError(dictionary.error)
				}))
			} else {
				dispatch(setTargetMemberTotal({
					target: 'all'
				}))
			}
		}

		return () => {
			isMounted.current = undefined
		}
	}, [dispatch, businessCode, dictionary, noRequest])

	return (
		<></>
	)
}