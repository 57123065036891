import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { Title2 } from '../../components/Font'
import StickyMenu from '../../components/StickyMenu'
import { TransparentBtn } from '../../components/Button'

class SendJobs extends React.Component {
  changePage() {
    this.props.history.push(this.props.customRoute ? this.props.customRoute : '/jobs');
  }
  render() {
    let { store_language: { dictionary } } = this.props
    return (
      <StickyMenu>
        <TransparentBtn onClick={this.changePage.bind(this)}>
          <Title2 bold><i className="fas fa-chevron-left" /> {dictionary.back}</Title2>
        </TransparentBtn>
      </StickyMenu>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    store_members: state.members,
    store_business: state.business,
    store_jobs: state.jobs,
    store_language: state.language
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendJobs))

