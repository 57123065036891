import styled from "styled-components";

export const LegendChart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    :not(:last-child) {
      padding-right: 25px;
    }
  }
`;