import styled from 'styled-components'

export default styled.div`
  top: 50px;
  position: sticky;
  min-height: 50px;
  padding: 10px 15px;
  width: 100%;
  z-index: 998;
  display: flex;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
`