import React from "react";
import BusinessUsageChartCreditUsageDoughnut from "./Chart/BusinessUsageChartCreditUsageDoughnut";
import { Row, Col } from "reactstrap";
import BusinessUsageChartLegend from "./Chart/BusinessUsageChartLegend";
import { useSelector } from "react-redux";
import { toCurrency } from "../../../../libs/currency";
import { Title2 } from "../../../Font";
import useOverageTransactionData from "./Chart/hooks/useOverageTransactionData";




const ModalBodyBusinessUsageOverageDoughnut = () => {
  const { dictionary } = useSelector((state) => state.language);
  const current = useSelector((state) => state.business.current);
  const { credit, overageTransaction } = useOverageTransactionData();

  return (
    <div className="bg-white mt-3 p-3 rounded">
      <Title2 bold className="p-2">
        {dictionary.chart_overage_tx}
      </Title2>

      <div className="pt-3">
        <Row className="d-flex align-items-center" style={{ justifyContent: 'space-evenly' }}>
          <Col xs={12} md={6} lg={3} className="d-flex flex-column justify-content-center align-items-center p-2">
            <BusinessUsageChartCreditUsageDoughnut title={dictionary.chart_doughnut_tx} type="tx" />
          </Col>
          <Col xs={12} md={6} lg={3} className={`${current?.current_package === 'ps-f200' ? 'd-none' : 'd-flex'} flex-column justify-content-center align-items-center p-2`}>
            <BusinessUsageChartCreditUsageDoughnut title={dictionary.chart_doughnut_sms} type="sms" />
          </Col>
          <Col xs={12} md={6} lg={3} className={`${!current?.is_use_line_messaging ? 'd-none' : 'd-flex'} flex-column justify-content-center align-items-center p-2`}>
            <BusinessUsageChartCreditUsageDoughnut title={dictionary.chart_doughnut_line} type="line" />
          </Col>
          <Col xs={12} md={6} lg={3} className="d-flex flex-column justify-content-center align-items-center p-2">
            <BusinessUsageChartCreditUsageDoughnut title={dictionary.chart_doughnut_email} type="email" />
          </Col>
        </Row>
      </div>
      <div className="p-2 mt-3">
        <BusinessUsageChartLegend overage={credit} type="Credit-Usage" />
      </div>
      <div className="border-top d-flex pt-3 justify-content-end">
        <Title2 bold className="pr-3">{dictionary.chart_summary_credit}</Title2>
        <Title2 bold>{toCurrency(overageTransaction?.totalCreditUsage || 0, 2)}</Title2>
      </div>
    </div>
  );
};

export default ModalBodyBusinessUsageOverageDoughnut;
