import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { ChartColor } from "./ChartConstant";
import { LegendChart } from "./Chart.style";
import { Title3 } from "../../../../Font";

const legendItems = [
  { color: ChartColor.point, titleKey: "chart_point_title", id: "point" },
  { color: ChartColor.coupon, titleKey: "chart_coupon_title", id: "coupon" },
  { color: ChartColor.broadcast, titleKey: "chart_broadcast_title", id: "broadcast" },
  { color: ChartColor.member_card, titleKey: "chart_member_card_title", id: "member_card" },
  { color: ChartColor.trans_noti_expire_member_card, titleKey: "chart_trans_noti_expire_member_card_title", id: "trans_noti_expire_member_card" },
  { color: ChartColor.reject_collect_points, titleKey: "chart_reject_collect_points_title", id: "reject_collect_points" },
  { color: ChartColor.trans_noti_expire_point, titleKey: "chart_trans_noti_expire_point_title", id: "trans_noti_expire_point" },
];

const BusinessUsageChartLegend = ({ transaction, overage, type }) => {
  const { dictionary } = useSelector((state) => state.language);

  const filteredLegendItems = useMemo(() => {
    return legendItems.filter(item => {
      const overageCondition = overage && overage[item.id] !== 0;
      const transactionCondition = transaction ? transaction[item.id] !== 0 : true;
      return type === "Overview-Usage" 
        ? (overageCondition || transactionCondition) 
        : (overageCondition && transactionCondition);
    });
  }, [transaction, overage, type]);

  return (
    <LegendChart className="p-2">
      {filteredLegendItems.map((item, index) => (
        <div key={index} className="d-flex align-items-center" style={{ marginRight: '2px' }}>
          <div style={{ backgroundColor: item.color, width: 25, height: 15, marginRight: 4 }}></div>
          <Title3>{dictionary[item.titleKey]}</Title3>
        </div>
      ))}
    </LegendChart>
  );
};

export default BusinessUsageChartLegend;