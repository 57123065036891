import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Title2 } from '../../Font'
import { FormGroup, Input } from 'reactstrap'
import { usePrevious } from '../../../libs/hooks/usePrevious'
import { getTargetMemberBirthMonthTotal, setTargetMemberTotal } from '../../../redux/actions/action_broadcast'
import { toastError } from '../../ToastComponent/ToastError'

export default function TargetBirthMonth({ target = '', disabled }) {
	target = target.toString() === '0' ? '' : target
	const [selectedMonth, setSelectedMonth] = useState(target)
	const dictionary = useSelector((state) => state.language.dictionary)
	const business_code = useSelector((state) => state.business.current.business_code)
	const monthPrevious = usePrevious(selectedMonth)
	const dispatch = useDispatch()

	const handleChange = (e) => {
		let { value } = e.target
		setSelectedMonth(value)
	}

	useEffect(() => {
		if (selectedMonth !== monthPrevious) {
			if (selectedMonth !== '') {
				dispatch(getTargetMemberBirthMonthTotal(business_code, selectedMonth, (err) => {
					if (err) toastError(dictionary.error)
				}))
			} else {
				dispatch(setTargetMemberTotal({ target: 'birthmonth' }))
			}
		}
	}, [dispatch, selectedMonth, monthPrevious, business_code, dictionary])


	return (
		<FormGroup>
			<Title2 className='pb-2' bold><span className='text-danger'>* </span>{dictionary.select_birth_month_members}</Title2>
			<Input
				type="select"
				name='target_value'
				data-element="birthmonth"
				value={selectedMonth}
				onChange={handleChange}
				style={{ fontSize: '14px' }}
				disabled={disabled}>
				<MonthList/>
			</Input>
		</FormGroup>
	)
}

const MonthList = () => {
	const dictionary = useSelector((state) => state.language.dictionary)
	const months = [
		dictionary.January, dictionary.February, dictionary.March,
		dictionary.April, dictionary.May, dictionary.June,
		dictionary.July, dictionary.August, dictionary.September,
		dictionary.October, dictionary.November, dictionary.December
	]
	return (
		<>
			<option disabled value="">-- { dictionary.choose } --</option>
			{
				months.map((data, key) => <option value={key + 1} key={`${data}_${key}`}> {data} </option>)
			}
		</>
	)

}