import _ from "lodash";

export const rangeDates = ['rfm_groups_6m', 'rfm_groups_12m'];

export const targetGroups = [{
  value: 'all',
  name: 'all_members',
  isShow: true,
  sub_type: []
}, {
  value: 'rfm_groups',
  name: 'rfm_members',
  isShow: true,
  sub_type: rangeDates
}, {
  value: 'member_receive_reward',
  name: 'member_receive_reward',
  isShow: true,
  sub_type: []
}, {
  value: 'member_purchase_product',
  name: 'member_purchase_product',
  isShow: false,
  sub_type: []
}, {
  value: 'member_label',
  name: 'member_label',
  isShow: true,
  sub_type: []
}, {
  value: 'member_points',
  name: 'member_points',
  isShow: true,
  sub_type: []
}, {
  value: 'birthmonth',
  name: 'birth_month_members',
  isShow: true,
  sub_type: []
}, {
  value: 'member_card',
  name: 'member_card_groups',
  isShow: false,
  sub_type: []
}, {
  value: 'member_pending_group',
  name: 'member_pending_group',
  isShow: false,
  sub_type: []
}, {
  value: 'member',
  name: 'member_business',
  isShow: true,
  sub_type: []
}];

export const getTargetGroups = (showMemberCard) => {

  const options = [...targetGroups];
  // if (forCampaign) {
  //   let birthmonthOptionIndex = _.findIndex(targetGroups, { value: "birthmonth" });
  //   if (birthmonthOptionIndex === -1) {
  //     birthmonthOptionIndex = targetGroups.length;
  //   }
  //   options.splice(birthmonthOptionIndex, 0 , {
  //     value: 'each_birthmonth',
  //     name: 'each_birth_month_members',
  //     isShow: true,
  //     sub_type: []
  //   });
  // }

  if (showMemberCard) {
    const memberCardOption = _.find(targetGroups, { value: 'member_card' });
    if (memberCardOption) {
      memberCardOption.isShow = true;
    }
  }
  return options;
}