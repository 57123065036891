import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import CustomTooltip from "./CustomTooltip";
import { ChartColor } from "./ChartConstant";
import useTooltip from "./hooks/useTooltip";
import { useSelector } from "react-redux";

const BusinessUsageOverviewChartCredit = (props) => {
  const { credit } = props;
  const { dictionary } = useSelector((state) => state.language);

  useTooltip();

  const DoughnutSetting = {
    stack: "Stack 1",
    barThickness: 42,
    maxBarThickness: 50,
  };

  const data = {
    labels: [dictionary.credit],
    datasets: [
      {
        label: dictionary.chart_point_title,
        data: [credit.point],
        backgroundColor: ChartColor.point,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_coupon_title,
        data: [credit.coupon],
        backgroundColor: ChartColor.coupon,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_broadcast_title,
        data: [credit.broadcast],
        backgroundColor: ChartColor.broadcast,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_member_card_title,
        data: [credit.member_card],
        backgroundColor: ChartColor.member_card,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_trans_noti_expire_member_card_title,
        data: [credit.trans_noti_expire_member_card],
        backgroundColor: ChartColor.trans_noti_expire_member_card,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_reject_collect_points_title,
        data: [credit.reject_collect_points],
        backgroundColor: ChartColor.reject_collect_points,
        ...DoughnutSetting,
      },
      {
        label: dictionary.chart_trans_noti_expire_point_title,
        data: [credit.trans_noti_expire_point],
        backgroundColor: ChartColor.trans_noti_expire_point,
        ...DoughnutSetting,
      },
      {
        label: "remain",
        data: [credit.remain === 0 && credit.total === 0 ? 1 : credit.remain],
        backgroundColor: ChartColor.remain,
        ...DoughnutSetting,
      },
    ],
  };

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
    },
    responsive: true,
    indexAxis: "y",
    legend: false,
    title: {
      display: false,
    },
    tooltips: {
      enabled: false,
      position: "nearest",
      custom: CustomTooltip("credit", credit.total),
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          stepSize: 1,
          beginAtZero: true,
        },
      },
      y: {
        stacked: true,
      },
      xAxes: [
        {
          gridLines: { display: false, drawOnChartArea: false, drawTicks: true },
          display: false,
          ticks: {
            min: 0,
            max: credit.total + (credit.remain === 0 && credit.total === 0 ? 1 : credit.remain),
            callback: function (value) {
              return Number(value.toString());
            },
          },
          afterBuildTicks: function (chartObj) {
            chartObj.ticks = [credit.total + (credit.remain === 0 && credit.total === 0 ? 1 : credit.remain)];
          },
        },
      ],
      yAxes: [
        {
          gridLines: { display: false, drawOnChartArea: false, drawTicks: false },
          display: false,
        },
      ],
    },
  };

  return <HorizontalBar height={18} data={data} options={options} />;
};

export default BusinessUsageOverviewChartCredit;
