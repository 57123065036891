import { MESSAGE } from '../actions_const'
import { FB_Firestore } from '../../libs/Firebase'
import { setCookie } from '../../libs/cookies'
import { ERROR } from '../../libs/utils'

export const fetch_message = (callback) => {
  return dispatch => {
    FB_Firestore.collection('broadcast_message').where('show', '==', true).orderBy('timestamp', 'desc').limit(20)
      .onSnapshot(snapshot => {
        let message = []
        snapshot.forEach(queryDataSnapshot => {
          message.push({
            ref_id: queryDataSnapshot.ref.id,
            data: queryDataSnapshot.data()
          })
        })
        dispatch({ type: MESSAGE.FETCH_BROADCAST_MESSAGE, data: message })
        if (typeof callback === 'function') callback(null, message)
      }, error => ERROR('broadcast_message', error))
  }
}
export const read_message = (message_key, callback) => {
  return dispatch => {
    setCookie('__read', { [message_key]: true })

    dispatch({ type: MESSAGE.READ_MESSAGE, data: true })
    if (typeof callback === 'function') callback(null, true)
  }
}