import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { Title3 } from '../Font'
import { LabelCircle } from './label.style'
import { Tooltip } from 'reactstrap';

class LabelMemberCircleList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      mouseOverId: null,
    }
    this.toggle = this.toggle.bind(this);
  }

  toggle(){
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    let { store_members: { userInfo } } = this.props
    let { store_business: { label: { member_label } } } = this.props
    let { member_badge_code, memberLabel, size, className, limit, style, hiddenIcon, index, isModal } = this.props

    if (!member_label || !member_label.length)
      return null

    let member = userInfo && userInfo[member_badge_code]
    let member_tag_label = memberLabel || (member && member.member_label)

    if (!member_tag_label || _.isEmpty(member_tag_label))
      return null

    let labels = (member_label && member_label.length > 0 && member_tag_label)
      ? member_label.filter(f => member_tag_label.indexOf(f.tag_id) > -1)
      : null

    if (!labels || !labels.length)
      return null

    size = size || '15px'
    limit = limit || 5

    return (
      <div
        className={`w-100 d-flex align-items-center ${className}`}
        style={style}
      >
        {!hiddenIcon ? (
          <Title3 style={{ padding: "0px 2px", fontSize: size }}>
            <i className="fas fa-user-tag" />
          </Title3>
        ) : null}

        {labels
          .sort((a, b) => a.tag_name.localeCompare(b.tag_name))
          .map((v, k) => {
            const isMouseOver =
              this.state.mouseOverId === (isModal ? `label_circle_${v.tag_id}_${index}_modal` : `label_circle_${v.tag_id}_${index}`);
            return (
              k < limit && (
                <div key={v.tag_id}>
                  <LabelCircle
                    size={size}
                    color={v.tag_style.tag_color}
                    id={isModal ? `label_circle_${v.tag_id}_${index}_modal` : `label_circle_${v.tag_id}_${index}`}
                    onMouseOver={() =>
                      this.setState({
                        mouseOverId: isModal ? `label_circle_${v.tag_id}_${index}_modal` : `label_circle_${v.tag_id}_${index}`,
                      })
                    }
                    onMouseOut={() => this.setState({ mouseOverId: null })}
                  />
                  {isMouseOver && (
                    <Tooltip
                      placement="bottom"
                      isOpen={true}
                      target={isModal ? `label_circle_${v.tag_id}_${index}_modal` : `label_circle_${v.tag_id}_${index}`}
                      toggle={() => this.toggle()}
                      delay={100}
                    >
                      {v.tag_name}
                    </Tooltip>
                  )}
                </div>
              )
            );
          })}

        {labels.length > limit && (
          <Title3 secondary bold className="ml-1" style={{ fontSize: size }}>
            ...
          </Title3>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_members: state.members,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LabelMemberCircleList)







