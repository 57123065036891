import { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import { getTargetRFM } from '../../../redux/actions/action_broadcast';
import { toastError } from '../../ToastComponent/ToastError';

export const useGetTargetRFMGroups = ({ init = '', noRequest }) => {
	const [targetRange, setTargetRange] = useState(init);
	const business_code = useSelector(
		(state) => state.business.current.business_code
	);
	const dictionary = useSelector((state) => state.language.dictionary)
	const dispatch = useDispatch();
	const isMounted = useRef();

	const findRange = useCallback(() => {
		let start_date = '';
		let end_date = moment().endOf('month').format('YYYY-MM-DD 23:59:59');

		switch (targetRange) {
			case 'rfm_groups_6m':
				start_date = moment()
					.subtract(6, 'month')
					.startOf('month')
					.format('YYYY-MM-DD 00:00:00');
				break;
			case 'rfm_groups_12m':
				start_date = moment()
					.subtract(12, 'month')
					.startOf('month')
					.format('YYYY-MM-DD 00:00:00');
				break;
			default:
				start_date = moment()
					.subtract(6, 'month')
					.startOf('month')
					.format('YYYY-MM-DD 00:00:00');
				break;
		}
		return Object.assign({
			range: targetRange,
			start_date: start_date,
			end_date: end_date
		});
	}, [targetRange]);

	useEffect(() => {
		if (!isMounted.current && !noRequest) {
			if (targetRange !== '') {
				isMounted.current = true;
				const rangeDate = findRange();
				dispatch(
					getTargetRFM(business_code, {
						range: rangeDate.range,
						start_date: rangeDate.start_date,
						end_date: rangeDate.end_date
					}, (err) => {
						if (err) toastError(dictionary.error)
					})
				);
			}
		}

		return () => {
			isMounted.current = undefined;
		};
	}, [dispatch, findRange, business_code, noRequest, targetRange, dictionary]);

	return [targetRange, setTargetRange];
};

export const useHandleSetTargetMemberTotal = (init = '') => {
	const [segment, setSegment] = useState(init)
	const RFMgroups = useSelector((state) => state.broadcast.target_rfm)

	const memoRFMgroups = useMemo(() => {
		const rfm = get(RFMgroups, 'segment_group', []);
		const total = rfm.filter((f) => f.segment_group_id.toString() === segment.toString())
		let result = []
		if (total.length) {
			result = {
				range: RFMgroups && RFMgroups.range,
				segment_id: segment,
				...total[0],
			}
		}
		return result
	}, [RFMgroups, segment])

	return [segment, setSegment, memoRFMgroups]
}
