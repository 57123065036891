import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Loading from '../../components/Loading'
import Cookies from 'js-cookie'
import { DEBUG } from '../../libs/utils'
import { userLogout } from '../../redux/actions/action_permission'
import { initWalletOwner, createBusiness } from '../../redux/actions/action_wizard'
import { Title1 } from '../../components/Font'
import { InvertBtn } from '../../components/Button'
import WizardContainer from './wizardContainer'
import { ContainerWizard, TitleBar } from './wizard.style'

class wizardIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.props.initWalletOwner((err, data) => {
      let info = data.data
      if (info && info.result && info.data && info.data.business_name && !info.data.archive) {
        let business = {
          owner_id: info.data.owner_id,
          business_name: !info.data.business_name ? 'My Business' : info.data.business_name,
          business_code: info.data.business_code ? info.data.business_code : null
        }
        this.props.createBusiness(business)
      }
      this.setState({ loading: false })
    })
  }

  logout() {
    this.setState({ loading: true })
    DEBUG('logout');
    for (let key in localStorage) {
      delete localStorage[key]
    }
    Cookies.remove('_token');
    Cookies.remove('_email');
    this.props.userLogout();
  }

  render() {
    let { store_language: { dictionary } } = this.props
    let { store_wizard: { business_name ,archive } } = this.props

    return <>
      {
       ( business_name && !archive) || this.state.loading ?
        <Loading />
        : <div>
            <TitleBar>
              <Title1 bold white>{dictionary.create_business}</Title1>
            </TitleBar>
            <ContainerWizard>
              <div />
              <WizardContainer {...this.props} />
              <div />
              <div className='w-100 pr-2 d-flex justify-content-start align-items-center'>
                <InvertBtn bold md onClick={this.logout}>{dictionary.logout}</InvertBtn>
              </div>
            </ContainerWizard>
          </div>
      }
    </>
  }
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
    store_business: state.business,
    store_wizard: state.wizard,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    userLogout,
    initWalletOwner,
    createBusiness
  }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(wizardIndex)