import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import styled from 'styled-components'

class CouponManual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkProps: true,
      // manaul_url: "https://rp.link/0db42e3a40",
      coupon_reward: {
        title: {
          th: 'ตัวอย่างการสร้างของรางวัลสะสมแต้ม',
          en: 'Example of creating redeem rewards'
        },
        keyType: 'coupon_reward',
        description: "Coupon reward Example",
        link_url: 'https://manual.pointspot.co/17387319/redeem-rewards',
        img_url: "https://firebasestorage.googleapis.com/v0/b/rp-loyalty.appspot.com/o/_screenshot%2Fcoupon-1911-rewards.png?alt=media&token=f2c1e94b-874c-4f10-97b0-3caf9c16a894"
      },
      coupon_promotion: {
        title: {
          th: 'ตัวอย่างการสร้างคูปองโปรโมชั่น',
          en: 'Example of creating promotion coupon'
        },
        description: "Coupon promotion Example",
        link_url: 'https://manual.pointspot.co/17387321/promotional-coupons',
        img_url: "https://firebasestorage.googleapis.com/v0/b/rp-loyalty.appspot.com/o/_screenshot%2Fcoupon-1911-promotion.png?alt=media&token=ad56d2e3-0921-4c02-84be-5e0d0257e43e"
      },
      coupon_welcome: {
        title: {
          th: 'ตัวอย่างการสร้างคูปองต้อนรับ',
          en: 'Example of creating welcome coupon'
        },
        description: "Coupon welcome Example",
        link_url: 'https://manual.pointspot.co/17387320/welcome-coupons',
        img_url: "https://firebasestorage.googleapis.com/v0/b/rp-loyalty.appspot.com/o/_screenshot%2Fcoupon-1911-welcome.png?alt=media&token=ab3a718a-b1f2-4f0b-b585-23a118f8ba84"
      },
      coupon_receipt: {
        title: {
          th: 'ตัวอย่างการสร้างคูปองแนบใบเสร็จ',
          en: 'Example of creating receipt coupon'
        },
        description: "Coupon receipt Example",
        link_url: 'https://manual.pointspot.co/17387323/receipt-coupons',
        img_url: "https://firebasestorage.googleapis.com/v0/b/rp-loyalty.appspot.com/o/_screenshot%2Fcoupon-1911-receipt.png?alt=media&token=68e5a798-2773-4aaa-9104-c705b0516475"
      },
      coupon_giveaway: {
        title: {
          th: 'ตัวอย่างการสร้างคูปองแจกฟรี',
          en: 'Example of creating giveaway coupon'
        },
        description: "Coupon giveaway Example",
        link_url: 'https://manual.pointspot.co/17526617/giveaway-coupon',
        img_url: "https://firebasestorage.googleapis.com/v0/b/rp-pointspot-admin-stg.appspot.com/o/image%2FScreen%20Shot%202563-04-16%20at%2009.42.01.png?alt=media&token=7850f449-a14f-4e06-b014-836c06282456"
      },
      giftvoucher: {
        title: {
          th: 'ตัวอย่างการสร้างบัตรกำนัล',
          en: 'Example of creating gift voucher'
        },
        description: "Gift voucher Example",
        link_url: 'https://manual.pointspot.co/17567777/gift-voucher',
        img_url: "https://firebasestorage.googleapis.com/v0/b/rp-pointspot-admin-stg.appspot.com/o/image%2FScreen%20Shot%202563-06-02%20at%209.10.52%20AM.png?alt=media&token=54b5987e-552e-4a5a-ab8a-f930458861fe"
      },
      automation_campaign: {
        title: {
          th: 'ตัวอย่างของการสร้างแคมเปญ',
          en: 'Example of creating campaign'
        },
        description: "Coupon campaign Example",
        link_url: 'https://manual.pointspot.co/automation/coupon',
        img_url: "https://firebasestorage.googleapis.com/v0/b/rp-loyalty.appspot.com/o/_screenshot%2Fautomation-example.png?alt=media&token=323ff43a-a425-4fdb-8a97-18d308177c7b"
      },
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.store_language.locale !== this.props.store_language.locale)
      this.setState({ checkProps: !this.state.checkProps })
  }

  render() {
    let { type, className } = this.props
    let { store_language: { dictionary } } = this.props

    let element = this.state[type || 'coupon_reward']

    return <ContainerCouponManual className={className}>
      <TitleCoupon className='pt-2'>{element.title[dictionary.locale || 'th']}</TitleCoupon>
      <DescriptionCoupon>
        {dictionary.you_can_learn}
        <a href={element.link_url} target="_blank" rel="noopener noreferrer">{' '}{dictionary.get_from_here}</a>
      </DescriptionCoupon>
      <div className='pt-2' >
        <img
          src={element.img_url}
          alt={element.description}
          style={{ width: "100%", maxWidth: '1100px' }}
          data-tut={element.keyType === 'coupon_reward' ? 'p_rewards__first' : ''} />
      </div>
    </ContainerCouponManual >
  }
}

const ContainerCouponManual = styled.div`
  color: ${props => props.theme.primary};
`

const TitleCoupon = styled.h4`
  font-weight: 600;
`

const DescriptionCoupon = styled.h6`
  font-weight: 600;
`
const mapStateToProps = (state) => {
  return {
    store_language: state.language,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponManual)