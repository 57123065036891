import { ERROR } from './../../libs/utils'
import { getCookie } from '../../libs/cookies'

import axios from './../axios_request'
import { businessAPI } from '../end_point'
import { AUTOMATION } from '../actions_const';
import { uploadPhoto } from '../../libs/Firebase';

export const getCampaignList = (business_code, offset, callback) => {
  const isFunc = typeof callback === 'function';
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/ecard/automation/campaigns`,
      headers: { Authorization: getCookie("_token") },
      data: { offset }
    }).then(({ data }) => {
      isFunc && callback(null, data)
      return dispatch({ type: AUTOMATION.AUTOMATION_ECARD_CAMPAIGN_LIST, data: data.data })
    }).catch((err) => {
      ERROR('getCampaignList catch', err.message)
      return isFunc && callback(err)
    })
  }
}

export const deleteCampaign = (business_code, campaign_id, callback) => {
  const isFunc = typeof callback === 'function';
  return dispatch => {
    axios({
      method: 'delete',
      url: `${businessAPI}/${business_code}/ecard/automation/${campaign_id}`,
      headers: { Authorization: getCookie("_token") }
    }).then(({ data }) => {
      isFunc && callback(null, data)
    }).catch((err) => {
      ERROR('getCampaignList catch', err.message)
      return isFunc && callback(err)
    })
  }
}

export const createCampaign = (business_code, data, callback) => {
  return () => {

    const { ecard_info } = data;
    ecard_info.action = {
      type: ecard_info.action_type,
      value: ecard_info.action_value,
    };
    ecard_info.target = {
      type: ecard_info.target_type,
      value: ecard_info.target_value,
    };
    ecard_info.path_location = ecard_info.path_location || `${business_code}/coupons/${new Date().getTime()}.jpeg`;

    uploadPhoto(ecard_info.path_location, ecard_info.image).then((res) => {
      if (res.url) {
        ecard_info.link_url = res.url;
        delete ecard_info.image;
      } else {
        ecard_info.link_url = ecard_info.image;
      }

      axios({
        method: 'post',
        url: `${businessAPI}/${business_code}/ecard/automation/create`,
        headers: { Authorization: getCookie("_token") },
        data: data
      }).then(({ data }) => {
        if (data.result) {
          if (typeof callback === 'function') callback(null, data.data);
        } else if (typeof callback === 'function') {
          callback('result error');
        }
      }).catch((err) => {
        ERROR('createCampaign catch', err.message)
        if (typeof callback === 'function') callback(err);
      })
    }).catch((err) => {
      ERROR('createCampaign catch', err.message)
      if (typeof callback === 'function') callback(err);
    })
  }
}

export const updateCampaign = (business_code, data, callback) => {
  return () => {

    const { ecard_info } = data;
    ecard_info.action = {
      type: ecard_info.action_type,
      value: ecard_info.action_value,
    };
    ecard_info.target = {
      type: ecard_info.target_type,
      value: ecard_info.target_value,
    };

    uploadPhoto(ecard_info.path_location, ecard_info.image).then((res) => {
      if (res.url) {
        ecard_info.link_url = res.url;
        delete ecard_info.image;
      } else {
        ecard_info.link_url = ecard_info.image;
      }

      axios({
        method: 'put',
        url: `${businessAPI}/${business_code}/ecard/automation/info`,
        headers: { Authorization: getCookie("_token") },
        data: data
      }).then(({ data }) => {
        if (data.result) {
          if (typeof callback === 'function') callback(null, data.data);
        } else if (typeof callback === 'function') {
          callback('result error');
        }
      }).catch((err) => {
        ERROR('updateCampaign catch', err.message)
        if (typeof callback === 'function') callback(err);
      })
    }).catch((err) => {
      ERROR('updateCampaign catch', err.message)
      if (typeof callback === 'function') callback(err);
    })
  }
}

export const updateCampaignStatus = (business_code, data, callback) => {
  return () => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/ecard/automation/status`,
      headers: { Authorization: getCookie("_token") },
      data: data
    }).then(({ data }) => {
      if (data.result) {
        if (typeof callback === 'function') callback(null, data.data);
      } else if (typeof callback === 'function') {
        callback('result error');
      }
    }).catch((err) => {
      ERROR('updateCampaignStatus catch', err.message)
      if (typeof callback === 'function') callback(err);
    })
  }
}

export const getAutomationMemberCardTransactions = (business_code, offset, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/memberCard/automation/transactions`,
      headers: { Authorization: getCookie("_token") },
      data: { offset }
    }).then(({ data }) => {
      isFunc && callback(null, data)
      return dispatch({ type: AUTOMATION.AUTOMATION_MEMBER_CARD_TRNASACTIONS, data: data.data })

    }).catch((err) => {
      ERROR('getAutomationMemberCardTransactions catch', err.message)
      return isFunc && callback(err)

    })
  }
}

export const getAutomationEcardTransactions = (business_code, offset, callback) => {
  let isFunc = typeof callback === 'function'
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/ecard/automation/transactions`,
      headers: { Authorization: getCookie("_token") },
      data: { offset }
    }).then(({ data }) => {
      isFunc && callback(null, data)
      return dispatch({ type: AUTOMATION.AUTOMATION_ECARD_TRNASACTIONS, data: data.data })

    }).catch((err) => {
      ERROR('getAutomationEcardTransactions catch', err.message)
      return isFunc && callback(err)
    })
  }
}

export const setTrigger = (data) => {
  return dispatch => {
    return dispatch({ type: AUTOMATION.AUTOMATION_SET_TRIGGER, data })
  }
}
