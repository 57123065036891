import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import BusinessDropdown from './BusinessDropdown'
import { SidebarBusinessRow } from './BusinessProfile.style'


class BusinessProfile extends React.Component {
  render() {
    return <SidebarBusinessRow>
      <BusinessDropdown />
    </SidebarBusinessRow>
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessProfile)

