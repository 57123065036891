import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Title4 } from '../Font'
import styled, { css, keyframes } from 'styled-components'

class ViewMore extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
      first: true,
      showMore: false,
      hiddenButton: true,
      size: this.props.sizeViewMore,
      sizeMax: this.props.sizeViewMore + 10,
    }
    this.showDetail = this.showDetail.bind(this)
  }

  componentDidMount() {
    const height = ReactDOM.findDOMNode(this.refs.childrenData).clientHeight
    this.setState({
      height: height,
      hiddenButton: !(height > this.state.sizeMax)
    })
  }

  showDetail(event) {
    event.stopPropagation()
    this.setState({
      first: false,
      showMore: !this.state.showMore
    })
  }

  render() {
    const { store_language: { dictionary } } = this.props
    return <div>
      <CardEcounponDetail
        show={this.state.showMore}
        size={this.state.size}
        first={this.state.first}
        height={this.state.height} >
        <div ref='childrenData'> {this.props.children} </div>
      </CardEcounponDetail>
      {
        (this.state.hiddenButton && this.props.hiddenComponent)
          ? null
          : <ShowMoreButton>
            <ShowMoreTab
              onClick={this.showDetail}
              hidden={this.state.hiddenButton}>
              <Title4 secondary link className="mr-1">
                {this.state.showMore ? dictionary.show_less : dictionary.show_more}
              </Title4>
              <IconUpDown
                first={this.state.first}
                showMore={this.state.showMore}>
                <Title4 secondary link>
                  <i className={this.state.showMore ? 'fas fa-angle-up' : 'fas fa-angle-down'}></i>
                </Title4>
              </IconUpDown>
            </ShowMoreTab>
          </ShowMoreButton>
      }
    </div>
  }
}

const IconUpDown = styled.div`
  animation-fill-mode: forwards;
  ${props => props.first
    ? null
    : props.showMore
      ? animationup
      : animationDown
  }
`

const animationup = () =>
  css`${rotateIconUp}  0.7s linear;`

const animationDown = () =>
  css`${rotateIconDown}  0.7s linear;`

const rotateIconDown = keyframes`
  from { transform:  rotate(180deg); }
    to { transform:  rotate(360deg); }
`

const rotateIconUp = keyframes`
  from { transform:  rotate(180deg); }
    to { transform:  rotate(0deg); }
`

const CardEcounponDetail = styled.div`
  height: ${props => props.size}px;
  min-height: ${props => props.size}px;
  overflow: hidden;
  animation-fill-mode: forwards;
  animation : ${props => props.first
    ? null
    : props.show
      ? ViewMoreShow(props.height, props.size)
      : ViewMoreHidden(props.height, props.size)} 0.6s ease;

  ${props => props.show && `height: auto;`}
`

const ViewMoreShow = (height, size) => {
  return keyframes` 
    from { height: ${size}px; }
    to { height: ${height}px; }
  `
}

const ViewMoreHidden = (height, size) => {
  return keyframes` 
    from { height: ${height}px; }
    to { height: ${size}px; }
  `
}

const ShowMoreTab = styled.div`
  width-: 95px;
  display: flex;
  align-content: center;
  justify-content: space-between;
`

const ShowMoreButton = styled.div`
  height: 20px;
  min-height: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

const mapStateToProps = (state) => {
  return {
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewMore)