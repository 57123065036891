module.exports = (message, arr) => {
  message
    .match(/\$\d+/g)
    .filter((e, i, a) => i === a.indexOf(e))
    .forEach(e => {
      let regx = new RegExp(`(\\${e}(?!\\d+))`, 'g')
      let index = e.replace('$', '')
      message = message.replace(regx, arr[index - 1] || undefined)
    })
  return message
}

