import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProducts } from '../../redux/actions/action_product';
import { targetGroups } from '../../libs/targetGroups';
import { usePrevious } from '../../libs/hooks/usePrevious';
import { useGetMemberCard } from '../../libs/hooks/useGetMemberCard';
import { get } from 'lodash';

const useGetProductManage = () => {
	const [product, setProduct] = useState([])
	const businessCode = useSelector((state) => state.business.current.business_code)
	const store_packages = useSelector((state) => state.packages)
	const subscriptionItems = get(store_packages,'packages.package_data.items_id', null)
	const packageFeature = get(store_packages,'packages.package_data.feature', null)
	const dispatch = useDispatch()
	const isMounted = useRef()

	useEffect(() => {
		if (!isMounted.current) {
			if (subscriptionItems?.indexOf('add-product_mgmt-free') > -1 || packageFeature?.indexOf('product_management') > -1){
				isMounted.current = true
				dispatch(getProducts({ business_code: businessCode, excludeArchive: true },(err, product) => {
					if (!isMounted.current) return null

					if (!err && product.length > 0) {
						setProduct(product)
					}
				}))
			}
		}

		return () => {
			isMounted.current = undefined
		}
	}, [dispatch, subscriptionItems, packageFeature, businessCode])

	return product.length	
}

export const useTargetGroupList = (type = '') => {
	const dispatch = useDispatch()
	const [targetGroup, setTargetGroup] = useState(targetGroups || [])
	const sender = useSelector((state) => get(state,'business.current.is_sender_name', false))
	const memberCardInfo = useGetMemberCard()
	const previousMemberCard = usePrevious(memberCardInfo)
	const productManage = useGetProductManage()
	const productManagePrevious = usePrevious(productManage)
	const previousType = usePrevious(type)
	const isMounted = useRef()

	const isProductManageEquality = JSON.stringify(productManage) === JSON.stringify(productManagePrevious)

	useEffect(() => {
		if (type !== previousType) {
			setTargetGroup((prevTargetGroup) => {
				return prevTargetGroup.map((target) => 
					(target.value === 'member_receive_reward')
						? { ...target, isShow: type === 'broadcast' }
						: target
				)
			})
		}
	}, [type, previousType])

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true

			if (sender) {
				setTargetGroup((prevTargetGroup) => {
					return prevTargetGroup.map((target) => 
						(target.value === 'member_pending_group')
							? { ...target, isShow: true }
							: target
					)
				})
			}
		}
	}, [dispatch, sender])
	
	useEffect(() => {
		if (JSON.stringify(memberCardInfo) !== JSON.stringify(previousMemberCard) && (memberCardInfo && memberCardInfo[0])) {
			setTargetGroup((prevTargetGroup) => {
				return prevTargetGroup.map((target) =>
					(target.value === 'member_card')
						? { ...target, isShow: true }
						: target
				)
			})
		}
	}, [memberCardInfo, previousMemberCard])

	useEffect(() => {
		if (productManage && !isProductManageEquality) {
			setTargetGroup((prevTargetGroup) => {
				return prevTargetGroup.map((target) =>
					(target.value === 'member_purchase_product')
						? { ...target, isShow: true }
						: target
				)
			})
		}
	}, [productManage, isProductManageEquality])


	return targetGroup
}