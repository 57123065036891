import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Col, Collapse } from 'reactstrap'
import styled, { keyframes } from 'styled-components'

import { DangerBtn } from '../../components/Button'
import { Title2, Title3 } from '../../components/Font'
import BusinessNotificationSMS from '../../components/BusinessNotificationSMS'

import { toCurrency } from '../../libs/currency'
import { formatDate } from '../../libs/date'

import ActionJobsBtn from './ActionJobsBtn'
import JobStatus from '../BatchJobs/JobStatus'
import ActionJobsBtnDel from './ActionJobsBtnDel'
import { hasValueInArray } from '../../libs/validator'

class CronJobsDetailSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggle: true
    }
  }

  toggleBox = () => {
    this.setState({ toggle: !this.state.toggle })
  }

  getPrivilegeListName = (id) => {
    let { store_admin: { privilegeList } } = this.props
    let index = privilegeList && privilegeList.findIndex(index => index.user_id === id)
    let name = (index > -1 && privilegeList && privilegeList[index])
      ? Object.assign({}, {
        display_name: privilegeList[index].display_name,
        fullname: privilegeList[index].fullname,
        first_name: privilegeList[index].fullname && privilegeList[index].fullname.split(' ')[0],
        last_name: privilegeList[index].fullname && privilegeList[index].fullname.split(' ')[1],
      })
      : { id: id }
    return name
  }

  render() {
    let { permission, isLoadingGetOverTrans } = this.props
    let { store_jobs, type } = this.props
    let { store_packages: { packages, packages_free } } = this.props
    let { store_language: { dictionary } } = this.props
    let { store_usage: { usage_limit, usage_record } } = this.props
    const isFreePackage = packages && packages.package_data && packages.package_data.package_id && hasValueInArray(packages_free, packages.package_data.package_id) 


    let isPackageStatusHold = packages && packages.subscription_status === 'hold'

    return store_jobs.jobs.length > 0
      && store_jobs.jobs.map((val, key) => {
        let name = this.getPrivilegeListName(val.modified_by)
        return <Col key={key} style={{ backgroundColor: '#FFF', padding: 1 }}>
          <div className='w-100'>
            <div className='w-100 d-flex align-items-center justify-content-between p-3' style={{ backgroundColor: '#f2f2f2', cursor: 'pointer' }} onClick={this.toggleBox}>
              <Title2 bold>{`${dictionary.job_id} ${val.modified_id}`}</Title2>
              <IconChevron toggle={this.state.toggle}>
                <Title2 bold><i className="fas fa-chevron-up" /></Title2>
              </IconChevron>
            </div>

            <Collapse isOpen={this.state.toggle}>
              <div className='w-100 d-flex align-items-center justify-content-center flex-column border-top'>
                {
                  val.status === 'pending'
                  && <div className='w-100 d-flex align-items-center justify-content-end px-3 py-2'>
                    <Title3 bold link blue onClick={!this.props.isLoadingGetCronJob ? this.props.getCronjobData : null}>
                      {
                        this.props.isLoadingGetCronJob
                          ? <i className="fas fa-spinner fa-pulse" />
                          : <><i className="fas fa-sync-alt" /> {dictionary.refresh}</>
                      }
                    </Title3>
                  </div>
                }
                <div className='w-100'>
                  <BoxDeatail className='flex-column flex-md-column flex-lg-row'>
                    <DetailList title={dictionary.status} value={<JobStatus status={val.status} />} />
                    <DetailList title={dictionary.modified_on} value={formatDate(val.modified_date)} />
                    <DetailList title={dictionary.modified_by} value={name.display_name ? `${name.display_name} (${name.first_name})` : (name.first_name || name.id)} />
                  </BoxDeatail>
                  <BoxDeatail className='flex-column flex-md-column flex-lg-row'>
                    <DetailList title={dictionary.total_job} value={`${val.data && toCurrency(val.total)} ${dictionary.record}`} />
                    <DetailList title={dictionary.success_job} value={`${val.data && toCurrency(val.success)} ${dictionary.record}`} />
                    <DetailList title={dictionary.failure_job} value={`${val.data && toCurrency(val.failure)} ${dictionary.record}`} />
                  </BoxDeatail>
                  {
                    val.data && val.message && (
                      <BoxDeatail className='flex-column flex-md-column flex-lg-row'>
                        <DetailList title={dictionary.notification_message} value={val.message} titleCol={'col-lg-2'} valueCol={'col-lg-10'} />
                      </BoxDeatail>
                    )
                  }
                </div>
              </div>
              <div className='p-2'>
                {
                  (permission)
                    ? isPackageStatusHold
                      ? <div className="w-100 text-center">
                        <DangerBtn bold md>{dictionary.not_do_transaction}</DangerBtn>
                      </div>
                      : <>
                        {
                          (packages && packages.package_data && !isFreePackage) || (packages && packages.package_data && isFreePackage && !usage_limit.isOverUsage && (val.total + parseInt(usage_record.tx_total, 10)) <= parseInt(usage_record.free_transactions, 10))
                            ? ['stop', 'pending'].indexOf(val.status) > -1
                              ? <div className="w-100 text-center">
                                <ActionJobsBtn id={val.id} status={val.status} amount={val.total} isProcess={store_jobs.isProcess} getCronjobData={this.props.getCronjobData} type={type} isLoadingGetOverTrans={isLoadingGetOverTrans} />
                                {' '}
                                <ActionJobsBtnDel id={val.id} status={val.status} isProcess={store_jobs.isProcess} type={type} />
                              </div>
                              : null
                            : <div className="w-100 text-center">
                              <DangerBtn bold md>{dictionary.temporary_exceeding_quotas}</DangerBtn>
                            </div>
                        }
                      </>
                    : null
                }
                <div className='pt-2'>
                  <BusinessNotificationSMS center />
                </div>
              </div>
            </Collapse>
          </div>
        </Col>
      })
  }
}

const DetailList = ({ title, value, titleCol, valueCol }) => {
  return <div className='w-100 d-flex border-bottom' style={{ height: '52px', overflow: 'hidden' }}>
    <div className={`h-100 d-flex align-items-center justify-content-center text-center p-2 ${titleCol ? titleCol : 'col-6'}`} style={{ backgroundColor: '#f2f2f2' }}>
      <Title3 bold>{title}</Title3>
    </div>
    <div className={`h-100 d-flex align-items-center justify-content-center text-center p-2 ${valueCol ? valueCol : 'col-6'}`}>
      <Title3>{value}</Title3>
    </div>
  </div>
}

const BoxDeatail = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const rotateDown = keyframes`
  from { transform:  rotate(0deg); }
    to { transform:  rotate(180deg); }
`

const rotateUp = keyframes`
  from { transform:  rotate(180deg); }
    to { transform:  rotate(0deg); }
`

const IconChevron = styled.div`
  cursor: pointer;
  animation: ${props => props.toggle ? rotateUp : rotateDown} 0.25s linear ;
  animation-fill-mode: forwards;
`

const mapStateToProps = (state) => {
  return {
    store_members: state.members,
    store_business: state.business,
    store_jobs: state.jobs,
    store_language: state.language,
    store_usage: state.usage,
    store_packages: state.packages,
    store_admin: state.admin
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CronJobsDetailSection))
