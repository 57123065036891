import {
  CLEAR_BUSINESS_REVIEW,
  CREATE_BUSINESS_PINCODE,
  END_BUSINESS_PROCESS,
  GET_BUSINESS_BALANCE,
  GET_BUSINESS_INFO_FAIL,
  GET_BUSINESS_INFO_SUCCESS,
  GET_BUSINESS_LABEL,
  GET_BUSINESS_LINE_MEMBERS,
  GET_BUSINESS_LIST_SUCCESS,
  GET_BUSINESS_PINCODE,
  GET_BUSINESS_POINTEXPIRY,
  GET_BUSINESS_MEMBER_CARD_EXPIRY_SETTING,
  GET_BUSINESS_REVIEW,
  GET_BUSINESS_REVIEW_MESSAGE,
  GET_BUSINESS_REVIEW_PROCESS,
  GET_MEMBER_CARD_INFO,
  GET_POLICY_BUSINESS,
  GET_TERMS_BUSINESS,
  GET_VERIFYBUSINESS,
  GET_VERIFYBUSINESS_PROCESS,
  INITIAL_BUSINESS_STATE,
  MEMBER_IS_LOGOUT,
  RELOAD_PAGE,
  SET_BUSINESS_DROPDOWNLIST,
  SET_LOADING_MEMBER_LIST,
  SET_MEMBER_LIST,
  SET_PREMEMBER_LIST,
  START_BUSINESS_EXPIRY_POINTS_PROCESS,
  START_BUSINESS_PROCESS,
  UPDATE_BUSINESS_CONFIG,
  UPDATE_BUSINESS_CONTACT_BRANCH_PRIMARY,
  UPDATE_VERIFYBUSINESS,
  UPDATE_VERIFYBUSINESS_DETAIL,
  GET_BUSINESS_SHAPPY_MEMBERS,
  GET_BUSINESS_INTER_MEMBERS,
  CUSTOM_FORM,
} from '../actions_const';
import { DEBUG, ERROR, TRACE } from './../../libs/utils';
import { getCookie, setCookie } from '../../libs/cookies';

import Caching from "../../libs/caching";
import { deletePhoto, FB_Firestore, uploadPhoto } from "../../libs/Firebase";
import _ from "lodash";
import axios from "./../axios_request";
import { businessAPI, publicAPI } from "../end_point";

const TAG = 'a-business';
const cookie_name_token = '_token';
const cookie_name_rp_admin = 'login_user';

export const getBusinessList = () => {
  let TAGm = TAG + '.getBusinessList';
  DEBUG(TAGm);
  let uuid = getCookie('_uuid');

  let options = {
    method: 'get',
    url: businessAPI + `?uuid=${uuid}`,
    headers: { Authorization: getCookie(cookie_name_token) },
  };

  return (dispatch) => {
    axios(options)
      .then((response) => {
        let body = response.data;
        DEBUG(TAGm, body.result, body.data.length);

        if (!body.result) return dispatch({ type: MEMBER_IS_LOGOUT });
        dispatch({ type: GET_BUSINESS_LIST_SUCCESS, data: body.data });
      })
      .catch((error) => {
        ERROR(TAGm, 'catch', error);
        dispatch({ type: MEMBER_IS_LOGOUT });
      });
  };
};

export const selectBusinessDropdownlist = (businessCode) => {
  let TAGm = TAG + '.selectBusinessDropdownlist';
  DEBUG(TAGm, businessCode);
  Caching.setCaching('business', businessCode);

  return (dispatch) => {
    setCookie('__bnc', businessCode);
    dispatch({ type: SET_BUSINESS_DROPDOWNLIST, businessCode: businessCode });
    dispatch({ type: CUSTOM_FORM.CLEAR_CUSTOM_FORM_TITLE });
  };
};

export const getBusinessInfo = (businessCode, changeTab, callback) => {
  let TAGm = TAG + '.getBusinessInfo';
  DEBUG(TAGm, businessCode);
  Caching.setCaching('business', businessCode);

  return (dispatch) => {
    dispatch({ type: INITIAL_BUSINESS_STATE });
    axios({
      method: 'get',
      url: `${businessAPI}/${businessCode}`,
      headers: { Authorization: getCookie(cookie_name_token) },
    })
      .then((response) => {
        let body = response.data;
        TRACE(TAGm, businessCode, body.data.business_name);
        if (!body.result) {
          if (typeof callback === 'function') callback(!body.result);
          return dispatch({ type: GET_BUSINESS_INFO_FAIL });
        } else {
          if (typeof callback === 'function') callback(null, body.data);
          return dispatch({
            type: GET_BUSINESS_INFO_SUCCESS,
            data: body.data,
            changeTab: changeTab,
          });
        }
      })
      .catch((error) => {
        ERROR(TAGm, 'catch', error);
        return dispatch({ type: GET_BUSINESS_INFO_FAIL });
      });
  };
};

export const getBusinessBalance = (businessCode) => {
  let TAGm = TAG + '.getBusinessBalance';
  DEBUG(TAGm, businessCode);

  return (dispatch) => {
    getBusinessBalanceApi(businessCode).then(dispatch);

    listenBusinessBalance(businessCode, () => {
      getBusinessBalanceApi(businessCode).then(dispatch);
    });
  };
};

export const getBusinessBalanceOnce = (businessCode, callback) => {
  return (dispatch) => {
    getBusinessBalanceApi(businessCode).then((response) => {
      if (typeof callback === 'function') callback(null, response);
      dispatch(response);
    });
  };
};

let document_balance = {};
const listenBusinessBalance = (businessCode, callback) => {
  let TAGm = TAG + '.listenBusinessBalance';
  DEBUG(TAGm, businessCode);

  let doc = document_balance[businessCode];
  if (doc) return;

  doc = FB_Firestore.collection('pointsStat').doc(businessCode);

  doc.onSnapshot((snapshot) => {
    let data = snapshot.data();
    if (!data) return;

    DEBUG(TAGm, businessCode, 'TRIGGER');
    if (typeof callback === 'function')
      setTimeout(() => callback(null, data), 300);
  });
  document_balance[businessCode] = doc;
};

const getBusinessBalanceApi = (businessCode) => {
  let TAGm = TAG + '.getBusinessBalanceApi';
  DEBUG(TAGm, businessCode);

  let options = {
    method: 'get',
    url: businessAPI + '/' + businessCode + '/point',
    headers: { Authorization: getCookie(cookie_name_token) },
  };

  return new Promise((resolve, reject) => {
    axios(options)
      .then((response) => {
        let body = response.data;
        DEBUG(
          TAGm,
          businessCode,
          body.result,
          'current balance',
          body.data.total
        );

        if (!body.result)
          return resolve({ type: GET_BUSINESS_BALANCE, status: false });
        return resolve({
          type: GET_BUSINESS_BALANCE,
          status: true,
          balance: body.data,
        });
      })
      .catch((error) => {
        ERROR(TAGm, 'catch', error);
        return resolve({ type: GET_BUSINESS_BALANCE, status: false });
      });
  });
};

export const businessReload = () => {
  return { type: RELOAD_PAGE, data: true };
};

export const getMemberList = (
  business_code,
  limit,
  offset,
  typeMember,
  currentPage,
  sortColumn,
  sortType,
  filter
) => {
  let url =
    sortColumn === undefined || sortColumn === '' || !sortColumn
      ? `${businessAPI}/${business_code}/member?limit=${limit}&offset=${offset}&type=${typeMember}&filter=${JSON.stringify(
          filter || ''
        )}`
      : `${businessAPI}/${business_code}/member?limit=${limit}&offset=${offset}&type=${typeMember}&sort=${sortColumn}:${sortType}&filter=${JSON.stringify(
          filter || ''
        )}`;
  return (dispatch) => {
    dispatch({ type: SET_LOADING_MEMBER_LIST });
    axios({
      method: 'get',
      url: url,
      headers: { Authorization: getCookie(cookie_name_token) },
    })
      .then((data) => {
        if (typeMember === 'member') {
          dispatch({
            type: SET_MEMBER_LIST,
            data: data.data,
            currentPage: currentPage,
          });
        } else if (typeMember === 'premember') {
          dispatch({
            type: SET_PREMEMBER_LIST,
            data: data.data,
            currentPage: currentPage,
          });
        }
      })
      .catch((err) => {
        ERROR('getMemberList error', err);
        if (typeMember === 'member') {
          dispatch({ type: SET_MEMBER_LIST, data: '' });
        } else if (typeMember === 'premember') {
          dispatch({ type: SET_PREMEMBER_LIST, data: '' });
        }
      });
  };
};

export const termsConditionUpdate = (
  business_code,
  data,
  type_activty,
  callback
) => {
  return (dispatch) => {
    dispatch({ type: RELOAD_PAGE, data: true });
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/terms`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        activty: type_activty,
        terms: data,
      },
    })
      .then((data) => {
        dispatch({ type: GET_TERMS_BUSINESS, data: data.data.data });
        if (typeof callback === 'function') callback(null, data.data.data);
      })
      .catch((err) => {
        ERROR('termsConditionUpdate catch', err);
        if (typeof callback === 'function') callback(err);
      });
  };
};

export const privacyPolicyUpdate = (
  business_code,
  policy,
  url,
  option,
  type_activty,
  callback
) => {
  return (dispatch) => {
    dispatch({ type: RELOAD_PAGE, data: true });
    setCookie('_tab', 'policy');
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/policy`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        activty: type_activty,
        policy: policy,
        policy_url: url,
        policy_option: option,
      },
    })
      .then((data) => {
        dispatch({ type: GET_POLICY_BUSINESS, data: data.data.data });
        if (typeof callback === 'function') callback(null, data.data.data);
      })
      .catch((err) => {
        ERROR('privacyPolicyUpdate catch', err);
        if (typeof callback === 'function') callback(err);
      });
  };
};

export const getVerifyBusiness = (business_code, callback) => {
  return (dispatch) => {
    dispatch({ type: GET_VERIFYBUSINESS_PROCESS });
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/verification`,
      headers: { Authorization: getCookie(cookie_name_token) },
    })
      .then(({ data }) => {
        if (data.result === true) {
          if (typeof callback === 'function') callback(null, data.data);
          dispatch({ type: GET_VERIFYBUSINESS, data: data.data || {} });
        } else if (typeof callback === 'function') callback('error result');
      })
      .catch((err) => {
        ERROR('getVerifyBusiness catch', err);
        if (typeof callback === 'function') callback(err);
        dispatch({ type: GET_VERIFYBUSINESS, data: {} });
      });
  };
};

export const updateVerify = (business_code, data, callback) => {
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/verification`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: data,
    })
      .then(({ data }) => {
        if (data.result === true) {
          if (typeof callback === 'function') callback(null, data.data);
          return dispatch({
            type: UPDATE_VERIFYBUSINESS_DETAIL,
            data: data.data,
          });
        } else if (typeof callback === 'function') callback('error result');
      })
      .catch((err) => {
        ERROR('updateVerify catch', err);
        if (typeof callback === 'function') callback(err);
        return dispatch({ type: UPDATE_VERIFYBUSINESS_DETAIL });
      });
  };
};

export const getVerificationBusinessByHash = (hash, callback) => {
  return (dispatch, getState) => {
    const store_manager = getState().manager;

    dispatch({ type: START_BUSINESS_PROCESS });
    axios({
      method: 'post',
      url: `${businessAPI}/verification/${hash}`,
      data: {
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then((res) => {
        if (res.data.result) {
          if (typeof callback === 'function') callback(null, res);
          dispatch({ type: GET_VERIFYBUSINESS, data: res.data.data[0] });
        } else if (typeof callback === 'function') callback('error result');
        return dispatch({ type: END_BUSINESS_PROCESS });
      })
      .catch((err) => {
        ERROR('getVerificationBusinessByHash catch', err);
        return dispatch({ type: END_BUSINESS_PROCESS });
      });
  };
};

export const updateVerificationBusinessByHash = (
  { hash, status },
  callback
) => {
  return (dispatch, getState) => {
    dispatch({ type: START_BUSINESS_PROCESS });
    const store_manager = getState().manager;
    const rejectedInfo = [];
    _.forEach(status, (accepted, key) => {
      if (!accepted) {
        rejectedInfo.push(key);
      }
    });
    status.log = {
      action: 'Reject Business',
      business_code: _.get(getState().business.verifyBusiness, [
        'business_code',
      ]),
      new_value: rejectedInfo.join(', '),
    };
    axios({
      method: 'put',
      url: `${businessAPI}/verification/${hash}`,
      headers: { Authorization: getCookie(cookie_name_rp_admin) },
      data: {
        status: status,
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then((res) => {
        if (res.data.result) {
          if (typeof callback === 'function') callback(null, res);
          dispatch({ type: UPDATE_VERIFYBUSINESS, data: res.data.data });
        } else if (typeof callback === 'function') callback('error result');
        return dispatch({ type: END_BUSINESS_PROCESS });
      })
      .catch((err) => {
        ERROR('updateVerificationBusinessByHash catch', err);
        return dispatch({ type: END_BUSINESS_PROCESS });
      });
  };
};

export const updateBusinessName = (
  business_code,
  business_name,
  callback,
  fail_callback
) => {
  axios({
    method: 'patch',
    url: `${businessAPI}/${business_code}/name`,
    headers: { Authorization: getCookie(cookie_name_token) },
    data: { business_name },
  })
    .then((res) => {
      if (typeof callback === 'function') callback(res);
    })
    .catch((err) => {
      ERROR('updateBusinessContact catch', err);
      if (typeof fail_callback === 'function') fail_callback(err);
    });
};

export const updateBusinessContact = (
  business_code,
  data,
  callback,
  fail_callback
) => {
  return (dispatch) => {
    dispatch({ type: START_BUSINESS_PROCESS });
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        business_branch: [data],
      },
    })
      .then((res) => {
        dispatch({
          type: UPDATE_BUSINESS_CONTACT_BRANCH_PRIMARY,
          result: res.data.result,
          data: data,
        });
        if (typeof callback === 'function') callback(res);
      })
      .catch((err) => {
        ERROR('updateBusinessContact catch', err);
        if (typeof fail_callback === 'function') fail_callback(err);
        dispatch({
          type: UPDATE_BUSINESS_CONTACT_BRANCH_PRIMARY,
          result: false,
          data: '',
        });
      });
  };
};

export const getBusinessReview = (
  {
    business_code,
    limit,
    offset,
    type,
    review_star,
    review_message,
    start_date,
    end_date,
  },
  callback
) => {
  return (dispatch) => {
    dispatch({ type: GET_BUSINESS_REVIEW_PROCESS });
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/reviews`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        review_star: review_star,
        review_message: review_message,
        start_date: start_date,
        end_date: end_date,
        limit: limit,
        offset: offset,
      },
    })
      .then(({ data }) => {
        if (typeof callback === 'function') callback(null, data);
        if (type === 'load_more')
          return dispatch({
            type: GET_BUSINESS_REVIEW_MESSAGE,
            data: data.data,
          });
        else if (type === 'export') return dispatch({ type: END_BUSINESS_PROCESS });
        else return dispatch({ type: GET_BUSINESS_REVIEW, data: data.data });
      })
      .catch((err) => {
        ERROR('getBusinessReview catch', err);
        if (typeof callback === 'function') callback(err);
        return dispatch({ type: GET_BUSINESS_REVIEW, data: {} });
      });
  };
};

export const clearBusinessReview = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_BUSINESS_REVIEW });
  };
};

export const sendEmailReportReview = (
  {
    business_code,
    review_star,
    review_message,
    start_date,
    end_date,
    sendmail,
    language,
  },
  callback
) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/reviews/exportReview`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        review_star: review_star,
        review_message: review_message,
        start_date: start_date,
        end_date: end_date,
        sendmail,
        language,
      },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === 'function') callback(null);
        } else if (typeof callback === 'function')
          callback(new Error('internal-error'));
      })
      .catch((err) => {
        ERROR('sendEmailReportReview catch', err);
        if (typeof callback === 'function') {
          callback(err);
        }
      });
  };
};

export const getBusinessExpiryPoint = (business_code, callback) => {
  return (dispatch) => {
    dispatch({ type: START_BUSINESS_EXPIRY_POINTS_PROCESS });
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/expiryPoint`,
      headers: { Authorization: getCookie(cookie_name_token) },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === 'function') callback(null, data.data);
          return dispatch({ type: GET_BUSINESS_POINTEXPIRY, data: data.data });
        } else return Promise.reject(data.error || 'error');
      })
      .catch((err) => {
        ERROR('getBusinessExpiryPoint catch', err);
        if (typeof callback === 'function') callback(err);
        return dispatch({ type: GET_BUSINESS_POINTEXPIRY });
      });
  };
};

export const createBusinessExpiryPoint = (business_code, data, callback) => {
  return (dispatch) => {
    // dispatch({ type: START_BUSINESS_EXPIRY_POINTS_PROCESS })
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/expiryPoint`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: data,
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === 'function') callback(null, data.data);
          dispatch({ type: GET_BUSINESS_POINTEXPIRY, data: data.data });
        } else return Promise.reject(data.error || 'error');
      })
      .catch((err) => {
        ERROR('createBusinessExpiryPoint catch', err);
        if (typeof callback === 'function') callback(err);
        dispatch({ type: GET_BUSINESS_POINTEXPIRY });
      });
  };
};

export const archiveBusiness = (business_code, data, callback) => {
  axios({
    method: 'delete',
    url: `${businessAPI}/${business_code}`,
    headers: { Authorization: getCookie(cookie_name_token) },
    data: data,
  })
    .then((res) => {
      if (res.data && res.data.result)
        typeof callback === 'function' && callback(null, res.data);
      else
        typeof callback === 'function' &&
          callback(new Error('Result not completed'), res.data);
    })
    .catch((err) => {
      ERROR('archiveBusiness catch', err);
      if (typeof callback === 'function') callback(err);
    });
};

export const updateBusinessActivateConfig = (
  business_code,
  activate_swipe,
  activate_pincode = false,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/activate`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { activate_swipe, activate_pincode },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const getAccountPincode = (
  { business_code, offset, limit },
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/pincode?offset=${offset}&limit=${limit}`,
      headers: { Authorization: getCookie(cookie_name_token) },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({
          type: GET_BUSINESS_PINCODE,
          data: data.data,
          total: data.total,
          offset: data.offset,
          limit: data.limit,
        });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const getAccountPincodeWithSearch = (
  { business_code, offset, limit, search = null },
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/pincode/${search}?offset=${offset}&limit=${limit}`,
      headers: { Authorization: getCookie(cookie_name_token) },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({
          type: GET_BUSINESS_PINCODE,
          data: data.data,
          total: data.total,
          offset: data.offset,
          limit: data.limit,
        });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const createAccountPincode = (
  business_code,
  name,
  last_name,
  phone,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/pincode`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { name, last_name, phone },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({ type: CREATE_BUSINESS_PINCODE, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const UpdateAccountPincode = (
  business_code,
  upid,
  name,
  last_name,
  phone,
  enable,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/pincode`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { upid, name, last_name, phone, enable },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({ type: CREATE_BUSINESS_PINCODE, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const ArchiveAccountPincode = (business_code, upid, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${businessAPI}/${business_code}/pincode`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { upid },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({ type: CREATE_BUSINESS_PINCODE, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const updateBusinessMemberCardConfig = (
  business_code,
  member_card,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/memberCard`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { member_card },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const updateBusinessRequestPointsConfig = (
  business_code,
  request_points = false,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/requestPoints`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { request_points },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const updateBusinessCollectPointByProductConfig = (
  business_code,
  byProduct = false,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/collectPointByProduct`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { byProduct },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const updateRejectedCollectPointsNotiConfig = (
  value,
  business_code,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/rejectedCollectPointsNoti`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { value },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error Get Account Pincode'));

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get Account Pincode'));
      });
  };
};

export const updateBusinessRequireProductConfig = (
  business_code,
  require_product_send_point,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/requireProduct`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { require_product_send_point },
    })
      .then(({ data }) => {
        if (!data.result)
          return (
            isFunc && callback(new Error('Error Update Require Product Config'))
          );

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return (
          isFunc && callback(new Error('Error Update Require Product Config'))
        );
      });
  };
};

export const updateAutomationMemberCardConfig = (
  business_code,
  is_enabled,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/memberCard/automation/config`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { is_enabled },
    })
      .then(({ data }) => {
        if (!data.result) return isFunc && callback(new Error(data.error));

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return (
          isFunc &&
          callback(new Error('Error Update Automation Member Card Config'))
        );
      });
  };
};

export const updateAutomationMemberCardPromotionConfig = (
  business_code,
  type,
  point_list,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch, getState) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/memberCard/automation/promotion`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { type, point_list },
    })
      .then(({ data }) => {
        if (!data.result) return isFunc && callback(new Error(data.error));

        isFunc && callback(null, data.data);
        const member_card_info = _.get(getState(), [
          'business',
          'member_card_info',
        ]).map((item) => {
          item.automation_earned_point = point_list[item.card_id];
          return item;
        });
        dispatch({ type: GET_MEMBER_CARD_INFO, data: member_card_info });
        dispatch({
          type: UPDATE_BUSINESS_CONFIG,
          data: _.get(data, ['data', 'business_config']),
        });
      })
      .catch(() => {
        return (
          isFunc &&
          callback(new Error('Error Update Automation Member Card Config'))
        );
      });
  };
};

export const updateAutomationMemberCardDemotionConfig = (
  business_code,
  type,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/memberCard/automation/demotion`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { type },
    })
      .then(({ data }) => {
        if (!data.result) return isFunc && callback(new Error(data.error));

        isFunc && callback(null, data.data);
        dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return (
          isFunc &&
          callback(new Error('Error Update Automation Member Card Config'))
        );
      });
  };
};

export const getMemberCardInfo = (business_code, callback) => {
  let isFunc = typeof callback === 'function';

  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/memberCard`,
      headers: { Authorization: getCookie('_token') },
    })
      .then(({ data }) => {
        isFunc && callback(null, data);
        return dispatch({ type: GET_MEMBER_CARD_INFO, data: data.data });
      })
      .catch((err) => {
        ERROR('getMemberCardInfo catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const createMemberCardInfo = (
  business_code,
  { card_name, card_privilege, card_style, card_expiry, card_level },
  image,
  callback
) => {
  let isFunc = typeof callback === "function";

  let imgPath = `${business_code}/memberCard/${new Date().getTime()}.jpeg`;

  return (dispatch) => {
    if (image !== null) {
      uploadPhoto(imgPath, image).then((img) => {
        axios({
          method: "post",
          url: `${businessAPI}/${business_code}/memberCard`,
          headers: { Authorization: getCookie("_token") },
          data: {
            card_name,
            card_privilege,
            card_style,
            card_expiry,
            card_level,
            backgroundImage: img.url,
          },
        })
          .then(({ data }) => {
            return isFunc && callback(null, data);
          })
          .catch((err) => {
            ERROR("createMemberCardInfo catch", err.message);
            return isFunc && callback(err);
          });
      });
    } else {
      axios({
        method: "post",
        url: `${businessAPI}/${business_code}/memberCard`,
        headers: { Authorization: getCookie("_token") },
        data: {
          card_name,
          card_privilege,
          card_style,
          card_expiry,
          card_level,
          backgroundImage: null,
        },
      })
        .then(({ data }) => {
          return isFunc && callback(null, data);
        })
        .catch((err) => {
          ERROR("createMemberCardInfo catch", err.message);
          return isFunc && callback(err);
        });
    }
  };
};

export const updateMemberCardInfo = (
  business_code,
  { cardList },
  image,
  oldPathImage,
  callback
) => {
  let isFunc = typeof callback === "function";
  let imgPath = `${business_code}/memberCard/${new Date().getTime()}.jpeg`;

  return (dispatch) => {
    if (image !== null) {
      if (oldPathImage) {
        deletePhoto(oldPathImage).catch((error) => {
          ERROR("delete file fail", error);
        });
      }

      uploadPhoto(imgPath, image)
        .then((img) => {
          return axios({
            method: "put",
            url: `${businessAPI}/${business_code}/memberCard`,
            headers: { Authorization: getCookie("_token") },
            data: { cardList: cardList, backgroundImage: img.url },
          });
        })
        .then(({ data }) => {
          if (data.result) return isFunc && callback(null, data);
          else return isFunc && callback("result_error");
        })
        .catch((err) => {
          ERROR("updateMemberCardInfo catch", err.message);
          return isFunc && callback(err);
        });
    } else {
      return axios({
        method: "put",
        url: `${businessAPI}/${business_code}/memberCard`,
        headers: { Authorization: getCookie("_token") },
        data: { cardList: cardList, backgroundImage: null },
      })
        .then(({ data }) => {
          if (data.result) return isFunc && callback(null, data);
          else return isFunc && callback("result_error");
        })
        .catch((err) => {
          ERROR("updateMemberCardInfo catch", err.message);
          return isFunc && callback(err);
        });
    }
  };
};

export const deleteMemberCardInfo = (business_code, { card_id }, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${businessAPI}/${business_code}/memberCard`,
      headers: { Authorization: getCookie('_token') },
      data: { card_id },
    })
      .then(({ data }) => {
        return isFunc && callback(null, data);
      })
      .catch((err) => {
        ERROR('deleteMemberCardInfo catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const getBusinessLabel = (
  { business_code, excludeArchive = '', tag_type = '', isDelete = false },
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/tagLabel?excludeArchive=${
        excludeArchive || ''
      }&tag_type=${tag_type || ''}`,
      headers: { Authorization: getCookie('_token') },
    })
      .then(({ data }) => {
        if (data.result) {
          isFunc && callback(null, data.data);
          return dispatch({
            type: GET_BUSINESS_LABEL,
            data: data.data,
            isDelete: isDelete ? tag_type : false,
          });
        } else return Promise.reject(data.error || 'result error');
      })
      .catch((err) => {
        ERROR('getBusinessLabel catch', err);
        return isFunc && callback(err);
      });
  };
};

export const createBusinessLabel = (business_code, data, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/tagLabel`,
      headers: { Authorization: getCookie('_token') },
      data: data,
    })
      .then(({ data }) => {
        if (data.result) return isFunc && callback(null, data.result);
        else return Promise.reject(data.error || 'result error');
      })
      .catch((err) => {
        ERROR('createBusinessLabel catch', err);
        return isFunc && callback(err);
      });
  };
};

export const editBusinessLabel = (business_code, data, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/tagLabel`,
      headers: { Authorization: getCookie('_token') },
      data: data,
    })
      .then(({ data }) => {
        if (data.result) return isFunc && callback(null, data.result);
        else return Promise.reject(data.error || 'result error');
      })
      .catch((err) => {
        ERROR('editBusinessLabel catch', err);
        return isFunc && callback(err);
      });
  };
};

export const deleteBusinessLabel = (business_code, data, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${businessAPI}/${business_code}/tagLabel`,
      headers: { Authorization: getCookie('_token') },
      data: data,
    })
      .then(({ data }) => {
        if (data.result) return isFunc && callback(null, data.result);
        else return Promise.reject(data.error || 'result error');
      })
      .catch((err) => {
        ERROR('deleteBusinessLabel catch', err);
        return isFunc && callback(err);
      });
  };
};

export const getActivateAutomaticLabelConfig = (business_code, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/config/automaticLabel`,
      headers: { Authorization: getCookie(cookie_name_token) },
    })
      .then(({ data }) => {
        if (!data.result)
          return (
            isFunc && callback(new Error('Error Get AutomaticLabel Config'))
          );

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error Get AutomaticLabel Config'));
      });
  };
};

export const updateActivateAutomaticLabelConfig = (
  business_code,
  enable,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/automaticLabel`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { enable: enable },
    })
      .then(({ data }) => {
        if (!data.result)
          return (
            isFunc && callback(new Error('Error Enable AutomaticLabel Config'))
          );

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return (
          isFunc && callback(new Error('Error Enable AutomaticLabel Config'))
        );
      });
  };
};

export const getFriendGetFriendsConfig = ({ business_code }, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/config/friendGetFriends`,
      headers: { Authorization: getCookie(cookie_name_token) },
    })
      .then(({ data }) => {
        if (!data.result)
          return (
            isFunc && callback(new Error('Error Get FriendGetFriends Config'))
          );

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return (
          isFunc && callback(new Error('Error Get FriendGetFriends Config'))
        );
      });
  };
};

export const updateFriendGetFriendsConfig = (
  { business_code, enable },
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/friendGetFriends`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { enable: enable },
    })
      .then(({ data }) => {
        if (!data.result)
          return (
            isFunc &&
            callback(new Error('Error Enable FriendGetFriends Config'))
          );

        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch(() => {
        return (
          isFunc && callback(new Error('Error Enable FriendGetFriends Config'))
        );
      });
  };
};

export const setLineLiffID = (business_code, liff_id, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/lineLiff`,
      headers: { Authorization: getCookie('_token') },
      data: { liff_id: liff_id },
    })
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: UPDATE_BUSINESS_CONFIG, data: { liff_id } });
          return isFunc && callback(null, data);
        } else return isFunc && callback('result_error');
      })
      .catch((err) => {
        ERROR('setLineLiffID catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const getLineMessagingToken = (business_code, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/line/token`,
      headers: { Authorization: getCookie('_token') },
    })
      .then(({ data }) => {
        console.log('getLineMessagingToken', data);
        if (data.result) {
          if (data.data.token) {
            dispatch({
              type: UPDATE_BUSINESS_CONFIG,
              data: { line_messaging_token: data.data.token },
            });
            return isFunc && callback(null, data.data.token);
          } else return isFunc && callback('no_token');
        } else return isFunc && callback('result_error');
      })
      .catch((err) => {
        ERROR('setLineMessagingToken catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const setLineMessagingToken = (
  business_code,
  line_messaging_token,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/line/token`,
      headers: { Authorization: getCookie('_token') },
      data: { token: line_messaging_token },
    })
      .then(({ data }) => {
        console.log('setLineMessagingToken data', data);
        if (data.result) {
          dispatch({
            type: UPDATE_BUSINESS_CONFIG,
            data: { line_messaging_token },
          });
          return isFunc && callback(null, data);
        } else return isFunc && callback('result_error');
      })
      .catch((err) => {
        console.log('setLineMessagingToken err', err);
        ERROR('setLineMessagingToken catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const getLineMembers = (business_code, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/line/members`,
      headers: { Authorization: getCookie('_token') },
    })
      .then(({ data }) => {
        //console.log("getLineMembers data", data)
        if (data.result) {
          dispatch({ type: GET_BUSINESS_LINE_MEMBERS, data: data.data || [] });
          return isFunc && callback(null, data);
        } else return isFunc && callback('result_error');
      })
      .catch((err) => {
        //console.log("getLineMembers err", err)
        ERROR('getLineMembers catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const getShappyMembers = (business_code, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/shappy/members`,
      headers: { Authorization: getCookie('_token') },
    })
      .then(({ data }) => {
        //console.log("getLineMembers data", data)
        if (data.result) {
          dispatch({
            type: GET_BUSINESS_SHAPPY_MEMBERS,
            data: data.data || [],
          });
          return isFunc && callback(null, data);
        } else return isFunc && callback('result_error');
      })
      .catch((err) => {
        //console.log("getLineMembers err", err)
        ERROR('getLineMembers catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const getInterMembers = (business_code, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/email/members`,
      headers: { Authorization: getCookie('_token') },
    })
      .then(({ data }) => {
        if (data.result) {
          dispatch({ type: GET_BUSINESS_INTER_MEMBERS, data: data.data || [] });
          return isFunc && callback(null, data);
        } else return isFunc && callback('result_error');
      })
      .catch((err) => {
        ERROR('getInterMembers catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const getBusinessType = (callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${publicAPI}/business/businessType`,
      headers: { Authorization: getCookie('_token') },
    })
      .then(({ data }) => {
        if (data.result) {
          return isFunc && callback(null, data);
        } else return isFunc && callback('result_error');
      })
      .catch((err) => {
        ERROR('getBusiness List catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const getBusinessTypeByID = (
  business_category,
  product_category,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${publicAPI}/business/businessTypeByID`,
      headers: { Authorization: getCookie('_token') },
      data: { business_category, product_category },
    })
      .then(({ data }) => {
        if (data.result) {
          return isFunc && callback(null, data);
        } else return isFunc && callback('result_error');
      })
      .catch((err) => {
        ERROR('getBusiness List catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const getMemberCardExpiryNotiSetting = (business_code, callback) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/expiryMemberCardNotifySetting`,
      headers: { Authorization: getCookie('_token') },
    })
      .then(({ data }) => {
        isFunc && callback(null, data.data);
        return dispatch({
          type: GET_BUSINESS_MEMBER_CARD_EXPIRY_SETTING,
          data: data.data,
        });
      })
      .catch((err) => {
        ERROR('getMemberCardExpiryNotiSetting catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const updateMemberCardExpiryNotiSetting = (
  business_code,
  body,
  callback
) => {
  let isFunc = typeof callback === 'function';
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/expiryMemberCardNotifySetting`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { type: body.type, value: body.value },
    })
      .then(({ data }) => {
        if (!data.result) return isFunc && callback(new Error(data.error));
        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: data.data });
      })
      .catch((err) => {
        ERROR('updateMemberCardExpiryNotiSetting catch', err.message);
        return isFunc && callback(err);
      });
  };
};

export const updateBusinessSecurityConfig = (
  { business_code, uuid, two_factor_authen },
  callback
) => {
  let isFunc = typeof callback === "function";
  return (dispatch, getState) => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/config/twoFactorAuthen`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: { two_factor_authen, uuid },
    })
      .then(({ data }) => {
        if (!data.result)
          return isFunc && callback(new Error('Error set config security'));

        if (two_factor_authen) {
          let business_list = getState().business.list;
          business_list.map(
            (business) =>
              (business.otp_verify =
                true || business.business_code === business_code)
          );
          dispatch({
            type: "UPDATE_VERIFY_EMAIL_OTP_SUCCESS",
            data: business_list,
          });
        }
        let _data = two_factor_authen
          ? Object.assign({}, data.data, { otp_verify: true })
          : data.data;
        isFunc && callback(null, data.data);
        return dispatch({ type: UPDATE_BUSINESS_CONFIG, data: _data });
      })
      .catch(() => {
        return isFunc && callback(new Error('Error set config security'));
      });
  };
};
