import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { FormGroup, Input } from "reactstrap";
import { Title2 } from "../Font";
import { useTargetGroupList } from "./TargetGroupList";
import TargetRFM from "./TargetRFM";
import TargetMemberPoints from "./TargetMemberPoints";
import TargetMemberCard from "./TargetMemberCard";
import TargetBirthMonth from "./TargetBirthMonth";
import TargetSpecificMember from "./TargetSpecificMember";
import TargetMemberLabel from "./TargetMemberLabel";
import TargetAllMember from "./TargetAllMember";
import TargetPreMember from "./TargetPreMember";
import TargetPurchaseProduct from "./TargetPurchaseProduct";
import TargetRewardsCoupon from "./TargetRewardsCoupon";
import TargetEachBirthMonth from "./TargetEachBirthMonth";
import _ from "lodash";

export default function TargetGroups({
  target = "all",
  isLoading = false,
  disabled,
  options,
  type,
  max = 1000,
  campaign_title
}) {
  /**
   * replace target for rfm_groups cause it have multiple name
   * as rfm_groups, rfm_groups_6m, rfm_groups_12m.
   */
  target = target.indexOf("rfm_groups") > -1 ? "rfm_groups" : target;

  const dictionary = useSelector((state) => state.language.dictionary);
  const { trigger } = useSelector((state) => state.automation);

  const [targetType, setTargetType] = useState(target);
  const [isTriggerChange, setIsTriggerChange] = useState(false);
  const targetGroupList = useTargetGroupList(type);
  const isAutomation = _.get(options, "isAutomation", false) || campaign_title;
  const showTargetAsLabel = _.get(options, "showTargetAsLabel", false);
  let hideTargetValue = _.get(options, "hideTargetValue", false); // this flag use for automation coupon

  if (
    isAutomation &&
    (targetType === "birthmonth" || targetType === "each_birthmonth")
  ) {
    hideTargetValue = false; // not hide on birthmonth automation
  }

  const handleChangeTargetType = (e) => {
    let { value } = e.target;

    /**
     * this condition for automation method duplicate.
     * when open ecoupon modal and switch target group
     * will clear target value.
     */
    if (isAutomation && _.get(options, "value", false)) {
      options.value = "";
    }

    setTargetType(value);
  };

  const RenderOptions = useMemo(() => {
    const noRequest = _.get(options, "noRequest", false);
    const showAsLabel = _.get(options, "showAsLabel", false);
    const disabled = _.get(options, "disabled", false);
    const value = _.get(options, "value", "");

    switch (targetType) {
      case "all":
        return <TargetAllMember noRequest={noRequest} />;
      case "rfm_groups":
      case "rfm_groups_6m":
      case "rfm_groups_12m":
        return (
          <TargetRFM
            showAsLabel={showAsLabel}
            noRequest={noRequest}
            disabled={disabled}
            target={value}
          />
        );
      case "member_card":
        return (
          <TargetMemberCard
            showAsLabel={showAsLabel}
            target={value}
            disabled={disabled}
            noRequest={noRequest}
          />
        );
      case "birthmonth":
      case "each_birthmonth":
        return isAutomation ? (
          <TargetEachBirthMonth />
        ) : (
          <TargetBirthMonth target={value} disabled={disabled} />
        );

      case "member_purchase_product":
        return (
          <TargetPurchaseProduct
            target={value}
            disabled={disabled}
            noRequest={noRequest}
            noDateSelect={options?.targetPurchaseProduct?.noDateSelect}
            excludeArchive={options?.targetPurchaseProduct?.excludeArchive}
          />
        );
      case "member_receive_reward":
        return isAutomation ? (
          <Title2 className="mb-3">{dictionary.member_receive_reward}</Title2>
        ) : (
          <TargetRewardsCoupon
            target={value}
            disabled={disabled}
            noRequest={noRequest}
          />
        );
      case "member":
        return (
          <TargetSpecificMember
            method={options?.targetSpecificMember?.method}
            disabled={disabled}
            hideTagInput={options?.targetSpecificMember?.hideTagInput}
            target={value}
            noRequest={noRequest}
            max={max}
          />
        );
      case "member_label":
        return (
          <TargetMemberLabel
            readonly={options?.targetMemberLabel?.readonly}
            target={value}
            disabledLabel={options?.targetMemberLabel?.disabledLabel}
            hideAddButton={options?.targetMemberLabel?.hideAddButton}
            noRequest={noRequest}
          />
        );
      case "member_pending_group":
        return <TargetPreMember noRequest={noRequest} />;
      case "member_points":
        return (
          <TargetMemberPoints
            showAsLabel={showAsLabel}
            disabled={disabled}
            target={value}
            noRequest={noRequest}
          />
        );

      default:
        return <TargetAllMember noRequest={noRequest} />;
    }
  }, [options, dictionary, targetType, isAutomation, max]);

  const MemoTargetGroupList = useMemo(() => {
    let filteredTargetGroupList = targetGroupList;

    if (trigger === "new_members") {
      filteredTargetGroupList = filteredTargetGroupList.slice(5, 7);
      if (targetType !== "member_points" && targetType !== "birthmonth") {
        setTargetType("member_points");
        setIsTriggerChange(true);
      }
    } else if (isTriggerChange && trigger !== "new_members") {
      setTargetType("all");
      setIsTriggerChange(false);
    }

    const filteredOptions = _.filter(filteredTargetGroupList, {
      isShow: true,
    }).map((value, key) => (
      <option key={`${value}_${key}`} value={value.value}>
        {dictionary[value.name]}
      </option>
    ));

    return filteredOptions;
  }, [targetGroupList, dictionary, trigger, isTriggerChange, targetType]);

  return (
    <>
      <FormGroup>
        <Title2 bold>
          <span className="text-danger"> *</span> {dictionary.target_groups}{" "}
        </Title2>
        <div className="pt-2">
          {showTargetAsLabel ? (
            <RenderTargetGroupsAsLabel targetType={targetType} />
          ) : (
            <Input
              type="select"
              name="target_type"
              onChange={handleChangeTargetType}
              value={targetType}
              disabled={isLoading || disabled}
            >
              {MemoTargetGroupList}
            </Input>
          )}
        </div>
      </FormGroup>

      {!hideTargetValue && RenderOptions}
    </>
  );
}

const RenderTargetGroupsAsLabel = ({ targetType }) => {
  const dictionary = useSelector((state) => state.language.dictionary);

  const targetName =
    targetType.indexOf("rfm_groups") > -1
      ? "rfm_members"
      : targetType === "member"
      ? "member_business"
      : targetType;

  return (
    <Title2 className="pl-3" secondary>
      {dictionary[targetName] || ""}
    </Title2>
  );
};
