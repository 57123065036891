import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Row, Col,
  Card, CardHeader, CardBody, CardTitle
} from 'reactstrap'

import LoadingBox from './../Loading'
import { HighNumber, TransactionNumber } from './PointBoard.style'
import { getBusinessBalanceOnce } from '../../redux/actions/action_business'

class PointsDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,

      boardlist: [
        {// Column Total Balance
          name: 'total-balance',
          display: 'Points Balance',
          size: [12, null, null, null, null],
          balancekey: 'total'
        },
        {// Column earn
          name: 'send-points',
          display: 'Send Points',
          size: [12, 6, 3, null, null],
          balancekey: 'earn'
        },
        {// Column pull
          name: 'pull-points',
          display: 'Pull Points',
          size: [12, 6, 3, null, null],
          balancekey: 'pull'
        }, {
          name: 'pay-points',
          display: 'Pay Points',
          size: [12, 6, 3, null, null],
          balancekey: 'pay'
        },
        {// Column redeem
          name: 'redeem-points',
          display: 'Redeem Points',
          size: [12, 6, 3, null, null],
          balancekey: 'redeem'
        }, {// Column transfer
          name: 'transfer-points',
          display: 'Transfer Points',
          size: [12, 6, 3, null, null],
          balancekey: 'transfer'
        }
      ]
    }

  }
  componentDidMount() {
    let { getBusinessBalanceOnce, store_business: { current } } = this.props
    getBusinessBalanceOnce(
      current.business_code,
      () => this.setState({ loading: false })
    );
  }

  render() {
    let { store_business: { balance } } = this.props
    let { loading, boardlist } = this.state

    if (loading) return <LoadingBox type={'grow'} />;

    return <div>
      <Row>
        {
          boardlist.map(column => {
            let this_balance = balance[column.balancekey]
            if (!this_balance) return null

            if (typeof this_balance !== 'object') {
              this_balance = { balance: this_balance, transaction: null }
            }

            return <Col key={column.name} className='p-3'
              xs={column.size[0]} sm={column.size[1]} md={column.size[2]} lg={column.size[3]} xl={column.size[4]}>
              <Card>
                <CardHeader>
                  <CardTitle>{column.display}</CardTitle>
                  {!this_balance.transaction ? '0 txs' : <TransactionNumber>{this_balance.transaction.toLocaleString()}</TransactionNumber>}
                </CardHeader>
                <CardBody>
                  <HighNumber>{this_balance.balance.toLocaleString()}</HighNumber>
                </CardBody>
              </Card>
            </Col>
          })
        }
      </Row>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getBusinessBalanceOnce
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PointsDashboard)