import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import LoadingBox from './../Loading'
import RecentTransactions from '../../components/RecentTransactions'
import { getTransactions } from '../../redux/actions/action_transactions'


class RecentTransactionBoard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  componentDidMount() {
    let { store_business: { current } } = this.props
    let input = { business_code: current.business_code, limit: 10, offset: 0, }
    this.props.getTransactions(input, (err, data) => {
      this.setState({ loading: false })
    })
  }

  render() {
    let { store_transactions, store_language: { dictionary } } = this.props
    let recentTransactions = (store_transactions && store_transactions.transactions) || []

    if (this.state.loading)
      return <LoadingBox type={'grow'} />

    return <RecentTransactions
      dictionary={dictionary}
      transactions={recentTransactions} />
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_transactions: state.transactions,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getTransactions
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentTransactionBoard)