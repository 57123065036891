import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Tooltip } from 'reactstrap';
import { withRouter } from 'react-router-dom'

import {
  DropdownIconSpace,
  DropdownItem,
  DropdownCardMessage,
  DropdownItemMessage,
  BadgeIconSpace
} from './Dropdown.style'
import './Dropdown.css'

import TagManager from '../../libs/TagManager'
import { getCookieJSON } from '../../libs/cookies'
import { DEBUG } from '../../libs/utils'
import { nl2br } from '../../libs/nl2br'
import { fetch_message, read_message } from '../../redux/actions/action_message'

import { ComponentIcon } from '../Titlebar/Titlebar.style'

class DropdownMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DropdownShow: false,
      tooltipOpen: false
    }
    this.closeMenu = this.closeMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.toggle = this.toggle.bind(this);

    this.props.fetch_message();
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ DropdownShow: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ DropdownShow: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  toggle() {
    this.setState({ tooltipOpen: !this.state.tooltipOpen })
  }

  read_message_event(message_key, message) {
    TagManager.event(`btn-read_message`)

    DEBUG('read_message_event', message_key)
    this.props.read_message(message_key);
    if (message.data.linkto) {
      if (message.data.linkto.indexOf('http') > -1)
        window.open(message.data.linkto)
      else
        this.props.history.push(message.data.linkto)
    }
  }

  render() {
    const { store_message, store_language: { dictionary } } = this.props
    let { broadcast_message } = store_message
    let unread = (broadcast_message.length > 0) ? get_unread_count(broadcast_message) : 0;
    return (
      <div className='position-relative'>
        <ComponentIcon isOpen={this.props.isOpen} id={'menu_message'} onClick={this.showMenu}>
          {
            unread > 0
              ? <BadgeIconSpace>{unread}</BadgeIconSpace>
              : <DropdownIconSpace ><i className='fas fa-bell' /></DropdownIconSpace>
          }
        </ComponentIcon>
        <Tooltip placement="bottom-end" isOpen={this.state.tooltipOpen} target={'menu_message'} toggle={this.toggle} delay={750}>
          {dictionary.announce_messages}
        </Tooltip>
        <DropdownCardMessage hidden={!this.state.DropdownShow}>
          {
            broadcast_message.map((message_snapshot) => {
              const message_data = message_snapshot.data
              const isUnread = get_unread(message_snapshot)
              return (
                <DropdownItem id={`btn-read_notification_${message_snapshot.ref_id}`} key={message_snapshot.ref_id}
                  className='DropdownItemMessage'
                  onClick={() => this.read_message_event(message_snapshot.ref_id, message_snapshot)}>
                  <div className='d-flex align-items-sta'>
                    {isUnread
                      && <div className='pl-1' style={{ fontSize: 20 }}>
                        <i className="fas fa-info-circle"></i>
                      </div>
                    }
                    <DropdownItemMessage>
                      {nl2br(message_data.message)}
                    </DropdownItemMessage>
                  </div>
                </DropdownItem>
              )
            })
          }
        </DropdownCardMessage>
      </div >
    )
  }
}
const get_unread_count = (messages) => {
  let message_read = getCookieJSON('__read')
  let unread = 0;
  messages.forEach(message => {
    unread = !message_read[message.ref_id] ? unread + 1 : unread
  })
  return unread;
}

const get_unread = (message => {
  if (!message) return false;
  let message_read = getCookieJSON('__read')
  //DEBUG('message_read_check', message.ref_id, message_read[message.ref_id]);
  return !message_read[message.ref_id];
})

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
    store_permission: state.permission,
    store_message: state.message
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    fetch_message, read_message
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DropdownMessage))