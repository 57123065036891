import { JOBS } from "../actions_const";

let initState = {
  isLoading: true,
  isProcess: false,
  limit: 50,
  offset: 0,

  currentPage: 0,
  totalCronjobs: 0,
  cronjobs: [],

  currentPageJobs: 0,
  totalJobs: 0,
  jobs: [],

  prepareJobs: [],
  type: "",
  toggleTabMember: "import_member_card",
};

const ReducerJobs =  (state = initState, action) => {
  switch (action.type) {
    case JOBS.IS_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case JOBS.IS_PROCESS:
      return Object.assign({}, state, {
        isProcess: action.data,
      });
    case JOBS.IS_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        isProcess: false,
        isError: action.error,
      });

    case JOBS.GET_CRONJOBS:
      return Object.assign({}, state, {
        isLoading: false,
        cronjobs: action.data.data,
        totalCronjobs: action.data.total,
        currentPage: Math.ceil(action.data.offset / action.data.limit),
      });
    case JOBS.RESET_CRONJOBS:
      return Object.assign({}, state, {
        isLoading: false,
        cronjobs: [],
        totalCronjobs: 0,
        currentPage: 0,
      });

    case JOBS.GET_CRONJOBS_DETAIL:
      return Object.assign({}, state, {
        isLoading: false,
        jobs: action.data.data || [],
        totalJobs: action.data.total,
        offset: action.data.offset,
        currentPageJobs: Math.ceil(action.data.offset / action.data.limit),
      });
    case JOBS.RESET_CRONJOBS_DETAIL:
      return Object.assign({}, state, {
        isLoading: false,
        jobs: [],
        totalJobs: 0,
        currentPageJobs: 0,
      });

    case JOBS.UPDATE_CRONJOBS_STATUS:
      let { cronjob_id, method } = action.data[0];
      let cronjobsArr = state.cronjobs.map((val, key) => {
        if (val.cronjob_id === cronjob_id) val.status = method;
        return val;
      });
      let jobsArr = state.jobs.map((val, key) => {
        val.status = method;
        val.data = val.data.map((val, key) => {
          if (val.status === "stop") val.status = method;
          return val;
        });
        return val;
      });
      return Object.assign({}, state, {
        isLoading: false,
        isProcess: false,
        cronjobs: cronjobsArr,
        jobs: jobsArr,
      });

    case JOBS.IMPORT_CRONJOBS_FILE:
      return Object.assign({}, state, {
        prepareJobs: (action.data && action.data.list) || [],
        type: action.data && action.data.type,
      });

    case JOBS.INIT_STATE_JOB_DETAIL:
      return Object.assign({}, state, {
        isLoading: false,
        jobs: [],
        totalJobs: 0,
        offset: 0,
        currentPageJobs: 0,
      });
    case JOBS.TOGGLE_TAB_MEMBER:
      return {...state, toggleTabMember: action.data }

    case JOBS.INIT_STATE:
      return {...initState, toggleTabMember: state.toggleTabMember};

    default:
      return state;
  }
};

export default ReducerJobs;