import axios from './../axios_request';

import { DEBUG, ERROR } from '../../libs/utils'
import { privateAPI } from '../end_point'
import { MASTER_DATA } from '../actions_const'
import { getCookie } from '../../libs/cookies';

export const getArchiveReasons = (call_back) => {
  const TAG = 'getArchiveReasons'
  return dispatch => {
    axios({
      method: 'get',
      url: `${privateAPI}/archive`,
      headers: { Authorization: getCookie("_token") }

    }).then(({ data }) => {
      DEBUG('THEN', TAG)
      if (typeof call_back === 'function') call_back(null, data)
      return dispatch({ type: MASTER_DATA.GET_ARCHIVE_REASONS, data: data.data })

    }).catch(err => {
      ERROR('CATCH', TAG)
      if (typeof call_back === 'function') call_back(err)
    })
  }
}