import React, { useEffect, useState } from 'react';
import { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import { messagesActions } from '../../store/messagesSlice';
import './NewMessageForm.css';

library.add(faCircle);
library.add(faQuestionCircle);

function editMessageReducer(state, action) {
  switch (action.type) {
    case 'messageChange':
      return {
        message: action.payload,
        url: state.url,
        id: state.id,
      };
    case 'urlChange':
      return {
        message: state.message,
        url: action.payload,
        id: state.id,
      };
    case 'clearState':
      return { message: '', url: '', id: '' };
    case 'setState':
      return {
        message: action.payload.message,
        url: action.payload.url,
        id: action.payload.id,
      };
    default:
      return state;
  }
}

function EditMessage() {
  const dispatch = useDispatch();

  const editMessageData = useSelector((state) => state.messages.editMessageData);

  const [editMessageState, editMessagDispatch] = useReducer(editMessageReducer, {
    message: '',
    url: '',
    id: '',
  });

  const [emptyMessage, setEmptyMessage] = useState(false);

  useEffect(() => {
    editMessagDispatch({
      type: 'setState',
      payload: {
        message: editMessageData.message,
        url: editMessageData.url,
        id: editMessageData.id,
      },
    });
  }, [editMessageData]);

  function messageChangeHandler(e) {
    editMessagDispatch({ type: 'messageChange', payload: e.target.value });
  }

  function urlChangeHandler(e) {
    editMessagDispatch({ type: 'urlChange', payload: e.target.value });
  }

  function submitHandler(e) {
    e.preventDefault();

    if (editMessageState.message === '') {
      setEmptyMessage(true);
      return;
    }

    dispatch(
      messagesActions.editMessage({
        id: editMessageState.id,
        message: editMessageState.message,
        date: new Date().toString(),
        timestamp: Date.parse(new Date().toString()),
        url: editMessageState.url,
        show: true,
      })
    );

    editMessagDispatch({ type: 'clearState' });
  }

  function cancelHandler() {
    editMessagDispatch({ type: 'clearState' });
    dispatch(messagesActions.clearEditMessageData());
  }

  function cancelEdit() {
    setEmptyMessage(false);
  }

  const infoString =
    'For bold: use [b]text[/b]\nFor italic: use [i]text[/i]\nFor underline: use [u]text[/u]\nFor image: use [img]imgUrl[/img]\nFor YouTube: use [youtube]vdoID[/youtube]\nFor line break (new line): use \\n\nIf the default display of an element is block,\nDO NOT add \\n before or after that element';

  return (
    <div>
      {emptyMessage &&
        createPortal(
          <>
            <div className="empty-message-overlay" onClick={cancelEdit}></div>
            <div className="empty-message-popup">
              <p className="title">Empty Message</p>
              <p>Cannot save an empty message</p>
              <button className="okbtn" onClick={cancelEdit}>
                OK
              </button>
            </div>
          </>,
          document.getElementById('broadcast-message-modal')
        )}
      <div className="new-message-nav">
        <p>
          <FontAwesomeIcon icon={['far', 'circle']} />
        </p>
        <p>Edit Message</p>
      </div>
      <form className="new-message-form" onSubmit={submitHandler}>
        <div>
          <label>Message</label>
          <div className="messge-textarea">
            <textarea value={editMessageState.message} onChange={messageChangeHandler}></textarea>
            <p className="message-input-questionmark">
              <FontAwesomeIcon icon={['far', 'question-circle']} />
            </p>
            <div className="message-input-info">{infoString}</div>
          </div>
        </div>
        <div>
          <label>URL/Path</label>
          <input type="text" value={editMessageState.url} onChange={urlChangeHandler}></input>
        </div>
        <div className="btns">
          <button type="submit" className="submit-btn">
            Save
          </button>
          <div className="submit-btn" onClick={cancelHandler}>
            Cancel
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditMessage;
