import SHA1 from 'sha1'
import axios from './../axios_request';

import {
  INIT_WALLET_OWNER,
  CREATE_BUSINESS,
  UPDATE_BUSINESS,
  START_BUSINESS_PROCESS,
  END_BUSINESS_PROCESS,
  IS_OWNER_EXIST,
  TOGGLE_CREATE_BUSINESS_MODAL,
  IS_CREATED_BUSINESS,
  CLEAR_BUSINESS_CODE
} from '../actions_const'
import { businessAPI } from '../end_point'

import { getCookie, setCookie } from './../../libs/cookies'
import { ERROR } from './../../libs/utils'
import { uploadPhoto } from '../../libs/Firebase'

export const initWalletOwner = (callback) => {
  return dispatch => {
    if (Boolean(localStorage._wei)) {
      if (typeof callback === 'function')
        callback(null, localStorage._wei)
      return dispatch({ type: INIT_WALLET_OWNER, owner_id: localStorage._wei })
    }

    axios({
      method: 'post',
      url: `${businessAPI}/owner`,
      headers: { Authorization: getCookie("_token") },
    }).then((data) => {
      let _wei = (data && data.data && data.data.data && data.data.data.owner_id) || ''
      let business_name = (data && data.data && data.data.data && data.data.data.business_name) || ''
      let business_code = (data && data.data && data.data.data && data.data.data.business_code) || ''
      let archive = (data && data.data && data.data.data && data.data.data.archive) || false
      localStorage._wei = _wei

      if (typeof callback === 'function')
        callback(null, data)
      return dispatch({ type: INIT_WALLET_OWNER, owner_id: _wei, business_name: business_name, business_code: business_code, archive: archive })

    }).catch((error) => {
      delete localStorage._wei
      if (typeof callback === 'function') callback(error)
      ERROR('initWalletOwner', localStorage._wei, error)

    })
  }
}

export const checkOwnerAccount = () => {
  return dispatch => {
    let wei_interval = setInterval(() => {
      let owner_id = localStorage._wei
      if (!owner_id) return
      axios({
        method: 'get',
        url: businessAPI + '/owner/' + owner_id,
        headers: { Authorization: getCookie("_token") },
      }).then(res => {
        if (!res.data.data || !res.data.data.data || !res.data.data.data.have_owner_data)
          delete localStorage._wei
        else {
          if (res.data.data.data.have_owner_data > 0) {
            dispatch({ type: IS_OWNER_EXIST, data: true })
            clearInterval(wei_interval)
          }
        }
      }).catch(err => {
        delete localStorage._wei
        clearInterval(wei_interval)
      })
    }, 5000)
  }
}

export const createBusiness = (data, callback) => {
  return dispatch => {
    localStorage.removeItem('_wei')
    dispatch({ type: START_BUSINESS_PROCESS })
    let business_code = data.business_code || SHA1(new Date().getTime().toString()).slice(0, 8)
    let business = Object.assign({}, data, {
      business_code: business_code,
      business_status: 'creating',
    })
    axios({
      method: 'post',
      url: businessAPI,
      headers: { Authorization: getCookie("_token") },
      data: business
    }).then(({ data }) => {
      dispatch({ type: IS_CREATED_BUSINESS })
      if (typeof callback === 'function')
        callback(null, data)
      setCookie('__bnc', business_code)
      setCookie(`__CBS_${business_code}`, new Date().getTime())
      dispatch({ type: CLEAR_BUSINESS_CODE })
      return dispatch({ type: CREATE_BUSINESS, data: data.data })
    }).catch((error) => {

      if (typeof callback === 'function')
        callback(error || new Error('Create Business - Internal Error'));
      return dispatch({ type: END_BUSINESS_PROCESS })
    })
  }
}

export const toggleCreateBusinessModal = () => {
  return dispatch => {
    dispatch({ type: TOGGLE_CREATE_BUSINESS_MODAL })
  }
}

export const updateBusiness = (data, callback) => {
  let { business_code } = data
  let { business_logo_url, business_s_cover_url, business_cover_url } = data

  return dispatch => {
    dispatch({ type: START_BUSINESS_PROCESS })
    uploadImgPromise(data).then(([logo, s_cover, cover]) => {
      let input = Object.assign({}, data, {
        business_logo_url: logo.url || business_logo_url,
        business_s_cover_url: s_cover.url || business_s_cover_url,
        business_cover_url: cover.url || business_cover_url
      })

      return axios({
        method: 'put',
        url: `${businessAPI}/${business_code}`,
        headers: { Authorization: getCookie("_token") },
        data: input
      })
    }).then((res) => {
      if (typeof callback === 'function') callback(null, data);
      return dispatch({ type: UPDATE_BUSINESS, data: data })

    }).catch((err) => {
      if (typeof fail_callback === 'function') callback(err);
      return dispatch({ type: END_BUSINESS_PROCESS })

    })
  }
}

const uploadImgPromise = ({ business_code, business_logo_url, business_s_cover_url, business_cover_url }) => {
  return Promise.all([
    uploadPhoto(`${business_code}/profile/logo_${business_code}.jpeg`, business_logo_url),
    uploadPhoto(`${business_code}/profile/cover_${business_code}.jpeg`, business_s_cover_url),
    uploadPhoto(`${business_code}/profile/header_${business_code}.jpeg`, business_cover_url)
  ])
}