import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { DangerBtn } from '../../components/Button'
import { cancelCronjob } from '../../redux/actions/action_jobs'

class SendJobsDel extends React.Component {
  jobActionDel(event) {
    let { store_business: { current }, type } = this.props
    let { id } = event.currentTarget.dataset
    let { match: { path } } = this.props
    let customRoute = '';
   
    if(path === '/jobsMemberCard/point/:id'){
      customRoute = '/jobsMemberCard'
    }
    this.props.cancelCronjob(current.business_code, id, type, () => {
      this.props.history.push(type === 'membercard' || customRoute === '/jobsMemberCard' ? '/jobsMemberCard' : '/jobs')
    })
  }

  render() {
    let { status, id, isProcess, store_language: { dictionary } } = this.props
    return status === 'stop'
      && <div className="d-inline-block text-center">
        {
          ['stop', 'pending'].indexOf(status) > -1 && isProcess
            ? <DangerBtn bold md>
              <i className="fas fa-spinner fa-pulse"></i>
            </DangerBtn>
            : <DangerBtn bold md id={`btn-delete`} data-id={id} data-method='pending' onClick={this.jobActionDel.bind(this)}>
              {dictionary.cancel_job}
            </DangerBtn>
        }
      </div>
  }
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
    store_members: state.members,
    store_permission: state.permission,
    store_business: state.business,
    store_jobs: state.jobs
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    cancelCronjob
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SendJobsDel))