import axios from './../axios_request';

import { businessAPI } from '../end_point'
import { USAGE } from '../actions_const'
import { getCookie } from '../../libs/cookies';

export const getUsageRecord = (business_code, callback) => {
  callback = callback ? callback : () => { }
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/usage`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (!data.result) callback(new Error(data.message), null)

      callback(null, data.data)
      return dispatch({ type: USAGE.GET_USAGE_RECORD, data: data.data, business_info: data.business_info || {} })

    }).catch(() => {
      dispatch({ type: USAGE.USAGE_RECORD_LOADING, loading: false })
      //return dispatch({ type: USAGE.GET_USAGE_RECORD, data: {} })
    })
  }
}

export const getIsOverTrans = (business_code, callback) => {
  callback = callback ? callback : () => { }
  return dispatch => {
    dispatch({ type: USAGE.LOADING_IS_OVER_TRANS })
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/usage/isOverTrans`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (!data.result) callback(new Error(data.message), null)

      callback(null, data.data)
      return dispatch({ type: USAGE.GET_IS_OVER_TRANS, data: data.data })

    }).catch(() => {
      dispatch({ type: USAGE.USAGE_RECORD_LOADING, loading: false })
    })
  }
}

export const getFreeUsageOverLimit = (business_code, callback) => { 
  callback = callback ? callback : () => { }
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/usage/txTotal`,
      headers: { Authorization: getCookie("_token") },
    }).then(({ data }) => {
      if (!data.result) callback(new Error(data.message), null)

      callback(null, data.data)
      return dispatch({ type: USAGE.GET_FREE_USAGE_OVER_LIMIT, data: data.data })

    }).catch(() => {
      dispatch({ type: USAGE.USAGE_RECORD_LOADING, loading: false })
    })
  }
}

export const exportUsageReport = ({ business_code, start_date, end_date, send_to_email, language = 'th' }, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/usage/exportUsageReport`,
      headers: { Authorization: getCookie("_token") },
      data: {
        start_date,
        end_date,
        language,
        send_to_email
      }
    }).then(({ data }) => {
      if (typeof callback === 'function') callback(null, data)
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
    });
  }
}