import React from 'react'

import { ModalBody, ModalFooter } from 'reactstrap'
import { Title2, Title3 } from '../Font'
import { InvertBtn, PrimaryBtn } from '../Button'
import { BoxPaymentAgreement } from './BusinessPayment.style'

class PaymentAgreement extends React.Component {
  componentDidMount() {
    let { store_language: { dictionary } } = this.props
    this.props.setHeaderTitle && this.props.setHeaderTitle(`${dictionary.terms_payment}`)
  }

  render() {
    let { toggle, createCustomerToken } = this.props
    let { store_language: { dictionary } } = this.props
    let terms_payment_list = dictionary.terms_payment_list

    return <>
      <ModalBody>
        <BoxPaymentAgreement>
          {
            terms_payment_list.map((val, key) => {
              return <div key={key} className='pb-3'>
                <Title2 bold>{val.title}</Title2>
                <Title3>{val.description}</Title3>
              </div>
            })
          }
        </BoxPaymentAgreement>
      </ModalBody>
      {
        this.props.isLoadingPayment ? null : (
          <ModalFooter>
          {
            this.props.loading
              ? <InvertBtn md bold disabled>
                <i className='fas fa-spinner fa-spin' />
              </InvertBtn>
              : <>
                <InvertBtn md bold onClick={toggle}>{dictionary.close}</InvertBtn>
                <PrimaryBtn md bold onClick={createCustomerToken}>{dictionary.accept}</PrimaryBtn>
              </>
          }
        </ModalFooter>
        )
      }
    </>
  }
}

export default PaymentAgreement