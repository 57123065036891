import React, { Component } from 'react';
import { ellipsis } from '../../../libs/ellipsis'
import { formatCustom } from '../../../libs/date'
import { toCurrency } from '../../../libs/currency'
import { Title3, Title4 } from '../../../components/Font'
import defaultImg from '../../../assets/img/p300x150.jpg'
import LazyImg from '../../../components/LazyImg'
import {
  CenterBox, BoxDateTime, ShowPoint,
  TitleBar, CardReward, BoxImage, ContentBox,
  Content, Ribbon, TitleRibbon, BoxDateTimeAbsolute, BoxActionValueCoupon
  // HeadcouponAbsolute,QRBox, QRImgBox,
} from './Reward.style'
class RewardCard extends Component {

  render() {
    let { data, dictionary, disabled } = this.props
    return (
      <CenterBox>
        <CardReward disabled={disabled}>
          <Ribbon>
            <TitleRibbon className='text-center'>{dictionary.rewards}</TitleRibbon>
          </Ribbon>
          <TitleBar>
            <Title3 bold>{ellipsis(data.title, 25)}</Title3>
          </TitleBar>
          <ContentBox>
            <Content>
              <div className='w-100 py-2'>
                <Title4 bold className="text-center pb-1">{dictionary.rewards}</Title4>
                {
                  data.ecard_limit_redeem
                    ? <div className='w-100 d-flex align-items-center justify-content-center'>
                      <div className='d-flex flex-column align-items-start justify-content-center'>
                        <div className='d-flex justify-content-center'>
                          <Title4 className="text-center pr-1">{`${dictionary.all} :`}</Title4>
                          <Title4 warning>{toCurrency(data.ecard_limit_redeem)}</Title4>
                        </div>
                        <div className='d-flex justify-content-center'>
                          <Title4 className="text-center pr-1">{`${dictionary.balance} :`}</Title4>
                          <Title4 warning>
                            {
                              (data.ecard_limit_redeem - data.total_send) >= 0
                                ? toCurrency(data.ecard_limit_redeem - data.total_send)
                                : 0
                            }
                          </Title4>
                        </div>
                      </div>
                    </div>
                    : <Title4 className="text-center px-1">{dictionary.infinity_amount}</Title4>
                }
              </div>
              <div className='w-100 d-flex align-items-center justify-content-center'>
                <ShowPoint>
                  <Title4 className="text-center"> {toCurrency(data.action_value)} {dictionary.pt}</Title4>
                </ShowPoint>
              </div>
            </Content>
            <BoxImage>
              {
                data.ecard_cost
                && <BoxActionValueCoupon className='mr-1'>
                  <Title4 bold>{`${dictionary.voucher_value} ${toCurrency(data.ecard_cost, 2)} ${dictionary.bath}`}</Title4>
                </BoxActionValueCoupon>
              }
              <StatusDate startDue={data.start_due} stopDue={data.stop_due} ecard_life_time={data.ecard_life_time} dictionary={dictionary} />
              <LazyImg
                dataSrc={data.link_url || data.reward_cover_url}
                placeholder={defaultImg}
                classLazyLoad={'Main-Content'}
                style={{ filter: new Date().getTime() > new Date(data.stop_due).getTime() ? 'grayscale(100%)' : null }} />
            </BoxImage>
          </ContentBox>
        </CardReward>
      </CenterBox >
    );
  }
}

const StatusDate = (props) => {
  let { dictionary } = props
  let now = new Date().getTime();
  let startDue = new Date(props.startDue).getTime()
  let stopDue = new Date(props.stopDue).getTime()

  let timeNow = now > stopDue
    ? <Title4 bold danger>{dictionary.expired_coupon}</Title4>
    : now < startDue
      ? <Title4 bold>{`${dictionary.start_on} ${formatCustom(props.startDue, 'DD MMM YY')}`}</Title4>
      : <Title4 bold>{`${formatCustom(props.startDue, 'DD MMM YY')} - ${formatCustom(props.stopDue, 'DD MMM YY')}`}</Title4>

  return <BoxDateTimeAbsolute>
    <div className='d-flex'>
      {
        props.ecard_life_time
          ? <BoxDateTime className='mr-1'>
            <Title4 bold className="text-center">
              <i className="fas fa-history pr-1" />
              {props.ecard_life_time} {dictionary.day}
            </Title4>
          </BoxDateTime>
          : null
      }
      <BoxDateTime className='mr-1'>
        {timeNow}
      </BoxDateTime>
    </div>
  </BoxDateTimeAbsolute>
}

export default RewardCard;