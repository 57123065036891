import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Title2, Title3 } from '../../components/Font'
import { getCronjobs } from '../../redux/actions/action_jobs'
import JobStatus from './JobStatus'
import { getMemberCardColumns, getPointColumns, getProductColumns } from '../BatchJobsCreate/ImportColumn'
import _ from 'lodash'
import { getCookie } from '../../libs/cookies'
import { ColData, ColHeadData, RowData, RowHeadData } from './BatchJobsDetail.style'

export default function JobsTable({ type }) {
  const store_business = useSelector((state) => state.business);
  const store_jobs = useSelector((state) => state.jobs);
  const dictionary = useSelector((state) => state.language.dictionary);
  const { form_setting_all } = useSelector((state) => state.custom_form); 
  const lang = getCookie("__lang");
  const dispatch = useDispatch();
  const business = _.get(store_business, ['current']);
  let pathname = window.location.pathname;
  let detailMinWidth = 1500;
  const customRoute = pathname.includes("jobsMemberCard") && '/jobsMemberCard'
  const memberCardInfoList = store_business.member_card_info;
  const columns = type === 'membercard' ? getMemberCardColumns() 
    : store_jobs.jobs[0].data[0].sku_detail ? getProductColumns(customRoute) : getPointColumns(store_jobs.jobs[0].data[0].amount ? 'amount' : 'points', customRoute);
  const additionalColumns = [
    {
      key: 'id',
      dictionaryTitle: "order_no",
      width: 6,
      additionalCol: true
    },
    {
      key: 'status',
      dictionaryTitle: "status",
      width: 15,
      additionalCol: true
    }
  ];
  columns.splice(0, 0, ...additionalColumns);
  let additionalWidth = Math.max(100 - _.sumBy(columns, 'width'), 0);
  if (additionalWidth > 0) {
    additionalWidth = additionalWidth / columns.length;
  }
  if (store_jobs.jobs[0].data[0].custom_form) {
    let formIdList = store_jobs.jobs[0].data[0].custom_form.map((form) => form.form_id);
    const additionalColumnsForm = form_setting_all
      .filter((form) => formIdList.includes(form.id))
      .map((form) => {
        let titleEn = _.isEmpty(form["en"].title)
          ? form["th"].title
          : form["en"].title;
        return {
          key: `custom_form_${form.id}`,
          title: { th: form["th"].title, en: titleEn },
          width: 14,
          additionalCol: true
        };
    });
    detailMinWidth = detailMinWidth + (additionalColumnsForm.length * 200);
    columns.splice(columns.length, 0, ...additionalColumnsForm);
  }


  const getDisplayedDateOfBirth = (dateOfBirth) => {
    if (lang === 'th' && dateOfBirth) {
      const year = Number(dateOfBirth.split("-")[2]);
      return dateOfBirth.replace(year, (year + 543));
    }

    return dateOfBirth;
  }

  useEffect(() => {
    return () => {
      let offset = store_jobs.currentPage * store_jobs.limit
      dispatch(getCronjobs(business.business_code, store_jobs.limit, offset, type));
    }
  }, [business]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    store_jobs.jobs.length > 0 &&
    store_jobs.jobs.map((val, key) => (
      <div key={key} className="overflow-auto">
        <div
          style={{
            minWidth: `${detailMinWidth}px`
          }}
        >
          <RowHeadData>
            {columns.map((col, index) => (
              <ColHeadData key={index} width={col.width + additionalWidth}>
                <Title2 bold secondary style={{ wordBreak: col.key.includes("custom_form") ? 'break-word' : '' }}>
                  {col.key.includes("custom_form")
                    ? col.title[lang]
                    : dictionary[col.dictionaryTitle]}
                </Title2>
              </ColHeadData>
            ))}
          </RowHeadData>
          {val.data &&
            val.data.length > 0 &&
            val.data.map((val, key) => (
              <RowData key={key} valueKey={key}>
                {columns.map((col, index) => {
                  let value = (
                    <Title3 style={{ wordBreak: "break-all" }}>
                      {" "}
                      {val[col.key] || "-"}{" "}
                    </Title3>
                  );
                  if (col.key === "status") {
                    value =
                      val.status !== "stop" ? (
                        <JobStatus
                          dictionary={dictionary}
                          status={val.status}
                          status_detail={val.status_detail}
                        />
                      ) : (
                        <Title3>-</Title3>
                      );
                  } else if (col.key === "points") {
                    value = (
                      <Title3>
                        {" "}
                        {val.points || "-"} {dictionary.pt}{" "}
                      </Title3>
                    );
                  } else if (col.key === "card_name") {
                    const card =
                      _.find(memberCardInfoList, { card_id: val.card_id }) ||
                      {};
                    value = <Title3> {card.card_name || "-"} </Title3>;
                  } else if (col.key === "date_of_birth") {
                    value = (
                      <Title3>
                        {" "}
                        {getDisplayedDateOfBirth(val.date_of_birth) || "-"}{" "}
                      </Title3>
                    );
                  } else if (col.key === "sku" && val.sku_detail) {
                    let sku_list = [];
                    val.sku_detail.forEach((item, indx) => {
                      sku_list.push(item.sku);
                      sku_list.push(<br key={indx} />);
                    });
                    value = <Title3> {sku_list || "-"} </Title3>;
                  } else if (col.key === "amount" && val.sku_detail) {
                    let amount_product = [];
                    val.sku_detail.forEach((item, indx) => {
                      amount_product.push(item.amount);
                      amount_product.push(<br key={indx} />);
                    });
                    value = <Title3> {amount_product || "-"} </Title3>;
                  } else if (col.key.includes("custom_form")) {
                    let form_id = parseInt(col.key.split("_")[2]);
                    let custom_form = val.custom_form.find((form) => form.form_id === form_id);
                    value = <Title3 style={{ wordBreak: "break-word" }}> {custom_form?.value || "-"} </Title3>;
                  }
                  return (
                    <ColData key={`${key}-${index}`} width={col.width + additionalWidth}>
                      {value}
                    </ColData>
                  );
                })}
              </RowData>
            ))}
        </div>
      </div>
    ))
  );
}