import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { Title2, Title3 } from '../../Font'
import { Label } from '../../Label'
import { CouponSelectBox, RewardCouponBox } from '../TargetGroupStyle.style'
import ModalRewardListCoupon from '../../Modal/ModalRewardListCoupon'
import RewardCard from '../../../pages/Ecard/Rewards/RewardCard'
import LoadingGradient from '../../LoadingGradient'
import {
	getTargetMemberRewardTotal,
	initBroadcastTarget,
	setTargetMemberTotal
} from '../../../redux/actions/action_broadcast';
import { getReward } from '../../../redux/actions/action_rewards'
import { toastError } from '../../ToastComponent/ToastError'
import { toCurrency } from '../../../libs/currency'

export default function TargetRewardsCoupon({ target, noRequest, disabled }) {
	const [isOpenModal, setOpenModal] = useState(false)
	const [selectedReward, setSelectedReward] = useState(target ? [target] : [])
	const dictionary = useSelector((state) => state.language.dictionary)
	const { business_code } = useSelector((state) => state.business.current)
	const [isLoading, setLoading] = useState(true)
	const dispatch = useDispatch()
	const isMounted = useRef()

	useEffect(() => {
		if (!isMounted.current) {
			isMounted.current = true
			dispatch(initBroadcastTarget('member_receive_reward'))
			if (selectedReward.length) {
				if (!noRequest) {
					dispatch(getReward({ business_code, ecard_id: selectedReward[0].ecard_id }, (error, data) => {
						setLoading(false)
						if (!error) {
							setSelectedReward([data.data])
						} else {
							toastError(dictionary.error)
							setSelectedReward([])
						}
					}))	
				} else {
					dispatch(setTargetMemberTotal({
						target: 'member_receive_reward',
						target_data: { ecard_id: selectedReward[0].ecard_id }
					}))
				}
			} else {
				setLoading(false)
			}
		}
	}, [dispatch, noRequest, business_code, selectedReward, dictionary])

	useEffect(() => {
		if (isMounted.current && !noRequest && selectedReward && selectedReward.length) {
			dispatch(getTargetMemberRewardTotal(business_code, selectedReward[0].ecard_id))
		}
	}, [dispatch, business_code, selectedReward, noRequest])

	const toggleModalRewardCoupon = () => {
		if (!disabled) {
			setOpenModal(!isOpenModal)
		}
  }

	const onSubmitRewardCoupon = (list) => {
		if (list && list.length) {
			setLoading(true)
			list.forEach((item) => dispatch(getReward({ business_code, ecard_id: item.ecard_id }, (error, data) => {
				setLoading(false)
				if (!error) {
					setSelectedReward([data.data])
				} else {
					toastError(dictionary.error)
					setSelectedReward([])
				}
			})))
		}
  }

	return (
		<>
			<FormGroup className='pb-1 mb-0'>
				<Title2 bold>
					<span className='text-danger'>* </span>
					{dictionary.select_redeem_rewards}
				</Title2>
				<div className='d-flex align-items-center justify-content-between pt-2'>
				{
					isLoading
					? <RewardLoading />
					: selectedReward.length > 0
							? 
							<div>
								<RewardCouponBox className='send_receipt_coupon_box mt-2 mb-3 pointer' onClick={toggleModalRewardCoupon}>
									{
										selectedReward.map((value, key) => {
											return <div key={key} className='my-2 d-flex align-items-center justify-content-center'>
												<RewardCard sm data={value} dictionary={dictionary} disabled={disabled} />
											</div>
										})
									}
								</RewardCouponBox>
								<RewardCouponCondition selectedReward={selectedReward} />
							</div>
							:
							<CouponSelectBox>
								{
									!disabled
									?	<Title3 center bold className='pointer' onClick={toggleModalRewardCoupon}>
											<i className='fas fa-ticket-alt fa-2x mb-1 position-relative'>
												<i className='fas fa-percentage position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: 10, color: '#FFF' }} />
											</i>
											<div> {dictionary.select_reward}</div>
										</Title3>
									: <Title3 center bold>
											<div> {dictionary.reward_not_found}</div>
										</Title3> 
								}
							</CouponSelectBox>
				}
				</div>
			</FormGroup>
			{
				isOpenModal &&
				<ModalRewardListCoupon
					selectedReward={selectedReward}
					toggleModal={toggleModalRewardCoupon}
					isOpen={isOpenModal}
					onSubmitRewardCoupon={onSubmitRewardCoupon} />
			}
		</>
	)
}

const RewardLoading = () => {
	return (
		<div className='w-100 d-flex justify-content-center flex-column'>
			<LoadingGradient type={'square'} width={'100px'} className={'w-100'} />
			<LoadingGradient type={'square'} width={'16px'} className={'w-50'} />
			<LoadingGradient type={'square'} width={'16px'} className={'w-75'}/>
		</div>
	)
}

const RewardCouponCondition = ({ selectedReward }) => {
	const dictionary = useSelector((state) => state.language.dictionary)

	return (
		<div className='ml-1'>
			{
				selectedReward && selectedReward.map((value, index) => {
					const filterCondition = value?.filter_condition
					if (filterCondition) {
						const showMemberCard = filterCondition.member_card
						const showLabel = filterCondition.show_labels && [...filterCondition.show_labels]
						const hiddenLabel = filterCondition.hidden_labels && [...filterCondition.hidden_labels]
						const actionValue = filterCondition.action_value

						return (
							<div key={`filter_${index}`}>
								{
									showMemberCard &&
									<div className={showLabel && 'mb-2'}>
										<Title2 className='mb-0' bold>{dictionary.member_card_visibility}</Title2>
										<Title2 className='ml-1' secondary>{showMemberCard === 'all' ? dictionary.all : showMemberCard}</Title2>
									</div>
								}
								{
									(showLabel && showLabel.length > 0) &&
									<div className={hiddenLabel && 'mb-2'}>
										<Title2 className='mb-1' bold>{dictionary.member_label_visibility}</Title2>
										{
											showLabel
											.sort((a, b) => a.tag_name.localeCompare(b.tag_name))
											.map((tag) => {
												return <Label
													formLabel
													pointer={false}
													disabled={false}
													key={tag.tag_id}
													type={tag.tag_type}
													name={tag.tag_name}
													color={tag.tag_style.tag_color}
													style={{ margin: '5px 5px 5px 0px' }} />
											})
										}
									</div>
								}
								{
									(hiddenLabel && hiddenLabel.length > 0) &&
									<div className={actionValue && 'mb-2'}>
										<Title2 className='mb-1' bold>{dictionary.member_label_visibility_hidden}</Title2>
										{
											hiddenLabel
												.sort((a, b) => a.tag_name.localeCompare(b.tag_name))
												.map((tag) => {
													return <Label
														formLabel
														pointer={false}
														disabled={false}
														key={tag.tag_id}
														type={tag.tag_type}
														name={tag.tag_name}
														color={tag.tag_style.tag_color}
														style={{ margin: '5px 5px 5px 0px' }} />
												})
										}
									</div>
								}
								{
									actionValue &&
									<div className='mb-2'>
										<Title2 className='mb-0 pb-2' bold>{dictionary.show_for_action_value}</Title2>
											<Title2 className='ml-1' secondary>{toCurrency(actionValue)} {dictionary.points_or_greater}</Title2>
									</div>
								}
							</div>
						)
					}

					return ''
				}).filter(f => f !== '')
			}
		</div>
	)
}