import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setCookie } from '../../libs/cookies'

import { getRToken } from './../../redux/actions/action_rtoken'
import { unsubscribeWithToken } from "../../redux/actions/action_subscribe"
import { signinCustomToken } from '../../redux/actions/action_permission'

class RedirectRoute extends React.Component {

  componentDidMount() {
    let { match: { params } } = this.props
    let { business, token, action, userToken } = params

    if (business)
      this.routeWithBusiness.bind(this)(params.business, params.route)

    if (token)
      this.routeWithToken.bind(this)(token, action)

    if (userToken)
      this.routeWithCustomToken.bind(this)(userToken, action)
  }

  routeWithBusiness(business_code, route) {
    let { history } = this.props
    setCookie('__bnc', business_code)
    history.push(`/${route || ''}`)
  }

  routeWithToken(token, action) {
    let { history } = this.props

    getRToken(token, (err, data) => {
      if (err) return history.push('/')

      switch (action) {
        case 'unsubscribe':
          return unsubscribeWithToken(token, (err, data) => history.push(`/${action}/${token}`))

        default:
          return history.push('/')
      }
    })
  }

  routeWithCustomToken(userToken, action) {
    let { history } = this.props
    this.props.signinCustomToken(userToken, action, () => {
      
          return history.push('/')
      
    })
  }

  render() {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    signinCustomToken
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RedirectRoute)