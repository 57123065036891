import { PRODUCT } from '../actions_const'

let initState = {
    isLoadingGetProduct: false,
    isLoadingUpdateProduct: false,
    isLoadingGetCategory: false,
    isLoadingUpdateCategory: false,
    productList: [],
    categoryList: [],
    hasProduct: null,
}

const ReducerProduct = (state = initState, action) => {
    switch (action.type) {
        case PRODUCT.IS_LOADING_GET_PRODUCT:
            return Object.assign({}, state, {
                isLoadingGetProduct: true
            })
        case PRODUCT.IS_LOADING_UPDATE_PRODUCT:
            return Object.assign({}, state, {
                isLoadingUpdateProduct: true
            })
        case PRODUCT.GET_PRODUCT:
            return Object.assign({}, state, {
                isLoadingGetProduct: false,
                productList: action.data || [],
            })
        case PRODUCT.CREATE_PRODUCT:
            return Object.assign({}, state, {
                isLoadingUpdateProduct: false,
                productList: action.data ? [...state.productList, action.data] : state.productList || [],
            })
        case PRODUCT.UPDATE_PRODUCT:
            return Object.assign({}, state, {
                isLoadingUpdateProduct: false,
                productList: action.data ? state.productList.map(val => val.product_id === action.data.product_id ? action.data : val) : state.productList || [],
            })
        case PRODUCT.DELETE_PRODUCT:
            return Object.assign({}, state, {
                isLoadingUpdateProduct: false,
                productList: action.data ? state.productList.filter(f => f.product_id !== action.data.product_id) : state.productList || [],
            })
        case PRODUCT.MOVE_ALL_PRODUCT_CATEGORY:
            return Object.assign({}, state, {
                isLoadingUpdateProduct: false,
                productList: action.data ? state.productList.map(val => val.product_id === action.data.product_id ? action.data : val) : state.productList || [],
            })
        case PRODUCT.IS_LOADING_GET_CATEGORY:
            return Object.assign({}, state, {
                isLoadingGetCategory: true
            })
        case PRODUCT.IS_LOADING_UPDATE_CATEGORY:
            return Object.assign({}, state, {
                isLoadingUpdateCategory: true
            })
        case PRODUCT.GET_CATEGORY_PRODUCT:
            return Object.assign({}, state, {
                isLoadingGetCategory: false,
                categoryList: action.data || [],
            })
        case PRODUCT.CREATE_CATEGORY_PRODUCT:
            return Object.assign({}, state, {
                isLoadingUpdateCategory: false,
                categoryList: action.data ? [...state.categoryList, action.data] : state.categoryList || [],
            })
        case PRODUCT.UPDATE_CATEGORY_PRODUCT:
            return Object.assign({}, state, {
                isLoadingUpdateCategory: false,
                categoryList: action.data ? state.categoryList.map(val => val.id === action.data.id ? action.data : val) : state.categoryList || [],
            })
        case PRODUCT.DELETE_CATEGORY_PRODUCT:
            return Object.assign({}, state, {
                isLoadingUpdateCategory: false,
                categoryList: action.data ? state.categoryList.filter(f => f.id !== action.data.id) : state.categoryList || [],
            })
        case PRODUCT.SET_HAS_PRODUCT:
            return Object.assign({}, state, {
                hasProduct: action.data
            })
        default:
            return state
    }
}

export default ReducerProduct;