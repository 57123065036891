import styled from 'styled-components';

export const RecentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RecentItems = styled.div`
  :nth-child(even) {
    background-color: #00000008;
  }
`
