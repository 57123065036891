export const icon = {
  eye: 'far fa-eye',
  bars: 'fas fa-bars',
  edit: 'fas fa-pen-square',
  plus: 'fas fa-plus',
  redo: 'fas fa-redo-alt',
  save: 'fas fa-save',
  undo: 'fas fa-redo-alt fa-flip-horizontal',
  user: 'fas fa-user',
  admin: 'fas fa-user',
  eraser:'fas fa-eraser',
  close: 'fas fa-times',
  email: 'fas fa-envelope-open',
  squar: 'fas fa-square',
  terms: 'fas fa-list-alt',
  trash: 'fas fa-trash-alt',
  phone: 'fas fa-phone-square',
  qrcode: 'fas fa-qrcode',
  logout: 'fas fa-sign-out-alt',
  images: 'far fa-images',
  camera: 'fas fa-camera',
  reward: 'fas fa-gift',
  search: 'fas fa-search',
  moblie: 'fas fa-mobile-alt',
  pencil: 'fas fa-pencil-alt',
  card_id: 'fas fa-id-card-alt',
  ecoupon: 'fas fa-ticket-alt',
  history: 'fas fa-history',
  spinner: 'fas fa-spinner fa-pulse',
  birthday: 'fas fa-birthday-cake',
  settings: 'fas fa-cogs',
  eye_slash: 'far fa-eye-slash',
  user_plus: 'fas fa-user-plus',
  chevron_up: 'fas fa-chevron-up',
  arrow_right: 'fas fa-long-arrow-alt-right',
  demographic: 'fas fa-venus-mars',
  pull_points: 'fas fa-share fa-rotate-180',
  send_points: 'fas fa-share',
  request_points: 'fab fa-product-hunt',
  transaction: 'fas fa-users',
  chevron_down: 'fas fa-chevron-down',
  chevron_left: 'fas fa-chevron-left',
  chevron_right: 'fas fa-chevron-right',

  eye_2x: 'far fa-eye fa-2x',
  bars_2x: 'fas fa-bars fa-2x',
  edit_2x: 'fas fa-pen-square fa-2x',
  plus_2x: 'fas fa-plus fa-2x',
  redo_2x: 'fas fa-redo-alt fa-2x',
  save_2x: 'fas fa-save fa-2x',
  undo_2x: 'fas fa-redo-alt fa-flip-horizontal fa-2x',
  user_2x: 'fas fa-user fa-2x',
  admin_2x: 'fas fa-user fa-2x',
  close_2x: 'fas fa-times fa-2x',
  email_2x: 'fas fa-envelope-open fa-2x',
  squar_2x: 'fas fa-square fa-2x',
  terms_2x: 'fas fa-list-alt fa-2x',
  trash_2x: 'fas fa-trash-alt fa-2x',
  phone_2x: 'fas fa-phone-square fa-2x',
  qrcode_2x: 'fas fa-qrcode fa-2x',
  logout_2x: 'fas fa-sign-out-alt fa-2x',
  images_2x: 'far fa-images fa-2x',
  camera_2x: 'fas fa-camera fa-2x',
  reward_2x: 'fas fa-gift fa-2x',
  search_2x: 'fas fa-search fa-2x',
  moblie_2x: 'fas fa-mobile-alt fa-2x',
  pencil_2x: 'fas fa-pencil-alt fa-2x',
  card_id_2x: 'fas fa-id-card-alt fa-2x',
  ecoupon_2x: 'fas fa-ticket-alt fa-2x',
  history_2x: 'fas fa-history fa-2x',
  spinner_2x: 'fas fa-spinner fa-pulse fa-2x',
  birthday_2x: 'fas fa-birthday-cake fa-2x',
  settings_2x: 'fas fa-cogs fa-2x',
  eye_slash_2x: 'far fa-eye-slash fa-2x',
  user_plus_2x: 'fas fa-user-plus fa-2x',
  chevron_up_2x: 'fas fa-chevron-up fa-2x',
  arrow_right_2x: 'fas fa-long-arrow-alt-right fa-2x',
  demographic_2x: 'fas fa-venus-mars fa-2x',
  pull_points_2x: 'fas fa-share fa-rotate-180 fa-2x',
  send_points_2x: 'fas fa-share fa-2x',
  transaction_2x: 'fas fa-users fa-2x',
  chevron_down_2x: 'fas fa-chevron-down fa-2x',
  chevron_left_2x: 'fas fa-chevron-left fa-2x',
  chevron_right_2x: 'fas fa-chevron-right fa-2x',

}