import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import moment from 'moment'

class index extends Component {
  render() {
    let { store_business: { current: { sms_on, sms_off } } } = this.props

    let thisDay = moment()

    let on = GetTime(sms_on)
    let off = GetTime(sms_off)

    if (GetTime(thisDay.startOf('day')) === on && GetTime(thisDay.endOf('day')) === off)
      return null

    return null
    // not use from not
    // return <Title3 secondary={secondary} danger={!secondary} bold={!secondary} className={`${center && 'text-center'}`}>
    //   {`${String.format(dictionary.send_sms_text_messages, `${on} - ${off}`)}`}
    // </Title3>

  }
}

const GetTime = (data = moment()) => moment(data, 'HH:mm:ss').format('HH:mm')

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
