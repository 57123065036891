import { css } from 'styled-components';
import { theme } from './theme';

export const GlobalCss = css`
  *{
    outline: none !important;
   }

  input.form-control, textarea.form-control, select.form-control{
    font-size: 12.5px !important;
    @media (max-width: 990px) {
      font-size: 12px !important;
    }
    color:${theme.primary} !important;
  }

  input.form-control-xs, textarea.form-control-xs, select.form-control-xs{
    font-size: 11.5px !important;
    @media (max-width: 990px) {
      font-size: 10px !important;
    }
    color:${theme.primary} !important;
  }

  input.form-control-game, textarea.form-control-game, select.form-control-game{
    color:${theme.white} !important;
  }

  input.form-control-readOnly, textarea.form-control-readOnly, select.form-control-readOnly{
    color: #262f3c !important;
    font-weight: 600;
  }

  input.form-control::placeholder, textarea.form-control::placeholder, select.form-control::placeholder{
    font-size: 11.5px !important;
    color:${theme.input_placeholder} !important;
  }

  input.form-control-xs::placeholder, textarea.form-control-xs::placeholder, select.form-control-xs::placeholder{
    font-size: 10.5px !important;
    @media (max-width: 990px) {
      font-size: 10px !important;
    }
    color:${theme.input_placeholder} !important;
  }

  input.type-number::-webkit-outer-spin-button, input.type-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  select.hidden-arraow {
    -ms-expand {display: none;}
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .text-danger {
    color:${theme.red};
  }

  .text-success {
    color:${theme.green};
  }

  body {
    font-family: ${theme.font_style.primary} !important;
    margin: 0;
  }
  .Sarabun { 
    font-family: 'Sarabun', sans-serif !important;
  }

  .table th, td {
    vertical-align: middle !important;
  }

  .terms_conditions > ol { 
    margin: 0;
    font-size: 13px;

    @media (max-width: 990px) {
      font-size: 12px;
    }
  }

  .terms_conditions > blockquote { 
    margin: 0;
    font-size: 13px;

    @media (max-width: 990px) {
      font-size: 12px;
    }
  }

  .terms_conditions > ul {
    margin: 0;
    font-size: 13px;

    @media (max-width: 990px) {
      font-size: 12px;
    }
  }

  .terms_conditions > p { 
    margin: 0;
    font-size: 13px;

    @media (max-width: 990px) {
      font-size: 12px;
    }
  }
  
  .terms_conditions > h1 { 
    margin: 0;
    font-size: 2rem;

    @media (max-width: 990px) {
      font-size: 1.6rem;
    }
  }
  
  .terms_conditions > h2 { 
    margin: 0;
    font-size: 1.5rem;

    @media (max-width: 990px) {
      font-size: 1.2rem;
    }
  }
  
  .terms_conditions > h3 { 
    margin: 0;
    font-size: 1.17rem;

    @media (max-width: 990px) {
      font-size: 0.87rem;
    }
  }

  .btn_export_xls {
    color: #fafafa;
    cursor: pointer;
    outline: none;
    box-shadow: none;
    min-width: 80px;

    font-size: 12px;
    @media (max-width: 990px) {
      font-size: 10px;
    }

    border-radius: 4px;
    border: 1px solid ${theme.primary};
    padding: .375rem .75rem;
    background-color: ${theme.primary}
  }
  
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 15px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #C3C3C4; 
    border-radius: 15px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #9C9C9C;
  }

  .pointer { 
    cursor: pointer;
  }

  .reorder-dragged {
    z-index: 1;
  }
`