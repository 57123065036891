import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import NewMessageForm from './NewMessageForm';
import MessagesSetting from './MessagesSetting';
import EditMessage from './EditMessage';
import NavBar from './NavBar';
import './PageA.css';

import { ToastBar } from '../../../../components/ToastComponent/Toast.style';
import { Title2 } from '../../../../components/Font';

import { messagesActions } from '../../store/messagesSlice';

function PageA() {
  const logout = useSelector((state) => state.user.logout);
  const dispatch = useDispatch();

  const editMessageData = useSelector((state) => state.messages.editMessageData);

  const showEdit = editMessageData.message !== '';
  const showMordal = editMessageData.showMordal;

  if (logout === null) {
    return <div></div>;
  }

  if (showMordal) {
    toast['success'](
      <ToastBar>
        <Title2 bold white style={{ width: '100%', textAlign: 'center' }}>
          Message Edited
        </Title2>
      </ToastBar>
    );

    dispatch(messagesActions.clearEditMessageData());
  }

  return (
    <>
      <NavBar></NavBar>
      <div className="broadcast-container">
        {showEdit ? <EditMessage></EditMessage> : <NewMessageForm></NewMessageForm>}
        <MessagesSetting></MessagesSetting>
      </div>
    </>
  );
}

export default PageA;
