import styled from 'styled-components'

export const Boxcenter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const InputDateRangeBox = styled.div`
 @media (max-width:770px){
   margin-bottom: 8px;
 }
`