import React from 'react'
import { connect } from 'react-redux'
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row
} from 'reactstrap'
import { bindActionCreators } from 'redux'
import { Title1, Title2, Title3, Title4, TitlePointspot } from '../../components/Font'
import ButtonWithLoading from '../../components/ButtonWithLoading'
import { verifyEmail } from '../../libs/validator'
import './login.css'
import config from '../../config'

import { selectLang } from '../../redux/actions/action_language';
import { getCookie, setCookie } from '../../libs/cookies'

import { loginWithEmailAndPassword, userLogout } from '../../redux/actions/action_permission'

import _ from 'lodash'
import Cookies from 'js-cookie'
class Login extends React.Component {
  timeoutId = null;
  constructor(props) {
    super(props)
    this.state = {
      authenProvider: {
        email: true,
        facebook: false
      },
      email: '',
      password: '',
      isLoading: false
    }
    this.emailInputRef = React.createRef();
    this.handdleChange = this.handdleChange.bind(this)
    this.loginWithEmailAndPassword = this.loginWithEmailAndPassword.bind(this)
    this.reLogin = React.createRef();
  }

  componentDidMount() {
    let { store_permission, history } = this.props
    const { match: { params } } = this.props
    const { email, businessCode } = params
    const signedInEmail = _.get(store_permission, ['profile', 'email']);
    
    if (store_permission.providerData) history.push('/signup')
    if (store_permission.isLogin) {
      history.push("/")
    } else if (email) {
      setCookie("__bnc", businessCode);
      this.emailInputRef.current.value = email;
      this.setState({ email: email });

      if (signedInEmail && signedInEmail !== email) {
        Cookies.remove('_token');
        Cookies.remove('_email');
        this.props.userLogout();
      } else if (signedInEmail === email) {
        history.push("/")
      }
    } else {
      const currentLanguage = getCookie('__lang') || 'th';
      if (currentLanguage === 'th') {
        this.timeoutId = setTimeout(() => this.onThaiHandler(), 100);
      } else {
        this.timeoutId = setTimeout(() => this.onEnglishHandler(), 100);
      }
    }
  }

  componentDidUpdate() {
    const { store_permission } = this.props;
    if (store_permission.isError && this.state.isLoading && this.reLogin.current) {
      this.setState({ isLoading: false });
      this.reLogin.current = false;
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  handdleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  loginWithEmailAndPassword(e) {
    e.preventDefault();
    this.setState({ isLoading: true }, () => {
      this.reLogin.current = true;
      this.props.loginWithEmailAndPassword(this.state.email.toLowerCase(), this.state.password)
    })
  }

  validateField() {
    return !this.state.email
      || !this.state.password
      || this.state.isLoading
      || !verifyEmail(this.state.email)
  }

  onThaiHandler = () => {
    document.getElementById("select-thai").style.fontWeight = "bolder";
    document.getElementById("select-english").style.fontWeight = "lighter";

    document.getElementById("select-thai").style.opacity = "1";
    document.getElementById("select-english").style.opacity = "0.85";

    this.props.selectLang('th');
  }

  onEnglishHandler = () => {
    document.getElementById("select-english").style.fontWeight = "bolder";
    document.getElementById("select-thai").style.fontWeight = "lighter";

    document.getElementById("select-english").style.opacity = "1";
    document.getElementById("select-thai").style.opacity = "0.85";

    this.props.selectLang('en');
  }

  render() {
    let { store_permission, store_language: { dictionary }, store_config } = this.props
    let { REACT_APP_TERMS_URL, REACT_APP_POLICY_URL } = process.env

    return (
      <Container fluid className="pg-login main-bg">
        <Row className="pt-4 pb-4">
          <Col xs={12} className="d-flex justify-content-center">
            <Card style={{ maxWidth: 500 }}>
              <CardBody>
                <Row>
                  <Col xs={12} className="text-center">
                    <p className="language-selector"><span onClick={this.onEnglishHandler.bind(this)} id="select-english">EN</span> / <span onClick={this.onThaiHandler.bind(this)} id="select-thai">TH</span></p>
                    <div className='border-bottom  pb-3'>
                      <TitlePointspot>Pointspot™</TitlePointspot>
                      <Title1>{dictionary.singin_with_pointspot_account}</Title1>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={12} className="pt-3 mb-1">
                        <Form onSubmit={this.loginWithEmailAndPassword} noValidate="novalidate" >
                          <FormGroup>
                            <Title2>{dictionary.email}</Title2>
                            <Input type="email" name="email" id="email" onChange={this.handdleChange} innerRef={this.emailInputRef} />
                          </FormGroup>
                          <FormGroup>
                            <Title2>{dictionary.password}</Title2>
                            <Input type="password" name="password" id="password" onChange={this.handdleChange} />
                          </FormGroup>
                          <ErrorMessage
                            isError={store_permission.isError}
                            message={store_permission.error === 'incorrect_email_or_password' ? dictionary.email_or_password_is_incorrect : 
                            store_permission.error === 'too_many_attempts' ? dictionary.too_many_failed_login_attempts : dictionary.an_error_has_occurred } />
                          <hr />
                          <ButtonWithLoading
                            className="btn-fluid"
                            disabled={this.validateField()}
                            isLoading={this.state.isLoading} >
                            <Title2 white id="btn-signin">{dictionary.signin}</Title2>
                          </ButtonWithLoading>
                          <br /><br />
                          <p className="text-center" style={{ fontSize: '12px' }}>
                            <span >{dictionary.accept_terms}</span><br></br>
                            <a
                              id='link-term'
                              href={REACT_APP_TERMS_URL}
                              rel="noopener noreferrer"
                              target="_blank">
                              {dictionary.terms_conditions}</a>
                            <span style={{ color: '#adadad' }}> | </span>
                            <a
                              id='link-privacypolicy'
                              href={REACT_APP_POLICY_URL}
                              rel="noopener noreferrer"
                              target="_blank">
                              {dictionary.privacy_policy}</a>
                          </p>
                        </Form>
                      </Col>
                      <Col xs={12}>
                        <div className='py-2 d-flex align-items-center justify-content-center'>
                          <Title2 bold link onClick={() => { this.props.history.push('/signup') }}>
                            {dictionary.signup_business}
                          </Title2>
                          <Title2 className='px-2'>|</Title2>
                          <Title2 bold link onClick={() => { this.props.history.push('/reset') }}>
                            {dictionary.reset_password}
                          </Title2>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs={12} className="text-center">
                    <Title3 bold style={{ cursor: 'pointer' }} onClick={() => { window.location.href = config.landingpage }}>
                      Readyplanet &copy; 2018 - {new Date().getFullYear()} All Rights Reserved
                    </Title3>
                    <Title4 secondary>Version {store_config.application.version}</Title4>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

const ErrorMessage = ({ isError, message }) => {
  return isError && <div className="text-center pt-1">
    <Title3 danger>{message}</Title3>
  </div>
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
    store_permission: state.permission,
    store_config: state.config,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    loginWithEmailAndPassword, selectLang, userLogout
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)