import { createSlice } from '@reduxjs/toolkit';

const messagesSlice = createSlice({
  name: 'messages',
  initialState: {
    messages: [],
    editMessageData: { message: '', url: '', id: '', showMordal: false },
    getMessagesData: {
      continueGetMessages: true,
      lastTimestamp: Date.parse(new Date().toString()),
      noMore: false,
    },
    userData: { idToken: null, clientId: null },
  },
  reducers: {
    addNewMessage(state, action) {
      state.messages.push(action.payload);

      const newMessage = {
        message: action.payload.message,
        linkto: action.payload.url,
        date_broadcast: action.payload.date,
        show: action.payload.show,
        id: action.payload.id,
        timestamp: action.payload.timestamp,
      };

      fetch(`${process.env.REACT_APP_BACKEND_API}/broadcastmessage/message`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body:
          'newMessage=' +
          JSON.stringify(newMessage) +
          '&idToken=' +
          state.userData.idToken +
          '&clientId=' +
          state.userData.clientId,
      });
    },
    editMessage(state, action) {
      state.messages = state.messages.filter((message) => message.id !== action.payload.id);
      state.messages.push(action.payload);
      state.editMessageData = { message: '', url: '', id: '', showMordal: true };

      const updateData = {
        type: 'edit',
        message: action.payload.message,
        linkto: action.payload.url,
        date_broadcast: new Date().toString(),
        timestamp: Date.parse(new Date().toString()),
      };

      fetch(`${process.env.REACT_APP_BACKEND_API}/broadcastmessage/update/${action.payload.id}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body:
          'updateData=' +
          JSON.stringify(updateData) +
          '&idToken=' +
          state.userData.idToken +
          '&clientId=' +
          state.userData.clientId,
      });
    },
    showMessage(state, action) {
      const targetMessage = state.messages.find((message) => message.id === action.payload);
      targetMessage.show = true;

      const updateData = {
        type: 'show',
      };

      fetch(`${process.env.REACT_APP_BACKEND_API}/broadcastmessage/update/${action.payload}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body:
          'updateData=' +
          JSON.stringify(updateData) +
          '&idToken=' +
          state.userData.idToken +
          '&clientId=' +
          state.userData.clientId,
      });
    },
    hideMessage(state, action) {
      const targetMessage = state.messages.find((message) => message.id === action.payload);
      targetMessage.show = false;

      const updateData = {
        type: 'hide',
      };

      fetch(`${process.env.REACT_APP_BACKEND_API}/broadcastmessage/update/${action.payload}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body:
          'updateData=' +
          JSON.stringify(updateData) +
          '&idToken=' +
          state.userData.idToken +
          '&clientId=' +
          state.userData.clientId,
      });
    },
    editMessageInit(state, action) {
      const targetMessage = state.messages.find((message) => message.id === action.payload);
      state.editMessageData = {
        message: targetMessage.message,
        url: targetMessage.url,
        id: targetMessage.id,
        showMordal: false,
      };
    },
    deleteMessage(state, action) {
      state.messages = state.messages.filter((message) => message.id !== action.payload);

      const updateData = {
        type: 'delete',
      };

      fetch(`${process.env.REACT_APP_BACKEND_API}/broadcastmessage/update/${action.payload}`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body:
          'updateData=' +
          JSON.stringify(updateData) +
          '&idToken=' +
          state.userData.idToken +
          '&clientId=' +
          state.userData.clientId,
      });
    },
    clearEditMessageData(state, action) {
      state.editMessageData = { message: '', url: '', id: '', showMordal: false };
    },
    setMessages(state, action) {
      state.messages = state.messages.concat(action.payload.newMessages);
      state.getMessagesData.lastTimestamp = action.payload.lastTimestamp;
      state.getMessagesData.continueGetMessages = false;

      if (action.payload.newMessagesLength < 20) {
        state.getMessagesData.noMore = true;
      }
    },
    stopGetMessages(state, action) {
      state.getMessagesData = {
        continueGetMessages: false,
        lastTimestamp: state.getMessagesData.lastTimestamp,
        noMore: true,
      };
    },
    getMoreMessages(state, action) {
      state.getMessagesData = {
        continueGetMessages: true,
        lastTimestamp: state.getMessagesData.lastTimestamp,
        noMore: state.getMessagesData.noMore,
      };
    },
    setUserData(state, action) {
      state.userData = {
        idToken: action.payload.idToken,
        clientId: action.payload.clientId,
      };
    },
  },
});

export const messagesActions = messagesSlice.actions;
export default messagesSlice.reducer;
