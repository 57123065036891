import axios from '../redux/axios_request';
import { publicAPI } from '../redux/end_point';

export const sendConsent = (uid) => {
	return axios({
		method: 'post',
		headers: {'Content-Type' : 'application/json'},
		url: publicAPI + '/consent',
		data: {
			uid: uid
		}
	})
}