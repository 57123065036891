import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { getVerificationBusinessByHash } from "../../redux/actions/action_business";
import Loading from "../../components/Loading";
import VerifybusinessHashShow from "./verifybusinessHashShow";
import GoogleLogin from "./GoogleLogin";
import { VerifyBusinessContext } from './context';

export default function VerifyBusinessHash(props) {
  const { userData } = useSelector((state) => state.manager);
  const { isProcess } = useSelector((state) => state.business);
  const dispatch = useDispatch();
  const isMounted = useRef(false);
  const { hash } = props.match.params;

  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;
    else return;

    if (!hash || hash === null) return props.history.push("/");
  }, [hash, props.history]);

  useEffect(() => {
    if (!userData || !userData.idToken) return;

    dispatch(
      getVerificationBusinessByHash(hash)
    );
  }, [dispatch, userData, hash]);

  if (!userData || !userData.idToken) {
    return (
      <VerifyBusinessContext.Provider value={{ hash: props.match.params.hash }}>
        <Router>
          <Route path={"/googlelogin"}>
            <GoogleLogin redirectTo={`/verifybusiness`} />
          </Route>
          <Redirect
            to={{
              pathname: "/googlelogin",
              from: `/verifybusiness/${props.match.params.hash}`,
            }}
          />
        </Router>
      </VerifyBusinessContext.Provider>
    );
  }

  return isProcess ? <Loading /> : <VerifybusinessHashShow {...props} />;
}
