import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Title2 } from "../../components/Font";
import {
  BoxHead,
  BoxProcess,
  BoxProcessHead,
} from "./verifyBusinessHash.style";
import BodyDetail from "../../pages/VerifyBusiness/verifycomponents/bodyDetail";

export default function AddressInfo({ status, onCheck, info }) {
  const dictionary = useSelector((state) => state.language.dictionary);
  const [expand, setExpand] = useState(false);

  return (
    <BoxProcess select={!status}>
      <BoxProcessHead select={!status} pointer>
        <div onClick={onCheck}>
          {status ? (
            <i className="far fa-circle pr-2"></i>
          ) : (
            <i className="fas fa-times-circle pr-2"></i>
          )}
        </div>
        <Title2 className="flex-grow-1" onClick={() => setExpand(!expand)} bold white>
          รายละเอียดสถานประกอบการ
        </Title2>
        <div onClick={() => setExpand(!expand)}>
          {expand ? (
            <i className="fas fa-chevron-up"></i>
          ) : (
            <i className="fas fa-chevron-down"></i>
          )}
        </div>
      </BoxProcessHead>
      {expand ? (
        <div>
          {info.status_info !== null ? (
            <BodyDetail dictionary={dictionary} readOnly />
          ) : (
            <BoxHead center>
              <Title2 bold danger>
                ไม่มีข้อมูลการยืนยันรายละเอียดสถานประกอบการ
              </Title2>
            </BoxHead>
          )}
        </div>
      ) : null}
    </BoxProcess>
  );
}
