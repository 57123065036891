import styled, { css } from 'styled-components'

export const CreditCardShow = styled.div`
  @media (max-width: 1440px){
    max-width: 250px;
    max-height: 150px;
  }
  @media (max-width: 768px){
    max-height: 135px;
  }
  width: 25vw;
  max-width: 270px;
  min-width: 220px;
  height: 23vh;
  max-height: 155px;
  min-height: 135px;
  border: 1px solid #000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 15px;
  background-image: linear-gradient(to bottom right,#a7a6a6, #FFF, #a7a6a6);
  box-shadow: 0px 0px 9px 1px rgba(128,128,128,0.88);
`
export const CreditCardShowDetail = styled.div`
  width: 100%;
`
export const ChipCard = styled.div`
  @media (max-width: 1440px){
    width: 30px;
    height: 25px;
  }
  width: 36px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #735724;
  overflow: hidden;
  background-image: linear-gradient(to bottom right,#BF9F63, #F2D399, #BF9F63);
`
export const InChipCard = styled.div`
  @media (max-width: 1440px){
    width: 10px;
    height: 5px;
  }
  width: 12px;
  height: 6px;
  background-image: linear-gradient(to bottom right,#BF9F63, #F2D399, #BF9F63);
  ${props => props.topLeft && css`border-width: 0px 1px 1px 0px;`}
  ${props => props.centerLeft && css`border-width: 1px 1px 1px 0px;`}
  ${props => props.bottomLeft && css`border-width: 1px 1px 0px 0px;`}
  ${props => props.topRight && css`border-width: 0px 0px 1px 1px;`}
  ${props => props.bottomRight && css`border-width: 1px 0px 0px 1px;`}
  ${props => props.centerRight && css`border-width: 1px 0px 1px 1px;`}
  border-style: solid;
  border-color: #735724;
`

export const BlackCircle = styled.div`
  width: 7px;
  height: 7px;
  @media (max-width: 990px) {
    width: 5px;
    height: 5px;
  }

  margin: 1px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #546174;
`

export const BlockAlignCenter = styled.div`
  display: flex;
  align-items: center;
`
export const CreditLogoImage = styled.img`
  height: auto;
  max-width: 40px;
`