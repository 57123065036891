import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input } from 'reactstrap'
import { Title2 } from '../../Font'
import { getTargetMemberCardTotal, initBroadcastTarget, setTargetMemberTotal } from '../../../redux/actions/action_broadcast'
import { toastError } from '../../ToastComponent/ToastError'
import { useGetMemberCard } from '../../../libs/hooks/useGetMemberCard'

export default function TargetMemberCard({ target, disabled, noRequest, showAsLabel }) {
	const [selectedCard, setSelectedCard] = useState(target || '')
	const dictionary = useSelector((state) => state.language.dictionary)
	const businessCode = useSelector((state) => state.business.current.business_code)
	const dispatch = useDispatch()
	const memberCardInfo = useGetMemberCard()
	const isMounted = useRef()

	useEffect(() => {
	
		if (!isMounted.current) {
			dispatch(initBroadcastTarget('member_card'))
			isMounted.current = true
			if (!noRequest) {
				if (selectedCard !== '') {
					dispatch(getTargetMemberCardTotal(businessCode, selectedCard, (err, data) => {
						if (!isMounted.current) return null
						if (err) toastError(dictionary.error)
					}))
				}
			}	else {
				dispatch(setTargetMemberTotal({
					target: 'member_card',
					target_data: selectedCard
				}))
			}
		}
	
		return () => {
			isMounted.current = undefined
		}
	}, [dispatch, selectedCard, businessCode, noRequest, dictionary])

	const handleChange = (e) => {
		let { value } = e.target
		setSelectedCard(value)
	}

	const MemberCardListMemo = () => {
		return (
			<>
			<option disabled value=''> -- { dictionary.choose } -- </option>
			<option value={ 'all' }> { dictionary.all }</option>
				{
					memberCardInfo
          ? memberCardInfo.map((v, idx) => (
              <option value={v.card_id} key={`${v.card_id}_${idx}`}>
                {v.card_name}
              </option>
            ))
					: []
				}
			</>
		)
	}

	const RenderCardSelected = () => {

		if (selectedCard === 'all') return (<Title2 key={'all'} className='pl-3' secondary >{ dictionary.all }</Title2>) 
		
		const memberCardSelected = memberCardInfo ? memberCardInfo.filter((item) => item.card_id.toString() === selectedCard.toString()) : []
		
		return (
			memberCardSelected.map((item) => <Title2 key={item.card_name} className='pl-3' secondary >{ item.card_name }</Title2>)
		)
	}

	return (
		<FormGroup className='pb-1'>
			<Title2 className='pb-2' bold><span className='text-danger'>* </span>{dictionary.select_member_card}</Title2>
			{
				showAsLabel
					? <RenderCardSelected />
					:	<Input
							type="select"
							name='member_card'
							value={selectedCard}
							onChange={handleChange}
							disabled={!memberCardInfo || disabled}
						>
							<MemberCardListMemo/>
						</Input>
			}
	</FormGroup>
	)
}