import styled, { css, keyframes } from 'styled-components'

export const ReviewBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const ReviewRatelStar = styled.div`
  width: 100%;
  padding-left: 15px;
  > * {
    &:first-child {
      padding-top: 0px;
    }
    padding-top: 15px;
  }
`

export const ReviewBoxStar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-out;
  ${props => props.show ? css`opacity: 1;` : css`opacity: 0.2;`}
`

export const ReviewTitleStar = styled.div`
  min-width: 20px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ReviewTitlePercent = styled.div`
  min-width: 105px;
  padding-left: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 990px) {
    min-width: 95px;
  }
`

export const ReviewTitleReview = styled.div`
  min-width: 45px;

  display: flex;
  align-items: center;
  justify-content: center;
  
`

export const ReviewBar = styled.div`
  width: 100%;
  height: 16px;

  border-radius: 5px;
  background-color: #F0F0F0;
`

const rotate = (value) => keyframes`
  from { width: 0%; }
    to { width: ${value}%; }
`

const fadeSize = keyframes`
    0% { font-size: 0px; }
   50% { font-size: 85px; }
   80% { font-size: 55px; }
  100% { font-size: 70px; }
`

export const ReviewBarPercent = styled.div`
  width: ${props => props.percent || 0}%;
  height: 16px;

  border-radius: 5px;
  background-color: ${props => props.theme.primary};
  animation: ${rotate(props => props.percent)} 1s cubic-bezier(0.22, 0.61, 0.36, 1);
`

export const ReviewRatelTotal = styled.div`
  width: 100%;
  height: 100%;
  
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ReviewTotal = styled.div`
  color: ${props => props.theme.primary};
  font-weight: ${props => props.theme.font_bold};

  font-size: 26px;
  @media (max-width: 990px) {
    font-size: 24px;
  }
`

export const ReviewTitleStarAll = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
`

export const ReviewTitleStarBox = styled.div`
  position: relative;
  min-width: 100%;
  min-height: 100%;
`

export const ReviewIcon = styled.i`
  font-size: 70px;
  color: ${props => props.theme.primary};
  animation: ${fadeSize} 0.6s linear;
  animation-delay: -0.1s;
`

export const ReviewTotalStar = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%,-50%);

  color: ${props => props.theme.white};
  font-weight: ${props => props.theme.font_bold};
 
  font-size: 22px;
  @media (max-width: 990px) {
    font-size: 20px;
  }
`

export const ReviewTitleStarBoxtotal = styled.div`
  padding-top: 10px;
`

export const ReviewsRateBox = styled.div`
  padding: 10px;
  margin: 0px 15px 30px 15px;

  border-radius: 5px;
  border: 1px solid #dee2e6;
  transition: all .25s cubic-bezier(0.4, 0, 0.2, 1);

  :hover{
    border: 1px solid #CCC;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
  }
`

export const ReviewsHeadBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #CCC;
  cursor: pointer;
`

export const ReviewsHeadOverlay = styled.div`
    position: absolute;
    width: calc(100% - 50px);
    height: 40px;
`

export const CenterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const ProfilePhoto = styled.img`
  width: 110px;
  height: 110px;
  border: 1px solid #CCC;
  border-radius: 50%;
`

export const StarOptionBox = styled.div`
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // @media (max-width: 767px){
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  // }
`

// 
export const UserDataContainer = styled.div`
  border-radius: 5px;
  background: #F5F5F5;
  padding: 7px 15px;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  // max-height: 150px;
  width: 100%;
  overflow: hidden;
  // overflow-y: scroll;
`


const rotateLoader = keyframes`
    0% { transform: rotate(0deg) scale(0.6); }
   50% { transform: rotate(360deg) scale(0.4); }
  100% { transform: rotate(720deg) scale(0.6); }
`

const ball1 = keyframes`
    0% { box-shadow: 30px 0 0 #CCC; }
   50% { box-shadow: 0 0 0 #CCC;
         margin-bottom: 0;
         transform: translate(15px,15px); }
  100% { box-shadow: 30px 0 0 #CCC; 
         margin-bottom: 10px; }
`

const ball2 = keyframes`
    0% { box-shadow: 30px 0 0 #EEE; }
   50% { box-shadow: 0 0 0 #EEE;
         margin-top: -20px;
         transform: translate(15px,15px); }
  100% { box-shadow: 30px 0 0 #EEE;
         margin-top: 0; }
`

export const Loader = styled.div`
  animation: ${rotateLoader} 1s infinite;  
  height: 50px;
  width: 50px;
  &:before,&:after{
    //border-radius: 50%;
    content: '';
    display: block;
    height: 20px;  
    width: 20px;
  }
  &:before{
    animation: ${ball1} 1s infinite;  
    background-color: #EEE;
    box-shadow: 30px 0 0 #CCC;
    margin-bottom: 10px;
  }
  &:after{
    width: 20px;
    animation: ${ball2} 1s infinite; 
    background-color: #CCC;
    box-shadow: 30px 0 0 #EEE;
  }
`

export const ReviewBtnDropdown = styled.div`
  display: ${props => props.isOpen ? 'block' : 'none'};
  cursor: pointer;
  background-color: #FFFFFF;
  padding: 0px;
  width: 100%;

  border-radius: 4px; 
  border: 1px solid #546174;

  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 1;
`
