import { useEffect, useCallback } from "react";

const useTooltip = () => {
  const createTooltip = useCallback(() => {
    const tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.innerHTML = "<table></table>";
    document.body.appendChild(tooltipEl);
    return tooltipEl;
  }, []);

  useEffect(() => {
    const tooltipEl = createTooltip();

    return () => {
      if (document.body.contains(tooltipEl)) {
        document.body.removeChild(tooltipEl);
      }
    };
  }, [createTooltip]);
};

export default useTooltip;