import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import UpFileContainer from "./UpFileContainer";
import PreDataContainer from "./PreDataContainer";
import { getCustomFormSetting } from '../../redux/actions/action_custom_form'
import { useDispatch, useSelector } from "react-redux";
//import _ from 'lodash'

export default function CronjobsCreateContainer({ type = "point", customRoute }) {
	// const store_jobs = useSelector((state) => state.jobs);
	// const latestCronJob = _.first(store_jobs.cronjobs) || {}
	const dispatch = useDispatch();
	const business = useSelector((state) => state.business.current);
	const [message, setMessage] = useState({
		text: "",
		isError: false,
	});

	const onMessageChange = (data) => {
		setMessage(data);
	};

	if (type === "membercard" || (type === "point" && customRoute === "/jobsMemberCard")) {
		customRoute = "/jobsMemberCard";
	}

	useEffect(() => {
		if (business.custom_form && customRoute === "/jobsMemberCard")
			dispatch(getCustomFormSetting({ business_code: business.business_code}))
		// eslint-disable-next-line
	}, [business]);

	return (
		<Container className="mt-3">
			<UpFileContainer type={type} message={message} onMessageChange={onMessageChange} customRoute={customRoute} />
			<PreDataContainer type={type} message={message} customRoute={customRoute} />
		</Container>
	);
}
