import styled from 'styled-components'

export const BtnContainer = styled.div`
  position:absolute;
  bottom: 0;
  width:100%;
  height: 70px;
  padding: 15px 15px;
  display: flex;
  justify-content: flex-end;
`

export const AmountContainer = styled.div`
  max-width:500px;
  padding:15px;
`

export const SwitchToggleBar = styled.div`
  width: 100%;
  padding: 20px 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.25rem;
  background-color: #F7F7F7;
  border: 1px solid rgba(0, 0, 0, 0.125);
`

export const BadgePro = styled.div`
  font-size: 12px !important;
  font-weight: 500;
  color: #212529;
  background-color: #ffc107;
  padding: 4px 8px;
`

export const BagdeNew = styled.div`
  min-width: 20px;

  color: ${props => props.theme.green};
  padding: 0em 0.4em;

  border: 1px solid ${props => props.theme.green};
  border-radius: 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  font-size: 12px;
`