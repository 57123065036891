export const templateRFM = [
  { segment_group_id: 1, RFM_key: 'champions' },
  { segment_group_id: 2, RFM_key: 'loyal_customers' },
  { segment_group_id: 3, RFM_key: 'potential_loyal_list' },
  { segment_group_id: 4, RFM_key: 'new_customers' },
  { segment_group_id: 5, RFM_key: 'promise' },
  { segment_group_id: 6, RFM_key: 'need_attention' },
  { segment_group_id: 7, RFM_key: 'about_to_sleep' },
  { segment_group_id: 8, RFM_key: 'cant_lose_them' },
  { segment_group_id: 9, RFM_key: 'at_risk' },
  { segment_group_id: 10, RFM_key: 'hibernating' }
];