const { REACT_APP_RP_ACCOUNT_FUNCTION, REACT_APP_RP_ACCOUNT, REACT_APP_POINTSPOT_HOSTNAME, REACT_APP_AXIOS_TIMEOUT, REACT_APP_TRANS_LIMIT } = process.env

const config = {
  firebase: {
    functions: REACT_APP_RP_ACCOUNT_FUNCTION
  },
  rp_account: REACT_APP_RP_ACCOUNT,
  pointspot_hostname: REACT_APP_POINTSPOT_HOSTNAME,
  axios_timeout: REACT_APP_AXIOS_TIMEOUT || 30000,
  landingpage: 'https://goo.gl/TXq2yF',
  limit_per_page: REACT_APP_TRANS_LIMIT
}

export default config