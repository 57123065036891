import _ from "lodash";
import React, { useEffect } from "react";
import {
  useParams
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import Loading from "../../components/Loading";
import { getPrivilegeAndInvite } from "../../redux/actions/action_admin";
import { initialJobssState } from "../../redux/actions/action_jobs";
import JobsContainer from "./JobsContainer";
import StickyMenu from "./StickyMenu";
import { getMemberCardInfo } from "../../redux/actions/action_business";
import { getCustomFormSetting } from "../../redux/actions/action_custom_form";

export default function MemberCardJobsDetail() {
  const store_business = useSelector((state) => state.business);
  const { form_setting_all } = useSelector((state) => state.custom_form); 
  const dispatch = useDispatch();
  const params = useParams();

  const business = _.get(store_business, ['current']);
  const memberCardInfoList = store_business.member_card_info;

  useEffect(() => {
    if (business.business_code) {
      dispatch(getPrivilegeAndInvite(business.business_code));
    }
    if (_.isEmpty(form_setting_all)){
      dispatch(getCustomFormSetting({ business_code: business.business_code, with_archive: true }));
    }

    return () => {
      dispatch(initialJobssState());
    }
  }, [business]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_.isEmpty(memberCardInfoList) && business.business_code) {
      dispatch(getMemberCardInfo(business.business_code));
    }
  }, [business, memberCardInfoList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {
        store_business.isFetching || !params.id ? (
          <Container fluid style={{ margin: '30px 0px' }}>
            <Loading />
          </Container>
        ) : (
          <>
            <StickyMenu customRoute="/jobsMemberCard" />
            <JobsContainer 
              customRoute="/jobsMemberCard" 
              type="membercard" 
              cronJobsId={params.id} 
            />
          </>
        )
      }
    </div>
  )
}