import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ModalBody, Row, Col, FormGroup, Input } from 'reactstrap'
import Loadable from 'react-loadable'

//import GoogleMap from '../../../components/GoogleMap'
import { Title1, Title2, Title3 } from '../../../components/Font'
import { SuccessBtn, InvertBtn } from '../../../components/Button'
import { verifyEmail, verifyPhone, verifyIDcard, verifyZipcode } from '../../../libs/validator'

import { BusinessTypebar, BusinessTypeIcon, ModelDetailFooter, ScrollModal, InputBox } from '../verifyBusiness.style'

const WarningIncognito = () => {
  return <div className='text-center'>
    <Title1 danger>ไม่รองรับการใช้งานใน Incognito Mode กรุณาใช้งานในบราวเซอร์ปกติ<br />
      Incognito mode is not supported. Please use normal browser.</Title1>
  </div>
}
const GoogleMap = Loadable({
  loader: () => import('../../../components/GoogleMap'),
  loading: WarningIncognito,
})

class BodyDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      businessType: 'corporation',
      company: '',
      id: '',
      name: '',
      surname: '',
      email: '',
      phone: '',
      addressline1: '',
      addressline2: '',
      district: '',
      province: '',
      postal_code: '',
      country: '',
      lat: 13.755137989046432,
      lng: 100.49314539626528,
    }
  }

  componentDidMount() {
    let { store_business: { verifyBusiness } } = this.props

    if (verifyBusiness.owner_name && verifyBusiness.mobile)
      this.setState({
        businessType: verifyBusiness.business_type || 'corporation',
        company: verifyBusiness.owner_name,
        id: this.formatID(verifyBusiness.identity_number || ''),
        name: verifyBusiness.first_name,
        surname: verifyBusiness.last_name,
        email: verifyBusiness.email,
        phone: verifyBusiness.mobile.replace('+66', '0'),
        addressline1: verifyBusiness.address_line1,
        addressline2: verifyBusiness.address_line2,
        district: verifyBusiness.district,
        province: verifyBusiness.province,
        postal_code: verifyBusiness.postal_code,
        country: verifyBusiness.country,
        lat: verifyBusiness.latitude,
        lng: verifyBusiness.longitude,
      })
  }

  handleChange = (event) => {
    let { value, name } = event.target
    let newState = { [name]: value, [`Err${name}`]: false }

    switch (name) {
      case 'phone':
        Object.assign(newState, { [name]: value, [`Err${name}`]: !verifyPhone(value) })
        break;
      case 'email':
        Object.assign(newState, { [name]: value, [`Err${name}`]: !verifyEmail(value) })
        break;
      case 'id':
        let fID = this.formatID(value)
        Object.assign(newState, { [name]: fID, [`Err${name}`]: !verifyIDcard(value) })
        break;
      case 'postal_code':
        Object.assign(newState, { [name]: value, [`Err${name}`]: !verifyZipcode(value) })
        break;
      case 'country':
        Object.assign(newState, { [name]: value || 'ไทย', [`Err${name}`]: false })
        break;
      default:
        Object.assign(newState, { [name]: value, [`Err${name}`]: value === '' })
    }

    this.setState(newState)
  }

  formatID = (number) => {
    number = number.toString().replace(/[\W\D\s_]+/g, '')
    let split = [1, 4, 5, 2, 1]
    let chunk = []

    for (let index = 0, i = 0, len = number.length; i < len; i += split[index], index++) {
      chunk.push(number.substr(i, split[index]))
    }
    return chunk.join(' ')
  }

  onChangeBusinessType = (value) => {
    if (value !== this.state.businessType)
      this.setState({
        businessType: value,
        company: '',
        id: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        addressline1: '',
        addressline2: '',
        district: '',
        province: '',
        postal_code: '',
        country: '',
      })
  }

  verifySubmit = () => {
    const { businessType, company, id,
      name, surname, email,
      phone, addressline1, addressline2,
      district, province, postal_code,
      country, lat, lng } = this.state
    return !(businessType
      && (name && name.trim())
      && (surname && surname.trim())
      && (company && company.trim())
      && (addressline1 && addressline1.trim())
      && (addressline2 && addressline2.trim())
      && (district && district.trim())
      && (province && province.trim())
      && (country && country.trim())
      && verifyIDcard(id)
      && verifyEmail(email)
      && verifyPhone(phone)
      && verifyZipcode(postal_code)
      && lat
      && lng
    )
  }

  submitVeriftBusiness = () => {
    const { store_business: { current, verifyBusiness } } = this.props
    if (verifyBusiness) {
      const dataVerify = {
        business_code: current.business_code,
        businessType: this.state.businessType,
        company_name: this.state.company.trim(),
        id: this.state.id.replace(/[\W\D\s_]+/g, ''),
        name: this.state.name.trim(),
        surname: this.state.surname.trim(),
        email: this.state.email.trim(),
        phone: this.state.phone.trim(),
        addressline1: this.state.addressline1.trim(),
        addressline2: this.state.addressline2.trim(),
        district: this.state.district.trim(),
        province: this.state.province.trim(),
        postal_code: this.state.postal_code.trim(),
        country: this.state.country.trim(),
        lat: this.state.lat,
        lng: this.state.lng,
        address_id: verifyBusiness.address_id,
        type_update: 'verifyIden'
      }
      this.props.update_detail(dataVerify)
    }
  }

  getDataGoogleMap = ({ lat, lng }) => {
    this.setState({ lat: lat, lng: lng })
  }

  render() {
    let { dictionary } = this.props
    let { readOnly } = this.props
    let { store_business: { verifyBusiness } } = this.props

    let _isTypeCorp = this.state.businessType === 'corporation'

    const businessTypeOption = [
      { name: dictionary.juristic_person, value: 'corporation' },
      { name: dictionary.ordinary_person, value: 'person' }
    ];

    return (
      <ModalBody>
        <ScrollModal readOnly={readOnly}>
          <Row>
            <Col xs={12} md={12} lg={12} xl={6}>
              <Row>
                <Col xs={12}>
                  <Title2 bold className='pb-2'><span className='text-danger'>* </span>{dictionary.choose_registration_type}</Title2>
                </Col>
                {
                  businessTypeOption.map((value, key) => {
                    return <Col className='d-flex align-items-center justify-content-center' xs={12} md={6} lg={6} xl={6} key={key}>
                      <FormGroup className='w-100'>
                        <BusinessTypebar select={this.state.businessType === value.value} pointer={!(verifyBusiness.status_info || readOnly)}
                          onClick={() => { !(verifyBusiness.status_info || this.props.isHomeLoading || readOnly) && this.onChangeBusinessType(value.value) }}>
                          {
                            value.name === dictionary.juristic_person
                              ? <BusinessTypeIcon className="fas fa-building pr-2" select={this.state.businessType === value.value}></BusinessTypeIcon>
                              : <BusinessTypeIcon className="fas fa-user pr-2" select={this.state.businessType === value.value}></BusinessTypeIcon>
                          }
                          <Title3 bold third={this.state.businessType !== value.value}>{value.name}</Title3>
                        </BusinessTypebar>
                      </FormGroup>
                    </Col>
                  })
                }
              </Row>
              <Row>
                <Col xs={12} md={6} lg={12}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errcompany} className='pb-2'><span className='text-danger'>* </span> {_isTypeCorp ? dictionary.company_name : dictionary.business_name}</Title2>
                    <Input
                      id='ship-company'
                      type='text'
                      name='company'
                      autoComplete="billing organization"
                      onChange={this.handleChange}
                      value={this.state.company || ''}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly}
                      placeholder={_isTypeCorp ? dictionary.company_name : dictionary.business_name} />
                  </FormGroup>
                </Col><Col xs={12} md={6} lg={12}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errid} className='pb-2'><span className='text-danger'>* </span>{_isTypeCorp ? dictionary.tax_id : dictionary.id}</Title2>
                    <Input
                      id='id'
                      name='id'
                      type='text'
                      autoComplete={_isTypeCorp ? 'billing tax-id' : 'billing citizen-id'}
                      maxLength="17"
                      onChange={this.handleChange}
                      value={this.state.id || ''}
                      placeholder={_isTypeCorp ? dictionary.tax_id : dictionary.id}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errname} className='pb-2'><span className='text-danger'>* </span>{_isTypeCorp ? dictionary.name_of_director : dictionary.owner_name}</Title2>
                    <Input
                      id='fname'
                      type='text'
                      name='name'
                      autoComplete="billing given-name"
                      onChange={this.handleChange}
                      value={this.state.name || ''}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly}
                      placeholder={_isTypeCorp ? dictionary.name_of_director : dictionary.owner_name} />
                  </FormGroup>
                </Col><Col xs={12} md={6}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errsurname} className='pb-2'><span className='text-danger'>* </span>{dictionary.surname}</Title2>
                    <Input
                      id='lname'
                      type='text'
                      name='surname'
                      autoComplete="billing family-name"
                      value={this.state.surname || ''}
                      onChange={this.handleChange}
                      placeholder={dictionary.surname}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={12}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Erremail} className='pb-2'><span className='text-danger'>* </span>{dictionary.email}</Title2>
                    <Input
                      id='email'
                      type='text'
                      name='email'
                      autoComplete="billing email"
                      value={this.state.email || ''}
                      onChange={this.handleChange}
                      placeholder={dictionary.email}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col><Col xs={12} md={6} lg={12}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errphone} className='pb-2'><span className='text-danger'>* </span>{dictionary.phone}</Title2>
                    <Input
                      id='phone'
                      type='text'
                      name='phone'
                      autoComplete="billing phone"
                      maxLength="10"
                      onChange={this.handleChange}
                      placeholder={dictionary.phone}
                      value={this.state.phone || ''}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col>
              </Row>
              {verifyBusiness.certificate && readOnly
                ? <FormGroup>
                  <Title2 bold className='pb-2'><span className='text-danger'>* </span>{dictionary.attached_file}</Title2>
                  <InputBox>
                    <a target="_blank" href={verifyBusiness.certificate} rel="noopener noreferrer">
                      <Title2 link><i className="fas fa-paperclip pr-2" />{dictionary.attached_file}</Title2>
                    </a>
                  </InputBox>
                </FormGroup>
                : null
              }
            </Col>

            <Col xs={12} md={12} lg={12} xl={6}>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Erraddressline1} className='pb-2'><span className='text-danger'>* </span>{dictionary.business_address}</Title2>
                    <Input
                      id='address_line_1'
                      type='text'
                      name='addressline1'
                      autoComplete='billing address-line1'
                      onChange={this.handleChange}
                      value={this.state.addressline1 || ''}
                      placeholder={dictionary.address_line1}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col><Col>
                  <FormGroup>
                    <Input
                      id='address_line_2'
                      type='text'
                      name='addressline2'
                      autoComplete='billing address-line2'
                      onChange={this.handleChange}
                      value={this.state.addressline2 || ''}
                      placeholder={dictionary.address_line2}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errdistrict} className='pb-2'><span className='text-danger'>* </span>{dictionary.city}</Title2>
                    <Input
                      id='district'
                      type='text'
                      name='district'
                      autoComplete="billing locality"
                      onChange={this.handleChange}
                      value={this.state.district || ''}
                      placeholder={dictionary.city}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errprovince} className='pb-2'><span className='text-danger'>* </span>{dictionary.state}</Title2>
                    <Input
                      id='province'
                      type='text'
                      name='province'
                      autoComplete="billing region"
                      onChange={this.handleChange}
                      value={this.state.province || ''}
                      placeholder={dictionary.state}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errpostal_code} className='pb-2'>
                      <span className='text-danger'>* </span>{dictionary.postal_code}
                    </Title2>
                    <Input
                      id='postal_code'
                      type='text'
                      name='postal_code'
                      autoComplete="billing postal-code"
                      maxLength="5"
                      onChange={this.handleChange}
                      value={this.state.postal_code || ''}
                      placeholder={dictionary.postal_code}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Title2 bold danger={this.state.Errcountry} className='pb-2'>
                      <span className='text-danger'>* </span>{dictionary.country}
                    </Title2>
                    <Input
                      id='country'
                      type='text'
                      name='country'
                      autoComplete="billing country-name"
                      onChange={this.handleChange}
                      value={this.state.country || ''}
                      placeholder={dictionary.country}
                      disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Title2 bold className='pb-2'>
                      <span className='text-danger'>* </span>{dictionary.map_position}
                    </Title2>
                    <Title3 bold secondary className='pb-2'>{dictionary.map_description}</Title3>
                    <div className='d-flex align-items-center justify-content-center w-100'>
                      <GoogleMap
                        {...this.props}
                        location={{ lat: verifyBusiness.latitude, lng: verifyBusiness.longitude }}
                        zoom={12}
                        setPositionLatLng={(data) => { this.getDataGoogleMap(data) }}
                        disabled={verifyBusiness.status_info || this.props.isHomeLoading || readOnly}
                        style={{ width: '100%', height: '300px' }} />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </ScrollModal>
        <ModelDetailFooter>
          {
            verifyBusiness.status_info || readOnly
              ? !this.props.readOnly
                ? <InvertBtn md bold onClick={this.props.toggle}>{dictionary.close}</InvertBtn>
                : null
              : this.props.isHomeLoading
                ? <InvertBtn disabled><i className='fas fa-spinner fa-spin' /></InvertBtn>
                : <>
                  <InvertBtn md bold onClick={this.props.toggle}>{dictionary.cancel}</InvertBtn>
                  <SuccessBtn md bold
                    id={`btn-submit_verify_detail`}
                    onClick={this.submitVeriftBusiness}
                    disabled={this.verifySubmit() || this.props.isHomeLoading}>
                    {dictionary.submit_detail}
                  </SuccessBtn>
                </>
          }
        </ModelDetailFooter>
      </ModalBody>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BodyDetail)