String.Capitalize = function (input) {
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

String.format = function () {
  var s = arguments[0] || '';
  var o = arguments[1];
  if (typeof o !== 'object') {
    for (var i = 0; i < arguments.length - 1; i++) {
      let reg = new RegExp("\\{" + i + "\\}", "gm");
      s = s.replace(reg, arguments[i + 1]);
    }
  } else {
    for (const key in o) {
      if (o.hasOwnProperty(key)) {
        let reg = new RegExp("\\{" + key + "\\}", "gm");
        s = s.replace(reg, o[key]);
      }
    }
  }
  return s;
}