import axios from "./../axios_request";
import _ from "lodash";

import { DEBUG, ERROR } from "../../libs/utils";
import { managerAPI } from "../end_point";
import { MANAGER } from "../actions_const";
import { toCurrency } from "../../libs/currency";
import { getCookie } from "../../libs/cookies";

const cookie_name_token = "login_user";

const getLogDetails = (action, getState, newData) => {
  const storeManager = getState().manager;
  const storeBusiness = getState().business.verifyBusiness;
  const details = {
    business_code: _.get(storeBusiness, ["business_code"]),
  };
  switch (action) {
    case MANAGER.SET_TRIAL_PACKAGE: {
      details.action = "Set Trial Package";
      details.old_value = _.get(storeManager, ["packageInfo", "package_id"]);
      details.new_value = `${newData.package_id} (${newData.days} Days)`;
      break;
    }
    case MANAGER.CANCEL_TRIAL_PACKAGE: {
      details.action = "Cancel Trial Package";
      details.old_value = _.get(storeManager, ["packageInfo", "package_id"]);
      break;
    }
    case MANAGER.CREATE_SUBSCRIPTION: {
      details.action = "Create Subscription";
      details.old_value = _.get(storeManager, ["packageInfo", "package_id"])
        ? `${_.get(storeManager, ["packageInfo", "package_id"])} (${_.get(storeManager, ["isPrepaid"]) ? "Prepaid" : "Postpaid"})`
        : null;
      details.new_value = `${newData.package_id} (${newData.prepaid ? "Prepaid" : "Postpaid"})`;
      break;
    }
    case MANAGER.SET_PREPAID: {
      details.action = "Set Prepaid Status";
      details.old_value = _.get(storeManager, ["isPrepaid"]) ? "Prepaid" : "Postpaid";
      details.new_value = newData.prepaid ? "Prepaid" : "Postpaid";
      break;
    }
    case MANAGER.SET_CREDIT_BALANCE: {
      details.action = "Set Credit Balance";
      details.new_value = `Amount: ${toCurrency(newData.amount, 2)} THB / Quotation ID: ${newData.quotation_id}`;
      break;
    }
    case MANAGER.SET_PREMIUM_ID: {
      details.action = "Set Premium ID";
      details.old_value = _.get(storeManager, ["premiumId"]);
      details.new_value = newData.premium_id;
      break;
    }
    case MANAGER.SET_SENDER_NAME: {
      details.action = "Set Sender Name";
      details.old_value = _.get(storeManager, ["sender", "name"]);
      details.new_value = newData.sender_name;
      break;
    }
    case MANAGER.RESET_EXPIRY_POINT: {
      details.action = "Reset Expiry Point";
      details.old_value = `${_.get(storeManager, ["expiryPoint", "type_expiry_point"])} (${
        _.get(storeManager, ["expiryPoint", "value_expiry_point"]) || _.get(storeManager, ["expiryPoint", "date_expiry_point"])
      })`;
      break;
    }
    case MANAGER.UPDATE_BUSINESS_NAME: {
      details.action = "Update Business Name";
      details.old_value = _.get(storeBusiness, ["business_name"]);
      details.new_value = newData.business_name;
      break;
    }
    case MANAGER.CHARGE_INVOICE: {
      details.action = "Charge Customer Invoice";
      details.new_value = `Invoice ID: ${newData.invoice_id} Card ID: ${newData.card_id}`;
      break;
    }
    default: {
      details.action = action;
    }
  }
  return details;
};

export const getBusinessPackage = (business_code) => {
  return (dispatch, getState) => {
    const store_manager = getState().manager;

    dispatch({
      type: MANAGER.LOADING_API,
      data: {
        key: "isLoadingPackageInfo",
        value: true,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/getPackage`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        business_code,
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/getBusinessPackage", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.LOADING_API,
            data: {
              key: "isLoadingPackageInfo",
              value: false,
            },
          });
          return dispatch({
            type: MANAGER.GET_BUSINESS_PACKAGE,
            data: data.data,
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/getBusinessPackage", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการดึงข้อมูลแพคเกจ",
          },
        });
      });
  };
};

export const setTrialPackage = ({ business_code, email, package_id, days }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/setTrialPackage`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        email,
        package_id,
        days,
        log: getLogDetails(MANAGER.SET_TRIAL_PACKAGE, getState, {
          package_id,
          days,
        }),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/setTrialPackage", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "ตั้งค่าการทดลองใช้งานสำเร็จ",
            },
          });
          return dispatch({ type: MANAGER.SET_TRIAL_PACKAGE, data: data.data });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/setTrialPackage", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการตั้งค่าการทดลองใช้งาน",
          },
        });
      });
  };
};

export const createSubscription = ({ business_info, package_id, prepaid }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/createSubscription`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_info,
        package_id,
        prepaid,
        log: getLogDetails(MANAGER.CREATE_SUBSCRIPTION, getState, {
          package_id,
          prepaid,
        }),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/createSubscription", prepaid, business_info, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "ปรับสถานะสำเร็จ",
            },
          });
          dispatch({ type: MANAGER.SET_PREPAID, data: { prepaid } });
          return dispatch({
            type: MANAGER.CREATE_SUBSCRIPTION,
            data: data.data,
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/createSubscription", business_info.business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการปรับสถานะ",
          },
        });
      });
  };
};

export const cancelPackage = ({ business_code }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "delete",
      url: `${managerAPI}/cancelTrialPackage`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        log: getLogDetails(MANAGER.CANCEL_TRIAL_PACKAGE, getState),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/cancelPackage", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "ยกเลิกแพคเกจสำเร็จ",
            },
          });
          return dispatch({
            type: MANAGER.CANCEL_TRIAL_PACKAGE,
            data: data.data,
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/cancelPackage", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการยกเลิกแพคเกจ",
          },
        });
      });
  };
};

export const updateTrialPackage = ({ business_code, email, package_id, days }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "delete",
      url: `${managerAPI}/cancelTrialPackage`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        log: getLogDetails(MANAGER.CANCEL_TRIAL_PACKAGE, getState),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/updateTrialPackage cancel", business_code, data);
        if (data.result) {
          axios({
            method: "post",
            url: `${managerAPI}/setTrialPackage`,
            headers: { Authorization: getCookie(cookie_name_token) },
            data: {
              business_code,
              email,
              package_id,
              days,
              log: getLogDetails(MANAGER.SET_TRIAL_PACKAGE, getState, {
                package_id,
                days,
              }),
            },
          })
            .then(({ data }) => {
              DEBUG("THEN manager/updateTrialPackage set", business_code, data);
              if (data.result) {
                dispatch({
                  type: MANAGER.SET_PROCESSING_STATUS,
                  data: {
                    status: MANAGER.PROCESS_STATUS_SUCCEED,
                    message: "ตั้งค่าการทดลองใช้งานสำเร็จ",
                  },
                });
                return dispatch({
                  type: MANAGER.SET_TRIAL_PACKAGE,
                  data: data.data,
                });
              } else {
                throw data.error;
              }
            })
            .catch((err) => {
              ERROR("CATCH manager/updateTrialPackage set", business_code, err.message);
              throw data.error;
            });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/updateTrialPackage cancel", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการตั้งค่าการทดลองใช้งาน",
          },
        });
      });
  };
};

export const getPaymentData = (customer_token_id, business_code) => {
  return (dispatch, getState) => {
    const store_manager = getState().manager;

    dispatch({
      type: MANAGER.LOADING_API,
      data: {
        key: "isLoadingInvoiceInfo",
        value: true,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/getPaymentData`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        customer_token_id,
        business_code,
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/getPaymentData", customer_token_id, business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.LOADING_API,
            data: {
              key: "isLoadingInvoiceInfo",
              value: false,
            },
          });
          return dispatch({ type: MANAGER.GET_PAYMENT_DATA, data: data.data });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/getPaymentData", customer_token_id, business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการดึงข้อมูลใบแจ้งหนี้",
          },
        });
      });
  };
};

export const getPrepaidData = (customer_token_id) => {
  return (dispatch, getState) => {
    const store_manager = getState().manager;

    dispatch({
      type: MANAGER.LOADING_API,
      data: {
        key: "isLoadingCreditInfo",
        value: true,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/getPrepaidData`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        customer_token_id,
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/getPrepaidData", customer_token_id, data);
        if (data.result) {
          dispatch({
            type: MANAGER.LOADING_API,
            data: {
              key: "isLoadingCreditInfo",
              value: false,
            },
          });
          return dispatch({ type: MANAGER.GET_PREPAID_DATA, data: data.data });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/getPrepaidData", customer_token_id, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการดึงข้อมูลสถานะบัญชี",
          },
        });
      });
  };
};

export const setPrepaidStatus = ({ subscription_id, customer_token_id, prepaid }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/setPrepaid`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        customer_token_id,
        subscription_id,
        prepaid,
        log: getLogDetails(MANAGER.SET_PREPAID, getState, {
          prepaid,
        }),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/setPrepaidStatus", customer_token_id, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "ปรับสถานะสำเร็จ",
            },
          });
          return dispatch({
            type: MANAGER.SET_PREPAID,
            data: { isPrepaid: prepaid },
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/setPrepaidStatus", customer_token_id, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการปรับสถานะ",
          },
        });
      });
  };
};

export const setCreditBalance = ({ customer_token_id, amount, quotation_id }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/setCreditBalance`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        customer_token_id,
        amount,
        quotation_id,
        log: getLogDetails(MANAGER.SET_CREDIT_BALANCE, getState, {
          amount,
          quotation_id,
        }),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/setCreditBalance", customer_token_id, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "เติมเงินสำเร็จ",
            },
          });
          return dispatch({
            type: MANAGER.SET_CREDIT_BALANCE,
            data: data.data,
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/setCreditBalance", customer_token_id, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการเติมเงิน",
          },
        });
      });
  };
};

export const getMoreCreditBalanceHistory = (customer_token_id, offset) => {
  return (dispatch, getState) => {
    const store_manager = getState().manager;

    axios({
      method: "post",
      url: `${managerAPI}/getMoreCreditBalanceTransactions`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        customer_token_id,
        offset,
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/getMoreCreditBalanceHistory", customer_token_id, data);
        return dispatch({
          type: MANAGER.GET_CREDIT_BALANCE_HISTORY,
          data: data.data,
        });
      })
      .catch((err) => {
        ERROR("CATCH manager/getMoreCreditBalanceHistory", customer_token_id, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการดึงข้อมูล Credit",
          },
        });
      });
  };
};

export const getSettings = (business_code) => {
  return (dispatch, getState) => {
    const store_manager = getState().manager;

    dispatch({
      type: MANAGER.LOADING_API,
      data: {
        key: "isLoadingSettings",
        value: true,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/getSettings`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        business_code,
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/getSettings", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.LOADING_API,
            data: {
              key: "isLoadingSettings",
              value: false,
            },
          });
          return dispatch({ type: MANAGER.GET_SETTINGS, data: data.data });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/getSettings", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: err.message || "เกิดข้อผิดพลาดในการดึงข้อมูลการตั้งค่า",
          },
        });
      });
  };
};

export const setPremiumId = ({ business_code, premium_id }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/setPremiumId`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        premium_id,
        log: getLogDetails(MANAGER.SET_PREMIUM_ID, getState, {
          premium_id,
        }),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/setPremiumId", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "บันทึก Premium ID สำเร็จ",
            },
          });
          return dispatch({
            type: MANAGER.SET_PREMIUM_ID,
            data: {
              premium_id,
            },
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/setPremiumId", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการบันทึก Premium ID",
          },
        });
      });
  };
};

export const setSenderName = ({ business_code, sender_name }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/setSenderName`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        sender_name,
        log: getLogDetails(MANAGER.SET_SENDER_NAME, getState, {
          sender_name,
        }),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/setSenderName", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "บันทึก SMS Sender Name สำเร็จ",
            },
          });
          dispatch(getSettings(business_code));
          return dispatch({
            type: MANAGER.SET_SENDER_NAME,
            data: {
              sender_name,
            },
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/setSenderName", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: err.message || "เกิดข้อผิดพลาดในการบันทึก SMS Sender Name",
          },
        });
      });
  };
};

export const resetExpiryPoint = ({ business_code }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/resetExpiryPoint`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        log: getLogDetails(MANAGER.RESET_EXPIRY_POINT, getState),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/resetExpiryPoint", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "รีเซ็ต Point expiry date สำเร็จ",
            },
          });
          return dispatch({
            type: MANAGER.RESET_EXPIRY_POINT,
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/resetExpiryPoint", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการรีเซ็ต Point expiry date",
          },
        });
      });
  };
};

export const updateBusinessName = ({ business_code, business_name }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/updateName`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        business_name,
        log: getLogDetails(MANAGER.UPDATE_BUSINESS_NAME, getState, {
          business_name,
        }),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/updateBusinessName", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "บันทึกชื่อธุรกิจสำเร็จ",
            },
          });
          return dispatch({
            type: MANAGER.UPDATE_BUSINESS_NAME,
            data: {
              business_name,
            },
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/updateBusinessName", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการบันทึกชื่อธุรกิจ",
          },
        });
      });
  };
};

export const chargeInvoice = ({ customer_token_id, invoice_id, card_id, business_code }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/chargeInvoice`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        customer_token_id,
        invoice_id,
        card_id,
        log: getLogDetails(MANAGER.CHARGE_INVOICE, getState, { invoice_id, card_id }),
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/chargeInvoice", invoice_id, data);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "ชำระเงินสำเร็จ",
            },
          });
          if (!card_id) {
            dispatch(getPrepaidData(customer_token_id));
          }

          return dispatch(getPaymentData(customer_token_id, business_code));
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/chargeInvoice", invoice_id, err.message);
        dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการชำระเงิน",
          },
        });
        return dispatch(getPaymentData(customer_token_id, business_code));
      });
  };
};

export const resetProcessingStatus = () => {
  return (dispatch) => {
    return dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: "",
        message: "",
      },
    });
  };
};

export const getCouponInfo = (business_code) => {
  return (dispatch, getState) => {
    const store_manager = getState().manager;

    dispatch({
      type: MANAGER.LOADING_API,
      data: {
        key: "isLoadingSettings",
        value: true,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/getSubscribedCoupon`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        business_code,
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/getSettings", business_code, data);
        if (data.result) {
          dispatch({
            type: MANAGER.LOADING_API,
            data: {
              key: "isLoadingSettings",
              value: false,
            },
          });
          return dispatch({ type: MANAGER.GET_COUPON_INFO, data: data.data });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/getSettings", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการดึงข้อมูลการตั้งค่า",
          },
        });
      });
  };
};

export const updateBusinessCoupon = ({ business_code, expireDate, couponId }) => {
  return (dispatch, getState) => {
    const store_manager = getState().manager;

    dispatch({
      type: MANAGER.SET_PROCESSING_STATUS,
      data: {
        status: MANAGER.PROCESS_STATUS_WORKING,
      },
    });
    axios({
      method: "post",
      url: `${managerAPI}/updateBusinessCoupon`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        coupon_id: couponId,
        expiration_date: expireDate,
        business_code,
        idToken: store_manager.userData.idToken || null,
        clientId: store_manager.userData.clientId || null,
      },
    })
      .then(({ data }) => {
        DEBUG("THEN manager/updateBusinessCoupon", business_code, expireDate, couponId);
        if (data.result) {
          dispatch({
            type: MANAGER.SET_PROCESSING_STATUS,
            data: {
              status: MANAGER.PROCESS_STATUS_SUCCEED,
              message: "ตั้งค่าคูปองสำเร็จ",
            },
          });
          return dispatch({ type: MANAGER.GET_COUPON_INFO, data: data.data });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        ERROR("CATCH manager/setTrialPackage", business_code, err.message);
        return dispatch({
          type: MANAGER.SET_PROCESSING_STATUS,
          data: {
            status: MANAGER.PROCESS_STATUS_ERROR,
            message: "เกิดข้อผิดพลาดในการตั้งค่าการทดลองใช้งาน",
          },
        });
      });
  };
};

export const setUserData = ({ idToken, clientId }) => {
  return (dispatch) => {
    return dispatch({
      type: MANAGER.SET_USER_DATA,
      data: {
        idToken: idToken,
        clientId: clientId,
      },
    });
  };
};

// User Management

export const searchMemberProfile = ({ keyword, type }, callback) => {
  return (dispatch, getState) => {
    axios({
      method: "post",
      url: `${managerAPI}/getMemberProfile`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        keyword,
      },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data);
          return dispatch({
            type: MANAGER.SET_MEMBER_PROFILE,
            data: {
              type,
              profile: data.data,
            },
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        if (typeof callback === "function") callback(err);
      });
  };
};

export const archiveMemberProfile = (member_badge_code, callback) => {
  return (dispatch, getState) => {
    axios({
      method: "post",
      url: `${managerAPI}/archiveMemberProfile`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        member_badge_code,
      },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") callback(null, data);
          return dispatch({
            type: MANAGER.CLEAR_MEMBER_PROFILE,
            data: {
              type: "all",
            },
          });
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        if (typeof callback === "function") return callback(err);
      });
  };
};

export const unsubscribeBusiness = ({ business_code, member_badge_code }, callback) => {
  return (dispatch, getState) => {
    axios({
      method: "post",
      url: `${managerAPI}/unsubscribeBusiness`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        member_badge_code,
      },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") return callback(null, data);
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        if (typeof callback === "function") return callback(err);
      });
  };
};

export const updateNotificationBusiness = ({ business_code, member_badge_code, block_noti_business }, callback) => {
  return (dispatch, getState) => {
    axios({
      method: "post",
      url: `${managerAPI}/updateNotification`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        business_code,
        member_badge_code,
        block_noti_business,
      },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") return callback(null, data);
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        if (typeof callback === "function") return callback(err);
      });
  };
};

export const changePhoneNumber = ({ current_member_badge_code, new_member_badge_code, new_mobile_number }, callback) => {
  return (dispatch, getState) => {
    axios({
      method: "post",
      url: `${managerAPI}/changePhoneNumber`,
      headers: { Authorization: getCookie(cookie_name_token) },
      data: {
        idToken: getState().manager.userData.idToken,
        clientId: getState().manager.userData.clientId,
        current_member_badge_code,
        new_member_badge_code,
        new_mobile_number,
      },
    })
      .then(({ data }) => {
        if (data.result) {
          if (typeof callback === "function") return callback(null, data);
        } else {
          throw data.error;
        }
      })
      .catch((err) => {
        if (typeof callback === "function") return callback(err);
      });
  };
};

export const clearMemberProfile = () => {
  return (dispatch) => {
    dispatch({
      type: MANAGER.CLEAR_MEMBER_PROFILE,
      data: {
        type: "new",
      },
    });
  };
};
