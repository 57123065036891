import React, { Component } from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Input, Modal } from 'reactstrap'
import { Title2, Title3 } from '../../components/Font'
import { PrimaryBtn } from '../../components/Button'
import { toCurrency } from '../../libs/currency'
import TagManager from '../../libs/TagManager'
import { sumDeciNatural } from '../../libs/sumDeciNatural'

import { getBusinessPackage } from '../../redux/actions/action_package'
import { getProductCategory, getProducts } from '../../redux/actions/action_product'

import { DropdownProduct, ProductCol } from './SendPoint.style'
import ProductList from './ProductList'
import LoadingGradient from '../../components/LoadingGradient'

class ProductPoints extends Component {
	_isMounted = false
	constructor(props) {
		super(props)
		this.state = {
			productList: [],
			categoryList: [],
			productSelect: [],
			temp: [],
			search: '',
			isDropDown: false,
			isLoading: false,
			isExternalLoading: false,
			isMobile: false,
			limit: props.limit || ''
		}
	}

	componentDidMount() {
		let { store_business: { current: { business_code } }, excludeArchive } = this.props

		window.addEventListener('resize', this.setIsMobile)

		this.setState({
			isLoading: true,
			isExternalLoading: Boolean(this.props.product_list && this.props.product_list.length)
		})
		this.props.getProductCategory({ business_code }, (err, category) => {
			this.props.getProducts({ business_code, excludeArchive: excludeArchive === false ? '' : true }, (err, product) => {
				if (this._isMounted)
					return null

				this.setState({
					isLoading: false,
					productList: (product && product.length > 0)
						? product.sort((a, b) => a.product_name.localeCompare(b.product_name))
						: [],
					categoryList: category || [],
					isExternalLoading: false
				})
			})
		})
	}

	componentWillUnmount() {
		this._isMounted = true
		window.removeEventListener('resize', this.setIsMobile)
	}

	componentDidUpdate(prevProps, prevStates) {
		if (prevProps.product_list !== this.props.product_list && !this.props.product_list.length) {
			this.setState({ productSelect: [] })
		}

		if (
			this.props.targetGroups &&
			this.props.product_list &&
			this.props.product_list.length &&
			JSON.stringify(prevStates.productList) !==
				JSON.stringify(this.state.productList)
		) {
			if (this.state.productList && this.state.productList.length) {
				const product_list = this.props.product_list.map((p) => {
					const findIndexProduct = this.state.productList.findIndex(
						(fi) => fi.product_id === p
					);
					return this.state.productList[findIndexProduct];
				});
				this.setState({
					productSelect: product_list,
					temp: product_list
				});
			}
		}
	}

	setIsMobile = () => {
		this.setState({
			isMobile: window.innerWidth < 768
		})
	}

	clickToScroll = () => {
		const element = document.getElementById('temp_bar')
		let isDown = false, startX, scrollLeft

		if (!element) return null

		element.addEventListener('mouseenter', () => {
			if (element.scrollWidth > element.clientWidth)
				element.style.cursor = "grab"
		})
		element.addEventListener('mousedown', (e) => {
			isDown = true
			startX = e.pageX - element.offsetLeft
			scrollLeft = element.scrollLeft
		})
		element.addEventListener('mouseup', () => { isDown = false })
		element.addEventListener('mousemove', (e) => {
			if (!isDown) return
			e.preventDefault()
			const x = e.pageX - element.offsetLeft
			const walk = (x - startX) * 3 //scroll-fast
			element.scrollLeft = scrollLeft - walk
		})
	}

	toggleDropDownProduct = () => {
		this.setState({ isDropDown: !this.state.isDropDown }, () => {
			if (this.state.isDropDown) {
				TagManager.event(`btn-product_send`)
				this.setState({ temp: this.state.productSelect })
			} else
				this.setState({ temp: [], search: '', })
		})
	}

	clearSearch = () => {
		TagManager.event(`btn-clear_search_product`)
		this.setState({ search: '' })
	}

	onChangeSearch = (event) => {
		let { name, value } = event.target
		this.setState({ [name]: value })
	}

	keyPressSearch = (e) => {
		let { productList, search } = this.state
		let productWithSearch = (productList && productList.length > 0 && search.trim())
			&& productList.filter(f => {
				return f.product_name.toLowerCase().indexOf(search.trim().toLowerCase()) > -1
					|| f.product_code.toLowerCase().indexOf(search.trim().toLowerCase()) > -1
			})

		if (e.target.value.length > 0 && e.key === 'Enter' && productWithSearch.length === 1) {
			this.setState({ search: '' })
			this.selectTemp(productWithSearch[0])
		}
	}

	onChangeAmount = (event) => {
		let { id, value } = event.target
		let { store_business: { current: { points_conversion, exchange_by_product } } } = this.props
		let fnByPoint = exchange_by_product ? (i) => Math.floor(sumDeciNatural(i)) : (i) => sumDeciNatural(i)
		let split = value.toString().split('.')

		if (value && (!parseFloat(value) || parseFloat(value) <= 0 || isNaN(Number(value))))
			return null

		if (value && split && split.length > 1 && split[1].length > 2)
			return null

		this.setState({
			productSelect: this.state.productSelect.map(v => Object.assign({}, v, {
				amount: v.product_id === parseInt(id) ? value : (v.amount || ''),
				points: v.product_id === parseInt(id)
					? sumDeciNatural(
						fnByPoint(Math.floor(parseFloat(value)) / parseInt(v.points_ratio ? v.points_ratio[0] : points_conversion[0])) * parseInt(v.points_ratio ? v.points_ratio[1] : points_conversion[1])
					)
					: (v.points || '')
			}))
		}, () => this.callBackProduct())
	}

	selectTemp = (value) => {
		let exist = this.state.temp.filter(f => f.product_id === value.product_id)
		this.setState({ temp: (exist.length > 0) ? this.state.temp : [...this.state.temp, value] }, () => {
			if (this.state.temp.length > 0)
				this.clickToScroll()
		})
	}

	removeTempItem = (value) => {
		this.setState({ temp: this.state.temp.filter(f => f.product_id !== value.product_id) || [] })
	}

	selectProduct = () => {
		this.setState({ productSelect: this.state.temp || [] }, () => {
			this.toggleDropDownProduct()
			this.callBackProduct()
		})
	}

	removeProductSelected = (id) => {
		this.setState({
			productSelect: this.state.productSelect.filter(f => f.product_id !== id) || []
		}, () => {
			this.callBackProduct()
		})
	}

	callBackProduct = () => {
		if (this.props.callBack)
			return this.props.callBack(this.state.productSelect.map(v => Object.assign({}, v, {
				amount: parseFloat(v.amount) ? Number(parseFloat(v.amount).toFixed(2)) : '',
				points: parseFloat(v.points) ? Number(parseFloat(v.points).toFixed(2)) : ''
			})))
	}

	render() {
		let { store_language: { dictionary }, disabled, targetGroups, removeDisabled, hiddenButton} = this.props
		let { productList, categoryList, isDropDown, isLoading, search, temp, productSelect } = this.state

		let ComponentProductList = (this.state.isMobile || window.innerWidth < 768) ? Modal : DropdownProduct
		let ComponentProductListProps = (this.state.isMobile || window.innerWidth < 768) ? { isOpen: isDropDown, centered: true } : { hidden: !isDropDown, fullSize: this.props.fullSize }

		let productListWithSearch = (productList.length > 0 && search.trim())
			? productList.filter(f => {
				return f.product_name.toLowerCase().indexOf(search.trim().toLowerCase()) > -1
					|| f.product_code.toLowerCase().indexOf(search.trim().toLowerCase()) > -1
			})
			: productList

		if (this.props.isExternalLoading || this.state.isExternalLoading) {
			return <LoadingGradient type={'square'} width={'15px'} className='w-100' />
		}

		return <div className='w-100'>
			{
				(productSelect && productSelect.length > 0)
				? <div>
					{
						(!targetGroups) &&
						<div className='w-100 d-flex align-items-center'>
							<ProductCol bold>{dictionary.product_list}</ProductCol>
							<ProductCol bold align='center'>{`${dictionary.amount} (${dictionary.bath})`}</ProductCol>
							<ProductCol bold align='center'>{dictionary.points}</ProductCol>
							<ProductCol />
						</div>
					}
					{
						productSelect.filter((item) => item).map((product) => {
							return <div key={product.product_id} className='w-100 pt-1'>
								<div className='w-100 d-flex align-items-center'>
									<ProductCol bold style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
										{product.product_name.length > 40 ?
											<abbr title={product.product_name}>
												{product.product_name}
											</abbr>
											: product.product_name
										}
									</ProductCol>
									{ !targetGroups && 
									<ProductCol>
										<Input
											type='number'
											placeholder='0'
											id={product.product_id}
											className='w-100 text-right type-number'
											value={product.amount || ''}
											disabled={disabled}
											autoComplete='off'
											name={`${product.product_id}_${product.product_name}`}
											onChange={this.onChangeAmount} />
									</ProductCol>}
									{
										!targetGroups && 
										<ProductCol>
											<Input
												readOnly
												type='tel'
												placeholder='-'
												id={`points_${product.product_id}`}
												className='w-100 text-right'
												value={parseFloat(product.points) ? toCurrency(Number(parseFloat(product.points).toFixed(2)), 2) : ''}
												disabled={disabled}
												autoComplete='off'
												name={`${product.product_id}_${product.product_name}`}
											/>
										</ProductCol>
									}
									{
										!hiddenButton &&
										<ProductCol disabled={removeDisabled} bold align='center' onClick={!removeDisabled ? () => this.removeProductSelected(product.product_id) : null}>
											<i className="fas fa-trash-alt fa-lg pointer" />
										</ProductCol>
									}
								</div>
								<Title3 secondary>{`${dictionary.sku_number} : ${product.product_code}`}</Title3>
								{
									targetGroups
									? <CategoryTargetGroup category={categoryList} product={product} />
									: <Title3 secondary className='pt-1'>{`${dictionary.points_ratio} ${product.points_ratio ? `${product.points_ratio[0]} : ${product.points_ratio[1]}` : dictionary.standard_value}`}</Title3>
								}
							</div>
						})
					}
					</div>
				: disabled && <Title2 secondary className='pt-1'>{dictionary.no_product}</Title2>
			}
			<div className='mt-2 position-relative'>
				{
					!hiddenButton &&
					<PrimaryBtn sm bold style={{ fontSize: '10px' }} disabled={disabled} onClick={this.toggleDropDownProduct}>
						<div><i className="fas fa-plus pr-1" />{dictionary.select_product}</div>
					</PrimaryBtn>
				}
				{
					isDropDown &&
					<ComponentProductList { ...ComponentProductListProps } >
						<ProductList 
							productList={productList}
							productListWithSearch={productListWithSearch}
							categoryList={categoryList}
							temp={temp}
							search={search}
							disabled={disabled}
							onChangeSearch={this.onChangeSearch}
							toggleDropDownProduct={this.toggleDropDownProduct}
							keyPressSearch={this.keyPressSearch}
							clearSearch={this.clearSearch}
							removeTempItem={this.removeTempItem}
							selectTemp={this.selectTemp}
							selectProduct={this.selectProduct}
							isDropDown={isDropDown}
							limit={this.state.limit}
							isLoading={isLoading}
						/>
					</ComponentProductList>
				}
			</div>
		</div>
	}
}

const CategoryTargetGroup = ({ category, product }) => {
	const dictionary = useSelector((state) => state.language.dictionary)
	const cat = category.length > 0 && category.filter(f => f.id === product.category[0])

	return (
		<Title3 secondary className='pt-1'>{`${dictionary.category} : ${cat.length > 0 ? cat[0].category_name : ''}`}</Title3>
	)
}

const mapStateToProps = (state) => {
	return {
		store_business: state.business,
		store_language: state.language,
		store_packages: state.packages,
		store_products: state.products,
	}
}

const mapDispatchToProps = (dispatch, ownProps) => {
	return bindActionCreators({
		getBusinessPackage,
		getProducts,
		getProductCategory,
	}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPoints)