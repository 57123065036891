import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import _ from "lodash";

import { Title3 } from "../../components/Font";
import { jsonToCsv } from "../../libs/csv";
import ModalTypeImport from "./ModalTypeImport";
import { getMemberCardExampleCsvData, getPointExampleCsvData } from "./ImportColumn";


export default function CronjobsCreateContainer({ type = "point", customRoute }) {
	const dictionary = useSelector((state) => state.language.dictionary);
	const checkpack = useSelector((state) => state.packages.packages.package_data.feature);
	const store_packages = useSelector((state) => state.packages);
	const subscriptionItems = _.get(store_packages,'packages.package_data.items_id', null)
	const business = useSelector((state) => state.business.current); 
	const { form_setting } = useSelector((state) => state.custom_form);
	const [toggle, setToggle] = useState(false);

	const getPointExampleCSV = (type) => {
		if (customRoute === "/jobsMemberCard") {
			let fileName = 'example_import_initial_' + type + '.csv';
			let customForm = business && business.custom_form ? form_setting : [];
			jsonToCsv(getPointExampleCsvData(type, customRoute, customForm), fileName);
		} else {
			let fileName = 'example_import_' + type + '.csv';
			jsonToCsv(getPointExampleCsvData(type), fileName);
		}
		toggleModal();
	};

	const getMemberCardExampleCSV = () => {
		let fileName = 'example_import_member.csv';
		let customForm = business && business.custom_form ? form_setting : [];
		jsonToCsv(getMemberCardExampleCsvData(customForm), fileName);
	};

	const toggleModal = () => {
		setToggle(!toggle);
	};

	return type === "point" ? (
		<>
			<Button className="btn-secondary" onClick={toggleModal}>
				<Title3 white bold>{`${dictionary.download}`}</Title3>
			</Button>
			<ModalTypeImport
				subscription={checkpack}
				subscriptionItems={subscriptionItems}
				fileExam
				title={dictionary.choose_file_ex}
				dictionary={dictionary}
				isOpen={toggle}
				toggle={toggleModal}
				onClick={(event, type) => getPointExampleCSV(type)}
			/>
		</>
	) : (
		<Button className="btn-secondary" onClick={getMemberCardExampleCSV}>
			<Title3 white bold>{`${dictionary.download}`}</Title3>
		</Button>
	);
}
