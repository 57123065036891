import {
  CLEAR_REPORT_ADMIN,
  CLEAR_REPORT_BIRTHDAY,
  CLEAR_REPORT_COUPONS,
  CLEAR_REPORT_GET_FRIENDS,
  CLEAR_REPORT_POINTS_FLOW,
  CLEAR_REPORT_REWARDS,
  CLEAR_REPORT_TRANSACTION_MEMBERCARD,
  CLEAR_TRANSACTION_ADMIN,
  CLEAR_TRANSACTION_POINT,
  CLEAR_REPORT_DEMOGRAPHIC,
  GET_REPORT_ADMIN,
  GET_REPORT_ADMIN_USAGE,
  GET_REPORT_ADMIN_USAGE_MORE,
  GET_REPORT_BIRTHDAY,
  GET_REPORT_BIRTHDAY_MORE,
  GET_REPORT_COUPONS,
  GET_REPORT_COUPONS_MORE,
  GET_REPORT_FREQUENCY,
  GET_REPORT_FREQUENCY_LOADING,
  GET_REPORT_GENDER,
  GET_REPORT_GET_FRIENDS,
  GET_REPORT_LOADING,
  GET_REPORT_POINT,
  GET_REPORT_POINTS_FLOW,
  GET_REPORT_POINT_MORE,
  GET_REPORT_PRODUCT_DETAIL,
  GET_REPORT_PRODUCT_SUMMARY,
  GET_REPORT_RECENCY,
  GET_REPORT_RECENCY_LOADING,
  GET_REPORT_REWARDS,
  GET_REPORT_REWARDS_MORE,
  GET_REPORT_RFM_ANALYSIS,
  GET_REPORT_TRANSACTION_SENT,
  GET_REPORT_TRANSACTION_SENT_MORE,
  GET_REPORT_TRANSACTION_ACTIVATED,
  GET_REPORT_TRANSACTION_ACTIVATED_MORE,
  GET_REPORT_TRANSACTION_MEMBERCARD,
  GET_REPORT_TRANSACTION_MEMBERCARD_MORE,
  SET_EXPORT_POINT_ALL_TRANS_CANCEL_SOURCE,
  SET_REPORT_TRANS_ADMIN,
  STOP_LOADING,
  GET_REPORT_DASHBOARD,
  CLEAR_REPORT_DASHBOARD,
  GET_REPORT_DASHBOARD_REVIEW,
  GET_TRANSACTION_SUMMARY_REPORT,
  IS_LOADING_GET_TRANSACTION_SUMMARY,
  IS_LOADING_GET_DATA_TRANSACTION_CHART,
  GET_DATA_TRANSACTION_CHART
} from "../actions_const";

let initState = {
  isLoading: false,
  isLoadingFrequency: false,
  isLoadingRecency: false,
  isLoadingAdminUsage: false,
  dataReportGenderMale: {
    loading: true,
    total: 0,
    data: [],
  },
  dataReportGenderFemale: {
    loading: true,
    total: 0,
    data: [],
  },
  dataReportGenderUnspecified: {
    loading: true,
    total: 0,
    data: [],
  },
  dataReportBirthday: {
    transactionList: [],
    limit: 50,
    offset: 0,
    loadmore: false,
  },
  dataReportPoint: {
    transactionList: [],
    limit: 50,
    offset: 0,
    loadmore: false,
  },
  dataReportFrequency: [],
  dataReportRecency: [],
  transactionAdmin: {
    transactionPage: 0,
    transactionLimit: 50,
    transactionOffset: 0,
    transactionList: [],
    loadmore: false,
  },
  dataReportPointsFlow: {
    pointsFlowlist: [],
    maxPoints: 0,
    total_earned: 0,
    total_spent: 0,
    data_suggest: {
      total_earned_inThirtyDay: 0,
      total_spent_inThirtyDay: 0,
      frequent_earned_inThirtyDay: 0,
      frequent_spent_inThirtyDay: 0,
    },
  },
  dataReportRewards: {
    list: [],
    offset: 0,
    limit: 50,
    loadmore: false,
  },
  dataReportCoupons: {
    list: [],
    offset: 0,
    limit: 50,
    loadmore: false,
  },
  transActvated: {
    list: [],
    offset: 0,
    limit: 20,
    loadmore: false,
    total: 0,
  },
  transSent: {
    list: [],
    offset: 0,
    limit: 20,
    loadmore: false,
    total: 0,
  },
  RFMAnalysis: [],
  transMembercard: {
    list: [],
    offset: 0,
    limit: 20,
    loadmore: false,
  },
  dataReportAdmin: {
    list: [],
    offset: 0,
    limit: 50,
  },
  dataReportProduct: {
    summary: {
      list: [],
      offset: 0,
      limit: 50,
    },
    summaryMember: {
      list: [],
      offset: 0,
      limit: 50,
    },
    detail: {
      list: [],
      offset: 0,
      limit: 50,
    },
    detailMember: {
      list: [],
      offset: 0,
      limit: 50,
    },
  },
  dataReportFriends: {
    list: [],
    offset: 0,
    limit: 50,
  },
  cancelExportPointAllTransSource: false,
  dataReportDashboard: {
    data: {}
  },
  dataReportDashboardReview: {
    loadmore: true,
    limit: 12,
    offset: 0,
    total: {},
    reviews_rate: [],
    reviews_message: []
  },
  isLoadingTransactionSummary: false,
  transactionSummaryReport: {},
  isLoadingDataTransactionChart: false,
  dataTransactionChart: {},
  dateRangeType: "day",
};

const ReducerReport = (state = initState, action) => {
  switch (action.type) {
    case GET_REPORT_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      });

    case GET_REPORT_GENDER:
      let dataReportGenderFemale = {
        loading: false,
        total: 0,
        data: [0, 0, 0, 0, 0, 0, 0],
      };
      let dataReportGenderMale = {
        loading: false,
        total: 0,
        data: [0, 0, 0, 0, 0, 0, 0],
      };
      let dataReportGenderUnspecified = {
        loading: false,
        total: 0,
        data: [0, 0, 0, 0, 0, 0, 0],
      };
      console.log('-----> action.data', action.data)
      let reportGenderFemale = action.data && action.data.data && action.data.data.filter(report => report.r_gender === "female")
      let reportGenderMale = action.data && action.data.data && action.data.data.filter(report => report.r_gender === "male")
      let reportGenderUnspecified = action.data && action.data.data && action.data.data.filter(report => report.r_gender === "unspecified")
      console.log('-----> reportGenderFemale', reportGenderFemale)
      console.log('-----> reportGenderMale', reportGenderMale)
      console.log('-----> reportGenderUnspecified', reportGenderUnspecified)
      if (
        reportGenderFemale &&
        reportGenderFemale.length > 0 &&
        reportGenderFemale[0].total !== "0"
      ) {
        const data = reportGenderFemale[0];
        dataReportGenderFemale = {
          loading: false,
          total: data.r_total,
          data: [
            data.r_range_1,
            data.r_range_2,
            data.r_range_3,
            data.r_range_4,
            data.r_range_5,
            data.r_range_6,
            data.r_range_7,
          ],
        };
      }
      if (
        reportGenderMale &&
        reportGenderMale.length > 0 &&
        reportGenderMale[0].total !== "0"
      ) {
        const data = reportGenderMale[0];
        dataReportGenderMale = {
          loading: false,
          total: data.r_total,
          data: [
            data.r_range_1,
            data.r_range_2,
            data.r_range_3,
            data.r_range_4,
            data.r_range_5,
            data.r_range_6,
            data.r_range_7,
          ],
        };
      }
      if (
        reportGenderUnspecified && 
        reportGenderUnspecified.length > 0 &&
        reportGenderUnspecified[0].total !== "0"
      ) {
        const data = reportGenderUnspecified[0];
        dataReportGenderUnspecified = {
          loading: false,
          total: data.r_total,
          data: [
            data.r_range_1,
            data.r_range_2,
            data.r_range_3,
            data.r_range_4,
            data.r_range_5,
            data.r_range_6,
            data.r_range_7,
          ],
        };
      }
      return Object.assign({}, state, {
        dataReportGenderMale: dataReportGenderMale,
        dataReportGenderFemale: dataReportGenderFemale,
        dataReportGenderUnspecified: dataReportGenderUnspecified,
      });

    case GET_REPORT_BIRTHDAY:
      let check_load_birthday =
        state.dataReportBirthday.limit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        dataReportBirthday: Object.assign({}, initState.dataReportBirthday, {
          transactionList: (action.data && action.data.data) || [],
          loadmore: check_load_birthday,
        }),
      });

    case GET_REPORT_BIRTHDAY_MORE:
      let more_transactionList_birthday =
        action.data && action.data.data
          ? [...state.dataReportBirthday.transactionList, ...action.data.data]
          : [...state.dataReportBirthday.transactionList];
      let check_load_birthday2 =
        state.dataReportBirthday.limit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        dataReportBirthday: Object.assign({}, state.dataReportBirthday, {
          transactionList: more_transactionList_birthday,
          offset: action.data && action.data.offset,
          loadmore: check_load_birthday2,
        }),
      });

    case GET_REPORT_POINT:
      let check_load_point =
        state.dataReportPoint.limit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        dataReportPoint: Object.assign({}, initState.dataReportPoint, {
          transactionList: (action.data && action.data.data) || [],
          loadmore: check_load_point,
        }),
      });

    case GET_REPORT_POINT_MORE:
      let more_transactionList_point =
        action.data && action.data.data
          ? [...state.dataReportPoint.transactionList, ...action.data.data]
          : [...state.dataReportPoint.transactionList];
      let check_load_point2 =
        state.dataReportPoint.limit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        dataReportPoint: Object.assign({}, state.dataReportPoint, {
          transactionList: more_transactionList_point,
          offset: action.data && action.data.offset,
          loadmore: check_load_point2,
        }),
      });

    case GET_REPORT_FREQUENCY_LOADING:
      return Object.assign({}, state, {
        isLoadingFrequency: true,
      });

    case GET_REPORT_FREQUENCY:
      return Object.assign({}, state, {
        isLoadingFrequency: false,
        dataReportFrequency: action.data.data,
      });

    case GET_REPORT_RECENCY_LOADING:
      return Object.assign({}, state, {
        isLoadingRecency: true,
      });

    case GET_REPORT_RECENCY:
      return Object.assign({}, state, {
        isLoadingRecency: false,
        dataReportRecency: action.data.data,
      });

    case GET_REPORT_ADMIN_USAGE:
      let check_load_trans_admin = state.transactionAdmin.transactionLimit === (action.data && action.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        transactionAdmin: Object.assign({}, initState.transactionAdmin, {
          transactionList: action.data || [],
          loadmore: check_load_trans_admin,
        }),
      });

    case GET_REPORT_ADMIN_USAGE_MORE:
      let more_transactionList =
        action.data && action.data.data
          ? [...state.transactionAdmin.transactionList, ...action.data.data]
          : [...state.transactionAdmin.transactionList];
      let check_load_trans_admin2 =
        state.transactionAdmin.transactionLimit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        transactionAdmin: Object.assign({}, state.transactionAdmin, {
          transactionList: more_transactionList,
          transactionOffset: action.data && action.data.offset,
          loadmore: check_load_trans_admin2,
        }),
      });

    case GET_REPORT_POINTS_FLOW:
      return Object.assign({}, state, {
        isLoading: false,
        dataReportPointsFlow: Object.assign({}, state.dataReportPointsFlow, {
          pointsFlowlist: (action.data && action.data.data) || [],
          maxPoints: (action.data && action.data.maxpoint) || 0,
          total_earned: (action.data && action.data.total_earned) || 0,
          total_spent: (action.data && action.data.total_spent) || 0,
          data_suggest: {
            total_earned_inThirtyDay: (action.data && action.data.data_suggest.total_earned_inThirtyDay) || 0,
            total_spent_inThirtyDay: (action.data && action.data.data_suggest.total_spent_inThirtyDay) || 0,
            frequent_earned_inThirtyDay: (action.data && action.data.data_suggest.frequent_earned_inThirtyDay) || 0,
            frequent_spent_inThirtyDay: (action.data && action.data.data_suggest.frequent_spent_inThirtyDay) || 0,
          },
        }),
      });

    case GET_REPORT_REWARDS:
      let check_load_rewards =
        state.dataReportRewards.limit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        dataReportRewards: Object.assign({}, initState.dataReportRewards, {
          list: (action.data && action.data.data) || [],
          loadmore: check_load_rewards,
        }),
      });

    case GET_REPORT_REWARDS_MORE:
      let more_report_rewards_list =
        action.data && action.data.data
          ? [...state.dataReportRewards.list, ...action.data.data]
          : [...state.dataReportRewards.list];
      let check_load_rewards2 =
        state.dataReportRewards.limit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        dataReportRewards: Object.assign({}, state.dataReportRewards, {
          list: more_report_rewards_list,
          offset: action.data && action.data.offset,
          loadmore: check_load_rewards2,
        }),
      });

    case GET_REPORT_COUPONS:
      let check_load_coupons =
        state.dataReportCoupons.limit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        dataReportCoupons: Object.assign({}, initState.dataReportCoupons, {
          list: (action.data && action.data.data) || [],
          loadmore: check_load_coupons,
        }),
      });

    case GET_REPORT_COUPONS_MORE:
      let more_report_coupons_list =
        action.data && action.data.data
          ? [...state.dataReportCoupons.list, ...action.data.data]
          : [...state.dataReportCoupons.list];
      let check_load_coupons2 =
        state.dataReportCoupons.limit === (action.data && action.data.data && action.data.data.length);
      return Object.assign({}, state, {
        isLoading: false,
        dataReportCoupons: Object.assign({}, state.dataReportCoupons, {
          list: more_report_coupons_list,
          offset: action.data && action.data.offset,
          loadmore: check_load_coupons2,
        }),
      });

    case CLEAR_REPORT_BIRTHDAY:
      return Object.assign({}, state, {
        dataReportBirthday: initState.dataReportBirthday,
      });

    case CLEAR_TRANSACTION_POINT:
      return Object.assign({}, state, {
        dataReportPoint: initState.dataReportPoint,
      });

    case CLEAR_TRANSACTION_ADMIN:
      return Object.assign({}, state, {
        transactionAdmin: initState.transactionAdmin,
      });

    case CLEAR_REPORT_POINTS_FLOW:
      return Object.assign({}, state, {
        dataReportPointsFlow: initState.dataReportPointsFlow,
      });

    case SET_REPORT_TRANS_ADMIN:
      return Object.assign({}, state, {
        transactionAdmin: Object.assign({}, state.transactionAdmin, {
          transactionPage: action.data,
        }),
      });

    case CLEAR_REPORT_REWARDS:
      return Object.assign({}, state, {
        dataReportRewards: initState.dataReportRewards,
      });

      case CLEAR_REPORT_DEMOGRAPHIC:
        return Object.assign({}, state, {
          dataReportGenderFemale: initState.dataReportGenderFemale,
          dataReportGenderMale: initState.dataReportGenderMale,
          dataReportGenderUnspecified: initState.dataReportGenderUnspecified,
        });

    case CLEAR_REPORT_COUPONS:
      return Object.assign({}, state, {
        dataReportCoupons: initState.dataReportCoupons,
      });

    case STOP_LOADING:
      return Object.assign({}, state, {
        isLoading: false,
      });

    case GET_REPORT_TRANSACTION_SENT:
      return Object.assign({}, state, {
        transSent: {
          list: action.data,
          offset: action.offset,
          limit: action.limit,
          loadmore: action.data.length < action.total,
          total: action.total
        },
      });

    case GET_REPORT_TRANSACTION_SENT_MORE:
      let list_ = [...state.transSent.list, ...action.data];
      return Object.assign({}, state, {
        transSent: {
          list: list_,
          offset: action.offset,
          limit: action.limit,
          loadmore: list_.length < action.total,
        },
      });

    case GET_REPORT_TRANSACTION_ACTIVATED:
      return Object.assign({}, state, {
        transActvated: {
          list: action.data,
          offset: action.offset,
          limit: action.limit,
          loadmore: action.data.length < action.total,
          total: action.total
        },
      });

    case GET_REPORT_TRANSACTION_ACTIVATED_MORE:
      let list = [...state.transActvated.list, ...action.data];
      return Object.assign({}, state, {
        transActvated: {
          list: list,
          offset: action.offset,
          limit: action.limit,
          loadmore: list.length < action.total,
        },
      });

    case GET_REPORT_RFM_ANALYSIS:
      return Object.assign({}, state, {
        RFMAnalysis: action.data || [],
      });

    case GET_REPORT_TRANSACTION_MEMBERCARD:
      return Object.assign({}, state, {
        transMembercard: {
          list: action.data,
          offset: action.offset,
          limit: action.limit,
          loadmore: action.data.length < action.total,
        },
      });

    case GET_REPORT_TRANSACTION_MEMBERCARD_MORE:
      let _list = [...state.transMembercard.list, ...action.data];
      return Object.assign({}, state, {
        transMembercard: {
          list: _list,
          offset: action.offset,
          limit: action.limit,
          loadmore: _list.length < action.total,
        },
      });

    case CLEAR_REPORT_TRANSACTION_MEMBERCARD:
      return Object.assign({}, state, {
        transMembercard: initState.transMembercard,
      });

    case GET_REPORT_ADMIN:
      return Object.assign({}, state, {
        dataReportAdmin: {
          list: (action.data && action.data.data) || [],
          offset: (action.data && action.data.offset) || state.dataReportAdmin.offset,
          limit: (action.data && action.data.limit) || state.dataReportAdmin.limit,
        },
      });

    case GET_REPORT_PRODUCT_SUMMARY:
      let key_summary = action.byMember ? "summaryMember" : "summary";
      return Object.assign({}, state, {
        dataReportProduct: Object.assign({}, state.dataReportProduct, {
          [key_summary]: {
            list: (action.data && action.data.data) || [],
            offset: (action.data && action.data.offset) || state.dataReportProduct[key_summary].offset,
            limit: (action.data && action.data.limit) || state.dataReportProduct[key_summary].limit,
          },
        }),
      });

    case GET_REPORT_PRODUCT_DETAIL:
      if (action.key === "all") return state;
      let key_detail = action.key === "product_id" ? "detail" : "detailMember";
      return Object.assign({}, state, {
        dataReportProduct: Object.assign({}, state.dataReportProduct, {
          [key_detail]: {
            list: (action.data && action.data.data) || [],
            offset: (action.data && action.data.offset) || state.dataReportProduct[key_detail].offset,
            limit: (action.data && action.data.limit) || state.dataReportProduct[key_detail].limit,
          },
        }),
      });

    case GET_REPORT_GET_FRIENDS:
      return Object.assign({}, state, {
        dataReportFriends: {
          list: (action.data && action.data.data) || [],
          offset: (action.data && action.data.offset) || state.dataReportFriends.offset,
          limit: (action.data && action.data.limit) || state.dataReportFriends.limit,
        },
      });

    case CLEAR_REPORT_GET_FRIENDS:
      return Object.assign({}, state, {
        dataReportFriends: initState.dataReportFriends,
      });

    case CLEAR_REPORT_ADMIN:
      return Object.assign({}, state, {
        dataReportAdmin: initState.dataReportAdmin,
      });
    case SET_EXPORT_POINT_ALL_TRANS_CANCEL_SOURCE: {
      return Object.assign({}, state, {
        exportPointAllTransCancelSource: action.data.source,
      });
    }
    case GET_REPORT_DASHBOARD:
      return Object.assign({}, state, {
        isLoading: false,
        dataReportDashboard: Object.assign({}, initState.dataReportDashboard, {
          data: (action.data && action.data.data) || []
        }),
      });
    case GET_REPORT_DASHBOARD_REVIEW:
      return Object.assign({}, state, {
        isLoading: false,
        dataReportDashboardReview: Object.assign({}, initState.dataReportDashboardReview, action.data)
      });
    case CLEAR_REPORT_DASHBOARD:
      return Object.assign({}, state, {
        dataReportDashboard: initState.dataReportDashboard,
        dataReportDashboardReview: initState.dataReportDashboardReview
      });
    case IS_LOADING_GET_TRANSACTION_SUMMARY:
      return Object.assign({}, state, {
        isLoadingTransactionSummary: action.data
      });
    case GET_TRANSACTION_SUMMARY_REPORT:
      return Object.assign({}, state, {
        transactionSummaryReport: action.data || {}
      });
    case IS_LOADING_GET_DATA_TRANSACTION_CHART:
      return Object.assign({}, state, {
        isLoadingDataTransactionChart: action.data
      });
    case GET_DATA_TRANSACTION_CHART:
      return Object.assign({}, state, {
        dataTransactionChart: action.data || {},
        dateRangeType: action.dateRangeType
      });
    default:
      return state;
  }
};

export default ReducerReport;