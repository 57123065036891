import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { Title3 } from '../../Font'
import Loading from '../../Loading'
import { InvertBtn, DangerBtn } from '../../Button'

import CreditCard from '../Card/CreditCard'
import BusinessPaymentModalCard from './BusinessPaymentCardModal'
import {
  LoadingSizeBox,
  CreditCardDetail,
  CreditCardBox,
  IconPlus,
  TitleBar,
} from './BusinessPaymentCard.style'

import {
  updateCustomerPaymentCreditCard,
  deleteCustomerPaymentCreditCard
} from '../../../redux/actions/action_payment'

class BusinessPaymentCreditCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      limit_credit_card: 3,
      isPackage: 'ps-pro',
      isLoading: false,
      isLoading2: false,
      isOpenModalCard: false,
      isOpenConfirmDelete: false,
      card_id: '',
    }
    this.toggleModalCard = this.toggleModalCard.bind(this)
    this.removeCreditCard = this.removeCreditCard.bind(this)
    this.toggleModalConfirmDelete = this.toggleModalConfirmDelete.bind(this)
    this.onNewCardAdded = this.onNewCardAdded.bind(this)
  }

  toggleModalCard() {
    this.setState({ isOpenModalCard: !this.state.isOpenModalCard })
  }

  toggleModalConfirmDelete(card_id) {
    this.setState({
      card_id: card_id || '',
      isOpenConfirmDelete: !this.state.isOpenConfirmDelete
    })
  }

  removeCreditCard(card_id) {
    let { store_business: { current: { business_code } } } = this.props

    this.props.selectCreditCard('')
    this.setState({ isLoading2: true })
    this.props.deleteCustomerPaymentCreditCard(business_code, card_id,
      (error, data) => {
        if (Boolean(data))
          return this.toggleModalConfirmDelete('')
      })
  }

  onNewCardAdded(id) {
    //console.log("onNewCardAdded", id);
    this.props.reloadCardList();
    this.props.selectCreditCard(id)
  }

  render() {
    let { store_language: { dictionary } } = this.props
    let { store_payment: { card, payment: { payment } } } = this.props
    
    return <>
      <TitleBar>
        <Title3 bold>{`${dictionary.credit_card} / ${dictionary.debit_card}`}</Title3>
        {
          card && card.length > 0 && card.length < this.state.limit_credit_card
            ? <div onClick={this.toggleModalCard} style={{ cursor: 'pointer' }} className='pr-2 d-flex align-items-center'>
              <IconPlus className='fas fa-plus-circle pr-2' />
              <Title3>{dictionary.add_payment_method}</Title3>
            </div>
            : null
        }
      </TitleBar>

      {
        this.state.isLoading
          ? <CreditCardDetail>
            <LoadingBox height={85} size={2} />
          </CreditCardDetail>
          : card && card.length > 0
            ? <CreditCardDetail>
              {
                card.map((value, key) => {
                  return <CreditCard
                    key={key}
                    Id={value.id}
                    Name={value.name}
                    Brand={value.brand}
                    Last4={value.last4}
                    ExpYear={value.exp_year}
                    ExpMonth={value.exp_month}
                    DefaultCard={payment.default_source}
                    SelectCard={this.props.card_id === value.id}
                    onClickSelectCard={(id) => { this.props.selectCreditCard(id) }}
                    onClickRemoveCard={(id) => { this.toggleModalConfirmDelete(id) }}
                  />
                })
              }
            </CreditCardDetail>
            : <CreditCardDetail>
              <CreditCardBox>
                <div className='w-100 text-center'>
                  <InvertBtn invertSecondary onClick={this.toggleModalCard}>
                    <div className='d-flex align-items-center'>
                      <i className='fas fa-plus-circle pr-2' />
                      <Title3 bold>{dictionary.add_payment_method}</Title3>
                    </div>
                  </InvertBtn>
                </div>
              </CreditCardBox>
            </CreditCardDetail>
      }

      {
        this.state.isOpenModalCard
        && <BusinessPaymentModalCard
          isOpen={this.state.isOpenModalCard}
          toggleModalCard={this.toggleModalCard}
          onClickSelectCard={(id) => { this.onNewCardAdded(id) }} />
      }

      {
        this.state.isOpenConfirmDelete
        && <Modal centered fade={true} isOpen={this.state.isOpenConfirmDelete}>
          <ModalHeader>
            <Title3 bold>{dictionary.you_want_delete_credit_card}</Title3>
          </ModalHeader>
          <ModalBody>
            {
              this.state.isLoading2
                ? <LoadingBox height={85} size={2} />
                : card.filter(f => f.id === this.state.card_id).map((value, key) => {
                  return <CreditCard
                    readOnly
                    key={key}
                    Id={value.id}
                    Name={value.name}
                    Brand={value.brand}
                    Last4={value.last4}
                    ExpYear={value.exp_year}
                    ExpMonth={value.exp_month} />
                })
            }
          </ModalBody>
          <ModalFooter>
            <InvertBtn onClick={() => this.toggleModalConfirmDelete('')}>
              <Title3 secondary link>{dictionary.close}</Title3>
            </InvertBtn>
            <DangerBtn onClick={() => this.removeCreditCard(this.state.card_id)}>
              <Title3 white>{dictionary.delete}</Title3>
            </DangerBtn>
          </ModalFooter>
        </Modal>
      }
    </>
  }
}

const LoadingBox = ({ height, size }) => {
  return <LoadingSizeBox height={height}>
    <Loading size={`${size}rem`} />
  </LoadingSizeBox>
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_payment: state.payment,
    store_language: state.language,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    updateCustomerPaymentCreditCard,
    deleteCustomerPaymentCreditCard
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPaymentCreditCard)