import { RFM } from "../actions_const";

let initState = {
  RFMGroupData: {
    group1: [],
    group2: [],
    group3: [],
    group4: [],
    group5: [],
    group6: [],
    group7: [],
    group8: [],
    group9: [],
    group10: [],
  },
  Gender: {
    group1: {},
    group2: {},
    group3: {},
    group4: {},
    group5: {},
    group6: {},
    group7: {},
    group8: {},
    group9: {},
    group10: {},
  },
  totalMember: 0,
  totalRFMGroup: [],
  dataRFMForTable: [],
  dataRFMForExport: [],
  isLoadingDataExport: false,
  current_page: 0,
  isLoadingDataMember: false,
};

const ReducerRFM = (state = initState, action) => {
  let key = "group" + action.id;

  switch (action.type) {
    case RFM.GET_RFM_GROUP_DATA:
      return Object.assign({}, state, {
        RFMGroupData: {
          ...state.RFMGroupData,
          [key]: action.data || [],
        },
      });

    case RFM.GET_GENDER:
      const Data = action.data;
      const Gender = {};
      if (Data && Object.keys(Data).length > 0) {
        Data.forEach((item) => {
          const id = `group${item.id}`;
          Gender[id] = {
            male: item.male,
            female: item.female,
            unspecified: item.unspecified,
            sum: item.male + item.female + item.unspecified || 0,
          };
        });
      } else {
        for (let i = 1; i <= 10; i++) {
          const id = `group${i}`;
          Gender[id] = {
            male: 0,
            female: 0,
            unspecified: 0,
            sum: 0,
          };
        }
      }

      return Object.assign({}, state, {
        Gender: Gender,
        totalMember: action.totalCount,
      });

    case RFM.GET_DATA_RFM_FOR_EXPORT:
      return Object.assign({}, state, {
        dataRFMForExport: action.data,
        isLoadingDataExport: false,
      });

    case RFM.SET_IS_LOADING_DATA:
      return Object.assign({}, state, {
        isLoadingDataExport: true,
      });

    case RFM.SET_CURRENT_PAGE:
      return Object.assign({}, state, {
        current_page: action.data,
      });

    case RFM.GET_DATA_RFM_FOR_TABLE:
      return Object.assign({}, state, {
        dataRFMForTable: action.data,
        isLoadingDataMember: false,
      });

    case RFM.LOADING_DATA_MEMBER:
      return Object.assign({}, state, {
        isLoadingDataMember: true,
      });

    case RFM.RESET_DATA_RFM_FOR_TABLE:
      return Object.assign({}, state, {
        dataRFMForTable: [],
      });

    case RFM.GET_TOTAL_RFM_GROUP:
      return Object.assign({}, state, {
        totalRFMGroup: action.data,
      });

    case RFM.RESET_DATA_RFM:
      return Object.assign({}, state, {
        RFMGroupData: {
          group1: [],
          group2: [],
          group3: [],
          group4: [],
          group5: [],
          group6: [],
          group7: [],
          group8: [],
          group9: [],
          group10: [],
        },
        Gender: {
          group1: {},
          group2: {},
          group3: {},
          group4: {},
          group5: {},
          group6: {},
          group7: {},
          group8: {},
          group9: {},
          group10: {},
        },
        totalRFMGroup: [],
      });

    default:
      return state;
  }
};


export default ReducerRFM;