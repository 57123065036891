import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Title2 } from '../../Font'
import { FormGroup} from 'reactstrap'
import { setTargetMemberTotal } from '../../../redux/actions/action_broadcast'
import moment from 'moment'

export default function TargetEachBirthMonth() {
	const dictionary = useSelector((state) => state.language.dictionary)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setTargetMemberTotal({
			target: 'birthmonth',
			target_data: moment().month() + 1
		}))
	}, [dispatch])


	return (
		<FormGroup>
			<Title2 className="pl-2 mb-3">
				{dictionary.campaign_coupon_target_each_month}
			</Title2>
		</FormGroup>
	)
}
