import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { Title2, Title3 } from '../../components/Font'
import { toCurrency } from '../../libs/currency'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Date from '../../libs/date'
import { RecentHeader, RecentItems } from './recentTransaction.style'

class RecentTransactions extends React.Component {
  render() {
    let { transactions, admin, store_language: { dictionary } } = this.props
    return (
      <div className='mb-2'>
        <Card>
          <CardHeader>
            <Title2 bold>{admin ? `${dictionary.recent_transactions_of} ${admin}` : dictionary.recent_transactions}</Title2>
          </CardHeader>
          <CardBody className='p-3' style={{ maxHeight: 400, overflow: 'auto' }}>
            <RecentHeader className="pb-2" >
              <Title2 bold>{dictionary.mobile}</Title2>
              <Title2 bold>{dictionary.points}</Title2>
            </RecentHeader>
            {
              (transactions && transactions.length > 0)
                ? transactions.map((val, key) => (
                  <RecentItems className="px-3 py-2" key={key}>
                    <div className='d-flex align-items-center justify-content-between'>
                      <Title3 bold link blue>{val.receiver}</Title3>
                      <Title3 bold>{toCurrency(val.point)}</Title3>
                    </div>
                    <Title3 third>{val.timeStamp ? Date.formatDate(val.timeStamp) : ''}</Title3>
                    {val.remark && <Title3 secondary>{val.remark}</Title3>}
                  </RecentItems>
                ))
                : <Title3 secondary center className="pt-4 border-top">{dictionary.no_recent_transactions}</Title3>
            }
          </CardBody>
        </Card>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({

  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentTransactions)