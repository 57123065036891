import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, ModalBody } from 'reactstrap'

import { icon } from '../../libs/icon.js'
import { Title2, Title3 } from '../Font'
import { SuccessBtn, InvertBtn } from '../Button'
import { ToastBar } from "./../ToastComponent/Toast.style";
import { toast } from "react-toastify";

import {
  ImgUploadContainer,
  CameraHover,
  CameraPlaceholder,
} from './PhotoCropper.style'

import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'

let maxpx = 600

class PhotoCropper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      hover: false,
      isCroping: false,
      rounded: Boolean(this.props.rounded),
      hide: Boolean(this.props.hide),
      imageURL: props.image || '',
      imageOrigin: '',
    }

    this.toggle = this.toggle.bind(this)
    this.onClickUpload = this.onClickUpload.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
    this.imageCrop = this.imageCrop.bind(this)
  }

  componentDidMount() {
    if (this.props.autoUpload)
      this.onClickUpload()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.image !== this.props.image)
      this.setState({ imageURL: this.props.image })
  }

  toggle() {
    this.setState({ modal: !this.state.modal })
  }

  toastShow(type, message) {
    toast[type](<ToastBar><Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{message}</Title2></ToastBar>)
  }

  onClickUpload() {
    if (this.props.autoCrop && this.state.imageURL) return
    this.refs.uploadFile.click()
  }

  onChangeFile(e) {
    const file = e.target.files[0];
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(file.name)) {
      this.toastShow('error', this.props.store_language.dictionary.member_card_selected_fail);
      return;
    }

    let { uploadFile } = this.refs
    if (uploadFile.files.length > 0
      
      && ['image/jpeg', 'image/png'].indexOf(uploadFile.files[0].type) > -1
    ) {
      let imageOrigin = window.URL.createObjectURL(uploadFile.files[0])
      if (this.props.autoCrop) {
        let { crop, height, width } = this.props
        cropImage(imageOrigin, crop, height, width)
      } else {
        this.setState({
          imageOrigin: imageOrigin,
          modal: true
        })
      }
    }
  }

  imageCrop() {
    this.setState({ isCroping: true })
    let cropOption = {
      fillColor: '#fff',
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    }

    let { height, width } = this.refs.cropper.getCropBoxData()
    if (height > width) Object.assign(cropOption, { height: this.props.height || maxpx })
    else Object.assign(cropOption, { width: this.props.width || maxpx })

    let imgData = this.refs.cropper.getCroppedCanvas(cropOption).toDataURL('image/jpeg')
    this.props.crop(imgData)
    this.setState({ imageURL: imgData, modal: false, isCroping: false })
  }

  rotate(degree, event) {
    this.refs.cropper.rotate(degree)
  }

  onMouseOver() {
    if (!this.state.isCroping)
      this.setState({ hover: true })
  }

  onMouseLeave() {
    if (!this.state.isCroping)
      this.setState({ hover: false })
  }

  _crop() {
    if (this.props.autoCrop && !this.state.isCroping) {
      this.imageCrop()
    }
  }

  render() {
    let { store_language: { dictionary } } = this.props
    let { backgroundHidden } = this.props

    return <div>
      {
        this.props.autoCrop
          ? <ImgUploadContainer disabled={this.props.disabled}
            onClick={this.props.disabled ? null : this.onClickUpload}
            backgroundHidden={backgroundHidden}>
            {this.props.children}
          </ImgUploadContainer>
          : <ImgUploadContainer disabled={this.props.disabled}
            onClick={this.props.disabled ? null : this.onClickUpload}
            backgroundHidden={backgroundHidden}
            onMouseOver={this.onMouseOver.bind(this)}
            onMouseLeave={this.onMouseLeave.bind(this)}>
            {
              this.state.imageURL
              && this.state.hover
              && !this.props.disabled
              && <CameraHover>
                <Title3 bold>
                  <i className={`fas fa-image fa-lg mr-2`} />
                  {dictionary.select_photo}
                </Title3>
              </CameraHover>
            }
            {
              this.state.imageURL
                ? 
                (this.props.broadcastLine ?
                <img height='150px' src={this.state.imageURL} alt="" /> :
                <img className="w-100"src={this.state.imageURL} alt="" />)
                : this.props.children
                  ? this.props.children
                  : <CameraPlaceholder backgroundHidden={backgroundHidden} broadcastLine={this.props.broadcastLine}>
                    <div className='text-center'>
                      {this.props.broadcastLine ?
                      <>
                      <Title2 bold color='#546174'><i className='fas fa-image fa-3x' /></Title2>
                      <Title2 bold color='#546174' className='pt-1'>{dictionary.select_photo}</Title2>
                      </>
                    :
                    <>
                      <Title2 bold white><i className='fas fa-image fa-3x' /></Title2>
                      <Title3 bold white className='pt-1'>{dictionary.select_photo}</Title3>
                    </>
                    }
                    </div>
                  </CameraPlaceholder>
            }
          </ImgUploadContainer>
      }

      <input
        type="file"
        ref="uploadFile"
        className="d-none"
        onChange={this.onChangeFile}
        accept=".jpg, .jpeg, .png"
        onClick={event => { event.target.value = null }} />
    

      <Modal size="lg" centered fade={false} backdrop={'static'} toggle={this.toggle} isOpen={this.state.modal}>
        <ModalBody>
          <div className="d-flex justify-content-between mb-3">
            <Title2 onClick={this.rotate.bind(this, 90)} style={{ cursor: 'pointer' }} >
              <i className={icon.redo}></i>
            </Title2>
            <Title2 onClick={this.rotate.bind(this, -90)} style={{ cursor: 'pointer' }} >
              <i className={icon.undo}></i>
            </Title2>
          </div>
          <Cropper
            ref='cropper'
            crop={this._crop.bind(this)}
            src={this.state.imageOrigin}
            style={{ height: 400, width: '100%' }}
            aspectRatio={this.props.aspectRatio || NaN}
            autoCropArea={this.props.autoCropArea || 0.95}
            className={this.props.rounded ? ' business-logo-rounded' : ''} />
          <div className="mt-3 d-flex align-items-center justify-content-between">
            <InvertBtn md bold fluid onClick={this.toggle}>{dictionary.cancel}</InvertBtn>
            <SuccessBtn md bold fluid onClick={this.imageCrop.bind(this)}>{dictionary.crop}</SuccessBtn>
          </div>
        </ModalBody>
      </Modal>
    </div>
  }
}

const cropImage = (imgSrc, callback, maxHeight, maxWidth) => {
  let canvas = document.createElement("canvas")
  let ctx = canvas.getContext("2d")
  let img = new Image()
  img.onload = () => {
    let ratio = img.height / img.width
    maxHeight = maxHeight || maxpx
    maxWidth = maxWidth || maxpx
    if (img.height > img.width && img.height > maxHeight) {
      canvas.height = maxHeight
      canvas.width = maxHeight / ratio
    } else if (img.width > img.height && img.width > maxWidth) {
      canvas.width = maxWidth
      canvas.height = maxWidth * ratio
    } else if (img.width > maxWidth || img.height > maxHeight) {
      canvas.width = maxWidth
      canvas.height = maxWidth * ratio
    } else {
      canvas.height = img.height
      canvas.width = img.width
    }
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
    canvas.toBlob(data => {
      let url = canvas.toDataURL('image/jpeg', 1.0)
      callback(url)
    })
  }
  img.src = imgSrc
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PhotoCropper)
