const { default: styled, css } = require('styled-components')

export const ModalListCouponBox = styled.div`
  width: 100%;
  height: calc(100vh - 250px);
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
`

export const RewardCouponBox = styled.div`
  width: 100%;
  padding: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.selected && css`background: rgb(0, 0, 0, 0.05);`}

`

export const IconsSelectRewardCoupon = styled.div`
  color: ${props => props.theme.primary};
  font-size: 22px;

  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 990px) {
    font-size: 18px;
  }
`