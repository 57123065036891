import styled from 'styled-components'

export const ContainerTransaction = styled.div`
 position: relative;
 overflow-y: scroll;
 overflow-x: hidden;
 height: calc(95vh - 150px);
`

export const TransactionIconClose = styled.div`
  top: 0;
  right: 0;
  position: absolute;

  margin: 3px;
  z-index: 100;
  font-size: 14px;
  cursor: pointer;

  border-radius: 50%;
  border: 4px solid #546174;

  color: ${props => props.theme.primary};
  background: ${props => props.theme.white};

  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
`