const ps_admin = {}

module.exports.enable = () => {
  window.ps_admin = ps_admin
}
module.exports.disable = () => {
  window.ps_admin = null
}

module.exports.getCaching = (key) => {
  return ps_admin[key]
}

module.exports.setCaching = (key, value) => {
  ps_admin[key] = value
}

module.exports.clearCaching = (key) => {
  if (ps_admin.hasOwnProperty(key))
    delete ps_admin[key]
}