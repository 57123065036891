import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, ModalBody, Row } from "reactstrap";
import { InvertBtn, PrimaryBtn } from "../../components/Button";
import { Title2, Title3 } from "../../components/Font";
import { DEBUG } from "../../libs/utils";
import { BoxProcess, BoxProcessHead, VerifyModalFooter, VerifyModalHeader } from "./verifyBusinessHash.style";
import { getSettings, resetExpiryPoint, resetProcessingStatus, setPremiumId, setSenderName } from "../../redux/actions/action_manager";
import Loading from "./Loading";
import { MANAGER } from "../../redux/actions_const";
import { Button } from "reactstrap";
import moment from "moment";
import ConfirmModal from "./ConfirmModal";
import BoxItemApproval from "../../components/BoxItemApproval";

export default function Settings({ business_code }) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.manager.isLoadingSettings);
  const premiumId = useSelector((state) => state.manager.premiumId);
  const sender = useSelector((state) => state.manager.sender);
  const [isShowPremiumIdModal, setShowPremiumIdModal] = useState(false);
  const [isShowSenderNameModal, setShowSenderNameModal] = useState(false);
  const [isShowConfirmResetPoint, setShowConfirmResetPoint] = useState(false);
  const [expand, setExpand] = useState(true);

  useEffect(() => {
    DEBUG("[useEffect] Settings", business_code);
    if (business_code) {
      dispatch(getSettings(business_code));
    }
  }, [business_code, dispatch]);

  const onEditSubmitted = (data) => {
    DEBUG("[onEditSubmit] data", data);
    const { premium_id, sender_name } = data;
    if (premium_id) {
      dispatch(
        setPremiumId({
          business_code,
          premium_id,
        })
      );
    } else if (sender_name) {
      dispatch(
        setSenderName({
          business_code,
          sender_name,
        })
      );
    }
  };

  const onResetConfirm = () => {
    dispatch(
      resetExpiryPoint({
        business_code,
      })
    );
  };

  const onEditPremiumIdClicked = () => {
    dispatch(resetProcessingStatus());
    setShowPremiumIdModal(true);
  };

  const onEditSenderNameClicked = () => {
    dispatch(resetProcessingStatus());

    setShowSenderNameModal(true);
  };

  const onResetPointClicked = () => {
    dispatch(resetProcessingStatus());
    setShowConfirmResetPoint(true);
  };

  return (
    <BoxProcess>
      <BoxProcessHead className="justify-content-between align-items-center" pointer onClick={() => setExpand(!expand)}>
        <Title2 bold white>
          การตั้งค่า
        </Title2>
        {expand ? <i className="fas fa-chevron-up"></i> : <i className="fas fa-chevron-down"></i>}
      </BoxProcessHead>
      {expand ? (
        isLoading ? (
          <Loading />
        ) : (
          <div style={{ padding: "20px" }}>
            <Title2 bold>Premium ID</Title2>
            <div className="d-flex p-3 justify-content-between align-items-center">
              <div className="d-inline-flex">
                <Title2>pointspot.co/</Title2>
                <Title2 bold>{premiumId ? `@${premiumId}` : `b/${business_code}`}</Title2>
              </div>
              <Button color="outline" size="sm" onClick={onEditPremiumIdClicked}>
                <i className="far fa-edit" style={{ color: "teal" }} />
              </Button>
            </div>
            <Title2 bold>SMS Sender Name</Title2>
            <div className="d-flex p-3 justify-content-between align-items-center">
              {sender && sender.name ? (
                <div style={{ minWidth: "70%" }}>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <Row>
                        <Col xs={12}>
                          <Title2 className="pb-2">
                            <span className="text-danger"></span>
                            {sender.name || "-"}
                          </Title2>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex align-items-center justify-content-center p-2" xs={12} md={6} lg={6} xl={6}>
                      <BoxItemApproval value="True" state={sender.true_status} />
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center p-2" xs={12} md={6} lg={6} xl={6}>
                      <BoxItemApproval value="MailBIT" state={sender.mailbit_status} />
                    </Col>
                  </Row>
                </div>
              ) : (
                <div>
                  <Row>
                    <Col xs={12} md={12} lg={12} xl={12}>
                      <Row>
                        <Col xs={12}>
                          <Title2 className="pb-2">
                            <span className="text-danger"></span>-
                          </Title2>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}

              <Button color="outline" size="sm" onClick={onEditSenderNameClicked}>
                <i className="far fa-edit" style={{ color: "teal" }} />
              </Button>
            </div>

            <Title2 bold>Point expiry date</Title2>
            <ExpiryPoint onResetClick={onResetPointClicked} />
            {isShowPremiumIdModal && (
              <EditModal
                key="premium_id"
                isOpen={isShowPremiumIdModal}
                toggle={() => setShowPremiumIdModal(!isShowPremiumIdModal)}
                onSubmit={onEditSubmitted}
                fieldName="premium_id"
                defaultValue={premiumId}
              />
            )}
            {isShowSenderNameModal && (
              <EditModal
                key="sender_name"
                isOpen={isShowSenderNameModal}
                toggle={() => setShowSenderNameModal(!isShowSenderNameModal)}
                onSubmit={onEditSubmitted}
                fieldName="sender_name"
                defaultValue={sender.name}
              />
            )}
            {isShowConfirmResetPoint && (
              <ConfirmModal
                isOpen={isShowConfirmResetPoint}
                toggle={() => setShowConfirmResetPoint(!isShowConfirmResetPoint)}
                text="ยืนยันการรีเซ็ต Point expiry date"
                onConfirm={onResetConfirm}
              />
            )}
          </div>
        )
      ) : null}
    </BoxProcess>
  );
}

const EditModal = ({ isOpen, toggle, onSubmit, fieldName, defaultValue }) => {
  const isInited = useRef(false);
  const processingStatus = useSelector((state) => state.manager.processingStatus);
  const [fieldValue, setFieldValue] = useState(defaultValue || "");
  const [error, setError] = useState(false);

  useEffect(() => {
    DEBUG("[useEffect] PremiumIdModal", processingStatus, isOpen);
    if (!isInited.current) {
      isInited.current = true;
    } else {
      if (isOpen && processingStatus.status === MANAGER.PROCESS_STATUS_SUCCEED) {
        toggle();
      }
    }
  }, [processingStatus, isOpen, isInited, toggle]);

  const isProcessing = processingStatus.status === MANAGER.PROCESS_STATUS_WORKING;
  const isEditing = fieldValue && fieldValue !== defaultValue;

  const onSubmitClick = () => {
    onSubmit({
      [fieldName]: fieldValue,
    });
  };

  const onTextChange = (event) => {
    const { value } = event.target;
    let pattern = "";
    DEBUG("[EditModal] onTextChange", fieldName, value);


    if (fieldName === "premium_id") {
      // eslint-disable-next-line
      pattern = /^([A-Za-z0-9\-_.]{1,40})$/gi;
    } else if (fieldName === "sender_name") {
      // eslint-disable-next-line
      pattern = /^(?=.{4,11}$)(?!^[0-9]+$)(?!^[ ])(?!.*[ ]$)([A-Za-z0-9@_\-#$%\[\]*.:;!]* ?[A-Za-z0-9@_\-#$%\[\]*.:;!]+ ?)+$/gi;
    }

    if (pattern && !pattern.test(value)) {
      setError(true);
    } else {
      setError(false);
    }
    setFieldValue(value);
  };

  return (
    <Modal isOpen={isOpen} centered={true}>
      <VerifyModalHeader>
        <Title2 white bold>
          {fieldName === "premium_id" ? "แก้ไข Premium ID" : "แก้ไข SMS Sender Name"}
        </Title2>
      </VerifyModalHeader>
      <ModalBody>
        {fieldName === "premium_id" && (
          <>
            <Title2 bold className="mb-2">
              Premium ID
            </Title2>
            <input
              className="form-control mr-2"
              style={error ? { borderColor: "red", boxShadow: "0 0 0 0.2rem rgba(255, 0, 0, 0.25)" } : {}}
              type="text"
              name="field"
              value={fieldValue}
              onChange={onTextChange}
            />
            <Title3 className="mt-1" style={{ color: "red" }} secondary>
              {error ? "กรุณากรอกข้อมูลให้ถูกต้อง" : null}
            </Title3>
            <Title3 className="mt-1" secondary>
              * ระบุได้สูงสุด 40 ตัวอักษร
            </Title3>
            <Title3 className="mt-1" secondary>
              * อักขระที่สามารถใช้ได้ A-z 0-9 - _ .
            </Title3>
          </>
        )}
        {fieldName === "sender_name" && (
          <>
            <Title2 bold className="mb-2">
              SMS Sender Name
            </Title2>
            <input
              className="form-control mr-2"
              style={error ? { borderColor: "red", boxShadow: "0 0 0 0.2rem rgba(255, 0, 0, 0.25)" } : {}}
              type="text"
              maxLength="11"
              name="field"
              value={fieldValue}
              onChange={onTextChange}
            />
            <Title3 className="mt-1" style={{ color: "red" }} secondary>
              {error ? "กรุณากรอกข้อมูลให้ถูกต้อง" : null}
            </Title3>
            <Title3 className="mt-1" secondary>
              * ข้อความต้องมีความยาวระหว่าง 4 ถึง 11 ตัวอักษร
            </Title3>
            <Title3 className="mt-1" secondary>
              * ตัวอักษรที่อนุญาตให้ใช้ได้แก่ A-Z, a-z, 0-9, @, _, -, #, $, %, [, ], *, ., :, ;, และ !
            </Title3>
            <Title3 className="mt-1" secondary>
              * อนุญาตให้มีช่องว่างเฉพาะบริเวณกลางข้อความเท่านั้น และห้ามมีช่องว่างที่ต้นหรือท้ายข้อความ
            </Title3>
            <Title3 className="mt-1" secondary>
              * ข้อความไม่สามารถประกอบไปด้วยตัวเลข (0-9) เท่านั้นได้
            </Title3>
            <Title3 className="mt-1" style={{ color: "red" }} secondary>
              * อักขระพิเศษอาจจะไม่สามารถแสดงผลบนบางอุปกรณ์หรือบางผู้ให้บริการได้
            </Title3>
          </>
        )}
      </ModalBody>
      <VerifyModalFooter className="d-flex flex-grow-1 justify-content-end">
        <InvertBtn onClick={toggle} disabled={isProcessing}>
          ยกเลิก
        </InvertBtn>
        <PrimaryBtn className="ml-2" onClick={onSubmitClick} disabled={error || isProcessing || !isEditing}>
          {isProcessing ? <i className="fas fa-spinner fa-pulse" /> : "ยืนยัน"}
        </PrimaryBtn>
      </VerifyModalFooter>
    </Modal>
  );
};

const ExpiryPoint = ({ onResetClick }) => {
  const dictionary = useSelector((state) => state.language.dictionary);
  const expiryPoint = useSelector((state) => state.manager.expiryPoint);

  DEBUG("[ExpiryPoint]", expiryPoint);

  if (_.isEmpty(expiryPoint)) {
    return "-";
  }

  const expritPointsType = [
    {
      type: "expiration_non",
      title: dictionary.non_setting_expiry_points,
      detail: dictionary.non_setting_expiry_points_detail,
    },
    {
      type: "expiration_month",
      title: dictionary.month_setting_expiry_points,
      detail: `${expiryPoint.value_expiry_point} ${dictionary.month}`,
    },
    {
      type: "expiration_year",
      title: dictionary.year_setting_expiry_points,
      detail: `${expiryPoint.value_expiry_point} ${dictionary.year}`,
    },
    {
      type: "expiration_custom",
      title: dictionary.custom_setting_expiry_points,
      detail: `${moment(expiryPoint.date_expiry_point).format("MMMM YYYY")}`,
    },
  ];

  const expiryPointDetail = _.find(expritPointsType, {
    type: expiryPoint.type_expiry_point,
  });

  return (
    <div className="d-flex p-3 justify-content-between align-items-center">
      {expiryPointDetail ? <Title2>{`${expiryPointDetail.title}: ${expiryPointDetail.detail}`}</Title2> : "-"}
      {expiryPointDetail ? <PrimaryBtn onClick={onResetClick}>รีเซ็ต</PrimaryBtn> : null}
    </div>
  );
};
