import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Input } from 'reactstrap'
import { Title3 } from '../../Font'
import { usePrevious } from '../../../libs/hooks/usePrevious'
import './TargetMemberPointsStyle.css'

export default function RangePointFromTo({
	min,
	onChangePoint,
	onSetInvalid,
	isInvalid,
	start = '',
	end = '',
	disabled
}) {
	const dictionary = useSelector((state) => state.language.dictionary)
	const [pointRange, setPointRange] = useState({ start: start, end: end })
	const pointRangePrevious = usePrevious(pointRange)
	const isMounted = useRef()
	const setMin = min || 0

	useEffect(() => {
		if (
			isMounted.current &&
			JSON.stringify(pointRangePrevious) !== JSON.stringify(pointRange)
		) {
			onChangePoint(pointRange);
		}

		if (!isMounted.current) {
			isMounted.current = true
			onChangePoint(pointRange)
		}

	}, [pointRange, pointRangePrevious, onChangePoint])

	const handleChange = (e) => {
		let { name, value } = e.target

		if (name === 'point_start' && value === '') {
			setPointRange((prev) => { return { ...prev, start: '' } })
		}
		if (name === 'point_end' && value === '') {
			setPointRange((prev) => { return { ...prev, end: '' } })
		}

		value = parseInt(value)
		
		if (value < 0) value = setMin
		if (isNaN(value)) value = ''

		onSetInvalid(false)

		if (
			(name === 'point_start' && (pointRange.end === '' || value === '')) ||
			(name === 'point_end' && (pointRange.start === '' || value === ''))
		) {
			onSetInvalid(true)
		}
		
		if (
			(name === 'point_start' && value > pointRange.end) ||
			(name === 'point_end' && value < pointRange.start)
		) {
			onSetInvalid(true);
		}

		if (name === 'point_start') {
			const point = value.toString().replace('/^0+', '')
			setPointRange((prev) => {
				return {
					...prev,
					start: point
				}
			})
		} else if (name === 'point_end') {
			const point = value.toString().replace('/^0+', '')
			setPointRange((prev) => {
				return {
					...prev,
					end: point
				}
			})
		}
	}

	return (
		<>
			<Col xs={10} sm={3} className='p-0'>
				<Input
					type="number"
					id="point_start"
					name='point_start'
					value={pointRange.start}
					onChange={handleChange}
					cssModule={{ 'is-invalid': 'invalid-input-point' }}
					invalid={isInvalid}
					disabled={disabled}
					placeholder='0'
				>
				</Input>
			</Col>
			<Col xs={2} sm={1} className='align-self-center text-center p-0'>
				<Title3>{dictionary.to}</Title3>
			</Col>
			<Col xs={10} sm={3} className='p-0'>
				<Input
					type="number"
					id="point_end"
					name='point_end'
					value={pointRange.end}
					onChange={handleChange}
					cssModule={{ 'is-invalid': 'invalid-input-point' }}
					invalid={isInvalid}
					disabled={disabled}
					placeholder='0'
				>
				</Input>
			</Col>
			<Col xs={2} sm={2} className='align-self-center p-0 pl-2 col-sm'>
				<Title3>{dictionary.points.toLowerCase()}</Title3>
			</Col>
			{
				isInvalid &&
				<Col xs={10} sm={10} className='p-0'>
					<Title3 className='text-danger p-0 pl-1 pt-2'>{dictionary.points_range_invalid}</Title3>
				</Col>
			}
		</>
	)
}