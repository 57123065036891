import { Col, Fade, Input, ModalBody, ModalFooter, Row } from "reactstrap";
import { DescriptionBar, TitleBar } from "./Card/BusinessPaymentCard.style";
import { Title3, Title4 } from "../Font";
import { verifyIDcard, verifyPhone } from "../../libs/validator";

import { PrimaryBtn } from "../Button";
import React from "react";
import _ from "lodash";

const KEYS = ["company", "line1", "line2", "city", "state", "postal_code", "country", "phone", "tax_id"];
class BillingAddressIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: props.company || "",
      line1: props.line1 || "",
      line2: props.line2 || "",
      city: props.city || "",
      state: props.state || "",
      postal_code: props.postal_code || "",
      country: props.country || "",
      phone: verifyPhone(props.phone) ? props.phone : "",
      tax_id: verifyIDcard(props.tax_id) ? this.formatID(props.tax_id) : "",
    };
  }

  componentDidMount() {
    let {
      store_language: { dictionary },
    } = this.props;
    this.props.setHeaderTitle(dictionary.add_contact);
  }

  componentDidUpdate(prevProps, prevState) {
    for (let index = 0; index < KEYS.length; index++) {
      const element = KEYS[index];

      if (prevProps[element] !== this.props[element]) {
        const value = this.props[element];
        if (element === "tax_id") this.setState({ [element]: verifyIDcard(value) ? this.formatID(value) : ""});
        else if (element === "phone") this.setState({ [element]: verifyPhone(value) ? value : ""});
        else this.setState({ [element]: this.props[element] });
      }
    }
  }

  formatID = (number) => {
    number = number.toString().replace(/[\W\D\s_]+/g, "");
    let split = [1, 4, 5, 2, 1];
    let chunk = [];

    for (let index = 0, i = 0, len = number.length; i < len; i += split[index], index++) {
      chunk.push(number.substr(i, split[index]));
    }
    return chunk.join(" ");
  };

  onChangeInput(event) {
    let { name, value } = event.target;
    let reg = new RegExp("^[a-zA-Z0-9ก-๙ +/(),.-]+$", "g");
    if (name === "country") value = value || "TH";

    let isMatch = value.match && value.match(reg);
    if (isMatch || value === "") {
      //this.props.onChangeInput({ target: { name, value } });
      this.setState({ [name]: value, [`Err_${name}`]: !isMatch });
    }
  }

  onChangeInputPhone(event) {
    let { name, value } = event.target;
    let reg = new RegExp("^[0-9 +]+$", "g");
    if (name === "country-code") name = "phone";

    let isMatch = value.match && value.match(reg);
    if (isMatch || value === "") {
      //this.props.onChangeInput({ target: { name, value } });
      this.setState({ [name]: value, [`Err_${name}`]: !(isMatch && verifyPhone(value.trim())) });
    }
  }

  onChangeInputID(event) {
    let { name, value } = event.target;
    let fID = this.formatID(value);
    let reg = new RegExp("^[0-9 +]+$", "g");
    let isMatch = value.match && value.match(reg);

    if (isMatch || value === "") {
      //this.props.onChangeInput({ target: { name, value } });
      this.setState({ [name]: fID, [`Err_${name}`]: !(isMatch && verifyIDcard(value && value.trim())) });
    }
  }

  onChangeInputNumber(event) {
    let { name, value } = event.target;
    let reg = new RegExp("^[0-9]+$", "g");
    let isMatch = value.match && value.match(reg);

    if (isMatch || value === "") {
      if (value.length < 6) {
        //this.props.onChangeInput(event);
        this.setState({ [name]: value, [`Err_${name}`]: !isMatch });
      }
    }
  }

  validateField() {
    let isValid = true;
    let reg_NUM = new RegExp("^[0-9]+$", "g");
    let reg_TEXT = new RegExp("^[a-zA-Z0-9ก-๙ +/(),.-]+$", "g");

    let { company, line1, line2, city, state, postal_code, country, phone, tax_id } = this.state;
    let check_TEXT = [company, line1, line2, city, state, country];
    let check_NUM = [postal_code];

    check_NUM.forEach((value) => {
      isValid = isValid && value && value !== "" && value.trim() && value.match && value.match(reg_NUM);
    });
    check_TEXT.forEach((value) => {
      isValid = isValid && value && value !== "" && value.trim() && value.match && value.match(reg_TEXT);
    });

    return isValid && verifyPhone(phone) && verifyIDcard(tax_id);
  }

  setAddress = () => {
    this.props.setAddress(_.pick(this.state, KEYS));
    this.props.nextStep();
  }

  render() {
    let {
      store_language: { dictionary },
    } = this.props;
    let { company, line1, line2, city, state, postal_code, country, phone, tax_id } = this.state;

    return (
      <>
        <ModalBody className="pb-0">
          <TitleBar>
            <Title3 bold>{`${dictionary.contact_invoice} / ${dictionary.contact_receipt}`}</Title3>
          </TitleBar>
          <DescriptionBar>
            <Row>
              <Col xs={7} className="pb-2">
                <TitleInput title={dictionary.company_name} />
                <Input
                  id="bill-company"
                  type="text"
                  name="company"
                  required
                  autoComplete="billing organization"
                  value={company || ""}
                  className="form-control-xs"
                  invalid={this.state.Err_company}
                  placeholder={dictionary.company_name}
                  onChange={this.onChangeInput.bind(this)}
                />
                <ErrorInput dictionary={dictionary} type="text" isError={this.state.Err_company} />
              </Col>
              <Col xs={5} className="pb-2">
                <TitleInput title={dictionary.phone} />
                <Input
                  id="bill-country-code"
                  type="text"
                  name="country-code"
                  required
                  autoComplete="billing tel"
                  value={phone || ""}
                  className="form-control-xs"
                  invalid={this.state.Err_phone}
                  placeholder={dictionary.phone}
                  maxLength="15"
                  onChange={this.onChangeInputPhone.bind(this)}
                />
                <ErrorInput dictionary={dictionary} type="phone" isError={this.state.Err_phone} />
              </Col>
              <Col xs={7} className="pb-2">
                <TitleInput title={dictionary.receipt_tax_id} />
                <Input
                  id="bill-tax_id"
                  type="text"
                  name="tax_id"
                  required
                  autoComplete="billing tax-id"
                  value={tax_id || ""}
                  maxLength="17"
                  className="form-control-xs"
                  invalid={this.state.Err_tax_id}
                  placeholder={dictionary.receipt_tax_id}
                  onChange={this.onChangeInputID.bind(this)}
                />
                <ErrorInput dictionary={dictionary} type="number" isError={this.state.Err_tax_id} />
              </Col>
              <Col xs={12} className="pb-2">
                <TitleInput
                  title={`${dictionary.address} ${dictionary.address_number} ${dictionary.village_number}, ${dictionary.village}, ${dictionary.lane}`}
                />
                <Input
                  id="bill-address-line1"
                  type="text"
                  name="line1"
                  required
                  autoComplete="billing address-line1"
                  value={line1 || ""}
                  className="form-control-xs"
                  invalid={this.state.Err_line1}
                  onChange={this.onChangeInput.bind(this)}
                  placeholder={`${dictionary.address_number} ${dictionary.village_number}, ${dictionary.village}, ${dictionary.lane}`}
                />
                <ErrorInput dictionary={dictionary} type="text" isError={this.state.Err_line1} />
              </Col>
              <Col xs={12} className="pb-2">
                <TitleInput title={`${dictionary.road}, ${dictionary.district}`} />
                <Input
                  id="bill-address-line2"
                  type="text"
                  name="line2"
                  required
                  autoComplete="billing address-line2"
                  value={line2 || ""}
                  className="form-control-xs"
                  invalid={this.state.Err_line2}
                  onChange={this.onChangeInput.bind(this)}
                  placeholder={`${dictionary.road}, ${dictionary.district}`}
                />
                <ErrorInput dictionary={dictionary} type="text" isError={this.state.Err_line2} />
              </Col>
              <Col xs={6} md={6} lg={3} className="pb-2">
                <TitleInput title={dictionary.city} />
                <Input
                  id="bill-city"
                  type="text"
                  name="city"
                  required
                  autoComplete="billing locality"
                  value={city || ""}
                  className="form-control-xs"
                  placeholder={dictionary.city}
                  invalid={this.state.Err_city}
                  onChange={this.onChangeInput.bind(this)}
                />
                <ErrorInput dictionary={dictionary} type="text" isError={this.state.Err_city} />
              </Col>
              <Col xs={6} md={6} lg={3} className="pb-2">
                <TitleInput title={dictionary.state} />
                <Input
                  id="bill-state"
                  type="text"
                  name="state"
                  required
                  autoComplete="billing region"
                  value={state || ""}
                  className="form-control-xs"
                  placeholder={dictionary.state}
                  invalid={this.state.Err_state}
                  onChange={this.onChangeInput.bind(this)}
                />
                <ErrorInput dictionary={dictionary} type="text" isError={this.state.Err_state} />
              </Col>
              <Col xs={12} md={6} lg={3} className="pb-2">
                <TitleInput title={dictionary.postal_code} />
                <Input
                  id="bill-postcode"
                  type="number"
                  name="postal_code"
                  required
                  autoComplete="billing postal-code"
                  value={postal_code || ""}
                  className="form-control-xs"
                  invalid={this.state.Err_postal_code}
                  placeholder={dictionary.postal_code}
                  onChange={this.onChangeInputNumber.bind(this)}
                />
                <ErrorInput dictionary={dictionary} type="number" isError={this.state.Err_postal_code} />
              </Col>
              <Col xs={12} md={6} lg={3} className="pb-2">
                <TitleInput title={dictionary.country} />
                <Input
                  id="bill-country"
                  type="text"
                  name="country"
                  required
                  autoComplete="billing country"
                  value={country || ""}
                  className="form-control-xs"
                  placeholder={dictionary.country}
                  invalid={this.state.Err_country}
                  onChange={this.onChangeInput.bind(this)}
                />
                <ErrorInput dictionary={dictionary} type="text" isError={this.state.Err_country} />
              </Col>
            </Row>
          </DescriptionBar>
        </ModalBody>
        <ModalFooter>
          <PrimaryBtn md bold onClick={this.setAddress} disabled={!this.validateField()}>
            {dictionary.next}
          </PrimaryBtn>
        </ModalFooter>
      </>
    );
  }
}

const TitleInput = ({ title }) => {
  return (
    <div className="pb-1 d-flex align-items-center">
      <Title3 bold danger={title} className="pr-2">
        *
      </Title3>
      <Title3 bold className="w-100">
        {title}
      </Title3>
    </div>
  );
};

const ErrorInput = ({ type, isError, dictionary }) => {
  return (
    <Fade in={Boolean(isError)}>
      <Title4 danger className="mt-1 text-right">
        {type === "text" && dictionary.please_fill_information}
        {type === "phone" && dictionary.please_fill_information_format_phone}
        {type === "number" && dictionary.please_fill_information_number}
      </Title4>
    </Fade>
  );
};

export default BillingAddressIndex;
