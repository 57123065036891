import React from 'react';
import { Button } from 'reactstrap';
import { Title2 } from '../../components/Font';

function ButtonWithLoading(props) {
  return props.isLoading
    ? <Button
        disabled
        color="success"
        className={props.className}>
        <span>
          <Title2 white>
            <i className="fas fa-spinner fa-pulse"></i>
          </Title2>
        </span>
      </Button>
    : <Button
        type='submit'
        color="success"
        onClick={props.onClick}
        disabled={props.disabled}
        className={props.className}>
        {props.children}
      </Button>;
}

export default ButtonWithLoading;