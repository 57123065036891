import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import Loadable from 'react-loadable'
import {
  Nav, NavItem, NavLink,
  TabContent, TabPane
} from 'reactstrap'

import SearchResultPersonal from './SearchResultPersonal'
import { StickyContainer } from '../Transactions.style'

import { Title2 } from '../../../components/Font'
import Loading from '../../../components/Loading'
import { BagdeSuccess } from '../../../components/Sidebar/SidebarContents.style'

import {
  clearProfileSearch,
  getTransactionsWithFilter,
  toggleTransactionPersonal
} from '../../../redux/actions/action_transactions'
import { clearMemberProfile } from '../../../redux/actions/action_transfers'

const TransactionPersonal = Loadable({
  loader: () => import('./TransactionPersonal'),
  loading: Loading,
})

const CouponPersonal = Loadable({
  loader: () => import('./CouponPersonal'),
  loading: Loading,
})

const PersonalMemberCard = Loadable({
  loader: () => import('./PersonalMemberCard'),
  loading: Loading,
})


class TransactionPersonalIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      memberBadgeCode: null,
      search: props.search,
      tabs: props.activeTab || 'member_card',
    }
    this.toggleTabs = this.toggleTabs.bind(this)
  }

  componentDidMount() {
    let { memberBadgeCode } = this.props
    let { match: { params } } = this.props
    if (!Boolean(params && params.search) && !memberBadgeCode)
      return this.props.history.push('/transactions')

    if (params.search)
      this.props.toggleTransactionPersonal(true)

    this.setState({ memberBadgeCode: params.search || memberBadgeCode })
  }

  componentWillUnmount() {
    this.props.toggleTransactionPersonal(false)
    let { search } = this.props
    let page_not_clear = ['/send', '/pull', '/giftvoucher', '/send/' + search, '/pull/' + search]
    if (this.props.location && page_not_clear.indexOf(this.props.location.pathname) < 0)
      this.props.clearMemberProfile()
  }

  toggleTabs(tabs) {
    if (this.state.tabs === tabs) return
    this.setState({ tabs: tabs })
  }

  render() {
    let { store_language: { dictionary } } = this.props
    let { hiddenTab, isModal } = this.props

    let menu = [{
      id: 'member_card',
      btn_id: 'btn-tab-member-card',
      name: dictionary.member_card,
      component: <PersonalMemberCard memberBadgeCode={this.state.memberBadgeCode} />
    }, {
      id: 'coupon_member',
      btn_id: 'btn-tab-member-coupon',
      name: dictionary.ecoupon,
      component: <CouponPersonal memberBadgeCode={this.state.memberBadgeCode} isModal={isModal} />
    }, {
      id: 'transactions',
      btn_id: 'btn-tab-member-transactions',
      name: dictionary.history_transactions,
      component: <TransactionPersonal memberBadgeCode={this.state.memberBadgeCode} isModal={isModal} />
    }]

    if (!Boolean(this.state.memberBadgeCode))
      return null

    return <div>
      <StickyContainer>
        <SearchResultPersonal memberBadgeCode={this.state.memberBadgeCode} hiddenCloseBtn={isModal} />
        <div>
          <Nav tabs className='pt-2 px-3'>
            {
              menu
                .filter(f => (hiddenTab && hiddenTab.length > 0) ? hiddenTab.indexOf(f.id) < 0 : true)
                .map((value) => {
                  let active = this.state.tabs === value.id
                  return <NavItem key={`NavItem_${value.id}`}>
                    <NavLink
                      id={value.btn_id}
                      className={`${active ? 'active' : ''} `}
                      onClick={() => this.toggleTabs(value.id)}
                      style={{
                        cursor: 'pointer',
                        border: active ? '' : '1px solid #DEE2E6',
                        backgroundColor: active ? '' : '#F5F5F5',
                      }}>
                      <div className='d-flex align-items-center justify-content-center'>
                        <Title2 bold={active}>{value.name}</Title2>
                        {
                          value.new && <div className='ml-2'><BagdeSuccess>New</BagdeSuccess></div>
                        }
                      </div>
                    </NavLink>
                  </NavItem>
                })
            }
          </Nav>
        </div>
        <TabContent activeTab={this.state.tabs}>
          {
            menu.map(value => <TabPane key={`TabPane_${value.id}`} tabId={value.id}>
              {this.state.tabs === value.id && value.component}
            </TabPane>
            )
          }
        </TabContent>
      </StickyContainer>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language,
    store_permission: state.permission,
    store_transactions: state.transactions,
    store_custom_form: state.custom_form
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    clearProfileSearch,
    getTransactionsWithFilter,
    toggleTransactionPersonal,
    clearMemberProfile,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionPersonalIndex))