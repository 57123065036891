import React from 'react'
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap'
import { Title2 } from '../Font'
import { ModalBodyBox } from './BusinessPackage.style'
import PackagesDetail from "./PackagesDetail";

class BusinessPackage extends React.Component {
  render() {
    let { store_language: { dictionary } } = this.props
    let { packageItems, isOpen, toggle } = this.props

    return (
      <>
        <Modal centered size={packageItems.length < 3 ? 'lg' : 'xl'} fade={true} isOpen={isOpen}>
          <ModalBody className='p-0 m-0'>
            <div className='pt-3 pb-3 pr-4 pl-4 border-bottom d-flex align-items-center justify-content-between'>
              <Title2 bold>{dictionary.pricing_package}</Title2>
              <Title2 link onClick={toggle}><i className='fas fa-times' /></Title2>
            </div>
            <ModalBodyBox>
              <PackagesDetail {...this.props} />
            </ModalBodyBox>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store_language: state.language,
  }
}

export default connect(mapStateToProps)(BusinessPackage)