import React from 'react';
import { Spinner } from 'reactstrap';

function Loading({ type, size = '3.2rem', margin = '30px', padding = '10px' }) {
    return (
        <div className='d-flex justify-content-center align-items-center' style={{ padding, margin }}>
            <Spinner style={{ width: size, height: size, color: '#546174' }} type={type} />
        </div>
    );
}

export default Loading;