import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Input } from 'reactstrap'
import { Title3 } from '../../Font'
import { usePrevious } from '../../../libs/hooks/usePrevious'

export default function RangePointGreaterEqual({
	min,
	onChangePoint,
	onSetInvalid,
	isInvalid,
	start = '',
	end = '',
	disabled
}) {
	const setMin = min || 0
	const pointEnd = end
	const [pointStart, setPointStart] = useState(start)
	const pointStartPrevious = usePrevious(pointStart)
	const dictionary = useSelector((state) => state.language.dictionary)
	const isMounted = useRef()

	useEffect(() => {
		if (
			isMounted.current &&
			JSON.stringify(pointStartPrevious) !== JSON.stringify(pointStart)
		) {
			onChangePoint({
				start: pointStart,
				end: pointEnd
			});
		}

		if (!isMounted.current) {
			isMounted.current = true
			onChangePoint({
				start: pointStart,
				end: pointEnd
			})
		}
		
	}, [onChangePoint, pointStart, pointStartPrevious, pointEnd])

	const handleChange = (e) => {
		let { value } = e.target

		value = parseInt(value)

		if (value < 0) value = setMin
		if (isNaN(value)) value = ''

		onSetInvalid(false)

		if ( value === '' ) {
			onSetInvalid(true)
		}

		setPointStart(() => {
			const start = value.toString().replace('/^0+', '')
			return start
		})

	}

	return (
		<>
			<Col xs={7} sm={5} className='p-0'>
				<Input
					type="number"
					id="point_greater"
					name='point_greater'
					disabled={disabled}
					value={pointStart}
					cssModule={{ 'is-invalid': 'invalid-input-point' }}
					placeholder='0'
					invalid={isInvalid}
					onChange={handleChange} >
				</Input>
			</Col>
			<Col xs={2} sm={2} className='align-self-center p-0 pl-2'>
				<Title3>{dictionary.points.toLowerCase()}</Title3>
			</Col>
			{
				isInvalid &&
				<Col xs={10} sm={10} className='p-0'>
					<Title3 className='text-danger p-0 pl-1 pt-2'>{dictionary.points_invalid}</Title3>
				</Col>
			}
		</>
	)
}