import axios from './../axios_request';

import { businessAPI } from '../end_point'
import { PAYMENT } from '../actions_const'
import { getError } from '../../libs/my2c2p';
import { getCookie } from '../../libs/cookies';

export const clearPayment = () => {
  return dispatch => {
    return dispatch({ type: PAYMENT.CLEAR_PATMENT })
  }
}

export const getCustomerPayment = (business_code, call_back) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/payment`,
      headers: { Authorization: getCookie("_token") }
    }).then(response => {
      let paymentData = response.data.data
      if (typeof call_back === 'function') call_back(null, paymentData)
      return dispatch({ type: PAYMENT.GET_CUSTOMER_PAYMENT, data: paymentData })
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
      //return dispatch({ type: PAYMENT.GET_CUSTOMER_PAYMENT, data: {} })
    })
  }
}

export const createCustomerPayment = (business_code, call_back) => {
  return dispatch => {
    dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: true })
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/payment`,
      headers: { Authorization: getCookie("_token") }
    }).then((response) => {
      if (typeof call_back === 'function') call_back(null, response.data)
      return dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: false })
    }).catch((err) => {
      if (typeof call_back === 'function') call_back(err)
      return dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: false })
    })
  }
}

export const getCustomerPaymentCreditCard = (business_code, call_back) => {
  return dispatch => {
    axios({
      method: 'get',
      url: `${businessAPI}/${business_code}/payment/card`,
      headers: { Authorization: getCookie("_token") }
    }).then(response => {
      let cardData = response.data.data
      if (typeof call_back === 'function') call_back(null, response)
      return dispatch({ type: PAYMENT.GET_CUSTOMER_PAYMENT_CARD, data: cardData })
    }).catch(err => {
      if (typeof call_back === 'function') call_back(err)
    })
  }
}

export const createCustomerPaymentCreditCard = (business_code, card, callback) => {
  return dispatch => {
    axios({
      method: 'post',
      url: `${businessAPI}/${business_code}/payment/card`,
      headers: { Authorization: getCookie("_token") },
      data: { card: card }
    }).then(response => {
      if (response.data.result) {
        if (typeof callback === 'function') callback(null, response.data)
        // dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: true })
        // setTimeout(() => {
        //   return dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: false })
        // }, 1500)
      } else {
        let { code } = response.data.error.internal_code
        if (typeof callback === 'function') callback(getError(code))
      }
    }).catch(err => {
      if (typeof callback === 'function') callback(getError())
    })
  }
}

export const updateCustomerPaymentCreditCard = (business_code, card_id, callback) => {
  return dispatch => {
    dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: true })
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/payment/card`,
      headers: { Authorization: getCookie("_token") },
      data: { card_id: card_id }
    }).then(response => {
      if (typeof callback === 'function') callback(null, response.data)
      return dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: false })
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
      return dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: false })
    })
  }
}

export const deleteCustomerPaymentCreditCard = (business_code, card_id, callback) => {
  return dispatch => {
    axios({
      method: 'delete',
      url: `${businessAPI}/${business_code}/payment/card`,
      headers: { Authorization: getCookie("_token") },
      data: { card_id: card_id }
    }).then(response => {
      if (typeof callback === 'function') callback(null, response.data)
      dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: true })
      setTimeout(() => {
        return dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: false })
      }, 1500)
    }).catch(err => {
      if (typeof callback === 'function') callback(err)
    })
  }
}

export const updateSubscriptionPaymentInfo = (business_code, address, card_id, subscription_id, callback) => {
  return dispatch => {
    axios({
      method: 'put',
      url: `${businessAPI}/${business_code}/payment/info`,
      headers: { Authorization: getCookie("_token") },
      data: { address, card_id, subscription_id }
    }).then(response => {
      if (typeof callback === 'function') callback(null, response.data)
      dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: true })
      setTimeout(() => {
        return dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: false })
      }, 1500)
    }).catch(err => {
      dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: false })
      if (typeof callback === 'function') callback(err)
    })
  }
}