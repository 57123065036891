import React from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  SidebarContainer,
  SidebarRow,
  LogoutBtnSection,
  SidebarMenuItem,
  SidebarMenuItem2,
  SidebarSubmenuContainer,
  SidebarSubMenuItem,
  SidebarTitleItems,
  SidebarIconCaret,
  SidebarIcon,
  SidebarRow2,
  BadgePro,
  BagdeSuccess,
  BadgeNoti,
  BadgeVerify,
  SidebarSubMenuGroupLabel
} from './SidebarContents.style'

import logo from "./../../assets/img/logo.svg"

import { Title3 } from '../Font'
// import PointsBoard from '../PointsBoard'
import BusinessProfile from './BusinessProfile'
import BusinessPackage from '../BusinessPackage'
import BusinessUsage from '../BusinessUsage'

import { icon } from '../../libs/icon.js'
import { DEBUG } from './../../libs/utils'

import { toggleSidebar } from '../../redux/actions/action_sidebar'
import { getUsageRecord } from '../../redux/actions/action_usage'
import { getBusinessInfo } from '../../redux/actions/action_business'
import { resetDataRFM } from '../../redux/actions/action_rfm'
// import BusinessCredits from '../BusinessCredits'


class SidebarContents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentMenu: false
    }
  }

  componentDidMount() {
    let { menu } = this.props
    // 
    // let result = menu.filter(val => val.submenu && val.submenu.filter(val2 => val2.href === pathname).length > 0)
    let result = menu.filter(val => val.show)

    if (result.length > 0)
      this.setState({ currentMenu: result[0].name })
  }

  render() {
    let { menu } = this.props

    let { store_config } = this.props
    let { store_business } = this.props
    let { store_packages: { packages } } = this.props

    return this.state.currentMenu &&
      <SidebarContainer>
        <SidebarRow className="pt-2 pb-2 border-0">
          <img className="logo img img-fluid" src={logo} alt="Pointspot text logo" />
        </SidebarRow>
        <SidebarRow>
          <BusinessProfile />
        </SidebarRow>

        <SidebarRow2>
          <SidebarRow>
            {
              packages.package_data && (packages.package_data.package_id === 'ps-free-trial' || packages.package_data.package_id === 'ps-free-trial-2023') &&
              <SidebarRow>
                <BusinessUsage />
              </SidebarRow>
            }
            {/* {
              is_use_credit &&
              <SidebarRow>
                <BusinessCredits />
              </SidebarRow>
            }
            <SidebarRow>
              <PointsBoard />
            </SidebarRow> */}
            <SidebarRow>
              {
                store_business.list.length > 0 &&
                menu.map((val, key) => <SidebarMenu key={key} {...val} {...this.props} {...this.state} />)
              }
            </SidebarRow>
          </SidebarRow>
        </SidebarRow2>

        <SidebarRow>
          <BusinessPackage {...this.props} />
          <LogoutBtnSection>
            <div className="d-flex justify-content-between">
              <Title3 className="m-1">{store_config.application.name}</Title3>
              {/* <Link to={'/dashboard'} onClick={this.props.toggleSidebar}><Title3 className="m-1">&nbsp;&nbsp;&nbsp;</Title3></Link> */}
              <Title3 className="m-1">v.{store_config.application.version}</Title3>
            </div>
          </LogoutBtnSection>
        </SidebarRow>
      </SidebarContainer>
  }
}

class SidebarMenu extends React.Component {
  getMenuPermission(menu_name) {
    let { store_business } = this.props
    let business_permission = store_business.permission;

    if (!(business_permission && business_permission[menu_name]))
      return undefined;

    let permission_result = (business_permission && business_permission[menu_name] && business_permission[menu_name].permission_view === true);
    if (permission_result === undefined)
      DEBUG('Main menu permission view', menu_name, permission_result);

    return permission_result;
  }
  toggleSidebarMenu = () => {
    if (window.innerWidth < 992)
      this.props.toggleSidebar()
  }

  render() {
    let { location: { pathname } } = this.props
    let { submenu, href, show, name, currentMenu, icon, title } = this.props
    let { advanced } = this.props

    let has_submenu = (submenu && submenu.length) || 0
    const setPointerNone = href === pathname ? { pointerEvents: 'none'} : {}
    
    return !show || !this.getMenuPermission(name)
      ? null
      : has_submenu > 0
        ? <SidebarWithSubMenu {...this.props} isExpand={currentMenu === name} />
        : <Link to={href} style={{ textDecoration: 'none', ...setPointerNone }}>
          <SidebarMenuItem2 onClick={this.toggleSidebarMenu} actived={advanced} selected={href === pathname}>
            <div>
              <SidebarIcon>
                {icon && <i className={icon} />}
              </SidebarIcon>
              <SidebarTitleItems>{title}</SidebarTitleItems>
            </div>
            <BadgeStatus value={this.props} />
          </SidebarMenuItem2>
        </Link>
  }
}

class SidebarWithSubMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isExpand: this.props.isExpand || false,
    }
  }

  onClickMenu({ name }) {
    let { store_sidebar: { toggle } } = this.props
    let { store_business: { current } } = this.props
    let { store_usage: { usage_record } } = this.props

    let msTimeDelay = 3 * 1000
    let updataUsagedate = new Date(usage_record.create_date).getTime() || new Date().getTime()

    let key_menu_key = ['points-batchjob', 'setting-ecoupon', 'report-recency', 'giftvoucher-ecoupon']
    if (key_menu_key.indexOf(name) > -1 && (new Date().getTime() - updataUsagedate) > msTimeDelay) {
      this.props.getUsageRecord(current.business_code)
    }
      
    this.props.getBusinessInfo(current.business_code, true)

    if (window.innerWidth < 992 && toggle) {
      this.props.toggleSidebar()
    }
    if (name === 'report-RFM-analysis'){
      this.props.resetDataRFM();
    }
  }

  toggle() {
    this.setState({ isExpand: !this.state.isExpand })
  }

  componentDidMount() {
    let { submenu, location: { pathname } } = this.props
    let _pathname = pathname.split('/').filter(v => v)[0]
    let _check = submenu.filter(f => {
      let href = f.href && f.href.split('/').filter(v => v)[0]
      return href === _pathname
    })
    let isMenu = _check.length > 0 ? _check[0].name : ''
    let expand = _check.length > 0
    this.setState({ isExpand: expand })
    this.sidebarScrollIntoView(isMenu)
  }

  componentDidUpdate(prevProps) {
    let { isExpand } = this.state
    let { submenu, location: { pathname } } = this.props
    let { store_tutorial: { isOpenTutorial } } = this.props

    let isUpdate_receivePathname = prevProps.location.pathname !== pathname
    let isUpdate_receiveTutorial = prevProps.store_tutorial.isOpenTutorial !== isOpenTutorial

    if (isUpdate_receivePathname || isUpdate_receiveTutorial) {
      let _pathname = pathname.split('/').filter(v => v)[0]
      let _check = submenu.filter(f => {
        let href = f.href && f.href.split('/').filter(v => v)[0]
        return href === _pathname
      })

      let isMenu = _check.length > 0 ? _check[0].name : ''
      let expand = isOpenTutorial
        ? isOpenTutorial
        : _check.length > 0 || isExpand

      this.setState({ isExpand: expand })
      this.sidebarScrollIntoView(isMenu)
    }
  }

  sidebarScrollIntoView(name) {
    let { store_tutorial: { isOpenTutorial } } = this.props

    if (!Boolean(name) || !isOpenTutorial)
      return null

    if (name) {
      let elementName = window.document.querySelector(`#${name}`)
      if (elementName)
        elementName.scrollIntoView({ behavior: "auto", block: "end" });
    }
  }

  getSubMenuLength(subMenu) {
    return subMenu.filter(value => value.show && this.getMenuPermission(value.name)).length || 0
  }

  getMenuPermission(menu_name) {
    let permission_result = true;
    const business_permission = this.props.store_business.permission;
    //DEBUG("business_permission", business_permission)
    permission_result = (business_permission && business_permission[menu_name] && business_permission[menu_name].permission_view === true);
    //if (permission_result === undefined) DEBUG('Submenu permission view', menu_name, permission_result);
    return permission_result;
  }

  render() {
    let { store_packages: { packages } } = this.props
    let { store_language: { dictionary } } = this.props
    let { location: { pathname } } = this.props
    let _pathname = pathname.split('/').filter(v => v)[0]

    let menu_banned = (packages.package_data && packages.package_data.menu_banned) || []
    return <div>
      {
        (this.props.show && this.getSubMenuLength(this.props.submenu) > 0)
          ? <SidebarMenuItem disable={this.props.disable} onClick={this.toggle.bind(this)}>
            <SidebarTitleItems>{this.props.title}</SidebarTitleItems>
            <SidebarIconCaret toggle={this.state.isExpand}>
              <Title3 className='text-center'><i className={icon.chevron_down} /></Title3>
            </SidebarIconCaret>
          </SidebarMenuItem>
          : null
      }
      {
        (this.props.submenu.length > 0 && this.getSubMenuLength(this.props.submenu) > 0)
          ? <SidebarSubmenuContainer toggle={this.state.isExpand} totalChild={this.getSubMenuLength(this.props.submenu)}>
            {
              this.props.submenu.filter(sm => (this.getMenuPermission.bind(this)(sm.name) || sm.label))
                .map((val, key) => {
                  let href = val.href && val.href.split('/').filter(v => v)[0]
                  let linkTo = val.disable ? 'verify'
                    : (val.lock || (menu_banned.filter(v => v === val.name).length > 0))
                      ? 'packages' : val.href
                  if (val.label) {
                    return <SidebarSubMenuGroupLabel id={val.name} key={key}>
                      <SidebarTitleItems sm>{val.title}</SidebarTitleItems>
                    </SidebarSubMenuGroupLabel>
                  } else {
                    const setPointerNone =  _pathname === href ? { pointerEvents: 'none'} : {}
                    return val.show && this.getMenuPermission.bind(this)(val.name)
                    ? <Link to={linkTo} key={`${key}_${val.name}`} style={{ textDecoration: 'none', ...setPointerNone }} onClick={() => { this.onClickMenu(val) }}>
                      <SidebarSubMenuItem
                        id={val.name}
                        disable={val.lock}
                        active={_pathname === href}
                        data-tut={Boolean(val['data-tut']) ? val['data-tut'] : ''}>
                        <div className="d-flex overflow-hidden align-items-baseline">
                          {
                            val.icon && <SidebarIcon><i className={val.icon} /></SidebarIcon>
                          }
                          <SidebarTitleItems sm>{val.title}</SidebarTitleItems>
                        </div>
                        <BadgeStatus value={val} menu_banned={menu_banned} dictionary={dictionary} />
                      </SidebarSubMenuItem>
                    </Link>
                    : null
                  }
                })
            }
          </SidebarSubmenuContainer>
          : null
      }
    </div>
  }
}

const BadgeStatus = ({ value, menu_banned, dictionary }) => {
  if (value.lock)
    return <i className="p-1 fas fa-lock" style={{ fontSize: '11px', color: '#FF4444' }} />

  if (value.disable)
    return <BadgeVerify className="d-flex badge align-items-center">{dictionary.need_verify}</BadgeVerify>

  if (menu_banned && menu_banned.filter(v => v === value.name).length)
    return <BadgePro className="d-flex badge align-items-center">{dictionary.upgrade}</BadgePro>

  if (value.badge)
    return <BadgeNoti>{value.badge}</BadgeNoti>

  if (value.new)
    return <BagdeSuccess>{dictionary.new}</BagdeSuccess>

  return null
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_business: state.business,
    store_config: state.config,
    store_language: state.language,
    store_packages: state.packages,
    store_usage: state.usage,
    store_tutorial: state.tutorial,
    store_sidebar: state.sidebar,

  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    toggleSidebar,
    getUsageRecord,
    getBusinessInfo,
    resetDataRFM,
  }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(SidebarContents)