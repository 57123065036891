import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom'
import { Card, CardBody, Input } from "reactstrap";
import { Title2, Title3 } from "../../../components/Font";
import Loading from "../../../components/Loading";
import MemberCard from "../../../components/MemberCard";
import { DEBUG } from "../../../libs/utils";
import { getMemberCardInfo, updateAutomationMemberCardPromotionConfig } from "../../../redux/actions/action_business";
import { InvertBtn, SuccessBtn } from '../../../components/Button'
import { toCurrency } from "../../../libs/currency";
import ConfirmModal from "./ConfirmModal";

export default function PromotionSetting({ dictionary, permission, business, toastShow }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const isInit = useRef(false);
  const memberCardList = useSelector((state) => state.business.member_card_info);
  const [isUpdate, setIsUpdate] = useState(false);
  const [emptyCard, setEmptyCard] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const typeOptions = [
    {
      title: 'past_1_month',
      value: '1_month'
    },
    {
      title: 'past_3_month',
      value: '3_month'
    },
    {
      title: 'past_6_month',
      value: '6_month'
    },
    {
      title: 'past_12_month',
      value: '12_month'
    },
  ];

  const [isLoading, setLoading] = useState(false);
  const [selectedType, setType] = useState(business.member_card_automation_promotion_type || typeOptions[0].value);
  const [pointList, setPointList] = useState({});

  //DEBUG("[LevelUpSetting]", memberCardList, pointList, business);

  useEffect(() => {
    DEBUG("[LevelUpSetting] memberCardList", memberCardList);
    if (!isInit.current) {
      isInit.current = true;
      dispatch(getMemberCardInfo(business.business_code, (err, data) => {
        if (err) {
          toastShow('error', dictionary.error_occurred);
        }
      }));
    } else if (_.isEmpty(memberCardList)) {
      setEmptyCard(true);
    } else {
      const list = _.reduce(memberCardList, (result, value) => {
        result[value.card_id] = value.automation_earned_point;
        return result;
      }, {});
      setIsUpdate(_.some(list, Number));
      setPointList(list);
    }
  }, [memberCardList]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPointLevelChange = (event) => {
    const { value, name } = event.target;

    if (!isNaN(parseInt(value)) && value === 0) {
      return;
    }

    setPointList({
      ...pointList,
      [name]: value
    });
  };

  const gotoMemberCard = () => {
    history.push("/memberCard");
  };

  const onCancel = () => {
    setType(business.member_card_automation_promotion_type || typeOptions[0].value);
    setPointList(_.reduce(memberCardList, (result, value) => {
      result[value.card_id] = value.automation_earned_point;
      return result;
    }, {}));
  };

  const onSave = () => {
    DEBUG("[LevelUpSetting] onSave", selectedType, pointList);
    setShowConfirm(false);
    let isError = false;
    _.forEach(memberCardList, (item, index) => {
      const prevItem = (index === 0) ? null : memberCardList[index - 1];
      const currentPoint = pointList[item.card_id];
      const requiredPoint = prevItem ? (pointList[prevItem.card_id] ? parseInt(pointList[prevItem.card_id]) : 1) : 0;
      isError = !currentPoint || currentPoint <= requiredPoint; 
      DEBUG("check point", item.card_id, prevItem, currentPoint, requiredPoint, isError);
      if (isError) {
        toastShow('error', String.format(dictionary.member_card_earned_point_error_required, item.card_name, requiredPoint));
        return false;
      }
    });

    if (!isError) {
      setLoading(true);
      dispatch(updateAutomationMemberCardPromotionConfig(business.business_code, selectedType, pointList, (err, data) => {
        setLoading(false);
        if (err) {
          toastShow("error", dictionary.error_occurred);
        } else {
          toastShow('success', dictionary.member_card_automation_status_success);
        }
      }));
    }
  };

  return (
    <Card>
      <CardBody className="border-bottom">
        <Title2 bold>{dictionary.member_card_promotion_setting}</Title2>
        <Title3 secondary>{dictionary.member_card_promotion_setting_description}</Title3>
      </CardBody>
      {
        emptyCard ? (
          <CardBody>
            <Title2 className="my-2" center>{dictionary.member_card_not_available}</Title2>
            <Title3 center blue link bold onClick={!permission ? null : gotoMemberCard}>{dictionary.member_card_not_available_click}</Title3>
          </CardBody>
        ) : (_.isEmpty(memberCardList) || _.isEmpty(pointList)) ? (
          <Loading />
        ) : (
          <>
            <CardBody>
              <Title2 bold className="mb-3">{dictionary.member_card_promotion_setting_time}</Title2>
              <div className="col-lg-4">
                <Input type="select"
                  name="calculation-range"
                  className="form-control"
                  value={selectedType}
                  disabled={!permission}
                  onChange={(event) => setType(_.get(event, ['target', 'value']) || typeOptions[0].value)}>
                  {
                    typeOptions.map((item, index) => (
                      <option key={index} value={item.value}>{dictionary[item.title]}</option>
                    ))
                  }
                </Input>
              </div>
              <Title2 bold className="mt-4">{dictionary.member_card_earned_point}</Title2>
              <div className="d-flex flex-md-row mt-4 flex-wrap align-items-end justify-content-center">
                {
                  memberCardList.map((item, index) => {
                    const nextItem = (index === (_.size(memberCardList) - 1)) ? null : memberCardList[index + 1];
                    let pointRange = '';
                    if (nextItem) {
                      const startPoint = pointList[item.card_id] ? toCurrency(pointList[item.card_id]) : "?";
                      const endPoint = pointList[nextItem.card_id] ? toCurrency(pointList[nextItem.card_id] - 1) : "?";
                      if (startPoint === endPoint) {
                        pointRange = String.format(
                          dictionary.member_card_earned_point_specific,
                          pointList[item.card_id] ? toCurrency(pointList[item.card_id]) : "?");
                      } else {
                        pointRange = String.format(
                          dictionary.member_card_earned_point_range,
                          pointList[item.card_id] ? toCurrency(pointList[item.card_id]) : "?",
                          pointList[nextItem.card_id] ? toCurrency(pointList[nextItem.card_id] - 1) : "?");
                      }
                    } else {
                      pointRange = String.format(
                        dictionary.member_card_earned_point_range_last,
                        pointList[item.card_id] ? toCurrency(pointList[item.card_id]) : "?");
                    }

                    return (
                      <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                        <Title2 center>{item.card_name}</Title2>
                        <Input
                          id={item.card_id}
                          name={item.card_id}
                          onChange={onPointLevelChange}
                          value={pointList[item.card_id] || ''}
                          type="number"
                          className="form-control"
                          style={{ textAlign: 'right', maxWidth: 168, margin: '8px auto 0' }}
                          min={1}
                          disabled={!permission}
                          placeholder="1"></Input>
                        <Title3 className="mt-3" secondary center>{pointRange}</Title3>
                        <div className="d-flex justify-content-center"
                          style={{ transform: 'scale(0.75)', marginTop: -32 }}>
                          <MemberCard
                            cardName={item.card_name}
                            cardPrivilege={item.card_privilege}
                            cardPrimaryColor={item.card_style.primary}
                            cardFontColor={item.card_style.font}
                            memberBadgeCode='123456'
                            background_image={item.background_image}
                          />
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </CardBody>
            <CardBody className="border-top">
              <div className="d-flex justify-content-between align-items-center">
                <Title3 secondary>{isUpdate ? dictionary.member_card_promotion_edit_remark : dictionary.member_card_promotion_remark}</Title3>
                <div className="d-flex">
                  <InvertBtn onClick={onCancel}>
                    {dictionary.cancel}
                  </InvertBtn>
                  <SuccessBtn id={`btn-save`} disabled={isLoading || !permission} onClick={() => setShowConfirm(true)}>
                    {
                      isLoading ? <i className='fas fa-spinner fa-spin' /> : <Title2 white>{dictionary.save}</Title2>
                    }
                  </SuccessBtn>
                </div>
              </div>
            </CardBody>
          </>
        )
      }
      {
        showConfirm && <ConfirmModal 
          isOpen={showConfirm} 
          toggle={() => setShowConfirm(!showConfirm)}
          dictionary={dictionary}
          onConfirm={onSave}
        />
      }
    </Card>
  )
}