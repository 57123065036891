import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTargetPreMemberTotal, setTargetMemberTotal } from '../../../redux/actions/action_broadcast';
import { toastError } from '../../ToastComponent/ToastError';

export default function TargetPreMember({ noRequest }) {
	const businessCode = useSelector((state) => state.business.current.business_code)
	const dictionary = useSelector((state) => state.language.dictionary)
	const dispatch = useDispatch()
	const isMounted = useRef()

	useEffect(() => {
		isMounted.current = true

		const dispatchGetTargetPreMemberTotal = () => {
			dispatch(getTargetPreMemberTotal(businessCode, (err, data) => {
				if (!isMounted.current) return null
				if (err) toastError(dictionary.error)
			}))
		}
		
		if (isMounted.current) {
			if (!noRequest) {
				dispatchGetTargetPreMemberTotal()
			} else {
				dispatch(setTargetMemberTotal({
					target: 'member_pending_group'
				}))
			}
		}

		return () => {
			isMounted.current = undefined
		}
	}, [dispatch, businessCode, noRequest, dictionary])

	return (
		<></>
	)
}