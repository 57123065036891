import React from "react";
import styled from 'styled-components'

export default function Loading() {
  return <Container>
    <i className="fas fa-spinner fa-pulse" />
  </Container>
}

export const Container = styled.div`
  display: flex;
  padding: 24px;
  justify-content: center;
  font-size: 24px;
  color: #ccc;
`