import csv from 'csvtojson'

export const csvToJson = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.addEventListener(
      'loadend',
      () => resolve(csv().fromString(reader.result))
    )
    reader.readAsText(file)
  })
}
export const jsonToCsv = (data, fileName) => {
  let a = document.createElement("a")
  document.body.appendChild(a)
  let blob = new Blob(data, { type: "octet/stream" })
  a.href = window.URL.createObjectURL(blob)
  a.download = fileName
  a.click()
  window.URL.revokeObjectURL(a.href)
  a.remove()
}
