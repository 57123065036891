import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Input, Fade } from 'reactstrap'

import PointSpotLogo from '../../assets/img/logo.svg'

import Loading from '../../components/Loading'
import { Title1, Title3 } from '../../components/Font'
import { PrimaryBtn } from '../../components/Button'

import {
  WizardBox, WizardBoxDetail, WizardImageLogo
} from './wizard.style'

import { initWalletOwner, createBusiness } from '../../redux/actions/action_wizard'

class wizardContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isLoadingInitWallet: false,
      business_name: '',
      error_business_name: null,
    }

    this.onChangeInput = this.onChangeInput.bind(this)
    this.onClickCreateBusiness = this.onClickCreateBusiness.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    this.setState({ isLoadingInitWallet: true }, () => {
      this.props.initWalletOwner((err, data) => {
        if (this._isMounted)
          this.setState({ isLoadingInitWallet: false })
      })
    })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onChangeInput(event) {
    let { name, value } = event.target

    if (value.length <= 80) {
      let error = value.trim().length ? !(value.trim().length >= 5) : null
      this.setState({ [name]: value, error_business_name: error })
    }
  }

  onClickCreateBusiness() {
    let { business_name } = this.state
    let { store_wizard } = this.props
    let business = {
      owner_id: store_wizard.ownerId,
      business_name: business_name,
      signin_create: true,
      business_code: store_wizard.business_code
    }

    this.setState({ isLoading: true })
    this.props.createBusiness(business)
  }

  render() {
    let { business_name, error_business_name } = this.state
    let { store_language: { dictionary } } = this.props

    return <div className='px-3 d-flex flex-column align-items-center justify-content-center'>
      <WizardImageLogo alt='' src={PointSpotLogo} />
      <Title1 bold className='mx-2'>{dictionary.enter_information_create_business}</Title1>
      <WizardBox>
        <WizardBoxDetail>
          {
            this.state.isLoading
              ? <Loading size={'3rem'} margin={'0px'} padding={'0px'} />
              : <>
                <div className='w-100'>
                  <div className='w-100 pb-2 d-flex align-items-center justify-content-between'>
                    <div className='d-flex align-items-center justify-content-center'>
                      <Title1 bold danger className='pr-2'>*</Title1>
                      <Title1 bold>{dictionary.your_business_brand}</Title1>
                    </div>
                    <Title3 secondary>{`${business_name.trim().length} / 80 (${dictionary.recommend} 40)`}</Title3>
                  </div>
                  <Input
                    type='text'
                    className='w-100'
                    name='business_name'
                    value={business_name}
                    onChange={this.onChangeInput}
                    invalid={error_business_name || false}
                    placeholder={dictionary.business_brand} />
                  <Fade in={error_business_name} className="mt-2">
                    <Title3 danger>{dictionary.please_enter_your_business}</Title3>
                  </Fade>
                </div>
                <PrimaryBtn id='btn-save-business' bold fluid
                  onClick={this.onClickCreateBusiness}
                  disabled={business_name.trim().length < 5}>
                  {dictionary.create_business}
                </PrimaryBtn>
              </>
          }
          {
            this.state.isLoadingInitWallet
              ? <Title3 danger><i className='fas fa-spinner fa-pulse' /> {dictionary.busines_wallet_creating}</Title3>
              : null
          }
        </WizardBoxDetail>
      </WizardBox>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    store_wizard: state.wizard,
    store_language: state.language,
    store_business: state.business
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    initWalletOwner,
    createBusiness,
  }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(wizardContainer)