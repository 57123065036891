import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Loading from '../Loading'
import { getBusinessList } from '../../redux/actions/action_business'

class index extends React.Component {
  componentDidMount() {
    this.props.getBusinessList()
  }

  render() {
    let { store_business } = this.props
    return store_business.isLoading
      ? <Loading />
      : this.props.children
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getBusinessList
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(index)
