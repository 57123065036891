import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import _ from 'lodash'
import moment from 'moment'
import { nl2br } from '../../libs/nl2br'
import { toCurrency } from '../../libs/currency'

import { getUsageRecord } from './../../redux/actions/action_usage'
import { createCustomerPayment } from '../../redux/actions/action_payment'
import { getBusinessSubscription, cancelBusinessSubscription, getBusinessPackage } from '../../redux/actions/action_package'
import { checkLogin } from '../../redux/actions/action_permission'

import { DangerBtn, InvertBtn } from '../Button'
import { Title1, Title2, Title3, Title4 } from '../Font'
import Loading from '../Loading'
import BusinessSubscriptionDetail from './SubscriptionDetail/BusinessSubscriptionDetail'
import PaymentAgreement from '../BusinessPayment/PaymentAgreement'
import ContactUsModal from './ContactUsModal'
import UsageChart from './SubscriptionDetail/Components/usageChart'
import BusinessUsageDetail from './SubscriptionDetail/BusinessUsageDetail'

import { ToastBar } from './../ToastComponent/Toast.style'
import { BoxModalBody, DropdownCardBox, PackageLabel, RemarkBox } from './BusinessPackage.style'

// import BusinessSubscriptionDetailUsage from './SubscriptionDetail/BusinessSubscriptionUsage'


class BusinessSubscription extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      isLoadingCreateTKN: false,
      isModalUnsub: false,
      isLoadingUnsub: false,
      isOpenContact: false,
      isOpenAnnualOptions: false,
      isLoadingCard: false,
      error: ''
    }
    this.getAmuntSubscriptionItems = this.getAmountSubscriptionItems.bind(this)
    this.getTaxAmount = this.getTaxAmount.bind(this)
    this.UnsubscriptionBusiness = this.UnsubscriptionBusiness.bind(this)
    this.toggleConfirmUnsub = this.toggleConfirmUnsub.bind(this)
    this.closeMenu = this.closeMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.refreshUsage = this.refreshUsage.bind(this);
  }

  toggleConfirmUnsub() {
    this.setState({ isModalUnsub: !this.state.isModalUnsub })
  }

  toggleContact = () => {
    this.setState({
      isOpenContact: !this.state.isOpenContact
    });
  }

  togglePackageAnnual = () => {
    this.setState({
      isOpenAnnualOptions: !this.state.isOpenAnnualOptions
    });
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ isOpenAnnualOptions: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ isOpenAnnualOptions: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  componentDidMount() {
    this.getDetailSubscription()
  }

  componentWillUnmount() {
    this._isMounted = true
  }

  refreshUsage(){
    this.getDetailSubscription()
  }

  getDetailSubscription = () => {
    let { store_business: { current: { business_code } } } = this.props

    this.setState({ isLoading: true, business_code }, () => {
      this.props.getUsageRecord(business_code, (err, data) => {
        this.props.getBusinessSubscription(business_code, (err, data) => {
          if (this._isMounted) return null

          this.setState({ isLoading: false, isLoadingCreateTKN: false, error: err })
        })
      })
    })
  }

  createCustomerToken = () => {
    let { store_language: { dictionary } } = this.props
    let { store_business: { current: { business_code } } } = this.props
    this.setState({ isLoadingCreateTKN: true, error: '' }, () => {
      this.props.createCustomerPayment(business_code, (err, data) => {
        if (Boolean(err))
          this.toastShow('error', dictionary.error_occurred)
        this.getDetailSubscription()
      })
    })
  }

  toastShow(type, message) {
    toast[type](<ToastBar><Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{message}</Title2></ToastBar>)
  }

  UnsubscriptionBusiness() {
    let { store_language: { dictionary } } = this.props
    let { store_business: { current: { business_code } } } = this.props

    this.setState({ isLoadingUnsub: true })
    this.props.cancelBusinessSubscription(business_code, (err, data) => {
      if (this._isMounted) return null

      this.setState({ isLoadingUnsub: false })
      if (Boolean(err)) {
        this.toastShow('error', dictionary.cannot_completed_request)
        this.toggleConfirmUnsub();
        this.getDetailSubscription();
      } else {
        this.toastShow('success', dictionary.cancle_subscription_successfully)
        this.props.toggle()
        this.props.getBusinessPackage({ business_code })
      }
    })
  }

  getAmountSubscriptionItems(subscription_item) {
    let { plan: product_plan } = subscription_item
    if (_.isEmpty(product_plan))
      return 0

    let total_usage = subscription_item.total_usage
    let amount = 0

    switch (product_plan.billing_scheme) {

      case 'per_unit':
        amount = product_plan.amount * total_usage;
        break;

      case 'tiered':
        let counter = 0
        if (!Boolean(product_plan.tiers.length)) return 0

        product_plan.tiers.map((val, key, tiersArr) => {
          if (total_usage > val.up_to) {
            if (val.up_to) {
              let _this_tiered = key ? val.up_to - tiersArr[key - 1].up_to : val.up_to
              amount += _this_tiered * val.unit_amount
              counter += _this_tiered
            } else {
              amount += (total_usage - counter) * val.unit_amount
            }

          } else {
            if (total_usage > counter) {
              amount += (total_usage - counter) * val.unit_amount
              counter += total_usage - counter
            }
          }
          return amount
        })
        break;

      default:
        amount = 0;
    }
    return amount / 100
  }

  getTaxAmount(si, amount) {
    let tax_amount = 0.0
    if (si.tax_rates.length > 0) {
      let tax_rate = si.tax_rates[0]
      if (!tax_rate.active) return tax_amount

      tax_amount = amount / 100.0 * tax_rate.percentage
    }
    return tax_amount
  }

  openWindow(url, target) { 
    this.props.checkLogin(function () {
      window.open(url, target || 'Pointspot');
    });
    this.setState({ isOpenAnnualOptions: false })
  }

  render() {
    let { isOpen, toggle, togglePackage, packageItems } = this.props
    let { store_language: { dictionary, locale } } = this.props
    let { store_package: { subscription = {} } } = this.props
    let { store_package: { packages: { package_data } } } = this.props
    let { total, current_period_start, current_period_end } = subscription;
    let { store_business: { current: { is_use_credit, permission } } } = this.props
    let { store_usage: { usage_record } } = this.props
    const this_permission = (permission && permission["setting-packages"]) || {}
    let period_end_string = '';
    let free_credit_remain = 0;
    let colorChart = ["#556271", "#F6FAFE"];
    let items = Object.assign([], packageItems.items)
    let tx_remain = 0;
    items.map(val => {
      val.subscription_id = null
      val.usage = {}
      val.amount = 0
      return val
    })
    if (subscription && subscription.items) {
      items = subscription.items;
    }
    if (usage_record) {
      let remain = parseFloat(usage_record.free_credits) - parseFloat(usage_record.usage_credit)
      tx_remain = parseInt(usage_record.tx_limit || packageItems.transaction, 10) - parseInt(usage_record.tx_total, 10)
      if (remain > 0)
        free_credit_remain = remain
    }

    if (current_period_start && current_period_end) {
      moment.locale(locale)
      period_end_string = moment.unix(current_period_end).format('LLL');
      if (is_use_credit) {
        period_end_string = moment(new Date()).format('LLL');
      }
    }

    return (
      <>
        <Modal centered size="lg" fade={true} isOpen={isOpen}>
          {this.state.isLoadingCreateTKN ? (
            <ModalBody>
              <div
                className="w-100 d-flex align-items-center justify-content-center flex-column"
                style={{ padding: "50px 20px" }}
              >
                <Title3 bold secondary className="py-4">
                  {dictionary.wait_check_info}
                </Title3>
                <Loading size={"2rem"} />
              </div>
            </ModalBody>
          ) : Boolean(
              this.state.error && this.state.error.code === "request_token"
            ) ? (
            <>
              <ModalHeader>
                <Title2 bold> {dictionary.terms_payment}</Title2>
              </ModalHeader>
              <PaymentAgreement
                {...this.props}
                toggle={toggle}
                createCustomerToken={this.createCustomerToken}
              />
            </>
          ) : (
            <>
              {usage_record.credit_remaining_percent < 20 && (
                <ModalBody
                  className="p-2"
                  style={{ paddingBottom: "0px !important" }}
                >
                  <div className="d-flex align-items-center justify-content-between border border-danger rounded py-1 px-2">
                    <Title3 className="text-danger">
                    {parseInt(usage_record.credit_addons.total) === 0 ? dictionary.transactions_low : dictionary.credit_low}
                    </Title3>
                    <DangerBtn className="p-1" onClick={this.toggleContact}>
                      <Title3 className="text-white">
                        {dictionary.purchase_credit}
                      </Title3>
                    </DangerBtn>
                  </div>
                </ModalBody>
              )}
              { !is_use_credit ? 
                <>
                <ModalBody className='border-bottom'>
                  <div className='d-flex justify-content-between'>
                    <div className='mr-2'>
                      <div className="align-items-center d-flex">
                        <Title2 className="mr-2" bold>{dictionary.current_usage_package}</Title2>
                        <PackageLabel package_style={packageItems.package_style}>{dictionary[packageItems.title_dictionary]}</PackageLabel>
                      </div>
                      <div className='mt-2 align-items-baseline justify-content-end'>
                        {
                          current_period_start && current_period_end && <Title4 bold secondary>
                            {is_use_credit ? `${dictionary.usage_on} ${period_end_string}`
                             : `${dictionary.period_end} : ${period_end_string}`}<br />
                          </Title4>
                        }
                      </div>
                    </div>
                    <div className="text-right">
                      <Title1 className="mr-3" bold>{`${toCurrency(total, 2)} ${dictionary.currency.thb}`}</Title1>
                      {
                        package_data.monthly_fee && <Title3 className="mr-3" secondary>{dictionary.total_balance}</Title3>
                      }
                    {
                      !this.state.isLoading
                      && subscription
                      && package_data.monthly_fee
                      && Boolean(this_permission && this_permission.privilege_group_id === 1)
                      && (
                        <>
                          {
                            subscription.status === 'hold' ?
                            <div className="d-flex justify-content-end">
                            <InvertBtn onClick={() => { window.open(`${process.env.REACT_APP_RP_ACCOUNT}/invoices`, 'RPAuth') }}>
                              <Title3 danger link bold>{dictionary.payment_failed}</Title3>
                            </InvertBtn>
                          </div>
                          : <div className="d-flex align-items-center">
                          <InvertBtn onClick={togglePackage}>
                            <Title3 link blue bold>{dictionary.change_subscribed_package}</Title3>
                          </InvertBtn>
                          <Title3 secondary>/</Title3>
                          <InvertBtn onClick={this.toggleConfirmUnsub}>
                            <Title3 danger link bold>{dictionary.unsubscription_package}</Title3>
                          </InvertBtn>
                        </div>
                          }
                        </>
                        
                      )
                    }
                    </div>
                  </div>
                </ModalBody>
                <ModalBody className='border-bottom'>
                {
                  Boolean(this.state.error)
                    ? <Title2 bold center danger>{dictionary.unable_show_packages}</Title2>
                    : <BoxModalBody>
                      {/* <BusinessSubscriptionDetailUsage {...this.state} items={items} /> */}
                      <BusinessSubscriptionDetail {...this.state} refreshUsage={this.refreshUsage}/>
                      <Remark dictionary={dictionary} />
                    </BoxModalBody>
                  }
              </ModalBody>
              </>
              : <>
              <ModalBody className="border-bottom">
                <div className="d-flex justify-content-between align-items-end">
                  <Title2 className="mr-2" bold>
                    {dictionary.usage_detail}
                  </Title2>
                  <div className='d-flex'>
                    <Title3 secondary>{`${dictionary.data_on} ${period_end_string}`}</Title3>
                    <Title3 link onClick={this.refreshUsage}>&nbsp;<i className="fas fa-redo-alt"></i></Title3>
                  </div>
                </div>
              </ModalBody>
              <ModalBody
                className="border-bottom"
                style={{ backgroundColor: "#E8EBED" }}
              >
                {Boolean(this.state.error) ? (
                  <Title2 bold center danger>
                    {dictionary.unable_show_packages}
                  </Title2>
                ) : (
                  <BoxModalBody className="pr-0">
                    <Row>
                      <Col xs={12} md={12} lg={4} className="pb-3">
                        <div className="border border-white rounded bg-white p-2" style={{ minHeight: 210 }}>
                          <div className="d-flex justify-content-between align-items-center pb-4">
                            <Title3 bold>
                              {dictionary.package}
                            </Title3>
                            <div hidden={!Boolean(this_permission && this_permission.privilege_group_id === 1)}>
                              <Title2 link onClick={this.showMenu} className='px-2' ><i className="fas fa-ellipsis-v"></i></Title2>
                              <DropdownCardBox hidden={!this.state.isOpenAnnualOptions} style={ window.innerWidth < 992 ? { right: 45 } : {} }>
                                <Title3 className='p-2' link onClick={togglePackage}>{dictionary.change_subscribed_package}</Title3>
                                <Title3 className='px-2' link onClick={() => this.openWindow(`${process.env.REACT_APP_RP_ACCOUNT}/usages`, 'RPAuth')}>
                                  {dictionary.usage_history}
                                </Title3>
                                <Title3 className='p-2' link onClick={() => this.openWindow(`${process.env.REACT_APP_RP_ACCOUNT}/balance`, 'RPAuth')}>{
                                  dictionary.credit_purchase_history}
                                </Title3>
                              </DropdownCardBox>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 120 }}>
                            <Title1 bold style={{ fontSize: 20 }}>
                              {dictionary[packageItems.title_dictionary]}
                            </Title1>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} md={12} lg={8} className="pb-3">
                        <div className="border border-white rounded bg-white p-2 d-flex flex-column" style={{ minHeight: 210 }}>
                          <Title3 bold>{dictionary.usage}</Title3>
                          {this.state.isLoading ? (
                            <Loading />
                          ) : (
                            <Row className="m-auto justify-content-center" style={{ maxHeight: 175 }}>
                              <Col xs={6} md={6} lg={4}>
                                <UsageChart
                                  data={{
                                    datasets: [
                                      {
                                        data: usage_record.tx_total > 0
                                        ? [tx_remain > 0 ? tx_remain : 0, parseInt(usage_record.tx_total, 10)]
                                        : [parseInt(tx_remain, 10)],
                                        backgroundColor: colorChart,
                                        borderColor: colorChart,
                                        hoverBackgroundColor: colorChart,
                                        hoverBorderColor: colorChart,
                                        borderWidth: 1,
                                      },
                                    ],
                                  }}
                                  label={dictionary.basic_transactions}
                                  total={parseInt(usage_record.tx_limit || packageItems.transaction, 10)}
                                />
                              </Col>
                              { is_use_credit && <>
                                <Col xs={6} md={6} lg={4}>
                                  <UsageChart
                                    data={{
                                      datasets: [
                                        {
                                          data: usage_record.usage_credit > 0
                                          ? [parseFloat(free_credit_remain > 0 ? free_credit_remain : 0), parseFloat(usage_record.usage_credit)]
                                          : [parseFloat(free_credit_remain)],
                                          backgroundColor: colorChart,
                                          borderColor: colorChart,
                                          hoverBackgroundColor: colorChart,
                                          hoverBorderColor: colorChart,
                                          borderWidth: 1,
                                        },
                                      ],
                                    }}
                                    label={dictionary.monthly_free_credits}
                                    total={parseInt(usage_record.free_credits, 10)}
                                  />
                                </Col>
                                <Col xs={6} md={6} lg={4}>
                                  <UsageChart
                                    data={{
                                      datasets: [
                                        {
                                          data: usage_record.credit_addons.usage > 0
                                            ? [parseFloat(usage_record.credit_addons.remaining) > 0 ? parseFloat(usage_record.credit_addons.remaining) : 0, parseFloat(usage_record.credit_addons.usage)]
                                            : usage_record.credit_addons.total > 0
                                              ? [parseFloat(usage_record.credit_addons.remaining) > 0 ? parseFloat(usage_record.credit_addons.remaining) : 0]
                                              : [0, 1],
                                          backgroundColor: colorChart,
                                          borderColor: colorChart,
                                          hoverBackgroundColor: colorChart,
                                          hoverBorderColor: colorChart,
                                          borderWidth: 1,
                                        },
                                      ],
                                    }}
                                    label={dictionary.add_ons_credit}
                                    total={parseInt(usage_record.credit_addons.total, 10) || 0}
                                  />
                              </Col>
                              </>
                              }
                            </Row>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="pb-2">
                      <Col xs={12} md={12} lg={12}>
                        <div className="border border-white rounded bg-white p-2" >
                          <BusinessUsageDetail loading={this.state.isLoading} />
                        </div>
                      </Col>
                    </Row>
                    <Remark dictionary={dictionary} annual={is_use_credit} isCredit={parseInt(usage_record.free_credits, 10) > 0} />
                  </BoxModalBody>
                )}
              </ModalBody>
              </>
              }
              <ModalBody>
                <div className="w-100 d-flex justify-content-end">
                  <InvertBtn onClick={toggle}>
                    <Title3 secondary>{dictionary.close}</Title3>
                  </InvertBtn>
                </div>
              </ModalBody>
            </>
          )}
        </Modal>

        <Modal centered size="xs" fade={true} isOpen={this.state.isModalUnsub}>
          <ModalHeader>
            <Title2 bold>{dictionary.notification}</Title2>
          </ModalHeader>
          <ModalBody>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: 150 }}
            >
              {subscription.status === "active" ? (
                <Title2 bold center danger>
                  {nl2br(dictionary.unsubscription_package_confirm)}
                </Title2>
              ) : (
                <Title2 bold danger>
                  {dictionary.unsubscription_package_payment_failed}
                </Title2>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            {!this.state.isLoadingUnsub && (
              <InvertBtn onClick={this.toggleConfirmUnsub}>
                <Title3 bold secondary>
                  {dictionary.close}
                </Title3>
              </InvertBtn>
            )}
            {subscription.status === "active" && (
              <DangerBtn
                onClick={this.UnsubscriptionBusiness}
                disabled={this.state.isLoadingUnsub}
              >
                <Title3 bold white>
                  {this.state.isLoadingUnsub ? (
                    <i className="fas fa-spinner fa-pulse"></i>
                  ) : (
                    dictionary.unsubscription_package
                  )}
                </Title3>
              </DangerBtn>
            )}
          </ModalFooter>
        </Modal>

        <ContactUsModal
          isOpen={this.state.isOpenContact}
          toggleContact={this.toggleContact}
          isPurchase
        />
      </>
    );
  }
}

const Remark = ({ dictionary, annual, isCredit }) => {
  let remark_lists = annual
    ? isCredit
      ? [
          `1. ${dictionary.transaction_yearly_usage_description}`,
          `2. ${dictionary.transaction_yearly_credit_usage_description}`,
        ]
      : [`1. ${dictionary.transaction_annual_usage_description}`]
    : [
        `1. ${dictionary.member_usage_description}`,
        `2. ${dictionary.transaction_usage_description}`,
        `3. ${dictionary.notification_usage_description}`,
      ];

  return <Row>
    <Col xs={12} md={12} lg={12} className='p-0'>
      <RemarkBox>
        <Title3 bold className='pb-1'>{dictionary.remark}</Title3>
        {remark_lists.map(v => <Title4 bold key={v} className='pl-2 pt-1'>{v}</Title4>)}
      </RemarkBox>
    </Col>
  </Row>
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_business: state.business,
    store_language: state.language,
    store_package: state.packages,
    store_usage: state.usage,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getBusinessPackage,
    getUsageRecord,
    getBusinessSubscription,
    cancelBusinessSubscription,
    createCustomerPayment,
    checkLogin
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSubscription)