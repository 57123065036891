import { useMemo } from "react";
import { useSelector } from "react-redux";

const useOverageTransactionData = () => {
  const { subscription } = useSelector((state) => state.packages);
  const { usage_record } = useSelector((state) => state.usage);
  const { itemsUsage } = subscription;

  const overageTransaction = useMemo(() => 
    itemsUsage?.find((item) => item.title_dictionary === "overage_transaction"),
    [itemsUsage]
  );

  const usages = overageTransaction?.usages || [];

  const credit = useMemo(() => ({
    point: calculateUsage(usages, "point"),
    coupon: calculateUsage(usages, "ecard"),
    broadcast: calculateUsage(usages, "broadcast"),
    member_card: calculateUsage(usages, "member_card"),
    trans_noti_expire_point: calculateUsage(usages, "trans_noti_expire_point"),
    trans_noti_expire_member_card: calculateUsage(usages, "trans_noti_expire_member_card"),
    reject_collect_points: calculateUsage(usages, "reject_collect_points"),
  }), [usages]);

  credit.total = useMemo(() => 
    Object.values(credit).reduce((acc, cur) => acc + cur, 0) || 0,
    [credit]
  );
  
  credit.remain = usage_record.credit_total - credit.total;

  return { credit, overageTransaction };
};

const calculateUsage = (usages, title) => 
  usages
    .find((usage) => usage.title_dictionary === title)
    ?.items?.reduce((acc, cur) => acc + cur.sub_total, 0) || 0;

export default useOverageTransactionData;