import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { Title2, Title3 } from "../../components/Font";
import { formatDateTime } from "../../libs/date";
import { DEBUG } from "../../libs/utils";
import { VerifyText } from "../VerifyBusiness/verifyBusiness.style";
import { Modal, ModalBody } from "reactstrap";
import {
  VerifyModalFooter,
  VerifyModalHeader,
} from "./verifyBusinessHash.style";
import { InvertBtn, PrimaryBtn } from "../../components/Button";
import { resetProcessingStatus, updateBusinessName } from "../../redux/actions/action_manager";
import { MANAGER } from "../../redux/actions_const";

export default function BusinessInfo({ info }) {
  const dispatch = useDispatch();
  const [isShowModal, setShowModal] = useState(false);

  DEBUG("BusinessInfo", info, isShowModal);

  const onEditNameSubmitted = (data) => {
    const { business_name } = data;
    DEBUG("onEditNameSubmitted", business_name);
    dispatch(
      updateBusinessName({
        business_code: info.business_code,
        business_name,
      })
    );
  };

  const onEditClicked = () => {
    dispatch(resetProcessingStatus());
    setShowModal(true);
  }

  return (
    <>
      <div className="align-items-center d-flex flex-right mb-4 mt-4">
        <img
          className="flex-shrink-0"
          alt=""
          src={info.business_logo_url}
          style={{ width: 120, height: 120, borderRadius: "50%" }}
        />
        <div className="pl-4 flex-grow-1">
          <Title2 bold>ชื่อธุรกิจ</Title2>
          <div className="d-flex align-items-center mb-3">
            <Title2>{info.business_name}</Title2>
            <Title2 bold>
              <Button
                color="outline"
                size="sm"
                onClick={onEditClicked}
              >
                <i className="far fa-edit" style={{ color: "teal" }}></i>
              </Button>
            </Title2>
          </div>

          <Title2 bold>คำอธิบาย</Title2>
          <Title2 className="mb-3">
            {info.business_description || "-"}
          </Title2>
          <div className="d-flex">
            <div className="flex-grow-1">
              <Title2 bold>สถานะยืนยันธุรกิจ</Title2>
              <VerifyText status={info.status}>
                {info.status === "verified"
                  ? "การยืนยันธุรกิจสำเร็จ"
                  : info.status === "pending"
                  ? "-"
                  : "คำขอยืนยันธุรกิจได้ถูกปฏิเสธ"}
              </VerifyText>
            </div>
            <div className="flex-grow-1">
              <Title2 bold>สมัครใช้งานวันแรก</Title2>
              <Title2 className="mb-3">
                {formatDateTime(info.create_date)}
              </Title2>
            </div>
            <div className="flex-grow-1">
              <Title2 bold>วันที่เปลี่ยนแปลงข้อมูลล่าสุด</Title2>
              <Title2 className="mb-3">
                {formatDateTime(info.update_date)}
              </Title2>
            </div>
          </div>
        </div>
      </div>
      {isShowModal && (
        <EditNameModal
          businessName={info.business_name}
          isOpen={isShowModal}
          toggle={() => setShowModal(!isShowModal)}
          onSubmit={onEditNameSubmitted}
        />
      )}
    </>
  );
}

const EditNameModal = ({ businessName, isOpen, onSubmit, toggle }) => {
  const isInited = useRef(false);
  const processingStatus = useSelector(
    (state) => state.manager.processingStatus
  );

  useEffect(() => {
    DEBUG("[useEffect] EditNameModal", processingStatus, isOpen);
    if (!isInited.current) {
      isInited.current = true;
    } else {
      if (isOpen && processingStatus.status === MANAGER.PROCESS_STATUS_SUCCEED) {
        toggle();
      }
    }
  }, [processingStatus, isOpen, isInited, toggle]);

  const [name, setName] = useState(businessName);
  const isEditing = name && name !== businessName;
  const isProcessing = processingStatus.status === MANAGER.PROCESS_STATUS_WORKING;

  const onTextChange = (event) => {
    const { value } = event.target;
    if (value.length <= 80) {
      setName(value);
    }
  };

  const onSubmitClick = () => {
    onSubmit({
      business_name: name,
    });
  };

  return (
    <Modal isOpen={isOpen} centered={true}>
      <VerifyModalHeader>
        <Title2 white bold>
          กรุณากำหนดชื่อธุรกิจ
        </Title2>
      </VerifyModalHeader>
      <ModalBody>
        <input
          className="form-control"
          type="text"
          name="business_name"
          value={name}
          onChange={onTextChange}
        />
        <Title3 className="mt-1" secondary>* ระบุได้สูงสุด 80 ตัวอักษร</Title3>
      </ModalBody>
      <VerifyModalFooter className="d-flex flex-grow-1 justify-content-end">
        <InvertBtn onClick={toggle} disabled={isProcessing}>
          ยกเลิก
        </InvertBtn>
        <PrimaryBtn
          className="ml-2"
          onClick={onSubmitClick}
          disabled={isProcessing || !isEditing}
        >
          {isProcessing ? <i className="fas fa-spinner fa-pulse" /> : "ยืนยัน"}
        </PrimaryBtn>
      </VerifyModalFooter>
    </Modal>
  );
};
