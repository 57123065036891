import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Row, Col, ModalBody, ModalFooter } from 'reactstrap'
import { PrimaryBtn, InvertBtn } from '../Button'
import BusinessPaymentCreditCard from './Card/BusinessPaymentCreditCard'
import { getCustomerPaymentCreditCard } from '../../redux/actions/action_payment'

class BusinessPaymentCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      card_id: props.default_card || props.card_id,
      default_card: props.default_card
    }
    this.selectCreditCard = this.selectCreditCard.bind(this)
    this.setCard = this.setCard.bind(this)
    this.reloadCardList = this.reloadCardList.bind(this);
  }

  componentDidMount() {
    let { store_language: { dictionary } } = this.props
    let { store_business: { current: { business_code } } } = this.props
    this.props.setHeaderTitle(dictionary.payment_method)

    this.setState({ isLoading: true })
    this.props.getCustomerPaymentCreditCard(business_code, (error, data) => {
      if (Boolean(data))
        this.setState({ isLoading: false })
    })
  }

  selectCreditCard(card_id) {
    //console.log("BusinessPaymentCard selectCreditCard", card_id)
    this.setState({
      card_id
    })
  }

  setCard() {
    //console.log("BusinessPaymentCard setCard", this.state.card_id)
    this.props.selectCreditCard(this.state.card_id);

    if (this.props.isChangeCard) {
      this.props.setDefaultCard(this.state.card_id)
    } else {
      setTimeout(() => {
        this.props.nextStep();
      }, 300);
    }
  }

  reloadCardList() {
    let { store_business: { current: { business_code } } } = this.props

    this.setState({ isLoading: true })
    this.props.getCustomerPaymentCreditCard(business_code, (error, data) => {
      if (Boolean(data))
        this.setState({ isLoading: false })
    })
  }

  render() {
    let { isLoading, card_id, default_card } = this.state
    let { prevStep, LoadingBox, isChangeCard, toggleModalPackage } = this.props
    let { store_language: { dictionary } } = this.props
    let disabledCard = !card_id

    return isLoading
      ? LoadingBox
      : <>
        <ModalBody>
          <Row>
            <Col xs={12} md={12} lg={12}>
              <BusinessPaymentCreditCard {...this.props} card_id={card_id} selectCreditCard={this.selectCreditCard} reloadCardList={this.reloadCardList} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          {
            isChangeCard 
              ? <div className='w-100 d-flex align-items-center justify-content-end'>
                  <InvertBtn md bold onClick={toggleModalPackage}>{dictionary.close}</InvertBtn>
                  <PrimaryBtn md bold onClick={this.setCard} disabled={disabledCard || default_card === card_id}>{dictionary.confirm}</PrimaryBtn>
                </div>
              : <div className='w-100 d-flex align-items-center justify-content-between'>
                  <InvertBtn md bold onClick={prevStep}>{dictionary.back}</InvertBtn>
                  <PrimaryBtn md bold onClick={this.setCard} disabled={disabledCard}>{dictionary.next}</PrimaryBtn>
                </div>
          }
        </ModalFooter>
      </>
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_payment: state.payment,
    store_language: state.language,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getCustomerPaymentCreditCard
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPaymentCard)