import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { Title2 } from "../../../components/Font";
import { InvertBtn, PrimaryBtn } from "../../../components/Button";

export default function ConfirmModal({ dictionary, isOpen, toggle, onConfirm }) {
  return (
    <Modal isOpen={isOpen} centered={true}>
      <ModalBody className="p-4 text-lg-center">
        <Title2>{dictionary.member_card_automation_setting_confirmation}</Title2>
      </ModalBody>
      <ModalBody className="d-flex flex-grow-1 justify-content-end border-top">
        <InvertBtn onClick={toggle}>{dictionary.cancel}</InvertBtn>
        <PrimaryBtn onClick={onConfirm}>{dictionary.confirm}</PrimaryBtn>
      </ModalBody>
    </Modal>
  )
}