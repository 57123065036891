import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'

import {
  CenterBox, CouponBox, Headcoupon, ShowStat,
  QRBox, Tailcoupon, BusinessBar, BoxImageCoupon,
  BoxDateTimeCoupon, UnderLine, QRImgBox
} from './eCoupon.style'

import LazyImg from '../../../components/LazyImg'
import defaultImg from '../../../assets/img/p300x150.jpg'
import { ellipsis } from '../../../libs/ellipsis'
import { formatCustom } from '../../../libs/date'
import { PrimaryBtn } from '../../../components/Button'
import { Title3, Title4 } from '../../../components/Font'
import ECouponModal from './eCouponModal'

const isTimeDuplicate = new Date(new Date().setFullYear(2019, 10, 10)).setHours(0, 0, 0)

class ECouponCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpenEdit: false,
      isOpenDuplicate: false,
    }
  }

  toggleModalEdit = () => {
    this.setState({ isOpenEdit: !this.state.isOpenEdit })
  }

  toggleModalCopy = () => {
    this.setState({ isOpenDuplicate: !this.state.isOpenDuplicate })
  }

  render() {
    let { value } = this.props
    let { example, QRCode } = this.props
    let { menu_permission } = this.props
    let { store_language: { dictionary } } = this.props
    let { selectedCoupon, selectOpacity } = this.props
    let { value: { create_date, link_url, title, action_type, start_due, stop_due, total_activate, total_send, image, ecard_life_time } } = this.props

    return <>
      <CenterBox>
        <CouponBox select={selectedCoupon} selectOpacity={selectOpacity} pointer={menu_permission && menu_permission.permission_edit}>
          <Headcoupon select={selectedCoupon}>
            {
              selectedCoupon
                ? <div
                  className='d-flex align-items-center justify-content-center flex-column'>
                  <PrimaryBtn sm bold onClick={this.toggleModalEdit} className='p-2' style={{ minWidth: '80px' }}>
                    <i className="fas fa-pen pr-2" />
                    {dictionary.edit}
                  </PrimaryBtn>
                  {
                    isTimeDuplicate < new Date(create_date).getTime()
                    && <PrimaryBtn sm bold onClick={this.toggleModalCopy} className='mt-2 p-2' style={{ minWidth: '80px' }}>
                      <i className="fas fa-copy pr-2" />
                      {dictionary.duplicate}
                    </PrimaryBtn>
                  }
                </div>
                : <>
                  <div>
                    <Title3 bold className='text-center'>{dictionary.ecoupon}</Title3>
                    <Title4 secondary className='text-center'>{dictionary.coupon_promotion}</Title4>
                  </div>
                  {
                    this.props.isShowStat
                      ? <div className='w-100 d-flex align-items-center justify-content-center'>
                        <ShowStat>
                          <div className='d-flex'>
                            <Title4 className='pr-1'>{`${dictionary.ecoupon_all}`}</Title4>
                            <Title4 warning>{total_send}</Title4>
                          </div>
                          <div className='d-flex'>
                            <Title4 className='pr-1'>{`${dictionary.activated}`}</Title4>
                            <Title4 warning>{total_activate ? total_activate : '0'}</Title4>
                          </div>
                          <div className='d-flex'>
                            <Title4 className="pr-1">{`${dictionary.usage}`}</Title4>
                            <Title4 warning>{`${total_send > 0 ? _.round(((total_activate / total_send) * 100), 2) : 0}%`}</Title4>
                          </div>
                        </ShowStat>
                      </div>
                      : <QRBox><QRImgBox example={example} alt='' src={QRCode} /></QRBox>
                  }
                  {/* {example && <HeadcouponAbsolute><Title2 bold danger>{dictionary.example}</Title2></HeadcouponAbsolute>} */}
                </>
            }
          </Headcoupon>
          {/* <LineCut>
            <IconCut className='fas fa-cut' />
          </LineCut> */}
          <Tailcoupon select={selectedCoupon}>
            <BusinessBar>
              <Title3 bold>{ellipsis(title, 25)}</Title3>
            </BusinessBar>
            <div className='w-100'>
              <UnderLine type={action_type} />
              <BoxImageCoupon>
                <LazyImg
                  dataSrc={link_url || image}
                  placeholder={defaultImg}
                  classLazyLoad={'Main-Content'}
                  style={{ filter: (!ecard_life_time && new Date().getTime() > new Date(stop_due).getTime()) ? 'grayscale(100%)' : null }} />
                <BoxDateTimeCoupon>
                  {
                    ecard_life_time 
                    ? <Title4 bold><i className="fas fa-history pr-1" />{`${dictionary.expired_after_receiving_coupon} ${ecard_life_time} ${dictionary.day}`}</Title4>
                    : <StatusDate startDue={start_due} stopDue={stop_due} dictionary={dictionary} />
                  }
                </BoxDateTimeCoupon>
              </BoxImageCoupon>
            </div>
          </Tailcoupon>
        </CouponBox>
      </CenterBox>
      {
        this.state.isOpenEdit
        && <ECouponModal
          value={value}
          method='Update'
          toggle={this.toggleModalEdit}
          isOpen={this.state.isOpenEdit}
          getEcoupon={this.props.getEcoupon}
          campaign_title={value.campaign_title} />
      }
      {
        this.state.isOpenDuplicate
        && <ECouponModal
          value={value}
          method='Copy'
          toggle={this.toggleModalCopy}
          isOpen={this.state.isOpenDuplicate}
          getEcoupon={this.props.getEcoupon} />
      }
    </>
  }
}

const StatusDate = (props) => {
  const now = new Date().getTime();
  const startDue = new Date(props.startDue).getTime()
  const stopDue = new Date(props.stopDue).getTime()
  const timeNow = now > stopDue
    ? <Title4 bold danger>{props.dictionary.expired_coupon}</Title4>
    : now < startDue
      ? <Title4 bold>{`${props.dictionary.start_on} ${formatCustom(props.startDue, 'DD MMM YY')}`}</Title4>
      : <Title4 bold>{`${formatCustom(props.startDue, 'DD MMM YY')} - ${formatCustom(props.stopDue, 'DD MMM YY')}`}</Title4>

  return <div className='d-flex align-items-end justify-content-end'>
    {timeNow}
  </div>
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language,
    store_ecoupon: state.ecoupon
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ECouponCard)
