import styled from 'styled-components'

export const SearchResultContainer = styled.div`
  position: relative;
  background: ${props => props.theme.primary};
  display: flex;
  justify-content: space-between;
  padding: 15px 35px;

  @media (max-width: 991px) {
    justify-content: center;
    align-items: flex-start; 
    height: 380px;
  }
`

export const ProfileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 991px) {
    align-items: center;
  }
`

export const ProfileSection = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  // padding: 20px;
`

export const ImageProfileSection = styled.div`
  margin-left: 5px;
  width: 80px;
  height: 80px;
  //overflow: hidden;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  border: 1px solid #ccc;

  background-color: #F2F2F2;
  background-image: url('${props => props.src}');
  background-size: cover;
  flex-shrink: 0;
`

export const Icon = styled.div`
  font-size: 12px;
  min-width: 20px;
  margin-right: 5px;
  
  text-align: center;
  display: inline-block;
`

export const IconEdit = styled.div`
  font-size: 12px;
  min-width: 20px;
  margin-right: 5px;
  
  text-align: center;
  display: inline-block;
  cursor: pointer;
  color: #007bff;
  padding-left: 8px;
`


export const PointsTitle = styled.div`
 font-size: ${props => props.theme.font_l};
 color: ${props => props.theme.primary};
 font-weight: ${props => props.theme.font_bold};
`

export const PointsSubTitle = styled.div`
  font-size: ${props => props.theme.font_s};
  color: ${props => props.theme.secondary};
`

export const IconTimes = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  
  z-index: 10;
  cursor:pointer;
  
  padding: 3px 7px;
  margin: 0px 5px;

  color: ${props => props.theme.white};

  &:hover{
    opacity:0.9;
  }
`

export const Modal = styled.div`
  position: relative;
  background: ${props => props.theme.primary};
  display: flex;
  justify-content: space-between;
  padding: 15px 35px;

  @media (max-width: 991px) {
    justify-content: center;
    align-items: flex-start; 
    margin-bottom: 60px;
    padding-bottom: 60px;
  }
`

export const IconLine = styled.div` 
  background-image: url('${props => props.src}');
  width: 24px;
  height: 24px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0px;
  right: 0px;
  cursor: pointer;
`

export const PointsBox = styled.div`
// margin: 15px;
  min-height: 150px;
  max-height: 200px;
  min-width: 300px;
  border-radius: 4px;
  background: ${props => props.theme.white};
  
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;

  @media (max-width: 991px) {  
    position: absolute;
    width: 90%;
    height:  90%;
    padding-top: 25px;
    padding-bottom: 25px;
    height: 90px;
    margin-top: 200px;
    box-shadow: 0px 1px 1px 1px #ccc;
   }
`

export const ButtonSection = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center; 
  margin-top:10px;
`

export const Labelcss = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  margin-top: 8px;
  @media (max-width: 991px) {  
    justify-content: center;
   }
`

export const LineDisplayName = styled.div`
  display: ${props => props.size === 'large' ? 'flex' : 'none'};
  @media (max-width: 991px) {
    display: ${props => props.size === 'large' ? 'none' : 'flex'};
  }
`;
