import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import ViewMore from '../ViewMore'
import { Title3, Title4 } from '../Font'

import { nl2br } from '../../libs/nl2br'
import { formatCustom } from '../../libs/date'

import { ReviewsRateBox, ReviewsHeadBox, ReviewsHeadOverlay } from './BusinessReview.styled'
import BusinessReviewMessageDetailLoading from './BusinessReviewMessageDetailLoading'

import { getMemberProfileBasic } from '../../redux/actions/action_member'

// import CustomerProfileModal from './CustomerProfileModal'

class BusinessReviewMessageDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // isProfileModal: false
    }
  }

  componentDidMount() {
    let { value: { member_badge_code } } = this.props
    let { store_business: { current: { business_code } } } = this.props
    this.props.getMemberProfileBasic(business_code, member_badge_code)
  }

  // toggleModal = () => {
  //   this.setState({ isProfileModal: !this.state.isProfileModal })
  // }

  render() {
    let { store_member: { userInfo } } = this.props
    let { store_language: { dictionary } } = this.props

    let { value } = this.props
    let memberDetail = userInfo[value.member_badge_code]

    if (!(memberDetail && memberDetail.member_badge_code))
      return <BusinessReviewMessageDetailLoading />

    return <>
      <ReviewsRateBox>
        <ReviewsHeadBox className='mb-2 pb-2' onClick={window.toggleModalMember ? () => window.toggleModalMember(value.member_badge_code, { activeTab: 'transactions' }) : null}>
          <ReviewsHeadOverlay id={`btn-viewprofile`} />
          <div>
            <div className='d-flex align-items-center'>
              <Title3 bold>{dictionary.pointspot_id}</Title3>
              <Title3 bold className='px-1'>:</Title3>
              <Title3 bold link={window.toggleModalMember} blue={window.toggleModalMember}>{value.member_badge_code}</Title3>
            </div>
            <div className='d-flex align-items-center'>
              <Title4 secondary className='pr-1'>{dictionary.comment_on}</Title4>
              <Title4 secondary>{formatCustom(value.create_date, `D MMM YY HH:mm`)}</Title4>
            </div>
          </div>
          <div className='text-center'>
            <Title4 secondary>{dictionary.satisfaction}</Title4>
            <div className='d-flex justify-content-center align-items-center'>
              <ReviewsRate star={value.review_star} />
            </div>
          </div>
        </ReviewsHeadBox>
        <ViewMore sizeViewMore={50}>
          <Title3>{nl2br(value.review_message)}</Title3>
        </ViewMore>
      </ReviewsRateBox>
      {/* <CustomerProfileModal {...this.props}
        data={memberDetail}
        toggle={this.toggleModal}
        isOpen={this.state.isProfileModal} /> */}
    </>
  }
}


const ReviewsRate = ({ star }) => {
  let arr = []
  let maxStar = 5

  for (let index = 0; index < maxStar; index++) {
    arr.push(
      <Title3 key={index} style={{ paddingLeft: 3 }}>
        <i className={`${(star <= index) ? 'far ' : 'fas '} fa-star`} />
      </Title3>
    )
  }
  return arr
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language,
    store_member: state.members,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getMemberProfileBasic
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessReviewMessageDetail) 