import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { downloadExcel } from 'react-export-table-to-excel';

import { Title2, Title3 } from '../Font'
import { InvertBtn, PrimaryBtn, ButtonGroup } from '../Button'
import LoadingMore from '../LoadingMore'
import InputDateRange from "../InputDateRange";
import ButtonTextExportPermission from '../ButtonTextExportPermission';
import { formatDateDB, formatDateTime } from "../../libs/date";

import { toast } from 'react-toastify'
import { ToastBar } from '../ToastComponent/Toast.style'
import BusinessReviewMessageDetail from './BusinessReviewMessageDetail'
import BusinessReviewMessageDetailLoading from './BusinessReviewMessageDetailLoading'
import { mobileFormat } from '../../libs/mobile'
import { sendEmailReportReview, getBusinessReview } from './../../redux/actions/action_business'

class BusinessReviewMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModal: false,
      isLoadingBtnExport: false,
      exportData: []
    }
    this.exportToEmail = this.exportToEmail.bind(this)
    this.currentTableRef = React.createRef();
  }
  exportToExcel = () => {
    let { reviews, review_star, review_message, date_range, start_date, end_date } = this.props
    let { business } = this.props
    let limitDownload = reviews.total.reviews;
    let dataReview = {
      business_code: business.business_code,
      review_star: review_star,
      review_message: review_message,
      date_range: date_range,
      start_date: start_date,
      end_date: end_date,
      limit: 1000,
      type: 'export'
    }
    this.setState({ isOpenModal: true })
    if (review_star !== 'all') {
      limitDownload = reviews.reviews_rate.filter((review) => review.star === parseInt(review_star))[0].count
    }
    if (reviews.reviews_message.length && reviews.reviews_message.length > 0 && limitDownload <= 1000) {
      this.props.getBusinessReview(dataReview, (err, data) => {
        this.setState({ exportData: data && data.data && data.data.reviews_message }, () => {
          return downloadExcel({
            fileName: "report_business_reivew_" + formatDateDB(new Date()),
            sheet: 'ReportBusinessReview'
          }, this.currentTableRef.current)
        })
      })
    }
  }

  exportToEmail = () => {
    let { dictionary, business, profile } = this.props
    let { review_star, review_message, date_range, start_date, end_date } = this.props
    let dataHeaders = []
    dataHeaders = [
      {
        title: dictionary.satisfaction_comment_summary_report,
        colSpan: 5
      },
      {
        title: `${dictionary.business} : ${business.business_name}`,
        colSpan: 5
      },
      {
        title: `${dictionary.create_date} : ${formatDateTime(new Date())}`,
        colSpan: 5
      },
      {
        title: `${dictionary.date_range} ${dictionary[date_range]} : ${dictionary.date_on} ${formatDateTime(start_date)} ${dictionary.to} ${formatDateTime(end_date)}`,
        colSpan: 5
      },
      {
        title: `${dictionary.satisfaction_score} : ${review_star === 'all' ? dictionary.all : review_star}`,
        colSpan: 5
      },
      {
        title: `${dictionary.review} : ${review_message ? dictionary.review_only : dictionary.all}`,
        colSpan: 5
      },
      {
        columns: [
          {
            title: dictionary.date,
            dataKey: ["date"]
          },
          {
            title: dictionary.pointspot_id,
            dataKey: ["member_badge_code"]
          },
          {
            title: dictionary.mobile,
            dataKey: ["mobile_no"]
          },
          {
            title: dictionary.score,
            dataKey: ["score"]
          },
          {
            title: dictionary.review,
            dataKey: ["review"]
          },
        ]
      }
    ]

    let data = {
      business_code: business.business_code,
      review_star,
      review_message,
      start_date,
      end_date,
      language: dictionary.locale,
      sendmail: {
        email: profile.email,
        data_headers: dataHeaders,
      }
    }
    this.setState({ isLoadingBtnExport: true }, () => {
      this.props.sendEmailReportReview(data, (err) => {
        if (Boolean(err))
          this.toastShow('error', dictionary.error_occurred)
        else {
          this.toastShow('success', dictionary.report_will_send_to_email_after_processed)
        }
        this.setState({ isLoadingBtnExport: false, isOpenModal: false })
      })
    })
  }

  toastShow(type, message) {
    toast[type](<ToastBar><Title2 bold white style={{ width: '100%', textAlign: 'center' }}>{message}</Title2></ToastBar>)
  }

  render() {
    let { dictionary, business } = this.props
    let { reviews, review_star, review_message, date_range, start_date, end_date } = this.props
    let { isLoadingLoadMore, isLoadingComment } = this.props
    let limitDownload = review_star === 'all' ? reviews.total.reviews : reviews.reviews_rate.filter((review) => review.star === parseInt(review_star))[0].count;
    let optionReview = {
      status: [{ id: 'status', value: dictionary.comments_only.toUpperCase() }],
      dateRange: [
        { show: true, id: 'all_time', value: dictionary.all_time.toUpperCase() },
        { show: true, id: 'today', value: dictionary.today.toUpperCase() },
        { show: true, id: 'yesterday', value: dictionary.yesterday.toUpperCase() },
        { show: false, id: 'this_week', value: dictionary.this_week.toUpperCase() },
        { show: false, id: 'last_week', value: dictionary.last_week.toUpperCase() },
        { show: false, id: 'this_month', value: dictionary.this_month.toUpperCase() },
        { show: false, id: 'last_month', value: dictionary.last_month.toUpperCase() },
        { show: false, id: 'this_quarter', value: dictionary.this_quarter.toUpperCase() },
        { show: false, id: 'last_quarter', value: dictionary.last_quarter.toUpperCase() },
        { show: false, id: 'this_year', value: dictionary.this_year.toUpperCase() },
        { show: false, id: 'last_year', value: dictionary.last_year.toUpperCase() },
      ],
      star: [
        { show: true, id: 'all', icon: 'fas fa-star', value: dictionary.all.toUpperCase() },
        { show: true, id: '1', icon: 'fas fa-star', value: 1 },
        { show: true, id: '2', icon: 'fas fa-star', value: 2 },
        { show: true, id: '3', icon: 'fas fa-star', value: 3 },
        { show: true, id: '4', icon: 'fas fa-star', value: 4 },
        { show: true, id: '5', icon: 'fas fa-star', value: 5 }
      ],
    }

    return <div className='pb-3'>
      <LoadingMore
        isLoading={isLoadingLoadMore}
        actionLoadMore={this.props.onClickLoadMore}
        loadMoreCheck={reviews.loadmore && !this.props.isLoadingComment}>
        <Row className='pt-3'>
          <Col sm={12} md={4} lg={12} xl={12}>
            <div className='pl-1 pb-2'>
              <Title2 bold>{dictionary.comment_summary}</Title2>
              <Title3 secondary>{dictionary.comment_summary_in_business}</Title3>
            </div>
          </Col>
          <Col sm={12} md={8} lg={12} xl={12}>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <div className='w-100 d-flex flex-column flex-lg-row align-items-end justify-content-around'>
                  <div style={{ maxWidth: 300 }} className='w-100 pb-2'>
                    <InputDateRange
                      titleCustom={
                        <Title3 bold className="pl-1 mb-1">
                          {dictionary.date_range}
                        </Title3>
                      }
                      toggle={(value) => { this.props.onClickSortReviews(review_star, review_message, value)}}
                      type={date_range}
                    />
                  </div>
                  <div style={{ maxWidth: 200 }} className='w-100 pb-2 d-flex align-items-end justify-content-end'>
                    <div className='d-block d-md-none'>
                      <ReviewFilterOption
                        {...this.props}
                        data={optionReview.status}
                        onClick={() => this.props.onClickSortReviews(review_star, !review_message)} />
                    </div>
                  </div>

                  <div style={{ minWidth: 440 }} className='w-100  pb-2 d-flex align-items-end justify-content-end'>
                    <div className='d-none d-md-block'>
                      <ReviewFilterOption
                        {...this.props}
                        data={optionReview.status}
                        onClick={() => this.props.onClickSortReviews(review_star, !review_message)} />
                    </div>

                    <div className='ml-2'>
                      <BusinessReviewFilter
                        {...this.props}
                        selected={review_star}
                        data={optionReview.star}
                        title={dictionary.satisfaction_score}
                        onChange={(value) => { this.props.onClickSortReviews(value, review_message) }} />
                    </div>
                  </div>
                </div>

              </Col>
            </Row>
          </Col>
        </Row>

        <div className='w-100' style={{ minHeight: 'calc(100vh - 400px)' }}>
          <Row className='pt-2'>
            <Col sm={12} md={6} lg={6}>
              <Title3 secondary>{`${dictionary.date_on} ${
                formatDateTime(this.props.start_date) +
                  ` ${dictionary.to} ` +
                formatDateTime(this.props.end_date)
              }`}</Title3>
            </Col>
            <Col sm={12} md={6} lg={6} className="d-flex align-items-start justify-content-end">
              {
                reviews.reviews_message.length > 0 && (
                  <>
                    <ButtonTextExportPermission
                      menuKey="report-review"
                      className="d-none d-md-flex"
                      onClick={this.exportToExcel}
                    />
                  </>
                )
              } 
            </Col>
          </Row>
          <Row className='pt-2'>
            {
              isLoadingComment
                ? <ReviewMessageDetailLoading limit={reviews.limit} />
                : reviews.reviews_message.length > 0
                  ? reviews.reviews_message.map((value, key) => {
                    return <Col className='p-0' xs={12} md={6} lg={6} xl={4} key={`${key}_${value.action_code}_${value.member_badge_code}_${value.create_date}`}>
                      <BusinessReviewMessageDetail {...this.props} value={value} />
                    </Col>
                  })
                  : <Col className='text-center' xs={12}>
                    <div className='border-top border-bottom py-4'>
                      <Title3 secondary>{dictionary.non_review}</Title3>
                    </div>
                  </Col>
            }
          </Row>
        </div>
        {
          <table id="table-to-xls-report-business-review" ref={this.currentTableRef} border="1" hidden>
            <thead>
              <tr className="text-center">
                <th colSpan={5}>{dictionary.satisfaction_comment_summary_report}</th>
              </tr>
              <tr className="text-center">
                <th colSpan={5}>{dictionary.business} : {business.business_name}</th>
              </tr>
              <tr className="text-center">
                <th colSpan={5}>{dictionary.create_date} : {formatDateTime(new Date())}</th>
              </tr>
              <tr className="text-center">
                <th colSpan={5}>
                  {dictionary.date_range}{" "}
                  {dictionary[date_range]} :{" "}
                  {dictionary.date_on} {formatDateTime(start_date)}{" "}
                  {dictionary.to} {formatDateTime(end_date)}
                </th>
              </tr>
              <tr className="text-center">
                <th colSpan={5}>{dictionary.satisfaction_score} : {review_star === 'all' ? dictionary.all : review_star}</th>
              </tr>
              <tr className="text-center">
                <th colSpan={5}>{dictionary.review} : {review_message ? dictionary.review_only : dictionary.all}</th>
              </tr>
              <tr>
                <th>{dictionary.date}</th>
                <th>{dictionary.pointspot_id}</th>
                <th>{dictionary.mobile}</th>
                <th>{dictionary.score}</th>
                <th>{dictionary.review}</th>
              </tr>
            </thead>
            <tbody className='text-center'>
              {
                this.state.exportData.length > 0 ?
                  this.state.exportData.map((val, key) => {
                    return <tr key={`${key}_${val.action_code}_${val.member_badge_code}_${val.create_date}`}>
                      <td>{formatDateTime(val.create_date)}</td>
                      <td>{val.member_badge_code}</td>
                      <td>{mobileFormat(val.country_code, val.mobile_number)}</td>
                      <td>{val.review_star}</td>
                      <td>{val.review_message ? val.review_message : "-"}</td>
                    </tr>
                  })
                : null
              }
            </tbody>
          </table>
        }
        {
          <Modal isOpen={this.state.isOpenModal} centered>
            <ModalBody className="text-center">
              <Title2 className="p-3">
                {
                  limitDownload > 1000 
                    ? dictionary.report_large_date_export_to_email 
                    : dictionary.exported
                }
              </Title2>
            </ModalBody>
            <ModalFooter>
              <InvertBtn onClick={() => this.setState({ isOpenModal: false })}>{dictionary.close}</InvertBtn>
              <PrimaryBtn onClick={this.exportToEmail}>
                {
                  limitDownload > 1000 
                  ? dictionary.confirm
                  : this.state.isLoadingBtnExport 
                    ? (<i className="fas fa-spinner fa-pulse" />) 
                    : dictionary.send_to_my_email
                }
              </PrimaryBtn>
            </ModalFooter>
          </Modal>
        }
      </LoadingMore>
    </div>
  }
}

class BusinessReviewFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.toggleFiterDropdown = this.toggleFiterDropdown.bind(this)
    this.clickOutFiterDropdown = this.clickOutFiterDropdown.bind(this)
  }

  componentDidMount() {
    let elementMain = window.document.querySelector('.Main-Content')
    if (elementMain)
      elementMain.addEventListener('click', this.clickOutFiterDropdown)
  }

  componentWillUnmount() {
    let elementMain = window.document.querySelector('.Main-Content')
    if (elementMain)
      elementMain.removeEventListener('click', this.clickOutFiterDropdown)
  }

  toggleFiterDropdown() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  clickOutFiterDropdown() {
    if (this.state.isOpen) this.setState({ isOpen: !this.state.isOpen })
  }

  sortReviews(id) {
    this.props.onChange(id)
  }

  render() {
    let { data, selected, title } = this.props
    if (!data || data.length === 0)
      return null

    return <div>
      <Title3 bold className='pl-1'>{title}</Title3>
      <ButtonGroup primary sm bold>
        {
          data.map((value, key) => {
            return (value.show || value.id === selected)
              && <PrimaryBtn sm bold
                key={`${key}_${value.id}`}
                invert={value.id !== selected}
                onClick={() => { this.sortReviews(value.id) }}>
                {value.value}
                {value.icon && <>{' '}<i className={`${value.icon}`} /></>}
              </PrimaryBtn>
          })
        }
      </ButtonGroup>
    </div>
  }
}

const ReviewMessageDetailLoading = ({ limit }) => {
  let arr = []
  for (let index = 0; index < limit; index++) {
    arr.push(<Col className='p-0' xs={12} md={6} lg={6} xl={4} key={`loading_${index} `}>
      <BusinessReviewMessageDetailLoading />
    </Col>)
  }
  return arr
}

const ReviewFilterOption = ({ data, review_message, onClick }) => {
  return data.map((option, key) => {
    return <PrimaryBtn
      sm bold

      onClick={onClick}
      invert={!review_message}
      key={`${option.id}${key}`}
      id={`btn-options_filter_${option.id}`}>
      <div className='d-flex align-items-baseline'>
        <i className={`${review_message ? `fas fa-check-square` : `far fa-square`} pr-2`} />
        {option.value}
      </div>
    </PrimaryBtn>
  })
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    sendEmailReportReview,
    getBusinessReview,
  }, dispatch)
}

export default withRouter(connect(null, mapDispatchToProps)(BusinessReviewMessage))