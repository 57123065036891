import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'

import Sidebar from './Sidebar'
import { getBusinessList } from '../redux/actions/action_business'

class Layout extends React.Component {
  render() {
    let { store_business: { list } } = this.props

    return list && list.length > 0
      ? <Sidebar {...this.props} />
      : <Redirect to="/wizard" />
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_business: state.business
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    getBusinessList
  }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)