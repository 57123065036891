import { Modal, ModalBody } from "reactstrap";
import { clearPayment, createCustomerPayment, getCustomerPayment } from "../../redux/actions/action_payment";
import { createBusinessSubscription, getBusinessPackage } from "../../redux/actions/action_package";

import BusinessPaymentStep from "./BusinessPaymentStep";
import Loading from "../Loading";
import React from "react";
import { Title2 } from "../Font";
import { ToastBar } from "./../ToastComponent/Toast.style";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { verifyPremiumID } from "../../libs/validator";

class BusinessPayment extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      company: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      phone: "",
      tax_id: "",
      card_id: "",
      addon: [],
      premium_id: "",
      customer_token_id: "",
      isLoadingState: false,
      isLoadingPayment: false,
    };
    this.setHeaderTitle = this.setHeaderTitle.bind(this);
    this.subscriptionBusiness = this.subscriptionBusiness.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  componentDidMount() {
    let {
      store_permission: { profile },
    } = this.props;
    this.setState({ name: profile.name, last_name: profile.last_name });
  }

  componentWillUnmount() {
    this._isMounted = true;
    this.props.clearPayment();
  }

  setHeaderTitle(title) {
    this.setState({ title: title });
  }

  onChangeInput(event) {
    let { name, value } = event.target;
    if (name === "premium_id") value = value.trim();
    if (name === "premium_id" && value && (value.length > 16 || !verifyPremiumID(value))) return null;
    this.setState({ [name]: value });
  }

  selectCreditCard(card) {
    if (card === this.state.card_id) this.setState({ card_id: "" });
    else this.setState({ card_id: card });
  }

  selectAddons = (addon) => {
    if (this.state.addon.filter((f) => f.id === addon.id).length > 0)
      return this.setState({ addon: this.state.addon.filter((f) => f.id !== addon.id) });

    this.setState({ addon: [...this.state.addon, addon] });
  };

  setAddress = (address) => {
    this.setState({ ...address });
  }

  toastShow(type, message) {
    toast[type](
      <ToastBar>
        <Title2 bold white style={{ width: "100%", textAlign: "center" }}>
          {message}
        </Title2>
      </ToastBar>
    );
  }

  createCustomerToken = () => {
    let {
      store_business: {
        current: { business_code },
      },
    } = this.props;

    this.setState({ isLoadingPayment: true }, () => {
      this.props.createCustomerPayment(business_code, () => {
        if (this._isMounted) return null;
        this.getCustomerPayment();
      });
    });
  };

  getCustomerPayment = () => {
    let {
      store_business: {
        current: { business_code },
      },
    } = this.props;

    this.setState({ isLoadingPayment: true }, () => {
      this.props.getCustomerPayment(business_code, (err, data) => {
        if (this._isMounted) return null;

        this.setState({ isLoadingPayment: false });

        if (data && data.user && data.payment) {
          let paymentData = data.payment;
          let pickdata = {};
          let address = _.get(paymentData.addressList, [business_code]) || paymentData.address;
          Object.assign(pickdata, _.pick(paymentData, ["description", "phone"]));
          Object.assign(pickdata, _.pick(data.user, ["customer_token_id"]));

          if (address)
            Object.assign(
              pickdata,
              _.pick(address, [
                "line1",
                "line2",
                "city",
                "state",
                "postal_code",
                "country",
                "tax_id",
                "name",
                "phone"
              ])
            );

          pickdata.company = pickdata.name || pickdata.description;
          delete pickdata.description;
          delete pickdata.name;

          for (const name in pickdata) {
            if (pickdata.hasOwnProperty(name)) {
              const value = pickdata[name];
              this.onChangeInput({ target: { name, value } });
            }
          }
        }
      });
    });
  };

  subscriptionBusiness() {
    let { package_id } = this.props;
    let {
      store_language: { dictionary },
    } = this.props;
    let {
      store_business: {
        current: { business_code },
      },
    } = this.props;
    this.setState({ tax_id: this.state.tax_id.replace(/[\W\D\s_]+/g, "") });
    let input = {
      business_code: business_code,
      package_id: package_id,
      address: _.pick(this.state, [
        "company",
        "line1",
        "line2",
        "city",
        "state",
        "postal_code",
        "country",
        "phone",
        "tax_id",
      ]),
      card_token: this.state.card_id,
      addon: this.state.addon.map((v) => v.id),
      // premium_id: this.state.addon.filter(f => f.id === 'premium_id').length > 0 ? this.state.premium_id.trim() : null
      customer_token: this.state.customer_token_id,
    };

    this.setState({ isLoadingState: true }, () => {
      this.props.createBusinessSubscription(input, (err, data) => {
        if (this._isMounted) return null;
        this.setState({ isLoadingState: false });

        if (Boolean(err)) {
          return this.toastShow("error", dictionary.cannot_completed_request);
        } else {
          this.toastShow("success", dictionary.upgrade_subscription_successfully);
          if (this.props.toggleSubscription) this.props.toggleSubscription();
          if (this.props.toggleModalPackage) this.props.toggleModalPackage();
          if (this.props.toggle) this.props.toggle();
          this.props.getBusinessPackage({ business_code });
        }
      });
    });
  }

  clearState = () => {
    this.setState({
      title: "",
      company: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "",
      phone: "",
      card_id: "",
      addon: [],
      premium_id: "",
      customer_token_id: "",
      tax_id: "",
    });
  };

  onLastStepEnd = () => {
    const address = _.pick(this.state, [
      "company",
      "line1",
      "line2",
      "city",
      "state",
      "postal_code",
      "country",
      "phone",
      "tax_id",
    ]);
    const card_id = this.state.card_id;
    //console.log("onLastStepEnd", address, card_id);
    if (typeof this.props.onLastStepEnd === "function") {
      this.props.onLastStepEnd({ address, card_id });
    }
  };

  render() {
    let { isOpen, toggleModalPackage } = this.props;

    return (
      <Modal centered size="lg" fade={true} isOpen={isOpen}>
        {
          this.state.isLoadingPayment ? null : (
            <ModalBody className="w-100 border-bottom">
              <div className="w-100 d-flex align-items-center justify-content-between">
                <Title2 bold>{this.state.title}</Title2>
                <Title2 bold link className="pl-2 pr-2" onClick={toggleModalPackage}>
                  <i className="fas fa-times" />
                </Title2>
              </div>
            </ModalBody>
          )
        }
        <BusinessPaymentStep
          {...this.props}
          {...this.state}
          LoadingBox={<LoadingBox />}
          onChangeInput={this.onChangeInput}
          createCustomerToken={this.createCustomerToken}
          getCustomerPayment={this.getCustomerPayment}
          subscriptionBusiness={this.subscriptionBusiness}
          setHeaderTitle={(title) => this.setHeaderTitle(title)}
          selectCreditCard={(card) => this.selectCreditCard(card)}
          selectAddons={(addon) => this.selectAddons(addon)}
          setAddress={this.setAddress}
          default_card={this.props.card_id}
          clearState={this.clearState}
          onLastStepEnd={this.onLastStepEnd}
        />
      </Modal>
    );
  }
}

const LoadingBox = () => {
  return (
    <>
      <ModalBody>
        <div className="w-100 d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
          <Loading size="2.5rem" margin="0px" />
        </div>
      </ModalBody>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_business: state.business,
    store_payment: state.payment,
    store_language: state.language,
    store_packages: state.packages,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      clearPayment,
      createBusinessSubscription,
      createCustomerPayment,
      getCustomerPayment,
      getBusinessPackage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessPayment);
