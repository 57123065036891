import styled, { css } from 'styled-components'

export const LoadingSizeBox = styled.div`
  height: 100%;
  min-height: ${props => props.height || 200}px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const CreditCardDetail = styled.div`
  width: 100%;

  > * {
    border-bottom: 1px solid #dee2e6;
  }
`
export const CreditCardBox = styled.div`
  width: 100%;
  height: 85px;
  ${props => !props.readOnly && css`cursor: pointer;`}

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props => props.selectCard
    && css`background-color: rgb(0,0,0,0.05);`
  }
`

export const CreditLogo = styled.div`
  width: 25vw;
  max-width: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const CreditCardBoxDetail = styled.div`
  height: 80%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

export const CreditLogoImage = styled.img`
  height: auto;
  max-width: 40px;
`

export const BlockAlignCenter = styled.div`
  display: flex;
  align-items: center;
`

export const BlackCircle = styled.div`
  width: 7px;
  height: 7px;
  @media (max-width: 990px) {
    width: 5px;
    height: 5px;
  }

  margin: 1px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #546174;
`

export const CardPrimary = styled.div`
  width: 30vw;
  max-width: 180px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const CardPrimaryText = styled.div`
  width: 10vw;
  max-width: 100px;
  text-align: center;
`

export const CardPrimaryDelete = styled.div`
  width: 10vw;
  max-width: 50px;
  text-align: center;
`

export const TitleBar = styled.div`
  padding: 0.5rem 0.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  background-color: rgb(0,0,0,0.05);
`

export const IconPlus = styled.i`
  font-size: 13px;
  color: ${props => props.theme.secondary};
`

export const IconCheckBox = styled.i`
  font-size: 18px;
  color: ${props => props.theme.primary};
`

export const CardPrimarySelectCard = styled.div`
  width: 30vw;
  max-width: 150px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const DescriptionBar = styled.div`
  padding: 0.75rem;
`