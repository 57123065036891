const publicAPI = process.env.REACT_APP_BACKEND_API + '/pub'
const privateAPI = process.env.REACT_APP_BACKEND_API + '/api'
const businessAPI = process.env.REACT_APP_BACKEND_API + '/api/business';
const pointsAPI = process.env.REACT_APP_BACKEND_API + '/api/points';
const geoAPI = process.env.REACT_APP_BACKEND_API + '/geography'
const managerAPI = process.env.REACT_APP_BACKEND_API + '/manager'
const accountAPI = process.env.REACT_APP_ACCOUNT_API + '/apis'
const backendPAPI = process.env.REACT_APP_BACKEND_API + '/papi'
const rpBackendAPI = process.env.REACT_APP_RP_ACCOUNT_BACKEND

module.exports = { publicAPI, privateAPI, businessAPI, pointsAPI, geoAPI, managerAPI, accountAPI, backendPAPI, rpBackendAPI }
