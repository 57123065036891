import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PageA from './component/PageA/PageA';
import { messagesActions } from './store/messagesSlice';

function App(props) {
  const dispatch = useDispatch();
  const logout = useSelector((state) => state.user.logout);
  const messages = useSelector((state) => state.messages);

  const getMessagesData = messages.getMessagesData;
  const userData = messages.userData;

  useEffect(() => {
    props.setLogout(logout);
  }, [logout, props]);

  function toDateStr(secs) {
    const t = new Date(1970, 0, 1);
    t.setSeconds(secs);
    return t.toString();
  }

  useEffect(() => {
    if (getMessagesData.continueGetMessages && logout === false) {
      fetch(
        `${process.env.REACT_APP_BACKEND_API}/broadcastmessage/message/${getMessagesData.lastTimestamp}/${userData.idToken}/${userData.clientId}`,
        {
          method: 'GET',
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.data.messages.length === 0) {
            dispatch(messagesActions.stopGetMessages());
            return;
          }

          const newMessages = result.data.messages.map((message) => {
            const dateStr = `${
              typeof message.date_broadcast === 'string'
                ? message.date_broadcast
                : toDateStr(message.date_broadcast._seconds)
            }`;

            return {
              date: dateStr,
              message: message.message,
              url: message.linkto || '',
              show: message.show,
              id: message.id,
              timestamp: message.timestamp,
            };
          });

          dispatch(
            messagesActions.setMessages({
              newMessages: newMessages,
              lastTimestamp: result.data.messages[newMessages.length - 1].timestamp,
              newMessagesLength: result.results,
            })
          );
        });
    }
  }, [
    getMessagesData.continueGetMessages,
    dispatch,
    logout,
    userData.idToken,
    userData.clientId,
    getMessagesData.lastTimestamp,
  ]);

  return <PageA></PageA>;
}

export default App;
