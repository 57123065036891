import { AddonContainer, AddonItem, AddonsContent, AddonsImages, IconCheckBox } from './BusinessPayment.style'
import { InvertBtn, PrimaryBtn } from '../Button'
import React, { Component } from 'react';
import { Title2, Title3 } from '../Font'

import { ModalFooter } from 'reactstrap'
import ico_broadcast from '../../assets/img/addons/broadcast.svg'
import ico_premium from '../../assets/img/addons/premium.svg'
import ico_product from '../../assets/img/addons/product.svg'

const icons = {
  premium_id: ico_premium,
  broadcast_message: ico_broadcast,
  product_level_points: ico_product,
}

class BusinessSubscriptionAddons extends Component {

  componentDidMount() {
    let { store_language: { dictionary } } = this.props
    this.props.setHeaderTitle(dictionary.add_subscription_addons)
  }

  render() {
    let { store_language: { dictionary } } = this.props
    let { package_id, store_packages } = this.props
    let { nextStep, prevStep } = this.props

    let select_package = (store_packages.packages_list.filter(p => p.package_id === package_id) || [])[0]
    if (!select_package) return null;

    let addons_list = select_package.addons
      .sort((a, b) => a.id > b.id)
      .filter(f => (package_id === 'ps-gold') ? f.filter_term !== 'product_level_points' : f)
      .map(a => {
        let id = a.id
        return Object.assign(a, {
          id: a.item_id,
          iid: id,
          img: icons[a.title_dictionary],
          name: dictionary[a.title_dictionary] || a.title_dictionary,
          description: dictionary[a.description_dictionary] || a.description_dictionary,
        })
      })

    return (
      <div>
        {
          addons_list.map(val => {
            let select = this.props.addon.filter(f => f.id === val.id).length > 0

            return <AddonItem key={val.id}>
              <AddonContainer select={select} onClick={() => this.props.selectAddons(val)}>
                <IconCheckBox className={select ? "far fa-check-square pr-3" : "far fa-square pr-3"} />
                <AddonsImages src={val.img} alt={val.name} style={{ width: '64px', height: '64px' }} />
                <AddonsContent className='d-block d-md-flex'>
                  <div>
                    <Title2 bold>{val.name}</Title2>
                    <Title3 secondary> {val.description}</Title3>
                  </div>
                </AddonsContent>
              </AddonContainer>
              {/* {
                (select && (val.id === 'premium_id'))
                && <Fade in={select && (val.id === 'premium_id')}>
                  <AddonsContent select className='d-flex align-items-center justify-content-start'>
                    <Title3 bold minfit className='mr-2'>{dictionary.premium_id} : </Title3>
                    <Input
                      type='text'
                      name='premium_id'
                      value={this.props.premium_id || ''}
                      style={{ maxWidth: '300px' }}
                      onChange={this.props.onChangeInput} />
                  </AddonsContent>
                </Fade>
              } */}
            </AddonItem>
          })
        }
        <ModalFooter>
          <div className='w-100 d-flex align-items-center justify-content-between'>
            <InvertBtn md bold onClick={prevStep}>{dictionary.back}</InvertBtn>
            <PrimaryBtn
              md bold
              // disabled={(this.props.addon.filter(f => f.id === 'premium_id').length > 0 && (!this.props.premium_id || !verifyPremiumID(this.props.premium_id)))}
              onClick={nextStep}>{dictionary.next}
            </PrimaryBtn>
          </div>
        </ModalFooter>
      </div>
    );
  }
}

export default BusinessSubscriptionAddons;