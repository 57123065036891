import {
  BlackCircle,
  BlockAlignCenter,
  CardPrimary,
  CardPrimaryDelete,
  CardPrimarySelectCard,
  CreditCardBox,
  CreditCardBoxDetail,
  CreditLogo,
  CreditLogoImage,
  IconCheckBox,
} from './BusinessPaymentCard.style'

import React from 'react'
import { Title3 } from '../../Font'
import americanExpress from '../../../assets/img/creditCard/americanExpress.svg'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import dinersClub from '../../../assets/img/creditCard/dinersClub.svg'
import discover from '../../../assets/img/creditCard/discover.svg'
import jcb from '../../../assets/img/creditCard/JCB.svg'
import mastercard from '../../../assets/img/creditCard/mastercard.svg'
import pointspot from '../../../assets/img/logo.svg'
import unionPay from '../../../assets/img/creditCard/unionPay.svg'
import visa from '../../../assets/img/creditCard/visa.svg'

class CreditCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isDeleteCard: false
    }
  }

  render() {
    let { store_language: { dictionary } } = this.props
    let { readOnly, Id, Name, Brand, Last4, ExpMonth, ExpYear, SelectCard, DefaultCard } = this.props
    let { onClickSelectCard } = this.props
    let imageCrad = [
      { name: 'American Express', image: americanExpress },
      { name: 'Diners Club', image: dinersClub },
      { name: 'Discover', image: discover },
      { name: 'JCB', image: jcb },
      { name: 'MasterCard', image: mastercard },
      { name: 'UnionPay', image: unionPay },
      { name: 'Visa', image: visa },
      { name: 'Unknown', image: pointspot },
    ]

    let logo = Brand ? imageCrad.find(b => b.name.toLowerCase() === Brand.toLowerCase()) : {}
    if (!logo) {
      logo = imageCrad.find(b => b.name === 'Unknown');
    }

    return <CreditCardBox readOnly={readOnly} selectCard={SelectCard}>
      {
        !readOnly && <CardPrimarySelectCard onClick={() => { !readOnly && onClickSelectCard(Id) }}>
          {
            SelectCard
              ? <IconCheckBox className="far fa-check-square pl-3 pr-3" />
              : <IconCheckBox className="far fa-square pl-3 pr-3" />
          }
          <Title3 bold>{dictionary.select}</Title3>
        </CardPrimarySelectCard>
      }
      <div className='w-100 h-100 d-flex align-items-center' onClick={() => { !readOnly && onClickSelectCard(Id) }}>
        <CreditLogo>
          <CreditLogoImage alt='' src={logo.image} />
        </CreditLogo>
        <CreditCardBoxDetail>
          <Title3>{Name}</Title3>
          <NumberCard number={Last4} />
          <Title3 secondary>{`${dictionary.expired} ${formathMonth(ExpMonth)}/${formatYear(ExpYear)}`}</Title3>
        </CreditCardBoxDetail>
      </div>
      {
        <CardPrimary>
          {
            (Id === DefaultCard)
              ? <CardPrimaryDelete>
                <Title3 bold>{dictionary.card_primary}</Title3>
              </CardPrimaryDelete>
              : this.state.isDeleteCard
                ? <CardPrimaryDelete onClick={() => this.props.onClickRemoveCard(Id)}>
                  <Title3 link secondary>{dictionary.delete}</Title3>
                </CardPrimaryDelete>
                : null
          }
        </CardPrimary>
      }
    </CreditCardBox>
  }
}

const NumberCard = ({ number }) => {
  return <BlockAlignCenter className='w-100'>
    <BlockAlignCenter className='pr-2'><BlackCircle /><BlackCircle /></BlockAlignCenter>
    <BlockAlignCenter><Title3>{number}</Title3></BlockAlignCenter>
  </BlockAlignCenter>
}

const formathMonth = (month) => {
  if (typeof month === 'string') {
    return month;
  } else {
    let format = [null, '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    return format[month]
  }
}

const formatYear = (year) => {
  let txt = String(year)
  return txt.slice(-2);
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_payment: state.payment,
    store_language: state.language,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard)