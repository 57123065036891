import React, { Component } from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from 'redux'

import { getCookie, setCookie } from '../../libs/cookies'
import { Title2 } from '../Font'
import { CookieConsentContainer, CookieConsentLink, CookieConsentLinkInline } from './cookieConsent.style'

const cookie_name = '__ps_cookie_accept'

class CookieConsent extends Component {

  constructor(props) {
    super(props)

    this.state = {
      cookie_name: cookie_name,
      cookie_accept: this.getCookieConsent(cookie_name) || new Date(),
    }
  }
  getCookieConsent(cookie_name) {
    let cc = getCookie(cookie_name) || {}
    if (!cc.accept_date) return null;
    return cc.accept_date
  }

  acceptConsent() {
    const { cookie_name } = this.state
    setCookie(cookie_name, { accept_date: new Date() }, 30)
    this.setState({ cookie_accept: new Date() })
  }
  openPolicy() {
    //console.log('Open Consent Policy')
  }

  render() {
    let { store_language: { dictionary } } = this.props

    return <CookieConsentContainer hidden={!!this.state.cookie_accept}>
      <Title2 white>
        {dictionary.cookie_consent} <CookieConsentLinkInline id='btn-open_cookie-policy' onClick={this.openPolicy.bind(this)}>Cookie Policy</CookieConsentLinkInline>.
      </Title2>
      <CookieConsentLink id='btn-accept_cookie-policy' onClick={this.acceptConsent.bind(this)}> {dictionary.accept} </CookieConsentLink>
    </CookieConsentContainer>
  }
}


const mapStateToProps = (state) => {
  return {
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CookieConsent)