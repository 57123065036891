import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { ChartColor } from "./ChartConstant";
import { toCurrency2 } from '../../../../../libs/currency';

const BusinessUsageChartCreditUsageDoughnut = (props) => {
  const { subscription } = useSelector((state) => state.packages);
  const { dictionary } = useSelector((state) => state.language);
  const { itemsUsage } = subscription;
  const overageTransaction = itemsUsage.find((usage) => usage.title_dictionary === "overage_transaction");

  const totalValue = useMemo(() => ({
    tx: overageTransaction.txTotal,
    email: overageTransaction.emailTotal,
    sms: overageTransaction.smsTotal,
    line: overageTransaction.lineTotal,
  }[props.type] || { totalCredit: 0 }), [overageTransaction, props.type]);

  const getDataValuesAndLabels = useMemo(() => (total) => {
    const values = [
      { label: dictionary.chart_point_title, value: total.point, color: ChartColor.point },
      { label: dictionary.chart_coupon_title, value: total.ecard, color: ChartColor.coupon },
      { label: dictionary.chart_broadcast_title, value: total.broadcast, color: ChartColor.broadcast },
      { label: dictionary.chart_member_card_title, value: total.member_card, color: ChartColor.member_card },
      { label: dictionary.chart_trans_noti_expire_member_card_title, value: total.trans_noti_expire_member_card, color: ChartColor.trans_noti_expire_member_card },
      { label: dictionary.chart_reject_collect_points_title, value: total.reject_collect_points, color: ChartColor.reject_collect_points },
      { label: dictionary.chart_trans_noti_expire_point_title, value: total.trans_noti_expire_point, color: ChartColor.trans_noti_expire_point },
    ];

    if (total.totalCredit === 0) {
      return {
        values: [1],
        labels: [dictionary.chart_remain_title],
        colors: [ChartColor.remain],
      };
    }

    const filteredValues = values.filter(item => item.value > 0);
    return {
      values: filteredValues.map(item => item.value.toFixed(2)),
      labels: filteredValues.map(item => item.label),
      colors: filteredValues.map(item => item.color),
    };
  }, [dictionary]);

  const { values, labels, colors } = getDataValuesAndLabels(totalValue);

  const data = useMemo(() => ({
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  }), [labels, values, colors]);

  const options = useMemo(() => ({
    plugins: {
      datalabels: {
        display: false,
      },
    },
    cutoutPercentage: 65,
    legend: false,
    maintainAspectRatio: true,
    responsive: true,
    tooltips: {
      enabled: totalValue.totalCredit !== 0,
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const label = dataset.label || '';
          const value = dataset.data[tooltipItem.index];
          return `${label}: ${value}`;
        },
        title: function(tooltipItem, data) {
          const title = data.labels[tooltipItem[0].index];
          return `${title}`;
        }
      }
    }
  }), [totalValue.totalCredit]);

  return (
    <div>
      <Doughnut
        height={170}
        data={data}
        options={options}
        plugins={[
          {
            id: "textCenter",
            beforeDatasetDraw(chart) {
              const { ctx, width, height } = chart;
              ctx.save();
              ctx.font = `normal ${props.type !== "tx" ? "16" : "14"}px Prompt`;
              ctx.fillStyle = "#546174";
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              ctx.fillText(props.title, width / 2, height / 2 - 20);

              ctx.font = "bold 18px Prompt";
              ctx.fillStyle = "#546174";
              ctx.fillText(toCurrency2(totalValue.totalCredit, 2), width / 2, height / 2 + 5);

              ctx.font = "normal 12px Prompt";
              ctx.fillStyle = "#B2C4D9";
              ctx.fillText(`${dictionary.chart_doughnut_credit_title}`, width / 2, height / 2 + 30);

              ctx.restore();
            },
          },
        ]}
      />
    </div>
  );
}

export default BusinessUsageChartCreditUsageDoughnut;