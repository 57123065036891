import styled, { css } from 'styled-components'

export const AddonsImages = styled.img`
  ${props => props.IconSmall && css`width: 30px !important; border-radius: 7px !important;`}
  width: 70px;
  border-radius: 10px;
  user-select: none;
  @media (max-width: 990px){
    width: 50px;
  }
`

export const AddonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  ${props => props.select && css`background-color: rgb(0,0,0,0.05);`}
  @media (max-width: 990px){
    padding: 10px 20px;
  }
`

export const AddonItem = styled.div`
  border-top: 1px solid ${props => props.theme.border_line};
  :first-child {
    border-top: none;
  }
`

export const AddonsLabelList = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  :last-child {
    margin-bottom: 0px !important;
  }
`

export const AddonsContent = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  ${props => props.select && css`background-color: rgb(0,0,0,0.05);`}
`

export const IconCheckBox = styled.i`
  font-size: 18px;
  color: ${props => props.theme.primary};
`

export const BoxPaymentAgreement = styled.div`
  padding: 0px 20px;
  height: 50vh;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
`

export const ModalBodyBox = styled.div`
  margin: 0rem 0.75rem;
  height: 100%;
  max-height: 500px;

  @media (max-height: 636px) {
    max-height: 400px;
  }
  overflow: hidden;
  overflow-y: scroll;
`