import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import moment from 'moment'
import { Input } from 'reactstrap'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { enUS, th } from 'date-fns/esm/locale'

import { Title3 } from '../Font'
import { Boxcenter } from './InputSelect.style'
import { resetFilterMember } from '../../redux/actions/action_member'

class inputDateRange extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      type: 'today',
      start_date: new Date(),
      end_date: new Date(),
      group_time: 'all',
      show_custom: false,
    }
    this.handleChangeInput = this.handleChangeInput.bind(this)
    this.handleChangeStart = this.handleChangeStart.bind(this)
    this.handleChangeEnd = this.handleChangeEnd.bind(this)
    this.Toggle = this.Toggle.bind(this)
  }

  componentDidMount() {
    let { type, start_date, end_date } = this.props

    if (type === 'custom')
      this.setState({ type: type, start_date: start_date, end_date: end_date })
    else
      this.setState({ type: type })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.store_language.locale !== this.props.store_language.locale)
      this.setState({ type: this.state.type })

    let { store_member: { isResetFilterMember } } = this.props;

    if (isResetFilterMember) {
      this.setState({ type: 'this_month' })
      this.props.resetFilterMember(false);
    }
  }

  handleChangeStart(date) {
    if (!date) date = new Date()
    if (date > this.state.end_date)
      this.setState({ start_date: date, end_date: date }, () => this.Toggle())
    else
      this.setState({ start_date: date }, () => this.Toggle())
  }

  handleChangeEnd(date) {
    if (!date) date = new Date()
    if (date < this.state.start_date)
      this.setState({ end_date: this.state.start_date }, () => this.Toggle())
    else
      this.setState({ end_date: date }, () => this.Toggle())
  }

  onChangeRaw = ({ target: { name } }) => {
    if (name === 'start_date')
      return this.handleChangeStart(new Date())

    if (name === 'end_date')
      return this.handleChangeEnd(new Date())
  }

  handleChangeInput(event) {
    let { value } = event.target
    this.setState({
      type: value,
      show_custom: value === 'custom',
      start_date: value === 'custom' ? this.state.start_date : new Date(),
      end_date: value === 'custom' ? this.state.end_date : new Date(),
    }, () => {
      this.Toggle()
    })
  }

  Toggle() {
    let data = this.getDateValue(this.state.type)
    this.props.toggle(Object.assign({}, this.state, data))
  }

  getDateValue(value_type) {
    let { isShowGroupDate, store_business } = this.props

    let start_date, end_date, group_time
    let type = value_type
    let format_start = 'YYYY-MM-DD 00:00:00'
    let format_end = 'YYYY-MM-DD 23:59:59'

    switch (value_type) {
      case 'yesterday':
        start_date = moment().subtract(1, 'days').format(format_start)
        end_date = moment().subtract(1, 'days').format(format_end)
        group_time = 'day'
        break;

      case 'this_week':
        start_date = moment().day(1).format(format_start)
        end_date = moment().day(1).add(6, 'days').format(format_end)
        group_time = 'day'
        break;

      case 'last_week':
        start_date = moment().subtract(1, 'weeks').day(1).format(format_start)
        end_date = moment().subtract(1, 'weeks').day(1).add(6, 'days').format(format_end)
        group_time = 'day'
        break;

      case 'this_month':
        start_date = moment().startOf('month').format(format_start)
        end_date = moment().endOf('month').format(format_end)
        group_time = 'day'
        break;

      case 'last_month':
        start_date = moment().subtract(1, 'month').startOf('month').format(format_start)
        end_date = moment().subtract(1, 'month').endOf('month').format(format_end)
        group_time = 'day'
        break;

      case 'last_30_days':
        start_date = moment().subtract(30, 'day').startOf('day').format(format_start)
        end_date = moment().endOf('days').format(format_end)
        group_time = 'day'
        break;

      case 'this_quarter':
        const thisQuarters = moment().quarters()
        const monthStart = (thisQuarters - 1) * 3
        start_date = moment().month(monthStart).startOf('month').format(format_start)
        end_date = moment().month(monthStart + 2).endOf('month').format(format_end)
        group_time = 'month'
        break;

      case 'last_quarter':
        let LastQuarters = moment().quarters() - 1
        let month = (LastQuarters - 1) * 3
        if (LastQuarters > 0) {
          start_date = moment().month(month).startOf('month').format(format_start)
          end_date = moment().month(month + 2).endOf('month').format(format_end)
        } else {
          LastQuarters = 4
          month = (LastQuarters - 1) * 3
          const lastYear = moment().subtract(1, 'years').year()
          start_date = moment().year(lastYear).month(month).startOf('month').format(format_start)
          end_date = moment().year(lastYear).month(month + 2).endOf('month').format(format_end)
        }
        group_time = 'month'
        break;

      case 'last_6_month':
        start_date = moment().subtract(6, 'month').startOf('month').format(format_start)
        end_date = moment().endOf('month').format(format_end)
        group_time = 'month'
        break;

      case 'last_12_month':
        start_date = moment().subtract(12, 'month').startOf('month').format(format_start)
        end_date = moment().endOf('month').format(format_end)
        group_time = 'month'
        break;

      case 'this_year':
        start_date = moment().startOf('years').format(format_start)
        end_date = moment().endOf('years').format(format_end)
        group_time = 'month'
        break;

      case 'last_year':
        start_date = moment().subtract(1, 'years').startOf('years').format(format_start)
        end_date = moment().subtract(1, 'years').endOf('years').format(format_end)
        group_time = 'month'
        break;

      case 'all_time':
        start_date = moment(store_business.current.create_date).format(format_start)
        end_date = moment(new Date()).format(format_end)
        group_time = isShowGroupDate && isShowGroupDate.indexOf('all') > -1 ? 'all' : (isShowGroupDate && isShowGroupDate[0]) || 'day'
        break;

      case 'custom':
        start_date = moment(this.state.start_date).format(format_start)
        end_date = moment(this.state.end_date).format(format_end)
        group_time = isShowGroupDate && isShowGroupDate.indexOf('all') > -1 ? 'all' : (isShowGroupDate && isShowGroupDate[0]) || 'day'
        break;

      default:
        start_date = moment().format(format_start)
        end_date = moment().format(format_end)
        group_time = 'day'
        break;
    }

    return { type: type, start_date: start_date, end_date: end_date, group_time: group_time }
  }

  render() {
    let { customRange, title, noTitle, disabled } = this.props
    let { store_language: { dictionary } } = this.props
    let { titleCustom, popperCustom, popperModify } = this.props

    let range = [
      'today', 'yesterday', 'this_week',
      'last_week', 'this_month', 'last_month',
      'last_30_days', 'this_quarter', 'last_quarter',
      'last_6_month', 'last_12_month', 'this_year',
      'last_year', 'all_time', 'custom'
    ]

    if (customRange && customRange.length > 0) {
      range = range.filter(f => customRange.indexOf(f) > -1)
    }

    return <div className='w-100 d-flex flex-column justify-content-center align-items-start'>
      {
        this.props.toggle
        && <div className='w-100 d-flex flex-column align-items-start justify-content-center'>
          {
            !noTitle &&
            (titleCustom ? titleCustom : <Title3 bold className='pl-1' style={{ whiteSpace: 'nowrap' }}>{title ? title : dictionary.date_range}</Title3>
          )}
          <Input
            type="select"
            className='form-control'
            value={this.state.type}
            disabled={disabled}
            onChange={this.handleChangeInput}>
            {range.map((data, key) => <option value={data} key={key}> {dictionary[data]} </option>)}
          </Input>

          {
            this.state.show_custom
              ? <div className='w-100 d-flex align-items-center justify-content-center justify-content-md-end'>
                <Boxcenter className='pt-2'>
                  {/* <Title3 bold className="pr-2 d-none d-lg-block"> {dictionary.custom} : </Title3> */}
                  <div className='w-100'>
                    <DatePicker
                      name='start_date'
                      fixedHeight
                      showYearDropdown
                      showMonthDropdown
                      dateFormat='dd MMM yyyy'
                      popperPlacement={popperCustom ? popperCustom : 'top-start'}
                      popperModifiers={ popperModify 
                        ? {
                          flip: {
                            behavior: ['bottom']
                          },
                          preventOverflow: {
                            enabled: false
                          },
                          hide: {
                            enabled: false
                          }
                        }
                        : {}
                      }
                      locale={dictionary.locale === 'en' ? enUS : th}
                      selected={new Date(this.state.start_date)}
                      startDate={new Date(this.state.start_date)}
                      endDate={new Date(this.state.end_date)}
                      onChangeRaw={this.onChangeRaw}
                      onChange={this.handleChangeStart}
                      className='form-control' />
                  </div>
                  <Title3 bold className='px-2'> - </Title3>
                  <div className='w-100'>
                    <DatePicker
                      name='end_date'
                      fixedHeight
                      showYearDropdown
                      showMonthDropdown
                      dateFormat='dd MMM yyyy'
                      popperPlacement={popperCustom ? popperCustom : 'top-start'}
                      popperModifiers={ popperModify 
                        ? {
                          flip: {
                            behavior: ['bottom']
                          },
                          preventOverflow: {
                            enabled: false
                          },
                          hide: {
                            enabled: false
                          }
                        }
                        : {}
                      }
                      locale={dictionary.locale === 'en' ? enUS : th}
                      minDate={new Date(this.state.start_date)}
                      selected={new Date(this.state.end_date)}
                      startDate={new Date(this.state.start_date)}
                      endDate={new Date(this.state.end_date)}
                      onChangeRaw={this.onChangeRaw}
                      onChange={this.handleChangeEnd}
                      className='form-control' />
                  </div>
                </Boxcenter>
              </div>
              : null
          }
        </div>
      }
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_language: state.language,
    store_admin: state.admin,
    store_member: state.members,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    resetFilterMember
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(inputDateRange) 