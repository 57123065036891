import _ from "lodash";
import { getCookie } from "../../libs/cookies";

const colReceiver = {
	key: "receiver",
	csvTitle: "mobile or id *",
	dictionaryTitle: "job_receiver",
	dictionaryInvalid: "import_receiver_invalid",
	width: 12,
	require: true,
};
const colReceiverInitial = {
	key: "receiver",
	csvTitle: "mobile *",
	dictionaryTitle: "job_receiver",
	dictionaryInvalid: "mobile_invalid",
	width: 12,
	require: true,
};
const colPoint = {
	key: "points",
	csvTitle: "point *",
	dictionaryTitle: "job_points",
	dictionaryInvalid: "import_point_invalid",
	width: 10,
	require: true,
};
const colAmount = {
	key: "amount",
	csvTitle: "amount *",
	dictionaryTitle: "amount",
	dictionaryInvalid: "import_amount_invalid",
	width: 10,
	require: true,
};
const colPointProduct = {
	key: "points",
	csvTitle: "point",
	dictionaryNewline: "product_point",
	dictionaryTitle: "job_points",
	dictionaryInvalid: "import_point_invalid",
	width: 8,
	productPoint: true,
};
const colSKU = {
	key: "sku",
	csvTitle: "SKU  *",
	dictionaryTitle: "sku_number",
	dictionaryInvalid: "incorrect_sku",
	width: 12,
	require: true,
};
const colReceiptNo = {
	key: "receipt_no",
	csvTitle: "Receipt No *",
	dictionaryTitle: "receipt_number",
	dictionaryInvalid: "incorrect_receipt_no",
	width: 15,
	require: true,
};
const colRemark = {
	key: "remark",
	csvTitle: "remark",
	dictionaryTitle: "job_remark",
	width: 18,
};
const colMemberCardName = {
	key: "card_name",
	csvTitle: "member card type name *",
	dictionaryTitle: "member_card_type_name",
	dictionaryInvalid: "import_member_card_invalid",
	width: 20,
	require: true,
};
const colFirstName = {
	key: "first_name",
	csvTitle: "first name",
	dictionaryTitle: "first_name",
	dictionaryInvalid: "import_firstname_invalid",
	width: 14,
};
const colLastName = {
	key: "last_name",
	csvTitle: "last name",
	dictionaryTitle: "last_name",
	dictionaryInvalid: "import_lastname_invalid",
	width: 14,
};

const colGender = {
	key: "gender",
	csvTitle: "gender",
	dictionaryTitle: "gender",
	dictionaryInvalid: "import_gender_invalid",
	width: 8,
};

const colDateOfBirth = {
	key: "date_of_birth",
	sub_key: "age_range",
	csvTitle: "date of birth",
	dictionaryTitle: "date_of_birth",
	dictionaryInvalid: "import_date_of_birth_invalid",
	dictionarySubInvalid: "age_range_invalid",
	width: 10,
};

const colEmail = {
	key: "email",
	csvTitle: "email",
	dictionaryTitle: "email",
	dictionaryInvalid: "import_email_invalid",
	width: 14,
};

const colCustom = {
	key: "custom_form",
	csvTitle: "custom form",
	dictionaryInvalid: "modal_title_incomplete_import_data",
	width: 14,
	additionalCol: true
};

export const getPointExampleCsvData = (type, customRoute, custom_form) => {
	let custom_form_header = ''
	let custom_form_body = ''
	if (custom_form?.length > 0) {
		let lang = getCookie('__lang') || 'th';
		custom_form.forEach((form, index) => {
			let title = lang === "en" && _.isEmpty(form["en"].title)
				? form["th"].title
				: form[lang].title;
			let required = form.is_required ? ' *' : ''
			custom_form_header += `, ${title}${required}`
			custom_form_body += `, example`
		})
	}
	if (customRoute === "/jobsMemberCard") {
		if (type === "product") {
			return [
				`\ufeff${colReceiverInitial.csvTitle},${colAmount.csvTitle},${colSKU.csvTitle},${colReceiptNo.csvTitle},${colRemark.csvTitle},${colFirstName.csvTitle},${colLastName.csvTitle},${colGender.csvTitle},${colDateOfBirth.csvTitle}, ${colEmail.csvTitle}${custom_form_header}`,
				`\n099111xxxx,100,ex1,1,example remark,first name, last name, male, 31-01-1980, example@email.com${custom_form_body}`,
				`\n099111xxxx,100,ex2,2,example remark,first name, last name, male, 31-01-1980, example@email.com${custom_form_body}`,
				`\n099111xxxx,100,ex3,2,example remark,first name, last name, male, 31-01-1980, example@email.com${custom_form_body}`,
				`\n099111xxxx,100,ex1,4,example remark,first name, last name, male, 31-01-1980, example@email.com${custom_form_body}`,
				`\n099111xxxx,100,ex4,3,example remark,first name, last name, male, 31-01-1980, example@email.com${custom_form_body}`,
			];
		} else {
			return [
				`\ufeff${colReceiverInitial.csvTitle},${type === "amount" ? colAmount.csvTitle : colPoint.csvTitle},${
					colRemark.csvTitle
				},${colFirstName.csvTitle},${colLastName.csvTitle},${colGender.csvTitle},${colDateOfBirth.csvTitle}, ${
					colEmail.csvTitle
				}${custom_form_header}`,
				`\n099111xxxx,100,example remark, first name, last name, male, 31-01-1980, example@email.com${custom_form_body}`,
			];
		}
	} else {
		if (type === "product") {
			return [
				`\ufeff${colReceiver.csvTitle},${colAmount.csvTitle},${colSKU.csvTitle},${colReceiptNo.csvTitle},${colRemark.csvTitle}`,
				`\n099111xxxx,100,ex1,1,example remark`,
				`\n099111xxxx,100,ex2,2,example remark`,
				`\n099111xxxx,100,ex3,2,example remark`,
				`\n099111xxxx,100,ex1,4,example remark`,
				`\n099111xxxx,100,ex4,3,example remark`,
			];
		} else {
			return [
				`\ufeff${colReceiver.csvTitle},${type === "amount" ? colAmount.csvTitle : colPoint.csvTitle},${
					colRemark.csvTitle
				}`,
				`\n099111xxxx,100,example remark`,
			];
		}
	}
};

export const getcsvTitle = (importType, type, customRoute, custom_form, lang) => {
	let cols;
	if (customRoute === "/jobsMemberCard") {
		if (importType === "product") {
			cols = [
				colReceiverInitial,
				colAmount,
				colSKU,
				colReceiptNo,
				colRemark,
				colFirstName,
				colLastName,
				colGender,
				colDateOfBirth,
				colEmail,
			];
		} else if (type === "membercard") {
			cols = [
				colReceiverInitial,
				colMemberCardName,
				{ ...colFirstName, csvTitle: `${colFirstName.csvTitle} *` },
				{ ...colLastName, csvTitle: `${colLastName.csvTitle} *` },
				colGender,
				colDateOfBirth,
				colEmail
			];
		} else {
			cols =
				importType === "amount"
					? [colReceiverInitial, colAmount, colRemark, colFirstName, colLastName, colGender, colDateOfBirth, colEmail]
					: [colReceiverInitial, colPoint, colRemark, colFirstName, colLastName, colGender, colDateOfBirth, colEmail];
		}
		cols = getCustomFormKeys(cols, custom_form, lang)
	} else {
		if (type === "point") {
			cols =
				importType === "amount"
					? [colReceiver, colAmount, colRemark]
					: importType === "points"
					? [colReceiver, colPoint, colRemark]
					: [colReceiver, colAmount, colSKU, colReceiptNo, colRemark];
		}
	}
	return _.map(cols, "csvTitle");
};

export const getPointKeys = (type, customRoute, custom_form) => {
	var cols;
	if (customRoute === "/jobsMemberCard") {
		if (type === "product") {
			cols = [
				colReceiverInitial,
				colAmount,
				colSKU,
				colReceiptNo,
				colRemark,
				colFirstName,
				colLastName,
				colGender,
				colDateOfBirth,
				colEmail
			];
		} else {
			cols =
				type === "amount"
					? [colReceiverInitial, colAmount, colRemark, colFirstName, colLastName, colGender, colDateOfBirth, colEmail]
					: [colReceiverInitial, colPoint, colRemark, colFirstName, colLastName, colGender, colDateOfBirth, colEmail];
		}
		if (custom_form) {
			cols = getCustomFormKeys(cols, custom_form)
		}
	} else {
		cols =
			type === "amount"
				? [colReceiver, colAmount, colRemark]
				: type === "points"
				? [colReceiver, colPoint, colRemark]
				: [colReceiver, colAmount, colSKU, colReceiptNo, colRemark];
	}
	return _.map(cols, "key");
};

export const getPointColumns = (type, customRoute, custom_form) => {
	if (customRoute === "/jobsMemberCard") {
		if (type === "product") {
			let columns = [
				colReceiverInitial,
				colAmount,
				colPointProduct,
				colSKU,
				colReceiptNo,
				colRemark,
				colFirstName,
				colLastName,
				colGender,
				colDateOfBirth,
				colEmail,
			]
			if (custom_form?.length > 0) {
				columns = getCustomFormKeys(columns, custom_form)
			}
			return columns;
		} else {
			let amountColumns = [colReceiverInitial, colAmount, colPoint, colRemark, colFirstName, colLastName, colGender, colDateOfBirth, colEmail]
			let pointColumns = [colReceiverInitial, colPoint, colRemark, colFirstName, colLastName, colGender, colDateOfBirth, colEmail]
			if (custom_form?.length > 0) {
				amountColumns = getCustomFormKeys(amountColumns, custom_form)
				pointColumns = getCustomFormKeys(pointColumns, custom_form)
			}
			return type === "amount"
				? amountColumns
				: pointColumns;
		}
	} else {
		return type === "points"
			? [colReceiver, colPoint, colRemark]
			: type === "product"
			? [colReceiver, colAmount, colPointProduct, colSKU, colReceiptNo, colRemark]
			: [colReceiver, colAmount, colPoint, colRemark];
	}
};

export const getProductColumns = (customRoute, custom_form) => {
	var cols;
	if (customRoute === "/jobsMemberCard"){
		cols = [
			colReceiverInitial,
			colAmount,
			colPointProduct,
			colSKU,
			colReceiptNo,
			colRemark,
			colFirstName,
			colLastName,
			colGender,
			colDateOfBirth,
			colEmail
		]
		cols.map((col) => {
			if (col.width > 10) col.width = 10
			return col
		})
		if (custom_form) {
			cols = getCustomFormKeys(cols, custom_form, 5)
		}
	} else {
	  	cols = [colReceiver, colAmount, colPoint, colSKU, colReceiptNo, colRemark]
	}
	return cols;
};

export const getMemberCardExampleCsvData = (custom_form) => {
	let memberCardExampleCsvData = [
		`\ufeff${colReceiverInitial.csvTitle},${colMemberCardName.csvTitle},${colFirstName.csvTitle} *, ${colLastName.csvTitle} * ,${colGender.csvTitle},${colDateOfBirth.csvTitle}, ${colEmail.csvTitle}`,
		`\n099111xxxx, Gold Member, first name, last name, male, 31-01-1980, example@email.com`,
	]
	if (custom_form?.length > 0) {
		let lang = getCookie('__lang') || 'th';
		custom_form.map((form, index) => {
			let title = lang === "en" && _.isEmpty(form["en"].title)
				? form["th"].title
				: form[lang].title;
			let currentExample = [...memberCardExampleCsvData]
			let required = form.is_required ? ' *' : ''
			currentExample[0] = currentExample[0] + `, ${title}${required}`
			currentExample[1] = currentExample[1] + `, example`
			return memberCardExampleCsvData.splice(0, 2, ...currentExample)
		})
	}
	return memberCardExampleCsvData;
};

export const getMemberCardKeys = (custom_form) => {
	let keys = _.map([colReceiver, colMemberCardName, colFirstName, colLastName, colGender, colDateOfBirth, colEmail], "key");
	if (custom_form?.length > 0) {
		custom_form.map((form, index) => keys.push(`custom_form_${form.id}`))
	}
	return keys;
};

export const getMemberCardColumns = (custom_form) => {
	let memberCardColumns = [
		colReceiverInitial,
		colMemberCardName,
		{ ...colFirstName, require: true, csvTitle: `${colFirstName.csvTitle} *` },
		{ ...colLastName, require: true, csvTitle: `${colLastName.csvTitle} *` },
		colGender,
		colDateOfBirth,
		colEmail
	]
	if (custom_form?.length > 0) {
		memberCardColumns = getCustomFormKeys(memberCardColumns, custom_form)
	}
	return memberCardColumns;
};

const getCustomFormKeys = (columns, custom_form, lang) => {
	custom_form.map((form, index) => {
    let titleEn = _.isEmpty(form["en"].title)
      ? form["th"].title
      : form["en"].title;
		let required = form.is_required ? ' *' : ''
    return columns.push(
      Object.assign({}, colCustom, {
        key: `custom_form_${form.id}`,
        title: { th: form["th"].title + required, en: titleEn + required },
				csvTitle: lang === 'en' ? titleEn + required : form["th"].title + required,
      })
    );
  });
	return columns;
}