import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Progress } from 'reactstrap'

import { DangerBtn } from '../Button'
import { Title2, Title3 } from '../Font'

import { getMonth } from '../../libs/date'
import { toCurrency } from '../../libs/currency'
import _ from 'lodash'
import { hasValueInArray } from '../../libs/validator'
import { getBusinessPackage } from '../../redux/actions/action_package'

export default function BusinessUsage (props) {
  const dispatch = useDispatch()
  const { dictionary } = useSelector((state) => state.language)
  const store_package = useSelector((state) => state.packages)
  const store_business = useSelector((state) => state.business)
  const business_code = store_business.current.business_code
  const [progressBarShow, setProgressBarShow] = useState(false)
  let { packages = {}, packages_free } = store_package
  useEffect(() => {
    if (typeof packages.package_data === 'undefined') {
      dispatch(getBusinessPackage({ business_code, load: false }))
    }
    if (!packages.subscription_package || (packages.package_data && hasValueInArray(packages_free, packages.package_data.package_id))) {
      setProgressBarShow(true)
    }
  }, [packages]) // eslint-disable-line react-hooks/exhaustive-deps
  const getProgressColor = (value, max) => {
    return value > max * 0.95 ? 'danger'
      : value > max * 0.80 ? 'warning' : 'info'
  }
  let { overLimit } = props
  let { createDate, usageMember, usageTransaction, business_limit, usageTxAllTotal } = props

  let txFreeLimit = !_.isEmpty(packages.package_data) ? packages.package_data.items.filter(f => f.item_id === 'ps-transaction-free-limit-100') : null
  let txOverFreeLimit = !_.isEmpty(packages.package_data) && (packages.package_data.package_id === 'ps-free-trial' || packages.package_data.package_id === 'ps-free-trial-2023') ? usageTxAllTotal >= txFreeLimit[0].max_quantity : null
  
  const FreeTxOverLimit = () => {
    return (
      <>
        <div className='pt-1 d-flex align-items-center justify-content-between'>
          <Title3 bold>{dictionary.transaction_usage}</Title3>
          <Title3>{`${toCurrency(usageTxAllTotal)} ${dictionary.transactions}`}</Title3>
        </div>
        <div className='pt-1' hidden={!progressBarShow}>
          <Progress multi>
            <Progress bar color={getProgressColor(usageTxAllTotal, txFreeLimit[0].max_quantity)}
              value={usageTxAllTotal} max={txFreeLimit[0].max_quantity} />
          </Progress>
        </div>
      </>
    )
  }

  return <div style={{ padding: '0.5rem 1rem' }}>
    <div className='pt-1 d-flex align-items-center justify-content-center'>
      <Title2 bold className='text-center'>{`${dictionary.usage_in} ${getMonth(createDate, 'MMM YYYY', dictionary.locale)}`}</Title2>
    </div>

    <div className='pt-1 d-flex align-items-center justify-content-between'>
      <Title3 bold>{dictionary.member}</Title3>
      <Title3>{`${toCurrency(usageMember)} ${dictionary.report_members}`}</Title3>
    </div>
    <div className='pt-1' hidden={!progressBarShow}>
      <Progress multi>
        <Progress bar color={getProgressColor(usageMember, business_limit.member)} 
        value={usageMember} max={business_limit.member} />
      </Progress>
    </div>

    {
      packages.package_data && (packages.package_data.package_id === 'ps-free-trial' || packages.package_data.package_id === 'ps-free-trial-2023') && txOverFreeLimit ?
      <FreeTxOverLimit/> :
      <>
        <div className='pt-1 d-flex align-items-center justify-content-between'>
          <Title3 bold>{dictionary.transactions}</Title3>
          <Title3>{`${toCurrency(usageTransaction)} ${dictionary.transactions}`}</Title3>
        </div>
        <div className='pt-1' hidden={!progressBarShow}>
          <Progress multi>
            <Progress bar color={getProgressColor(usageTransaction, business_limit.transaction)}
              value={usageTransaction} max={business_limit.transaction} />
          </Progress>
        </div>
      </>
    }

    {
      packages.subscription_status === 'hold' ||  packages.subscription_status === 'inactive'
        ? <div onClick={() => { window.open(`${process.env.REACT_APP_RP_ACCOUNT}/invoices`, 'RPAuth') }}>
          <DangerBtn sm bold fluid invert style={{ marginTop: '0.4rem' }}>{dictionary.payment_failed}</DangerBtn>
        </div>
        : overLimit
          ? <Link to={'/packages'}>
            <DangerBtn sm bold fluid invert style={{ marginTop: '0.4rem' }}>{dictionary.over_usage_plz_upgrade}</DangerBtn>
          </Link>
          : null
    }
  </div>
}
