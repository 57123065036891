import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { InvertBtn, PrimaryBtn } from "../../components/Button";
import { Title1, Title2 } from "../../components/Font";
import { toCurrency } from "../../libs/currency";
import { DEBUG } from "../../libs/utils";
import { formatDateTime } from "../../libs/date";
import {
  createSubscription,
  getMoreCreditBalanceHistory,
  getPrepaidData,
  resetProcessingStatus,
  setCreditBalance,
  setPrepaidStatus,
} from "../../redux/actions/action_manager";
import { ModelDetailFooter } from "../VerifyBusiness/verifyBusiness.style";
import {
  BoxProcess,
  BoxProcessHead,
  VerifyModalFooter,
  VerifyModalHeader,
} from "./verifyBusinessHash.style";
import Loading from "./Loading";
import { MANAGER } from "../../redux/actions_const";

export default function CreditInfo({ info }) {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(true);
  const [isShowStatusModal, setShowStatusModal] = useState(false);
  const [isShowTopUpModal, setShowTopUpModal] = useState(false);
  const isInited = useRef(false);
  const isLoading = useSelector((state) => state.manager.isLoadingCreditInfo);
  const dictionary = useSelector((state) => state.language.dictionary);
  const customerToken = useSelector((state) => state.manager.customerToken);
  const isPrepaid = useSelector((state) => state.manager.isPrepaid);
  const prepaidInfo = useSelector((state) => state.manager.prepaidInfo);
  const isTrial = useSelector((state) => state.manager.isTrial);
  const subscriptionInfo = useSelector((state) => state.manager.subscriptionInfo);
  const newBalanceTransactionId = useSelector(
    (state) => state.manager.newBalanceTransactionId
  );
  const userData = useSelector((state) => state.manager.userData);

  DEBUG("[CreditInfo]", info, customerToken, isPrepaid, prepaidInfo, isTrial);

  useEffect(() => {
    DEBUG("[useEffect] CreditInfo", customerToken, isPrepaid, isInited.current);
    if (
      !isTrial &&
      customerToken &&
      (!isInited.current ||
        (isPrepaid && _.isEmpty(prepaidInfo)) ||
        newBalanceTransactionId)
    ) {
      if (!isInited.current) {
        isInited.current = true;
      }

      dispatch(
        getPrepaidData(customerToken)
      );
    }
  }, [customerToken, isPrepaid, prepaidInfo, isTrial, newBalanceTransactionId, dispatch, userData]);

  const onStatusChanged = (data) => {
    const { selectedPackage, isPrepaid } = data;
    DEBUG("onStatusChanged", selectedPackage, isPrepaid);
    if (selectedPackage) {
      dispatch(
        createSubscription({
          business_info: info,
          package_id: selectedPackage,
          prepaid: isPrepaid,
        })
      );
    } else {
      dispatch(
        setPrepaidStatus({
          subscription_id: subscriptionInfo.id,
          customer_token_id: customerToken,
          prepaid: isPrepaid,
        })
      );
    }
  };

  const onTopUpSubmitted = (data) => {
    const { amount, quotation } = data;
    DEBUG("topUpSubmitted", amount, quotation);
    dispatch(
      setCreditBalance({
        customer_token_id: customerToken,
        quotation_id: quotation,
        amount,
      })
    );
  };

  const onEditStatusClicked = () => {
    dispatch(resetProcessingStatus());
    setShowStatusModal(true);
  }

  const onTopUpClicked = () => {
    dispatch(resetProcessingStatus());
    setShowTopUpModal(true);
  }

  return isTrial ? null : (
    <BoxProcess>
      <BoxProcessHead
        className="justify-content-between align-items-center"
        pointer
        onClick={() => setExpand(!expand)}
      >
        <Title2 bold white>
          Pointspot Credit
        </Title2>
        {expand ? (
          <i className="fas fa-chevron-up"></i>
        ) : (
          <i className="fas fa-chevron-down"></i>
        )}
      </BoxProcessHead>
      {expand ? (
        isLoading ? (
          <Loading />
        ) : (
          <div style={{ padding: "20px" }}>
            {isPrepaid ? (
              <>
                <Title2 bold>ยอดเงินคงเหลือ</Title2>
                <div className="d-flex p-3 justify-content-between align-items-center">
                  <Title1 bold>{`
                  ${toCurrency(prepaidInfo.credit, 2)} ${
                    dictionary[prepaidInfo.currency || "thb"]
                  }
                  `}</Title1>
                  <PrimaryBtn
                    onClick={onTopUpClicked}
                  >
                    เติมเงิน
                  </PrimaryBtn>
                </div>
              </>
            ) : null}
            <Title2 bold>สถานะบัญชี</Title2>
            <div className="d-flex p-3 justify-content-between align-items-center">
              <Title2>{isPrepaid ? "Prepaid" : "Postpaid"}</Title2>
              {!isTrial ? (
                <PrimaryBtn
                  onClick={onEditStatusClicked}
                >
                  ปรับสถานะ
                </PrimaryBtn>
              ) : null}
            </div>
            {isPrepaid ? <TopUpHistory customerToken={customerToken} /> : null}
            {isShowStatusModal && (
              <AccountStatusModal
                isOpen={isShowStatusModal}
                toggle={() => setShowStatusModal(!isShowStatusModal)}
                onSubmit={onStatusChanged}
                isPrepaid={isPrepaid}
              />
            )}
            {isShowTopUpModal && (
              <TopUpModal
                isOpen={isShowTopUpModal}
                toggle={() => setShowTopUpModal(!isShowTopUpModal)}
                onSubmit={onTopUpSubmitted}
              />
            )}
          </div>
        )
      ) : null}
    </BoxProcess>
  );
}

const AccountStatusModal = ({ isOpen, toggle, onSubmit, isPrepaid }) => {
  const isInited = useRef(false);
  const processingStatus = useSelector(
    (state) => state.manager.processingStatus
  );
  const currentPackage = useSelector((state) => state.manager.packageInfo);
  const dictionary = useSelector((state) => state.language.dictionary);
  const [status, setStatus] = useState(isPrepaid ? "prepaid" : "postpaid");

  useEffect(() => {
    DEBUG("[useEffect] AccountStatusModal", processingStatus, isOpen);
    if (!isInited.current) {
      isInited.current = true;
    } else {
      if (isOpen && processingStatus.status === MANAGER.PROCESS_STATUS_SUCCEED) {
        toggle();
      }
    }
  }, [processingStatus, isOpen, isInited, toggle]);

  const isProcessing = processingStatus.status === MANAGER.PROCESS_STATUS_WORKING;
  const isFreePackage = _.isEmpty(currentPackage);
  const statusOptions = [
    {
      id: "prepaid",
      title: "Prepaid",
    },
    {
      id: "postpaid",
      title: "PostPaid",
    },
  ];
  const availabelPackages = [
    {
      id: "ps-premium",
      title: "Premium",
    },
    {
      id: "ps-gold",
      title: "Gold",
    },
  ];

  const [selectedPackage, setSelectedPackage] = useState(
    _.get(currentPackage, ["package_id"]) || availabelPackages[0].id
  );

  const isEditing =
    isFreePackage ||
    _.get(currentPackage, ["package_id"]) !== selectedPackage ||
    (isPrepaid && status === "postpaid") ||
    (!isPrepaid && status === "prepaid");

  const onSubmitClick = () => {
    if (isFreePackage) {
      onSubmit({
        selectedPackage,
        isPrepaid: status === "prepaid",
      });
    } else {
      onSubmit({
        isPrepaid: status === "prepaid",
      });
    }
  };

  return (
    <Modal isOpen={isOpen} centered={true}>
      <VerifyModalHeader>
        <Title2 white bold>
          ปรับสถานะบัญชี
        </Title2>
      </VerifyModalHeader>
      <ModalBody>
        <Title2 bold className="mb-2">
          แพคเกจปัจจุบัน
        </Title2>
        <Title2 className="mb-3 pl-3">
          {dictionary[currentPackage.title_dictionary] ||
            currentPackage.title_dictionary ||
            "Free"}
        </Title2>
        {isFreePackage ? (
          <>
            <Title2 bold className="mb-2">
              เลือกแพคเกจ
            </Title2>
            <div className="d-flex align-items-center mb-3 pl-3">
              {availabelPackages.map((item, index) => (
                <div key={index} className="d-flex align-items-center pr-4">
                  <input
                    className="pointer"
                    type="radio"
                    name={item.id}
                    onChange={() => setSelectedPackage(item.id)}
                    checked={selectedPackage === item.id}
                  />
                  <Title2
                    className="pl-2 pointer"
                    onClick={() => setSelectedPackage(item.id)}
                  >
                    {item.title}
                  </Title2>
                </div>
              ))}
            </div>
          </>
        ) : null}
        <Title2 bold className="mb-2">
          สถานะบัญชี
        </Title2>
        <div className="d-flex align-items-center mb-3 pl-3">
          {statusOptions.map((item, index) => (
            <div key={index} className="d-flex align-items-center pr-4">
              <input
                className="pointer"
                type="radio"
                name={item.id}
                onChange={() => setStatus(item.id)}
                checked={status === item.id}
              />
              <Title2
                className="pl-2 pointer"
                onClick={() => setStatus(item.id)}
              >
                {item.title}
              </Title2>
            </div>
          ))}
        </div>
      </ModalBody>
      <VerifyModalFooter className="d-flex flex-grow-1 justify-content-end">
        <InvertBtn onClick={toggle} disabled={isProcessing}>
          ยกเลิก
        </InvertBtn>
        <PrimaryBtn
          className="ml-2"
          onClick={onSubmitClick}
          disabled={isProcessing || !isEditing}
        >
          {isProcessing ? <i className="fas fa-spinner fa-pulse" /> : "ยืนยัน"}
        </PrimaryBtn>
      </VerifyModalFooter>
    </Modal>
  );
};

const TopUpModal = ({ isOpen, toggle, onSubmit }) => {
  const isInited = useRef(false);
  const processingStatus = useSelector(
    (state) => state.manager.processingStatus
  );
  const [amount, setAmount] = useState("");
  const [quotation, setQuotation] = useState("");
  const quotationPattern = /^[A-Za-z]*\d{6}-[A-Za-z]*\d+$/gi;
  const isEditing = amount && quotation && quotationPattern.test(quotation);
  const isProcessing = processingStatus.status === MANAGER.PROCESS_STATUS_WORKING;

  useEffect(() => {
    DEBUG("[useEffect] TopUpModal", processingStatus, isOpen);
    if (!isInited.current) {
      isInited.current = true;
    } else {
      if (isOpen && processingStatus.status === MANAGER.PROCESS_STATUS_SUCCEED) {
        toggle();
      }
    }
  }, [processingStatus, isOpen, isInited, toggle]);

  const onSubmitClick = () => {
    onSubmit({
      amount,
      quotation,
    });
  };

  const onTextChange = (event) => {
    const { name, value } = event.target;
    DEBUG("[TopUpModal] onTextChange", name, value);
    if (name === "quotation") {
      setQuotation(value);
    } else if (name === "amount" && !isNaN(value)) {
      const amt = parseFloat(value);
      setAmount(amt > 0 ? amt : "");
    }
  };

  return (
    <Modal isOpen={isOpen} centered={true}>
      <VerifyModalHeader>
        <Title2 white bold>
          เติมเงิน
        </Title2>
      </VerifyModalHeader>
      <ModalBody>
        <Title2 bold className="mb-2">
          จำนวนเงินที่ต้องการเติม (รวมภาษีแล้ว)
        </Title2>
        <div className="d-flex align-items-center mb-3 pl-3">
          <input
            className="form-control mr-2 mr-3"
            type="number"
            name="amount"
            value={amount}
            onChange={onTextChange}
            placeholder="1"
            min={1}
          />
          <Title2>บาท</Title2>
        </div>
        <Title2 bold className="mb-2">
          เลข Quotation
        </Title2>
        <div className="d-flex align-items-center mb-3 pl-3">
          <input
            className="form-control mr-2"
            type="text"
            name="quotation"
            value={quotation}
            onChange={onTextChange}
            placeholder="[ANY][YYYY][MM]-[AUTO] เช่น Q202105-44749"
          />
        </div>
      </ModalBody>
      <VerifyModalFooter className="d-flex flex-grow-1 justify-content-end">
        <InvertBtn onClick={toggle} disabled={isProcessing}>
          ยกเลิก
        </InvertBtn>
        <PrimaryBtn
          className="ml-2"
          onClick={onSubmitClick}
          disabled={isProcessing || !isEditing}
        >
          {isProcessing ? <i className="fas fa-spinner fa-pulse" /> : "ยืนยัน"}
        </PrimaryBtn>
      </VerifyModalFooter>
    </Modal>
  );
};

const TopUpHistory = ({ customerToken }) => {
  const dispatch = useDispatch();
  const transactions = useSelector(
    (state) => state.manager.creditBalanceHistory
  );
  const hasMore = useSelector(
    (state) => state.manager.hasMoreCreditBalanceHistory
  );
  DEBUG("[TopUpHistory]", hasMore, transactions);
  /*
   * amount is an integer in cents
   * 100 cent = 1 dollar
   * A negative amount represents a credit
   * Readmore: https://stripe.com/docs/api/customers/create#create_customer-balance
   */

  const viewMore = () => {
    const offset =
      parseInt(_.first(transactions).rank || 0) -
      parseInt(_.last(transactions).rank) + 1;

    dispatch(getMoreCreditBalanceHistory(customerToken, offset));
  };

  return !_.isEmpty(transactions) ? (
    <>
      <ModelDetailFooter />
      <Title2 bold>ประวัติการเติมเงิน</Title2>
      <div className="d-flex p-3 justify-content-between">
        <Title2 bold>วันที่เติมเงิน</Title2>
        <Title2 bold>จำนวนเงิน (บาท)</Title2>
      </div>
      {transactions.map((item, index) => {
        const createDate = moment(item.create_date);
        return (
          <div
            key={index}
            className="d-flex pl-3 pr-3 mb-3 justify-content-between"
          >
            <Title2>{formatDateTime(createDate.valueOf())}</Title2>
            <Title2>{toCurrency(item.amount * -0.01, 2)}</Title2>
          </div>
        );
      })}
      {hasMore ? (
        <Title2 className="pl-3 pr-3" onClick={viewMore} underline link blue>
          ดูประวัติการเติมเงินเพิ่มเติม
        </Title2>
      ) : null}
    </>
  ) : null;
};
