import React, { Component } from 'react';
import { Title2 } from '../Font'
import {
  CreditCardShow, BlockAlignCenter, BlackCircle, CreditCardShowDetail, CreditLogoImage, ChipCard, InChipCard
} from './cardModel.style'

import pointspot from '../../assets/img/logo.svg'
import jcb from '../../assets/img/creditCard/JCB.svg'
import visa from '../../assets/img/creditCard/visa.svg'
import unionPay from '../../assets/img/creditCard/unionPay.svg'
import discover from '../../assets/img/creditCard/discover.svg'
import mastercard from '../../assets/img/creditCard/mastercard.svg'
import dinersClub from '../../assets/img/creditCard/dinersClub.svg'
import americanExpress from '../../assets/img/creditCard/americanExpress.svg'

class CardModel extends Component {
  render() {

    let imageCard = [
      { name: 'American Express', image: americanExpress },
      { name: 'Diners Club', image: dinersClub },
      { name: 'Discover', image: discover },
      { name: 'JCB', image: jcb },
      { name: 'MasterCard', image: mastercard },
      { name: 'UnionPay', image: unionPay },
      { name: 'Visa', image: visa },
      { name: 'Unknown', image: pointspot },
    ]
    let { card_type, card_last4, title_card } = this.props

    let logo = card_type ? imageCard.find(b => b.name.toLowerCase() === card_type.toLowerCase()) : {};

    return (
      <CreditCardShow>
        <Title2 bold>{title_card}</Title2>
        <ChipCard className='d-flex justify-content-between'>
          <div className=' d-flex justify-content-between' style={{ flexDirection: 'column' }}>
            <InChipCard topLeft />
            <InChipCard centerLeft />
            <InChipCard centerLeft />
            <InChipCard bottomLeft />
          </div>
          <div className=' d-flex justify-content-between' style={{ flexDirection: 'column' }} >
            <InChipCard topRight />
            <InChipCard centerRight />
            <InChipCard bottomRight />
          </div>
        </ChipCard>
        <CreditCardShowDetail>
          <div className='d-flex align-items-center justify-content-between'>
            <BlockAlignCenter>
              <BlackCircle /><BlackCircle /><BlackCircle /><BlackCircle /><div className='pr-2' />
              <BlackCircle /><BlackCircle /><BlackCircle /><BlackCircle /><div className='pr-2' />
              <BlackCircle /><BlackCircle /><BlackCircle /><BlackCircle /><div className='pr-2' />
              <Title2 bold>{card_last4}</Title2>
            </BlockAlignCenter>
            <CreditLogoImage style={{ marginLeft: '10px' }} alt='' src={logo.image} />
          </div>
        </CreditCardShowDetail>
      </CreditCardShow>
    );
  }
}

export default CardModel;