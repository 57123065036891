import styled from "styled-components";
import { lighten, darken } from "polished";
import { hexToHSL } from "../../libs/hextohsl";

export const CardMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => (props.isManual ? "0px" : "20px")};
  position: relative;
  perspective: 1000px;
`;

export const MemberCardContainerBackground = styled.div`
  background-color: ${(props) => props.primary + "80"};
  background-image: ${(props) =>
    props.croppedImage
      ? `url(${props.croppedImage})`
      : `url(${props.background_image})`};
  background-size: cover;
  background-position: center;
  height: 170px;
  border-radius: 8px;
  width: 300px;
`;

export const MemberCardContainer = styled.div`
  background-color: white;
  width: 302px;
  height: 172px;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #eeeeee;
  transform-style: preserve-3d;
  transform: ${(props) =>
    props.rotateCard ? "rotateY(180deg)" : "rotateY(0)"};
  transition: transform 1s;
`;

export const BackgroundUploadedImage = styled.img`
  width: 300px;
  height: 170px;
  border-radius: 8px;
  position: absolute;
`;

export const CardFront = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(0deg);
`;

export const CardBack = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(180deg);
`;

export const ButtonAndExpire = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: flex-end;
  position: relative;
`;

export const QRButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.primary};
  background-color: white;
  position: absolute;
  right: -15px;
  top: -30px;
  box-shadow: 0px 1px 3px ${(props) => props.primary};
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ExpireBox = styled.div`
  margin-top: 10px;
  align-self: center;
  color: ${(props) => props.themeColor};
  font-size: 12px;
`;

export const BusinessLogo = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #f0f0f0;
  margin-top: 20px;
  margin-left: 20px;
  border: 1px solid ${(props) => lighten(0.1, props.primary)};
`;

export const CardNameBar = styled.div`
  background-color: ${(props) => props.primary};
  color: ${(props) => props.fontColor};
  min-height: 30px;
  width: 200px;
  border-radius: 0px 7px 0px 30px;
  padding: 5px 10px 5px 25px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const IDContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const IDBox = styled.div`
  color: ${(props) => lighten(0.3, props.fontColor)};
  text-align: center;
  position: absolute;
  bottom: 10px;
`;

export const BackMemberCardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  justify-content: space-around;
  align-items: center;
`;

export const BusinessQR = styled.div`
  width: 120px;
  height: 120px;
  padding: 8px;
  border-radius: 10px;
  overflow: hidden;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const QRCodeImg = styled.img`
  width: 100px;
  height: 100px;
`;

//MemberCardInfoSection
export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MemberCardTabBar = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  background-color: ${(props) => hexToHSL(props.themeColor, { l: 98 })};
  margin-top: 30px;
`;

export const TabMenu = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  text-align: center;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
`;

export const SelectedBox = styled.div`
  background-color: ${(props) => hexToHSL(props.themeColor, { l: 92 })};
  border-radius: 8px;
  position: absolute;
  height: 30px;
  margin-top: 10px;
  width: calc(50% - 20px);
  margin-left: 20px;
  transition: transform 0.5s ease-in-out;
  transform: translateX(${(props) => props.position}%);
  z-index: 0;

  @media (max-width: 767px) {
    width: calc(50% - 20px);
  }
`;

//Info
export const InfoContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 160px;
  overflow-y: auto;
  color: ${(props) => props.themeColor};
  font-size: 14px;
`;

export const InfoTitle = styled.div`
  min-width: 100px;
  width: auto;
  padding-right: 10px;
  color: ${(props) => darken(0.1, props.themeColor)};
`;

//Privilege
export const PrivilegeContainer = styled.div`
  margin-top: 10px;
  padding: 10px 20px 20px 20px;
  height: 200px;
  overflow-y: auto;
  color: ${(props) => props.themeColor};
  font-size: 14px;
  word-break: break-word;
`;
