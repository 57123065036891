import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Input } from 'reactstrap'
import { Title3 } from '../../Font'
import { usePrevious } from '../../../libs/hooks/usePrevious'

export default function RangePointLessEqual({
	min,
	onChangePoint,
	onSetInvalid,
	isInvalid,
	start = '',
	end = '',
	disabled
}) {
	const setMin = min || 0
	const pointStart = start
	const [pointEnd, setPointEnd] = useState(end)
	const pointEndPrevious = usePrevious(pointEnd)
	const dictionary = useSelector((state) => state.language.dictionary)
	const isMounted = useRef()

	useEffect(() => {
		if (
			isMounted.current &&
			JSON.stringify(pointEndPrevious) !== JSON.stringify(pointEnd)
		) {
			onChangePoint({
				start: pointStart,
				end: pointEnd
			});
		}

		if (!isMounted.current) {
			isMounted.current = true
			onChangePoint({
				start: pointStart,
				end: pointEnd
			})
		}
		
	}, [onChangePoint, pointStart, pointEndPrevious, pointEnd])

	const handleChange = (e) => {
		let { value } = e.target

		onSetInvalid(false)

		if (value === '') {
			onSetInvalid(true)
		}

		value = parseInt(value)
		
		if (value < 0) value = setMin
		if (isNaN(value)) value = ''

		setPointEnd(() => {
			const end = value.toString().replace('/^0+', '')
			return end
		})
	}

	return (
		<>
			<Col xs={7} sm={5} className='p-0'>
				<Input
					type="number"
					id="point_less"
					name='point_less'
					disabled={disabled}
					value={pointEnd}
					cssModule={{ 'is-invalid': 'invalid-input-point' }}
					invalid={isInvalid}
					placeholder='0'
					onChange={handleChange} >
				</Input>
			</Col>
			<Col xs={2} sm={2} className='align-self-center p-0 pl-2'>
				<Title3>{dictionary.points.toLowerCase()}</Title3>
			</Col>
			{
				isInvalid &&
				<Col xs={10} sm={10} className='p-0'>
					<Title3 className='text-danger p-0 pl-1 pt-2'>{dictionary.points_invalid}</Title3>
				</Col>
			}
		</>
	)
}