import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import { userLogout, checkLogin, getAccountProfile } from '../../redux/actions/action_permission'

import {
  DropdownIcon,
  DropdownItem,
  DropdownAvatar,
  DropdownCard,
  Avatar, AvatarBadge,
  DropdownItemName,
  DropdownItemIcon
} from './Dropdown.style'

import Cookies from 'js-cookie'
import { Title2, Title3 } from '../Font'
import { icon } from '../../libs/icon.js'
import { DEBUG } from '../../libs/utils'

import { InvertBtn, SuccessBtn } from '../Button'
import default_avatar from '../../assets/img/default_avatar.png'

class DropdownAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      DropdownShow: false,
      modal: false,
      isLoading: false
    }
    this.closeMenu = this.closeMenu.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.logout = this.logout.bind(this);
    this.modal_logout = this.modal_logout.bind(this);
    this.openWindow = this.openWindow.bind(this)
  }

  componentDidMount() {
    const { profile } = this.props.store_permission;
    if(profile && profile.uid){
      this.fetchProfile(profile.uid);
    }
  }
  
  componentDidUpdate(prevProps) {
    const { profile: prevProfile } = prevProps.store_permission;
    const { profile } = this.props.store_permission;
  
    if (prevProfile && profile && prevProfile.uid !== profile.uid) {
      this.fetchProfile(profile.uid);
    }
  }
  
  fetchProfile(uid) {
    this.props.getAccountProfile(uid);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ DropdownShow: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ DropdownShow: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  logout() {
    DEBUG('logout');
    for (let key in localStorage) {
      delete localStorage[key]
    }
    Cookies.remove('_token');
    Cookies.remove('_email');
    //window['location'].href = process.env.REACT_APP_RP_ACCOUNT + '/signout'
    this.setState({ isLoading: true }, () => {
      this.props.userLogout();
    });
  }

  openWindow(url, target) { 
    var windowReference = window.open(null, target);
    this.props.checkLogin(function () {
      windowReference.location = url;
    });
  }

  modal_logout() {
    this.setState({ modal: !this.state.modal })
  }

  componentWillUnmount() {
    //this.setState({ DropdownShow: false })
  }

  render() {
    const { store_permission: { profile, profileAccount }, store_language: { dictionary }, store_transactions } = this.props
    const { isLoading } = this.state;
    const menuList = [
      {
        title: dictionary.account_profile,
        show: true,
        icon: 'fas fa-user',
        function: () => this.openWindow(`${process.env.REACT_APP_RP_ACCOUNT}/editprofile`, 'RPAuth')
      },
      {
        title: dictionary.billing_payment,
        show: true,
        icon: 'fas fa-credit-card',
        function: () => this.openWindow(`${process.env.REACT_APP_RP_ACCOUNT}/payment`, 'RPAuth')
      },
      {
        title: dictionary.reset_password,
        show: true,
        icon: 'fas fa-unlock-alt',
        function: () => this.openWindow(`${process.env.REACT_APP_RP_ACCOUNT}/resetpassword`, 'RPAuth')
      },
      {
        title: dictionary.logout,
        show: true,
        icon: icon.logout,
        function: () => this.setState({ modal: true })
      }
    ]
    return (
      <div className='position-relative'>
        <DropdownIcon onClick={this.showMenu} hidden={store_transactions.hiddenSearch}>
          <AvatarBadge className='d-flex align-items-center justify-content-center' src={profileAccount.photoURL || profile.photoURL || default_avatar} />
        </DropdownIcon>
        <DropdownCard hidden={!this.state.DropdownShow}>
          <div ref='DropdownCard'>
            <DropdownAvatar>
              <div className='mt-2 mb-2 d-flex align-items-center justify-content-center'>
                <Avatar className='d-flex align-items-center justify-content-center' src={profileAccount.photoURL || profile.photoURL || default_avatar} />
              </div>

              <div className='ml-3 mr-3 pt-2 pb-2 border-top border-bottom'>
                <div className='d-flex align-items-center justify-content-center'>
                  <Title2 bold>{profileAccount.name || profile.name}</Title2>
                  <Title2 bold className='pl-2'>{profileAccount.last_name || profile.last_name}</Title2>
                </div>
                <Title3 secondary className='text-center'>{profileAccount.email || profile.email}</Title3>
              </div>
            </DropdownAvatar>
          </div>
          {
            menuList.map((value, key) => {
              return (
                value.show && <DropdownItem key={key} onClick={value.function}>
                  <div className='d-flex align-items-center'>
                    <DropdownItemIcon>
                      <i className={value.icon} style={{ width: '15px', fontSize: 12 }} />
                    </DropdownItemIcon>
                    <DropdownItemName>
                      <Title3>{value.title}</Title3>
                    </DropdownItemName>
                  </div>
                </DropdownItem>
              )
            })
          }
        </DropdownCard>

        <Modal fade={false} centered={true} isOpen={this.state.modal} toggle={this.modal_logout}>
          <ModalHeader>
            <Title2 bold>{dictionary.notification}</Title2>
          </ModalHeader>
          <ModalBody className="text-center">
            <Message>
              <Title2>{dictionary.confirm_logout}</Title2>
            </Message>
          </ModalBody>
          <ModalFooter>
            <InvertBtn onClick={this.modal_logout} disabled={isLoading}>{dictionary.cancel}</InvertBtn>
            <SuccessBtn onClick={this.logout} disabled={isLoading}>
              {isLoading ? <i className="fas fa-spinner fa-pulse" /> : dictionary.logout}
            </SuccessBtn>
          </ModalFooter>
        </Modal>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    store_permission: state.permission,
    store_language: state.language,
    store_transactions: state.transactions,
  }
}

const Message = styled.div`
  color: ${props => props.theme.primary};
  padding: 40px 15px;
`

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    userLogout,
    checkLogin,
    getAccountProfile
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownAccount)