import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";

import { getCookie, setCookie } from "../../libs/cookies";
import { nl2br } from "../../libs/nl2br";
import { Title2 } from "../Font";
import { PrimaryBtn, InvertBtn } from "../Button";

import logo_stripe from "../../assets/img/logo_stripe.png";
import logo_2c2p from "../../assets/img/logo_2c2p.png";
import BusinessPayment from "../BusinessPayment";
import { DEBUG } from "../../libs/utils";
import { getBusinessSubscription } from "../../redux/actions/action_package";
import moment from "moment";
import { updateSubscriptionPaymentInfo } from "../../redux/actions/action_payment";
import { ToastBar } from "../ToastComponent/Toast.style";
import Loading from "../Loading";
import { PAYMENT } from "../../redux/actions_const";

export default function UpdatePaymentMethod() {
  const dispatch = useDispatch();
  const COOKIE_PREFIX = "__LAST_SHOW_UPDATE_PAYMENT_";
  const [isOpen, toggleModal] = useState(false);
  const [isShowPayment, setShowPayment] = useState(false);
  const dictionary = useSelector((state) => state.language.dictionary);
  const business = useSelector((state) => state.business.current);
  const packages = useSelector((state) => state.packages);
  const isLoading = useSelector((state) => state.payment.isLoading);
  const permission = useSelector((state) => state.permission);
  const { business_code } = business;
  const { packages: { uid } } = packages;
  const permission_profile_id = _.get(permission, ['profile', 'uid']);
  //DEBUG("[UpdatePaymentMethod]", business, packages, permission);

  useEffect(() => {
    DEBUG("[UpdatePaymentMethod] useEffect", business_code);

    if (business_code) {
      const cookie = getCookie(`${COOKIE_PREFIX}${business_code}`);
      if (cookie) {
        const lastShowDate = moment(parseInt(cookie));
        const today = moment();
        DEBUG("[UpdatePaymentMethod] cookie", cookie, lastShowDate.date(), today.date());
        if (lastShowDate.date() === today.date()) {
          return;
        }
      }

      dispatch(getBusinessSubscription(business_code, (error, data) => {
        if (error) {
          return;
        }

        const subscription = _.get(data, ['data', 'subscription']);
        const { id, subscription_payment_type } = subscription;
        DEBUG("[UpdatePaymentMethod] cb", error, subscription);
        if (id && (typeof subscription_payment_type === "undefined" || subscription_payment_type === "stripe")) {
          toggleModal(true);
        }
      }));
    }
  }, [business_code, dispatch]);

  const onLaterClicked = () => {
    const { business_code } = business;
    setCookie(`${COOKIE_PREFIX}${business_code}`, moment().valueOf())
    toggleModal();
  };

  const onVerifyClicked = () => {
    setShowPayment(true);
  };

  const toastShow = (type, message) => {
    toast[type](
      <ToastBar>
        <Title2 bold white style={{ width: "100%", textAlign: "center" }}>
          {message}
        </Title2>
      </ToastBar>
    );
  }

  const onSubmit = (data) => {
    dispatch({ type: PAYMENT.SET_PATMENT_LOADING, loading: true })
    const { business_code } = business;
    const { packages: { subscription_id } } = packages;
    const { address, card_id } = data;
    address.business_code = business_code;
    //console.log("onSubmit", business_code, subscription_id, address, card_id);
    dispatch(updateSubscriptionPaymentInfo(business_code, address, card_id, subscription_id, (err) => {
      if (Boolean(err)) {
        setShowPayment(false);
        return toastShow("error", dictionary.cannot_completed_request)
      } else {
        setShowPayment(false);
        toggleModal();
        dispatch(getBusinessSubscription(business_code));
        return toastShow("success", dictionary.update_payment_info_successfully)
      }
    }))
  };

  return (
    <Modal centered fade isOpen={isOpen}>
      {
        isLoading ? <Loading size={`3rem`} /> : (
          isShowPayment ? (
            <BusinessPayment
              isOpen={isShowPayment}
              toggle={setShowPayment}
              toggleModalPackage={() => { setShowPayment(false) }}
              showSteps={[1, 3]}
              onLastStepEnd={onSubmit}
            />
          ) : (
            <ContainerModal permission={permission_profile_id === uid} onLaterClicked={onLaterClicked} onVerifyClicked={onVerifyClicked} />
          )
        )
      }
    </Modal>
  );
}

const ContainerModal = ({ permission, onLaterClicked, onVerifyClicked }) => {
  const dictionary = useSelector((state) => state.language.dictionary);
  return (
    <ModalBody>
      <div className="align-items-center d-flex justify-content-center p-2">
        <img alt="stripe" src={logo_stripe} style={{ height: 80 }} />
        <i className="fas fa-arrow-right m-2"></i>
        <img alt="2c2p" src={logo_2c2p} style={{ height: 80 }} />
      </div>
      <div className="text-center p-2 mt-3">
        <Title2 className="mb-3" bold>
          {nl2br(dictionary.change_payment_noti_title)}
        </Title2>
        <Title2>{nl2br(dictionary.change_payment_noti_des)}</Title2>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <InvertBtn md fluid onClick={onLaterClicked}>
          {dictionary.change_payment_noti_later}
        </InvertBtn>
        {
          permission ? <PrimaryBtn bold md fluid className="mb-2" onClick={onVerifyClicked}>
            {dictionary.change_payment_noti_verify}
          </PrimaryBtn> : null
        }

      </div>
    </ModalBody>
  );
};
