import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import { DEBUG, ERROR } from './utils';
import { setCookie } from './cookies';

const APP_NAME = 'RP_ACCOUNT';

const {
  REACT_APP_FB_ACCOUNT_APIKEY,
  REACT_APP_FB_ACCOUNT_PROJECT_ID,
  REACT_APP_FB_ACCOUNT_SENDER_ID
} = process.env;
const config = {
  apiKey: REACT_APP_FB_ACCOUNT_APIKEY,
  authDomain: `${REACT_APP_FB_ACCOUNT_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${REACT_APP_FB_ACCOUNT_PROJECT_ID}.firebaseio.com`,
  projectId: `${REACT_APP_FB_ACCOUNT_PROJECT_ID}`,
  storageBucket: `${REACT_APP_FB_ACCOUNT_PROJECT_ID}.appspot.com`,
  messagingSenderId: REACT_APP_FB_ACCOUNT_SENDER_ID
};

let firebaseApp;

try {
  DEBUG('Firebase Project', APP_NAME, config.projectId);
  if (!firebase.apps.length || !firebase.apps.find((app) => app.name === APP_NAME)) {
    firebaseApp = firebase.initializeApp(config, APP_NAME);
  } else {
    firebaseApp = firebase.app(APP_NAME);
  }
} catch (err) {
  ERROR('Firebase initialization error', err);
  firebaseApp = firebase.initializeApp(config, APP_NAME);
}

let profileListener;

export const signinWithCustomToken = (token) => {
  return firebaseApp.auth().signInWithCustomToken(token);
};

export const signout = () => {
  if (profileListener) {
    profileListener();
  }
  return firebaseApp.auth().signOut();
};

export const setPersistence = () => {
  return firebase.app(APP_NAME).auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
};

export const signin = (email, password) => {
  return firebaseApp.auth().signInWithEmailAndPassword(email, password);
};

export const getIdToken = () => {
  return new Promise((resolve, reject) => {
    const fbApp = firebase.app(APP_NAME).auth().currentUser;
    fbApp.getIdToken(true).then((idToken) => {
      resolve(idToken);
    });
  });
};

export const setFBAccountToken = (token) => {
  return new Promise((resolve, reject) => {
    try {
      const currentUser = firebaseApp.auth().currentUser;
      if (!currentUser) {
        throw new Error('No authenticated user');
      }
      firebaseApp
        .firestore()
        .collection('user_token')
        .doc(token)
        .set({
          uid: currentUser.uid,
          iss: new Date(),
          service: 'loyalty'
        })
        .then(resolve)
        .catch(reject);
    } catch (err) {
      ERROR('setFBAccountToken err', err);
      reject(err);
    }
  });
};

export const getUserProfile = (user) => {
  return new Promise((resolve, reject) => {
    firebaseApp
      .firestore()
      .collection('account')
      .doc(user.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const profile = doc.data();
          profile.emailVerified = user.emailVerified;
          resolve(profile);
        } else {
          reject({ code: 'UNREGISTERED', providerData: user });
        }
      })
      .catch(reject);
  });
};


export const updateUserStatus = (isLogin, tabUUIDStore) => {

  const fbApp = firebase.app(APP_NAME);
  const dateTime = new Date();
  const currentUser = fbApp.auth().currentUser || {};

  if (!currentUser.uid) return null;

  if (isLogin) {
    const tabUUID = sessionStorage.getItem('tabUUID') || tabUUIDStore;
    setCookie('_tabUUID', tabUUID);
  }

  // Object.assign(window.ps_auth, {
  //   currentUser: isLogin && currentUser.emailVerified ? JSON.parse(JSON.stringify(currentUser)) : null,
  //   uid: isLogin ? currentUser.uid : null,
  //   email: isLogin ? currentUser.email : null
  // })
  return fbApp
    .firestore()
    .collection('user_status')
    .doc(currentUser.uid)
    .set({ isLogin, lastLogin: dateTime }, { merge: true });
};

export const getFirebaseApp = () => {
  return firebaseApp;
};

export const getProfileOnSnapshot = (uid) => {
  return new Promise((resolve, reject) => {
    const profileRef = firebaseApp.firestore().collection('account').doc(uid);

    profileListener = profileRef.onSnapshot(
      (snapshot) => {
        const data = snapshot.data();
        if (data) {
          const { photoURL, name, last_name, email } = data;
          resolve({ photoURL, name, last_name, email });
        } else {
          reject(new Error('No profile data available'));
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
};

export const FB_Firestore = firebaseApp.firestore();
export const FB_Auth = firebaseApp.auth();
export const FB_Storage = firebaseApp.storage();
export const FB_Functions = firebaseApp.functions();
