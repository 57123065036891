import styled, { keyframes, css } from 'styled-components';

export const SidebarContainer = styled.div`
  display : flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 250px;
  background: #fafafa;
`;

export const BusinessAvatar = styled.img`
  max-width: 35px;
  border-radius: 50%;
`
export const BusinessAvatar2 = styled.div`
  right: 2px;
  bottom: 7px;
  width: 12px;
  height: 12px;
  color: #00C851;
  border-radius: 50%;
  position: absolute;
`

export const SidebarRow = styled.div``

export const SidebarMenuItem = styled.div`
   font-size: ${props => props.theme.font_m};
   color:  ${props => props.active ? props.theme.primary : props.theme.secondary};
   font-weight:  ${props => props.active ? props.theme.font_bold : null}; 
   display: block;
   padding: 15px;
   //border-bottom: 1px solid ${props => props.theme.white_2};
   cursor:pointer;
`
export const LogoutBtnSection = styled.div`
  padding:15px;
`
export const SidebarTitleItems = styled.div`
  display: inline-block;
  padding: 0 15px;
`

const rotateDown = keyframes`
  from {
    transform:  rotate(0deg);
  }

  to {
    transform:  rotate(180deg);
  }
`;

const rotateUp = keyframes`
  from {
    transform:  rotate(180deg);
  }

  to {
    transform:  rotate(0deg);
  }
`;

export const SidebarIcon = styled.div`
  padding: 0px 5px;
  display: inline-block;
`

export const SidebarIconCaret = styled.div`
  float:right;
  animation : ${props => props.toggle ? rotateDown : rotateUp} 0.25s linear ;
  animation-fill-mode: forwards;
`

export const SidebarSubmenuContainer = styled.div`
  overflow:hidden;
  height: ${props => props.toggle ? (props.totalChild * 51) + 'px' : '0'} ;
  transition: height .5s;
  border-bottom:  ${props => props.toggle ? '1px' : '0px'} solid ${props => props.theme.white_border};
`

export const SidebarSubMenuItem = styled.div`
   font-size: ${props => props.theme.font_m};
   color:  ${props => props.active ? props.theme.primary : props.theme.secondary};
   font-weight:  ${props => props.active ? props.theme.font_bold : null}; 
   display: block;
   padding: 15px 25px;
   cursor:pointer;   
   border-left: 5px solid ${props => props.theme.primary}; 
`

export const SidebarBusinessName = styled.div`
  font-size: ${props => props.theme.font_l};
  color: ${props => props.theme.primary};
  font-weight: ${props => props.theme.font_bold};
  padding: 10px 0px;
`

export const SidebarBusinessDropdown = styled.div`
  // margin: 10px 0px 0px 0px;
  position: relative;
  width: 100%;
`

export const SidebarBusinessSelected = styled.div`
  padding: 0px 15px 10px 15px;
  color: ${props => props.theme.primary};
  font-weight: ${props => props.theme.font_bold};
  font-size: ${props => props.theme.font_m};
  display: flex;
  justify-content: space-between;
  align-items:center;
  cursor:pointer;
`

export const SidebarBusinessList = styled.div`
  position: absolute;
  top: 45px;
  color: ${props => props.theme.secondary};
  background: ${props => props.theme.white};
  max-height: 200px;
  opacity: ${props => props.toggle ? '1' : '0'};
  transition: opacity .5s;
  box-shadow: 1px 1px 1px 1px #ccc;
  width:100%;
  z-index:  ${props => props.toggle ? '10' : '-10'};
  overflow-y: hidden;
`

export const SidebarBusinessListDropdown = styled.div`
  max-height: 200px;
  overflow-y: auto;
`

export const SidebarBusinessListItem = styled.div`
  padding: 5px 15px;
  font-size: ${props => props.theme.font_s};
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover{
    > * {
      > * {
        color: ${props => props.theme.white};
      }
    }
    background:  ${props => props.theme.primary};
  }

  ${props => props.selected && css`
    color: ${props => props.theme.primary};
    font-weight: ${props => props.theme.font_bold};
    background: #E0E0E0;
  `}
`
export const SidebarBusinessListItem2 = styled.div`
  padding: 5px;
  font-weight: ${props => props.theme.font_bold};
  font-size: ${props => props.theme.font_m};
  text-align: center;
  cursor: pointer;
  &:hover{
    color: ${props => props.theme.white};
    background:  ${props => props.theme.primary};
  }
`

export const SidebarBusinessListItemMethod = styled.div`
  font-size: ${props => props.theme.font_s};
  padding: 5px;
  text-align: center;
  cursor: pointer;
  border-top : 1px solid   ${props => props.theme.white_border};
  &:hover{
    color: ${props => props.theme.white};
    background:  ${props => props.theme.primary};
  }
`

export const SidebarBusinessTotal = styled.div`
  padding: 5px;
  text-align: center;
  cursor: pointer;
  font-size: ${props => props.theme.font_s};
  color: ${props => props.theme.secondary};
`

export const WizardContainerBody = styled.div`
  height: calc(100vh - 150px);
  max-height: 650px;

  display: flex;
  align-items: center;
  justify-content: center;
`