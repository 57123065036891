import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toCurrency } from "../../../libs/currency";
import { Title2, Title3 } from "../../Font";
import LoadingGradient from "../../LoadingGradient";

export default function BusinessUsageDetail(props) {
  const { subscription } = useSelector((state) => state.packages);
  const { dictionary } = useSelector((state) => state.language);
  const { usage_record } = useSelector((state) => state.usage);

  const { itemsUsage } = subscription;
  const { loading } = props;
  
  if (loading) return <LoadingBusinessSubscriptionDetail />;
  return (
    <UsageDetailWithFreeCredit itemsUsage={itemsUsage} usage_record={usage_record} dictionary={dictionary} />
  );
}

const LoadingBusinessSubscriptionDetail = () => {
  return (
    <div>
      <div>
        <div className="d-flex pb-2 w-100">
          <div className="col-4">
            <LoadingGradient width={"5vw"} minWidth={"15px"} />
          </div>
          <div className="col-2 d-flex justify-content-end">
            <LoadingGradient width={"5vw"} minWidth={"15px"} />
          </div>
          <div className="col-3 d-flex justify-content-end"></div>
          <div className="col-3 d-flex justify-content-end">
            <LoadingGradient width={"5vw"} minWidth={"15px"} />
          </div>
        </div>
      </div>
      <div className="mb-1 align-items-baseline d-flex w-100">
        <div className="col-4" style={{ paddingLeft: "1.75rem" }}>
          <LoadingGradient width={"5vw"} minWidth={"15px"} />
        </div>
        <div className="col-2 d-flex justify-content-end">
          <LoadingGradient width={"2vw"} minWidth={"15px"} />
        </div>
        <div className="col-3 d-flex justify-content-end"></div>
        <div className="col-3 d-flex justify-content-end">
          <LoadingGradient width={"2vw"} minWidth={"15px"} />
        </div>
      </div>
      <div className="pt-2 px-3">
        <div className="d-flex border-top align-items-baseline justify-content-end py-2">
          <div className="col-3 d-flex justify-content-end">
            <LoadingGradient width={"5vw"} minWidth={"15px"} />
          </div>
          <div className="col-3 pr-0 d-flex justify-content-end">
            <LoadingGradient width={"2vw"} minWidth={"15px"} />
          </div>
        </div>
      </div>
    </div>
  );
};

const UsageDetailWithFreeCredit = (props) => {
  const { itemsUsage, usage_record, dictionary } = props;
  const [showDetail, setShowDetail] = useState(false);
  const checkShowBtn = _.filter(itemsUsage, (usage) => usage.sub_total && usage.sub_total > 0);

  const toggleDetails = () => {
    setShowDetail(!showDetail);
  };
  return (
    <div>
      {itemsUsage.length ? (
        <div>
          <div className="d-flex pb-2 w-100">
            <Title2 className="col-4" bold>
              {dictionary.broadcast_billing_credit}
            </Title2>
            <Title2 className="col-2 text-right" bold>
              {showDetail && dictionary.billing_quantity}
            </Title2>
            <Title2 className="col-3 text-right" bold>
              {showDetail && dictionary.billing_unit_credit}
            </Title2>
            <Title2 className="col-3 text-right" bold>
              {dictionary.credit}
            </Title2>
          </div>
        </div>
      ) : null}

      {itemsUsage.map((usage, key) => {
        let title = dictionary[usage.title_dictionary];
        return (
          <div key={key} className={showDetail ? "pb-2" : ""}>
            <div key={key} className="mb-1 align-items-baseline d-flex w-100">
              <Title3 className="col-4" bold style={{ paddingLeft: "1.75rem" }}>
                {title}
              </Title3>
              <Title3 className="col-2 text-right"></Title3>
              <Title3 className="col-3 text-right"></Title3>
              <Title3 className="col-3 text-right" bold={showDetail}>
                {usage.sub_total ? `${toCurrency(usage.sub_total || 0, 2)}` : "0"}
              </Title3>
            </div>
            {usage.overage &&
              usage.overage.map((overageItems, keys) => {
                let titleDetail = dictionary.package_items[overageItems.title_dictionary];
                return (
                  <div key={keys}>
                    {showDetail ? (
                      <div key={keys}>
                        <div key={keys} className="mb-1 align-items-baseline d-flex w-100">
                          <Title3 className={overageItems.total_usage > 0 ? "col-4" : "col-5"} style={{ paddingLeft: "2.5rem" }}>
                            {titleDetail}
                          </Title3>
                          <Title3 className="col-2 text-right">{overageItems.total_usage && overageItems.total_usage > 0 ? toCurrency(overageItems.total_usage) : ""}</Title3>
                          <Title3 className="col-3 text-right">{overageItems.amount && overageItems.total_usage > 0 ? `${toCurrency(overageItems.amount || 0, 2)}` : ""}</Title3>
                          <Title3 className="col-3 text-right">
                            {overageItems.sub_total ? `${toCurrency(overageItems.sub_total || 0, 2)}` : overageItems.items ? (overageItems.items.length === 0 ? 0 : "") : 0}
                          </Title3>
                        </div>
                        <div>
                          {overageItems.items &&
                            overageItems.items.map((item, keyItems) => {
                              let titleDetail = dictionary.package_items[item.title_dictionary];
                              let titleSplit = titleDetail.split(" - ");
                              let titleProvider = titleSplit.length > 1 ? titleSplit[1] : titleDetail;
                              return (
                                <div key={keyItems} className="mb-1 align-items-baseline d-flex w-100">
                                  <Title3 className="col-4" style={{ paddingLeft: "3.25rem" }}>
                                    {titleProvider}
                                  </Title3>
                                  <Title3 className="col-2 text-right">{item.total_usage ? toCurrency(item.total_usage) : ""}</Title3>
                                  <Title3 className="col-3 text-right">{item.amount ? `${toCurrency(item.amount || 0, 2)}` : ""}</Title3>
                                  <Title3 className="col-3 text-right">{item.sub_total ? `${toCurrency(item.sub_total || 0, 2)}` : ""}</Title3>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
        );
      })}
      {checkShowBtn.length > 0 && (
        <div className="mb-1 align-items-baseline d-flex w-100" onClick={toggleDetails}>
          <Title3 className="col-4" style={{ paddingLeft: "1.75rem" }} bold link blue>
            {showDetail ? dictionary.view_details_less : dictionary.dashboard_view_details}
          </Title3>
        </div>
      )}
      <div className="pt-2 px-3">
        <div className="d-flex border-top align-items-baseline justify-content-end py-2">
          <Title2 className="col-3 text-right" bold>
            {dictionary.credit_use}
          </Title2>
          <Title2 className="col-3 pr-0 text-right" bold>
            {toCurrency(usage_record.usage_credit, 2)}
          </Title2>
        </div>
      </div>
    </div>
  );
};
