import React, { Component } from 'react';
import { Modal, ModalBody, Card, CardHeader, CardBody } from 'reactstrap'

import { Title2, Title3 } from '../../components/Font'
import { InvertBtn, DangerBtn } from '../../components/Button'

import { BoxDetail, BoxHead, ModelDetailFooter } from './verifyBusinessHash.style'

class ConfirmModalHash extends Component {
  render() {
    let { info_status, category_status, phone_status, card_status } = this.props
    return (
      <Modal isOpen={this.props.isOpen} centered={true}>
        <ModalBody>
          <Card>
            <CardHeader>
              <Title2 bold>ยืนยันการปฏิเสธธุรกิจ</Title2>
            </CardHeader>
            <CardBody>
              <BoxHead>
                <Title2 bold> <span className='text-danger'>* </span>หัวข้อที่คุณเลือกต่อไปนี้จะถูกปฏิเสธธุรกิจ</Title2>
              </BoxHead>
              {
                !info_status
                && <BoxDetail select><Title3 white bold >รายละเอียดสถานประกอบการ</Title3> </BoxDetail>
              }
              {
                !category_status
                && <BoxDetail select><Title3 white bold>รายละเอียดประเภทธุรกิจ</Title3> </BoxDetail>
              }
              {
                !phone_status
                && <BoxDetail select><Title3 white bold>รายละเอียดเบอร์โทรศัพท์</Title3> </BoxDetail>
              }
              {
                !card_status
                && <BoxDetail select><Title3 white bold>รายละเอียดบัตรเครดิต</Title3></BoxDetail>
              }
              <ModelDetailFooter>
                <DangerBtn md bold onClick={this.props.updateHash}>ปฏิเสธธุรกิจ</DangerBtn>
                <InvertBtn md bold onClick={this.props.toggle}>ยกเลิก</InvertBtn>
              </ModelDetailFooter>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    );
  }
}

export default ConfirmModalHash;