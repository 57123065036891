import moment from 'moment'
import { getCookie } from './cookies'

function countUpperCaseY(str) {
  return (str.match(/Y/g) || []).length;
}

function formatDateCustom(date, format = "DD-MM-YYYY HH:mm") {
  let locale = getCookie("__lang");

  let countY = countUpperCaseY(format);
  
  let m = moment(date);

  let formatDate = m.format(format);

  if (locale === "th") {
    const firstYPosition = format.indexOf("Y");
    const lastYPosition = format.lastIndexOf("Y");

    const beforeY = format.substring(0, firstYPosition).trim();
    const afterY = format.slice(lastYPosition + 1).trim();

    if (countY === 0) {
      formatDate = m.format(format);
      return formatDate;
    }

    if (beforeY.length > 0) {
      formatDate = m.format(beforeY);
    }

    if (countY === 2) {
      formatDate =
        formatDate + " " + (m.year() + 543).toString().substring(2, 4);
    } else if (countY === 4) {
      formatDate = formatDate + (formatDate.endsWith('-') ? "" : " ") + (m.year() + 543).toString();
    }

    if (afterY.length > 0) {
      formatDate = formatDate + " " + m.format(afterY);
    }
  }

  return formatDate;
}

export const formatCustom = (date, format = 'DD-MM-YYYY HH:mm') => formatDateCustom(date, format)

export const formatDate = (date) => formatDateCustom(date, 'DD-MM-YYYY HH:mm')
export const formatDateTime = (date) => formatDateCustom(date, 'D MMM YYYY HH:mm')

export const formatDateFull = (date) => formatDateCustom(date, 'D MMMM YYYY')
export const formatDateFull2 = (date) => formatDateCustom(date, 'D MMM YYYY')
export const formatDateDB = (date) => formatDateCustom(date, 'DD-MM-YYYY')
export const formatTime = (time) => moment(time, 'HH:mm:ss').format('HH:mm')

export const toMinute = (dictionary, seconds) => {
  seconds = parseInt(seconds, 10)

  if (isNaN(seconds))
    return false

  let divide = 60
  let temp = calculate(seconds, divide, [])
  let ss = temp[1]
  let mm = temp[0]
  let hr = 0

  if (mm >= divide) {
    hr = Math.ceil(mm / divide)
    mm = Math.ceil(mm % divide)
  }

  return `${hr ? `${hr} ${hr > 1 ? dictionary.hours : dictionary.hour}\n` : ""} ${mm || hr ? `${mm} ${mm > 1 ? dictionary.minutes : dictionary.minute}` : ""}  ${ss ? ss : ''}  ${ss ? dictionary.seconds : ''}`
}
export const getMonth = (date, format = 'MMMM', locale) => {
  if (locale) moment.locale(locale)
  return moment(date).format(format)
}

function calculate(value, divide, resultArr) {
  if (divide < 1) return resultArr
  let result = Math.floor(value / divide)
  let next = value % divide
  resultArr.push(result)
  return calculate(next, divide / 60, resultArr)
}
