import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal, ModalBody } from 'reactstrap'
import ReactDOM from 'react-dom'
import { withRouter } from 'react-router-dom'
import {
  selectBusinessDropdownlist,
  businessReload,
  getBusinessBalance,
  getBusinessInfo,
  getBusinessLabel,
} from '../../redux/actions/action_business'
import { getUsageRecord } from '../../redux/actions/action_usage'
import { toggleCreateBusinessModal } from '../../redux/actions/action_wizard'

import {
  SidebarBusinessDropdown,
  SidebarBusinessSelected,
  SidebarIcon,
  SidebarBusinessList,
  SidebarBusinessListDropdown,
  SidebarBusinessListItem,
  SidebarBusinessListItemMethod,
  BusinessAvatar,
  BusinessAvatar2,
  WizardContainerBody,
} from './BusinessDropdown.style'


import WizardContainer from '../../pages/Wizard/wizardContainer'
import { Title2, Title3, Title4 } from '../Font'

import { icon } from '../../libs/icon.js'
import prepareText from '../../libs/prepareText'

const this_menu = 'ecoupon'

class BusinessDropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = { toggle: false }

    this.getBusinessInfo = this.getBusinessInfo.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.getBusinessUsageRecord = this.getBusinessUsageRecord.bind(this)
  }

  componentDidMount() {
    let { store_business: { current } } = this.props
    this.getBusinessInfo(current.business_code)
    this.getBusinessUsageRecord(current.business_code)
  }

  componentDidUpdate(prevProps) {
    let nextBusiness = this.props.store_business.current.business_code
    let prevBusiness = prevProps.store_business.current.business_code

    if (nextBusiness !== prevBusiness) {
      this.props.history.push('/')
      this.getBusinessInfo(nextBusiness)
      this.getBusinessUsageRecord(nextBusiness)
    }
  }

  handleOutsideClick(e) {
    if (ReactDOM.findDOMNode(this.refs.dropdown).contains(e.target)) return
    this.toggle()
  }

  toggle(e) {
    if (!this.state.toggle) document.addEventListener('click', this.handleOutsideClick, false)
    else document.removeEventListener('click', this.handleOutsideClick, false)
    this.setState({ toggle: !this.state.toggle })
    
  }

  getBusinessInfo(business_code) {
    this.props.getBusinessInfo(business_code, false, () => {
      this.props.getBusinessLabel({ business_code: business_code, excludeArchive: true })
    })
  }

  getBusinessUsageRecord(business_code) {
    this.props.getUsageRecord(business_code)
  }

  render() {
    if (!this.props.store_business)
      return null

    let { store_permission: { profile } } = this.props
    let { store_language: { dictionary } } = this.props
    let { store_business: { current, list, permission } } = this.props

    let role_name = '';
    let max_business = profile.max_business || 1
    let total_business = list.filter(v => v.fk_privilege_group_id === 1).length
    if(permission && permission[this_menu] && permission[this_menu].privilege_group_name){
      role_name = permission[this_menu].privilege_group_name
    }
    return <SidebarBusinessDropdown ref="dropdown" >
      <SidebarBusinessSelected onClick={this.toggle.bind(this)}>
        {
          current.verified
            ? <div style={{ width: '35px', height: '35px', position: 'relative' }}>
              <BusinessAvatar alt="" src={current.business_logo_url} />
              <BusinessAvatar2>
                <i className="fas fa-check-circle" style={{ backgroundColor: '#FFFFFF', borderRadius: '50%' }} />
              </BusinessAvatar2>
            </div>
            : <BusinessAvatar alt="" src={current.business_logo_url} />
        }
        <div className='text-center'>
          <Title2 bold>{current.business_name}</Title2>
          <Title4 secondary>{role_name}</Title4>
        </div>
        <SidebarIcon>
          <Title3><i className={icon.chevron_down} /></Title3>
        </SidebarIcon>
      </SidebarBusinessSelected>

      <SidebarBusinessList toggle={this.state.toggle}>
        <SidebarBusinessListDropdown>
          {
            list.length > 0 && list.map((val, key) => {
              let { business_code, business_name, verified, business_logo_url, privilege_group_name } = val
              let _this_current = current.business_code === business_code
              return <SidebarBusinessListItem key={key} selected={_this_current} onClick={() => { !_this_current && this.props.selectBusinessDropdownlist(val.business_code); this.toggle(); }}>
                <div>
                  {
                    verified
                      ? <div style={{ width: '35px', height: '35px', position: 'relative' }}>
                        <BusinessAvatar alt="" src={business_logo_url} />
                        <BusinessAvatar2>
                          <i className="fas fa-check-circle" style={{ backgroundColor: '#FFFFFF', borderRadius: '50%' }} />
                        </BusinessAvatar2>
                      </div>
                      : <BusinessAvatar alt="" src={business_logo_url} />
                  }
                </div>
                <div className='text-right'>
                  <Title3>{business_name}</Title3>
                  <Title4 secondary>{privilege_group_name}</Title4>
                </div>
              </SidebarBusinessListItem>
            })
          }

          {
            total_business < (profile.max_business || 1)
              ? <SidebarBusinessListItemMethod onClick={() => { this.props.toggleCreateBusinessModal(); this.toggle(); }}>
                {prepareText(dictionary.create_x_business, [Number(max_business) - Number(total_business)])}
              </SidebarBusinessListItemMethod>
              : <SidebarBusinessListItemMethod>
                {prepareText(dictionary.limited_x_business, [Number(total_business)])}
              </SidebarBusinessListItemMethod>
          }
        </SidebarBusinessListDropdown>
      </SidebarBusinessList>

      <Modal size="xl" centered fade={false} isOpen={this.props.store_business.toggleModal} toggle={this.props.toggleCreateBusinessModal} >
        <ModalBody className='border-bottom'>
          <div className='w-100 d-flex align-items-center justify-content-between'>
            <Title2 bold>{dictionary.create_business}</Title2>
            <Title2 bold link onClick={this.props.toggleCreateBusinessModal}><i className='fas fa-times' /></Title2>
          </div>
        </ModalBody>
        <ModalBody className='p-0' style={{ backgroundColor: '#7d889a11' }}>
          <WizardContainerBody>
            <div className='w-100'>
              <WizardContainer />
            </div>
          </WizardContainerBody>
        </ModalBody>
      </Modal>
    </SidebarBusinessDropdown>
  }
}

const mapStateToProps = (state) => {
  return {
    store_business: state.business,
    store_permission: state.permission,
    store_language: state.language
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators({
    selectBusinessDropdownlist,
    businessReload,
    toggleCreateBusinessModal,
    getBusinessBalance,
    getBusinessInfo,
    getUsageRecord,
    getBusinessLabel,
  }, dispatch)
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BusinessDropdown))

