import styled, { css } from 'styled-components'

const fontStyle = styled.div`
  color: ${props => props.theme.primary};

  ${props => props.primary && css`
    color: ${ props.theme.primary};
  `}

  ${props => props.secondary && css`
    color: ${ props.theme.secondary};
  `}

  ${props => props.third && css`
    color: ${ props.theme.third};
  `}
  
  ${props => props.gray && css`
    color: ${ props.theme.gray};
  `}

  ${props => props.white && css`
    color: ${ props.theme.white};
  `}

  ${props => props.bold && css`
    font-weight: ${ props.theme.font_bold};
  `}

  ${props => props.danger && css`
    color: ${ props.theme.red};
  `}

  ${props => props.blue && css`
    color: ${ props.theme.blue};
  `}
  
  ${props => props.success && css`
    color: ${ props.theme.green};
  `}

  ${props => props.yellow && css`
  color: ${ props.theme.yellow};
  `}

  ${props => props.brown && css`
  color: ${ props.theme.brown};
  `}

  ${props => props.brown_light && css`
  color: ${ props.theme.brown_light};
  `}

  ${props => props.warning && css`
    color: ${ props.theme.orange};
  `}
  
  ${props => props.flex && css`
    display: flex;
    align-items: center;
  `}

  ${props => props.link && css`
    cursor: pointer;
    &:hover{
      opacity:0.9;
    }
  `}

  ${props => props.center && css`
    text-align: center;
  `}

  ${props => props.minfit && css`
    min-width: fit-content;
  `}

  ${props => props.underline && css`
    text-decoration: underline;
  `}

  ${props => props.disabled && css`
    pointer-events: none;
  `}
`

export const Title1 = styled(fontStyle)`
   font-size: ${props => props.theme.font_l};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_m};
  }
`

export const Title2 = styled(fontStyle)`
  font-size: ${props => props.theme.font_m};
  overflow-wrap: anywhere;
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_s};
  }
`

export const Title3 = styled(fontStyle)`
  font-size: ${props => props.theme.font_s};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xs};
  }
`

export const Title4 = styled(fontStyle)`
  font-size: ${props => props.theme.font_xs};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xxs};
  }
`

export const Title5 = styled(fontStyle)`
  font-size: ${props => props.theme.font_xxs};
`


const spanStyle = styled.span`
  color: ${props => props.theme.primary};
`

export const Font2 = styled(spanStyle)`
  font-size: ${props => props.theme.font_m};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_s};
  }
`

export const TitlePointspot = styled(fontStyle)`
    font-size: 26px;
  @media (max-width: 990px) {
    font-size: 24px;
  }
`

export const TitleMain1 = styled(fontStyle)`
   font-size: ${props => props.theme.font_xxl};
  @media (max-width: 990px) {
    font-size: ${props => props.theme.font_xl};
  }
`