import styled, { css } from 'styled-components'

export const AddeCouponBar = styled.div`
  top: 50px;
  z-index: 998;
  display: flex;
  position: sticky;
  padding: 10px 15px;
  min-height: 50px;
  justify-content: flex-end;
  box-shadow :1px 1px 1px #ccc;
  background:  ${props => props.theme.white};
`

// border-top-left-radius: calc(0.25rem - 1px);
// border-top-right-radius: calc(0.25rem - 1px);
// min-height:150px;
export const CouponImg = styled.div`
  margin-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.border_line};
  background: ${props => props.theme.secondary};
`

export const CardEcounponDetail = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const CardEditButton = styled.div`
  top: 19px;
  right: 3px;
  width: auto;
  position: absolute;
`

export const CardTypeCouponTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: auto;
  margin: 0px;
  padding: 0px 15px;
  border-radius: 10px 10px 10px 10px;
  background-color: ${props => props.theme.primary};
  
  ${props => props.type && css`
    background-color: ${ props.theme.coupon[props.type]};
  `}
`

export const ShowStat = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ButtonActionEcoupon = styled.div`
  width: auto;
  display: flex;
  align-items: center;  
`

export const ButtonActionEcouponItems = styled.div`
  height: 26px;
  display: flex;
  align-items: center;
 
  &:hover{
    border-radius: 2px;
    background-color: #F0F0F0;
  }
`
export const Message = styled.div`
  color: ${props => props.theme.primary};
  padding: 40px 15px;
`

export const CenterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CouponBox = styled.div`
  width: 100%;
  height: 40vw;
  min-width: 240px;
  min-height: 115px;
  transition: all 0.2s;
  ${props => props.selectOpacity ? css`opacity: 1;` : css`opacity: .5; `}

${props => props.sm
    ? css`
      max-width: 260px;
      max-height: 115px;
  `
    : css`
      max-width: 340px;
      max-height: 145px;
  `
  }

  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 15px;
  ${props => props.pointer && css`cursor: pointer;`}
`

export const Headcoupon = styled.div`
  width: 35%;
  height: 100%;
  overflow: hidden;

  padding: 7px;
  ${props => props.select ? css`background: #e2e2e2;` : css`background: #F0F0F1;`}
  border-radius: 10px;
  
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  border-width: 1px;
  border-style: solid;
  border-color: #b5bbc3;
  box-shadow: 0 6px 20px -6px #CCCCCC;
`

export const QRBox = styled.div`
  width: 65%;
  position: relative;
`

export const QRImgBox = styled.img`
  width: 100%;
  ${props => props.example && css`opacity: 0.35;`}
`

export const HeadcouponAbsolute = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  > * {
    transform: rotate(-45deg);
  }
`

export const LineCut = styled.div`
  width: 1px; 
  z-index: 1;
  position: relative;

  height: 90%;
  border-left: 2px dashed ${props => props.theme.primary};
`

export const IconCut = styled.div`
  position: absolute;
  left: -9.5px;
  bottom: -7px;

  font-size: 20px;
  transform: rotate(270deg);
  color: ${props => props.theme.primary};
`

export const Tailcoupon = styled.div`
  width: 65%;
  height: 100%;
  overflow: hidden;

  overflow: hidden;
  border-radius: 10px;
  ${props => props.select ? css`background: #e2e2e2;` : css`background: #F0F0F1;`}

  border-width: 1px;
  border-style: solid solid solid none;
  border-color: #b5bbc3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 6px 20px -6px #CCCCCC;
`

export const BusinessBar = styled.div`
  height: 100%;
  padding: 4px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const BoxImageCoupon = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const BoxDateTimeCoupon = styled.div`
  right: 1px;
  bottom: 6px;
  position: absolute;

  ${props => props.danger
    ? css`color: ${props.theme.red};`
    : css`color: ${props.theme.primary};`
  }

  text-align: right;
  border-radius: 10px;
  background: rgba(250,250,250,.9);

  margin: 0px;
  padding: 3px 7px;

  font-size: 10px;
  @media (max-width: 990px) {
    font-size: 8px;
  }
`

export const UnderLine = styled.div`
  width: 100%;
  height: 5px;
  
  ${props => props.type === 'discount'
    ? css`background: ${props => props.theme.red};`
    : css`background: ${props => props.theme.blue};`
  }
`
export const ShowPoint = styled.div`
  text-align: center;
  border-radius: 10px;
  background: rgba(230,230,230,.9);
  padding: 3px 7px;
`