import Axios from 'axios'
import { backendPAPI, rpBackendAPI } from '../redux/end_point'

export const updatePassword = (accessToken, password) => {
  return Axios({ 
    method: 'post',
    url: rpBackendAPI + '/auth/updatePassword',
    headers: { Authorization: accessToken },
    data: { password }
  })
}

export const updateUserProfile = (accessToken, data) => {
  return Axios({ 
    method: 'post',
    url: rpBackendAPI + '/auth/updateProfile',
    headers: { Authorization: accessToken },
    data: data
  })
}

export const register = (accessToken, data) => {
  return Axios({
    method: 'post',
    url: backendPAPI + '/oauth2/wallet',
    headers: { Authorization: accessToken },
    data: data
  })
}

export const sendEmailVerification = (accessToken, language) => {
  return Axios({
    method: 'post',
    url: backendPAPI + '/oauth2/sendEmailVerification',
    headers: { Authorization: accessToken },
    data: { language },
  })
}

export const getVerifyEmail = (code) => {
  return Axios({
    method: 'get',
    url: backendPAPI + '/oauth2/verifyEmail?code=' + code,
  })
}

export const sendResetPassword = (email, language) => {
  return Axios({
    method: 'post',
    url: backendPAPI + '/oauth2/sendResetPassword',
    data: {
      email,
      language, 
    }
  })
}

export const resetPassword = (new_password, code) => {
  return Axios({
    method: 'post',
    url: rpBackendAPI + '/auth/resetPassword',
    data: {
      new_password: new_password,
      code: code
    }
  })
}

export const getResetPassword = (code) => {
  return Axios({
    method: 'get',
    url: rpBackendAPI + '/auth/resetPassword?code=' + code
  })
}